import React, { useEffect, useState } from "react";
import { country } from "./Fraud";
import MobileValidation from "../src/helper/MobileValidation";

interface TelInput {
  value: string;
  onChange: (number)=> void

  fullNumber: (number)=> void
  valid?:(number)=> void
  onCountryCodeChange?:(number)=> void
  countries: country[]
}
function TelInput({ value, onChange , countries ,fullNumber,valid,onCountryCodeChange }: TelInput){
  const [defaultValue , setValue] = useState(value)
  const [selectCounty , setSelectCountry] = useState('966')

  useEffect(()=> {
    let number = `${selectCounty}${defaultValue}`
    onChange(defaultValue)
    fullNumber(number)
    onCountryCodeChange && onCountryCodeChange(selectCounty)
    valid && valid((new MobileValidation(`+${number}`)).isValid())
  } ,[defaultValue,selectCounty])
  return(
    <div className="w-full flex">
      <select defaultValue={selectCounty} onChange={(e)=> setSelectCountry(e.target.value)} className="w-1/11 text-col border border-coolGray-100 border-r-0 text-center text-sm  bg-coolGray-100 text-coolGray-500 rounded-s-md hover:none focus:outline-none focus:border-coolGray-100 focus:ring-0">
        { countries.map((country)=> <option key={country.id} value={country.countryCode}>{country.countryCode}</option>) }
      </select>
      <input type="text" onChange={ (e)=> setValue(e.target.value) }  className="w-full border-coolGray-100 border-s-0  text-coolGray-500 rounded-r-md hover:none focus:outline-none focus:border-coolGray-100 focus:ring-0 "/>
    </div>
  )
}

export  default TelInput