import React from "react";

export type DividerProps = {
  label?: string;
  labelAlignment?: "start" | "center" | "end";
  containerStyle?: string;
  height?: number;
  darker?: boolean;
};

export default function Divider({
  label,
  labelAlignment = "center",
  containerStyle,
  height = 1,
  darker = false
}: DividerProps) {
  return (
    <div
      className={`w-full flex items-center ${containerStyle} ${labelAlignment === 'end' ? 'flex-row-reverse' : 'flex-row' }`}
    >
      {label && labelAlignment !== "center" && (
        <p
          className={`${darker ? 'text-coolGray-500' : 'text-coolGray-400'} ${labelAlignment === 'start' ? 'pe-2' : 'ps-2'}`}
        >
          {label}
        </p>
      )}
      <div
        className={`${darker ? 'bg-coolGray-300' : 'bg-coolGray-100'} flex-1`}
        style={{
          minHeight: height,
          maxHeight: height,
        }}
      />
      {label && labelAlignment === "center" && (
        <p
          className={`${darker ? 'text-coolGray-500' : 'text-coolGray-400'} px-2 text-sm`}
        >
          {label}
        </p>
      )}
      <div
        className={`${darker ? 'bg-coolGray-300' : 'bg-coolGray-100'} flex-1`}
        style={{
          minHeight: height,
          maxHeight: height,
        }}
      />
    </div>
  );
}
