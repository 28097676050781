import DefaultInput from "./DefaultInput";
import EditMobile from "./EditMobile";
import  EditEmail  from "./EditEmail";
import React from "react";
import Translation from "../../helper/Translation";

const PersonalInformation = ({ email,token , locale ,emailVerified, mobile , fullName ,enabledCountries, onInputChange,  recaptchaEnabled=true })=> {
  return (
    <div className={`flex flex-col gap-2`}>
      <h2 className={`text-sm leading-5 font-medium text-coolGray-700 mt-4`}>{Translation.t('texts.personal_information')}</h2>
      <DefaultInput  name={`full_name`} label={Translation.t('inputs.full_name.label')} value={fullName} onValueChange={(value)=>{ onInputChange({ full_name: value }) }} />
      <EditEmail email={email}   locale={locale} token={token} emailConfirmation={emailVerified} onEmailChange={(obj)=> onInputChange(obj)} recaptchaEnabled={recaptchaEnabled} />
      <EditMobile {...mobile} token={token} locale={locale} enabledCountries={enabledCountries} mobileObject={mobile} onMobileChange={(obj)=>{ onInputChange(obj)}} recaptchaEnabled={recaptchaEnabled} />

    </div>
  );
}


export default PersonalInformation
