import React from 'react'
import Translation from '../../helper/Translation'
import 'remixicon/fonts/remixicon.css';

export default function ChatAlert() {
  return (
    <div className='px-4 py-2 flex flex-col gap-1 bg-red-100 text-red-600'>
      <div className='flex items-center gap-2'>
        <i className='ri-alert-fill'></i>
        <h6 className="font-semibold text-sm">{Translation.t('inbox.chat.alerts.awareness.title')}</h6>
      </div>
      <p className="text-xs">{Translation.t('inbox.chat.alerts.awareness.description')}</p>
    </div>
  )
}

