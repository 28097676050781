import React from "react";
import { Community } from "../entities";
import Translation from '../src/helper/Translation'

const CommunityBadge = ({community}: {community: Community}) => {
  return (
    <span className="inline-flex truncate items-center rounded-full text-xs px-1 py-0.5 leading-4 font-medium bg-emerald-50 text-emerald-600">
      <div className="flex gap-1 items-center">
        <div className="border border-coolGray-100 rounded-full">
          <img className="w-4 h-4 rounded-full" src={community.image_url} alt="" />
        </div>
        { Translation.t('communities.labels.community') }
      </div>
    </span>
  )
}

export default CommunityBadge
