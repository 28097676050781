import React from 'react'

export default function AbsherIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.85938 0.0218724C7.07188 0.240622 1.56563 1.91562 1.49688 1.95625C1.44063 1.99062 1.36875 2.05625 1.33125 2.10312L1.26562 2.19062V5.9C1.26562 8.775 1.275 9.65625 1.30625 9.81875C1.60313 11.3906 2.85313 12.9125 5.08438 14.4156C6.15 15.1312 7.74688 16 8 16C8.25313 16 9.85 15.1312 10.9156 14.4156C13.1469 12.9125 14.3969 11.3906 14.6938 9.81875C14.725 9.65625 14.7344 8.775 14.7344 5.9V2.19062L14.6688 2.10312C14.6313 2.05625 14.5594 1.99062 14.5031 1.95625C14.3813 1.88437 8.21875 0.0343724 8.04688 0.0156224C7.97813 0.00937238 7.89375 0.0124974 7.85938 0.0218724Z" fill="#059669"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4732 2.16938C10.4732 1.91929 10.6759 1.71655 10.926 1.71655H11.4167V2.66377H10.4732V2.16938ZM10.4732 3.2059C10.4732 3.08086 10.5746 2.97949 10.6996 2.97949H11.4167V11.5938C11.4167 11.7189 11.3153 11.8202 11.1902 11.8202H10.4732V3.2059ZM9.44145 3.61097C9.31641 3.61097 9.21504 3.71234 9.21504 3.83739V11.8202H9.93209C10.0571 11.8202 10.1585 11.7189 10.1585 11.5938V3.61097H9.44145ZM7.95726 5.10036C7.95726 4.97531 8.05863 4.87395 8.18368 4.87395H8.90073V11.5938C8.90073 11.7189 8.79936 11.8202 8.67431 11.8202H7.95726V5.10036ZM6.92565 4.87395C6.80061 4.87395 6.69924 4.97531 6.69924 5.10036V11.8202H7.41629C7.54134 11.8202 7.6427 11.7189 7.6427 11.5938V4.87395H6.92565ZM5.44121 5.10036C5.44121 4.97531 5.54258 4.87395 5.66763 4.87395H6.38468V11.5938C6.38468 11.7189 6.28331 11.8202 6.15826 11.8202H5.44121V5.10036ZM4.40973 4.87396C4.28469 4.87396 4.18332 4.97533 4.18332 5.10038V13.0832H4.90037C5.02541 13.0832 5.12678 12.9818 5.12678 12.8568V4.87396H4.40973ZM8.90073 4.08458C8.90073 4.34615 8.68953 4.5582 8.429 4.5582C8.16847 4.5582 7.95726 4.34615 7.95726 4.08458C7.95726 3.82302 8.16847 3.61097 8.429 3.61097C8.68953 3.61097 8.90073 3.82302 8.90073 4.08458ZM7.17097 4.5582C7.4315 4.5582 7.6427 4.34615 7.6427 4.08458C7.6427 3.82302 7.4315 3.61097 7.17097 3.61097C6.91044 3.61097 6.69924 3.82302 6.69924 4.08458C6.69924 4.34615 6.91044 4.5582 7.17097 4.5582ZM6.38468 4.08458C6.38468 4.34615 6.17348 4.5582 5.91295 4.5582C5.65242 4.5582 5.44121 4.34615 5.44121 4.08458C5.44121 3.82302 5.65242 3.61097 5.91295 3.61097C6.17348 3.61097 6.38468 3.82302 6.38468 4.08458ZM9.68677 13.0832C9.9473 13.0832 10.1585 12.8712 10.1585 12.6096C10.1585 12.348 9.9473 12.136 9.68677 12.136C9.42624 12.136 9.21504 12.348 9.21504 12.6096C9.21504 12.8712 9.42624 13.0832 9.68677 13.0832Z" fill="white"/>
    </svg>
  )
}
