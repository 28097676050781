import React from 'react'

export default function ShobbakUserXIcon() {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69 36.17V20.36a5.11 5.11 0 0 0-3.91-5L39.17 9.12a5.11 5.11 0 0 0-6.31 5V64a5.11 5.11 0 0 0 6.31 5l25.93-6.33a5.11 5.11 0 0 0 3.91-5v-2" stroke="#FFD836" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M69 41.71a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.56a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm-48.47-41.7 2.14-7 .31-1c.23-.73-.92-1-1.14-.32l-2.14 7-.31 1c-.23.73.91 1 1.14.32ZM2.29 11.8 9 14.68l.94.4a.61.61 0 0 0 .81-.21.589.589 0 0 0-.21-.81l-6.71-2.88-.94-.41a.63.63 0 0 0-.81.21.61.61 0 0 0 .21.82Z" fill="#10B981"/>
      <path d="m13.82 10.38-2.23-6.94-.32-1C11 1.72 9.9 2 10.13 2.76l2.23 6.94.31 1c.24.72 1.38.41 1.15-.31v-.01Z" fill="#FFD836"/>
      <path d="M38.34 37.06a11.72 11.72 0 0 0-5.05-3.14c2.4-1.34 4.08-4.37 4.08-7.9a8.72 8.72 0 0 0-5.3-8.4 4.15 4.15 0 0 0-2.13-.4l-6.23 1.36s1.55.55 1.68 1.1c.044.155.122.299.23.42a9.88 9.88 0 0 0-1.87 5.9 9.18 9.18 0 0 0 3.48 7.51 12.66 12.66 0 0 0-7.92 3.9c-5 5.34-4.38 13.93-4.35 14.3a1 1 0 0 0 .77.91l23.63 5.9h-.16a1.008 1.008 0 0 0 .24 2h.24l4.18-1a.85.85 0 0 0 .3-.15.81.81 0 0 0 .25-.3.505.505 0 0 0 0-.13c.033-.087.057-.178.07-.27v-.06c.17-.61.52-14.68-6.14-21.55Z" fill="#10B981"/>
      <path d="M16.59 52.11s-1.28-17.26 13.55-17.26S44.23 59 44.23 59l-27.64-6.89Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.29 36.1a.25.25 0 1 1 0 .499.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .49.25.25 0 1 1 0-.49Zm.62-.63a.25.25 0 1 1 .02.5.25.25 0 0 1-.02-.5Zm-1.24 0a.25.25 0 1 1 0 .501.25.25 0 0 1 0-.5ZM33.91 38a.25.25 0 0 1 .25.25.253.253 0 0 1-.052.208.25.25 0 0 1-.387 0 .25.25 0 0 1-.052-.208.25.25 0 0 1 .24-.25Zm-1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-1.9a.25.25 0 0 1 .05.495.25.25 0 1 1-.05-.495Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M32.05 36.1a.25.25 0 0 1 .05.495.25.25 0 1 1-.05-.495Zm5.61 6.9a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.23a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm.62-.63a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1-.25.25.26.26 0 0 1 .25-.25Zm1.28 1.25a.25.25 0 0 1 .048.495.25.25 0 1 1-.049-.495ZM36.41 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25 2.47a.25.25 0 1 1 0 .501.25.25 0 0 1 0-.5Zm0-4.98a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 0 1 .24.25.25.25 0 1 1-.49 0 .25.25 0 0 1 .25-.25Zm.62-.62a.25.25 0 1 1-.001.5.25.25 0 0 1 0-.5Zm-1.28 0a.253.253 0 0 1 .209.052.25.25 0 0 1-.208.438.25.25 0 0 1-.276-.352.25.25 0 0 1 .276-.138Zm1.28 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.59-1.87a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M37.66 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25-3.75a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.66-.62a.25.25 0 1 1 0 .49.25.25 0 0 1-.275-.352.25.25 0 0 1 .276-.138Zm1.28 1.24a.25.25 0 1 1 0 .499.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5ZM36.41 38a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M36.41 38a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 5a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-2.51a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 1 1-.001.499.25.25 0 0 1 0-.5Zm0 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M36.41 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-5a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 1 1 0 .501.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 .1.49.25.25 0 0 1-.1-.49Zm1.25 1.24a.25.25 0 1 1-.001.5.25.25 0 0 1 0-.5Zm-.62.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-3.73a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.63-.62a.25.25 0 0 1 .25.25.25.25 0 1 1-.49 0 .25.25 0 0 1 .24-.25Zm1.25 1.24a.25.25 0 1 1 0 .501.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 .02.5.25.25 0 0 1-.02-.5Z" fill="#10B981"/>
      <path d="M35.17 38a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.8 11.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Zm0-1.25a.25.25 0 0 1-.25-.25.26.26 0 0 1 .25-.25.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M38.21 49.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Z" fill="#10B981"/>
      <path d="M38.21 49.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Zm0-1.25a.25.25 0 0 1-.25-.25.26.26 0 0 1 .25-.25.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm1.32-3a.25.25 0 1 1-.001.501.25.25 0 0 1 0-.5Zm0 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 1 1 0 .499.25.25 0 0 1 0-.5Zm0 1.24a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm.62.63a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0-4.98a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.24a.25.25 0 0 1 .048.495.25.25 0 1 1-.049-.495Zm-.62-.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.3a.251.251 0 0 1 .299.244.251.251 0 0 1-.19.243.25.25 0 0 1-.11.002.25.25 0 0 1-.276-.352.25.25 0 0 1 .276-.138Z" fill="#10B981"/>
      <path d="M39.53 43.57a.25.25 0 1 1-.001.501.25.25 0 0 1 0-.5Zm-.62-3.11a.25.25 0 1 1 0 .49.25.25 0 0 1-.276-.352.25.25 0 0 1 .275-.138Zm.62.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.69 8.56a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm.62.6a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm0-1.22a.25.25 0 0 1-.24-.25.251.251 0 0 1 .245-.3.25.25 0 0 1 .245.3.25.25 0 0 1-.25.25Zm-.62-.63a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm.62 4.36a.25.25 0 1 1 .001-.5.25.25 0 0 1 0 .5Zm0-1.25a.25.25 0 1 1 .1-.49.25.25 0 0 1-.1.49Zm.63 1.87a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Z" fill="#10B981"/>
      <path d="M38.84 49.64a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm.62 3.11a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm.63 1.87a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm0-1.25a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm0 4.98a.25.25 0 1 1 0-.501.25.25 0 0 1 0 .5Zm0-1.25a.25.25 0 1 1 0-.49.252.252 0 0 1 .276.138.25.25 0 0 1-.275.352Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-2.49a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm1.24 3.73a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm.62.62a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm0-1.25a.25.25 0 1 1 .08-.493.25.25 0 0 1-.08.494Zm-.62-.62a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm1.24 4.98a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm-1.24 0a.25.25 0 1 1 0-.501.25.25 0 0 1 0 .5Zm1.24-1.25a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm-1.24 0a.25.25 0 1 1 0-.49.252.252 0 0 1 .276.138.25.25 0 0 1-.275.352Zm.62.63a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.29 0a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm-1.29-1.25a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm1.29 0a.25.25 0 0 1-.3-.24.26.26 0 0 1 .25-.25.25.25 0 0 1 .25.25.25.25 0 0 1-.2.24Zm.58 1.87a.25.25 0 1 1-.02-.5.25.25 0 0 1 .02.5Zm0-1.25a.25.25 0 1 1-.02-.5.25.25 0 0 1 .02.5Zm-1.25-1.24a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm2.49 2.49a.25.25 0 1 1-.02-.5.25.25 0 0 1 .02.5Zm0-1.25a.25.25 0 1 1-.02-.5.25.25 0 0 1 .02.5Zm-1.81 1.88a.25.25 0 1 1 0-.49.25.25 0 0 1 .208.438.25.25 0 0 1-.208.051Zm1.23 0a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.2-1.22a.25.25 0 1 1-.001-.5.25.25 0 0 1 0 .5Zm.07 1.26a.25.25 0 0 1-.246-.3.25.25 0 1 1 .245.3Z" fill="#10B981"/>
      <path d="M10.26 52.58s-1.34-18 14.12-18c15.46 0 14.69 25.19 14.69 25.19l-28.81-7.19Zm14.5-19.05c2.95 0 5.34-3.139 5.34-7.01 0-3.872-2.39-7.01-5.34-7.01-2.95 0-5.34 3.138-5.34 7.01 0 3.871 2.39 7.01 5.34 7.01Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M42.6 37.54A11.617 11.617 0 0 0 39 38c-4.9 1.55-8.72 5.3-8.72 11.8 0 6.5 3.87 11.8 8.72 11.8.33 0 2.72.45 3.65.45 5.07 0 9.2-5.5 9.2-12.26s-4.18-12.25-9.25-12.25Z" fill="#10B981"/>
      <path d="M50 49.75c0 5.8-3.19 10.5-7.38 10.5-4.19 0-7.81-4.7-7.81-10.5s3.4-10.5 7.6-10.5c4.2 0 7.59 4.75 7.59 10.5Z" fill="#C2E9DB"/>
      <path d="M45.37 54.66a1.541 1.541 0 0 1-1.1-.45l-1.73-1.74-1.74 1.74a1.54 1.54 0 0 1-1.1.45 1.5 1.5 0 0 1-1.09-.46 1.541 1.541 0 0 1 0-2.19l1.73-1.73-1.73-1.74a1.541 1.541 0 0 1 0-2.19 1.53 1.53 0 0 1 2.19 0l1.74 1.73 1.73-1.73a1.51 1.51 0 0 1 1.1-.46 1.56 1.56 0 0 1 1.1 2.65l-1.74 1.74L46.47 52a1.55 1.55 0 0 1-1.1 2.65v.01Z" fill="#fff"/>
      <path d="M45.37 46.58a.86.86 0 0 1 .61.25.88.88 0 0 1 0 1.22l-1.25 1.25-1 1 1 1L46 52.5a.88.88 0 0 1 0 1.22.87.87 0 0 1-1.22 0l-1.25-1.25-1-1-1 1-1.25 1.25a.859.859 0 1 1-1.21-1.22l1.24-1.25 1-1-1-1-1.21-1.2a.86.86 0 0 1 .93-1.406.86.86 0 0 1 .28.186l1.25 1.25 1 1 1-1 1.25-1.25a.86.86 0 0 1 .61-.25h-.05Zm.05-1.38a2.23 2.23 0 0 0-1.59.66l-1.29 1.24-1.25-1.24A2.24 2.24 0 0 0 38.12 49l1.24 1.25-1.24 1.24a2.242 2.242 0 0 0 3.17 3.17l1.25-1.24 1.24 1.24A2.25 2.25 0 1 0 47 51.52l-1.25-1.24L47 49a2.25 2.25 0 0 0-1.59-3.83l.01.03Z" fill="#10B981"/>
    </svg>
  )
}
