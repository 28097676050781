import React, { useEffect, Suspense, useState, Fragment } from "react";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import 'remixicon/fonts/remixicon.css'
import useAuth from "../src/Hooks/useAuth";
import { AuctionBidding, PostingAuction, User } from "@shobbak/react-services/dist/Entities";
import AppContext, { Env } from "../src/Services/AppContext";
import { Provider } from "react-redux";
import { store } from '../src/redux/store'
import shobbakSocket from '../src/shobbakSocket'
import { subscribeAuctionNewBidSocketEvent, subscribeAuctionReloadSocketEvent } from "../src/Components/Auction/subscribeAuctionNewBidSocketEvent";
import LiveScenario from "../src/Components/Auction/Scenarios/LiveScenario";
import AwardedScenario from "../src/Components/Auction/Scenarios/AwardedScenario";
import MyAuctionScenario from "../src/Components/Auction/Scenarios/MyAuctionScenario";
import CanceledScenario from "../src/Components/Auction/Scenarios/CanceledScenario";
import AwardedToMeScenario from "../src/Components/Auction/Scenarios/AwardedToMeScenario";
import MyAuctionAwardedScenario from "../src/Components/Auction/Scenarios/MyAuctionAwardedScenario";

interface Props {
  token: string;
  locale: string;
  auctionId: string|number;
  env: Env;
}

const SCREEN_NAME = 'PostingAuction';

const AuctionWidget = ({locale, token, auctionId, env}: Props) => {
  BackendCall.i({ token, locale })
  const { device } = useAuth({ token, locale })
  const [auction, setAuction] = React.useState<PostingAuction>();
  const auctionRef = React.useRef<PostingAuction>();
  const [isLoading, setIsLoading] = useState(true)
  const [bidDraft, setBidDraft] = React.useState<AuctionBidding>();

  const getBidDraft = (amount = 0) => {
    setIsLoading(true)
    BackendCall.i()
      .auctionBidActions(auctionId, 'draft', {amount})
      .then((res) => {
        setBidDraft(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };

  const getAuction = () => {
    setIsLoading(true)
    BackendCall.i()
      .getAuctionDetails(auctionId)
      .then((res) => {
        setAuction(res);
        auctionRef.current = res;
        setIsLoading(false)
        getBidDraft(res.startingAmount.value);
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };
  
  useEffect(() => {
    if (auctionId) {
      getAuction()
    }
  }, [auctionId]);

  useEffect(() => {
    if (auctionId) {
      shobbakSocket.connection()
      shobbakSocket.signin(device?.lastSeen?.token)
      shobbakSocket.joinChannel(SCREEN_NAME, auctionId);
      subscribeAuctionNewBidSocketEvent(auctionRef, (data) => {
        const {auction, currentBidAmount} = data;
        setAuction(auction);
        auctionRef.current = auction;
        getBidDraft(currentBidAmount.value);
      }, locale);
      subscribeAuctionReloadSocketEvent(() => {
        getAuction();
      });
    }

    return () => {
      auctionId && shobbakSocket.leaveChannel(SCREEN_NAME, auctionId);
    };
  }, []);

  const renderScenarios = () => {
    // Case when bottom sheet content is loading
    if (isLoading) {
      return <Loader />
    }
    // Case when bottom sheet content is not loadingView
    if (!isLoading && auction?.id) {
      const scenarios = {
        my_auction: 
          <MyAuctionScenario 
            auction={auction} 
            getAuction={getAuction}
          />,

        my_auction_awarded: 
          <MyAuctionAwardedScenario 
            auction={auction} 
          />,

        awarded_to_me: 
          <AwardedToMeScenario
            auction={auction}
          />,

        awarded: 
          <AwardedScenario
            auction={auction}
          />,

        canceled: 
          <CanceledScenario
            auction={auction}
          />,

        live: 
          <LiveScenario
            auction={auction} 
            bidDraft={bidDraft} 
            getBidDraft={getBidDraft}
            getAuction={getAuction}
          />,
      }

      return scenarios[auction?.currentScenario];
    }

    return <></>;
  }
  
  return (
    <Provider store={store}>
      <AppContext.Provider value={{ env }}>
        <Suspense fallback={Loader()}> 
          <div className="p-4">      
            {renderScenarios()}
          </div>
        </Suspense>
        </AppContext.Provider>
    </Provider>
  )
}

export default AuctionWidget;
