import {PostingOffer, PublishedPosting } from '@shobbak/react-services/dist/Entities';
import React, { Reducer, useContext, useEffect } from 'react'
import BackendCall, { HttpUnprocessableEntity } from '../BackendCall';
import { ChatChannel } from 'bulbul-chat'
import { RemoteItemLoaderState, RemoteItemLoaderAction, RemoteItemLoaderReducer, RemoteItemLoaderInitialState } from '../../Stores/RemoteItemLoaderStore';
import AppContext from '../../Services/AppContext';
import Translation from '../../helper/Translation';
import redirectToUrl from '../../../components/home-page/redirectToUrl';
import EventDispatcher from '../../helper/EventDispatcher';
import { toast } from 'react-toastify';
import SendOffer from '../../../components/SendOffer';
import { defaultImageObject } from '../../helper/posting';

export default function OfferButton(
  {
    channel,
    isChatDisabled
  }: {
    channel: ChatChannel, 
    isChatDisabled: boolean
  }
) {
  const appContext = useContext(AppContext);
  const [remotePosting, remotePostingDispatch] = React.useReducer<
    Reducer<
      RemoteItemLoaderState<PublishedPosting>,
      RemoteItemLoaderAction<PublishedPosting>
    >
  >(RemoteItemLoaderReducer, RemoteItemLoaderInitialState);
  const [remoteOffers, remoteOffersDispatch] = React.useReducer<
    Reducer<RemoteItemLoaderState<PostingOffer[]>, RemoteItemLoaderAction<any>>
  >(RemoteItemLoaderReducer, RemoteItemLoaderInitialState);

  const [isButtonLoading, setButtonLoading] = React.useState(false);

  const loadData = async () => {
    try {
      remotePostingDispatch({type: 'startLoading'});
      const remotePosting = await BackendCall.i().exploreShow(channel.metadata.postingId);

      remotePostingDispatch({type: 'loaded', item: remotePosting});
    } catch (error: any) {
      remotePostingDispatch({type: 'loadingFailed', error});
    }
  };

  useEffect(() => {
    window.addEventListener("posting_offer_updated", () => {
      fetchOffers()
    });
    return () => {
      window.removeEventListener("posting_offer_updated", () => {
      });
    };
  }, []);

  const fetchOffers = async () => {
    try {
      remoteOffersDispatch({type: 'startLoading'});
      const offers = await BackendCall.i().postingOffers({
        posting_id: channel.metadata?.postingId,
        buyer_id: channel.metadata?.buyerId,
      });

      remoteOffersDispatch({type: 'loaded', item: offers});
    } catch (error: any) {
      remoteOffersDispatch({type: 'loadingFailed', error});
    }
  };

  useEffect(() => {
    loadData()
    fetchOffers()
  }, [channel.id])

  const activeOfferExists = !!remoteOffers.item?.length;
  const canBuyNow = remotePosting.item?.offerMeta.canBuyNow;
  const canSendOffer = remotePosting.item?.offerMeta.canSendOffer;
  const isPostingOwner = remotePosting.item?.user.id === appContext.user?.id;
  const canMakeOffer = canBuyNow || canSendOffer;


  if (!activeOfferExists && (isPostingOwner || !canMakeOffer)) {
    return null;
  }

  if (isChatDisabled || !channel.metadata.postingId) {
    return null;
  }

  let title: string | null = null;
  let subtitle: string | null | undefined = null;
  const buttonData: {label: string; onPress: () => any} = {
    label: '',
    onPress: () => null,
  };

  if (activeOfferExists) {
    title = `SAR ${remoteOffers.item?.[0]?.offeredAmount}`;
    subtitle = remoteOffers.item?.[0]?.statusTitle;
  } else {
    if (canBuyNow) {
      title = `SAR ${remotePosting.item?.totalPrice}`;
      subtitle = '';
    } else {
      title = Translation.t('buttons.make_offer');
      subtitle = '';
    }
  }

  if (activeOfferExists) {
    buttonData.label = Translation.t('buttons.view_offer');
    buttonData.onPress = () =>
      redirectToUrl({redirectUrl: `/my_offers/${remoteOffers?.item?.[0]?.id || ''}`})
  } else {
    buttonData.label = canBuyNow
      ? Translation.t('buttons.buy_now')
      : Translation.t('buttons.send_offer');
    buttonData.onPress = async () => {
      if (remotePosting.item) {
        try {
          setButtonLoading(true);
          EventDispatcher.dispatch(canBuyNow ? 'buy-now-action' : 'open-send-offer-modal', {postingId: remotePosting.item.id});
        } catch (error) {
          console.log(error);

          let errorMessage = Translation.t('texts.operation_failed');
          if (error instanceof HttpUnprocessableEntity) {
            errorMessage = error.messageStr();
          }
          toast.error(errorMessage);
        } finally {
          setButtonLoading(false);
        }
      }
    };
  }

  return (
    <>
      <div className="bg-emerald-50 px-4 py-2 md:px-6 md:py-4 rounded-lg flex items-center">
        <div className="flex-1">
          <p className="text-emerald-500 md:text-lg">{title}</p>
          <p className="text-coolGray-700 text-xs md:text-base">{subtitle}</p>
        </div>
        <div className="flex-shrink-0">
          <button
            className="px-4 py-2 bg-emerald-500 rounded-lg text-white"
            onClick={buttonData.onPress}
            disabled={isButtonLoading}
          >{buttonData.label}</button>
        </div>
      </div>

      <SendOffer postingImage={remotePosting.item?.processedAssetUrls && defaultImageObject(remotePosting.item.processedAssetUrls).url} />
    </>
  )
}

