import React, { Suspense, useEffect, useState } from "react";
import BackendCall from "../src/Components/BackendCall";
import { UserResume } from "@shobbak/react-services/dist/Entities";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import EventDispatcher from "../src/helper/EventDispatcher";
import { ResumeHeader } from "./MyResumes";
import Divider from "../src/Components/Divider";
import Translation from "../src/helper/Translation";
import Loader from "../src/Components/icon/Loader";
import DateTime from "../src/helper/DateTime";
import { toast } from "react-toastify";

const SelectResumeModal = ({locale ,postingId, token, posting = {}}) => {
  const [userResumes , setUserResumes] = useState<UserResume[]>([]);
  const [isLoading , setIsLoading] = useState(false)
  const [isRendered ,setIsRendered]= useState(false)
  const [selectResume , setSelectResume] = useState({})

  const fetchUserResumes = async () => {
    BackendCall.i({ token, locale })
    .userResumeList()
    .then(response=>{
      setUserResumes(response)
      const selected = response.find((item)=> item.primary )
      setSelectResume(selected ? selected : {})
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }

  const submit = ()=> {
    setIsLoading(true)
    BackendCall.i({token , locale})
      .applyToJob({ posting_id:  postingId  , user_resume_id : selectResume?.id ,
        first_name: selectResume?.fullName,
        last_name: selectResume?.fullName.split(' ').slice(-1)[0]
      })
      .then(()=> {
        setIsLoading(false)
        toast.success(Translation.t('texts.appliedjob'))
        window.trackActivity({event: 'job_apply_submitted', rawPayload: {...posting}, shouldRedirect: false, callback: () => {
            setTimeout(()=> {
                window.location.reload()
            } ,200)
        }});

      }).catch(()=> {
      toast.success(Translation.t('texts.apply_job_fail'))
      setTimeout(()=> {
        window.location.reload()
      } ,200)

    })


  }

  useEffect(()=> {
    EventDispatcher.on('open-select-resume-modal' , async (event)=> {
     await fetchUserResumes()
      setIsRendered(true)
    })
  } , [])


  const children = ()=> {
    return (
      <div>
        <div className="flex flex-col  items-center md:p-6 p-4 justify-items-center mx-auto">
         <ResumeHeader />
          <Divider label={Translation.t("texts.or_choose_from_list")} darker containerStyle="my-6" />
          {isLoading && (<Loader />)}
          {!isLoading && userResumes.length == 0 && (
            <span className={`flex justify-center items-center`}>{Translation.t('posting.tabs.not_results')}</span>
          )}
          {!isLoading && userResumes  && (
            <div className="w-full max-h-96 px-2	overflow-y-auto  slider-scroll-style">
              {userResumes.map((item)=> {
               return (
                 <>
                 <div  onClick={()=> {
                   const userResumesTemp = userResumes.map((userResume) => ({...userResume , primary: userResume.id == item.id ? true : false}))
                   setSelectResume(item)
                   setUserResumes(userResumesTemp)
                 }}  className={`${item.primary ? 'bg-emerald-50' : 'bg-coolGray-50'} flex justify-between items-center p-4 border rounded-md w-full border-lg border-box mb-2 cursor-pointer`}>
                    <div className={`flex items-centers gap-4 w-full`}>
                       <div className={`${item.primary? 'bg-white': 'bg-coolGray-100'} rounded-md flex justify-center items-center w-10 h-10 `}>
                          <i className={`${item.primary? 'text-emerald-500': 'text-coolGray-500'} ri-file-word-2-line`} />
                       </div>
                      <div className={`flex flex-col gap-1`}>
                          <h2 className={`text-base leading-5 font-normal`}>{item?.resumeFileName}</h2>
                          <h2 className={`text-base leading-5 font-normal`}>{new DateTime(item?.createdAt).short()}</h2>
                      </div>
                    </div>
                   {item.primary &&(
                     <i className={`ri-checkbox-circle-line text-emerald-500 rx`} />
                   )}
                </div>
                 </>
               )
            })}
            </div>
          )}


        </div>


        {Object.keys(selectResume).length > 0 &&  (
        <div className="flex justify-center items-center w-full mx-auto px-2 py-4">
          <button
            onClick={submit}
            disabled={isLoading}
            className="hover:bg-emerald-700 active:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full text-center inline-block px-6 py-3 bg-emerald-500 text-white rounded-md cursor-pointer text-base leading-6 font-normal">
            {Translation.t('buttons.submit')}
          </button>
        </div>
        )}
      </div>
    );
  }

  if (!isRendered) return (<></>) ;
  return (
      <BaseModal onClose={()=> {
        setIsRendered(false)
        window.trackActivity({event: 'job_apply_abandoned', rawPayload: {...posting}, shouldRedirect: false});
      }}>
        {children()}
      </BaseModal>
      );

}

export default  SelectResumeModal ;