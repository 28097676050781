import qs from 'qs'
import merge from 'lodash/merge'
export const updateQueryString = (url: string, newParams= {}) => {
  if (typeof newParams === 'string') {
    newParams = qs.parse(newParams)
  }
  const oldParams = qs.parse(new URL(url).search.replace('?', ''))

  const params =  merge(oldParams, newParams)

  return url.split('?')[0] + '?' + qs.stringify(params, {encode: false, arrayFormat: 'brackets'})
}