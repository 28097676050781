import React, {useEffect} from 'react'
import {ChatService, useChannelsData} from 'bulbul-chat'
import SocketClient from "../src/shobbakSocket";
import shobbakSocket from "../src/shobbakSocket";
import { USER_STATUS_CHANNEL } from "../src/shobbakSocket/events";
import useAuth from "../src/Hooks/useAuth";

const indicator = document.getElementById("new-messages-indicator");

const showNotification = () => {
  const notification = new Notification('You received a new Message');
  notification.onclick = () => {
    notification.close();
    window.parent.focus();
  }
}

const requestNotificationPermission = async () => {
  if (Notification.permission === 'granted') return Promise.resolve(true)

  const permission = await Notification.requestPermission();
  return permission === "granted";
}

const sendBrowserNotification = () => {
  requestNotificationPermission().then(accepted => {
    if (accepted) {
      showNotification()
    }
  })

}

const initializeChatService = ({userToken, endpoint}) => {
  const chatService = ChatService.getService(
    userToken,
    endpoint
  );
  chatService.start()

  return chatService
}

const NewMessagesIndicatorContainer = ({chatService}) => {
  const [state] = useChannelsData(chatService);

  return state.channels.length > 0 ? <NewMessagesIndicator channels={state.channels}/> : null
}

const NewMessagesIndicator = ({channels}) => {

  const unreadMessageCount = channels.reduce(
    (prev, channel) => (channel.unreadMessageCount || 0) + prev,
    0,
  );
  const [defaultUnreadMessagesCount, setDefaultUnreadMessagesCount] = React.useState(unreadMessageCount)

  useEffect(() => {
    if (unreadMessageCount) {
      indicator.classList.remove('hidden')
    } else {
      indicator.classList.add('hidden')
    }

    if (unreadMessageCount > defaultUnreadMessagesCount) {
      sendBrowserNotification();
    }
    setDefaultUnreadMessagesCount(unreadMessageCount)
  }, [unreadMessageCount]);

  return <span style={{fontSize: unreadMessageCount > 99 ? 8 : 12}}>
    {unreadMessageCount > 99 ? '99+' : unreadMessageCount}
  </span>;
}

export default ({socketToken, userToken, endpoint , currentUserId , locale}) => {
  useEffect(()=> {
    shobbakSocket.connection()
    shobbakSocket.signin(socketToken)
    SocketClient.setIsShowOnlineStatusToOtherUsers(true)
  })

  if (!indicator) return;

  const chatService = initializeChatService({userToken, endpoint})

  return <NewMessagesIndicatorContainer chatService={chatService} /> // workaround because we can't use hook outside component
}
