import {eventChannel} from 'redux-saga';
import {call, put, takeEvery, take} from 'redux-saga/effects';

import directUpload from './services/directUpload';
import generateFileMetadata from './services/generateFileMetadata';
import {UploadActionTypes} from './upload.types';

function* uploadAddMetadata(action) {
  try {
    const fileMetadata = yield call(generateFileMetadata, action.data);
    yield put({
      type: UploadActionTypes.UPDATE_UPLOAD_METADATA,
      data: {uploadKey: action.data.uploadKey, ...fileMetadata},
    });
  } catch (e) {}
}

function uploadEmiter(upload) {
  return eventChannel((emit) => {
    directUpload(upload, (progressData) => emit(progressData))
      .then((result) => {
        emit({result});
      })
      .catch((error) => {
        emit({error});
      });
    return () => {};
  });
}

function* uploadFile(action) {
  try {
    yield put({
      type: UploadActionTypes.UPDATE_UPLOAD_PROGRESS,
      data: {
        uploadKey: action.data.uploadKey,
        uploading: true,
        progress: 0,
        status: 'uploading',
      },
    });
    const channel = yield call(uploadEmiter, action.data);
    while (true) {
      const {error, result, ...data} = yield take(channel);
      if (error) {
        yield put({
          type: UploadActionTypes.UPLOAD_FAILED,
          data: {uploadKey: action.data.uploadKey, error},
        });
        channel.close();
        return;
      }
      if (result) {
        action.data.onComplete && action.data.onComplete();
        yield put({
          type: UploadActionTypes.UPLOAD_COMPLETED,
          data: {uploadKey: action.data.uploadKey, result},
        });
        return;
      }
      yield put({
        type: UploadActionTypes.UPDATE_UPLOAD_PROGRESS,
        data: {uploadKey: action.data.uploadKey, ...data},
      });
    }
  } catch (e) {
    yield put({
      type: UploadActionTypes.UPLOAD_FAILED,
      data: {uploadKey: action.data.uploadKey, error: e},
    });
  }
}

function* uploadSaga() {
  yield takeEvery(UploadActionTypes.ADD_UPLOAD, uploadAddMetadata);
  yield takeEvery(UploadActionTypes.ADD_UPLOAD, uploadFile);
}

export default uploadSaga;
