import React, { useEffect, useState } from 'react';
import NotificationIcon from './NotificationIcon';
import BackendCall from '../../src/Components/BackendCall';

export default ({ token, locale }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    fetchLatestNotifications()

    const someNotificationsWasMarkedAsReadEventHandler = (event) => {
      fetchLatestNotifications()
    };

    const decrementUnreadNotificationsCountEventHandler = (event) => {
      setCount(prevCount => prevCount - 1)
    };

    // Listen for the 'decrement' events on window, because other components can clear notifications
    window.addEventListener('someNotificationsWasMarkedAsRead', someNotificationsWasMarkedAsReadEventHandler);
    window.addEventListener('decrementUnreadNotificationsCount', decrementUnreadNotificationsCountEventHandler);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('someNotificationsWasMarkedAsRead', someNotificationsWasMarkedAsReadEventHandler);
      window.removeEventListener('decrementUnreadNotificationsCount', decrementUnreadNotificationsCountEventHandler);
    };
  }, [])

  function fetchLatestNotifications() {
    BackendCall.i({ token, locale })
      .getNotifications({ page: { size: 1 }, with_stats: true })
      .then(res => {
        setCount(res?.meta?.notification_count)
      })
      .catch(err => console.log({ err }))
  }

  return (
    <div className="relative inline-block text-start">
      <a href="/notifications" className="relative inline-block">
        <NotificationIcon count={count} />
      </a>
    </div>
  )
};
