import React, { useEffect, useRef, useState } from 'react'
import { sidebar } from '../helper/Sidebar'
import BarCharIcon from "./icon/BarCharIcon";
import ImageIcon from "./icon/ImageIcon";
import OfferIcon from "./icon/OfferIcon";
import BookmarkIcon from "./icon/BookmarkIcon";
import FileListIcon from "./icon/FileListIcon";
import ContactPhoneIcon from "./icon/ContactPhoneIcon";
import PinDistanceIcon from "./icon/PinDistanceIcon";
import WalletIcon from "./icon/WalletIcon";
import EditIcon from "./icon/EditIcon";
import BankCardIcon from "../../src/Components/icon/BankCardIcon";
import NotificationIcon from "./icon/NotificationIcon";
import { ChevronDownIcon } from '@heroicons/react/solid'
import EventDispatcher from "../helper/EventDispatcher";
import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import StoreFill from './icon/StoreFill';
import StoreLine from './icon/StoreLine';
import 'remixicon/fonts/remixicon.css'
import BillIcon from './icon/BillIcon';

const ProfileSidebar = () => {
  const [groupSidebar, setGroupSideBar] = useState({});
  const [openSelect, setOpenSelect] = useState(false)
  const selectRef = useRef()
  const menuSelectRef = useRef()


  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!menuSelectRef.current || menuSelectRef?.current?.contains(event.target) || selectRef.current?.contains(event.target)) {
          return;
        }
        setOpenSelect(false)
      };
      EventDispatcher.on("mousedown", listener);
      EventDispatcher.on("touchstart", listener);
      return () => {
        EventDispatcher.remove("mousedown", listener);
        EventDispatcher.remove("touchstart", listener);
      };
    }, [menuSelectRef]
  );


  const redirect = (item) => {
    if (item.active) return;
    if (item.route) {
      window.location.href = item.route
    }
  }
  const toggleOpen = () => {
    setOpenSelect(!openSelect)
  }

  useEffect(() => {
    setGroupSideBar(groupBy(sidebar, (item) => item.group))
  }, [])


  const getCorrectIcon = (item) => {
    let defaultClass = 'fill-current w-4 h-4 flex items-center justify-center'
    defaultClass += item.active ? ' text-emerald-500' : ' text-coolGray-700'
    switch (item.icon) {
      case 'bar-icon':
        return (<BarCharIcon className={defaultClass} />)
      case 'image-icon':
        return (<ImageIcon className={defaultClass} />)
      case 'offer-icon':
        return (<OfferIcon className={defaultClass} />)
      case 'bookmark-icon':
        return (<BookmarkIcon className={defaultClass} />)
      case 'contact-phone-icon':
        return (<ContactPhoneIcon className={defaultClass} />)
      case 'pin-distance-icon':
        return (<PinDistanceIcon className={defaultClass} />)
      case 'wallet-icon':
        return (<WalletIcon className={defaultClass} />)
      case 'edit-icon':
        return (<EditIcon className={defaultClass} />)
      case 'bank-card-icon':
        return (<BankCardIcon className={defaultClass} />)
      case 'bill-icon':
        return (<BillIcon className={defaultClass} />)
      case 'notification-icon':
        return (<NotificationIcon className={defaultClass} />)
      case 'my-stores':
        return (<StoreLine className={defaultClass} />)
      case 'all-stores':
        return (<StoreFill className={defaultClass} />)
      default:
        return (<i className={`${defaultClass} ${item.icon}`} />)
    }
  }

  // @ts-ignore
  return (
    <>
      <div className="w-1/4 flex flex-col gap-2">
        { sessionStorage.getItem("should_completed_profile") == 'true' && (
          <div className="hidden md:flex w-full md:border border-b  border-orange-200  bg-orange-50 rounded-md p-2.5 flex gap-2 items-center text-orange-900">
            <i className={`ri-information-line ri-xl text-orange-900`} />
            <span className="text-sm leading-5 font-normal">{sessionStorage.getItem('completed_profile_text')}</span>
          </div>
        )}
        <div className="hidden md:flex w-full md:border border-b  border-gray-200  bg-white rounded-md">
          <div className="flex flex-col divide-y w-full">

            {Object.keys(groupSidebar).map((bar) => {
              return (
                <div key={bar} className="p-4 flex flex-col">
                  {bar != 'dashboard' ? <h2 className="text-start mb-2 text-xs leading-4 font-medium text-coolGray-400">{bar}</h2> : null}
                  <div className="flex flex-col gap-4">
                    {groupSidebar[bar].map((item) => {
                      const itemContent = (
                        <>
                          <span className="rounded-full w-8 h-8 flex items-center justify-center bg-coolGray-50">
                            {getCorrectIcon(item)}
                          </span>
                          <span
                            className={`text-base leading-6 font-normal ${item.active ? 'text-emerald-500' : 'text-coolGray-700'} `}
                            key={item.id}>{item.title}
                          </span>
                        </>
                      )

                      if (item.method) {
                        return (
                          <a className="flex gap-4 justify-start items-center cursor-pointer" href={item.route} data-method={item.method} key={item.id}>
                            {itemContent}
                          </a>
                        )
                      }

                      return (
                        <div className="flex gap-4 justify-start items-center cursor-pointer" onClick={() => {
                          redirect(item)
                        }} key={item.id}>
                          {itemContent}
                        </div>
                      )
                    })}
                  </div>

                </div>
              );
            })}


          </div>
        </div>

      </div>


      <div className="w-full flex flex-col gap-2 md:hidden">
        { sessionStorage.getItem("should_completed_profile") == 'true' && (
        <div className="w-full md:border border-b  border-orange-200  bg-orange-50 rounded-md p-2.5  gap-2 flex items-center text-orange-900  md:hidden">
          <i className={`ri-information-line ri-xl text-orange-900`} />
          <span className="text-sm leading-5 font-normal">{sessionStorage.getItem('completed_profile_text')}</span>
        </div>)}
        <div className="w-full md:border border-b  border-gray-200  bg-white rounded-md px-4 py-2 md:hidden block">
        <div className="mt-1 relative w-full">
          <button type="button"
            onClick={toggleOpen}
            ref={selectRef}
            className="relative w-full bg-white border border-white  pe-10 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm"
            aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">


            <div className="flex gap-4 justify-start items-center cursor-pointer">
              <span className="rounded-full w-8 h-8 flex items-center justify-center bg-coolGray-50">
                {getCorrectIcon(first(sidebar.filter((item) => item.active)))}
              </span>
              <span
                className={`text-base leading-6 font-normal text-emerald-500`}>{first(sidebar.filter((item) => item.active)).title}</span>

              <div className="absolute inset-y-0 end-0 flex items-center ps-2 pointer-events-none">
                <ChevronDownIcon className="h-5 flex-1 w-5 text-gray-400" />
              </div>
            </div>

          </button>

          {openSelect && (
            <ul
              ref={menuSelectRef}
              className="absolute z-40 mt-3 w-full bg-white shadow-lg max-h-60  py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">


              {sidebar.filter((item) => !item.active).map((item) => {
                let itemContent = (
                  <div className="flex gap-4 justify-start items-center cursor-pointer" onClick={() => {
                    redirect(item)
                  }}>
                    <span className="rounded-full w-8 h-8 flex items-center justify-center bg-coolGray-50">
                      {getCorrectIcon(item)}
                    </span>
                    <span
                      className={`text-base leading-6 font-normal text-coolGray-700`}>{item.title}
                    </span>
                  </div>
                )

                if (item.method) {
                  itemContent = (
                    <a className="flex gap-4 justify-start items-center cursor-pointer" href={item.route} data-method={item.method}>
                      <span className="rounded-full w-8 h-8 flex items-center justify-center bg-coolGray-50">
                        {getCorrectIcon(item)}
                      </span>
                        <span
                          className={`text-base leading-6 font-normal text-coolGray-700`}>{item.title}
                      </span>
                    </a>
                  )
                }

                return (
                  <li key={item.id} className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                    id="listbox-option-0"
                    role="option"
                  >
                    {itemContent}
                  </li>
                );
              })}
            </ul>
          )}

        </div>
      </div>
      </div>
    </>
  );

}


export default ProfileSidebar
