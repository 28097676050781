import React, { useEffect, useState } from 'react'
import SocketClient from "../src/shobbakSocket";
import { USER_STATUS_CHANNEL } from "../src/shobbakSocket/events";

const UserOnlineStatus = ({userId})=> {
  const [isUserOnline , setUserOnline] = useState<boolean>(false)

  useEffect(()=> {
    setTimeout(()=>  SocketClient.userStatusEvent((result)=> {
      if (result.channel === `${USER_STATUS_CHANNEL}/${userId}`) {
       setUserOnline(result?.payload?.isOnline);
      }
    }) , 0)


    SocketClient.joinChannel(USER_STATUS_CHANNEL, userId);

    return () => {
      SocketClient.leaveChannel(USER_STATUS_CHANNEL, userId);
    };


  } , [userId])

  const onlineSvg = ()=> {
    return ( <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#10B981"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5 10C5 12.7615 7.2385 15 10 15C12.7615 15 15 12.7615 15 10C15 7.2385 12.7615 5 10 5C7.2385 5 5 7.2385 5 10ZM10 12.5C8.89543 12.5 8 11.6046 8 10.5H12C12 11.6046 11.1046 12.5 10 12.5Z" fill="white"/>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#F9FAFB"/>
    </svg>)
  }


  const offlineSvg = ()=> {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#9CA3AF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 10C5 12.7615 7.2385 15 10 15C12.7615 15 15 12.7615 15 10C15 7.2385 12.7615 5 10 5C7.2385 5 5 7.2385 5 10ZM10 12.5C8.89543 12.5 8 11.6046 8 10.5H12C12 11.6046 11.1046 12.5 10 12.5Z" fill="white"/>
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#F9FAFB"/>
      </svg>
    )
  }

  return isUserOnline ? onlineSvg(): offlineSvg()
}

export default UserOnlineStatus