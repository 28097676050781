import React, {useEffect, useState, useRef} from "react";
import Translation from '../../../helper/Translation'
import BaseModal from "./BaseModal";
import {UserPhoneType} from "../UserMobile";
import MobileValidation from "../../../helper/MobileValidation";
import CountryCode from "../../../helper/countryCode";


interface MobilePhoneModalType {
    onClose: () => {};
    onSubmit: () => {};
    selectedMobilePhone: UserPhoneType;
    selectedCountry: {}
}

const MobilePhoneModal = ({selectedMobilePhone, onClose, onSubmit, selectedCountry}) => {


    const [countryCode, setCountryCode] = useState(CountryCode.available().filter((item) => item.isoCode3 == selectedCountry.code3)[0].countryCodes[0])
    const [isoCode, setIsoCode] = useState(selectedCountry.code3)
    const [number, setNumber] = useState(null)
    const [error, setError] = useState(null)
    const isMounted = useRef(false)


    useEffect(() => {
        if (isMounted.current) {
            validate()
        } else {
            isMounted.current = true
        }

    }, [countryCode, number])

    function validate() {
        const mobile = new MobileValidation(`+${countryCode}${number}`);
        if (!mobile.isValid()) {
            setError(Translation.t('errors.messages.invalid_mobile_number'))
        } else {
            setIsoCode(mobile.countryIso3)
            setError(null)

        }
    }

    function submit() {
        validate()
        if (error === null) {
            const country = isoCode;
            onSubmit({number: `+${countryCode}${number}`, country})
        }


    }

    return (
        <BaseModal onClose={onClose}>
            <div className="md:p-6 px-2 py-4">
                <div className="flex items-start">
                    <div
                        className="me-4 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-50">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 1C0 0.447715 0.447715 0 1 0H3.15287C3.64171 0 4.0589 0.353413 4.13927 0.835601L4.87858 5.27147C4.95075 5.70451 4.73206 6.13397 4.3394 6.3303L2.79126 7.10437C3.90756 9.87832 6.12168 12.0924 8.89563 13.2087L9.6697 11.6606C9.86603 11.2679 10.2955 11.0492 10.7285 11.1214L15.1644 11.8607C15.6466 11.9411 16 12.3583 16 12.8471V15C16 15.5523 15.5523 16 15 16H13C5.8203 16 0 10.1797 0 3V1Z"
                                fill="#10B981"/>
                        </svg>

                    </div>
                    <div className="text-start flex-1 flex-col">
                        <p className="text-base leading-6 font-semibold text-coolGray-900">{Translation.t('create_new_ad.ad_add_phone_no')}</p>
                        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('create_new_ad.add_phone_paragraph')}</p>
                    </div>
                    <div className="self-start cursor-pointer" onClick={onClose}>
                        <svg className="h-6 w-6 text-coolGray-400" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 24 24" stroke="none">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"/>
                        </svg>
                    </div>
                </div>

                <MobileInput containerClass={`mt-3`} number={number} countryCode={countryCode} setCountryCode={setCountryCode} setNumber={(number)=> { setNumber(number) }} />

                {error &&
                (<span className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{error} </span>)}

                <input disabled={error ? true : false} type="submit" name="commit"
                       value={Translation.t('create_new_ad.verify_number')}
                       onClick={submit}
                       className={`mt-4 ${error ? 'cursor-not-allowed' : 'cursor-pointer'} inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm`}
                       data-disable-with="Login"/>

            </div>

        </BaseModal>
    )
}

export const MobileInput = ({countryCode ,setCountryCode,number,containerClass= '',setNumber})=> {
    return (
      <div className={`flex flex-col ${containerClass} `}>
          <label htmlFor=""
                 className="text-sm leading-5 font-medium inline-flex flex-end  text-gray-700">{Translation.t('create_new_ad.ad_phone_number')}</label>
          <div
            className="flex mt-2 border rounded-md overflow-hidden focus-within:ring-2 focus-within:ring-emerald-500  shadow-sm focus:outline-none">
              <select required value={countryCode} onChange={(e) => {
                  setCountryCode(e.target.value)
              }} className="text-gray-500 sm:text-sm  border-gray-300 border-e border-t-0 bg-coolGray-50 text-base leading-6 text-coolGray-500 text-normal border-b-0 border-s-0 focus:ring-0 focus:outline-none
                   border-white focus:outline-none focus:border-emerald-500">
                  {CountryCode.available().map((item) => {
                      return (<option key={item.countryCodes[0]}
                                      value={item.countryCodes[0]}>{item.countryCodes[0]}</option>)
                  })}
              </select>
              <input type="text" value={number} required id="search"
                     onChange={(e) => {
                         setNumber(e.target.value)
                     }}
                     className="block px-3 w-full sm:text-sm border-0 border-white  focus:outline-none focus:ring-0 focus:border-white"/>
          </div>
      </div>
    );
}

export default MobilePhoneModal;