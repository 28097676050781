import { StatusGroups } from '@shobbak/react-services/dist/Entities';
import React, { useEffect, useState } from 'react';
import { useRemoteItem } from '../Hooks/useRemoteItem';
import BackendCall from './BackendCall';

import FilterButton from './FilterButton';
type Props = {
  onSelect?: (data) => void;
  type?: 'postingOffers' | 'posting' | 'invoices';
  containerStyle?: string;
};
const statusGroupPostingOfferLoader = () => BackendCall.i().getPostingOffersGroups();
const statusGroupPostingLoader = () => BackendCall.i().getPostingStatusGroups();
const statusGroupInvoicesLoader = () => BackendCall.i().getInvoicesStatusGroups();

const StatusGroupsComponent = ({
  onSelect,
  type = 'postingOffers',
  containerStyle,
}: Props) => {
  const [selectedStatusGroup, setSelectedStatusGroup] = useState({});
  const [statusGroups, loadStatusGroup] = useRemoteItem<StatusGroups[]>({
    dataLoader:
      type === 'postingOffers'
        ? statusGroupPostingOfferLoader
        : type === 'invoices' ? statusGroupInvoicesLoader : statusGroupPostingLoader,
  });

  useEffect(() => {
    loadStatusGroup();
  }, []);

  const onPressFilterButton = (key) => {
    let _selectedGroups = {};
    if (selectedStatusGroup[key]) {
      _selectedGroups = { ...selectedStatusGroup };
      delete _selectedGroups[key];
    } else {
      _selectedGroups = { ...selectedStatusGroup, [key]: key };
    }
    onSelect && onSelect(_selectedGroups);
    setSelectedStatusGroup(_selectedGroups);
  };
  return (
    <div className={`${containerStyle ?? ''} flex items-center py-2 space-s-2 overflow-x-auto hide-scroll`}>
      {statusGroups.item?.map((statusGroup) => {
        return (
          <FilterButton
            key={statusGroup.key}
            isSelected={selectedStatusGroup[statusGroup.key]}
            name={statusGroup.name}
            foregroundColor={statusGroup.foregroundColor}
            backgroundColor={statusGroup.backgroundColor}
            onPress={() => onPressFilterButton(statusGroup.key)}
          />
        );
      })}
    </div>
  );
};

export default StatusGroupsComponent;
