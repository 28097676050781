import React, { FC, useState, forwardRef, useImperativeHandle, Dispatch } from 'react'
import AddCircleFill from '../../icon/AddCircleFill'
import CloseCircle from '../../icon/CloseCircle'
import UserAddFill from '../../icon/UserAddFill'
import { BusinessProfileState } from '../../../../components/CreateBusinessProfile'
import Translation from '../../../helper/Translation'

interface BusinessControlProps {
  state: BusinessProfileState;
  dispatch: Dispatch<any>;
}

const BusinessControl: FC<BusinessControlProps> = forwardRef(({ state, dispatch }, ref) => {
  const [errorBag, setErrorBag] = useState({})

  function resetErrorBag() {
    setErrorBag({})
  }

  useImperativeHandle(ref, () => ({
    validateStep() {
      return new Promise((resolve, reject) => {
        let errors = {}
        state.emails.forEach((email, index) => {
          if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+(\.[A-Za-z]{2,})+$/.test(email.email) == false) {
            errors[index] = Translation.t('create_new_ad.invalid_email_address')
          }
        })

        if (Object.keys(errors).length == 0) {
          resolve(true)
        } else {
          setErrorBag(errors)
          reject(errors)
        }
      })
    }
  }))

  return (
    <div>
      {/* Invite Users */}
      <div className="mb-4">
        <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.invite_users_to_manage_your_profile')}</h3>
        <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.invite_detail')}</h4>
      </div>

      <div className="mb-4">
        <h5 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.employees')}</h5>
        <button className="w-full" onClick={() => {
          const emails = state.emails
          dispatch({ type: 'key_was_updated', payload: { key: 'emails', value: [...emails, ''] } })
        }}>
          <div className="flex items-center px-8 py-4 mt-2 rounded-lg border-2 border-dashed border-coolGray-300">
            <div className="w-10">

              <AddCircleFill className="fill-current text-coolGray-700" />
            </div>
            <div className="flex-1 flex flex-col px-2 text-start">
              <p className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('texts.business.add_employee')}</p>
              <p className="text-coolGray-500 text-sm leading-0 font-normal">{Translation.t('texts.business.add_employee_detail')}</p>
            </div>
          </div>
        </button>

        {/* Emails Repeater */}
        {state.emails.map((email, index) => {
          return (
            <div className="my-4" key={index}>
              <label htmlFor={`${email}-${index}`} className="block text-sm font-medium text-gray-300">
                {Translation.t('inputs.email.label')}
              </label>
              <div className="mt-1 flex rounded-md">
                <div className="relative flex items-stretch flex-grow rounded-md border border-coolGray-300 focus-within:border-coolGray-400">
                  <div className="absolute inset-y-0 start-0 ps-3 pe-3 bg-coolGray-50 flex items-center pointer-events-none rounded-s-md border-e border-coolGray-300">
                    <UserAddFill className="fill-current text-coolGray-700" />
                  </div>
                  <input
                    type="email"
                    name="email_contact"
                    id={`${email}-${index}`}
                    className="block w-full ps-10 ms-4 pe-10 sm:text-sm rounded-md border-0 focus:outline-none focus:ring-0"
                    value={email.email == undefined || email.email == '' ? '' : email.email}
                    onChange={event => {
                      let emails = state.emails.map((e, i) => {
                        if (i != index) return e
                        return { email: event.target.value }
                      })
                      dispatch({ type: 'key_was_updated', payload: { key: 'emails', value: emails } })
                      setErrorBag({})
                    }}
                  />
                  <div className="absolute inset-y-0 end-0 pe-3 flex items-center cursor-pointer rounded-md">
                    <button onClick={() => {
                      let emails = state.emails.filter((e, i) => i != index)
                      dispatch({ type: 'key_was_updated', payload: { key: 'emails', value: emails } })
                      setErrorBag({})
                    }}>
                      <CloseCircle className="fill-current text-coolGray-700" />
                    </button>
                  </div>
                </div>
              </div>
              <span className="text-red-500 text-sm">
                {errorBag[index]}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
})

export default BusinessControl