export interface PasswordRule {
  title: string;
  isValid: boolean
}
export default class PasswordInstructions {

  static rules(password, withPasswordConfirmation = false, passwordConfirmation = null): PasswordRule[] {
    let rules = [
      {
        title: 'Password must be at least 8 characters with no whitespace',
        // isValid: ((password)=> password.length >= 8)(password) ,
        isValid: ((password) => (password.replace(/\s/g, "").length >= 8))(password),
      },
      {
        title: 'Password must contain at least one letter',
        isValid: ((password) => (/[a-zA-Z]/.test(password)))(password),
      },
      {
        title: 'Password must contain at least one number',
        isValid: ((password) => (/\d/.test(password)))(password),
      },
      // {
      //   title: 'Don’t use spaces, the same character 3 times',
      //   isValid: ((password) => !(/(\w)(?=.*?\1{2,})/.test(password) || /\s/.test(password)))(password),
      // }
    ]

    if (withPasswordConfirmation) {
      rules.push({
        title: 'Password Confirmation not match ',
        isValid: ((password, passwordConfirmation) => password == passwordConfirmation)(password, passwordConfirmation),
      })
    }

    return rules
  }


  static upperAndLowerInText(password): boolean {
    return (/[A-Z]/.test(password)) && (/[a-z]/.test(password))
  }



}



