import React, { lazy, Suspense, useReducer, useState } from "react";
import Loader from "../src/Components/icon/Loader";
import ProfileLayout from "../src/Components/ProfileLayout";
import ProfileCard from "../src/Components/profile/ProfileCard"
import FraudSearch from './fraud/SearchFraud'
import ReportForm from './fraud/ReportForm'
import DisplaySearchResult from "./fraud/DisplaySearchResult";


type mode =
  | 'display_checking_result'
  | 'search'
  |'report'

export interface initialStateProps {
  number: string ;
  isBlocked: boolean ;
  mode: mode;
  countryCode: string
}

const initialState: initialStateProps = {
  number: null,
  isBlocked: false ,
  mode:'search',
  countryCode: '966'
}

export interface country {
  id: number
  code: string
  countryCode: string
}
interface FraudProps {
  locale: string;
  token: string;
  countries: country[]
}

export default ({ locale, token, countries }: FraudProps) => {
  const reducer = (state: initialStateProps, action) => {
    switch (action.type) {
      case 'update_key':
        if (action.payload instanceof Array) {
          const newState = { ...state }
          action.payload.forEach((item) => {
            newState[item.key] = item.value
          })
          return newState;
        }
        return { ...state, [action.payload.key]: action.payload.value };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={''}>
          {state.mode == 'search' && <FraudSearch countries={countries} token={token} dispatch={dispatch} locale={locale}/>}
          {state.mode == 'display_checking_result' && <DisplaySearchResult  state={state} dispatch={dispatch} locale={locale}/>}
          {state.mode == 'report' && <ReportForm token={token} state={state} dispatch={dispatch} locale={locale}/>}
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  )
}