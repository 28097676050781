import React, { lazy, Suspense } from "react";
import Loader from "../src/Components/icon/Loader";
const CreateUserResume = lazy(() => import("../src/Components/UserResume/CreateUserResume"))

export default ({ token, locale }) => {

  return (
    <Suspense fallback={Loader()}>
      <CreateUserResume
        locale={locale}
        token={token}
      />
    </Suspense>
  )
}

