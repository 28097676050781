import React, { useEffect, useRef, useState } from 'react'
import EventDispatcher from "../../helper/EventDispatcher";
import Translation from '../../helper/Translation'
import ShobbakLockIcon from '../icon/ShobbakLockIcon';

const ConfirmPasswordAlert = () => {
  const [show, setShow] = useState(false)
  const [disableAction, setDisableAction] = useState(false)
  const ref = useRef()
  const passwordRef = useRef(null)
  const [eventDetail, setEventDetail] = useState({})
  const [inputsData, setInputData] = useState({})
  const [error, setError] = useState('')


  useEffect(() => {
    EventDispatcher.on("confirm-password", ({ detail }) => {
      setEventDetail(detail);
      setDisableAction(false)
      setShow(true)
    });

    EventDispatcher.on("close-confirm-password-modal", () => {
      setShow(false)
    });

    EventDispatcher.on("confirm-password-error", ({ detail }) => {
      setError(detail.error)
    });

    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref?.current?.contains(event.target)) {
        return;
      }
      setShow(false)
    };
    EventDispatcher.on("mousedown", listener);
    EventDispatcher.on("touchstart", listener);
    return () => {
      EventDispatcher.on("mousedown", listener);
      EventDispatcher.on("touchstart", listener);
    };


  }, [ref]
  )

  const updateInputsValue = (key, value) => {
    setInputData((prev) => {
      return { ...prev, [key]: value }
    })
  }


  if (!show) {
    return (<></>)
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-44 text-center sm:block sm:p-0">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          ref={ref}
          className="inline-block align-bottom bg-white rounded-lg px-4 text-start overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-sm w-full p-4">

          <div className="flex justify-center flex-col gap-2 items-center">
            <ShobbakLockIcon />
            <h2 className="text-lg leading-7 font-semibold text-coolGray-700">{eventDetail.title}</h2>

            <p
              className="text-base leading-5 font-normal text-coolGray-500 inline-flex items-center text-center px-10">
              {eventDetail.paragraph}
            </p>

            <div className="relative mt-1 flex flex-col w-full px-2">
              <input className="form-input border border-coolGray-300 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                type="password"
                ref={passwordRef}
                placeholder={Translation.t('inputs.password.placeholder')}
                required={true}
                id="password"
                onChange={(e) => updateInputsValue("password", e.target.value)}
                name="password"
              />
              <i
                className={`ri-eye-line cursor-pointer absolute top-1/4 end-2 pb-1 fill-current text-coolGray-400 h-6 w-6`}
                onClick={() => passwordRef.current.type = (passwordRef.current.type == 'text' ? 'password' : 'text')}
              />
            </div>

            <div className="flex w-full px-2">
              <p className="text-red-500 text-sm">{error}</p>
            </div>

            <div className="grid grid-cols-2 gap-4 w-full px-2">

              <button type="button"
                onClick={() => {
                  setShow(false)
                  EventDispatcher.dispatch('discard', eventDetail)
                  setInputData({})
                }}
                className={`${disableAction ? 'cursor-not-allowed' : 'cursor-pointer'} w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm`}>
                {Translation.t('buttons.cancel')}
              </button>

              <button
                type="button"
                onClick={() => {
                  if (disableAction) return;
                  setError(null)
                  EventDispatcher.dispatch('confirm-password-request', { ...inputsData })
                  // setDisableAction(true)
                }}
                className={` ${disableAction ? 'cursor-not-allowed' : 'cursor-pointer'} w-full inline-flex items-center justify-center rounded-md border border-gray-300 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:text-sm`}>
                {eventDetail.display_action}

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default ConfirmPasswordAlert
