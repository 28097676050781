import { PaymentMethod } from '@shobbak/react-services/dist/Entities'
import Translation from '../src/helper/Translation';
import React from 'react';
import "remixicon/fonts/remixicon.css"
import Divider from '../src/Components/Divider';
import DateTime from "../src/helper/DateTime";

type PaymentMethodsDetailsProps = {
  paymentMethods: PaymentMethod[];
  currency?: string;
};

export default function PaymentMethodsDetails({
  paymentMethods,
  currency
}: PaymentMethodsDetailsProps) {
  const getIcon = (key) => {
    switch (key) {
      case 'card':
        return (
          <i className="text-emerald-500 w-5 h-5 ri-visa-line " />
        );

      case 'apple_pay':
        return (
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2468 0.708008C11.3668 1.78801 10.9368 2.87801 10.2868 3.65801C9.63678 4.43801 8.57679 5.04801 7.54678 4.96801C7.40678 3.90801 7.92678 2.79801 8.52678 2.10801C9.19678 1.32801 10.3168 0.748008 11.2468 0.708008ZM6.54519 5.68998C6.98201 5.86336 7.37164 6.018 7.68675 6.018C7.96357 6.018 8.32466 5.87609 8.75181 5.70822C9.43566 5.43946 10.2888 5.10414 11.2367 5.178C11.8267 5.218 13.5167 5.398 14.6167 7.008C14.5267 7.058 12.5967 8.178 12.6167 10.518C12.6467 13.318 15.0667 14.248 15.0967 14.258C15.0767 14.328 14.7067 15.588 13.8167 16.888C13.0467 18.018 12.2467 19.138 10.9867 19.158C10.3782 19.1727 9.97199 18.9972 9.54941 18.8146C9.1109 18.6251 8.65479 18.428 7.93675 18.428C7.17376 18.428 6.6937 18.633 6.23131 18.8304C5.83315 19.0004 5.44809 19.1649 4.90675 19.188C3.68675 19.228 2.76675 17.968 1.98675 16.848C0.396748 14.548 -0.813252 10.368 0.816748 7.538C1.62675 6.128 3.06675 5.238 4.63675 5.218C5.31309 5.20095 5.97004 5.4617 6.54519 5.68998Z" fill="#9CA3AF" />
          </svg>
        )

      case 'wallet':
        return (
          <i className="text-emerald-500 w-5 h-5 ri-wallet-2-fill" />
        );

      case 'shobbak_card':
        return (
          <i className="text-emerald-500 w-5 h-5 ri-wallet-fill" />
        );

      default:
        return (
          <i className="text-emerald-500 w-5 h-5 ri-visa-line" />
        );
    }
  };
  return (
    <div className="p-4">
      <p className="font-bold mb-4">
        {Translation.t('buttons.payment_methods')}
      </p>
      {paymentMethods?.map((method, index) => (
        <div key={index}>
          <div
            className="border border-coolGray-200 rounded-lg px-2 py-1 mb-2">
            <div
              className="flex items-center me-1">
              <div
                className="w-8 h-8 rounded-xl flex items-center justify-center bg-coolGray-50">
                {getIcon(method.key)}
              </div>

              <p
                className="flex-1 font-bold text-coolGray-700">
                {method.title}
              </p>
              <p className="font-bold text-coolGray-700">
                {currency ? `${currency} ` : ''}{method.amount}
              </p>
            </div>

            {method.transaction && (
              <div
                className="flex items-center ms-8">
                <p
                  className="text-coolGray-400 flex-1 me-2">
                  {new DateTime(method.transaction.time).short()}
                </p>
                <p
                  className="font-medium text-coolGray-500 flex-1">
                  {method.transaction.type}
                </p>
                <p
                  className="font-medium text-coolGray-500 flex-1">
                  {currency ? `${currency} ` : ''}{method.transaction.amount}
                </p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
