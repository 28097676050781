import React from 'react';

function StartAuction() {
	return (
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M69.5 36.17V20.36a5.11 5.11 0 0 0-3.91-5L39.67 9.12a5.11 5.11 0 0 0-6.31 5V64a5.11 5.11 0 0 0 6.31 5l25.93-6.33a5.11 5.11 0 0 0 3.91-5v-2" stroke="#FFD836"/>
			<path d="M69.5 41.71a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.56a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm-48.47-41.7 2.14-7 .31-1c.23-.73-.92-1-1.14-.32l-2.14 7-.31 1c-.23.73.91 1 1.14.32ZM2.79 11.8l6.71 2.88.94.4a.61.61 0 0 0 .81-.21.587.587 0 0 0-.037-.656.59.59 0 0 0-.173-.154l-6.71-2.88-.94-.41a.63.63 0 0 0-.81.21.61.61 0 0 0 .21.82Z" fill="#10B981"/>
			<path d="m14.32 10.38-2.23-6.94-.32-1c-.27-.72-1.37-.44-1.14.32l2.23 6.94.31 1c.24.72 1.38.41 1.15-.31v-.01Z" fill="#FFD836"/>
			<path d="M44.58 60.17V17.89l-31.41 8.58a2.5 2.5 0 0 0-1.84 2.41v21a2.5 2.5 0 0 0 1.88 2.42l31.37 7.87Z" fill="#fff" stroke="#10B981"/>
			<path d="M39.93 50.48v-23L17.3 31.9c-.78.1-1.32.42-1.32.78v13.2c0 .37.56.69 1.36.78l22.59 3.82Z" fill="#C2E9DB" stroke="#10B981"/>
			<path d="m44.58 17.89 5.39 1.24v39.66l-5.39 1.38V17.89Z" fill="#fff" stroke="#10B981"/>
			<path d="M20.24 35.29v6.99m2.12-3.5h-4.24m3.62-2.47-3 4.94m3 0-3-4.94m8.57-1.02v6.99m2.12-3.5h-4.24m3.62-2.47-3 4.94m3 0-3-4.94m8.6-1.02v6.99m2.12-3.5h-4.24m3.62-2.47-3 4.94m3 0-3-4.94" stroke="#10B981"/>
			<path d="M48.63 42.08a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-.64-.64a.26.26 0 0 1 .25.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .26-.26Zm0 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-.65-1.92a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm0 1.28a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Zm1.29 1.29a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.29 0a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm1.29-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-.64-.64a.26.26 0 0 1 .25.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .26-.26Zm0 1.29a.25.25 0 0 1 .25.25.26.26 0 0 1-.255.31.26.26 0 0 1-.255-.31.252.252 0 0 1 .161-.234.251.251 0 0 1 .1-.016Zm-.65-1.93a.26.26 0 0 1 .286.366.26.26 0 1 1-.286-.366Zm0 1.28a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Z" fill="#10B981"/>
			<path d="M48.63 42.08a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.29 0a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm1.29-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.28a.26.26 0 0 1 .184.444.26.26 0 1 1-.184-.444Zm-.64-.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.29a.26.26 0 1 1-.102.511.26.26 0 0 1 .102-.51Zm-.65-1.93a.26.26 0 1 1 .102.511.26.26 0 0 1-.102-.51Zm0 1.28a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Zm1.29 1.29a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.29 0a.26.26 0 0 1 .286.366.26.26 0 1 1-.286-.366Zm1.29-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.29a.26.26 0 0 1 .26.25.26.26 0 0 1-.52 0 .26.26 0 0 1 .26-.25Zm-.64-.66a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.3a.26.26 0 1 1-.102.511.26.26 0 0 1 .102-.511Zm-.65-1.93a.26.26 0 0 1 .26.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .25-.26Zm0 1.29a.248.248 0 0 1 .24.152.25.25 0 0 1 .02.098.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Z" fill="#10B981"/>
			<path d="M48.63 36.94a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.29 0a.26.26 0 1 1 .102.511.26.26 0 0 1-.102-.51Zm-1.28 5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm-1.28 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Zm-.64-.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.28a.26.26 0 1 1 0 .521.26.26 0 0 1 0-.521Zm-.64.65a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm-1.28-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 0 1 .286.366.26.26 0 1 1-.286-.366Zm-1.28 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Zm-.64-.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.29a.26.26 0 0 1 .26.25.26.26 0 0 1-.52 0 .26.26 0 0 1 .26-.25Z" fill="#10B981"/>
			<path d="M46.06 42.08a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1-.25.25.25.25 0 0 1 .25-.25Zm-1.28-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1 .102.511.26.26 0 0 1-.102-.51Zm-1.28 1.28a.26.26 0 1 1 0 .521.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1 .02.52.26.26 0 0 1-.02-.52Zm-.64-.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm0 1.29a.26.26 0 0 1 .044.516.259.259 0 0 1-.304-.266.26.26 0 0 1 .26-.25Zm-.64.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 0 1 .286.366.26.26 0 1 1-.286-.366Zm-1.28-5.14a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 0 1 .26.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .25-.26Zm-1.28 1.29a.26.26 0 0 1 .26.25.26.26 0 1 1-.52 0 .26.26 0 0 1 .26-.25Zm1.28 0a.248.248 0 0 1 .24.152.25.25 0 0 1 .02.098.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25ZM46.7 35a.26.26 0 1 1 0 .521.26.26 0 0 1 0-.521Zm0 1.3a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Z" fill="#10B981"/>
			<path d="M46.06 36.94a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm1.28 0a.26.26 0 1 1 .102.511.26.26 0 0 1-.102-.51Zm.58-5.79a.26.26 0 0 1 .26.25.26.26 0 1 1-.52 0 .26.26 0 0 1 .26-.25Zm0 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm.64-.64a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.28 0a.26.26 0 1 1-.1.51.26.26 0 0 1 .1-.51Zm1.28 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.28 0a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-.65-1.92a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Zm0 1.28a.26.26 0 0 1 .26.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .25-.26Zm1.29 1.29a.26.26 0 0 1 .26.25.26.26 0 0 1-.52 0 .26.26 0 0 1 .26-.25Zm-1.29 0a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Zm1.93-3.22a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm-1.28 0a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Z" fill="#10B981"/>
			<path d="M47.92 31.15a.26.26 0 0 1 .26.25.26.26 0 1 1-.52 0 .26.26 0 0 1 .26-.25Zm-1.29 0a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Z" fill="#10B981"/>
			<path d="M46.63 31.15a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Zm0 1.28a.26.26 0 0 1 .26.26.26.26 0 0 1-.51 0 .26.26 0 0 1 .25-.26Zm-.64-.64a.26.26 0 1 1 .102.511.26.26 0 0 1-.102-.51Zm0 1.28a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Zm.64.65a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Zm-.64-3.22a.26.26 0 1 1 0 .52.26.26 0 0 1 0-.52Z" fill="#10B981"/>
			<path d="M46.63 31.15a.25.25 0 0 1 .26.25.26.26 0 1 1-.51 0 .25.25 0 0 1 .25-.25Zm-.64 16.56a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm0-1.29a.26.26 0 1 1 .102-.51.26.26 0 0 1-.102.51Zm.65 1.93a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm0-1.29a.26.26 0 0 1-.194-.444.26.26 0 0 1 .444.194.25.25 0 0 1-.25.25Zm0-1.28a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm-.65 4.49a.25.25 0 0 1-.25-.25.26.26 0 0 1 .25-.26.26.26 0 0 1 .26.26.26.26 0 0 1-.26.25Zm0-1.27a.26.26 0 0 1-.25-.26.25.25 0 0 1 .25-.25.26.26 0 0 1 .26.25.26.26 0 0 1-.26.26Zm.65 1.92a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.52Zm0-1.29a.26.26 0 0 1-.286-.366.26.26 0 0 1 .436-.054.26.26 0 0 1-.15.42Z" fill="#10B981"/>
			<path d="M46.64 48.35a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm-.65 4.49a.26.26 0 1 1 .101-.509.26.26 0 0 1-.101.51Zm0-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm.65 1.93a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.519Zm0-1.29a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm0-1.28a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.52Zm-.65 4.49a.26.26 0 1 1 .114-.507.26.26 0 0 1 .102.453.26.26 0 0 1-.216.054Zm0-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm.65 1.92a.248.248 0 0 1-.24-.152.252.252 0 0 1-.02-.098.26.26 0 0 1 .366-.286.262.262 0 0 1 .144.286.25.25 0 0 1-.25.25Zm0-1.28a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Z" fill="#10B981"/>
			<path d="M46.64 53.49a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.519Zm1.28-5.14a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm1.28-1.29a.26.26 0 1 1 0-.519.26.26 0 0 1 0 .519Zm-1.28 0a.26.26 0 0 1-.194-.444.26.26 0 0 1 .444.194.25.25 0 0 1-.25.25Zm.64.65a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm1.28 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28-1.29a.26.26 0 1 1-.101-.512.26.26 0 0 1 .101.512Zm1.28 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-.64-.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm1.28 5.14a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.52Zm1.28-1.29a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 0 1-.286-.366.26.26 0 0 1 .436-.054.26.26 0 0 1-.15.42Zm.64.64a.26.26 0 0 1-.26-.25.26.26 0 0 1 .26-.26.26.26 0 0 1 .22.24.25.25 0 0 1-.22.27Zm1.28 0a.259.259 0 0 1-.26-.27.26.26 0 0 1 .52 0 .262.262 0 0 1-.158.25.259.259 0 0 1-.102.02ZM47.28 49a.26.26 0 0 1-.26-.26.26.26 0 0 1 .26-.25.25.25 0 0 1 .25.25.26.26 0 0 1-.25.26Zm1.28 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Z" fill="#10B981"/>
			<path d="M47.92 48.35a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm1.28 5.14a.26.26 0 1 1 0-.519.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.519Zm1.28-1.29a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm.64.64a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm1.28 0a.26.26 0 0 1-.254-.315.26.26 0 0 1 .36-.182.26.26 0 0 1-.106.497Zm-1.28-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm1.28 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-.64-.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.52Zm1.28 5.13a.26.26 0 0 1-.26-.25.26.26 0 0 1 .52 0 .26.26 0 0 1-.26.25Zm-1.28 0a.248.248 0 0 1-.24-.152.252.252 0 0 1-.02-.098.26.26 0 0 1 .366-.286.262.262 0 0 1 .144.286.25.25 0 0 1-.25.25Zm1.28-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Zm.64.64a.26.26 0 0 1-.286-.366.26.26 0 1 1 .286.366Zm1.28 0a.26.26 0 0 1-.254-.314.26.26 0 1 1 .254.314Zm-1.28-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm1.28 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Z" fill="#10B981"/>
			<path d="M47.92 53.49a.26.26 0 1 1 0-.519.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.519.26.26 0 0 1 .02.519ZM46.06 58a.26.26 0 0 1-.26-.25.26.26 0 1 1 .52 0 .26.26 0 0 1-.26.25Zm.64.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm0-1.29a.26.26 0 0 1-.044-.516.259.259 0 0 1 .304.266.26.26 0 0 1-.26.25Zm.64.65a.25.25 0 0 1-.25-.25.26.26 0 1 1 .51 0 .25.25 0 0 1-.26.25Zm-1.28-1.29a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm1.28 0a.26.26 0 0 1-.25-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.26.26ZM48.63 58a.26.26 0 0 1-.26-.25.26.26 0 1 1 .52 0 .26.26 0 0 1-.26.25Z" fill="#10B981"/>
			<path d="M47.34 58a.25.25 0 0 1-.25-.25.26.26 0 1 1 .51 0 .25.25 0 0 1-.26.25Zm.65.64a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm0-1.29a.26.26 0 1 1 .25-.25.25.25 0 0 1-.25.25Zm.64-.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.29 0a.26.26 0 0 1-.25-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.26.26Zm-1.42-35.98a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm0-1.29a.25.25 0 0 1-.25-.25.26.26 0 1 1 .51 0 .249.249 0 0 1-.26.25Zm.65 1.93a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm0-1.28a.26.26 0 0 1-.266-.304.26.26 0 1 1 .266.304Zm-.65 3.21a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm0-1.3a.26.26 0 0 1 0-.51.26.26 0 1 1 0 .51Zm.65 1.94a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Zm0-1.28a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Z" fill="#10B981"/>
			<path d="M46.57 21.37a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm-.65 4.5a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm0-1.29a.26.26 0 1 1 .101-.51.26.26 0 0 1-.101.51Zm.65 1.93a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm0-1.29a.248.248 0 0 1-.24-.152.25.25 0 0 1-.02-.098.26.26 0 1 1 .51 0 .25.25 0 0 1-.25.25Zm0-1.28a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Zm1.28-2.57a.26.26 0 0 1-.255-.31.26.26 0 1 1 .255.31Z" fill="#10B981"/>
			<path d="M46.57 21.37a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm1.28-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 0 1-.266-.304.26.26 0 1 1 .266.304Zm.64.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm1.29 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-.65 3.21a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Zm1.28-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm.64.64a.26.26 0 1 1 .067-.515.26.26 0 0 1-.067.515Zm1.29 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52ZM47.21 22a.26.26 0 0 1-.044-.516.259.259 0 0 1 .304.266.26.26 0 0 1-.26.25Zm1.29 0a.26.26 0 1 1 .102-.51.26.26 0 0 1-.102.51Zm-.65-.63a.26.26 0 0 1-.255-.31.26.26 0 1 1 .255.31Z" fill="#10B981"/>
			<path d="M46.57 21.37a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm1.28 5.14a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm1.28-1.29a.26.26 0 0 1-.26-.25.26.26 0 0 1 .52 0 .26.26 0 0 1-.26.25Zm-1.28 0a.248.248 0 0 1-.24-.152.25.25 0 0 1-.02-.098.26.26 0 1 1 .51 0 .25.25 0 0 1-.25.25Zm.64.65a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Zm1.29 0a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.29-1.29a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Zm1.29 0a.26.26 0 1 1 .1-.509.26.26 0 0 1-.1.509Zm-.65-.64a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm-1.28 0a.26.26 0 0 1-.26-.26.26.26 0 0 1 .51 0 .26.26 0 0 1-.25.26Zm-.58 5.79a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Zm0-1.28a.26.26 0 0 1-.184-.444.26.26 0 1 1 .184.444Zm.64.64a.26.26 0 1 1 .101-.509.26.26 0 0 1-.101.51Zm0-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm.65 1.92a.26.26 0 1 1 .25-.25.25.25 0 0 1-.25.25Zm0-1.28a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm-1.29-1.29a.26.26 0 0 1-.26-.25.26.26 0 1 1 .52 0 .26.26 0 0 1-.26.25Zm1.29 0a.252.252 0 0 1-.24-.152.25.25 0 0 1-.02-.098.26.26 0 0 1 .366-.286.26.26 0 0 1 .144.286.25.25 0 0 1-.25.25Zm-.65 3.22a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Z" fill="#10B981"/>
			<path d="M45.99 29.73a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Zm1.29 0a.26.26 0 1 1 .25-.25.25.25 0 0 1-.25.25Zm1.28 0a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Z" fill="#10B981"/>
			<path d="M47.28 29.73a.26.26 0 1 1 .25-.25.25.25 0 0 1-.25.25Zm1.28-1.28a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Zm-1.28 0a.26.26 0 1 1-.02-.52.26.26 0 0 1 .02.52Zm.64.64a.26.26 0 1 1-.101-.511.26.26 0 0 1 .101.511Zm0-1.28a.26.26 0 1 1 0-.52.26.26 0 0 1 0 .52Zm.64-.65a.26.26 0 0 1-.26-.25.26.26 0 1 1 .52 0 .26.26 0 0 1-.26.25Zm-1.28 0a.252.252 0 0 1-.24-.152.25.25 0 0 1-.02-.098.26.26 0 0 1 .366-.286.26.26 0 0 1 .144.286.25.25 0 0 1-.25.25Zm.64 3.22a.262.262 0 0 1-.26-.26.259.259 0 0 1 .26-.26.26.26 0 0 1 0 .52Zm.64-.65a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521Z" fill="#10B981"/>
			<path d="M47.28 29.73a.26.26 0 1 1 .25-.25.25.25 0 0 1-.25.25Z" fill="#10B981"/>
		</svg>
	);
}

export default StartAuction;
