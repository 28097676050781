import React, { useEffect } from 'react'

const EditIcon = (props) => {
  return (

  <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 12H4.82867L12.452 4.37664C12.7123 4.11631 12.7123 3.69431 12.452 3.43397L10.566 1.54797C10.3057 1.28772 9.88367 1.28772 9.62333 1.54797L2 9.17131V12ZM14 13.3333H2V14.6667H14V13.3333Z"/>
    </svg>

  );
}


export default EditIcon