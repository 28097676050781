import React, { useState } from 'react'
import { Business } from '@shobbak/react-services/dist/Entities'
import Translation from '../../helper/Translation'
import BackendCall from '../BackendCall'
import AlertFill from '../icon/AlertFill'
import ArrowLeftRight from '../icon/ArrowLeftRight'
import CheckFill from '../icon/CheckFill'
import CloseFill from '../icon/CloseFill'
import ErrorWarningFill from '../icon/ErrorWarningFill'
import RateStars from '../../../components/RateStars'

interface Props {
  token: string;
  businessProfile: Business;
  showSwitchAccountsModal: () => void;
}

export default function BusinessAccountInfo({ token, businessProfile, showSwitchAccountsModal }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  function myBusinessProfileIsReady() {
    setIsLoading(true)
    BackendCall.i()
      .myBusinessProfileIsReady(businessProfile.id)
      .then(() => window.location.reload())
      .catch(() => setIsLoading(false))
  }

  function getAvatarIconColor() {
    if (businessProfile.status == "published") {
      return 'ring-emerald-50 bg-emerald-500'
    } else if (businessProfile.status == "rejected") {
      return 'ring-red-50 bg-red-500'
    }
    return 'ring-orange-50 bg-orange-500'
  }

  function renderAvatarIcon() {
    if (businessProfile.status == "published") {
      return <CheckFill className="fill-current text-white" width="14" height="14" />
    } else if (businessProfile.status == "rejected") {
      return <CloseFill className="fill-current text-white" width="14" height="14" />
    }
    return <AlertFill className="fill-current text-white" width="12" height="12" />
  }

  function renderStatusDetail() {
    const mappings = {
      published: {
        title: Translation.t('texts.account_verified'),
        bgColor: 'bg-emerald-50',
        textColor: 'text-emerald-500',
        icon: <span className="flex items-center justify-center rounded-full bg-emerald-500"><CheckFill className="fill-current text-emerald-50" /></span>
      },
      rejected: {
        title: Translation.t('texts.business.reasons_for_rejection'),
        description: businessProfile.rejectReason,
        bgColor: 'bg-red-50',
        textColor: 'text-red-500',
        icon: <span className="flex items-center justify-center rounded-full bg-red-50"><ErrorWarningFill className="fill-current text-red-500" /></span>
      },
      submitted_for_review: {
        title: Translation.t('texts.account_under_review'),
        description: Translation.t('texts.account_under_review_description'),
        bgColor: 'bg-orange-50',
        textColor: 'text-orange-500',
        icon: <span className="flex items-center justify-center rounded-full bg-orange-50"><AlertFill className="fill-current text-orange-500" /></span>
      }
    }

    if (mappings[businessProfile.status] == null) return
    const status = mappings[businessProfile.status]

    return (
      <div className={`rounded-md ${status.bgColor} p-4`}>
        <div className="flex items-center">
          {status.icon}
          <div className="ms-3">
            <h3 className={`text-sm leading-5 font-medium ${status.description ? '-mb-2' : ''} ${status.textColor}`}>{status.title}</h3>
            {status.description ?
              <div className={`mt-2 text-sm ${status.textColor}`}>
                <p className="text-xs leading-0 font-normal text-coolGray-500">{status.description}</p>
              </div> : null
            }
          </div>
        </div>
      </div>
    )
  }

  function renderSubmitProfileButton() {
    if (businessProfile.status != "initiated") return

    return (
      <button disabled={isLoading} onClick={myBusinessProfileIsReady} className="w-full h-12 px-8 py-3 shadow-sm rounded-md bg-orange-50 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-100">
        <span className="text-coolGray-700 text-base leading-0">{Translation.t('buttons.submit_business_profile_for_review')}</span>
      </button>
    )
  }

  function renderBusinessSwitch() {
    return (
      <div className="flex-col">
        <div className="mb-4 bg-coolGray-700 p-4 flex flex-col rounded-md">
          <p className="mb-2 text-lg leading-6 font-medium text-white">{Translation.t('texts.create_another_business_account')}</p>
          <a href="/business_profiles">
            <p className="mb-4 text-sm leading-0 font-normal text-coolGray-400">{Translation.t('texts.you_can_create_another_business_account')}</p>
          </a>
          <a href="/business_profiles/new">
            <button className="w-full h-12 px-8 py-3 shadow-sm rounded-md bg-white hover:bg-coolGray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coolGray-100">
              <span className="text-base leading-6 text-center font-semibold text-coolGray-700">{Translation.t('texts.open_new_business_account')}</span>
            </button>
          </a>
        </div>

        <button className="w-full" onClick={() => showSwitchAccountsModal()}>
          <div className="flex justify-between items-center p-4 bg-orange-50 rounded-md hover:bg-orange-100">
            <p className="text-coolGray-700 text-base leading-6">{Translation.t('buttons.profile_switch_accounts')}</p>
            <div className="bg-white flex items-center justify-center h-8 w-8 rounded-full object-cover">
              <ArrowLeftRight className="fill-current text-orange-500" />
            </div>
          </div>
        </button>
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-col py-4 space-y-4 rounded-lg">
        {/* <div className="flex items-start space-s-4">
          <span className="inline-block relative">
            <img className="h-12 w-12 object-cover rounded-full" src={businessProfile.logo} />
            <span className={`absolute bottom-0 right-0 flex items-center justify-center h-4 w-4 rounded-full ring-4 ${getAvatarIconColor()}`}>
              {renderAvatarIcon()}
            </span>
          </span>
          <div className="font-medium text-lg leading-6 space-y-1">
            <h3 className="text-xl leading-7 text-coolGray-700 font-semibold -mb-2">{businessProfile.name}</h3>
            <span className="text-xs leading-4 font-normal text-coolGray-400">{Translation.t('texts.business.business_account')}</span>
          </div>
        </div> */}
        <div className={`flex flex-col space-y-2 ${businessProfile.about ? 'border-b border-coolGray-100 pb-4' : ''}`}>
          <div className="mb-12 relative h-36 rounded-t-lg border-b border-coolGray-200 bg-no-repeat bg-cover bg-center cursor-pointer" style={{ backgroundImage: `url(${businessProfile.coverImage})` }}>
            <div className="absolute bottom-0 mx-4 -my-12">
              <img className="rounded-full object-cover h-24 w-24" src={businessProfile.logo} />
              <span className={`absolute bottom-0 right-0 flex items-center justify-center h-4 w-4 rounded-full ring-4 ${getAvatarIconColor()}`}>
                {renderAvatarIcon()}
              </span>
            </div>
          </div>
          <div className="mt-4 ms-4 font-medium text-lg leading-6 space-y-1">
            <h3 className="text-xl leading-7 text-coolGray-700 font-semibold -mb-2">{businessProfile.name}</h3>
            <span className="text-xs leading-4 font-normal text-coolGray-400">{businessProfile.businessTypeDetail.name}</span>
            <a href="/dashboard/reviews">
              <RateStars score={businessProfile.rateAvg} />
            </a>
          </div>
          {renderStatusDetail()}
        </div>
        {businessProfile.about ?
          <div>
            <h4 className="text-coolGray-700 text-base leading-6 font-semibild">{Translation.t('texts.business.about')}</h4>
            <p className="text-coolGray-500 text-sm leading-5 font-normal">{businessProfile.about}</p>
          </div> :
          null
        }
        {renderSubmitProfileButton()}
      </div>
      {renderBusinessSwitch()}
    </div>
  )
}
