// Imports: Dependencies
import {all, fork} from 'redux-saga/effects';

// Imports: Redux Sagas
import uploadSaga from './uploadReducer/upload.saga';

// Redux Saga: Root Saga
export function* rootSaga() {
  yield all([fork(uploadSaga)]);
}
