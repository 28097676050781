import React, { lazy, Suspense, useMemo } from "react";
import OfferButton from "../src/Components/Chat/OfferButton";
import OtpModal from "../src/Components/EditProfile/OtpModal";
import RequestProfileVerifyModal from "../src/Components/EditProfile/RequestProfileVerifyModal";
import Loader from "../src/Components/icon/Loader";
import useAbsher from "../src/Hooks/useAbsher";
import { ChatChannel } from 'bulbul-chat'
import AppContext from "../src/Services/AppContext";
import Translation from "../src/helper/Translation";
import ChatAlert from "../src/Components/Chat/ChatAlert";
import EventDispatcher from "../src/helper/EventDispatcher";
const App = lazy(() => import("@shobbak/bulbul-chat-web"))

export default ({ authUser, userToken, jwtToken, basename, endpoint, locale, isVerified, shouldVerifyWithAbsher }) => {
  const { isVerificationModalVisible, isOtpModalVisible, absherVerificationMethod, updateVerificationMethods, afterVerificationMethodUpdated, resendVerificationCode } = useAbsher({ token: jwtToken, locale, isVerified, shouldVerifyWithAbsher });

  return (
    <AppContext.Provider value={{ user: authUser.user }}>
      <Suspense fallback={Loader()}>
        {isVerificationModalVisible &&
          <RequestProfileVerifyModal
            onSuccess={(method) => afterVerificationMethodUpdated(method)}
            updateMethods={(method) => { updateVerificationMethods(method) }}
            locale={locale}
            token={jwtToken}
            methodPayload={absherVerificationMethod}
            canClose={false}
            onClose={() => { }} />

        }

        {isOtpModalVisible &&
          <OtpModal
            locale={locale}
            token={jwtToken}
            methodPayload={absherVerificationMethod}
            resendVerificationCode={(methodPayloadData) => resendVerificationCode(methodPayloadData)}
            title={absherVerificationMethod.pageTitle}
            onClose={() => { }}
            canClose={false}
            onSuccess={(methodPayload) => {
              window.location.reload()
            }} />
        }

        <div className="mb-5 chat-app w-full h-full">
          <App
            userToken={userToken}
            basename={basename}
            endpoint={endpoint}
            locale={locale}
            chatAlertComponent={() => <ChatAlert />}
            beforeChatFormComponent={(channel: ChatChannel, isChatDisabled: boolean) => <OfferButton channel={channel} isChatDisabled={isChatDisabled} />}
            additionalChatActions={[
              {
                title: Translation.t('buttons.black_list'),
                action: () => window.open('/frauds', '_blank')
              },
              {
                title: Translation.t('buttons.report_user'),
                action: (otherUserMembership) => EventDispatcher.dispatch('report-entity', otherUserMembership.user.externalId)
              }
            ]}
            quickReplies={[
              Translation.t('discussion.hello'),
              Translation.t('discussion.good'),
              Translation.t('discussion.ok'),
              Translation.t('discussion.no'),
              Translation.t('discussion.thanks'),
              Translation.t('discussion.still_available'),
              Translation.t('discussion.how_much'),
              Translation.t('discussion.waiting_reply'),
              Translation.t('discussion.waiting_offer_acceptance'),
            ]}
          />
        </div>
      </Suspense>
    </AppContext.Provider>
  )
}

