import React, {useState} from 'react';
import { toast } from 'react-toastify';
import { ApplePay } from '../../helper/ApplePay';
import EventDispatcher from '../../helper/EventDispatcher';
import Translation from '../../helper/Translation';
import BackendCall, { HttpUnprocessableEntity } from '../BackendCall';

import PaymentButtons from '../PaymentButtons';
import { PaymentMethodName } from '../PaymentMethodsList';

type Props = {
  draft: any;
  amountValue: string;
  redirectUrl?: string;
  onSuccess?: () => void;
  onError?: (message: string) => void;
  onUpdate?: (newOffer: any) => void;
};

export type HandlePaymentParams = {
  card_id?: string;
  payment_handler: PaymentMethodName;
  payment_method: any;
  payment_data?: any;
  transaction_data?: any;
  card_cvv?: string;
  charge_wallet_too?: boolean;
  redirect_url?: string;
};

const PaymentAction = ({
  draft,
  amountValue,
  redirectUrl,
  onSuccess,
  onError,
  onUpdate,
}: Props) => {
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);

  React.useEffect(() => {
    const listener = EventDispatcher.on(
      'payment-callback',
      async ({params}) => {
        setIsProcessingPayment(false);
        if (
          params?.payment_result === 'failed' ||
          params?.payment_result === 'cancelled'
        ) {
          const message = Translation.t('texts.offer_update_failed');
          toast.error(message);
          return onError?.(message);
        }

        toast.success(
					Translation.t('texts.offer_updated')
				);
        onSuccess?.();
      },
    );
    return () => {
      if (typeof listener === 'string') {
        EventDispatcher.remove('payment-callback', listener);
      }
    };
  }, []);

  const handlePayment = async (data: HandlePaymentParams) => {
    if (data.payment_handler === 'web_pay') {
      setIsProcessingPayment(true);
      if (draft.webPaymentUrl) {
        window.location.href = draft.webPaymentUrl
      }
      setIsProcessingPayment(false);
    } else {
      setIsProcessingPayment(true);
      try {
        const createdRequest = await BackendCall.i().rechargeWalletCreate({
          amount: amountValue,
        });
        if (redirectUrl) {
          data.redirect_url = redirectUrl
        }
        const newDraft = await BackendCall.i().rechargeWalletPay(
          createdRequest.id,
          {
            ...data,
          },
        );
        if (
          newDraft.payment3Ds.shouldRedirect &&
          data.payment_handler === 'card_pay'
        ) {
          window.location.href = newDraft.payment3Ds.url
        } else {
          setIsProcessingPayment(false);
          if (data.payment_handler === 'apple_pay') {
            ApplePay.complete(ApplePay.SUCCESS);
          }
          //update draft
          toast.success(
            Translation.t('texts.offer_updated')
          );
          onSuccess?.();
        }
      } catch (error) {
        setIsProcessingPayment(false);
        if (data.payment_handler === 'apple_pay') {
          ApplePay.complete(ApplePay.FAILURE);
        }
        let message = Translation.t('texts.offer_update_failed');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message);
        onError?.(message);
      } finally {
        setIsProcessingPayment(false);
      }
    }
  };

  const handleError = (message) => {
    setIsProcessingPayment(false);
    toast.error(message);
    onError?.(message);
  };

  const {
    cardPayAllowed,
    applePayAllowed,
    webPayAllowed,
    walletPayAllowed,
    canPayByWallet,
  } = draft;
  return (
    <div className="flex-1">
      <PaymentButtons
        size="sm"
        amount={amountValue}
        offerAmount={amountValue}
        deliveryFee={0}
        currency={draft?.currency}
        paymentOptions={draft ? draft.paymentOptions : []}
        onSuccess={handlePayment}
        onFailure={handleError}
        isProcessingPayment={isProcessingPayment}
        onStartPayment={() => setIsProcessingPayment(true)}
        readyToPay
        walletPayAllowed={walletPayAllowed}
        cardPayAllowed={cardPayAllowed}
        applePayAllowed={ApplePay.isAvailable() && applePayAllowed}
        canPayByWallet={canPayByWallet}
        webPayAllowed={!cardPayAllowed && !(ApplePay.isAvailable() && applePayAllowed) && webPayAllowed}
        lineItems={[]}
        withWalletLineItems={[]}
        messages={[]}
      />
    </div>
  );
};

export default PaymentAction;
