import { transformKeysToCamelCase } from "../../helper/Object";
import shobbakSocket from '../../shobbakSocket';
const auctionNewBidEventType = 'posting_auction_new_bid';
const auctionReloadEventType = 'posting_auction_reload';

export const subscribeAuctionNewBidSocketEvent = (auctionRef, callback, currentLang = 'en') => {
  shobbakSocket.subscribeEvent(auctionNewBidEventType, (data) => {
    const cammelizeData = transformKeysToCamelCase(data.payload);
    let {lastBid, currentBidAmount, ...rest} = cammelizeData;
    currentBidAmount = currentBidAmount[currentLang];
    lastBid.biddingAmount = lastBid.allLocaleBiddingAmount[currentLang];
    delete lastBid['allLocaleBiddingAmount'];
    let postingAuctionBids: any[] = auctionRef.current?.postingAuctionBids;
    const index = postingAuctionBids.findIndex(
      (pAuctionBid) => pAuctionBid?.id == lastBid.id,
    );
    if (index === -1) {
      postingAuctionBids = [lastBid].concat(postingAuctionBids);
    }
    const auction = {
      ...auctionRef.current,
      currentBidAmount,
      postingAuctionBids,
      ...rest,
    };
    callback({
      auction,
      currentBidAmount,
    });
  });
};

export const subscribeAuctionReloadSocketEvent = (callback) => {
  shobbakSocket.subscribeEvent(auctionReloadEventType, (_data) => {
    callback();
  });
};