import React, { useEffect, useState } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import Geocode from "react-geocode";

// fore reference: https://github.com/fullstackreact/google-maps-react/issues/494
function MapWithMarker({ googleMapsApiKey, onDragEnd, lat = null, lon = null }) {
  const [google, setGoogle] = useState();
  const [mapLat, setMapLat] = useState(lat ? lat : 26.27944);
  const [mapLng, setMapLng] = useState(lon ? lon : 50.20833);

  function onDragend(_, __, e) {
    Geocode.setApiKey(googleMapsApiKey)
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng())
      .then(
        (response) => {
          const responseAddress = response.results[0].formatted_address;
          onDragEnd({ lat: e.latLng.lat(), lon: e.latLng.lng(), address_line: responseAddress })
        },
        (error) => {
          console.error(error);
          onDragEnd({ lat: e.latLng.lat(), lon: e.latLng.lng(), address_line: '' })

        }
      );
  }

  function Wrapper(props) {
    useEffect(() => {
      props.onChange(props.google);
    }, [props.google]);

    return null;
  }

  const GoogleProvider = GoogleApiWrapper({
    apiKey: googleMapsApiKey
  })(Wrapper);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <GoogleProvider onChange={(google) => setGoogle(google)} />
      <Map
        zoom={9}
        google={google}
        initialCenter={{
          lat: mapLat,
          lng: mapLng
        }}
      >
        <Marker draggable name="Your Address" onDragend={onDragend} />
      </Map>
    </div>


  );
}

export default MapWithMarker;