import FaqItem from "./FaqItem";
import React, { useEffect, useState } from "react";
import BackendCall, { FaqType } from "../src/Components/BackendCall";
import Translation from "../src/helper/Translation"


interface FaqProps {
  id: string ;
  title: string;
  description: string;
}

interface FaqsProps {
  section: string ;
  locale: string;
  token: string
}

function Faqs({ section, locale , token }: FaqsProps) {
  const [faqs , setFaqs] = useState<FaqProps[]>([])

  useEffect(()=> {
    BackendCall.i({token: token , locale: locale}).getFaqs({ section: section }).then((faqs)=> {
      setFaqs(faqs.map((faq)=> {
        return {title: faq.question , id: faq.id , description: faq.answer}
      }))
    }).catch(()=> {
      setFaqs([])
    })
  } , [])
  return (
    <div className="flex px-6 py-4 flex flex-col gap-4 w-full items-start">
      <p>{Translation.t("frauds.labels.faqs")}</p>
      <div className="grid grid-cols-1 divide-y divide-coolGray-50 w-full">
        {faqs.map(faq => <FaqItem key={faq?.id} title={faq.title} description={faq.description} />)}
      </div>
    </div>

  );
}

export default Faqs