import React from 'react';
import Translation from '../helper/Translation'
import 'remixicon/fonts/remixicon.css'
import { Hooks } from '@shobbak/react-services';
import DateTime from "../helper/DateTime";
const { useTimer } = Hooks

const ExpireDate = ({
  text,
  iconClasses = '',
  textClasses = ''
}) => {
  const expiry = new DateTime(text).utc();
  const { days, hours, secondsLabel, minutesLabel, makeTwoNumber, isRunning } =
    useTimer({
      expiryTimestamp: expiry,
    });
  const totalHours = makeTwoNumber(days * 24 + hours);

  if (!text) return <></>

  return isRunning ? (
    <div className="flex items-center justify-center space-s-1">
      <i className={`ri-time-fill ${iconClasses}`} />
      <span className={`${textClasses}`}>
        {Translation.t('texts.expiry', {
          expiry: `${totalHours}:${minutesLabel}:${secondsLabel}`,
        })}
      </span>
    </div>
  ) : <span className={`${textClasses}`}>
    {Translation.t('texts.expiry', {
      expiry: new DateTime(text).MMddyyyy(),
    })}
  </span>;
};

export default ExpireDate;

