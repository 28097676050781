import React from 'react'
import Translation from '../../helper/Translation';
import Modal from 'react-modal'
import ErrorBag from '../../../components/ErrorBag';
import ImageUploader from '../Posting/ImageUploader';
import Input from '../Input';
import Button from '../Button';
import BackendCall from '../BackendCall';
import { ReturnProduct } from '@shobbak/react-services/dist/Entities';
import { serialize } from 'object-to-formdata';

type Props = {
  returnProduct: ReturnProduct;
  isVisible: boolean;
  onClose: () => void;
  loadOffer: () => void;
}

export const SellerRejectReturnModal = ({returnProduct, isVisible, onClose, loadOffer}: Props) => {
  const [errorBag, setErrorBag] = React.useState([])
  const [actionLoading, setActionLoading] = React.useState<boolean>();
  const [userComment, setUserComment] = React.useState<string>();
  const [attachments, setAttachments] = React.useState([]);

  const handleReject = () => {
    setErrorBag([])
    setActionLoading(true);

    const params = serialize(
      {
        rejection_comment: userComment,
        images: attachments,
      },
      {
        indices: false,
        allowEmptyArrays: false,
      }
      );
    BackendCall.i()
      .returnProductActions(returnProduct.id, 'reject', params)
      .then((res) => {
        onClose()
        loadOffer()
      })
      .catch(({errors}) => {
        setErrorBag(errors)
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <Modal
      className="w-11/12 md:w-1/2 lg:w-1/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 focus-within:outline-none"
      overlayClassName="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      isOpen={isVisible}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <div
        className="bg-white rounded-lg"
      >
        <div className="flex items-center p-2 justify-between bg-coolGray-50 rounded-t-lg">
          <div className="w-8"></div>
          <p className="text-center font-bold text-coolGray-700">
            {Translation.t('dispute.labels.reject_information')}
          </p>
          <button
            onClick={onClose}
          >
            <i className="ri-close-circle-fill r-2x text-coolGray-400" />
          </button>
        </div>

        {errorBag.length >0 && (<ErrorBag errorBag={errorBag} showMoreDetails={true} />) }


        <div className="bg-white p-4 rounded-b-lg overflow-y-auto" style={{maxHeight: 'calc(100vh - 100px)'}}>

          <Input
              placeholder="Describe your response to buyer"
              containerStyle="mt-4 mb-4"
              onChange={(e) => setUserComment(e.target.value)}
              value={userComment}
              label={Translation.t('dispute.labels.comment')}
              multiline
              rows={3}
              required
            />

          <div className="my-4">
              <label className="text-coolGray-700 text-start font-medium mb-2 inline-block">
                {Translation.t('dispute.labels.attach_proof')}
              </label>
            <ImageUploader state={{ images: attachments }} dispatch={({ payload }) => setAttachments([...payload[0].value])} validateImage={()=> {}} />
          </div>


          <Button
            disabled={!userComment || actionLoading}
            type="solid"
            size="medium"
            label={Translation.t('dispute.actions.respond_to_buyer')}
            containerStyle="mb-6 w-full"
            onPress={handleReject}
          />
        </div>

      </div>
    </Modal>
  );
}
