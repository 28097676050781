import React, { FC, useEffect } from "react"
import PopularSearch from "../../src/Types/PopularSearch"
import get from "lodash/get"
import 'remixicon/fonts/remixicon.css'
import redirectToUrl from './redirectToUrl'

interface PopularSearchListProps {
  popularSearches: PopularSearch[];
  submitUrl: string;
  blurInputHandler: () => void;
  verticalType?: string;
  category?: string;
  trackingMetaData?: {};
}

const PopularSearchList: FC<PopularSearchListProps> = ({ popularSearches, submitUrl, blurInputHandler, verticalType = null, category = null, trackingMetaData = {} }) => {
  useEffect(() => {
    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, [])

  function clickHandler(e) {
    if (document.getElementById('searches-list').contains(e.target) || get(e, 'target.dataset.searchInputField')) {
      // Clicked inside the component, don't blur the input field
    } else {
      blurInputHandler()
    }
  }

  function navigateToPopularSearch(popularSearch: PopularSearch) {
    let url = `${submitUrl}?filter[q]=${popularSearch.search_term}`;

    redirectToUrl({
      redirectUrl: encodeURI(url),
      searchTerm: popularSearch.search_term,
      verticalType,
      category,
      trackingMetaData
    })
  }

  return popularSearches.map((popularSearch, index) => {
    return (
      <li
        key={popularSearch.search_term}
        className="px-3 py-1 rounded-lg relative cursor-pointer hover:bg-coolGray-50 hover:text-gray-900 text-coolGray-700">
        <div className="flex justify-between">
          <span className="flex flex-1" onClick={() => navigateToPopularSearch(popularSearch)}>
            <i className="ri-line-chart-line pe-2" />
            <span>
              {popularSearch.search_term}
            </span>
          </span>
        </div>
      </li>
    )
  }
  )
}

export default PopularSearchList
