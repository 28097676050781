import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import 'remixicon/fonts/remixicon.css'
import Translation from '../../helper/Translation';
import { AuctionService } from '../../Services/Auction';
import LiveAuctionTimer from './LiveAuctionTimer';

type Props = {
  item: PostingAuction;
  horizontal?: boolean;
};

const PostingAuctionCard = ({item}: Props) => {
	return (
		<div className="rounded border border-coolGray-100 overflow-hidden text-coolGray-700 bg-white">
			<div className="h-40 p-2 bg-cover" style={{backgroundImage: `url('${item?.posting?.processedAssetUrls[0]?.url}')`}}>
					<div className="flex justify-end space-s-2">
							<div className="flex justify-center items-center w-9 h-9 rounded p-1 bg-coolGray-50 space-s-1">
									<i className="ri-auction-fill" />
									<span className="text-xs font-medium">{item.bidsCount}</span>
							</div>
							<div className="flex justify-center items-center w-9 h-9 rounded p-1 bg-coolGray-50 space-s-1">
									<i className="user-3-fill" />
									<span className="text-xs font-medium">{item.biddersCount}</span>
							</div>
					</div>
			</div>
			<div className="flex flex-col divide-y divide-coolGray-100">
				<div className="px-1.5 py-2 flex items-center justify-between">
						<span 
        			className={`${AuctionService.isTimerClosed(item?.status) ? 'text-red-500' : 'text-coolGray-400'} text-xs`}
						>
							{AuctionService.isTimerClosed(item?.status) ? Translation.t('texts.closed') : Translation.t('auctions.labels.closing_on')}
      			</span>
						<LiveAuctionTimer 
							closed={AuctionService.isTimerClosed(item?.status)} 
							expiresAt={item?.expiresAt}
						/>
				</div>
				<div className="p-2 pb-1.5 flex flex-col space-y-2">
						<div className="flex flex-col space-y-1">
								<p className="text-xs font-medium">{Translation.t('auctions.labels.live_auction_fire')}</p>
								<h5 className="text-sm font-bold truncate">{item.posting.title}</h5>
								<p className="text-xs text-coolGray-500 truncate">{item.posting.addressLine}</p>
						</div>
						<div className="bg-coolGray-50 p-1 rounded text-xs">
							{Translation.t("texts.auctions.labels.highest_bid")}: <span className="font-bold">{item.currentBidAmount.humanized}</span>
						</div>
				</div> 
			</div>
	</div>
	);
}

export default PostingAuctionCard;