import { AuctionBidding, PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import Button from '../../Button'
import Translation from '../../../helper/Translation'
import TimerContainer from '../TimerContainer';
import Bids from '../Bids';
import IconButton from '../../IconButton';
import EventDispatcher from '../../../helper/EventDispatcher';
import WalletRecharge from '../../Wallet/WalletRecharge';
import ConfirmBid from '../ConfirmBid';
import Url from '../../../helper/Url';
import BackendCall from '../../BackendCall';
import { toast } from 'react-toastify';
import Message from '../Message';
import HighestBid from '../HighestBid';

type Props = {
  auction: PostingAuction;
  bidDraft?: AuctionBidding;
  getBidDraft: Function;
  getAuction: Function;
};

function LiveScenario({
  auction,
  bidDraft,
  getBidDraft,
  getAuction
}: Props) {
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const [confirmBidVisible, setConfirmBidVisible] = React.useState<boolean>(false);
  const [isWalletRechargeVisible, setIsWalletRechargeVisible] = React.useState(false)

  const onBidNow = () => {
    if(bidDraft?.requiresLogin){
      EventDispatcher.openLoginModal({redirectUrl: window.location.href})
    }
    else if(bidDraft?.requiresMobileVerification){
      EventDispatcher.dispatch('verify-mobile-number-container')
    }
    else if (bidDraft?.steps[stepIndex].needRecharge) {
      trackAuction({ event: 'auction_bid_clicked', bidAmount: bidDraft?.steps[stepIndex].biddingAmount.value })
      setIsWalletRechargeVisible(true);
    } else {
      trackAuction({ event: 'auction_bid_clicked', bidAmount: bidDraft?.steps[stepIndex].biddingAmount.value })
      setConfirmBidVisible(true);
    }
  }

  const confirmBid = () => {
    setConfirmBidVisible(false);
    BackendCall.i()
      .auctionBidActions(String(auction?.id), '', {
        amount: bidDraft?.steps[stepIndex].biddingAmount.value,
      })
      .then((res) => {
        if ((res as any).length > 0) {
          toast.error(res[0]?.detail)
        } else {
          trackAuction({ event: 'auction_bid_completed', bidAmount: bidDraft?.steps[stepIndex].biddingAmount.value })
        }
        getAuction();
      })
      .catch((error) => {
        toast.error(error.message)
      });
  }

  function trackAuction({ event, bidAmount }) {
    window.trackActivity({ event: event, rawPayload: { ...auction.posting, bid_amount: bidAmount, bid_created_at: (new Date).toISOString() }, shouldRedirect: false })
  }

  return (
    <>
      {/* start timer container */}
      <div className="flex items-center">
        <p className="text-lg font-bold text-coolGray-700 flex-1">{Translation.t("auctions.labels.live_auction_fire")}</p>
        <TimerContainer
          auction={auction}
          containerStyle="px-1.5 py-2"
        />
      </div>
      {/* end timer container  */}


      <hr className="my-4" />


      {/* start highest bid container */}
      <HighestBid
        auction={auction}
      />
      {/* end highest bid container */}



      {/* Start bidsContainer */}
      { auction?.postingAuctionBids.length > 0 &&
        <Bids
          auction={auction}
          size="small"
          containerStyle="p-2 mb-4 rounded border border-coolGray-100"
        />
      }
      {/* End bidsContainer */}



      {/* Start actionsContainer */}
      <>
        <div className="flex space-s-2">
          <div className="flex flex-1 items-center justify-center space-s-2.5 border border-coolGray-300 rounded-md">
            <IconButton
              containerStyle="p-0"
              iconName={'ri-indeterminate-circle-line'}
              type={'clear'}
              disabled={!bidDraft?.canBid || stepIndex <= 0}
              color={'red-500'}
              size={'large'}
              onPress={() => {
                if (stepIndex > 0) {
                  trackAuction({ event: 'auction_bid', bidAmount: bidDraft?.steps[stepIndex - 1].biddingAmount.value })
                  setStepIndex(stepIndex - 1);
                }
              }}
            />
            <span className="font-medium text-coolGray-700">{bidDraft?.steps[stepIndex].biddingAmount.humanized}</span>
            <IconButton
              disabled={!bidDraft?.canBid}
              containerStyle="p-0"
              iconName={'ri-add-circle-fill'}
              type={'clear'}
              color={'emerald-500'}
              size={'large'}
              onPress={() => {
                if (bidDraft && stepIndex < bidDraft?.steps.length - 1) {
                  trackAuction({ event: 'auction_bid', bidAmount: bidDraft?.steps[stepIndex + 1].biddingAmount.value })
                  setStepIndex(stepIndex + 1);
                }
              }}
            />
          </div>
          <Button
            label={Translation.t('buttons.bid_now')}
            disabled={!bidDraft?.canBid}
            onPress={onBidNow}
            containerStyle="flex-1"
          />
        </div>
        <Message bidDraft={bidDraft} stepIndex={stepIndex} />
      </>
      {/* End actionsContainer */}



      {/* Start Modals */}
      {bidDraft?.steps[stepIndex].rechargeLink && (
        <WalletRecharge
          auctionId={auction?.id}
          amount={Url.parseParams(bidDraft?.steps[stepIndex].rechargeLink).amount}
          visible={isWalletRechargeVisible}
          onClose={() => setIsWalletRechargeVisible(false)}
          redirectUrl={window.location.href}
        />
      )}

      {bidDraft?.steps[stepIndex].biddingAmount && (
        <ConfirmBid
          visible={confirmBidVisible}
          onClose={() => setConfirmBidVisible(false)}
          onConfirm={confirmBid}
          amount={bidDraft.steps[stepIndex].biddingAmount.humanized}
        />
      )}
      {/* End Modals */}
    </>
  );
}

export default LiveScenario;
