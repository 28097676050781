import React from 'react';
import { renderOfferMessages } from '../../../src/Components/Offer/renderOfferMessages'
import Divider from '../Divider';
import Translation from "../../helper/Translation";
interface Props {
  messages?: any;
  lineItems: any;
  paymentMethod?: string;
  showWallet?: boolean;
  wallet?: any;
  showDivider?: boolean;
}
export default function PricesBreakDown({
  messages,
  lineItems,
  showWallet,
  paymentMethod,
  wallet,
  showDivider = true
}: Props) {
  return (
    <>
      {messages &&
        messages.length > 0 &&
        renderOfferMessages(messages, 'above_payment_breakdown')}
      <div className="p-4">
        <p
          className="mb-2 font-bold text-coolGray-700">
          {Translation.t('texts.offer_total_amount')}
        </p>
        {lineItems.map((item, index) => (
          <div
            className="flex items-center mb-2"
            key={index}>
            <p
              className={`flex-1 ${item.isTotal ? 'font-bold' : ''} ${item.light ? 'text-coolGray-500' : 'text-coolGray-700'}`}>
              {item.label.replace('%{paymentMethod}', Translation.t('texts.card'))}
            </p>

            {item.placeholder ? (
              <p
                className={`text-orange-500 ${item.isTotal ? 'font-bold' : 'font-normal'}`}
              >
                {item.placeholder}
              </p>
            ) : item?.amount ? (
              <p
                className={`${item.light ? 'text-coolGray-500' : 'text-coolGray-700'} ${item.isTotal ? 'font-bold' : 'font-normal'}`}
              >
                {item.amount}
              </p>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
