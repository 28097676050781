import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { Business } from "@shobbak/react-services/dist/Entities";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"));
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"));
import Loader from "../src/Components/icon/Loader";
import ProfileCardWrapper from "../src/Components/profile/ProfileCardWrapper";
import Translation from '../src/helper/Translation';
import EditIcon from "../src/Components/icon/EditIcon";

export default function MyStores({ token, locale }) {
  const [businessProfiles, setBusinessProfiles] = useState<Business[]>([])

  useEffect(() => {
    BackendCall.i({ token, locale })
      .getMyBusinessProfiles()
      .then((response: Business[]) => setBusinessProfiles(response))
      .catch(error => { })
  }, [])

  function edit(businessProfileId) {
    window.location.href = `/business_profiles/${businessProfileId}/edit`
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCardWrapper token={token} locale={locale}>
          <ProfileCard withBoarder={false} title={Translation.t('buttons.my_stores')}>
            {businessProfiles.map((businessProfile: Business) => {
              return (
                <div key={businessProfile.id} className="flex-col mb-4 rounded-lg border border-coolGray-200">
                  <div className="relative h-36 rounded-t-lg bg-no-repeat bg-cover bg-center border-b border-coolGray-200" style={{ backgroundImage: `url(${businessProfile.coverImage})` }}>
                    <img className="absolute bottom-0 m-4 rounded-full object-cover h-16 w-16" src={businessProfile.logo} />
                  </div>
                  <div className="py-2 px-4 flex justify-between items-center">
                    <div>
                      <h3 className="text-coolGray-700 text-base leading-6 font-bold">{businessProfile.name}</h3>
                      <p className="text-sm text-coolGray-500 font-normal">{businessProfile.name}</p>
                      <p className="text-sm font-normal" style={{ color: businessProfile.statusDetail?.foreground }}>{businessProfile.statusDetail?.name}</p>
                    </div>
                    {businessProfile.canEdit ?
                      <button onClick={() => edit(businessProfile.id)} className="inline-flex text-xs text-emerald-500">
                        <EditIcon className="fill-current me-2 h-4 w-4" />
                        {Translation.t('buttons.edit')}
                      </button>
                      : null
                    }
                  </div>
                </div>
              )
            })}
          </ProfileCard>
        </ProfileCardWrapper>
      </ProfileLayout>
    </Suspense>
  )
}
