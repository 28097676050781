import React from 'react';
import 'remixicon/fonts/remixicon.css'
import Input from './Input';
import Switch from './Switch';


type CompProps = {
  title: string;
  description: string;
  warning?: string;
  iconName: string;
  valueRequired?: boolean;
  onUpdate: (v) => void;
  containerStyle?: string;
  locale?: string;
};

export default function SelectionSwitch({
  title,
  description,
  warning,
  iconName,
  valueRequired,
  onUpdate,
  containerStyle,
  locale
}: CompProps) {
  const [active, setActive] = React.useState<boolean>(false);
  return (
    <div
      className={`p-2 border border-coolGray-50 rounded-lg ${containerStyle ?? ''} ${active ? 'bg-white' : 'bg-coolGray-50'}`}
    >
      <div className="flex">
        <div className={`w-8 h-8 flex items-center justify-center rounded-xl ${active ? 'bg-coolGray-100' : 'bg-white'}`}>
          <i className={`${iconName} text-coolGray-700`} />
        </div>
        <div className="mx-2 flex-1">
          <p className="mb-1 font-medium text-sm text-coolGray-700">{title}</p>
          <p className="text-coolGray-500 text-xs">{description}</p>
        </div>
        <Switch
          locale={locale}
          onValueChange={(e) => {
            setActive(e);
            if (e === false) {
              onUpdate(undefined);
            }
          }}
        />
      </div>
      {warning && !active && (
        <div className="flex item-center rounded-lg bg-orange-50 px-2 mt-2">
          <i className="ri-information-fill text-orange-700" />
          <p className="text-orange-700 ms-2 text-xs leading-5">{warning}</p>
        </div>
      )}
      {valueRequired && active && (
        <Input placeholder={title} containerStyle="mt-2" onChange={e => onUpdate(e.target.value)} />
      )}
    </div>
  );
}
