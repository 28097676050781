import React, { useRef, useMemo } from 'react'
import Translation from '../helper/Translation'
import DeleteBinEmpty from './icon/DeleteBinEmpty';

export default function SingleImageUploader({ keyName, uploadedFile, onUpload, onDeleted , containerStyle = null ,mediaTypePlaceholder=null }) {
  const fileInput = useRef(null);
  const fileWasUploaded = useMemo(() => uploadedFile != null, [uploadedFile])

  return (
    <div className={`flex ${fileWasUploaded ? 'space-s-2' : null}`}>
      <div
        className={`flex ${fileWasUploaded ? 'w-24 h-24' : 'w-full'} justify-center ${containerStyle ?  containerStyle : 'pt-5 pb-6'} border-2 border-gray-300 border-dashed rounded-md`}>
        <div className="space-y-1 text-center">
          <svg className="cursor-pointer mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
            onClick={() => fileInput.current.click()}
            viewBox="0 0 48 48" aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label htmlFor={`file-upload-${keyName}`} className="relative cursor-pointer bg-white rounded-md font-medium text-emerald-600 hover:text-emerald-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-emerald-500">
              {!fileWasUploaded ? <span className="text-emerald-600">{Translation.t('texts.upload_file')}</span> : null}
              <input
                id={`file-upload-${keyName}`}
                ref={fileInput}
                name={`file-upload-${keyName}`}
                type="file"
                className="sr-only"
                onChange={e => onUpload(e.target.files[0])}
              />
            </label>
            {!fileWasUploaded ? <p className="pl-1">{Translation.t('texts.drop_upload_image')}</p> : null}
          </div>
          {!fileWasUploaded ? <p className="text-xs text-gray-500"> {mediaTypePlaceholder ? mediaTypePlaceholder : Translation.t('texts.file_types')} </p> : null}
        </div>
      </div>
      {fileWasUploaded ?
        <div className="relative">
          <button
            className="absolute top-0 end-0 bg-coolGray-400 p-2 rounded m-2 hover:bg-coolGray-700"
            onClick={() => {
              onDeleted()
              fileInput.current.value = ""  // resetting the input to allow uploading the same file again after deleting it
            }}>
            <DeleteBinEmpty className="fill-current text-white" width="16" height="16" />
          </button>
          <img className="object-contain w-24 h-24" height="40" src={URL.createObjectURL(uploadedFile)} />
        </div>
        : null
      }
    </div>
  )

}
