import { StatusGroups } from '@shobbak/react-services/dist/Entities';
import { useRemoteItem } from '../../Hooks/useRemoteItem';
import BackendCall from '../BackendCall';
import React, {useEffect, useState} from 'react';

import FilterButton from '../FilterButton';
type Props = {
  role: string;
  onSelect?: (data) => void;
  containerStyle?: string;
};

const itemsLoader = (params: object = {}) => BackendCall.i().getPostingAuctionsGroups(params);

const StatusGroupsComponent = ({role, onSelect, containerStyle }: Props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [items, loadItems] = useRemoteItem<StatusGroups[]>({dataLoader: itemsLoader});

  useEffect(() => {
    loadItems(getParams());
  }, []);

  const getParams = () => {
    return {
      role,
    };
  }

  const onPressFilterButton = (key) => {
    let _selectedItems = {};
    if (selectedItems[key]) {
      _selectedItems = {...selectedItems};
      delete _selectedItems[key];
    } else {
      _selectedItems = {...selectedItems, [key]: key};
    }
    onSelect && onSelect(_selectedItems);
    setSelectedItems(_selectedItems);
  };

  return (
    <div className={`${containerStyle ?? ''} flex items-center py-2 space-s-2 overflow-x-auto hide-scroll`}>
      {items.item?.map((item) => {
        return (
          <FilterButton
            key={item.key}
            isSelected={selectedItems[item.key]}
            name={item.name}
            foregroundColor={item.foregroundColor}
            backgroundColor={item.backgroundColor}
            onPress={() => onPressFilterButton(item.key)}
          />
        );
      })}
    </div>
  );
};

export default StatusGroupsComponent;
