import React, { useEffect, useRef } from "react"
import Translation from "../src/helper/Translation";
import EventDispatcher from "../src/helper/EventDispatcher";
import WarningAlert from "../src/Components/Alerts/WarningAlert";
import BackendCall, { HttpUnprocessableEntity } from "../src/Components/BackendCall";
import { toast } from "react-toastify";


const AllowedActions = ({  postingId , token , locale })=> {
  const selectedAction = useRef(null)
  const isPromoted = useRef(false)

  const handleSelectedAction = (action) => {
    let promotion = isPromoted && action == 'close' ? 'warning_for_promotion': action
    const displayTranslationAction = {
      title: Translation.t(`posting.allowed_actions.${action}.title`),
      display_action: Translation.t(`posting.allowed_actions.${action}.display_action`),
      paragraph: Translation.t(`posting.allowed_actions.${promotion}.paragraph`),
      action: action,
      postId: postingId
    }
    EventDispatcher.dispatch('open-warning-modal', displayTranslationAction)
  }

  const handleConfirmedAction = async (action, postId) => {
    let response = {};
    let allowedActions  = ['void','close','publish','mark_available','sold']

    if (!allowedActions.includes(action)){
       return ;
    }

    return BackendCall.i({token , locale}).postingAction(postId ,action);
  }


  useEffect(()=> {
    EventDispatcher.on('set-selected-action' , (event)=> {
      selectedAction.current = event.detail.selectAction
      handleSelectedAction(event.detail.selectAction)
    })
    EventDispatcher.on('confirmed' , (payload)=> {
      if (payload.detail.action == selectedAction.current){
        handleConfirmedAction(selectedAction.current , postingId)
        .then((res)=>  {
          EventDispatcher.dispatch('close-warning-modal')
          toast.success(Translation.t('texts.ads_updated'))
          setTimeout(()=> window.location.reload() , 1000)
        })
          .catch(error => {
            EventDispatcher.dispatch('close-warning-modal')
            let errorMessage = Translation.t('texts.operation_failed');
            if (error instanceof HttpUnprocessableEntity) {
              errorMessage = error.messageStr();
            }
            toast.error(errorMessage)
            setTimeout(()=> window.location.reload() , 2000)
          })
      }
    })
  } , [])

  return (<WarningAlert />)



}


export default AllowedActions
