window.shouldOpenRegisterModal = false;
window.confirmation_modal = () => {
    return {
        mode: 'register',
        show: false,
        toggleModal() {
            this.show = !this.show
        },
        showConfirmation(mode = 'register') {
            this.mode = mode;
            this.show = true;
        }
    }
}