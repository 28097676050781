import React, { useRef, useEffect, useState } from 'react'
import { Blurhash } from "react-blurhash";


const BlurHashImage = ({ imageData , imageHeight , ...props })=> {

  const imageRef = useRef(null)
  const [showBlurHash , setShowBlurHash] = useState<Boolean>(true)
  const [hash , setHash] = useState<Boolean>(imageData?.blurhash)
  const [src ,setSrc] = useState(imageData?.thumbnail || imageData)


  useEffect(() => {
    imageRef.current.classList.add('hidden')
    imageRef.current.onload = () => {
      setTimeout(()=> {
        imageRef.current.classList.add("duration-500");
        imageRef.current.classList.add("ease-out");
        imageRef.current.classList.remove("hidden");
        setShowBlurHash(false)
      } , 500)
    }
  }, []);


  return(
    <div className={`w-12 h-12`}>
      {showBlurHash && hash && (<Blurhash hash={hash} {...imageHeight} />)}
      <img {...props} src={src} ref={imageRef} />
    </div>
  )
}

export default BlurHashImage