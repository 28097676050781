import React, { useEffect } from 'react';
import Divider from '../src/Components/Divider';
import SelectionSwitch from '../src/Components/SelectionSwitch';
import StartAuctionSvg from '../src/Assets/svg/Auctions/StartAuction';
import BackendCall from '../src/Components/BackendCall';
import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import { toast } from 'react-toastify';
import Translation from '../src/helper/Translation';
import PaymentAction from '../src/Components/Auction/PaymentAction';
import { Hooks } from '@shobbak/react-services';
const { useDebounce } = Hooks
import { Provider } from "react-redux";
import { store } from '../src/redux/store'
import AppContext, { Env } from "../src/Services/AppContext";
import { showError } from '../src/helper/errors';
interface Props {
  token: string;
  locale: string;
  postingId: string | number;
  env: Env;
}

export default function StartAuction({ locale, token, postingId, env }: Props) {
  BackendCall.i({ token, locale })
  const [auction, setAuction] = React.useState<PostingAuction>();
  const [amount, setAmount] = React.useState<string>();
  const delayedAmount = useDebounce<any>(amount, 1000);

  useEffect(() => {
    if (!amount) return;

    BackendCall.i()
      .createPostingAuction({
        posting_id: postingId,
        starting_amount: amount,
      })
      .then((res) => {
        setAuction(res);
      })
      .catch((error) => {
        showError(error)
      });
  }, [delayedAmount]);

  return (
    <Provider store={store}>
      <AppContext.Provider value={{ env }}>
        <div className="py-6 flex flex-col gap-4 md:flex-row-reverse md:items-start">

          <div className="md:w-1/3 bg-white p-4 rounded-lg border border-coolGray-200">
            <div className="flex items-center justify-center mb-2">
              <StartAuctionSvg />
            </div>
            <p className="font-semibold leading-7 text-coolGray-700 text-center mb-1 text-xl">{Translation.t('auctions.labels.auction_fees')}</p>
            <p className="text-coolGray-500 leading-5 text-center px-4 mb-2 text-sm">
              {Translation.t('auctions.messages.inform_fees')}
            </p>
            <div className="bg-coolGray-50 p-4 rounded-lg">
              <p className="font-semibold leading-7 text-emerald-500 text-center mb-1 text-2xl">{auction?.processingFee.humanized}</p>
            </div>
          </div>


          <div className="md:w-2/3 bg-white p-6 rounded-lg border border-coolGray-200">
            <p className="text-coolGray-700 font-bold text-lg leading-7 mb-1">
              {Translation.t('auctions.labels.select_action_options')}
            </p>
            <p
              className="mb-2 text-coolGray-400 leading-6"
            >
              {Translation.t('auctions.labels.auction_options_sub')}
            </p>

            <div className="flex item-center rounded-lg bg-orange-50 p-2 mt-2 mb-3">
              <i className="ri-information-fill text-orange-700" />
              <p className="text-orange-700 ms-2 text-xs">
                {Translation.t('auctions.labels.auction_duration_warning')}
              </p>
            </div>

            <SelectionSwitch
              title={Translation.t('auctions.labels.select_starting_amount')}
              description={Translation.t('auctions.labels.auction_starting_amount_description')}
              iconName="ri-money-dollar-circle-fill"
              valueRequired
              onUpdate={(value) => setAmount(value)}
              locale={locale}
            />

            <Divider height={8} containerStyle="mt-4" />

            {auction && auction.allowedActions.includes('pay') && (
              <PaymentAction
                onError={() => { }}
                onSuccess={(e) => {
                  window.location.href = `/postings/${postingId}}`
                }}
                onUpdate={(auction) => {
                  setAuction(auction);
                }}
                auction={auction}
                lineItems={auction.lineItems}
              />
            )}
          </div>

        </div>
      </AppContext.Provider>
    </Provider>
  )
}
