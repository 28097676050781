import { MobileInput } from "../Posting/Modal/MobilePhoneModal";
import React, { useEffect, useRef, useState } from "react";
import DefaultButton from "./DefaultButton";
import VerifyMobileModal from "../../../components/VerifyMobileModal";
import CountryCode from "../../helper/countryCode";
import BackendCall, { HttpUnprocessableEntity } from "../BackendCall";
import { toast } from "react-toastify";
import Translation from "../../helper/Translation";
import ReCAPTCHA from "react-google-recaptcha";


const EditMobile = ({number ,token , locale,mobileObject,enabledCountries, verified ,countryId ,countryCode, nextNotificationTime , onMobileChange, recaptchaEnabled = true})=> {
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const [mobile , setMobile] = useState({ number: number?.replace(`+${countryCode}`,'') ,countryId: countryId, countryCode: countryCode })
  const [isVerified ,setIsVerified] =useState(false)
  const [mobileObjectCopy ,setMobileObjectCopy] = useState(null)

  useEffect(()=> {
    setMobileObjectCopy(mobileObject)
  } , [])
  const recaptchaRef = useRef(null)
  useEffect(()=> {
    setIsVerified( verified == true || verified == undefined )
  }, [])
  const updateMobileObject = (mobile , countryCode)=> {
    let newCountryId  = CountryCode.getCountryIdFromMobileCountryCode(countryCode ,JSON.parse(enabledCountries) ) || countryId

    setMobile({ number: mobile  , countryCode: countryCode , countryId: newCountryId }) ;
    onMobileChange({ mobile:{number: `${countryCode}${mobile}` , countryCode: countryCode , countryId: newCountryId} })
    setIsVerified(true)
  }

  const resendMobileVerificationCode =async ()=> {
    let params = {}
    if (recaptchaEnabled) {
      await recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    BackendCall.i({token , locale})
    .sendPhoneVerifyCode(mobileObject.id , params)
    setShowVerifyModal(true)
  }

  const handleUpdateUserMobile = async ()=> {
    let newCountryId  = CountryCode.getCountryIdFromMobileCountryCode(countryCode ,JSON.parse(enabledCountries) ) || countryId
    setMobile({ number: mobile.number  , countryCode: countryCode , countryId: newCountryId }) ;
    let params = {number: `${countryCode}${mobile.number}` ,
      country_id: mobile.countryId}

    if (recaptchaEnabled) {
      recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      // @ts-ignore
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    
    BackendCall.i({token , locale})
     .createNewUserMobile( params).then(res=> {
      setMobileObjectCopy(res)
      if(!res.verified){
        setShowVerifyModal(true)
      }
    })
      .catch(error=> {
        let errorMessage = Translation.t('texts.operation_failed');
        if (error instanceof HttpUnprocessableEntity) {
          errorMessage = error.messageStr();
        }
        toast.error(errorMessage)
      })

  }

  return (
    <div className={`flex items-end gap-2 w-full`} >
      <MobileInput containerClass={`w-full`}
                   countryCode={mobile.countryCode}
                   setCountryCode={(value)=>{
                     updateMobileObject(mobile?.number , value)
                      }}
                   number={mobile.number}
                   setNumber={(value)=>{
                     updateMobileObject(value , mobile.countryCode)
                   }} />
      {!isVerified && (
        <DefaultButton label={Translation.t('buttons.verify')} disabled={isVerified} onButtonClick={()=>{   if(!isVerified){ resendMobileVerificationCode() }  }} />
      )}

      {(<DefaultButton label={Translation.t('buttons.edit')} disabled={false} onButtonClick={()=>{  handleUpdateUserMobile() }} />) }


      {showVerifyModal && (<VerifyMobileModal
                                            onAfterSubmit={()=> { toast.success(Translation.t('texts.profile_update_successfully')) ;
                                            setShowVerifyModal(false) ;
                                            setTimeout(()=> window.location.reload() , 1000)  }}
                                              onClose={()=>{ setShowVerifyModal(false) }}
                                              onChangeMobile={()=>{}} canChangeMobile={false}
                                              selectedMobilePhone={mobileObjectCopy}
                                              recaptchaEnabled={recaptchaEnabled} /> )}


      {recaptchaEnabled && <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      }

    </div>
  );
}
export  default  EditMobile