import React from "react"
import Translation from "../../src/helper/Translation";
import 'remixicon/fonts/remixicon.css'

interface headerProps {
  icon?: string;
  title?: string;
  onClose: ()=> void;
}
export default function Header({icon, title, onClose}: headerProps) {
  return (
    <div className="w-full flex gap-4 justify-between">
      <div className="flex gap-4 items-center">
        <div className="w-12 h-12 bg-emerald-50 rounded-full flex items-center justify-center">
          <i className={`${icon || 'ri-share-circle-line'} ri-lg text-emerald-500`} />
        </div>
        <div className="flex flex-col">
          <span className="text-base leading-6 font-semibold text-coolGray-900 flex">{ title || Translation.t("withdraw_request.modal.title") }</span>
        </div>
      </div>
      <div onClick={onClose} className="cursor-pointer">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 10.5867L16.95 5.63672L18.364 7.05072L13.414 12.0007L18.364 16.9507L16.95 18.3647L12 13.4147L7.04999 18.3647L5.63599 16.9507L10.586 12.0007L5.63599 7.05072L7.04999 5.63672L12 10.5867Z" fill="#9CA3AF"/>
        </svg>
      </div>
    </div>
  )
}
