import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import Translation from '../../../helper/Translation';
import Button from '../../Button';
import Bids from '../Bids';
import HighestBid from '../HighestBid';
import SellerAuctionDetails from '../SellerAuctionDetails';
import TimerContainer from '../TimerContainer';

type Props = {
  auction: PostingAuction;
	getAuction: Function;
};

function MyAuctionScenario({
	auction,
	getAuction
}: Props) {
  const [isSellerAuctionDetailsVisible, setIsSellerAuctionDetailsVisible] = React.useState(false)

	return (
    <>
			{/* start timer container */}
			<div className="flex items-center">
        <p className="text-lg font-bold text-coolGray-700 flex-1">{Translation.t("auctions.labels.live_auction_fire")}</p>
        <TimerContainer
          auction={auction}
          containerStyle="px-1.5 py-2"
        />
      </div>
      {/* end timer container  */}


      <hr className="my-4" />


      {/* start highest bid container */}
			<HighestBid
        auction={auction}
      />
			{/* end highest bid container */}

			{auction?.postingAuctionBids.length === 0 ? (
          <div className="p-2 rounded-lg bg-orange-50 flex mb-4">
						<i className="ri-auction-fill text-orange-700" />
						<p className="ms-2 text-orange-700 text-start text-xs">
							{Translation.t('auctions.messages.no_bids')}
						</p>
					</div>
        ) : (
					<Bids
						auction={auction}
						size="small"
						containerStyle="p-2 mb-4 rounded border border-coolGray-100"
					/>
				)
			}


      {/* Start actionsContainer */}
      <Button
          label={Translation.t("auctions.actions.manage")}
          onPress={() => setIsSellerAuctionDetailsVisible(true)}
          containerStyle="w-full"
        />
      {/* End actionsContainer */}


      {/* Start Modals */}
      	<SellerAuctionDetails
          auction={auction}
          visible={isSellerAuctionDetailsVisible}
          onClose={() => setIsSellerAuctionDetailsVisible(false)}
          onAction={() => getAuction()}
        />
      {/* End Modals */}
    </>
  );
}

export default MyAuctionScenario;