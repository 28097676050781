import React, { useEffect } from 'react'

const BarCharIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.99967 0H8.99967V12H4.99967V0ZM0.333008 6.66667H4.33301V12H0.333008V6.66667ZM13.6663 3.33333H9.66634V12H13.6663V3.33333Z"/>
    </svg>

  );
}


export default BarCharIcon