import React from 'react';

import { Category, PostingAuction } from '@shobbak/react-services/dist/Entities';
import useRemoteData from '../../Hooks/useRemoteData';
import BackendCall from '../BackendCall';
import PostingAuctionCard from './PostingAuctionCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import Loader from '../icon/Loader';

type Props = {
  category: Category;
};

export default function AuctionsSlider({category}: Props) {
  const auctionsLoader = async () => {
    return BackendCall.i().getAuctions('trending', 
      {
        category_id: category.id,
      }
    );
  };
  const [{items, isLoading}, fetchAuctions] = useRemoteData<PostingAuction>({
    dataLoader: auctionsLoader,
  });

  React.useEffect(() => {
    fetchAuctions({});
  }, []);

  if (isLoading) return <Loader />
  
  return (
    <div>
      <div className="mb-4">
        <p className="text-coolGray-700 text-xl font-bold">
          {category.name}
        </p>
      </div>

      <Swiper
				spaceBetween={8}
        breakpoints={{
          320: {
            slidesPerView: 1.25,
          },
          640: {
            slidesPerView: 2.25,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
			>
        {items.map((item, index) => (
					<SwiperSlide key={item.id}>
            <a href={`/postings/${item.posting.id}`}>
          	  <PostingAuctionCard key={item.id} item={item} />
            </a>
					</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
