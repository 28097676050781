import React from 'react'
import { PostingOffer } from '@shobbak/react-services/dist/Entities';
import { Services } from '@shobbak/react-services';
import CalenderFill from '../icon/CalenderFill';
import DateTime from '../../helper/DateTime';
import ExpireDate from '../ExpireDate';
import { ReactSVG } from 'react-svg';
import CommunityBadge from '../../../components/CommunityBadge';
import RateStars from '../../../components/RateStars';

const { Helpers: { imageUrl } } = Services;

interface Props {
  offer: PostingOffer
}

function OfferSummaryCard({ offer }: Props) {
  let { posting } = offer
  const { processedAssetUrls } = posting || {};
  const image = processedAssetUrls?.[0]?.thumbnail;

  function renderStatus() {
    if (offer.expiresAt) {
      return <ExpireDate iconClasses="text-coolGray-400" textClasses="text-xs font-medium leading-4 text-coolGray-400" text={offer.expiresAt} />
    } else if (offer.deliveryOptionDetail) {
      return (
        <div className="flex space-s-1 items-center">
          <ReactSVG
            src={offer.deliveryOptionDetail.icon}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', 'currentColor')
              svg.setAttribute('style', 'width: 12px; height: 12px')
              svg.firstElementChild.setAttribute('fill', 'currentColor')
            }}
          />
          <p className="text-xs font-medium leading-4 text-coolGray-700" >{offer.deliveryOptionDetail.name}</p>
        </div>
      )
    }
  }

  return (
    <a href={offer.offerUrl}>
      <div className="flex space-s-2 p-4 border border-coolGray-200 rounded-md mb-4">
        <div>
          <img className="w-8 h-8 rounded-full" src={imageUrl(image, 25, 25)} />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex flex-col pb-2 mb-2 border-b border-coolGray-100">
            <div className="flex justify-between">
              <h4 className="text-coolGray-700 text-base font-semibold leading-6">{posting.title}</h4>
              <p className="text-emerald-500 text-sm font-semibold leading-5">{offer.role == 'seller' ? offer?.sellerAmountDetail?.formatted : offer.totalAmountPayableDetail?.formatted}</p>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-x-3 gap-y-1">
              <div className="flex gap-1">
                <h5 className="text-coolGray-500 text-sm font-normal leading-6">{offer.subtitle}</h5>
              </div>
              <div className="flex items-end space-s-1 ms-auto">
                <CalenderFill className="fill-current text-coolGray-300" width="16" height="16" />
                <p className="text-coolGray-400 text-xs font-medium leading-4">{new DateTime(offer.createdAt).MMddyyyy()}</p>
              </div>
            </div>
            <div className="flex items-start w-full sm:w-auto order-3 sm:order-none"> 
              <RateStars score={offer.partenerRateAvg} />
            </div>
            {offer.inSameComunity && offer.community && <div className="flex items-start"> 
               <CommunityBadge community={{id: offer.community.id, image_url: offer.community.logoUrl?.url}} />
              </div>
            }
          </div>
          <div className="flex justify-between">
            <div className="flex justify-between">
              <div className="flex items-center">
                <span className="flex me-1 items-center justify-center w-5 h-5 rounded-full" style={{ backgroundColor: offer.statusInfo?.backgroundColor }}>
                  <i className={`${offer.statusInfo?.icon}`} style={{ color: offer.statusInfo?.foregroundColor }}></i>
                </span>
                <p className="text-xs font-medium leading-4" style={{ color: offer.statusInfo?.foregroundColor }}>{offer.statusInfo?.name}</p>
              </div>
            </div>
            <div>
              {renderStatus()}
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default OfferSummaryCard;
