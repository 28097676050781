import React, { FC } from "react"
import SearchSuggestion from "../../src/Types/SearchSuggestion"
import redirectToUrl from './redirectToUrl'
import qs from "qs";

interface SearchSuggestionsListProps {
  suggestions: SearchSuggestion[];
  submitUrl: string;
  verticalType?: string;
  category?: string;
  trackingMetaData?: {};
}

const SearchSuggestionsList: FC<SearchSuggestionsListProps> = ({ suggestions, submitUrl, verticalType = null, category = null, trackingMetaData = {} }) => {
  function navigateToSuggestion(suggestion: SearchSuggestion) {
    let url = `${submitUrl}?filter[search_term]=${suggestion.query}&`
    url += qs.stringify({filter: suggestion.filters}, {encode: false})

    redirectToUrl({
      redirectUrl: encodeURI(url),
      searchTerm: suggestion.query,
      verticalType,
      category,
      trackingMetaData
    })
  }

  return (
    suggestions.length ? <ul className="absolute bg-white border border-coolGray-200 rounded-lg w-full mt-1">
      {suggestions.map(suggestion => {
        return (
          <li
            dangerouslySetInnerHTML={{ __html: suggestion.highlighted_query.replace(/<em>/g, '<b>').replace(/<\/em>/g, '</b>') }}
            key={suggestion.id}
            onClick={() => navigateToSuggestion(suggestion)}
            className="px-3 py-1 relative cursor-pointer hover:bg-coolGray-50 hover:text-gray-900">
          </li>
        )
      })}
    </ul> : null
  )
}

export default SearchSuggestionsList
