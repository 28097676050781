import React, { useState } from "react";
import { toast } from "react-toastify";
import Translation from "../../helper/Translation";
import BackendCall from "../BackendCall";
import RequestProfileVerifyModal from "./RequestProfileVerifyModal";
import OtpModal from "./OtpModal";

const VerificationMethod = ({ methodPayload, updateMethods, locale, token }) => {
  const [verified, setVerified] = useState(methodPayload?.metadata?.status == 'verified')
  const [requestProfileVerifyModal, setRequestProfileVerifyModal] = useState(false)
  const [showOtpModel, setShowOtpModel] = useState(false)


  const afterVerificationMethodUpdated = (method) => {
    setRequestProfileVerifyModal(false)
    if (method.afterInput == 'ask_otp') {
      setShowOtpModel(true)
    } else {
      toast.success(Translation.t('texts.verification_wait_approve'))
    }
  }

  const resendVerificationCode = (method) => {
    BackendCall.i({ token, locale }).verifyProfileCodeResend(method.key, String(method.metadata.id))
  }

  return (
    <>
      <div className={`flex flex-col gap-2`}>
        <div className={`flex gap-4 ${verified ? 'items-center' : 'items-start'}`}>
          <div className={`mt-4`}>
            <span className={`flex items-center rounded-md justify-center h-12 w-12 bg-emerald-50`}>
              {!verified && (
                <img className={`h-8 w-6`} src={methodPayload?.icon} alt={methodPayload?.pageTitle} />
              )}
              {verified && (
                <i className={`ri-checkbox-circle-fill text-emerald-500  ri-xl`} />
              )}
            </span>
          </div>
          <div className={`flex flex-col gap-2 justify-start`}>
            {verified && (<h2 className={`text-sm leading-5 font-medium text-coolGray-700 mt-4`}>{Translation.t('texts.verified_by_method', { method: methodPayload.name })}</h2>)}
            {!verified && (
              <>
                <h2 className={`text-sm leading-5 font-medium text-coolGray-700 mt-4`}>{methodPayload?.pageTitle}</h2>
                <p className={`text-base leading-6 font-normal text-coolGray-500`}>{methodPayload?.description}</p>
                <div>
                  <button onClick={() => { setRequestProfileVerifyModal(true) }} className={`class="cursor-pointer text-emerald-500 bg-white whitespace-nowrap  border border-emerald-500 border-transparent rounded-md h-9 py-1.5 px-4 items-center justify-center text-sm font-normal leading-5"`}>
                    {Translation.t('buttons.verify')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

      </div>
      {requestProfileVerifyModal && <RequestProfileVerifyModal onSuccess={(method) => afterVerificationMethodUpdated(method)}
        updateMethods={(method) => { updateMethods(method) }}
        locale={locale} token={token}
        methodPayload={methodPayload}
        onClose={() => setRequestProfileVerifyModal(false)} />}
      {showOtpModel && <OtpModal locale={locale} token={token} methodPayload={methodPayload} resendVerificationCode={(methodPayloadData) => resendVerificationCode(methodPayloadData)} title={methodPayload.pageTitle} onClose={() => { setShowOtpModel(false) }} onSuccess={(methodPayload) => {
        updateMethods(methodPayload);
        toast.success(Translation.t('texts.profile_update_successfully'))
        window.location.reload()
      }} />}

    </>
  )
}


export default VerificationMethod
