import Translation from '../helper/Translation';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import 'remixicon/fonts/remixicon.css';

import ConfirmationModal from './ConfirmationModal';
import CreditCardDisplay from './PaymentMethodCard';
import { CreditCard } from '@shobbak/react-services/dist/Entities';
import RadioButton from './RadioButton';
import "remixicon/fonts/remixicon.css";
import Loader from './icon/Loader';
import Switch from "./Switch";

export type PaymentMethodName =
  | 'card_pay'
  | 'apple_pay'
  | 'web_pay'
  | 'wallet';

type PaymentMethodListItem = {
  title?: string;
  last4?: string;
  name?: string;
  id: string;
  iconName: string;
  extraOptions: ExtraOption[];
};

export default function PaymentMethodsList({
  cards,
  isFetchingCards,
  onDelete,
  onCheckedCardChange,
  defaultCheckedCardId,
  hideRadio = false,
  showWebPay,
  wallet,
  chargeWallet = false,
  onChargeWalletChange,
}: {
  cards: CreditCard[];
  isFetchingCards: boolean;
  onDelete?: (cardId: string) => Promise<any>;
  onCheckedCardChange?: (cardId: string) => void;
  defaultCheckedCardId?: string;
  hideRadio?: boolean;
  showWebPay: boolean;
  wallet?: any;
  chargeWallet?: boolean;
  onChargeWalletChange?: (e) => void;
}) {
  const [checkedCardId, setCheckedCardId] = React.useState<string | null>(
    defaultCheckedCardId,
  );
  const [walletSelected, setWalletSelected] = React.useState<boolean>(
    defaultCheckedCardId === 'wallet' || chargeWallet,
  );
  const [optionsDisabled, setOptionsDisabled] = React.useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = React.useState<string | null>(
    null,
  );

  React.useEffect(() => {
    if (walletSelected) {
      onChargeWalletChange?.(true);
      if (wallet?.data?.can_partial_pay === true) {
        setOptionsDisabled(false);
        onCheckedCardChange?.(checkedCardId);
      } else {
        onCheckedCardChange?.('wallet');
        setOptionsDisabled(true);
      }
    } else {
      onChargeWalletChange?.(false);
      setOptionsDisabled(false);
      onCheckedCardChange?.(checkedCardId);
    }
  }, [walletSelected]);
  const uniqueCards: {
    title?: string;
    last4?: string;
    name?: string;
    id: string;
    iconName: string;
  }[] = uniqBy(cards, (c) => c.id).map((c) => ({
    id: c.id,
    last4: c.cardNumber.substr(c.cardNumber.length - 4),
    name: c.name || c.expiryDate,
    title: undefined,
    iconName: c.brand,
  }));

  if (showWebPay) {
    uniqueCards.push({
      id: 'web_pay',
      title: Translation.t('texts.web_pay'),
      iconName: 'web_pay',
    });
  }

  return (
    <div className="flex-1">
      <div className="flex flex-col flex-1 content-start space-y-2.5">
        {wallet && (
          <div
						className="rounded-md p-2 bg-coolGray-50 mb-2 flex items-center"
          >
            <div
							className="w-8 h-8 rounded-lg flex items-center justify-center bg-white"
            >
							<i className="ri-wallet-2-fill ri-1x text-emerald-500" />
            </div>
            <p
							className="font-medium text-coolGray-700 ms-2 flex-1"
            >
              {Translation.t('texts.use_wallet_balance')}{' '}
              <span className="text-emerald-500">
                {wallet?.data.balance.humanized}
              </span>
            </p>
            <Switch
              disabled={
                wallet?.data?.canPay === false &&
                wallet?.data?.canPartialPay === false
              }
              value={walletSelected}
              onChange={() => setWalletSelected(!walletSelected)}
            />
          </div>
        )}
        {uniqueCards.map((item, index) => (
          <CreditCardDisplay
            key={index}
            last4={item.last4}
            name={item.name}
            title={item.title}
            selected={checkedCardId === item.id}
            onSelect={() => {
              if (!optionsDisabled) {
                setCheckedCardId(item.id);
                if (onCheckedCardChange) {
                  onCheckedCardChange(item.id);
                }
              }
            }}
            action={
              hideRadio ? null : (
                <RadioButton
                  size="small"
                  containerStyle="mt-0 mb-0"
                  inActiveColor="emerald-500"
                  value={checkedCardId === item.id}
                  disabled={optionsDisabled}
                  onChange={() => {
                    if (!optionsDisabled) {
                      setCheckedCardId(item.id);
                      if (onCheckedCardChange) {
                        onCheckedCardChange(item.id);
                      }
                    }
                  }}
                />
              )
            }
            onDelete={
              () => setSelectedCardId(item.id)
            }
            iconName={item.iconName}
          />
        ))}
      </div>
      <ConfirmationModal
        isVisible={!!selectedCardId}
        title={Translation.t('texts.confirm_card_delete')}
        confirmText={
          isFetchingCards ? <Loader /> : Translation.t('texts.delete')
        }
        onConfirm={async () => {
          if (selectedCardId && onDelete) {
            await onDelete(selectedCardId);
          }
          setSelectedCardId(null);
        }}
        onCancel={() => {
          setSelectedCardId(null);
        }}
      />
    </div>
  );
}
