import React from "react";

export default function () {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92 48.227v-21.08a6.813 6.813 0 0 0-5.213-6.667l-34.56-8.32a6.812 6.812 0 0 0-8.414 6.667v66.507A6.815 6.815 0 0 0 52.226 92L86.8 83.56a6.813 6.813 0 0 0 5.213-6.666v-2.667" stroke="#F9C12A" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M92 55.614a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm0 4.746a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm0 4.76a1.333 1.333 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.333 1.333 0 0 0 0-2.56 1.334 1.334 0 0 0 0 2.56Zm-64.627-55.6 2.854-9.333.413-1.333c.306-.974-1.227-1.334-1.52-.427l-2.854 9.333-.413 1.334c-.306.973 1.213 1.333 1.52.426Zm-24.32 1.453L12 19.573l1.253.533a.813.813 0 0 0 1.08-.28.787.787 0 0 0-.28-1.08l-8.946-3.84-1.254-.546a.84.84 0 0 0-1.08.28.813.813 0 0 0 .28 1.093Z" fill="#10B981"/>
      <path d="m14.977 4.74 2.95 9.179v.013l.023.074c.033.103.011.151-.006.178a.31.31 0 0 1-.18.114.384.384 0 0 1-.25-.012.269.269 0 0 1-.145-.173l-.411-1.327-.002-.005-2.972-9.249c-.038-.129-.011-.19.006-.219a.258.258 0 0 1 .156-.103.36.36 0 0 1 .24.017.33.33 0 0 1 .169.193l.422 1.32Z" stroke="#F9C12A"/>
      <path d="M19.173 44.267v27.92l31.614 7.733.2-44.427-31.814 8.773Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M50.467 35.533V19.227L19.213 30.04l-.067 13.32 31.32-7.827Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeLinejoin="round"/>
      <path d="m44.547 59.44-20.227.454m20.227 9.493L24.32 67.04m21.093-37.733-21.96 6.906" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M57.973 37.386v40.747l-7.186 1.787.2-44.427 6.986 1.893Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M56.453 51.92a.333.333 0 1 1 .134.653.333.333 0 0 1-.134-.653Zm0 1.627a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm.88-.814a.32.32 0 0 1 .32.333.32.32 0 0 1-.32.267.333.333 0 0 1 0-.653v.053Zm-1.693 0a.335.335 0 0 1 .4.327.334.334 0 1 1-.4-.327Zm1.693 1.627a.319.319 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm-1.693 0a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm-.813-2.44a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Zm0 1.627a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.652Zm1.627 1.626a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.626-6.493a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm0 1.614a.332.332 0 0 1 .4.326.333.333 0 1 1-.4-.326Zm.88-.801a.307.307 0 0 1 .32.32.32.32 0 1 1-.32-.32Zm-1.693 0a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm1.693 1.613a.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm-1.693 0a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm-.813-2.426a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm0 1.614a.331.331 0 0 1 .367.184.333.333 0 1 1-.367-.184Z" fill="#10B981"/>
      <path d="M56.453 51.92a.333.333 0 1 1 .134.653.333.333 0 0 1-.134-.653Zm-1.626 0a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Zm1.627-6.493a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.001 1.627a.333.333 0 0 1 .334.32.319.319 0 0 1-.334.32.32.32 0 1 1 0-.64Zm.88-.814a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334.333.333 0 0 1 0-.654Zm-1.693 0a.333.333 0 1 1 .026.667.333.333 0 0 1-.026-.666Zm1.693 1.626a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-1.693 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-.813-2.439a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 0 1 .333.32.32.32 0 0 1-.333.32.321.321 0 0 1-.296-.443.32.32 0 0 1 .296-.197Zm1.626 1.626a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-1.626 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627-6.507a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm.879-.814a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334.333.333 0 0 1 0-.654Zm-1.693.001a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.693 1.626a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.4.333.333 0 0 1 0-.653v-.067Zm-1.693.001a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.813-2.441a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Z" fill="#10B981"/>
      <path d="M56.454 45.427a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM53.2 51.92a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm1.627 0a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652ZM53.2 53.547a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm1.627 0a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.652Zm-.814-.814a.335.335 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm-1.613 0a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm1.613 1.627a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm-1.613 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-.813-2.44a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm1.613 1.626a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-3.24.001a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652ZM53.2 48.68a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32ZM53.2 50.294a.332.332 0 0 1 .4.326.333.333 0 1 1-.4-.326Zm1.627 0a.331.331 0 0 1 .367.184.333.333 0 1 1-.367-.184Zm-.814-.801a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm-1.613 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .333-.32Zm1.613 1.613a.332.332 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm-1.613 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-.813-2.426a.32.32 0 1 1-.027.638.32.32 0 0 1 .027-.638Zm0 1.613a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M53.2 51.92a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm1.627 0a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Zm-3.24 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm1.613-6.493a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM53.2 47.054a.333.333 0 0 1 .333.32.317.317 0 0 1-.207.3.32.32 0 0 1-.126.02.32.32 0 1 1 0-.64Zm1.627 0a.333.333 0 0 1 .333.32.32.32 0 0 1-.333.32.321.321 0 0 1-.296-.443.32.32 0 0 1 .296-.197Zm-.814-.814a.334.334 0 1 1 .026.668.334.334 0 0 1-.026-.668Zm-1.613 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.613 1.626a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-1.613.001a.32.32 0 1 1-.026.638.32.32 0 0 1 .026-.639Zm-.813-2.44a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm0 1.627a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .334-.32ZM53.2 48.68a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-3.24 0a.32.32 0 1 1-.027.638.32.32 0 0 1 .027-.638Zm1.613-6.507a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666ZM53.2 43.8a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm1.627 0a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm-.814-.813a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm-1.613 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.613 1.627a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm-1.613-.001a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-.813-2.439a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm0 1.626a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652Z" fill="#10B981"/>
      <path d="M53.2 45.427a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm1.627 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-3.24 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm0 6.493a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm0 1.627a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm0-6.494a.32.32 0 1 1-.027.638.32.32 0 0 1 .027-.638Zm0 1.613a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M51.587 51.92a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm0-6.493a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm0 1.627a.32.32 0 0 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .334-.32Zm0 1.626a.32.32 0 1 1-.027.638.32.32 0 0 1 .027-.638Zm0-6.506a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm0 1.626a.333.333 0 1 1-.133.652.333.333 0 0 1 .133-.652Z" fill="#10B981"/>
      <path d="M51.587 45.427a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm5.6-7.333a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm0 1.626a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm0 1.614a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Z" fill="#10B981"/>
      <path d="M57.187 38.094a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-1.627 0a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.333Z" fill="#10B981"/>
      <path d="M57.187 38.094a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666ZM55.56 39.72a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.627 0a.333.333 0 1 1-.132.652.333.333 0 0 1 .132-.652Zm-.814-.813a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-1.627 0a.334.334 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm1.627 1.626a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-.813-2.44a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm.001 1.627a.334.334 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm1.626 1.613a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.627.001a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-3.253-.001a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm2.439-4a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.626 0a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm.813.761a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.333Z" fill="#10B981"/>
      <path d="M57.187 38.094a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-3.254-.001a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm-1.627.001a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M53.933 38.093a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm-1.627 1.627a.333.333 0 1 1 .046.666.333.333 0 0 1-.046-.666Zm1.628 0a.334.334 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-.814-.814a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334.333.333 0 0 1 0-.654Zm-1.627.001a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.627 1.626a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334.333.333 0 0 1 0-.654Zm-1.627 0a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm.814.8a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm1.627 0a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.628-4.866a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm.814.866a.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32.333.333 0 0 1 0-.653Zm-1.626.001a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.652Zm.812.76a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Z" fill="#10B981"/>
      <path d="M53.933 38.093a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm-1.627 21.76a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.001-1.627a.321.321 0 0 1-.296-.442.32.32 0 0 1 .296-.198.322.322 0 0 1 .333.32.333.333 0 0 1-.333.32Zm-.813.814a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm1.626 0a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.627-1.627a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.813 2.44a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.626a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64ZM52.307 56.6a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm1.626 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.627 6.506a.334.334 0 1 1 .134-.654.334.334 0 0 1-.133.654Zm0-1.626a.334.334 0 1 1 .134-.654.334.334 0 0 1-.133.654Zm-.812.814a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm1.626-.001a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.626-1.626a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm.813 2.44a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M52.306 59.853a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm1.627 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-1.627 6.507a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-.813.814a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.627 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.627-1.627a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.627 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm.814 2.44a.334.334 0 0 1-.254-.564.334.334 0 1 1 .254.564Zm0-1.626a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm-1.628-1.628a.334.334 0 1 1 .134-.654.334.334 0 0 1-.133.654Zm1.627.001a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32ZM52.307 69.6a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Zm-.001-1.6a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-.813.786a.321.321 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm1.627.001a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-1.627-1.613a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.627-.001a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.813 2.427a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.001-1.6a.334.334 0 1 1-.027-.666.334.334 0 0 1 .027.666Z" fill="#10B981"/>
      <path d="M52.306 66.36a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.628 0a.334.334 0 0 1-.254-.564.334.334 0 1 1 .254.564Zm1.626-6.507a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm-1.627 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.627-1.627a.32.32 0 0 1-.32-.32.308.308 0 0 1 .32-.32.32.32 0 0 1 0 .64Zm-1.627.001a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.814.813a.333.333 0 1 1 .32-.374.32.32 0 0 1-.32.373Zm1.626 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm-1.626-1.627a.32.32 0 1 1 0-.639.32.32 0 0 1 0 .64Zm1.626 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.814 2.44a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 1 1 0 .64ZM55.56 56.6a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm3.254 0a.32.32 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm-1.627 6.506a.32.32 0 0 1-.32-.32.321.321 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm-1.627.001a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.627-1.627a.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.333.333.333 0 0 1 0 .654Zm-1.627 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm.814.813a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.626 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-1.626-1.627a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.626 0a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm.814 2.441a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Z" fill="#10B981"/>
      <path d="M55.56 59.853a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm-1.627 0a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm3.254 0a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665ZM55.56 66.36a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-1.626 0a.334.334 0 0 1-.254-.564.334.334 0 1 1 .254.564Zm1.626-1.627a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-1.626.001a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm.813.812a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm1.626.001a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm-1.626-1.627a.333.333 0 1 1-.027-.664.333.333 0 0 1 .027.664Zm1.626 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm.814 2.44a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-1.627-1.627a.32.32 0 0 1-.32-.32.321.321 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm-1.627.001a.333.333 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm3.254 0a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665ZM55.56 69.6a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.627 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64ZM55.56 68a.317.317 0 0 1-.3-.207.32.32 0 0 1-.02-.126.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-1.626 0a.334.334 0 1 1-.027-.666.334.334 0 0 1 .027.666Zm.813.787a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm1.626-.001a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.626-1.613a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm1.626.001a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm.814 2.426a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm0-1.6a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Z" fill="#10B981"/>
      <path d="M55.56 66.36a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-1.626 0a.334.334 0 0 1-.254-.564.334.334 0 1 1 .254.564Zm3.253 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-6.507a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 1 1 0 .64Zm0-1.626a.32.32 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm0 6.507a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Z" fill="#10B981"/>
      <path d="M57.187 59.853a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0 6.507a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.626a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0 6.493a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm0-1.6a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Z" fill="#10B981"/>
      <path d="M57.187 66.36a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-5.6 7.32a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm0-1.68a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm0-1.56a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0 4.867a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Z" fill="#10B981"/>
      <path d="M51.587 73.68a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm1.613 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M51.587 73.68a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64ZM53.2 72a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .317.317 0 0 1-.207.3.32.32 0 0 1-.126.02Zm-1.613 0a.321.321 0 0 1-.334-.32.334.334 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm.813.867a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.613-.001a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64ZM52.4 71.254a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm1.614 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm.813 2.426a.321.321 0 0 1-.296-.442.32.32 0 0 1 .296-.198.321.321 0 0 1 .333.32.333.333 0 0 1-.333.32Zm0-1.68a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32ZM53.2 70.44a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.613 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm3.24 0a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666ZM53.2 75.306a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.613.001a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm.813.813a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm1.613 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653ZM52.4 74.494a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm1.613 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.334.32Zm.814.812a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653ZM53.2 73.68a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M51.587 73.68a.333.333 0 0 1-.334-.32.32.32 0 0 1 .334-.32.32.32 0 0 1 0 .64Zm3.24 0a.321.321 0 0 1-.296-.442.32.32 0 0 1 .296-.198.321.321 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.626 0a.32.32 0 0 1-.32-.347.32.32 0 0 1 .32-.32.32.32 0 0 1 .334.32.332.332 0 0 1-.334.347Z" fill="#10B981"/>
      <path d="M54.827 73.68a.321.321 0 0 1-.296-.442.32.32 0 0 1 .296-.198.321.321 0 0 1 .333.32.333.333 0 0 1-.333.32ZM56.453 72a.32.32 0 0 1-.32-.32.334.334 0 0 1 .654 0 .319.319 0 0 1-.334.32Zm-1.626 0a.32.32 0 0 1-.32-.32.334.334 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm.813.866a.32.32 0 1 1 .027-.639.32.32 0 0 1-.027.64Zm1.693.001a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm-1.693-1.613a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.693 0a.333.333 0 0 1 0-.587.32.32 0 0 1 .32.32.32.32 0 0 1-.32.267Zm-.88-.814a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.626 0a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.626 4.866a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm-1.626 0a.334.334 0 1 1 .132-.653.334.334 0 0 1-.132.653Zm.813.814a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm1.693 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.693-1.626a.32.32 0 0 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm1.693 0a.322.322 0 0 1-.295-.198.322.322 0 0 1 .069-.349.32.32 0 0 1 .226-.093.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Zm-.88-.814a.32.32 0 0 1-.32-.347.32.32 0 0 1 .32-.32.32.32 0 0 1 .334.32.332.332 0 0 1-.334.347Z" fill="#10B981"/>
      <path d="M54.827 73.68a.321.321 0 0 1-.296-.442.32.32 0 0 1 .296-.198.321.321 0 0 1 .333.32.333.333 0 0 1-.333.32Zm.787 3.426a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm.813-.813a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-.813.813a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.627 0a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Z" fill="#10B981"/>
      <path d="M53.987 77.106a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-.814-.813a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.333Zm-1.626 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.626 1.627a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm-1.626 0a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Z" fill="#10B981"/>
      <path d="m19.173 44.267 13.334 11.32 18.8-18.76a.76.76 0 0 0-.787-1.2l-31.347 8.64Z" fill="#D1FAE5" stroke="#10B981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M70.32 49.333a15.161 15.161 0 0 0-4.76.587c-6.4 2.08-11.387 6.893-11.387 15.413s5.12 15.413 11.387 15.413c.44 0 3.547.587 4.76.587 6.667 0 12-7.186 12-16 0-8.813-5.373-16-12-16Z" fill="#10B981"/>
      <path d="M80 65.254c0 7.573-4.16 13.72-9.64 13.72s-10.227-6.147-10.227-13.72c0-7.574 4.44-13.707 9.92-13.707 5.48 0 9.947 6.133 9.947 13.707Z" fill="#D1FAE5"/>
      <path d="M73.826 71.547a2.054 2.054 0 0 1-1.466-.6l-2.307-2.32-2.32 2.32a2.052 2.052 0 0 1-1.467.6 2.001 2.001 0 0 1-1.453-.614 2.054 2.054 0 0 1 0-2.92l2.307-2.306-2.307-2.32a2.053 2.053 0 0 1 0-2.92 2.04 2.04 0 0 1 2.92 0l2.32 2.306 2.307-2.306a2.015 2.015 0 0 1 1.466-.614 2.08 2.08 0 0 1 1.467 3.534l-2.32 2.32L75.293 68a2.067 2.067 0 0 1-1.467 3.534v.013Z" fill="#fff"/>
      <path d="M73.827 60.774a1.146 1.146 0 0 1 .813.333 1.173 1.173 0 0 1 0 1.627L72.973 64.4l-1.333 1.334 1.333 1.333 1.694 1.6a1.173 1.173 0 0 1 0 1.627 1.16 1.16 0 0 1-1.627 0l-1.667-1.667-1.333-1.333-1.333 1.333-1.667 1.667a1.147 1.147 0 0 1-1.613 0 1.148 1.148 0 0 1 0-1.627L67.08 67l1.333-1.333-1.333-1.333-1.613-1.6a1.148 1.148 0 0 1 .8-1.96 1.146 1.146 0 0 1 .813.333l1.667 1.667 1.333 1.333 1.333-1.333 1.667-1.667a1.147 1.147 0 0 1 .813-.333h-.066Zm.066-1.84a2.973 2.973 0 0 0-2.12.88l-1.72 1.653-1.666-1.653A2.986 2.986 0 0 0 64.16 64l1.653 1.667-1.653 1.653a2.989 2.989 0 0 0 4.227 4.227l1.666-1.653 1.654 1.653a3.001 3.001 0 0 0 5.215-2.034A3 3 0 0 0 76 67.36l-1.667-1.653L76 64a3 3 0 0 0-2.12-5.106l.013.04Z" fill="#10B981"/>
    </svg>

  )
}
