import React from 'react'
import Modal from 'react-modal'
import Translation from '../../helper/Translation';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

type ModalProps = {
  visible: boolean;
  onClose: () => void;
  message: {
    files: any[];
    comment: string;
  };
};

const MessageDetailsModal
  = ({ visible, onClose, message }: ModalProps) => {
    return (
      <Modal
        className="w-11/12 md:w-1/2 lg:w-1/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 focus-within:outline-none"
        overlayClassName="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        isOpen={visible}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
      >
        <div
          className="bg-white rounded-lg"
        >
          <div className="flex items-center p-2 justify-between bg-coolGray-50 rounded-t-lg">
            <div className="w-8"></div>
            <p className="text-center font-bold text-coolGray-700">
              {Translation.t('dispute.labels.view_details')}
            </p>
            <button
              onClick={onClose}
            >
              <i className="ri-close-circle-fill r-2x text-coolGray-400" />
            </button>
          </div>


          <div className="px-4 py-6 flex flex-col gap-6">

            <div>
              <p className="text-coolGray-900 font-medium mb-2">{Translation.t('dispute.labels.comment')}</p>
              <p className="text-coolGray-500 text-sm">{message.comment}</p>
            </div>

            {message.files?.length > 0 && (
              <>
                <hr />

                <div>
                  <p className="text-coolGray-900 font-medium mb-2">{Translation.t('dispute.labels.files')}</p>

                  <div className="flex flex-wrap gap-3">
                    <PhotoProvider>
                      {message.files.map((file, index) => {
                        const elementSize = 400;
                        return <PhotoView
                          key={index}
                          width={elementSize}
                          height={elementSize}
                          render={({ scale, attrs }) => {
                            const width = attrs.style.width;
                            const offset = (width - elementSize) / elementSize;
                            const childScale = scale === 1 ? scale + offset : 1 + offset;
                            return (
                              <div {...attrs} className="PhotoView__Photo flex items-center justify-center">
                                <div style={{ transform: `scale(${childScale})`, width: elementSize, transformOrigin: '0 0' }}>
                                  {file.type === 'video' ? (
                                    <video controls>
                                      <source src={file.url} />
                                    </video>
                                  ) : (
                                    <img src={file.url} />
                                  )}
                                </div>
                              </div>
                            );
                          }}
                        >
                          {file.type === 'video' ? (
                            <video className="w-28 h-28 border rounded-lg cursor-pointer">
                              <source src={file.url} />
                            </video>
                          ) : (
                            <img src={file.url} alt="" className="w-28 h-28 border rounded-lg cursor-pointer" />
                          )}
                        </PhotoView>
                      })}
                    </PhotoProvider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }

export default MessageDetailsModal
