import React, { useState, useRef, useEffect } from "react";
import BaseLayout from "./BaseLayout";
import Header from "./Header";
import { initialStateProps } from "../Fraud";
import ImageUploader from "../../src/Components/Posting/ImageUploader";
import Translation from "../../src/helper/Translation";
import BackendCall, { HttpUnprocessableEntity } from "../../src/Components/BackendCall";
import { serialize } from 'object-to-formdata';
import { toast } from "react-toastify";


interface ReportFormProps {
  state: initialStateProps;
  dispatch: React.Dispatch<any>;
  locale: string ;
  token: string;
}
function ReportForm ({ locale,token, state ,dispatch }: ReportFormProps) {
  const [form , setForm] = useState({ images: [] , new_images: [] , comments: ''})
  const [isLoading ,setIsLoading] = useState(false)
  const [reportSent , setReportSent] = useState(false)
  const handleFormChanges = (payload) => {
    const newForm = {...form}
    payload.map(element=> {
      newForm[element.key]= element.value
    })
    setForm(newForm)
  }

  const sendReport = ()=> {

    if(isLoading || !form.comments) return;

    setIsLoading(true)
    let data = { ...form ,number: state.number , country_code: state.countryCode , attachments: form.images , report_type: 'fraud' }

    const options = {
      indices: false,
      allowEmptyArrays: false,
    };

    const formData = serialize(
      data,
      options
    );

    BackendCall
    .i({token: token , locale: locale})
    .submitInvestigation(formData)
    .then(res=> setReportSent(true))
    .catch(error=> {
      setIsLoading(false)
      let errorMessage = Translation.t('texts.operation_failed');
      if (error instanceof HttpUnprocessableEntity) {
        errorMessage = error.messageStr();
      }
      toast.error(errorMessage)
    })
  }


  return (
    <BaseLayout>
      <>
        <Header title={`+${state.number}`} description={reportSent ? Translation.t("frauds.messages.thanks_for_cooperation") : Translation.t("frauds.messages.search_fraud")} />
        {!reportSent && (<form className="w-full flex flex-col md:gap-6 gap-4">
          <div className="flex flex-col gap-1 w-full">
            <p className="text-medium font-normal">{Translation.t("texts.write_message")}<span className="ms-1 text-red-600">*</span></p>
            <textarea rows={5} className="rounded-md bg-white border border-coolGray-200 focus:outline-none focus:border-coolGray-200 focus:ring-0" onChange={(e)=> setForm((prev) => {
              return {...prev, comments: e.target.value}
            }) } />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <p className="text-medium font-normal">{Translation.t("create_new_ad.ad_images")}</p>
            <ImageUploader state={ {...form} } dispatch={(newFiles)=> handleFormChanges(newFiles.payload)} validateImage={()=> {}}/>
          </div>

          <div className="w-full pt-2">
            <button type="button" disabled={isLoading || !form.comments} className={`text-white ${isLoading || !form.comments ? 'cursor-not-allowed': ''} text-base leading-6 font-normal bg-white text-red-600 border border-red-100 w-full flex items-center justify-center rounded-md py-2`} onClick={sendReport}>{Translation.t("frauds.actions.send_report")}</button>
          </div>
        </form>)}
        {reportSent && (
        <div className="w-full pt-2">
          <button className="text-white text-base leading-6 font-normal bg-emerald-500 w-full flex items-center justify-center rounded-md py-2" onClick={()=> {
            dispatch({
              type: 'update_key' ,
              payload: { key:'mode' , value:'search' }
            })
          }}>{Translation.t("buttons.done")}</button>
        </div>
        )}

      </>
    </BaseLayout>
  )
}

export default ReportForm
