import Button from "../Components/Button"
import SeparatedModal from "./SeparatedModal"
import Input from "./Input"
import Translation from '../helper/Translation';
import React from 'react';
import 'remixicon/fonts/remixicon.css';
import { CreditCard } from '@shobbak/react-services/dist/Entities';

type ValidationType = {
  isEmpty: boolean;
  isValid: boolean;
  errorMessage?: string;
}|null;

type ConfirmCardModalProps = {
  visible: boolean;
  card?: CreditCard;
  currency: string;
  amount: string;
  onClose: () => void;
  onConfirm: (cvv) => void;
};

const renderIcon = (brand: string) => {
  switch (brand?.toLowerCase()) {
    case 'visa':
      return <i className="ri-visa-line text-emerald-500 ri-2x"/>;
    case 'mastercard':
      return <i className="ri-mastercard-line text-emerald-500 ri-2x"/>;
    case 'mada':
      return ( <i className="ri-visa-line text-emerald-500 ri-2x"/> );
    default:
      return ( <i className="ri-visa-line text-emerald-500 ri-2x"/> );
  }
};

export default function ConfirmCardModal({
                                           visible,
                                           card,
                                           onClose,
                                           onConfirm,
                                           currency,
                                           amount,
                                         }: ConfirmCardModalProps) {
  const [cvvValidation, setCVVValidation] =
    React.useState<ValidationType>(null);
  const [cvv, setCvv] = React.useState<string>();
  return (
    <SeparatedModal
      visible={visible}
      onClose={onClose}
      headerLabel={Translation.t('texts.cvv_required')}
      closeBtn
      footer={
        <Button
          containerStyle="w-full"
          label={Translation.t('buttons.pay')}
          disabled={cvvValidation?.isEmpty || !cvvValidation?.isValid}
          onPress={() => {
            onConfirm(cvv);
          }}
        />
      }
      body={
        <>
          <div className={`flex p-2 bg-coolGray-50 rounded-md items-center mb-4`}>
            <div className={"w-9 h-9 rounded-xl bg-white flex items-center justify-center me-2"}>
              {card && renderIcon(card?.brand)}
            </div>
            <div className="flex flex-col">
              <span className={"text-coolGray-700 mb-1 text-left text-sm"}>{card?.cardNumber}</span>
              <span className={'text-coolGray-500 text-xs'}>{card?.name}</span>
            </div>
          </div>
          <p className={'mb-2'}>{Translation.t('inputs.card_cvv.label')}
            <span className="text-red-500">*</span>
          </p>

          <Input
            placeholder="932"
            value={cvv}
            message={cvvValidation?.errorMessage || ' '}
            status={cvvValidation?.errorMessage ? 'error' : 'normal'}
            maxLength={3}
            onChange={(event) => {
              let val = event.target.value
              setCvv(val);
              setCVVValidation({
                isEmpty: !val.length,
                isValid: val.length > 2,
                errorMessage: !val.length
                  ? Translation.t('inputs.card_cvv.required')
                  : '',
              });
            }}
          />

          <div className="flex">
            <i className="ri-information-fill text-coolGray-400 text-2xl "/>
            <div className={'text-left flex-1 ml-2 text-xs text-coolGray-400'}>
              {Translation.t('texts.cvv_description')}
            </div>
          </div>
        </>
      }
    />
  );
}
