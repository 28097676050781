import React, { useEffect, useState } from 'react'
import { Business, BusinessMetaData, User, UserMeta } from '@shobbak/react-services/dist/Entities'
import Translation from '../../helper/Translation'
import WalletIcon from '../icon/WalletIcon'
import BackendCall from '../BackendCall'
import ImageAddFill from '../icon/ImageAddFill'
import ArrowDownLine from '../icon/ArrowDownLine'
import ArrowUpLine from '../icon/ArrowUpLine'

interface Props {
  user?: User;
  businessProfile?: Business;
  token: string;
  locale: string;
}

export default function DashboardStats({ user, businessProfile, token, locale }: Props) {
  const [stats, setStats] = useState<UserMeta>()

  useEffect(() => {
    if (businessProfile?.id) {
      getBusinessPRofileMeta()
    } else if (user?.id) {
      getUserMeta()
    }
  }, [])

  function getBusinessPRofileMeta() {
    BackendCall.i({ token, locale })
      .getCurrentBusinessProfileMeta()
      .then(response => setStats(response))
      .catch(() => { })
  }

  function getUserMeta() {
    BackendCall.i({ token, locale })
      .getMyAccountMeta()
      .then(response => setStats(response))
      .catch(() => { })
  }

  return (
    <div className="grid grid-cols-2 lg:flex lg:justify-between py-4 px-2 border border-coolGray-200 rounded-md">
      <div className="flex items-center lg:mb-0 mb-4">
        <span className="flex items-center justify-center h-10 w-10 me-2 bg-blue-50 rounded-full">
          <ImageAddFill className="fill-current text-blue-500" />
        </span>
        <div className="flex-col">
          <p className="text-coolGray-700 text-sm leading-5 font-normal">{stats?.adsCount ? stats.adsCount : '0'}</p>
          <span className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('buttons.my_posts')}</span>
        </div>
      </div>

      <div className="flex items-center lg:mb-0 mb-4">
        <span className="flex items-center justify-center h-10 w-10 me-2 bg-orange-50 rounded-full">
          <ArrowDownLine className="fill-current text-orange-500" />
        </span>
        <div className="flex-col">
          <p className="text-coolGray-700 text-sm leading-5 font-normal">{stats?.offersReceived ? stats.offersReceived : '0'}</p>
          <span className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('buttons.orders_received')}</span>
        </div>
      </div>

      <div className="flex items-center lg:mb-0 mb-4">
        <span className="flex items-center justify-center h-10 w-10 me-2 bg-red-50 rounded-full">
          <ArrowUpLine className="fill-current text-red-500" />
        </span>
        <div className="flex-col">
          <p className="text-coolGray-700 text-sm leading-5 font-normal">{stats?.offersSent ? stats.offersSent : '0'}</p>
          <span className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('buttons.orders_sent')}</span>
        </div>
      </div>

      <div className="flex items-center lg:mb-0 mb-4">
        <span className="flex items-center justify-center h-10 w-10 me-2 bg-emerald-50 rounded-full">
          <WalletIcon className="fill-current text-emerald-500" />
        </span>
        <div className="flex-col">
          <p className="text-coolGray-700 text-sm leading-5 font-normal">{stats?.walletBalance ? stats?.walletBalance?.humanized : '0'}</p>
          <span className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('texts.balance')}</span>
        </div>
      </div>
    </div>
  )
}
