import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import Translation from '../../../helper/Translation';
import AuctionTitleWithDescription from '../AuctionTitleWithDescription';
import TimerContainer from '../TimerContainer';

type Props = {
  auction: PostingAuction;
};

function CanceledScenario({auction}: Props) {
	return (
    <>
      {/* start timer container */}
      <div className="flex items-center">
        <p className="text-lg font-bold text-coolGray-700 flex-1">{Translation.t("auctions.labels.live_auction_fire")}</p>
        <TimerContainer
          auction={auction}
          containerStyle="px-1.5 py-2"
        />
      </div>
      {/* end timer container  */}


      <hr className="mt-4" />


      <AuctionTitleWithDescription 
        title={auction?.statusTitle} 
        description={auction?.statusDescription} 
      />
    
    </>
  );
}

export default CanceledScenario;