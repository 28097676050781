import { BusinessMetaData, Document } from '@shobbak/react-services/dist/Entities'
import React, { useState, Dispatch, FC, forwardRef, useImperativeHandle } from 'react'
import { BusinessProfileState } from '../../../../components/CreateBusinessProfile'
import DocumentsUploader from '../../DocumentsUploader'
import Translation from '../../../helper/Translation'
interface DocumentedFilesProps {
  state: BusinessProfileState;
  dispatch: Dispatch<any>;
  businessMeta: BusinessMetaData;
  mode: 'create' | 'update';
}

const DocumentedFiles: FC<DocumentedFilesProps> = forwardRef(({ state, dispatch, businessMeta, mode = 'create' }, ref) => {
  const [errorBag, setErrorBag] = useState({})

  useImperativeHandle(ref, () => ({
    validateStep() {
      return new Promise((resolve, reject) => {
        if (mode == 'update') {
          return resolve(true)
        }

        let errors = {}

        businessMeta.documents.forEach(d => {
          if (d.required == false) return

          const container = state.documents.find(c => c.id == d.id)

          if (container == undefined || container == null) return

          if (container.documents.length == 0) {
            errors[`document-${d.id}`] = Translation.t('errors.messages.required_field', { field: d.name })
          }
        })

        if (Object.keys(errors).length == 0) {
          return resolve(true)
        }

        setErrorBag(errors)
        return reject()
      })
    }
  }))

  if (!state?.documents || state.documents == undefined || state.documents == null) return <></>

  return (
    <div>
      <div className="mb-4">
        <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.upload_the_documented_files')}</h3>
        <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.upload_documented_detail')}</h4>
      </div>

      {businessMeta?.documents?.map((document: Document) => {
        if (state.documents.length == 0) return <div key={document.id}></div>

        const documentsContainer = state?.documents?.find(container => container.id == document.id)

        if (documentsContainer == null || documentsContainer == undefined) return <div key={document.id}></div>

        const { documents } = documentsContainer

        return (
          <div key={document.id} className="mb-4">
            <div className="w-full flex flex-col">
              <h4 className="text-base leading-7 font-semibold text-coolGray-700">
                {document.name} {document.required ? '' : <span className="text-coolGray-400">{Translation.t('texts.business.optional')}</span>}
              </h4>
              <DocumentsUploader
                keyName={document.id}
                documents={documents}
                disabled={documents.length == document.documentsLimit}
                limit={document.documentsLimit}
                accept={document.documentTypes.map(extension => `.${extension}`).join(',')}
                onUpload={files => {
                  const documentsContainer = state.documents.find(container => container.id == document.id)
                  if (document.documentsLimit < (documentsContainer.documents.length + files.length)) return

                  const new_documents = state.documents.map(container => {
                    if (container.id != document.id) return container
                    return {
                      id: container.id,
                      documents: [...container.documents, ...files]
                    }
                  })

                  dispatch({ type: 'key_was_updated', payload: { key: 'documents', value: new_documents } })
                }}
                onDeleted={index => {
                  const new_documents = state.documents.map(container => {
                    if (container.id != document.id) return container
                    return {
                      id: container.id,
                      documents: container.documents.filter((d, i) => index != i)
                    }
                  })

                  dispatch({ type: 'key_was_updated', payload: { key: 'documents', value: new_documents } })
                }}
              />
              <span className="text-red-500 text-sm">
                {errorBag[`document-${document.id}`]}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default DocumentedFiles