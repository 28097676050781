import { LineItem, PostingOffer } from '@shobbak/react-services/dist/Entities';
import BackendCall, { HttpUnprocessableEntity } from './BackendCall';
import AppContext from '../Services/AppContext';
import Translation from '../helper/Translation';
import React, { useEffect, useMemo, useState } from 'react';
import { CreditCard } from '@shobbak/react-services/dist/Entities';
import AddPaymentMethodForm from './AddPaymentMethodForm';
import PaymentMethodsList, { PaymentMethodName } from './PaymentMethodsList';
import CreditCardDisplay from './PaymentMethodCard';
import ConfirmCardModal from './ConfirmCardModal';
import { renderOfferMessages } from './Offer/renderOfferMessages';
import PricesBreakDown from './Offer/PricesBreakDown';
import { HandlePaymentParams } from './Offer/Actions/PaymentAction';
import Loader from './icon/Loader';
import { toast } from 'react-toastify';
import Divider from './Divider';
import SeparatedModal from './SeparatedModal';
import Button from './Button';
import 'remixicon/fonts/remixicon.css';
import RadioButton from './RadioButton';
import PaymentMethodSquareCard from './PaymentMethodSquareCard';
import AddPaymentMethodFormCompact from './AddPaymentMethodFormCompact';
import WalletIcon from './icon/WalletIcon';
import BankCardIcon from './icon/BankCardIcon';
import PaymentIsProtected from './PaymentIsProtected';
import { ApplePay } from '../helper/ApplePay';

const ExtraWallet = ({ balance }) => {
  return (
    <div
      className="p-2 rounded-lg bg-coolGray-50 mt-2 flex items-center"
    >
      <div
        className="w-8 h-8 rounded-xl items-center justify-center bg-white"
      >
        <i className="ri-wallet-2-fill ri-1x text-emerald-500" />
      </div>
      <p
        className="font-medium text-coolGray-700 ms-2 flex-1 text-sm">
        {Translation.t('texts.use_wallet_balance')}{' '}
        <p className="text-emerald-500 text-sm">{balance}</p>
      </p>
    </div>
  );
};

type PaymentButtonsProps = {
  size?: 'sm' | 'lg';
  isDisabled?: boolean;
  amount: string;
  currency: string;
  paymentOptions: any;
  onFailure: (message: any) => void;
  onSuccess: (handlePaymentParams: HandlePaymentParams) => void;
  isProcessingPayment: boolean;
  onStartPayment: () => void;
  onEndPayment: () => void;
  readyToPay: boolean;
  cardPayAllowed: boolean;
  applePayAllowed: boolean;
  webPayAllowed: boolean;
  walletPayAllowed: boolean;
  canPayByWallet: boolean;
  offerAmount: any;
  deliveryFee: any;
  lineItems: LineItem[];
  messages?: any;
  withWalletLineItems?: LineItem[];
  coveredByGiftCard?: boolean;
  offer?: PostingOffer;
  selectedGiftCard?: any;
  onPaymentMethodUpdate?: (method: string) => any;
};

const PaymentButtons = ({
  size = 'lg',
  isDisabled = false,
  amount,
  currency,
  paymentOptions,
  onSuccess,
  isProcessingPayment,
  readyToPay,
  cardPayAllowed,
  applePayAllowed,
  webPayAllowed,
  walletPayAllowed,
  canPayByWallet,
  offerAmount,
  deliveryFee,
  lineItems,
  messages = [],
  withWalletLineItems,
  onStartPayment,
  onEndPayment,
  coveredByGiftCard = false,
  offer,
  selectedGiftCard,
  onPaymentMethodUpdate,
}: PaymentButtonsProps) => {
  const appContext = React.useContext(AppContext);

  const [cards, setCards] = React.useState<CreditCard[]>([]);
  const [isFetchingCards, setIsFetchingCards] = React.useState<boolean>(false);
  const [showSelectMethodModal, setShowSelectMethodModal] =
    React.useState<boolean>(false);
  const [isNewCardModalVisible, setNewCardModalVisible] =
    React.useState<boolean>(false);
  const [selectedCard, setSelectedCard] = React.useState<CreditCard | null>(
    null,
  );
  const [selectedMethodName, setSelectedMethodName] = React.useState<string>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<PaymentMethodName>(selectedGiftCard && offer?.coveredByGift ? null :  applePayAllowed
    ? 'apple_pay' : cardPayAllowed ? 'card_pay' : 'web_pay');
  const [chargeWallet, setChargeWallet] = React.useState<boolean | null>(null);

  const [confirmCardModalVisible, setConfirmCardModalVisible] =
    React.useState<boolean>(false);
  const walletOption = paymentOptions?.find(
    (paymentOption) => paymentOption.paymentType === 'wallet',
  );

  const allowedPaymentMethods = useMemo(() => {
    let methods = [];
    if (ApplePay.isAvailable() && applePayAllowed) methods = [...methods, 'apple_pay'];
    if (cardPayAllowed) methods = [...methods, 'card_pay'];
    if (webPayAllowed) methods = [...methods, 'web_pay'];
    methods = [...methods, 'wallet']
    return methods;
  }, [cardPayAllowed, webPayAllowed, applePayAllowed])

  const cardPayIsDisabled = useMemo(() => {
    return selectedPaymentMethod == "wallet" || offer?.coveredByGift
  }, [offer, selectedPaymentMethod, selectedGiftCard])

  useEffect(() => {
    if (!onPaymentMethodUpdate) return

    onPaymentMethodUpdate(selectedPaymentMethod)
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (chargeWallet && (offer?.canPayByWallet || canPayByWallet)) {
      setSelectedPaymentMethod("wallet")
    } else {
      if (selectedPaymentMethod == "wallet") setSelectedPaymentMethod(selectedGiftCard && offer?.coveredByGift ? null : cardPayAllowed ? 'card_pay' : 'web_pay')
    }
  }, [chargeWallet, offer])

  React.useEffect(() => {
    async function fetchCards() {
      setIsFetchingCards(true);
      try {
        const cards = await BackendCall.i().getCreditCards();
        setIsFetchingCards(false);
        setCards(cards);
        if (selectedPaymentMethod === 'card_pay') {
          if (cards.length) {
            setSelectedCard(cards[0]);
          } else {
            setSelectedCard(null);
          }
        }
      } catch (error) {
        setIsFetchingCards(false);
      }
    }

    if (cardPayAllowed) {
      fetchCards();
    }
  }, []);

  const renderPaymentMethod = () => {
    if (selectedPaymentMethod === 'card_pay') {
      return isFetchingCards ? (
        <div className="w-full flex-1">
          <Loader />
        </div>
      ) : selectedCard ? (
        <>
          <CreditCardDisplay
            last4={selectedCard.cardNumber.substr(selectedCard.cardNumber.length - 4)}
            name={selectedCard.name || selectedCard.expiryDate}
            iconName={selectedCard?.brand}
          />
        </>
      ) : (
        <button
          className="mt-2 w-full"
          onClick={() => {
            setNewCardModalVisible(true);
          }}
        >
          <div className="p-2 border rounded-lg flex items-center justify-center border-dashed border-emerald-500">
            <div
              className="w-8 h-8 rounded-xl bg-emerald-50 flex items-center justify-center me-4 ">
              <i className="ri-bank-card-fill ri-1x text-emerald-500" />
            </div>
            <p className="text-emerald-500 flex-1 font-bold leading-7 text-start">
              {Translation.t('buttons.add_new_credit_card')}
            </p>
            <i className="ri-add-circle-fill ri-1x text-emerald-500" />
          </div>
        </button>
      );
    } else if (selectedPaymentMethod === 'web_pay') {
      return (
        <CreditCardDisplay
          title={Translation.t('texts.web_pay')}
          iconName="web_pay"
          action={null}
        />
      );
    } else if (selectedPaymentMethod === 'wallet') {
      return (
        <CreditCardDisplay
          title={Translation.t('texts.wallet')}
          name={`${Translation.t('texts.current_balance')} ${walletOption.data.balance.humanized
            }`}
          iconName="wallet"
        />
      );
    }
  };

  const processPayment = () => {
    if (readyToPay) {
      if (selectedCard && selectedPaymentMethod === 'card_pay') {
        if (selectedCard.cvvRequired) {
          setConfirmCardModalVisible(true);
        } else {
          onSuccess({
            card_id: selectedCard.id,
            payment_handler: 'card_pay',
            payment_method: 'checkout',
            charge_wallet_too: chargeWallet,
            request_from: 'web'
          });
        }
      } else if (selectedPaymentMethod === 'apple_pay') {
        const applePaymentOptions = paymentOptions?.find(
          (paymentOption) =>
            paymentOption.paymentType ===
            `${chargeWallet ? 'apple-pay-with-wallet' : 'apple-pay'}`,
        );
        // Check if ApplePay is available
        if (applePayAllowed && applePaymentOptions) {
          ApplePay.requestPayment(
            applePaymentOptions.data,
            (applePaymentToken) => {
              onSuccess({
                charge_wallet_too: chargeWallet,
                payment_handler: 'apple_pay',
                payment_method: {
                  display_name: applePaymentToken.paymentMethod.displayName,
                  type: applePaymentToken.paymentMethod.type,
                  network: applePaymentToken.paymentMethod.network,
                },
                payment_data: applePaymentToken.paymentData,
              });
            }
          )
        } else {
          toast.error(Translation.t('texts.failed'));
        }
      } else if (selectedPaymentMethod === 'web_pay') {
        onSuccess({
          charge_wallet_too: chargeWallet,
          payment_handler: 'web_pay',
          payment_method: 'checkout',
          request_from: 'web'
        });
      } else if (selectedPaymentMethod === 'wallet') {
        if (canPayByWallet) {
          onSuccess({
            charge_wallet_too: chargeWallet,
            payment_handler: 'wallet',
            payment_method: 'wallet',
            request_from: 'web'
          });
        } else {
          toast.error(Translation.t('texts.no_enough_balance'));
        }
      }
    } else {
      toast.warning('Please choose address');
    }
  };

  const payByGiftCard = () => {
    onStartPayment()
    BackendCall.i()
      .actionPostingOffers(offer.id, 'pay', {
        user_shobbak_card_id: selectedGiftCard?.id,
      })
      .then(async (offer) => {
        onEndPayment();
        window.location.reload()
      })
      .catch(async (error) => {
        onEndPayment();
        let message = Translation.t('texts.offer_update_failed');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message);
      })
      .finally(() => {
        onEndPayment();
      });
  };

  const payAfterCvvConfirmed = (cvv) => {
    setConfirmCardModalVisible(false);
    onSuccess({
      charge_wallet_too: chargeWallet,
      card_id: selectedCard?.id,
      payment_handler: 'card_pay',
      payment_method: 'checkout',
      card_cvv: cvv,
      request_from: 'web'
    });
  };

  function renderOldImplementation() {
    return (
      <div className="flex-1 w-full">
        {renderOfferMessages(messages, 'above_payment')}
        <div className="p-4">
          <div className="flex items-center mb-4">
            <p className="font-bold flex-1 text-coolGray-700">{Translation.t('texts.payment_method')}</p>
            <button onClick={() => setShowSelectMethodModal(true)}>
              <p className="text-coolGray-700"> {Translation.t('buttons.change')} </p>
            </button>
          </div>

          {renderPaymentMethod()}
          {selectedPaymentMethod &&
            selectedPaymentMethod !== 'wallet' &&
            chargeWallet && (
              <ExtraWallet balance={walletOption?.data?.balance.humanized} />
            )}
        </div>
        {renderOfferMessages(messages, 'under_payment')}

        <Divider height={8} />

        <PricesBreakDown
          wallet={walletOption}
          showWallet={chargeWallet}
          paymentMethod={selectedPaymentMethod}
          lineItems={chargeWallet && withWalletLineItems ? withWalletLineItems : lineItems}
        />

        <Button
          disabled={
            isDisabled ||
            !readyToPay ||
            isProcessingPayment ||
            (selectedPaymentMethod === 'card_pay' &&
              (!selectedCard || isFetchingCards))
          }
          loading={isProcessingPayment}
          type="solid"
          color={selectedPaymentMethod === 'apple_pay' ? "coolGray-900" : "emerald-500"}
          size="large"
          label={
            selectedPaymentMethod === 'apple_pay'
              ? 'Apple Pay'
              : Translation.t('buttons.pay', { currency, amount })
          }
          onPress={processPayment}
          containerStyle={"px-6 w-full"}
        />

        <SeparatedModal
          onClose={() => {
            setShowSelectMethodModal(false);
          }}
          closeBtn
          visible={showSelectMethodModal}
          headerLabel={Translation.t('texts.payment_method')}
          footer={
            <Button
              containerStyle="w-full"
              label={Translation.t('buttons.save')}
              onPress={() => {
                if (
                  selectedMethodName === 'web_pay'
                ) {
                  setSelectedPaymentMethod(selectedMethodName);
                  setSelectedCard(null);
                } else if (selectedMethodName === 'wallet') {
                  setSelectedPaymentMethod('wallet');
                  setSelectedCard(null);
                } else {
                  setSelectedPaymentMethod('card_pay');
                  const card =
                    cards.find((c) => c.id === selectedMethodName) || null;
                  setSelectedCard(card);
                }
                setShowSelectMethodModal(false);
              }}
            />
          }
          body={
            <>
              <PaymentMethodsList
                onChargeWalletChange={(value) => setChargeWallet(value)}
                chargeWallet={chargeWallet}
                wallet={walletOption}
                showWebPay={webPayAllowed}
                cards={cardPayAllowed ? cards : []}
                isFetchingCards={cardPayAllowed ? isFetchingCards : false}
                defaultCheckedCardId={
                  selectedPaymentMethod === 'wallet'
                    ? 'wallet'
                    : selectedCard?.id ||
                    ('web_pay')
                }
                onCheckedCardChange={(cardId) => {
                  setSelectedMethodName(cardId);
                }}
              />
              {cardPayAllowed && (
                <button
                  className="mt-2 w-full"
                  onClick={() => {
                    setShowSelectMethodModal(false);
                    setTimeout(() => {
                      setNewCardModalVisible(true);
                    }, 0);
                  }}
                >
                  <div
                    className="p-2 rounded-lg border border-dashed border-emerald-500 flex items-center justify-center"
                  >
                    <div
                      className="w-8 h-8 rounded-xl bg-emerald-50 flex items-center justify-center me-4">
                      <i className="ri-bank-card-fill ri-1x text-emerald-500" />
                    </div>
                    <p className="font-bold flex-1 text-emerald-500 text-start text-sm">
                      {Translation.t('buttons.add_new_credit_card')}
                    </p>
                    <i
                      className="ri-add-circle-fill ri-1x text-emerald-500"
                    />
                  </div>
                </button>
              )}
            </>
          }
        />

        <SeparatedModal visible={isNewCardModalVisible}
          onClose={() => setNewCardModalVisible(false)}
          headerLabel={"Add New Card"}
          closeBtn
          body={<AddPaymentMethodForm
            onCancel={() => setNewCardModalVisible(false)}
            onSuccess={async (card) => {
              setNewCardModalVisible(false);
              try {
                setIsFetchingCards(true);
                await BackendCall.i().tokenizeCreditCard({
                  token: card.token,
                  name: card.name,
                });
                const cards = await BackendCall.i().getCreditCards();
                setCards(cards);
                setSelectedCard(
                  cards.find(
                    (c) =>
                      c.cardNumber.substr(c.cardNumber.length - 4) === card.last4,
                  ) || null,
                );
                setSelectedPaymentMethod('card_pay');
                toast.success(Translation.t('texts.card_add_success'));
                setIsFetchingCards(false);
              } catch (error) {
                toast.error(
                  error.message
                );
                setIsFetchingCards(false);
              }
            }}
            publicKey={appContext.env?.CHECKOUT_PUBLIC_KEY}
            customRender={(renderForm, renderButton) => {
              return (
                <SeparatedModal
                  visible={isNewCardModalVisible}
                  body={renderForm()}
                  footer={renderButton()}
                  closeBtn
                  onClose={() => setNewCardModalVisible(false)}
                  headerLabel={Translation.t('buttons.add_payment_method')}
                />
              );
            }}
          />}
        />


        {
          selectedCard && (
            <ConfirmCardModal
              currency={currency}
              amount={amount}
              visible={confirmCardModalVisible}
              card={selectedCard}
              onClose={() => {
                setConfirmCardModalVisible(false);
              }}
              onConfirm={payAfterCvvConfirmed}
            />
          )
        }
      </div >
    );
  }

  function renderPaymentMethods() {
    return (
      <div>
        {allowedPaymentMethods.filter(method => method != "wallet").map(method => {
          const isSelected = selectedPaymentMethod == method;
          if (method == "apple_pay") {
            return (
              <div key={method} className={`mb-4 p-4 border rounded-lg ${isSelected ? 'border-emerald-500' : 'border-coolGray-200'}`}>
                <div className="flex items-center space-x-2">
                  <RadioButton disabled={cardPayIsDisabled} containerStyle="flex items-center justify-center hover:cursor-pointer" value={isSelected} onChange={() => setSelectedPaymentMethod(method)} />
                  <i className="ri-apple-fill ri-xl text-coolGray-300" />
                  <h4 className="text-normal font-semibold leading-6 text-coolGray-700">Apple Pay</h4>
                </div>
              </div>
            )
          }
          if (method == "card_pay") {
            return (
              <div key={method} className={`mb-4 p-4 border rounded-lg ${isSelected ? 'border-emerald-500' : 'border-coolGray-200'}`}>
                <div className={`flex items-center space-x-2 ${isSelected ? "mb-4" : ""}`}>
                  <RadioButton disabled={cardPayIsDisabled} containerStyle="flex items-center justify-center hover:cursor-pointer" value={isSelected} onChange={() => setSelectedPaymentMethod(method)} />
                  <BankCardIcon className={`w-5 h-5 fill-current ${isSelected ? "text-emerald-500" : "text-coolGray-300"}`} />
                  <h4 className="text-normal font-semibold leading-6 text-coolGray-700">{Translation.t('texts.pay_card')}</h4>
                </div>
                <div className={`${selectedPaymentMethod != method ? "hidden" : ""}`}>
                  <div>
                    <p className="text-normal font-medium leading-5 text-coolGray-700 mb-2">{Translation.t('buttons.add_new_credit_card')}</p>
                    <AddPaymentMethodFormCompact
                      onCancel={() => setNewCardModalVisible(false)}
                      onSuccess={async (card) => {
                        setNewCardModalVisible(false);
                        try {
                          setIsFetchingCards(true);
                          await BackendCall.i().tokenizeCreditCard({
                            token: card.token,
                            name: card.name,
                          });
                          const newCards = await BackendCall.i().getCreditCards();
                          setCards(newCards);
                          setSelectedCard(newCards.find(c => c.cardNumber.substr(c.cardNumber.length - 4) === card.last4))
                          setSelectedPaymentMethod('card_pay');
                          toast.success(Translation.t('texts.card_add_success'));
                          setIsFetchingCards(false);
                        } catch (error) {
                          toast.error(
                            error.message
                          );
                          setIsFetchingCards(false);
                        }
                      }}
                      publicKey={appContext.env?.CHECKOUT_PUBLIC_KEY}
                    />
                  </div>

                  {cards.length > 0 && (
                    <div className="mb-4">
                      <p className="text-normal font-medium leading-5 text-coolGray-700 mb-2">{Translation.t('texts.saved_cards')}</p>
                      <div className={`grid grid-cols-1 ${size === 'sm' ? 'md:grid-cols-2' : 'md:grid-cols-3'} gap-4`}>
                        {cards.map((card) => {
                          return (
                            <PaymentMethodSquareCard
                              key={card.id}
                              id={card.id}
                              last4={card.cardNumber.substr(card.cardNumber.length - 4)}
                              name={card.name}
                              expiryDate={card.expiryDate}
                              iconName={card?.brand}
                              selected={selectedCard != null && selectedCard?.id == card.id}
                              onSelect={id => setSelectedCard(cards.find(c => c.id == id))}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )}

                  <PaymentIsProtected />
                </div>
              </div>
            )
          }

          return (
            <div key={method}>
              <RadioButton />
              <p>{method} - {selectedMethodName}</p>
            </div>
          )

        })}
      </div>
    )
  }

  function renderWallet() {
    const method = "wallet";
    const isSelected = chargeWallet;
    const walletIsDisabled = (walletPayAllowed == false || walletOption?.data?.balance.value == 0);
    return (
      <div key={method} className={`mb-4 p-4 border rounded-lg ${walletIsDisabled ? "bg-coolGray-100" : isSelected ? "border-emerald-500 bg-emerald-50" : "border-coolGray-200"}`}>
        <div className="flex items-center space-x-1">
          {/* <RadioButton disabled={walletIsDisabled} containerStyle="flex items-center justify-center hover:cursor-pointer" value={isSelected} onChange={() => setSelectedPaymentMethod(method)} /> */}
          <input
            type="checkbox"
            disabled={walletIsDisabled}
            checked={chargeWallet} onChange={(e) => setChargeWallet(e.target.checked)}
            className={`rounded-sm focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300 ${walletIsDisabled ? "cursor-not-allowed" : "cursor-pointer"} me-2`} />
          <div className="flex items-center space-x-2">
            <WalletIcon className={`fill-current ${isSelected ? "text-emerald-500" : "text-coolGray-300"}`} />
            <h4 className={`text-normal font-semibold leading-6 ${walletIsDisabled ? "text-coolGray-300" : "text-coolGray-700"}`}>
              {Translation.t('texts.wallet')}
            </h4>
            <p className={`text-sm leading-5 font-semibold ${walletIsDisabled ? "text-coolGray-400" : isSelected ? "text-emerald-600" : "text-yellow-500"}`}>
              {`${walletOption?.data?.balance?.humanized ?? '0 SAR'}`}
            </p>
          </div>
        </div>
      </div>
    )
  }

  function renderGiftCards() {
    //
  }

  function renderNewImplementation() {
    return (
      <div className="w-full p-4">
        <h3 className="mb-4 text-lg font-bold text-coolGray-900">{Translation.t('texts.payment_method')}</h3>

        {renderOfferMessages(messages, 'above_payment')}

        {renderPaymentMethods()}
        {renderWallet()}
        {renderGiftCards()}

        {renderOfferMessages(messages, 'under_payment')}

        <PricesBreakDown
          showDivider={false}
          wallet={walletOption}
          showWallet={chargeWallet}
          paymentMethod={selectedPaymentMethod}
          lineItems={chargeWallet && withWalletLineItems ? withWalletLineItems : lineItems}
        />

        {coveredByGiftCard == false && (
          <Button
            disabled={
              isDisabled ||
              !readyToPay ||
              isProcessingPayment ||
              (!allowedPaymentMethods.includes(selectedPaymentMethod) && !coveredByGiftCard) ||
              (selectedPaymentMethod === 'card_pay' && (!selectedCard || isFetchingCards))
            }
            loading={isProcessingPayment}
            type="solid"
            color={selectedPaymentMethod === 'apple_pay' ? "coolGray-900" : "emerald-500"}
            size="large"
            label={
              selectedPaymentMethod === 'apple_pay'
                ? 'Apple Pay'
                : Translation.t('buttons.pay', { currency, amount })
            }
            onPress={processPayment}
            containerStyle={"w-full"}
          />
        )}

        {coveredByGiftCard == true && (
          <Button
            loading={isProcessingPayment}
            type="solid"
            color={"emerald-500"}
            size="large"
            label={Translation.t('buttons.pay', { currency, amount })}
            onPress={payByGiftCard}
            containerStyle={"w-full"}
          />
        )}

        {selectedCard && (
          <ConfirmCardModal
            currency={currency}
            amount={amount}
            visible={confirmCardModalVisible}
            card={selectedCard}
            onClose={() => {
              setConfirmCardModalVisible(false);
            }}
            onConfirm={payAfterCvvConfirmed}
          />
        )
        }
      </div>
    )
  }

  return (
    <>
      {/* {renderOldImplementation()} */}
      {/* <Divider height={16} /> */}
      {renderNewImplementation()}
    </>
  )
};

export default PaymentButtons;
