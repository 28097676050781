import React, { useEffect } from 'react'

const PinDistanceIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.59301 6.92675L3.99968 9.51808L1.40634 6.92675C0.893722 6.41389 0.544677 5.76055 0.403338 5.04933C0.261998 4.33812 0.334711 3.60096 0.612283 2.93107C0.889854 2.26117 1.35982 1.68862 1.96276 1.28579C2.56571 0.882972 3.27455 0.667969 3.99968 0.667969C4.7248 0.667969 5.43365 0.882972 6.03659 1.28579C6.63953 1.68862 7.1095 2.26117 7.38707 2.93107C7.66464 3.60096 7.73735 4.33812 7.59601 5.04933C7.45468 5.76055 7.10563 6.41389 6.59301 6.92675ZM3.99968 5.66675C4.3533 5.66675 4.69244 5.52628 4.94248 5.27623C5.19253 5.02618 5.33301 4.68704 5.33301 4.33342C5.33301 3.9798 5.19253 3.64066 4.94248 3.39061C4.69244 3.14056 4.3533 3.00008 3.99968 3.00008C3.64605 3.00008 3.30692 3.14056 3.05687 3.39061C2.80682 3.64066 2.66634 3.9798 2.66634 4.33342C2.66634 4.68704 2.80682 5.02618 3.05687 5.27623C3.30692 5.52628 3.64605 5.66675 3.99968 5.66675ZM12.593 12.9267L9.99968 15.5187L7.40634 12.9261C6.89372 12.4132 6.54468 11.7599 6.40334 11.0487C6.262 10.3374 6.33471 9.60029 6.61228 8.9304C6.88986 8.2605 7.35982 7.68795 7.96276 7.28513C8.56571 6.8823 9.27455 6.6673 9.99968 6.6673C10.7248 6.6673 11.4336 6.8823 12.0366 7.28513C12.6395 7.68795 13.1095 8.2605 13.3871 8.9304C13.6646 9.60029 13.7374 10.3374 13.596 11.0487C13.4547 11.7599 13.1056 12.4132 12.593 12.9261V12.9267ZM9.99968 11.6667C10.3533 11.6667 10.6924 11.5263 10.9425 11.2762C11.1925 11.0262 11.333 10.687 11.333 10.3334C11.333 9.9798 11.1925 9.64066 10.9425 9.39061C10.6924 9.14056 10.3533 9.00008 9.99968 9.00008C9.64605 9.00008 9.30692 9.14056 9.05687 9.39061C8.80682 9.64066 8.66634 9.9798 8.66634 10.3334C8.66634 10.687 8.80682 11.0262 9.05687 11.2762C9.30692 11.5263 9.64605 11.6667 9.99968 11.6667Z"/>
    </svg>

  );
}


export default PinDistanceIcon