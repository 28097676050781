import useTimer from "../Hooks/useTimer";
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Translation from "../helper/Translation";

type Props = {
  id?: string | number;
  expiry?: any;
  onFinish: () => void;
  disabled: boolean;
  onResend?: () => any;
};

const MyTimer = ({ id, expiry, onFinish, disabled, onResend }: Props) => {
  const [isFinished, setFinished] = useState(false)
  const { secondsLabel, minutesLabel, restart, isRunning, pause, start } =
    useTimer({
      expiryTimestamp: moment(expiry).utc(),
      onExpire: () => {
        onFinish()
        setFinished(true)
      },
    });

  useEffect(() => {
    restart(moment(expiry).utc());
  }, [expiry]);

  const isDisabled = useMemo(() => {
    return disabled || isRunning
  }, [disabled, isRunning])

  const isLastMinute = useMemo(() => {
    return minutesLabel == "00" && secondsLabel != "00"
  }, [minutesLabel, secondsLabel])

  return (
    <div className="flex flex-col items-center justify-center">
      {isFinished == false && (<p className={`${isLastMinute ? 'text-red-500' : 'text-coolGray-500'}`}>{minutesLabel}:{secondsLabel}</p>)}
      <button disabled={isDisabled} className={`${isDisabled ? 'cursor-not-allowed text-coolGray-500' : 'text-coolGray-800'} text-sm leading-6 font-normal `} onClick={() => {
        onResend()
        setFinished(false)
      }}>
        {Translation.t('buttons.resend_verification_code')}
      </button>
    </div>
  );
};

export default MyTimer;
