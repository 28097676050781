import React, { useEffect, useState } from 'react'
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import { toast } from "react-toastify";
import 'remixicon/fonts/remixicon.css'
import PromotionAdSvg from "../src/Assets/svg/PromotionPosting/PromotionAdSvg";


interface PostingPromotionInterface {
  token: string;
  locale: string;
  postingId: string;
  promotionPackageId: string;
  action: string[];
  isPromoted: boolean
}

const PromotePosting = ({ token, locale, postingId, promotionPackageId, isPromoted, action = [] }) => {
  const [showPromotion, setShowPromotion] = useState(false)
  const [promotionPackages, setPromotionPackages] = useState([])
  const [promotionPackage, setPromotionPackage] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showCancelPromotion, setShowCancelPromotion] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)
  const [posting, setPosting] = useState({})


  const submitForm = () => {
    if (formDisabled) return;
    isPromoted ? setShowCancelPromotion(true) : promotePosting()
  }

  const promotePosting = () => {
    setFormDisabled(true)
    BackendCall.i({ token, locale })
      .promotePosting(postingId, promotionPackage?.id)
      .then(res => {
        window.trackActivity({ event: 'super_package_purchased', rawPayload: { ...posting, package_title: promotionPackage?.title, package_days: promotionPackage?.days, package_recommended: promotionPackage?.recommended, package_free_delivery_count: promotionPackage?.freeDeliveryCount || '0', package_social_posts_count: promotionPackage?.socialMediaPostsCount || '0' }, processPayload: false, redirectUrl: `/promotion_posting/${res?.promotionPosting?.id}` })
      })
      .catch(err => setFormDisabled(false))
  }

  const cancelPromotedPosting = () => {
    if (formDisabled) return;
    setFormDisabled(true)
    BackendCall.i({ token, locale })
      .cancelPromotePosting(postingId)
      .then(posting => window.location.href = posting.publicSiteUrl)
  }

  const isSelected = (promoted) => {
    return promoted?.id == promotionPackage?.id
  }


  useEffect(() => {
    EventDispatcher.on('show-promotion', (event) => {
      setPosting(event.detail.posting)
      setIsLoading(true)
      BackendCall.i({ token, locale })
        .getPromotionPackages()
        .then((response) => {
          setIsLoading(false)
          promotionPackageId ? setPromotionPackage(() => response.find((item) => parseInt(item.id) == promotionPackageId)) : setPromotionPackage(() => response.find((item) => item.recommended))
          setPromotionPackages(response)
          setShowPromotion(true)
        }).catch(error => toast.error(error.message, { onClose: () => setShowPromotion(false) }))
    })
  }, [])

  const header = () => {
    return <div className="flex flex-col gap-1 items-center">
      <PromotionAdSvg />
      <p className="text-2xl leading-8 font-bold text-emerald-500">{Translation.t('promotion_posting.header.title')}</p>
      <p className="text-base leading-6 font-normal text-coolGray-500 text-center">{Translation.t('promotion_posting.header.choose_plan')}</p>
    </div>
  }

  const packages = () => {
    return <div className="flex gap-1 items-center w-full py-8">
      {promotionPackages.map((promotion) => {
        return (
          <div className={`${isSelected(promotion) ? 'border-emerald-500' : 'border-coolGray-100'} flex flex-col border  justify-start rounded w-full cursor-pointer`} onClick={() => {
            if (isPromoted) return;
            setPromotionPackage(promotion)
            window.trackActivity({ event: 'super_package_selected', rawPayload: { ...posting, package_title: promotion.title, package_days: promotion.days, package_recommended: promotion.recommended, package_free_delivery_count: promotion.freeDeliveryCount || '0', package_social_posts_count: promotion.socialMediaPostsCount || '0' }, shouldRedirect: false, processPayload: false })
          }} key={promotion.id}>
            <button className={`${isSelected(promotion) && promotion.recommended ? 'bg-emerald-500 text-white w-full' : promotion.recommended && !isSelected(promotion) ? 'bg-coolGray-100 text-coolGray-700 w-full ' : 'text-white bg-white border-none rounded-md'} text-sm leading-5 font-semibold py-1`}>RECOMMENDED</button>
            <div className="flex flex-col justify-center items-center w-full gap-1 md:p-4">
              <img src={promotion.image.url} alt={promotion.title} className="h-10 w-10" />
              <span className="text-lg leading-7 font-semibold text-center flex justify-center items-center text-coolGray-700 align-middle w-full">{promotion.title}</span>
              <span className="text-lg leading-7 font-semibold text-emerald-500">{promotion.dayPrice}</span>
              <span className="text-sm leading-8 font-normal text-coolGray-500">{promotion.currency}/Day</span>
            </div>
            <button className={`w-full ${isSelected(promotion) ? 'bg-emerald-500 text-white' : 'bg-coolGray-100 text-coolGray-700'} w-full flex justify-center items-center text-sm leading-5 font-semibold py-1`}>{promotion.days} Day</button>
          </div>
        )
      })}
    </div>
  }

  const views = () => {
    return (<div className="flex justify-start items-start">
      <div className="flex flex-col items-start justify-center p-1">
        <i className="ri-eye-fill ri-lg text-emerald-500" />
      </div>
      <div className="flex flex-col items-start justify-center md:gap-1">
        <p className="text-base leading-6 font-bold text-coolGray-700">{Translation.t('promotion_posting.plan_benefits.more_view.title')}</p>
        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('promotion_posting.plan_benefits.more_view.description')}</p>
      </div>
    </div>)
  }

  const socialMedia = () => {
    if (!promotionPackage?.socialMediaPostsCount) return (<></>)

    return (<div className="flex justify-start items-start">
      <div className="flex flex-col items-start justify-center p-1">
        <i className="ri-facebook-circle-fill ri-lg text-emerald-500" />
      </div>
      <div className="flex flex-col items-start justify-center md:gap-1">
        <p className="text-base leading-6 font-bold text-coolGray-700">{Translation.t('promotion_posting.plan_benefits.social_media.title', { posts_count: promotionPackage?.socialMediaPostsCount })}</p>
        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('promotion_posting.plan_benefits.social_media.description', { posts_count: promotionPackage?.socialMediaPostsCount })}</p>
      </div>
    </div>)
  }

  const listPaid = () => {
    return (<div className="flex justify-start items-start">
      <div className="flex flex-col items-start justify-center p-1">
        <i className="ri-pie-chart-2-fill ri-lg text-emerald-500" />
      </div>
      <div className="flex flex-col items-start justify-center md:gap-1">
        <p className="text-base leading-6 font-bold text-coolGray-700">{Translation.t('promotion_posting.plan_benefits.listing_paid.title')}</p>
        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('promotion_posting.plan_benefits.listing_paid.description')}</p>
      </div>
    </div>)
  }

  const delivery = () => {
    if (!promotionPackage?.freeDeliveryCount) return (<></>)


    return (<div className="flex justify-start items-start">
      <div className="flex flex-col items-start justify-center p-1">
        <i className="ri-motorbike-fill ri-lg text-emerald-500" />
      </div>
      <div className="flex flex-col items-start justify-center md:gap-1">
        <p className="text-base leading-6 font-bold text-coolGray-700">{Translation.t('promotion_posting.plan_benefits.free_delivery.title')}</p>
        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('promotion_posting.plan_benefits.free_delivery.description', { free_delivery_count: promotionPackage?.freeDeliveryCount })}</p>
      </div>
    </div>)
  }

  const packageFeatures = () => {
    return (<div className='flex-1'>
      <p className="text-lg leading-6 font-semibold text-coolGray-700 pb-2 text-start">{Translation.t('promotion_posting.plan_benefits.header')}</p>
      <div className="flex flex-col items-start md:gap-2 gap-1">
        {views()}
        {socialMedia()}
        {listPaid()}
        {delivery()}
      </div>
    </div>)
  }

  const buttonActionName = () => {
    return action.includes('promote') ? Translation.t('promotion_posting.buttons.promote') : Translation.t('promotion_posting.buttons.cancel_promotion')
  }

  const cancelPromotionModal = () => {
    return (
      <div className={`flex flex-col md:p-4 p-2 `}>
        <div className="flex flex-col gap-1 items-center">
          <PromotionAdSvg />
          <p className="text-2xl leading-8 font-bold text-coolGray-700">{Translation.t('promotion_posting.cancel_promotion_modal.title')}</p>
          <p className="text-base leading-6 font-normal text-coolGray-500 text-center">{Translation.t('promotion_posting.cancel_promotion_modal.description')}</p>
        </div>
        <div className="flex gap-2 pt-6">
          <button onClick={() => {
            cancelPromotedPosting()
          }} className={`w-full bg-white border border-emerald-500 text-emerald-500 text-base leading-6 font-normal rounded py-2.5`}>{Translation.t('promotion_posting.buttons.cancel_plan')}</button>
          <button onClick={() => {
            setFormDisabled(false)
            setShowPromotion(false)
            setShowCancelPromotion(false)
          }} className={`w-full bg-emerald-500 text-white text-base leading-6 font-normal rounded py-2.5`}>{Translation.t('promotion_posting.buttons.keep_promoted')}</button>
        </div>
      </div>
    )
  }

  const packageContainer = () => {
    return (
      <div className={`flex flex-col md:p-4 p-2 `}>
        {header()}
        {packages()}
        {/* {packageFeatures()} */}
        <div className="pt-4">
          <button onClick={submitForm} className={`w-full bg-emerald-500 text-white text-base leading-6 font-normal rounded py-2.5`}>{buttonActionName()}</button>
        </div>
      </div>
    )
  }

  if (!showPromotion) return <></>

  return (
    <BaseModal maxWidth={`max-w-lg`} onClose={() => { setShowPromotion(false) }}>
      {isLoading ? <Loader /> : (showCancelPromotion ? cancelPromotionModal() : packageContainer())}
    </BaseModal>
  )
}

export default PromotePosting
