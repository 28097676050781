import { User } from '@shobbak/react-services/dist/Entities'
import React from 'react'
import Translation from '../../helper/Translation'
import UserSmileFill from '../icon/UserSmileFill'
import isEmpty from 'lodash/isEmpty'
import CheckFill from '../icon/CheckFill'
import CloseFill from '../icon/CloseFill'
import ArrowLeftRight from '../icon/ArrowLeftRight'
import AbsherIcon from '../icon/AbsherIcon'
import RateStars from '../../../components/RateStars'

interface Props {
  user: User;
  showSwitchAccountsModal: () => void;
  isVerified: boolean;
}

export default function UserAccountInfo({ user, showSwitchAccountsModal, isVerified = false }: Props) {
  function getAvatarIconColor() {
    if (isEmpty(user)) return
    return user.emailConfirmed ? 'ring-emerald-50 bg-emerald-500' : 'ring-red-50 bg-red-500'
  }

  function renderAvatarIcon() {
    if (isEmpty(user)) return
    return user.emailConfirmed ? <CheckFill className="fill-current text-white" width="14" height="14" /> : <CloseFill className="fill-current text-white" width="14" height="14" />
  }

  function renderActionFlag() {
    if (isEmpty(user.actionFlag)) return

    return (
      <div className="bg-emerald-50 border-s-4 p-4" style={{ backgroundColor: user.actionFlag.backgroundColor, borderColor: user.actionFlag.bubbleColor }}>
        <div className="flex">
          <div className="flex-shrink-0">
            {/* TODO: display svg icon if there any */}
          </div>
          <div className="ms-3">
            <p className="text-sm leading-5 font-medium" style={{ color: user.actionFlag.bubbleColor }}>
              {user.actionFlag.message}
            </p>
          </div>
        </div>
      </div>
    )
  }

  function renderBusinessSwitch() {
    if (user.hasBusiness) {
      return (
        <button className="w-full" onClick={() => showSwitchAccountsModal()}>
          <div className="flex justify-between items-center p-4 bg-orange-50 rounded-md">
            <p className="text-coolGray-700 text-base leading-6">{Translation.t('buttons.profile_switch_accounts')}</p>
            <div className="bg-white flex items-center justify-center h-8 w-8 rounded-full object-cover">
              <ArrowLeftRight className="fill-current text-orange-500" />
            </div>
          </div>
        </button>
      )
    } else {
      return (
        <div className="flex-col">
          <div className="mb-4 bg-coolGray-700 p-4 flex flex-col rounded-md">
            <p className="mb-2 text-lg leading-6 font-medium text-white">{Translation.t('texts.upgrade_profile_to_business')}</p>
            <a href="/business_profiles">
              <p className="mb-4 text-sm leading-0 font-normal text-coolGray-400">{Translation.t('texts.know_more_about_business')}</p>
            </a>
            <a href="/business_profiles/new">
              <button className="w-full h-12 px-8 py-3 shadow-sm rounded-md bg-white hover:bg-coolGray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coolGray-100">
                <span className="text-base leading-6 text-center font-semibold text-coolGray-700">{Translation.t('texts.upgrade_to_business')}</span>
              </button>
            </a>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="mb-4 flex flex-col px-2 py-4 space-y-4 rounded-lg bg-coolGray-50">
        <div className="flex items-start space-s-4">
          <span className="inline-block relative">
            <img className="h-12 w-12 object-cover rounded-full" src={user.avatarUrl} />
            <span className={`absolute bottom-0 right-0 flex items-center justify-center h-4 w-4 rounded-full ring-4 ${getAvatarIconColor()}`}>
              {renderAvatarIcon()}
            </span>
          </span>
          <div className="font-medium text-lg leading-6 space-y-1">
            <div className="flex items-center gap-2 -mb-2">
              <h3 className="text-xl leading-7 text-coolGray-700 font-semibold">{user.fullName}</h3>
              {isVerified && (
                <AbsherIcon />
              )}
            </div>
            <p className="text-xs leading-5 text-coolGray-500 font-normal">{user.email}</p>
            <a href="/dashboard/reviews">
              <RateStars score={user.rateAvg} />
            </a>
            <div className="flex items-center space-s-2 text-coolGray-400">
              <UserSmileFill className="fill-current" />
              <span className="text-xs leading-4 font-normal">{Translation.t('texts.regular_account')}</span>
            </div>
          </div>
        </div>
        {renderActionFlag()}
      </div>
      {renderBusinessSwitch()}
    </div>
  )
}
