import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import Translation from '../../helper/Translation';

type Props = {
  auction?: PostingAuction;
};

function HighestBid({auction}: Props) {
	return (
		<div className="mb-4 flex">
			<div className="flex-1">
				<p className="text-coolGray-500 mb-0.5 text-xs">{Translation.t('texts.auctions.labels.highest_bid')}</p>
				<p className="font-bold text-coolGray-400 text-xl">
					{auction?.currency}{' '}
					<span className="text-coolGray-700">{auction?.currentBidAmount.value}</span>
				</p>
			</div>
			<div className="flex items-center p-1 bg-coolGray-50 rounded-md text-xs">
				<i className="ri-auction-fill" />
				<span className="font-bold ms-1">{auction?.bidsCount} {Translation.t('auctions.labels.bids')}</span>
			</div>
		</div>
	);
}

export default HighestBid;