import React, { FC } from 'react'

interface AuthModalHeaderProps {
  title: string;
  subTitle: string ;
  headerIconName: string;
  onClose: ()=> void
}

const AuthModalHeader:  FC<AuthModalHeaderProps>  = ({ title , subTitle , headerIconName , onClose})=> {
  return (
    <div>
      <div className="flex items-center gap-4">
        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-emerald-50">
          <i className={`${headerIconName} text-xl text-center text-emerald-500 w-6 h-6`} />
        </div>
        <div className="text-start flex-1 flex-col">
          <p className="text-base leading-6 font-semibold text-coolGray-900">
            {title}
          </p>
          <p className="text-sm leading-5 font-normal text-coolGray-500">
            {subTitle}
          </p>
        </div>
        <div onClick={onClose}  className="self-start cursor-pointer">
          <i className={`h-6 w-6 text-coolGray-400 text-xl ri-close-line`}  />
        </div>
      </div>
    </div>
  );
}

export default AuthModalHeader