import React, { FC } from 'react'

interface AuthButtonProps{
  label: string;
  disabled: boolean;
  onSubmit?: (e)=> void

  type?:  "button" | "submit" | "reset"

}
const AuthButton:FC<AuthButtonProps> = ({label,type= 'submit', onSubmit = (e)=> {},disabled,...props})=> {
  return (
    <button type={type} onClick={onSubmit}  disabled={disabled} className={`${disabled ? 'cursor-not-allowed':'cursor-pointer'} inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm`}>{label}</button>
  )
}

export default AuthButton