import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React, { Fragment } from 'react';
import Translation from '../../helper/Translation';
import Divider from '../Divider';
import BidderRaw from './BidderRaw';

type Props = {
  auction?: PostingAuction;
	size?: 'large' | 'small';
	containerStyle?: string;
};

function Bids({auction, size = 'large', containerStyle}: Props) {
	const maxHeight = () => {
		return {
			large: 'max-h-72',
			small: 'max-h-28'
		}[size]
	}

	return (
		<div className={`${containerStyle ?? ''}`}>
			<p className="font-bold mb-3 text-coolGray-700">
				{auction?.postingAuctionBids.length} {Translation.t('auctions.labels.bids')} 🥇
			</p>
				<div className={`${maxHeight()} overflow-y-auto hide-scroll`}>
					{auction?.postingAuctionBids.map((bid, index) => (
						<Fragment key={index}>
							<BidderRaw bidder={bid} index={index} />
							{index < auction?.postingAuctionBids.length - 1 && (
								<Divider containerStyle={'my-2'} />
							)}
						</Fragment>
					))}
				</div>
		</div>
	);
}

export default Bids;