import React, { useEffect, useMemo, useRef, useState } from 'react'
import EventDispatcher from "../../helper/EventDispatcher";
import { ExclamationIcon } from "@heroicons/react/outline";
import Translation from '../../helper/Translation'
import DefaultInput from "../EditProfile/DefaultInput";
import ShobbakUserXIcon from '../icon/ShobbakUserXIcon';

const WarningAlert = () => {
  const [show, setShow] = useState(false)
  const [disableAction, setDisableAction] = useState(false)
  const ref = useRef()
  const [eventDetail, setEventDetail] = useState({})
  const [inputsData, setInputData] = useState({})
  const allRequiredInputsAreFilled = useMemo(() => {
   return eventDetail?.inputs
      ?.filter(input => input.isRequired)
      ?.every(input => {
        return inputsData[input.name] && inputsData[input.name]?.toString()?.trim() != ''
      })
  }, [inputsData, eventDetail]);

  useEffect(() => {
    EventDispatcher.on('close-warning-modal', () => setShow(false))
    EventDispatcher.on("open-warning-modal", ({ detail }) => {
      setEventDetail(detail);
      setDisableAction(false)
      setShow(true)
    });

    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref?.current?.contains(event.target)) {
        return;
      }
      setShow(false)
    };
    EventDispatcher.on("mousedown", listener);
    EventDispatcher.on("touchstart", listener);
    return () => {
      EventDispatcher.on("mousedown", listener);
      EventDispatcher.on("touchstart", listener);
    };


  }, [ref]
  )

  const updateInputsValue = (key, value) => {
    setInputData((prev) => {
      return { ...prev, [key]: value }
    })
  }


  const displayInput = (input) => {
    return (
      <div>
        <DefaultInput label={input.label}
          isRequired={input.isRequired}
          key={input.id}
          type={input.type}
          onValueChange={(value) => updateInputsValue(input.name, value)}
          value={inputsData[input.name]}
          name={input.name} />
      </div>
    )
  }
  if (!show) {
    return (<></>)
  }
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-44 text-center sm:block sm:p-0">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          ref={ref}
          className="inline-block align-bottom bg-white rounded-lg px-4 text-start overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-sm w-full p-4">

          <div className="flex justify-center flex-col gap-2 items-center">
            {/* <div
              className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-xl bg-red-100 sm:mx-0">
              <ExclamationIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
            </div> */}
            <ShobbakUserXIcon />

            <h2 className="text-center text-lg leading-7 text-coolGray-700">{eventDetail.title}</h2>

            <p
              className="text-center text-base leading-5 font-thin text-coolGray-500 inline-flex items-center">
              {eventDetail.paragraph}
            </p>

            {eventDetail?.inputs?.length > 0 && (
              <div className={`mt-2 flex flex-col w-full px-2 mb-2`}>
                {eventDetail?.inputs.map((item) => {
                  return displayInput(item)
                })}
              </div>
            )}

            <div className="grid grid-cols-2 gap-4 w-full px-2">

              <button type="button"
                onClick={() => {
                  setShow(false)
                  EventDispatcher.dispatch('discard', eventDetail)
                  setInputData({})
                }}
                className={`${disableAction ? 'cursor-not-allowed bg-coolGray-100' : 'cursor-pointer bg-white hover:bg-gray-50'} w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm`}>
                {Translation.t('buttons.discard')}
              </button>

              <button
                type="button"
                onClick={() => {
                  if (disableAction) return;
                  EventDispatcher.dispatch('confirmed', { ...eventDetail, ...inputsData })
                  setDisableAction(true)
                }}
                disabled={allRequiredInputsAreFilled == false}
                className={` ${(disableAction || allRequiredInputsAreFilled == false) ? 'cursor-not-allowed' : 'cursor-pointer'} w-full bg-red-600 hover:bg-red-700 inline-flex items-center justify-center border border-transparent rounded-md  py-2 text-base font-medium text-white  shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:text-sm`}>
                {eventDetail.display_action}

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default WarningAlert
