import React, { useState, useMemo, ReactElement, useContext } from 'react'
import isEmpty from 'lodash/isEmpty';
import BackendCall from '../BackendCall';
import Translation from '../../helper/Translation';
import { BusinessProfileState } from '../../../components/CreateBusinessProfile';
import BaseModal from '../Posting/Modal/BaseModal';
import UserAccountInfo from './UserAccountInfo';
import BusinessAccountInfo from './BusinessAccountInfo';
import AppContext from '../../Services/AppContext';

interface Props {
  token: string;
  locale: string;
  isVerified: boolean;
}

export default function AccountInfo({ token, locale, isVerified = false }: Props): ReactElement {
  const { user, device, businessProfile } = useContext(AppContext)
  const [businessProfiles, setBusinessProfiles] = useState<BusinessProfileState[]>([]);
  const [switchAccountsModalVisible, setSwitchAccountsModalVisibility] = useState<boolean>(false);
  const deviceIsRelatedToAnIndividual = useMemo(() => isEmpty(device?.businessProfile), [device])

  function getMyBusinessProfiles() {
    return new Promise((resolve, reject) => {
      if (businessProfiles.length) return resolve(businessProfiles)

      BackendCall.i()
        // .getMyBusinessProfiles({ "filter[group]": "published" })
        .getMyBusinessProfiles()
        .then(response => {
          setBusinessProfiles(response as BusinessProfileState[])
          resolve(response)
        })
    })
  }

  async function handleBusinessProfileSelection(profileId = null) {
    let response = null;
    if (profileId) {
      response = await BackendCall.i().attachBusinessProfileToDevice(profileId)
    } else {
      response = await BackendCall.i().detachBusinessProfileFromDevice(profileId)
    }
    window.location.reload()
  }

  async function showSwitchAccountsModal() {
    await getMyBusinessProfiles()
    setSwitchAccountsModalVisibility(true)
  }

  function renderSwitchAccountsModal() {
    if (switchAccountsModalVisible == false) return

    return (
      <BaseModal onClose={() => { setSwitchAccountsModalVisibility(false); return {} }}>
        <div className="flex flex-col">
          <div className="p-4 text-center bg-coolGray-50">
            <p className="text-lg font-medium leading-6 text-coolGray-700">{Translation.t('texts.select_account')}</p>
          </div>
          <div className="divide-y divide-coolGray-100">
            <div className="p-4">
              <h4 className="text-start mb-2 text-lg font-normal leading-5 text-coolGray-700">{Translation.t('texts.business.primary_account')}</h4>
              <div className="flex items-center space-s-2">
                <img className="h-8 w-8 rounded-full object-cover" src={user.avatarUrl} alt={user.fullName} />
                <div className="relative flex flex-1 items-start py-4">
                  <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="primary-account" className="font-medium text-gray-700">
                      {user.fullName}
                    </label>
                  </div>
                  <div className="ms-3 flex items-center h-5">
                    <input
                      id="primary-account"
                      aria-describedby="primary-account"
                      name="primary-account"
                      type="checkbox"
                      className="cursor-pointer focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded-full"
                      checked={deviceIsRelatedToAnIndividual}
                      disabled={deviceIsRelatedToAnIndividual}
                      onChange={() => {
                        if (deviceIsRelatedToAnIndividual) return
                        handleBusinessProfileSelection()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 max-h-60 overflow-y-auto">
              <h4 className="text-start mb-2 text-lg font-normal leading-5 text-coolGray-700">{Translation.t('texts.business_stores')}</h4>
              {businessProfiles.map((profile: BusinessProfileState) => {
                let currentlySelected = device.businessProfile?.id == profile.id
                return (
                  <div key={profile.id} className="flex items-center space-s-2">
                    <img className="h-8 w-8 rounded-full object-cover" src={profile.logo as string} alts={`${profile.name} logo`} />
                    <div className="relative flex flex-1 items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor={`profile-${profile.id}`} className="font-medium text-gray-700">
                          {profile.name}
                        </label>
                      </div>
                      <div className="ms-3 flex items-center h-5">
                        <input
                          id={`profile-${profile.id}`}
                          name={`profile-${profile.id}`}
                          type="checkbox"
                          className="cursor-pointer focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded-full"
                          defaultChecked={currentlySelected}
                          disabled={currentlySelected}
                          onChange={() => {
                            if (currentlySelected) return
                            currentlySelected = true
                            handleBusinessProfileSelection(profile.id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </BaseModal >
    )
  }

  if (isEmpty(device) || isEmpty(user)) return <></>

  if (deviceIsRelatedToAnIndividual) {
    return (
      <>
        {renderSwitchAccountsModal()}
        <UserAccountInfo user={user} showSwitchAccountsModal={showSwitchAccountsModal} isVerified={isVerified} />
      </>
    )
  } else {
    return (
      <>
        {renderSwitchAccountsModal()}
        <BusinessAccountInfo token={token} businessProfile={businessProfile} showSwitchAccountsModal={showSwitchAccountsModal} />
      </>
    )
  }
}
