import React, { useEffect, useState } from "react";
import EventDispatcher from "../src/helper/EventDispatcher";
import AuctionRules from  "../src/Components/Auction/AuctionRules"
import BackendCall from "../src/Components/BackendCall";

function Modals({locale, token}) {
  BackendCall.i({ token, locale })
  const [isAuctionRulesVisible, setIsAuctionRulesVisible] = useState(false)

  useEffect(() => {
    EventDispatcher.on('open-auction-rules-modal', () => {
      setIsAuctionRulesVisible(true)
    })
});

  return (
		<>
			<AuctionRules
				visible={isAuctionRulesVisible}
				onClose={() => setIsAuctionRulesVisible(false)}
			/>
		</>
	);
}

export default Modals;
