import React from 'react';

import Button, {ButtonType} from './Button';
import Modal from './Modal';

export type AlertModalProps = {
  visible: boolean;
  containerStyle?: string;
  iconName?: string;
  iconColor?: string;
  headline: string;
  body: string;
  bodyStyle?: string;
  onClose?: () => void;
  rowButtons?: {
    label: string;
    type: ButtonType;
    onPress: (x?) => void;
    color?: string;
  }[];
  columnButtons?: {
    label: string;
    type: ButtonType;
    onPress: (x?) => void;
    color?: string;
  }[];
};

export default function ModalComponent({
  visible,
  containerStyle = '',
  iconName,
  iconColor = 'text-emerald-500',
  headline,
  body,
  bodyStyle,
  rowButtons,
  columnButtons,
  onClose,
}: AlertModalProps) {
  return (
    <Modal
      visible={visible}
      containerStyle={containerStyle}
      onClose={onClose}
      header={
        iconName && (
          <i className={`ri-3x mb-4 inline-block w-full text-center ${iconName} ${iconColor}`} />
        )
      }
      body={
        <>
          <p className="pb-2 text-center">
            {headline}
          </p>
          <p
            className={`mb-4 text-center text-coolGray-700 ${bodyStyle}`}
          >
            {body}
          </p>
        </>
      }
      footer={
        <>
          <div className='w-full'>
            {columnButtons && (
              <>
                <div className={`mb-4 ${rowButtons}`}>
                  {columnButtons?.map((btnProps, index) => (
                    <Button
                      key={btnProps.label}
                      containerStyle={`${index < columnButtons.length - 1 ? 'mb-4' : 'mb-0'}`}
                      label={btnProps.label}
                      type={btnProps.type}
                      size="medium"
                      onPress={btnProps.onPress}
                      color={btnProps.color}
                    />
                  ))}
                </div>
              </>
            )}
            {rowButtons && (
              <div
                className="flex items-center w-full"
              >
                {rowButtons?.map((btnProps, index) => (
                  <Button
                    key={btnProps.label}
                    containerStyle={`flex-1 ${index < rowButtons.length - 1 ? 'mr-4' : 'mr-0'}`}
                    label={btnProps.label}
                    type={btnProps.type}
                    size="medium"
                    onPress={btnProps.onPress}
                    color={btnProps.color}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      }
    />
  );
}
