import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Login from "../src/Components/Auth/Login";
import Register from "../src/Components/Auth/Register";
import RegisterWithMobile from "../src/Components/Auth/RegisterWithMobile";
import ForgetPassword from "../src/Components/Auth/ForgetPassword";
import ForgetPasswordWithMobile from "../src/Components/Auth/ForgetPasswordWithMobile";
import { Country } from "@shobbak/react-services/dist/Entities";


interface AuthenticationModeInterface {
  mode: string;
  modalOpen: boolean;
  userSignIn: boolean;
}
export interface socialItemProps {
  id: string;
  key: string;
  name: string;
  title: string;
  enabled: boolean;
  route: string;
  icon: string
}

interface AuthenticationContainerProps {
  socialLinks: socialItemProps[];
  locale: string;
  token: string;
  requireLogin: boolean;
  country: string;
  enabledCountries: Country[];
  defaultCountry: Country;
  recaptchaEnabled: boolean;
}


const AuthenticationContainer: FC<AuthenticationContainerProps> = ({ socialLinks, defaultCountry, enabledCountries, requireLogin, country, locale, token, recaptchaEnabled = true, ...props }) => {

  const [authenticationData, setAuthenticationData] = useState<AuthenticationModeInterface>({
    mode: 'login',
    modalOpen: false,
    userSignIn: false
  })
  const redirect = useRef(null)

  useEffect(() => {

    setAuthenticationData(prev => ({ mode: 'login', modalOpen: requireLogin, userSignIn: prev.userSignIn }))

    EventDispatcher.on('close-auth-modal', () => {
      setAuthenticationData(prev => ({ mode: 'login', modalOpen: false, userSignIn: prev.userSignIn }))
    })
    EventDispatcher.on('open-auth-modal', ($event) => {
      const mode = $event.detail.mode || 'login'
      redirect.current = $event?.detail?.redirectUrl
      setAuthenticationData(prev => ({ mode, modalOpen: true, userSignIn: prev.userSignIn }))
    })
  }, [])


  const closeModal = () => {
    setAuthenticationData(prevState => ({ mode: prevState.mode, modalOpen: false, userSignIn: prevState.userSignIn }))
  }
  const displayAuthComponent = (mode) => {
    switch (mode) {
      case 'login':
        return (<Login socialLinks={socialLinks} redirectUrl={redirect.current} onClose={closeModal} locale={locale} country={country} defaultCountry={defaultCountry} enabledCountries={enabledCountries} recaptchaEnabled={recaptchaEnabled} />)
      case 'register':
        return (<RegisterWithMobile token={token} redirectUrl={redirect.current} defaultCountry={defaultCountry} enabledCountries={enabledCountries} onClose={closeModal} locale={locale} country={country} recaptchaEnabled={recaptchaEnabled} />)
        // return (<Register socialLinks={socialLinks} redirectUrl={redirect.current} defaultCountry={defaultCountry} enabledCountries={enabledCountries} onClose={closeModal} locale={locale} country={country} />)
      case 'forget-with-email':
        return (<ForgetPassword locale={locale} country={country} recaptchaEnabled={recaptchaEnabled} />)
      case 'forget-with-mobile':
        return (<ForgetPasswordWithMobile token={token} locale={locale} country={country} defaultCountry={defaultCountry} enabledCountries={enabledCountries} recaptchaEnabled={recaptchaEnabled} />)
      default:
    }

  }


  if (!authenticationData.modalOpen) return (<></>);

  return (
    <BaseModal onClose={() => closeModal()} >
      {displayAuthComponent(authenticationData.mode)}
    </BaseModal>
  )
}



export default AuthenticationContainer
