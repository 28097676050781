import React, { FC, useContext, useEffect, useState, useMemo, useRef } from "react";
import Translation from '../../helper/Translation'

const ImageUploader = ({ state, dispatch, validateImage }) => {

	const fileInput = useRef(null);

	const updateState = (files) => {
		let notCorrectFiles = Array.from(files).filter((file) => {
			const fileType = file.type;
			return !fileType.startsWith('image/') && !fileType.startsWith('video/');
		}
		)

		if (notCorrectFiles.length > 0) {
			alert("Media should be Images or Videos")
			return;
		}
		const allFiles = [...state.images, ...files];
		dispatch({
			type: 'key_was_updated',
			payload: [{ key: 'images', value: allFiles }, { key: 'new_images', value: files }]
		})

		validateImage(allFiles)
	}


	const memoizedImages = useMemo(() => {
		return state.images.map((photo, index) => (
			<div className="relative flex bg-center bg-cover bg-no-repeat  rounded-md"
				key={`${photo.name}-${index}`}
				style={{
					backgroundImage: photo?.thumbnail ? `url(${photo?.thumbnail})` : `url(${window.URL.createObjectURL(photo)})`,
					height: '100px',
					width: '100px'
				}}>
				<div className="absolute top-2 right-2 p-2 bg-coolGray-400 rounded-md cursor-pointer"
					onClick={() => {
						const newFiles = [...state.images.filter((_, i) => i !== index)];
						const removedImage = state.images[index];
						dispatch({
							type: 'key_was_updated',
							payload: [
								{ key: 'images', value: newFiles },
								{ key: 'image_option_detail', value: removedImage?.id }
							]
						});
						validateImage(newFiles);
					}}>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd"
							d="M10.333 3.00065H13.6663V4.33398H12.333V13.0007C12.333 13.3688 12.0345 13.6673 11.6663 13.6673H2.33301C1.96482 13.6673 1.66634 13.3688 1.66634 13.0007V4.33398H0.333008V3.00065H3.66634V1.00065C3.66634 0.632461 3.96482 0.333984 4.33301 0.333984H9.66634C10.0345 0.333984 10.333 0.632461 10.333 1.00065V3.00065ZM10.9997 4.33398H2.99967V12.334H10.9997V4.33398ZM4.99967 3.00065V1.66732H8.99967V3.00065H4.99967Z"
							fill="white" />
					</svg>
				</div>
			</div>
		));
	}, [state.images]);

	return (
		<>
			{state.images.length > 0 && (
				<div className="flex flex-wrap w-full gap-4">
					<div className="flex justify-center  p-6 border-2 border-gray-300 border-dashed rounded-md">
						<input type="file"
							multiple={true}
							accept="image/*,video/*"
							className="hidden"
							ref={fileInput}
							onChange={(e) => {
								updateState(e.target.files);
							}}
						/>
						<svg className="mx-auto h-12 w-12 text-gray-400 cursor-pointer"
							onClick={() => {
								fileInput.current.click()
							}}
							stroke="currentColor" fill="none"
							viewBox="0 0 48 48" aria-hidden="true">
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>

					{memoizedImages}
				</div>
			)}
			{state.images.length === 0 && (
				<div
					className="flex justify-center w-full pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
					<div className="space-y-1 text-center">
						<svg className="cursor-pointer mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
							onClick={() => fileInput.current.click()}
							viewBox="0 0 48 48" aria-hidden="true">
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
						<div className="flex text-sm text-gray-600">
							<label htmlFor="file-upload"
								className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
								<span
									className="text-emerald-600">{Translation.t('texts.upload_file')}</span>
								<input id="file-upload" ref={fileInput}
									accept="image/* ,video/*"
									multiple={true} onChange={(e) => {
										updateState(e.target.files);

									}} name="file-upload" type="file" className="sr-only" />
							</label>
							<p className="pl-1">{Translation.t('texts.drop_upload_image')}</p>
						</div>
						<p className="text-xs text-gray-500">
							{Translation.t('texts.file_types')}
						</p>
					</div>
				</div>
			)}
		</>
	);
}

export default ImageUploader;
