import React from 'react';
import Modal from 'react-modal';

export type ModalComponentProps = {
  visible: boolean;
  containerStyle?: string;
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  animationType?: 'none' | 'slide' | 'fade';
  onClose?: () => void;
};

export default function ModalComponent({
  visible,
  containerStyle,
  header = null,
  body = null,
  footer = null,
  onClose,
}: ModalComponentProps) {
  return (
    <Modal
      ariaHideApp={false}
      className="w-11/12 md:w-1/2 lg:w-1/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 focus-within:outline-none"
      overlayClassName="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      isOpen={visible}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div
        className="flex items-center justify-center"
      >
        <div
          className={`bg-white w-full rounded-lg px-6 py-8 ${containerStyle ? containerStyle : ''}`}
        >
          {header}
          {body}
          {footer}
        </div>
      </div>
    </Modal>
  );
}
