import React from 'react'
import { GiftCard } from '@shobbak/react-services/dist/Entities';
import logo from '../../../../assets/images/logo-white.png';
import Translation from '../../helper/Translation';
import moment from 'moment';
import Button from '../Button';

type GiftCardProps = {
  gift: GiftCard;
  onPress?: () => void;
  showReadMore?: boolean;
};

export default function GiftCardItem({
  gift,
  onPress,
  showReadMore = true,
}: GiftCardProps) {
  return (
    <div className={`p-4 rounded-lg relative overflow-hidden text-white ${gift.usage !== 'available' ? 'bg-coolGray-400' : 'bg-emerald-500'}`}>
      <img src={logo} className="w-60 opacity-10 absolute -translate-y-1/2 top-4 end-0 translate-x-1/4 rtl:-translate-x-1/4" />

      <div style={{isolation: 'isolate'}}>
        <div className="flex justify-between">
          <p className="text-sm">{gift.shobbakCard?.name}</p>
          <div className="flex items-center gap-1">
            <img src={logo} className="w-7" />
            <span>{Translation.t('texts.gift')}</span>
          </div>
        </div>

        <p className="text-3xl">{gift.amountDetail?.humanized}</p>

        <hr className={`border-dashed mt-6 mb-2 ${gift.usage !== 'available' ? 'border-coolGray-300' : 'border-emerald-400'}`} />

        <div className="flex items-center justify-between">
          <p className="text-sm">
            {gift.expiresAt &&
              `${Translation.t('texts.expires')}: ${moment(gift.expiresAt).format(
                'DD MMM YYYY',
              )}`}
          </p>
          {showReadMore && (
            <Button
              label={Translation.t('buttons.read_more')}
              innerContainerStyle="px-0 py-0"
              size="small"
              type="clear"
              color="white"
              onPress={onPress}
            />
          )}
        </div>
      </div>
    </div>
  )
}

