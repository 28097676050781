import React from 'react'

export default function ShobbakSuccessIcon() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92 48.227v-21.08a6.813 6.813 0 0 0-5.214-6.667l-34.56-8.32a6.812 6.812 0 0 0-8.413 6.667v66.507A6.815 6.815 0 0 0 52.226 92l34.573-8.44a6.813 6.813 0 0 0 5.214-6.666v-2.667" stroke="#F9C12A"/>
      <path d="M92 55.614a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.746a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm-64.627-55.6 2.854-9.333.413-1.333c.306-.974-1.227-1.334-1.52-.427l-2.854 9.333-.413 1.334c-.306.973 1.213 1.333 1.52.426ZM3.054 15.733 12 19.573l1.253.533a.813.813 0 0 0 1.08-.28.787.787 0 0 0-.28-1.08l-8.947-3.84-1.253-.546a.84.84 0 0 0-1.08.28.813.813 0 0 0 .28 1.093Z" fill="#10B981"/>
      <path d="m14.977 4.74 2.95 9.179v.013l.023.074c.033.103.011.151-.006.178a.31.31 0 0 1-.18.114.384.384 0 0 1-.25-.012.269.269 0 0 1-.145-.173l-.411-1.327-.002-.005-2.972-9.249c-.038-.129-.011-.19.006-.219a.258.258 0 0 1 .156-.103.36.36 0 0 1 .24.017.33.33 0 0 1 .169.193l.422 1.32Z" stroke="#F9C12A"/>
      <path d="M18.227 36.16V67.2l25.733 6.187V29.36l-25.733 6.8Zm51.453 0V67.2l-25.72 6.187V29.36l25.72 6.8Z" fill="#fff" stroke="#10B981"/>
      <path d="M49.734 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.799-.813a.334.334 0 1 1 .026.667.334.334 0 0 1-.026-.667Zm-1.613 0a.334.334 0 1 1-.134.654.334.334 0 0 1 .133-.654ZM50.533 54a.334.334 0 1 1 .027.667.334.334 0 0 1-.027-.667Zm-1.613 0a.334.334 0 1 1-.134.654.334.334 0 0 1 .133-.654Zm-.813-2.44a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.627 1.627a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627-6.506a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm0 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm.799-.814a.335.335 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm-1.613 0a.334.334 0 1 1-.001.668.334.334 0 0 1 0-.668Zm1.613 1.627a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.653Zm-1.613 0a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-.813-2.44a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M49.734 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627-6.493a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm0 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm.799-.786a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm-1.613 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.654 0 .321.321 0 0 1 .333-.32Zm1.613 1.613a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.653Zm-1.613 0a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-.813-2.426a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm0 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm1.627 1.641a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627-6.494a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.799-.774a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm-1.613.001a.334.334 0 1 1-.134.654.334.334 0 0 1 .133-.654Zm1.613 1.586a.333.333 0 0 1 .333.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-1.613 0a.32.32 0 1 1 0 .64.32.32 0 0 1-.334-.32.334.334 0 0 1 .333-.32Zm-.813-2.44a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Z" fill="#10B981"/>
      <path d="M49.734 45.067a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm-1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64ZM46.48 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-.813-.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654ZM47.294 54a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm.813.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627-6.506a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm1.627 0a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-.813-.814a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 1.627a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Z" fill="#10B981"/>
      <path d="M46.48 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627-6.493a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-1.627 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .206-.3.32.32 0 0 1 .127-.02Zm1.627 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm-.813-.786a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .321.321 0 0 1 .333-.32Zm-1.627 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .318.318 0 0 1 .207-.3.32.32 0 0 1 .126-.02Zm1.627 1.613a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm.813.814a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627-6.494a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654ZM46.48 43.44a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm1.627 0a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-.813-.773a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 1.586a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Zm-1.627 0a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Z" fill="#10B981"/>
      <path d="M46.48 45.067a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm2.347-4.081a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Zm-1.628 0a.334.334 0 0 1 .333.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M50.454 40.986a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Zm-3.254 0a.334.334 0 0 1 .334.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-1.626 0a.333.333 0 0 1 .333.32.319.319 0 0 1-.333.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M47.2 40.986a.334.334 0 0 1 .334.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-1.626 18.507a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.626a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm.812.8a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.613a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.814 2.439a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0-1.626a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626 6.507a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm0-1.627a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm.813.813a.333.333 0 1 1-.107-.657.333.333 0 0 1 .107.657Zm-.001-1.627a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.654Zm.814 2.441a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M45.574 59.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm1.626 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm0-1.612a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.812.799a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm0-1.613a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.814 2.426a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm0-1.612a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.626-1.627a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.626 6.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm.812.814a.333.333 0 1 1 .133-.654.333.333 0 0 1-.133.654Zm.001-1.627a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm.813 2.44a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M45.574 65.986a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm1.626 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm1.628-6.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm-1.628 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm1.628-1.626a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm-1.628 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm.8.8a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm1.64 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653ZM48 57.054a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm1.64 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.813 2.439a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.625-1.626a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm-1.628 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm3.253 0a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.625 6.507a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.628 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.628-1.627a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.628 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.8.813a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.64 0a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666ZM48 60.306a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.654Zm1.64 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.654Zm.813 2.441a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M48.828 59.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm-1.628 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm3.253 0a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-1.627 6.493a.321.321 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.626 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm1.628-1.612a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.628 0a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm.8.799a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm1.64 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32ZM48 63.56a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm1.64 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.813 2.427a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm0-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.625-1.626a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.628 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm3.253 0a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.625 6.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm-1.628 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm1.626-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.334.334 0 0 1-.333.32Zm-1.626 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.333.32Zm.8.814a.333.333 0 1 1 .132-.654.333.333 0 0 1-.133.654Zm1.64 0a.333.333 0 1 1 .133-.654.333.333 0 0 1-.133.654ZM48 66.8a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm1.64 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.026.64Zm.813 2.44a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 0 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M48.826 65.986a.321.321 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.626 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm3.253.001a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm0-6.494a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0 6.507a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M50.453 59.493a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0 6.494a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm0-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0 6.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.32.32 0 0 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M50.453 65.987a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-3.973 5.707a.334.334 0 1 1-.026-.668.334.334 0 0 1 .025.668Zm-.813-.814a.334.334 0 0 1-.333-.32.317.317 0 0 1 .207-.3.32.32 0 0 1 .126-.02.32.32 0 1 1 0 .64Zm1.627 0a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm.812.814a.335.335 0 0 1-.34-.395.334.334 0 1 1 .34.395Zm-1.626-1.627a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.627 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.813.813a.333.333 0 0 1-.334-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm1.613 0a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm-.799-.813a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M48.107 70.067a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm6.559-18.507a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm0 1.627a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm.748-.814a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.628 0a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333.333.333 0 0 1 0-.653ZM55.414 54a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.628 0a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333.333.333 0 0 1 0-.653Zm-.813-2.44a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.693 1.627a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.693 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.693-6.507a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm0 1.626a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm.748-.813a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm-1.628 0a.321.321 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm1.628 1.626a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm-1.628.001a.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32.333.333 0 0 1 0-.653Zm-.812-2.441a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm0 1.627a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Z" fill="#10B981"/>
      <path d="M54.666 51.56a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.693 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.693-6.494a.32.32 0 1 1 0 .641.32.32 0 0 1 0-.64Zm0 1.601a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm.748-.787a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-1.627 0a.307.307 0 0 1 .32.32.32.32 0 1 1-.32-.32Zm1.627 1.613a.333.333 0 1 1 .026.667.333.333 0 0 1-.026-.667Zm-1.628 0a.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32.333.333 0 0 1 0-.654Zm-.812-2.427a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm0 1.6a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm1.692 1.641a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm-1.692-.001a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm1.692-6.492a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm0 1.626a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm.748-.774a.333.333 0 1 1-.32.32.32.32 0 0 1 .32-.32Zm-1.628.001a.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333.333.333 0 0 1 0-.653Zm1.628 1.586a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm-1.627 0a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Zm-.814-2.439a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.626a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Z" fill="#10B981"/>
      <path d="M54.666 45.066a.32.32 0 1 1 0 .641.32.32 0 0 1 0-.64Zm-1.692 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-1.626 6.494a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.625 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.813-.814a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm-.812.814a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.626-6.508a.331.331 0 0 1 .368.184.333.333 0 1 1-.368-.184Zm1.627 0a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm-1.627 1.627a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm1.627 0a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm-.814-.813a.334.334 0 0 1 .367.47.334.334 0 1 1-.367-.47Zm0 1.627a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.652Z" fill="#10B981"/>
      <path d="M51.348 51.56a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.626-6.494a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm-1.627 1.6a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm1.627 0a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm-.814-.786a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm0 1.613a.333.333 0 1 1 .132.652.333.333 0 0 1-.132-.652Zm-.813.813a.331.331 0 0 1 .368.184.333.333 0 1 1-.368-.184Zm1.627 0a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm-1.626-6.492a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-1.625 1.626a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm1.625 0a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.813-.774a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.587a.334.334 0 0 1 .333.32.321.321 0 0 1-.333.32.32.32 0 0 1 0-.64Z" fill="#10B981"/>
      <path d="M51.347 45.066a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm1.627 0a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm2.359-4.08a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Zm-1.627.001a.32.32 0 1 1 0 .64.319.319 0 0 1-.35-.435.336.336 0 0 1 .35-.205Z" fill="#10B981"/>
      <path d="M55.333 40.986a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Zm-3.253 0a.32.32 0 1 1 0 .64.307.307 0 0 1-.32-.32.32.32 0 0 1 .32-.32Z" fill="#10B981"/>
      <path d="M52.08 40.986a.32.32 0 1 1 0 .64.307.307 0 0 1-.32-.32.32.32 0 0 1 .32-.32Zm-.813 17.681a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm0-1.613a.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.334.334.334 0 0 1 0 .654Zm.813 2.439a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm0-1.627a.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm0-1.626a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm-.813 5.694a.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .654Zm0-1.627a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.334.334 0 0 1 0 .654Zm.813 2.44a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm0-1.627a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Z" fill="#10B981"/>
      <path d="M52.08 59.493a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm-.812 5.68a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm-.001-1.613a.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .654Zm.813 2.427a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.614a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm0-1.626a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-.813 5.68a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334.334.334 0 0 1 0 .654Zm0-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm.813 2.44a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm0-1.627a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M52.08 65.987a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm1.626-6.493a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-1.626-.001a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm1.626-1.626a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-1.626-.001a.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm.813.8a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.627 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-1.627-1.613a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm1.627 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.813 2.441a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-1.627-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-1.626 0a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm3.253 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-1.627 6.507a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm-1.626 0a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm1.626-1.627a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm-1.626 0a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm.814.813a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm1.626 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .133.653Zm-1.627-1.626a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm1.627 0a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.813 2.44a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0-1.627a.335.335 0 0 1-.4-.327.334.334 0 1 1 .4.327Z" fill="#10B981"/>
      <path d="M53.706 59.494a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-1.626-.001a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.333.333 0 0 1 0 .653Zm3.253.001a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-1.627 6.492a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.626.001a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm1.626-1.614a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm-1.626 0a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm.814.8a.32.32 0 0 1-.334-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm1.626 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm-1.626-1.613a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm1.626 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .133.653Zm.813 2.426a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-1.627-1.626a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm-1.626 0a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm3.253 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-1.627 6.493a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-1.626 0a.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333.334.334 0 0 1 0 .653Zm1.626-1.627a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 0 1 0 .64Zm-1.626 0a.32.32 0 0 1-.32-.32.307.307 0 0 1 .32-.32.32.32 0 0 1 0 .64Zm.813.813a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.627 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666ZM52.894 66.8a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm1.626 0a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.813 2.44a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Z" fill="#10B981"/>
      <path d="M53.706 65.986a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-1.626.001a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm3.253-.001a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-6.492a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0 6.507a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0-1.627a.335.335 0 0 1-.4-.327.334.334 0 1 1 .4.327Z" fill="#10B981"/>
      <path d="M55.333 59.494a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0 6.492a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0-1.626a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0 6.493a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Z" fill="#10B981"/>
      <path d="M55.333 65.986a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-3.987 4.081a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm1.627 0a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Zm1.693 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M52.973 70.067a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64ZM59.48 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.814-.813a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654ZM60.294 54a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1 0 .667.334.334 0 0 1-.367-.47.333.333 0 0 1 .367-.184V54Zm-.814-2.44a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm1.627 1.627a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm1.627-6.506a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm.814-.814a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 1.627a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.814-2.44a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Z" fill="#10B981"/>
      <path d="M59.48 51.56a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm1.627-6.493a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm0 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .317.317 0 0 1 .206-.3.32.32 0 0 1 .127-.02Zm.814-.786a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .321.321 0 0 1 .333-.32Zm-1.627 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .318.318 0 0 1 .207-.3.32.32 0 0 1 .126-.02Zm1.627 1.613a.334.334 0 1 1 0 .668.334.334 0 0 1 0-.668Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.814-2.426a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm0 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm1.627 1.641a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627-6.494a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.814-.773a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 1.586a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Zm-1.627 0a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Zm-.814-2.44a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Z" fill="#10B981"/>
      <path d="M59.48 45.067a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm-1.627 0a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-1.626 6.493a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.626 1.627a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-.814-.813a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm0 1.627a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm-.812.813a.334.334 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.626-6.506a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.321.321 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.626 1.627a.334.334 0 0 1 0 .653.321.321 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.626 0a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-.814-.814a.334.334 0 0 1 0 .654.32.32 0 0 1-.32-.32.321.321 0 0 1 .32-.334Zm0 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.333Z" fill="#10B981"/>
      <path d="M56.227 51.56a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.626 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.627-6.494a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm1.627.001a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm-1.626 1.599a.32.32 0 1 1-.32.32.305.305 0 0 1 .195-.3.307.307 0 0 1 .125-.02Zm1.626 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm-.813-.786a.32.32 0 1 1-.32.32.305.305 0 0 1 .32-.32Zm-.001 1.613a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.32.322.322 0 0 1 .32-.334Zm-.812.814a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.321.321 0 0 1 .32-.333Zm1.626 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.626-6.494a.334.334 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626 0a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.626 1.627a.334.334 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.626 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .132-.653Zm-.814-.773a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm.001 1.586a.32.32 0 1 1 0 .64.307.307 0 0 1-.32-.32.32.32 0 0 1 .32-.32Z" fill="#10B981"/>
      <path d="M56.226 45.066a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm1.627.001a.32.32 0 1 1-.027.64.32.32 0 0 1 .027-.64Zm2.347-4.081a.334.334 0 0 1 .334.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-1.626 0a.333.333 0 0 1 .333.32.319.319 0 0 1-.333.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M60.2 40.986a.334.334 0 0 1 .334.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-3.24 0a.32.32 0 0 1 .373.347.32.32 0 0 1-.32.32.32.32 0 0 1-.333-.32.333.333 0 0 1 .28-.347Z" fill="#10B981"/>
      <path d="M56.96 40.986a.32.32 0 0 1 .373.347.32.32 0 0 1-.32.32.32.32 0 0 1-.333-.32.333.333 0 0 1 .28-.347Zm-.813 17.68a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.613a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.813 2.441a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-.814 5.693a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.001-1.626a.333.333 0 1 1-.026-.665.333.333 0 0 1 .026.665Zm.813 2.44a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0-1.627a.334.334 0 0 1-.367-.47.334.334 0 1 1 .367.47Z" fill="#10B981"/>
      <path d="M56.96 59.494a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm-.814 5.679a.32.32 0 0 1-.334-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.814 2.426a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm0-1.626a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-.813 5.679a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm-.001-1.626a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.814 2.44a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm0-1.627a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Z" fill="#10B981"/>
      <path d="M56.96 65.986a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm1.614-6.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614.001a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm1.614-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614 0a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm.8.8a.333.333 0 1 1 .131-.653.333.333 0 0 1-.132.653Zm1.626 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.626-1.613a.333.333 0 1 1 .132-.653.333.333 0 0 1-.133.653Zm1.626 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.814 2.439a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0-1.626a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626-1.627a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm3.24 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626 6.507a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.614 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm1.614-1.627a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.614 0a.334.334 0 0 1-.367-.47.334.334 0 1 1 .367.47Zm.8.813a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 0a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.626-1.627a.334.334 0 1 1 .132-.653.334.334 0 0 1-.133.654Zm1.626 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.654Zm.814 2.441a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M58.574 59.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614.001a.333.333 0 1 1-.026-.667.333.333 0 0 1 .026.667Zm3.24-.001a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm-1.626 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm-1.614 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm1.614-1.612a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm-1.614-.001a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm.8.8a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.334.32Zm1.626 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32ZM57.76 63.56a.333.333 0 1 1-.001-.665.333.333 0 0 1 0 .665Zm1.626 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.814 2.426a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm0-1.612a.333.333 0 1 1 .005-.667.333.333 0 0 1-.005.667Zm-1.626-1.627a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.614 0a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm3.24 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.626 6.493a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.614 0a.333.333 0 1 1-.026-.666.333.333 0 0 1 .026.666Zm1.614-1.627a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm-1.614 0a.333.333 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm.8.814a.334.334 0 1 1 .132-.654.334.334 0 0 1-.133.654Zm1.626 0a.333.333 0 1 1 .133-.654.333.333 0 0 1-.133.654ZM57.76 66.8a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Zm1.627 0a.32.32 0 1 1 .027-.64.32.32 0 0 1-.027.64Zm.813.813a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M58.574 65.986a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm-1.614 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm3.24 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm0-6.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0-1.626a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0 6.507a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M60.2 59.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.132.654Zm0 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm0-1.612a.333.333 0 1 1 .005-.667.333.333 0 0 1-.005.667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0 4.866a.32.32 0 1 1 0-.64.32.32 0 0 1 .334.32.334.334 0 0 1-.333.32Z" fill="#10B981"/>
      <path d="M60.2 65.986a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.333.32Zm.894-14.426a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.812-.814a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .027.666.333.333 0 0 1-.027-.666Zm-.812.814a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.001-6.508a.331.331 0 0 1 .368.184.333.333 0 1 1-.368-.184Zm0 1.627a.333.333 0 1 1 .133.653.333.333 0 0 1-.133-.653Zm.815-.813a.335.335 0 0 1 .4.327.333.333 0 1 1-.4-.327Zm0 1.627a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.653Z" fill="#10B981"/>
      <path d="M61.094 51.56a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.001-6.494a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm0 1.6a.32.32 0 0 1 .334.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm.813-.786a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm.002 1.613a.333.333 0 1 1 .132.653.333.333 0 0 1-.132-.653Zm-.815.813a.331.331 0 0 1 .368.184.333.333 0 1 1-.368-.184Zm.001-6.492a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.626a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm.812-.774a.333.333 0 1 1 .027.665.333.333 0 0 1-.027-.665Zm0 1.587a.333.333 0 0 1 .333.32.321.321 0 0 1-.333.32.32.32 0 0 1 0-.64Z" fill="#10B981"/>
      <path d="M61.093 45.066a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm.733-4.08a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M61.826 40.986a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Zm-.813 17.681a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm0-1.613a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.815 2.439a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.815 5.693a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm0-1.627a.334.334 0 1 1 .133-.653.334.334 0 0 1-.133.654Zm.815 2.441a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M61.828 59.493a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.815 5.68a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .321.321 0 0 1-.334.32Zm0-1.613a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.813 2.427a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm.002-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-.815 5.68a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm.001-1.627a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Zm.812.813a.32.32 0 1 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M61.826 65.987a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm.002-6.494a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0 6.507a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M61.828 59.493a.333.333 0 0 1 0-.653.321.321 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.002 6.494a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm.002-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-.002 4.866a.32.32 0 1 1 0-.64.307.307 0 0 1 .32.32.32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M61.826 65.987a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-16.332-34.12a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm1.626 0a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-1.627 3.253a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm.813.813a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm0-1.627a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm.814 2.44a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm0-1.627a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Z" fill="#10B981"/>
      <path d="M45.494 31.867a.334.334 0 1 1 .132-.654.334.334 0 0 1-.132.654Zm1.626 0a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm-1.626 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32Zm-.001-1.613a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.813.813a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm0-1.56a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm.814 2.36a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm0-1.614a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm-1.627-1.626a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.627 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Z" fill="#10B981"/>
      <path d="M47.12 31.867a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.626 3.253a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-1.626 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm1.626-1.627a.321.321 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-1.626 0a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm.88.814a.321.321 0 0 1-.32-.334.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .654Zm1.56-.001a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652ZM48 32.68a.32.32 0 0 1-.32-.32A.32.32 0 0 1 48 32a.334.334 0 0 1 0 .653v.027Zm1.56 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .026.666Zm.814 2.44a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Z" fill="#10B981"/>
      <path d="M47.12 31.867a.334.334 0 1 1 .32-.32.32.32 0 0 1-.32.32Zm1.626 6.493a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm-1.626 0a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Zm1.626-1.613a.321.321 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-1.626-.001a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667Zm.88.814a.321.321 0 0 1-.32-.334.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .654Zm1.56 0a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652ZM48 36a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm1.56 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.812 2.36a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm.002-1.613a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Zm-1.628-1.627a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-1.626 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .027.666Zm3.254 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Z" fill="#10B981"/>
      <path d="M50.374 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-.002 4.867a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm.002-1.613a.333.333 0 1 1-.132-.654.333.333 0 0 1 .132.654Z" fill="#10B981"/>
      <path d="M50.374 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm-3.988 5.707a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665ZM45.574 40a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm.8.827a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665ZM46.386 39.2a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.614 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.64 1.627a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Z" fill="#10B981"/>
      <path d="M48 40.827a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.828-.827a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.625 0a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-.813-.8a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.64 0a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm3.187-4.894a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.654Zm-.001-1.626a.334.334 0 1 1-.026-.667.334.334 0 0 1 .026.667ZM52 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm0-1.627a.333.333 0 1 1-.133-.653.333.333 0 0 1 .132.653Zm-.813 4.067a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.56a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654ZM52 38.36a.32.32 0 0 1-.334-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.133-.654.333.333 0 0 1 .132.654Z" fill="#10B981"/>
      <path d="M52 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm1.613 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Z" fill="#10B981"/>
      <path d="M52 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm1.613-1.626a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666ZM52 33.493a.333.333 0 1 1-.133-.653.333.333 0 0 1 .132.653Zm.8.813a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.627 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.813.814a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 3.24a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32Zm-1.613 0a.32.32 0 0 1-.334-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm1.613-1.613a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.613 0a.333.333 0 1 1-.133-.654.333.333 0 0 1 .132.654Zm.8.813a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.627 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665ZM52.8 36a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.627 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm.813 2.36a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32Zm0-1.613a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627-1.627a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Z" fill="#10B981"/>
      <path d="M52 35.12a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm3.24 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M55.24 35.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0 3.24a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32Zm0-1.613a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M55.24 35.12a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-3.973 5.706a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653ZM52.08 40a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm.814.827a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653ZM51.267 39.2a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm1.627 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm1.626 1.627a.333.333 0 1 1-.132-.653.333.333 0 0 1 .133.653Z" fill="#10B981"/>
      <path d="M52.894 40.827a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.812-.827a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm1.627 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm-.813-.8a.333.333 0 1 1-.132-.653.333.333 0 0 1 .133.653Zm-1.626 0a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653ZM56 34.306a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.867.814a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668ZM56 37.56a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665ZM56 36a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm.867 2.36a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm0-1.613a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Z" fill="#10B981"/>
      <path d="M56.867 35.12a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.627 0a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M56.867 35.12a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm3.253 0a.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-1.626 3.24a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Zm-1.627 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm1.627-1.613a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.627 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm.812.813a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.629 0a.335.335 0 0 1-.342-.39.334.334 0 1 1 .342.39ZM57.679 36a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.629 0a.334.334 0 1 1-.027-.667.334.334 0 0 1 .027.667Zm.812 2.36a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.613a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-1.626-1.627a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M56.867 35.12a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm3.253 0a.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Z" fill="#10B981"/>
      <path d="M60.12 35.12a.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm0 3.24a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.613a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Z" fill="#10B981"/>
      <path d="M60.12 35.12a.319.319 0 0 1-.32-.333.32.32 0 0 1 .32-.32.334.334 0 0 1 0 .653Zm-3.974 5.707a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653ZM56.96 40a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm.8.827a.333.333 0 1 1-.001-.666.333.333 0 0 1 0 .666ZM56.146 39.2a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm1.614 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.626 1.627a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Z" fill="#10B981"/>
      <path d="M57.76 40.827a.333.333 0 1 1-.001-.666.333.333 0 0 1 0 .666Zm.814-.827a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm1.626 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-.814-.8a.333.333 0 1 1 0-.667.333.333 0 0 1 0 .667Zm-1.626 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm3.173-1.64a.333.333 0 1 1-.133-.653.333.333 0 0 1 .133.653Zm0-1.56a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm.814 2.36a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Z" fill="#10B981"/>
      <path d="M61.747 38.36a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.653.333.333 0 0 1 .132.653Zm-.734 4.08a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm.815-.827a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-.815-.8a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm4.975 12.36a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.812-.813a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654ZM66.8 54a.333.333 0 1 1-.133.653A.333.333 0 0 1 66.8 54Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm-.813-2.44a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm0 1.626a.333.333 0 1 1-.132.653.333.333 0 0 1 .133-.653Zm1.628 1.627a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm1.628-6.506a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm0 1.627a.333.333 0 1 1 0 .665.333.333 0 0 1 0-.665Zm.812-.814a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.627 1.627a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-2.44a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm0 1.627a.333.333 0 1 1 .001.665.333.333 0 0 1 0-.665Z" fill="#10B981"/>
      <path d="M65.988 51.56a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm-1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm1.628-6.493a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm0 1.599a.32.32 0 0 1 .32.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .334-.32Zm.812-.786a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .321.321 0 0 1 .333-.32Zm-1.627 0a.32.32 0 0 1 .32.32.333.333 0 0 1-.653 0 .321.321 0 0 1 .333-.32Zm1.627 1.613a.333.333 0 1 1 0 .667.333.333 0 0 1 0-.667Zm-1.627 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-.813-2.427a.32.32 0 1 1-.088.634.32.32 0 0 1 .088-.634Zm0 1.6a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .334-.32Zm1.628 1.641a.333.333 0 1 1 0 .666.333.333 0 0 1 0-.666Zm-1.628 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm1.628-6.494a.333.333 0 1 1-.133.654.333.333 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm.812-.773a.333.333 0 1 1-.133.653.333.333 0 0 1 .133-.653Zm-1.627 0a.334.334 0 1 1-.133.654.334.334 0 0 1 .133-.654Zm1.627 1.586a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.333.333 0 0 1 .333-.32Zm-1.627 0a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Zm-.813-2.44a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm0 1.627a.333.333 0 1 1-.132.653.333.333 0 0 1 .133-.653Z" fill="#10B981"/>
      <path d="M65.988 45.067a.32.32 0 1 1-.027.639.32.32 0 0 1 .027-.64Zm-1.628-.001a.32.32 0 1 1-.088.634.32.32 0 0 1 .088-.634Zm-1.628 6.494a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm-1.628 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.628-.001a.333.333 0 1 1-.132.653.333.333 0 0 1 .133-.653Zm-.813-.813a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm0 1.627a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm-.815.813a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm-1.628-6.506a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.628 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.628 1.627a.333.333 0 0 1 0 .653.322.322 0 0 1-.295-.198.321.321 0 0 1-.025-.122.32.32 0 0 1 .32-.333Zm1.628 0a.333.333 0 1 1 .001.665.333.333 0 0 1 0-.665Zm-.813-.814a.334.334 0 0 1 0 .654.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334Zm0 1.627a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Z" fill="#10B981"/>
      <path d="M62.732 51.56a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm-1.626-6.494a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm1.626 0a.32.32 0 1 1-.088.634.32.32 0 0 1 .088-.634Zm-1.628 1.6a.32.32 0 1 1-.32.32.306.306 0 0 1 .32-.32Zm1.628 0a.32.32 0 0 1 .32.32.334.334 0 0 1-.653 0 .32.32 0 0 1 .334-.32Zm-.813-.786a.32.32 0 1 1-.32.32.307.307 0 0 1 .32-.32Zm0 1.613a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.334Zm-.815.814a.333.333 0 0 1 0 .653.32.32 0 0 1-.32-.32.32.32 0 0 1 .32-.333Zm1.628 0a.334.334 0 1 1 0 .667.334.334 0 0 1 0-.667Zm-1.628-6.494a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.628 0a.334.334 0 1 1-.132.654.334.334 0 0 1 .133-.654Zm-1.628 1.627a.333.333 0 0 1 0 .654.32.32 0 0 1-.32-.334.32.32 0 0 1 .32-.32Zm1.628 0a.333.333 0 1 1-.132.653.333.333 0 0 1 .133-.653Zm-.813-.773a.334.334 0 0 1 0 .653.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32Zm0 1.586a.32.32 0 1 1 0 .64.307.307 0 0 1-.32-.32.32.32 0 0 1 .32-.32Z" fill="#10B981"/>
      <path d="M62.734 45.066a.32.32 0 1 1 0 .64.32.32 0 0 1 0-.64Zm1.626 0a.32.32 0 1 1-.088.634.32.32 0 0 1 .088-.634Zm2.306-4.08a.333.333 0 0 1 .333.32.319.319 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-1.586 0a.334.334 0 0 1 .333.32.32.32 0 0 1-.333.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M66.666 40.986a.333.333 0 0 1 .333.32.319.319 0 0 1-.333.32.32.32 0 1 1 0-.64Zm-3.2 0a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Z" fill="#10B981"/>
      <path d="M63.466 40.986a.32.32 0 1 1 0 .64.32.32 0 0 1-.333-.32.334.334 0 0 1 .333-.32Zm-.799 17.68A.333.333 0 1 1 62.64 58a.333.333 0 0 1 .026.666Zm0-1.613a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm.801 2.441a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm0-1.627a.333.333 0 1 1-.027-.666.333.333 0 0 1 .026.666Zm-.802 5.693a.333.333 0 1 1 0-.6.333.333 0 0 1 0 .654v-.054Zm.001-1.626a.333.333 0 1 1-.027-.665.333.333 0 0 1 .027.665Zm.799 2.44a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm0-1.627a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Z" fill="#10B981"/>
      <path d="M63.468 59.494a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm-.801 5.679a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.132-.652.333.333 0 0 1 .132.652Zm.799 2.426a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm0-1.613a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm0-1.626a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm-.8 4.053a.32.32 0 1 1 .32-.32.333.333 0 0 1-.32.32Zm.8.813a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Z" fill="#10B981"/>
      <path d="M63.466 65.986a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm1.614-6.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.612.001a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm1.612-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.612 0a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm.799.8a.333.333 0 1 1 .133-.654.333.333 0 0 1-.133.654Zm1.627 0a.333.333 0 1 1 .132-.653.333.333 0 0 1-.132.653Zm-1.627-1.613a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm1.627 0a.333.333 0 1 1 .133-.653.333.333 0 0 1-.133.653Zm.773 2.439a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm0-1.626a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654ZM65.08 56.24a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.612 0a.333.333 0 1 1-.027-.666.333.333 0 0 1 .026.666Zm3.199 0a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.587 6.507a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.614 0a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm1.614-1.627a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.614 0a.335.335 0 0 1-.4-.327.333.333 0 1 1 .4.327Zm.801.813a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm1.627 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm1.627 0a.334.334 0 1 1 .133-.653.334.334 0 0 1-.133.654Zm.773 2.441a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M65.08 59.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.612.001a.333.333 0 1 1-.027-.667.333.333 0 0 1 .026.667Zm3.199-.001a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-1.587 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.614 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm1.614-1.612a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.614-.001a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm.801.8a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .321.321 0 0 1-.334.32Zm1.627 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .654 0 .32.32 0 0 1-.334.32Zm-1.627-1.613a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm1.627 0a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm.772 2.426a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm.001-1.612a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.587-1.627a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-1.614 0a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm3.201 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.587 4.866a.32.32 0 1 1 0-.64.32.32 0 0 1 .333.32.333.333 0 0 1-.333.32Zm-1.614 0a.334.334 0 0 1-.333-.32.32.32 0 0 1 .333-.32.32.32 0 1 1 0 .64Zm.802-.813a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Zm1.625 0a.32.32 0 1 1 .026-.64.32.32 0 0 1-.026.64Z" fill="#10B981"/>
      <path d="M65.08 65.986a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.333.32Zm-1.614 0a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm3.2 0a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm.001-6.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm0-1.626a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm0-1.627a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm0 6.507a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M66.667 59.493a.334.334 0 1 1 .133-.654.334.334 0 0 1-.133.654Zm-.001 6.493a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32Zm.001-1.612a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm0-1.627a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Z" fill="#10B981"/>
      <path d="M66.666 65.986a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .319.319 0 0 1-.333.32ZM67.6 51.56a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm0 1.627a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm.814-.814a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm0 1.627a.333.333 0 1 1 .026.666.333.333 0 0 1-.026-.666Zm-.814.814a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm-.001-6.508a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm0 1.627a.333.333 0 1 1 .133.654.333.333 0 0 1-.133-.654Zm.814-.813a.334.334 0 0 1 .368.47.334.334 0 1 1-.368-.47Zm0 1.627a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Z" fill="#10B981"/>
      <path d="M67.6 51.56a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm0-6.494a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm0 1.6a.32.32 0 0 1 .333.32.334.334 0 0 1-.654 0 .32.32 0 0 1 .32-.32Zm.814-.786a.32.32 0 0 1 .333.32.333.333 0 0 1-.653 0 .32.32 0 0 1 .32-.32Zm-.001 1.613a.333.333 0 1 1 .133.652.333.333 0 0 1-.133-.652Zm-.814.813a.332.332 0 0 1 .4.327.335.335 0 0 1-.4.327.333.333 0 0 1 0-.654Zm.001-6.492a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm0 1.626a.334.334 0 1 1 .026.666.334.334 0 0 1-.026-.666Zm.814-.774a.333.333 0 1 1 .026.665.333.333 0 0 1-.026-.665Zm0 1.587a.334.334 0 0 1 .333.32.321.321 0 0 1-.333.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M67.6 45.066a.32.32 0 1 1-.32.32.333.333 0 0 1 .32-.32Zm.732-4.079a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Z" fill="#10B981"/>
      <path d="M68.332 40.987a.32.32 0 0 1 .32.32.307.307 0 0 1-.32.32.32.32 0 1 1 0-.64Zm-.812 17.68a.334.334 0 0 1 0-.653.319.319 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.613a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.334.322.322 0 0 1-.32.32Zm.813 2.439a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.813 5.694a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm0-1.627a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.334.32.32 0 0 1-.32.32Zm.813 2.44a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M68.333 59.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm-.813 5.68a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Zm0-1.613a.334.334 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm.814 2.427a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-.001-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333ZM67.52 66.8a.32.32 0 1 1 0-.64.32.32 0 0 1 0 .64Z" fill="#10B981"/>
      <path d="M68.334 65.987a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-.001-6.494a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm0 6.507a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.627a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M68.333 59.493a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.333.32.32 0 0 1-.32.32Zm.001 6.494a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32Zm-.001-1.614a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm0-1.626a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Z" fill="#10B981"/>
      <path d="M68.334 65.987a.32.32 0 1 1 .32-.32.306.306 0 0 1-.32.32ZM62.56 37.56a.333.333 0 1 1 0-.665.333.333 0 0 1 0 .665Zm0-1.56a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm.813 2.36a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32Zm-.001-1.613a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667ZM65 38.36a.32.32 0 1 1 .32-.32.307.307 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M63.373 38.36a.32.32 0 0 1-.32-.32.333.333 0 0 1 .653 0 .321.321 0 0 1-.333.32ZM65 36.747a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-1.628 0a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm.815.813a.333.333 0 0 1 0-.654.32.32 0 0 1 .32.32.32.32 0 0 1-.32.334Zm1.627 0a.334.334 0 0 1-.254-.564.333.333 0 1 1 .254.564Zm.852.8a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.613a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Z" fill="#10B981"/>
      <path d="M66.666 38.36a.307.307 0 0 1-.32-.32.32.32 0 1 1 .32.32Zm0-1.613a.32.32 0 0 1-.32-.333.32.32 0 0 1 .32-.32.333.333 0 0 1 0 .653Zm-3.999 4.08a.333.333 0 1 1-.132-.652.333.333 0 0 1 .132.652Zm.799-.827a.333.333 0 1 1-.133-.654.333.333 0 0 1 .133.654Zm.801.827a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Zm-1.6-1.627a.333.333 0 1 1-.133-.652.333.333 0 0 1 .133.652Zm1.6 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.627 1.627a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666Z" fill="#10B981"/>
      <path d="M64.267 40.827a.333.333 0 1 1 0-.666.333.333 0 0 1 0 .666ZM65.08 40a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm1.587 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm-.773-.8a.334.334 0 1 1 0-.667.334.334 0 0 1 0 .667Zm-1.627 0a.334.334 0 1 1 0-.668.334.334 0 0 1 0 .668Zm3.173-1.64a.333.333 0 1 1-.132-.653.333.333 0 0 1 .133.653Zm.813.8a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Z" fill="#10B981"/>
      <path d="M68.253 38.36a.32.32 0 0 1-.333-.32.333.333 0 0 1 .653 0 .32.32 0 0 1-.32.32Zm-.733 2.466a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm.813-.826a.333.333 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-.813-.8a.334.334 0 0 1 0-.653.32.32 0 0 1 .32.32.32.32 0 0 1-.32.333Zm-41.04-5.227V69.24l7.08 1.64V32.106l-7.08 1.867Zm43.84 16.693a15.158 15.158 0 0 0-4.76.587c-6.4 2.08-11.386 6.893-11.386 15.413S59.294 82.08 65.56 82.08c.44 0 3.546.587 4.76.587 6.666 0 12-7.186 12-16 0-8.813-5.374-16-12-16Z" fill="#10B981"/>
      <path d="M80 66.587c0 7.573-4.16 13.72-9.64 13.72s-10.227-6.147-10.227-13.72c0-7.574 4.44-13.707 9.92-13.707 5.48 0 9.947 6.133 9.947 13.706Z" fill="#D1FAE5"/>
      <path d="M69.04 74.134a3.055 3.055 0 0 1-2.666-1.454l-1.76-2.48a2.266 2.266 0 0 1 .533-3.146 2.307 2.307 0 0 1 2.774.106l.946.787 2.667-6.067a2.253 2.253 0 0 1 2.067-1.333 2.4 2.4 0 0 1 .906.187c.546.249.975.697 1.2 1.253a2.266 2.266 0 0 1 0 1.72L71.894 72.4a1.516 1.516 0 0 1-.133.254 3.066 3.066 0 0 1-2.667 1.506l-.053-.026Z" fill="#fff"/>
      <path d="M73.6 61.44c.184 0 .366.041.533.12.323.142.577.405.706.734.134.324.134.689 0 1.013L71.013 72l-.08.147a2.187 2.187 0 0 1-3.734 0l-1.72-2.427a1.334 1.334 0 0 1 .294-1.853 1.342 1.342 0 0 1 1.64.053l1.88 1.56.986-2.24 2.174-4.946a1.333 1.333 0 0 1 1.213-.8l-.067-.054Zm.066-1.786a3.16 3.16 0 0 0-3 1.853l-2.173 4.947a3.173 3.173 0 0 0-4.614 4.28l1.734 2.426a4 4 0 0 0 3.4 1.894 4 4 0 0 0 3.466-1.947l.187-.36L76.506 64a3.187 3.187 0 0 0-1.627-4.186 3.28 3.28 0 0 0-1.333-.267l.12.107Z" fill="#10B981"/>
    </svg>
  )
}
