import React, { FC, useEffect, useMemo, useState } from 'react'
import { PostingFormState } from "./CreatePostingForm";
import UserMobile from "./UserMobile";
import UserEmail from "./UserEmail";
import Translation from "../../helper/Translation";
import Warning from '../Warning';


interface PostingCommunicationProps {
  state: PostingFormState | any;
  dispatch: React.Dispatch<any>;
  withOutDisplayIconName?: boolean
}


const PostingCommunication: FC<PostingCommunicationProps> = ({ state, withOutDisplayIconName = false, dispatch, recaptchaEnabled = true }) => {

  const [hasChat, setHasChat] = useState(false)
  const mobileWillBeSharedWarning = useMemo(() => (state.mobile_communications && state.mobile_communications.length > 0), [state.mobile_communications])

  useEffect(() => {
    const chatType = state.communications.find(c => c.communication_type == 'chat' || c.communicationType == 'chat')
    setHasChat(chatType ? true : false)
  }, [state.communications])


  return (<>
    <div className="grid grid-cols-1">
      {state.category !== null && state.category?.postingPhoneCall !== 'not_allowed' && (
        <UserMobile recaptchaEnabled={recaptchaEnabled} withOutDisplayIconName={withOutDisplayIconName} dispatch={dispatch} state={state} />)}

      {state.category !== null && state.category?.postingEmail !== 'not_allowed' && (
        <UserEmail withOutDisplayIconName={withOutDisplayIconName} dispatch={dispatch} state={state} />)}

    </div>

    <div className="flex flex-col gap-2">
      <div className="mt-2 flex gap-2">
        <input id="chat" checked={hasChat} onChange={(e) => {
          let communications = state.communications;

          if (hasChat) {
            // stop disable chant option (business need)
            // communications = []
          } else {
            communications.push({ communication_type: 'chat' })
          }

          dispatch({
            type: 'key_was_updated',
            payload: { key: 'communications', value: [...communications] }
          })

        }} name="push-notifications"
          type="checkbox"
          className="focus:ring-emerald-500 rounded-sm h-4 w-4 text-emerald-600 border-gray-300 cursor-not-allowed" />
        <label htmlFor="chat"
          className="text-coolGray-700 text-sm leading-5 font-normal">{Translation.t('create_new_ad.chat_in_app')}</label>
      </div>
      {mobileWillBeSharedWarning && <Warning title={Translation.t("frauds.labels.warning")} description={Translation.t("create_new_ad.your_contact_will_be_visible_to_users")} color="yellow" />}
    </div>
  </>)
}


export default PostingCommunication
