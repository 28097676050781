import {HttpUnprocessableEntity} from '../Components/BackendCall';
import { toast } from 'react-toastify';

export const showError = (error) => {
  let errorMessage = null;
  if (error instanceof HttpUnprocessableEntity) {
    errorMessage = error.messageStr();
  }
  toast.error(errorMessage);
};
