import moment from 'moment'
import React, { useState, useEffect } from 'react'
import BackendCall from '../../src/Components/BackendCall'
import Translation from '../../src/helper/Translation'
import { decrementUnreadNotificationsCount } from './events'

export default function NotificationsList({ notifications = [], token, locale = 'en', size = 'large' }) {
  const [localNotifications, setLocalNotifications] = useState([])

  useEffect(() => {
    setLocalNotifications([...notifications])
  }, [notifications])

  function markAsRead(notification) {
    // if the notification has been already read, we can navigate to it's url (if it has), or just return
    if (notification.readedAt) {
      if (!notification.webUrl) return

      // navigate to the url
      window.location.href = notification.webUrl
      return
    }

    setLocalNotifications(prevNotifications =>
      prevNotifications.map(n =>
        n.id === notification.id ? { ...n, readedAt: true } : n
      )
    )

    BackendCall.i({ token, locale })
      .markNotificationAsRead(notification.id)
      .then(() => {
        decrementUnreadNotificationsCount()
        if (notification.webUrl) {
          window.location.href = notification.webUrl
        }
      })
      .catch(err => console.log({ err }))
  }

  if (localNotifications.length == 0) {
    return (
      <div className="flex flex-col space-y-4 items-center justify-center h-96">
        <div className="flex items-center justify-center h-16 w-16 bg-coolGray-50 border rounded-full">
          <i className={`ri-notification-3-line ri-3x text-coolGray-400`}></i>
        </div>
        <p className="text-base leading-6 font-normal text-coolGray-700">{Translation.t('texts.you_have_no_notifications')}</p>
      </div>
    )
  }

  return (
    <>
      {
        localNotifications.map(n => {
          return (
            <button key={`${n.id} + ${n.readedAt}`} onClick={() => markAsRead(n)} type="button" className={`flex items-start gap-4 border ${n.readedAt ? 'bg-coolGray-50 border-coolGray-100 hover:bg-gray-50' : 'bg-emerald-50 border-emerald-100 hover:bg-emerald-100'} rounded-lg px-4 ${size == 'small' ? 'py-2' : 'py-3.5'} text-sm text-gray-700`} role="menuitem">
              <i className={`${n.readedAt ? 'bg-gray-100 text-coolGray-500 ' : 'bg-emerald-500 text-white'} ri-notification-3-line ri-lg p-2 rounded-full`}></i>
              <div className="flex-1 flex flex-col text-start">
                <p className="font-normal leading-6">{n.title}</p>
                <p className="leading-5 text-xs text-coolGray-500">{n.body}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-xs leading-none font-normal text-coolGray-400">{moment(n.createdAt).locale(locale).fromNow()}</p>
              </div>
            </button>
          )
        })
      }
    </>
  )
}
