import React, { useEffect, useState } from 'react'
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Translation from "../src/helper/Translation";
import 'remixicon/fonts/remixicon.css'
import PromotionAdSvg from "../src/Assets/svg/PromotionPosting/PromotionAdSvg";
import BackendCall from "../src/Components/BackendCall";




const PromoteOnBoarding = ({ token, locale }) => {

  const [showOnBoarding, setShowOnBoarding] = useState(false)
  const [promotionFaqs, setPromotionFaqs] = useState([])
  const [promotionOpen, setPromotionOpen] = useState(null)

  useEffect(() => {
    EventDispatcher.on('promotion-on-boarding', (event) => {
      BackendCall.i({ token: token, locale: locale })
        .getFaqs({ section: 'ad_promotion' })
        .then((res) => setPromotionFaqs(res))
      setShowOnBoarding(true)
    })
  }, [])


  const setPromotionId = (id) => {
    setPromotionOpen(id == promotionOpen ? null : id)
  }


  if (!showOnBoarding) return <></>

  return (
    <BaseModal maxWidth={`max-w-lg`} onClose={() => { setShowOnBoarding(false) }}>
      <div>
        <div className={`flex flex-col items-center p-2 pb-4`}>
          <PromotionAdSvg />
          <p className={`text-2xl leading-8 font-bold text-emerald-500`}>{Translation.t('promotion_posting.on_boarding.title')}</p>
          <p className={`text-sm leading-5 text-center font-normal text-coolGray-500 mt-2`}>{Translation.t('promotion_posting.on_boarding.description')}️</p>
          <div className={`py-4 flex gap-4 w-full`}>
            <div className={`flex flex-col justify-center items-center w-full gap-1`}>
              <div className="flex flex-col gap-2 justify-center items-center w-14 h-14 rounded-full p-1 bg-emerald-50">
                <i className="ri-eye-fill ri-xl text-coolGray-700" />
              </div>
              <span className="text-sm text-coolGray-500 leading-5 font-semibold">{Translation.t('promotion_posting.on_boarding.features.more_view')}</span>
            </div>
            <div className={`flex flex-col justify-center items-center w-full gap-1`}>
              <div className="flex flex-col gap-2 justify-center items-center w-14 h-14 rounded-full p-1 bg-emerald-50">
                <i className="ri-money-dollar-circle-fill ri-xl text-coolGray-700" />
              </div>
              <span className="text-sm text-coolGray-500 leading-5 font-semibold">{Translation.t('promotion_posting.on_boarding.features.speed_selling')}</span>
            </div>
            <div className={`flex flex-col justify-center items-center w-full gap-1`}>
              <div className="flex flex-col gap-2 justify-center items-center w-14 h-14 rounded-full p-1 bg-emerald-50">
                <i className="ri-e-bike-2-fill  ri-xl text-coolGray-700" />
              </div>
              <span className="text-sm text-coolGray-500 leading-5 font-semibold">{Translation.t('promotion_posting.on_boarding.features.free_delivery')}</span>
            </div>
          </div>
          <button onClick={() => { window.trackActivity({ event: 'home_super_opened_my_posts', rawPayload: JSON.stringify({}), redirectUrl: `${window.location.origin}/my_postings?origin=promotions`, processPayload: false }) }} className="flex items-center justify-center space-s-1 w-full px-6 py-3 bg-emerald-500 text-white truncate rounded-lg cursor-pointer text-base leading-6 font-normal">{Translation.t('promotion_posting.on_boarding.my_ads')}</button>
        </div>
        <div className={`flex px-6 p-4 rounded-md`}>
          <div className={`bg-white w-full`}>
            <p className={`text-base leading-6 font-bold text-coolGray-700 pb-4 space-y`}>{Translation.t('promotion_posting.on_boarding.faqs_title')}</p>
            <div className={`grid grid-cols-1 divide-y divide-coolGray-50`}>
              {promotionFaqs.map((promotionFaq) => {
                return (<div key={promotionFaq.id}>
                  <div className={`flex items-center justify-center w-full py-2 cursor-pointer`} onClick={() => setPromotionId(promotionFaq.id)}>
                    <p className={`text-sm leading-5 font-semibold text-coolGray-700 w-full`}>{promotionFaq.question}</p>
                    <i className={`${promotionOpen == promotionFaq.id ? 'ri-arrow-drop-down-line' : 'ri-arrow-drop-up-line'}  text-coolGray-700 ri-1x`}></i>
                  </div>
                  {promotionOpen == promotionFaq.id && (<span className={`text-xs leading-4 font-normal text-coolGray-500`}>{promotionFaq.answer}</span>)}
                </div>)
              })}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default PromoteOnBoarding
