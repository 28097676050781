import React, { useState } from "react";

interface FaqProps {
  title: string;
  description: string;
}
function FaqItem({ title, description }:FaqProps ) {
  const [isOpen , setIsOpen] = useState<boolean>(false)
  return (
    <div className="w-full flex flex-col">
      <div className={`flex items-center justify-center w-full py-2 cursor-pointer`} onClick={()=> setIsOpen((prevState)=> !prevState)}>
        <p className={`text-sm font-medium text-coolGray-700 w-full`}>{title}</p>
        <i className={`${isOpen ? 'ri-arrow-drop-up-line' : 'ri-arrow-drop-down-line'} text-coolGray-700 ri-1x`}></i>
      </div>
      {isOpen && <span className="text-xs leading-4 font-normal text-coolGray-500 my-2">{description}</span>}
    </div>
  )
}

export default FaqItem;



