import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import Translation from '../../helper/Translation';
import { AuctionService } from '../../Services/Auction';
import LiveAuctionTimer from './LiveAuctionTimer';

type Props = {
  auction?: PostingAuction;
	containerStyle?: string;
};

function TimerContainer({auction, containerStyle}: Props) {
	if (!auction) return null;

	return (
		<div className={`flex items-center justify-center space-s-2 ${containerStyle ?? ''}`}>
			<span 
        className={`${AuctionService.isTimerClosed(auction?.status) ? 'text-red-500' : 'text-coolGray-400'} text-xs`}
			>
        {AuctionService.isTimerClosed(auction?.status) ? Translation.t('texts.closed') : Translation.t('auctions.labels.closing_on')}
      </span>
      <LiveAuctionTimer 
				closed={AuctionService.isTimerClosed(auction?.status)} 
				expiresAt={auction?.expiresAt}
			/>
		</div>
	);
}

export default TimerContainer;