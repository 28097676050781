import React, { useState } from "react";
import BackendCall from "../BackendCall";
import ReactInputVerificationCode from "react-input-verification-code";
import Translation from "../../helper/Translation";
import DefaultButton from "../EditProfile/DefaultButton";
import Timer from "../Timer";

const OtpModal = ({ token, locale, mobileData, onSuccess, resendVerificationCode, api='verifyMobileV2' }) => {
  const [code, setCode] = useState('')
  const [errors, setErrors] = useState({})

  const validateAndSubmit = () => {

    BackendCall.i({ token, locale, pathPrefix: '/api/v2/' })
      [api](
        {
          number: mobileData.number,
          country_id: mobileData.countryId,
          code: code,
        },
      )
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        if (error.errors) {
          console.log({ error: error.errors[0].detail })
          setErrors({ code: error.errors[0].detail })
        }
      });
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className={`flex flex-col gap-2 md:px-6 px-4 pb-4`}>
          <div className="mt-6" dir="ltr">
            <div className="grid gap-2 grid-cols-4 px-4 justify-center">
              <ReactInputVerificationCode length={4} value={code} onChange={(e) => {
                if (/^\d+$/.test(e)) {
                  setErrors({})
                  return setCode(e)
                }
                // setErrors({ code: 'invalid code' })

              }} />
            </div>
          </div>
          <div className="mt-6 flex flex-col gap-2 items-center">

            <div className="flex items-center">
              {Object.keys(errors).map(k => {
                return (
                  <span key={k} className="text-sm text-red-500">{errors[k]}</span>
                )
              })}
            </div>

            <span className="text-base leading-7 font-normal text-coolGray-900">{Translation.t('create_new_ad.code_will_send_in_second')}</span>
            {/* <span className="text-sm leading-6 font-normal text-coolGray-500 cursor-pointer" onClick={resendVerificationCode}>{Translation.t('buttons.resend_verification_code')}</span> */}
            <div className="flex items-center justify-center">
              <Timer
                expiry={mobileData.nextNotificationTime}
                onFinish={() => { }}
                disabled={false}
                onResend={resendVerificationCode}
              />
            </div>
          </div>
          <DefaultButton label={Translation.t('buttons.submit')} disabled={code.length != 4} onButtonClick={validateAndSubmit} />
        </div>

      </div>
    </>
  );
}


export default OtpModal
