import React, { lazy, Suspense } from "react";
const CreatePostingForm = lazy(() => import("../src/Components/Posting/CreatePostingForm"))
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import { Env } from "../src/Services/AppContext";

interface PostAdProps {
  locale: string;
  authUser: any;
  token: string;
  env: Env;
  isVerified: boolean;
  shouldVerifyPropertyAdsWithAbsher: boolean;
  community_id?: number;
}

export default ({ locale, authUser, token, env, isVerified, shouldVerifyPropertyAdsWithAbsher, community_id }: PostAdProps) => {
  BackendCall.i({ token, locale })

  return (
    <Suspense fallback={Loader()}>
      <CreatePostingForm
        locale={locale}
        authUser={authUser}
        token={token}
        googleMapsApiKey={env.FRONTEND_GOOGLE_MAP_KEY}
        isVerified={isVerified}
        shouldVerifyPropertyAdsWithAbsher={shouldVerifyPropertyAdsWithAbsher}
        community_id={community_id}
      />
    </Suspense>
  )
}
