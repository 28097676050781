import React, { useEffect, useState } from "react"
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Translation from "../src/helper/Translation";
import EventDispatcher from "../src/helper/EventDispatcher";
import DefaultButton from "../src/Components/EditProfile/DefaultButton";
import Loader from "../src/Components/icon/Loader";
import BackendCall from "../src/Components/BackendCall";
import { PostingReportType } from "@shobbak/react-services/dist/Entities";
import RadioButton from "../src/Components/RadioButton";
import ImageUploader from "../src/Components/Posting/ImageUploader";
import ErrorBag from "./ErrorBag";
import { serialize } from 'object-to-formdata';
import LoginSvgIcon from "../src/Assets/svg/login/LoginSvgIcon";

const ReportEntityModal = ({ locale, token, user_id, posting_id, business_profile_id }) => {
  const [entity, setEntity] = useState<'user' | 'posting' | 'business_profile'>('posting')
  const [entityId, setEntityId] = useState()
  const [reportTypes, setReportTypes] = useState<PostingReportType[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedReportType, setSelectedReportType] = useState<string>();
  const [comments, setComments] = useState<string>();
  const [attachments, setAttachments] = useState([]);

  const [errorBag, setErrorBag] = useState([]);

  useEffect(() => {
    EventDispatcher.on('report-entity', e => {
      // e.detail might be user or user-10 (user or user with id 10)
      const [entity, id] = e.detail.split('-')
      setEntity(entity)
      if (id) setEntityId(id)
      setShowModal(true)
      setErrorBag([])
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)

    BackendCall.i({ token, locale })
      .getInvestigationsTypes(entity)
      .then(res => {
        setReportTypes(res)
        setIsLoading(false)
      })
  }, [entity])

  const submit = () => {
    setIsLoading(true)
    setErrorBag([])
    const formData = serialize(
      {
        [`${entity}_id`]: entityId || eval(`${entity}_id`),
        report_type: selectedReportType,
        comments,
        attachments
      },
      {
        indices: false,
        allowEmptyArrays: false,
      }
    );

    BackendCall.i({ token, locale })
      .createInvestigation(formData, entity)
      .then(res => {
        setIsLoading(false)
        setShowSuccessModal(true)
      })
      .catch(({ errors }) => {
        setErrorBag(errors)
        setIsLoading(false)
      })
  }

  const header = () => {
    const icon = {
      user: 'ri-flag-fill',
      posting: 'ri-error-warning-fill',
      business_profile: 'ri-flag-fill',
    }[entity]

    return (
      <div>
        <div className={`flex justify-between p-6`}>
          <div className="flex gap-4">
            <div className="w-11 h-11 rounded-full bg-emerald-50 flex items-center justify-center">
              <i className={`${icon} ri-lg text-emerald-500`} />
            </div>
            <div>
              <h6 className={`text-base leading-6 text-coolGray-900`}>{Translation.t(`report.${entity}.title`)}</h6>
              <p className="text-coolGray-500 text-sm leading-5">{Translation.t(`report.${entity}.description`)}</p>
            </div>
          </div>
          <i className={`ri-close-line ri-lg text-coolGray-400 cursor-pointer`} onClick={() => setShowModal(false)} />
        </div>
      </div>
    );
  }

  const footer = () => {
    return (
      <div className="flex w-full py-4 gap-4 justify-end">
        <DefaultButton containerClass="w-full" disabled={false} onButtonClick={submit} label={Translation.t('buttons.report')} />
      </div>
    );
  }

  if (!showModal) return (<></>);

  if (showSuccessModal) return (
    <BaseModal onClose={() => setShowModal(false)} overflowHidden={false}>
      <div className="p-4 md:p-10 flex flex-col items-center justify-center gap-4 md:gap-6">
        <div className="flex flex-col items-center justify-center text-center gap-2">
          <LoginSvgIcon />
          <h6 className="text-coolGray-700 text-xl">{Translation.t('texts.thank_you')}</h6>
          <p className="text-coolGray-500 text-sm md:text-base">{Translation.t('frauds.messages.thanks_for_cooperation')}</p>
        </div>
        <DefaultButton disabled={false} onButtonClick={() => window.location.reload()} label={Translation.t('buttons.done')} />
      </div>
    </BaseModal>
  )

  return (
    <BaseModal onClose={() => setShowModal(false)} overflowHidden={false}>
      {isLoading ? <Loader /> : (
        <>
          {header()}

          {errorBag.length > 0 && (<ErrorBag errorBag={errorBag} />)}

          <div className={`max-h-96 md:max-h-120 px-6 overflow-y-auto slider-scroll-style`}>

            {/* Start report types */}
            <div className="flex flex-col gap-2">
              {reportTypes.map(reportType => (
                <div onClick={() => setSelectedReportType(reportType.key)} key={reportType.key} className={`flex p-4 cursor-pointer border rounded-lg ${reportType.key === selectedReportType ? 'border-emerald-500' : 'border-coolGray-200'}`}>
                  <div className="flex-1">
                    {reportType.name}
                  </div>

                  <RadioButton
                    onChange={() => setSelectedReportType(reportType.key)}
                    value={reportType.key === selectedReportType}
                    inActiveColor='coolGray-200'
                    containerStyle="flex items-center justify-center"
                  />
                </div>
              ))}
            </div>
            {/* End report types */}

            {/* Start comments */}
            <div className="mt-6">
              <label htmlFor="report-entity-comments" className="text-coolGray-700">{Translation.t('texts.write_message')}</label>
              <textarea id="report-entity-comments" placeholder={Translation.t(`inputs.report_${entity}.placeholder`)} value={comments} onChange={e => setComments(e.target.value)} className="mt-2 w-full resize-none border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
            </div>
            {/* End comments */}

            {/* Start Image/Video uploader */}
            <div className="mt-6">
              <ImageUploader state={{ images: attachments }} dispatch={({ payload }) => setAttachments([...payload[0].value])} validateImage={console.log} />
            </div>
            {/* End Image/Video uploader */}

            {footer()}

          </div>
        </>
      )}
    </BaseModal>
  );
}

export default ReportEntityModal
