import { Business, Country, DeviceMeResponse, User } from '@shobbak/react-services/dist/Entities';
import React from 'react';

export interface Env {
  CHECKOUT_PUBLIC_KEY?: string;
  FRONTEND_GOOGLE_MAP_KEY?: string;
  SOHBBAK_SOCKET_URL?: string;
}

type AppContextParam = {
  user?: User;
  device?: DeviceMeResponse;
  businessProfile?: Business;
  country?: Country;
  env?: Env
};

const AppContext = React.createContext({
  user: undefined,
  device: undefined,
  businessProfile: undefined,
  country: undefined,
  env: {}
} as AppContextParam); // Create a context object

export default AppContext;
