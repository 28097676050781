import React, { useState } from 'react';
import 'remixicon/fonts/remixicon.css'
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import Loader from "./icon/Loader";
import camelCase from 'lodash/camelCase'
import { toast } from "react-toastify";
import { Theme } from '@shobbak/react-services';
import Translation from '../helper/Translation';
const { Colors } = Theme;


type ValidationType = {
  isEmpty: boolean;
  isValid: boolean;
  errorMessage?: string;
} | null;

type AddPaymentMethodFormProps = {
  publicKey?: string;
  onCancel: () => void;
  onSuccess: (cardEvent: FrameCardTokenizedEvent & { name: string }) => void;
  customRender?: (renderForm: () => any, renderButton: () => any) => any;
};

export default function AddPaymentMethodFormCompact({
  publicKey,
  onCancel,
  onSuccess,
  customRender,
}: AddPaymentMethodFormProps) {
  const [cardholder, setCardholder] = React.useState({
    name: '',
  });
  const errorBagRef = React.useRef({})
  const [errorBag, setErrorBag] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFramesLoading, setIsFramesLoading] = React.useState(true)


  const validateCard = (errors) => {
    const prevErrorBag = errorBagRef.current
    if (errors.element && !errors.isValid) {
      errorBagRef.current = { ...prevErrorBag, [camelCase(errors.element)]: "invalid " + camelCase(errors.element) }
    }
    if (errors.isValid) {
      delete prevErrorBag[camelCase(errors.element)]
      errorBagRef.current = { ...prevErrorBag }
    }

    setErrorBag(errorBagRef.current)

  }
  if (!publicKey) {
    return <Loader />;
  }
  return (
    // @ts-ignore
    <Frames
      cardTokenized={(e) => {
        onSuccess(e)
      }}
      ready={() => {  setIsFramesLoading(false) }}
      frameValidationChanged={(errors) => {
        validateCard(errors)
      }}
      config={{
        debug: true,
        publicKey: publicKey,
        cardholder: {
          name: cardholder.name,
        },
        localization: {
          cardNumberPlaceholder: "3344 2315 6674 1275",
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: 'CVV',
        },
        style: {
          base: {
            color: Colors['coolGray']['600']
          },
          placeholder: {
            base: {
              color: Colors['coolGray']['300']
            }
          }
        }
      }}
    >
      {isFramesLoading && <Loader />}

      <div className={`flex-col gap-2 ${isFramesLoading ? 'hidden' : 'flex'}`}>
        <div className="flex flex-col gap-4 mb-4">
          <div>
            <label>{Translation.t('inputs.card_number.label')}</label>
            <CardNumber
              className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'} />
            {errorBag?.cardNumber && (<span className={`text-red-500 -mt-2 text-sm`}>{errorBag?.cardNumber}</span>)}
          </div>

          <div>
            <label>{Translation.t('inputs.card_name.label')}</label>
            <input
              className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-coolGray-600 placeholder-coolGray-300 font-serif'}
              placeholder="Mahmoud Ahmed"
              onChange={(e) => {
                setCardholder({
                  name: e.target.value,
                });
              }}
            />
          </div>

          <div className={'flex space-x-4 items-center w-full'}>
            <div className="flex space-x-4 w-full">
              <div className={'flex flex-col w-1/3'}>
                <label>{Translation.t('inputs.card_expiry_date.label')}</label>
                <ExpiryDate className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'} />
                {errorBag?.expiryDate && (<span className={`text-red-500 text-sm`}>{errorBag?.expiryDate}</span>)}
              </div>
              <div className={'flex flex-col w-1/3'}>
                <label>{Translation.t('inputs.card_cvv.label')}</label>
                <Cvv className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'} />
                {errorBag?.cvv && (<span className={`text-red-500 text-sm`}>{errorBag?.cvv}</span>)}
              </div>
            </div>
            <div className={'w-1/3'}>
              <button
                className={`${isLoading ? 'cursor-not-pointer' : 'cursor-pointer'} whitespace-nowrap bg-emerald-600 border border-transparent rounded-md py-2 px-4 items-center justify-center text-base font-normal  leading-6 text-white hover:bg-emerald-700 active:bg-emerald-600 md:inline-flex`}
                onClick={() => {
                  setIsLoading(true)
                  Frames
                    .submitCard()
                    .catch(error => {
                      toast.error(error)
                    })
                    .finally(() => setIsLoading(false))
                }}
              >
                <div className={'flex gap-2 items-center justify-center'}>
                  {isLoading && (
                    <svg className="animate-spin -ms-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={4} />
                      <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  )}
                  <span>Save</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Frames>
  );
}


