import React from "react";

const DefaultInput = ({ type = 'text', containerClass = '', placeholder = '', name, label, value, onValueChange, inputClasses = '', isRequired = false }) => {

  if (type == 'textarea') {
    return (
      <div className={`flex flex-col ${containerClass}`}>
        <label className="text-base leading-5 text-semibold text-coolGray-700" htmlFor={name}>{label}{isRequired ? <span className="text-red-600">*</span> : null}</label>
        <textarea rows={3}
          placeholder={placeholder} onChange={(e) => { onValueChange(e.target.value) }}
          value={value} name={name} id={name}
          className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputClasses}`} />
      </div>
    )
  }
  return (
    <div className={`flex flex-col ${containerClass}`}>
      <label className="text-base leading-7 text-semibold text-coolGray-700" htmlFor={name}>{label}</label>
      <input placeholder={placeholder} onChange={(e) => { onValueChange(e.target.value) }} type={type} value={value} name={name} id={name} className={`w-full mt-1 form-input border border-coolGray-300 py-y px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputClasses}`} />
    </div>
  )
}

export default DefaultInput
