import React, {useState, useEffect, useReducer} from 'react'
import {
    Bank,
    WithdrawRequestDestination,
    WithdrawRequestDestinationDetail,
    WithdrawRequestMeta
} from '@shobbak/react-services/dist/Entities'
import camelCase from 'lodash/camelCase'
import BaseModal from '../../src/Components/Posting/Modal/BaseModal'
import Translation from '../../src/helper/Translation'
import BackendCall from "../../src/Components/BackendCall"
import _ from "lodash"
import ReviewStep from "../WithdrawRequest/ReviewStep";
import Header from "../WithdrawRequest/Header";
import Loader from '../../src/Components/icon/Loader'
import first from 'lodash/first'
import omit from 'lodash/omit'
import without from 'lodash/without'
import {toast} from 'react-toastify';

interface WithdrawRequestsProps {
    onClose: () => void;
    token: string;
    locale: string;
}

interface WithdrawRequest {
    id: bigint
    lineItems: any[]
    allowedActions: any[]
}

interface ValidationError {
    title: string;
    detail: string;
}

interface ReducerAction {
    type: string;
    payload: {
        key: string;
        value: any;
    }
}

function reducer(state, action?: ReducerAction) {
    switch (action.type) {
        case 'update_key':
        default:
            return {...state, [action.payload.key]: action.payload.value};
            break;
        case 'reset':
            if (action.payload?.key) {
                return {...omit(state, action.payload.key)}
            }

            return initialState
            break;
    }
}

const initialState = {
    amount: ''
};

export default function NewWithdrawRequest({onClose, token, locale}: WithdrawRequestsProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [destination, setDestination] = useState<WithdrawRequestDestination>()
    const [destinationFields, setDestinationFields] = useState([])
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
    const [withdrawRequestMeta, setWithdrawRequestMeta] = useState<WithdrawRequestMeta>()
    const [fieldSettings, setFieldSettings] = useState({})
    const [banks, setBanks] = useState<Bank[]>([])
    const [showSubmit, setShowSubmit] = useState(true)
    const [withdrawRequest, setWithdrawRequest] = useState<WithdrawRequest | null>(null)
    const [withdrawMethods, setWithdrawMethods] = useState([])
    const [selectedWithdrawMethod, setSelectedWithdrawMethod] = useState({})
    const [isFetchingWithdrawMethods, setIsFetchingWithdrawMethods] = useState(false)
    const [mode, setMode] = useState<'withdraw_rules' | 'withdraw' | 'add_bank' | 'withdraw_methods'>('withdraw_rules')

    useEffect(() => {
        getBanks()
        getWithdrawRequestsMeta()
    }, [])

    useEffect(() => {
        Object.entries(fieldSettings).forEach(([key, newObject]) => {
            dispatch({type: 'update_key', payload: {key: _.snakeCase(key), value: newObject?.value}})
        });
    }, [fieldSettings])

    useEffect(() => {
        if (!withdrawMethods.length) return
        if (state.bank) return

        setSelectedWithdrawMethod(first(withdrawMethods))
    }, [withdrawMethods])

    useEffect(() => {
        Object.entries(selectedWithdrawMethod).forEach(([key, value]) => {
            dispatch({type: 'update_key', payload: {key: _.snakeCase(key), value: value}})
        });
    }, [selectedWithdrawMethod])

    useEffect(() => {
        if (mode === 'add_bank') {
            resetWithdrawMethodData()
        }
    }, [mode])

    useEffect(() => {
        if (destination == "" || destination == undefined) return

        setDestinationFields(withdrawRequestMeta?.destinationFields[camelCase(destination)])
        getWithdrawMethods()
    }, [destination])

    function getWithdrawRequestsMeta() {
        BackendCall.i()
            .getWithdrawRequestMeta()
            .then(response => {
                setFieldSettings(response.data.fieldSettings)
                setWithdrawRequestMeta(response.data)
            })
            .catch(err => {
            })
    }


    function getBanks() {
        BackendCall.i()
            .getBanks()
            .then(response => setBanks(response))
            .catch(err => {
            })
    }

    function getWithdrawMethods() {
        setIsFetchingWithdrawMethods(true)
        BackendCall.i()
            .getWithdrawMethods()
            .then(response => {
                const methods = response
                setWithdrawMethods(methods)
                if (destination === 'bank_account' && !methods.length) {
                    setMode('add_bank')
                }
            })
            .catch(err => {
            })
            .finally(() => setIsFetchingWithdrawMethods(false))
    }

    function renderValidationErrors() {
        if (validationErrors.length == 0) return

        return (
            <div className="col-span-6 text-red-300 text-sm">
                {validationErrors.map((error: ValidationError, index) => {
                    return (
                        <p key={`error-${index}`}><span className="text-red-500">{error.title} ({error.detail})</span>
                        </p>
                    )
                })}
            </div>
        )
    }

    function renderWithdrawRules() {
        if (!withdrawRequestMeta) return <Loader/>

        return (
            <div>
                <div className="my-6 flex flex-col gap-6">
                    {withdrawRequestMeta?.allDestinations.map((dest: WithdrawRequestDestinationDetail) => (
                        <div key={dest.id} className="flex flex-col gap-4">
                            <div className="flex items-center gap-2">
                                <div className="w-9 h-9 bg-coolGray-50 rounded-full flex items-center justify-center">
                                    <img src={dest.iconUrl} className="w-4"/></div>
                                <p className="text-sm text-coolGray-700 font-bold">{dest.heading}</p>
                            </div>
                            <div>
                                {dest.points?.map((point) => (
                                    <div key={point.message}>
                                        <p className="text-sm text-coolGray-700">{point.message}</p>
                                        {point.points && (
                                            <ul className="list-disc list-inside">
                                                {point.points?.map((subPoint) => (
                                                    <li key={subPoint.message}
                                                        className="text-sm text-coolGray-700">{subPoint.message}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col mt-4">
                    <button onClick={e => {
                        e.preventDefault();
                        setMode('withdraw')
                    }}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                        {Translation.t('buttons.next')}
                    </button>
                </div>
            </div>
        )
    }

    function renderWithdrawMethodsList() {
        if (!withdrawMethods) return <Loader/>

        return (
            <div className="my-6 flex flex-col gap-6">
                <button
                    className="border border-dashed border-emerald-500 rounded-lg p-2 flex items-center justify-center gap-4"
                    onClick={e => {
                        e.preventDefault();
                        setMode('add_bank')
                    }}>
                    <span className="flex items-center justify-center p-2 bg-emerald-50 rounded-lg"><i
                        className="ri-add-circle-fill ri-lg text-emerald-500"/></span>
                    <span className="text-emerald-500">{Translation.t('texts.add_new_bank')}</span>
                </button>

                <div className="flex flex-col gap-2">
                    {withdrawMethods.map(withdrawMethod => (
                        <div key={withdrawMethod.id}
                             className="border rounded-lg p-3 bg-coolGray-100 flex items-center gap-2">
                            <div onClick={e => {
                                e.preventDefault();
                                setSelectedWithdrawMethod(withdrawMethod);
                                setMode('withdraw')
                            }}>
                                <div
                                    className={`rounded-full border-2 w-5 h-5 flex items-center justify-center ${selectedWithdrawMethod?.id === withdrawMethod.id ? 'border-emerald-500' : 'border-coolGray-300'}`}>
                                    {selectedWithdrawMethod?.id === withdrawMethod.id &&
                                        <div className="rounded-full bg-emerald-500 w-3 h-3"></div>}
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col gap-1" onClick={e => {
                                e.preventDefault();
                                setSelectedWithdrawMethod(withdrawMethod);
                                setMode('withdraw')
                            }}>
                                <p className="text-coolGray-700 font-semibold">{withdrawMethod.bankName}</p>
                                <p className="text-coolGray-400 text-sm">{withdrawMethod.beneficiaryName}</p>
                                <p className="text-coolGray-500 text-sm">{withdrawMethod.iban}</p>
                            </div>
                            <button onClick={e => {
                                e.preventDefault();
                                deleteWithdrawMethod(withdrawMethod.id)
                            }}>
                                <i className="ri-delete-bin-5-line ri-lg text-coolGray-400"/>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function renderDestinationSelector() {

        return (
            <>
                {/* Amount */}
                <div className="col-span-6 flex flex-col">
                    <label htmlFor="amount"
                           className="block text-sm font-medium text-coolGray-700 flex">{Translation.t("wallet.labels.amount")}</label>
                    <input onChange={(e) => dispatch({
                        type: 'update_key',
                        payload: {key: 'amount', value: e.target.value}
                    })} required={true} value={state.amount} type="number" name="amount" id="amount"
                           className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                </div>
                <div className="col-span-6 flex flex-col">
                    <label
                        className="block text-sm font-medium text-gray-700 flex">{Translation.t("wallet.labels.destination")}</label>
                    <select
                        id="destination"
                        name="destination"
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white form-select rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm">
                        <option value=""></option>
                        {withdrawRequestMeta?.allDestinations.map((d: WithdrawRequestDestinationDetail) => {
                            return (
                                <option key={d.id} value={d.key}>{d.name}</option>
                            )
                        })}
                    </select>
                </div>
            </>
        )
    }

    function renderDestinationFields() {
        if (!destinationFields.length) return
        return (
            <>

                {/* Dynamic fields */}
                {destinationFields.map(field => {
                    return (
                        <div key={field} className="col-span-6 flex flex-col">
                            <label htmlFor={field}
                                   className="block text-sm font-medium text-coolGray-700 flex">{Translation.t(`wallet.labels.${field}`)}</label>
                            <input disabled={!!fieldSettings[camelCase(field)]} onChange={(e) => dispatch({
                                type: 'update_key',
                                payload: {key: field, value: e.target.value}
                            })} value={state[field] || ''} required={true}
                                   type={field == "beneficiary_mobile_number" ? 'number' : 'text'} name={field}
                                   id={`${field}-id`}
                                   className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"/>
                        </div>
                    )
                })}
            </>
        )
    }

    function getFormData() {
        const formData = {...state}
        if (formData['beneficiary_mobile_number']) {
            formData['beneficiary_mobile_number'] = `966${formData['beneficiary_mobile_number']}`
        }

        return formData;
    }

    function saveWithdrawMethod() {
        setValidationErrors([])

        const formData = getFormData()

        BackendCall.i({token, locale})
            .createWithdrawMethod({...formData, destination})
            .then(response => {
                getWithdrawMethods()
                setMode('withdraw')
            })
            .catch(error => {
                setValidationErrors(error.errors)
            })
    }

    function deleteWithdrawMethod(id) {
        setValidationErrors([])

        BackendCall.i({token, locale})
            .deleteWithdrawMethod(id)
            .then(response => {
                toast.success(Translation.t('texts.withdraw_method_delete_success'));
                getWithdrawMethods()
            })
            .catch(error => {
                setValidationErrors(error.errors)
            })
    }

    function submit() {

        setValidationErrors([])

        const formData = getFormData()

        BackendCall.i({token, locale})
            .createWalletWithdrawRequest({...formData, destination})
            .then(response => {
                setWithdrawRequest(response)
                setShowSubmit(false)
            })
            .catch(error => {
                setValidationErrors(error.errors)
            })
    }

    function resetWithdrawMethodData() {
        setValidationErrors([])
        dispatch({
            type: 'reset',
            payload: {key: ['bank_id', ...without(destinationFields, ...Object.keys(fieldSettings).map(field => _.snakeCase(field)))]}
        })
    }

    function resetForm() {
        setValidationErrors([])
        dispatch({type: 'reset'})
        onClose()
    }

    const onCancelReviewStep = () => {
        setShowSubmit(true)
        setWithdrawRequest(null)
    }

    function renderReviewStep() {
        if (showSubmit) return;
        return (
            <ReviewStep withdrawRequestId={withdrawRequest?.id} onCancel={onCancelReviewStep} showToastyAlert={false}
                        allowedActions={withdrawRequest?.allowedActions} lineItems={withdrawRequest?.lineItems}/>
        )

    }

    function renderHeader() {
        if (mode === 'withdraw_rules') {
            return <Header icon="ri-information-fill" title={Translation.t("texts.withdraw_rules")}
                           onClose={resetForm}/>
        }

        if (mode === 'withdraw_methods') {
            return <Header icon="ri-bank-line" title={Translation.t("texts.saved_banks")} onClose={resetForm}/>
        }

        return <Header onClose={resetForm}/>
    }

    function renderAddNewBank() {
        return (
            <div className="mt-6">
                <p className="mb-2">{Translation.t('texts.add_new_bank')}</p>
                <div className="grid grid-cols-6 gap-6">
                    {renderValidationErrors()}

                    {/* Bank */}
                    {destination == 'bank_account' ?
                        <div className="col-span-6 flex flex-col">
                            <label
                                className="block text-sm font-medium text-gray-700 flex">{Translation.t("wallet.labels.bank")}</label>
                            <select
                                id="bank"
                                name="bank"
                                value={state.bank_id || ''}
                                onChange={(e) => dispatch({
                                    type: 'update_key',
                                    payload: {key: 'bank_id', value: e.target.value}
                                })}
                                className="mt-1 block w-full py-2 px-3 form-select border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm">
                                <option value=""></option>
                                {banks?.map((b: Bank) => {
                                    return (
                                        <option key={b.id} value={b.id}>{b.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : null
                    }

                    {renderDestinationFields()}
                </div>
                {destinationFields.length ? <div className="flex flex-col mt-4">
                    <button onClick={e => {
                        e.preventDefault();
                        saveWithdrawMethod()
                    }} type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                        {Translation.t('buttons.save')}
                    </button>
                </div> : null}
            </div>
        )
    }

    function renderBody() {
        if (mode === 'withdraw_rules') {
            return renderWithdrawRules()
        }

        if (isFetchingWithdrawMethods) return <Loader/>

        if (mode === 'add_bank') {
            return renderAddNewBank()
        }

        if (mode === 'withdraw_methods') {
            return renderWithdrawMethodsList()
        }

        if (showSubmit) {
            return (
                <div className="mt-6">
                    <div className="grid grid-cols-6 gap-6">
                        {renderValidationErrors()}
                        {renderDestinationSelector()}

                        {destination == 'bank_account' ?
                            <div className="col-span-6 flex items-end gap-2">
                                <div className="flex flex-col flex-1">
                                    <label htmlFor="withdraw_method"
                                           className="block text-sm font-medium text-coolGray-700 flex">{Translation.t('wallet.labels.bank')}</label>
                                    <input disabled={true} value={state.bank_name || ''} type="text"
                                           name="withdraw_method" id="withdraw_method"
                                           className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"/>
                                </div>

                                <button onClick={e => {
                                    e.preventDefault();
                                    setMode('withdraw_methods')
                                }} type="button"
                                        className="inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md text-coolGray-700 hover:bg-coolGray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coolGray-500">
                                    {Translation.t('buttons.change')}
                                </button>
                            </div>
                            : null
                        }

                        {renderDestinationFields()}
                    </div>
                    {destinationFields.length ? <div className="flex flex-col mt-4">
                        <button onClick={e => {
                            e.preventDefault();
                            submit()
                        }} type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            {Translation.t('wallet.actions.send_request')}
                        </button>
                    </div> : null}
                </div>
            )
        }

        return renderReviewStep()
    }

    return (
        <BaseModal onClose={resetForm} maxWidth="max-w-lg" overflowHidden={false}>
            <div className="bg-white p-4 sm:p-6">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    submit()
                }}>
                    <div className="sm:rounded-md">
                        <div>
                            {renderHeader()}


                            {renderBody()}
                        </div>

                    </div>
                </form>
            </div>
        </BaseModal>
    )
}
