import React, { FC, useState, useEffect, useMemo, lazy, useRef,Suspense } from "react"
import Translation from "../../src/helper/Translation"
import SearchSuggestion from "../../src/Types/SearchSuggestion"
import RecentSearch from "../../src/Types/RecentSearch"
import PopularSearch from "../../src/Types/PopularSearch"
import useDebouncedValue from "../../src/Hooks/useDebouncedValue"
import Loader from "../../src/Components/icon/Loader"
const SearchSuggestionsList = lazy(() => import("./SearchSuggestionsList"))
const RecentSearchList = lazy(() => import("./RecentSearchList"))
const PopularSearchList = lazy(() => import("./PopularSearchList"))
import redirectToUrl from './redirectToUrl'

interface MenuItem {
  id: string;
  icon: string;
  name: string;
  key: string;
  slug: string;
  search_url: string
}
interface SearchBoxProps {
  isMobile: boolean;
  baseSubmitUrl: string;
  searchWithCurrentUrl: boolean;
  withCard: boolean;
  withBackground: boolean;
  menuItems?: MenuItem[];
  backgroundUrl: string;
  isSearchSuggestionsEnabled: boolean;
  isRecentAndPopularSearchSuggestionsEnabled: boolean;
  selectSearchItem: any[],
  navigateToPageDirectly: boolean;
  widthFull: boolean;
  value: string;
  withSearchButton: boolean,
  dark: boolean,
}

const SearchBox: FC<SearchBoxProps> = ({
                                         baseSubmitUrl,
                                         menuItems,
                                         selectSearchItem = [],
                                         backgroundUrl = null ,
                                         value='',
                                         searchWithCurrentUrl= false ,
                                         widthFull = false,
                                         withBackground = true,
                                         withCard = true,
                                         isSearchSuggestionsEnabled = false,
                                         isRecentAndPopularSearchSuggestionsEnabled = false,
                                         isMobile = false,
                                         navigateToPageDirectly = false,
                                         withSearchButton = true,
                                         dark = false,
}) => {
  const [query, setQuery] = useState(value);
  const debouncedQuery = useDebouncedValue(query, 300);
  const isFirstRender = useRef(true);
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const [popularSearches, setPopularSearches] = useState<PopularSearch[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('all');
  const [selectedMenuItemType, setSelectedMenuItemType] = useState(null);
  const [submitUrl, setSubmitUrl] = useState(baseSubmitUrl)
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const showRecentOrPopularSearch = useMemo<boolean>(() => {
    return isRecentAndPopularSearchSuggestionsEnabled && inputIsFocused && (query == null || query == '')
  }, [inputIsFocused, query])

  useEffect(() => {
    if (!isRecentAndPopularSearchSuggestionsEnabled) return

    fetch('/recent_search')
      .then(response => response.json())
      .then(data => {
        setRecentSearches(data.data)
      })
      .catch(error => { })
  }, [])

  useEffect(() => {
    if (!isRecentAndPopularSearchSuggestionsEnabled) return

    fetch('/lookups/popular_searches')
      .then(response => response.json())
      .then(data => {
        setPopularSearches(data.data)
      })
      .catch(error => { })
  }, [])


  const handleSubmitUrl = (selectItem) => {
    if (navigateToPageDirectly) {
      // TODO: add support to nested pages
      window.location.href = `${baseSubmitUrl}/${selectItem?.slug}`
    } else {
      setSelectedMenuItemType(selectItem?.type)
      setSelectedMenuItem(selectItem?.slug)
      setSubmitUrl(selectItem?.search_url)
    }
  }


  useEffect(() => {
    if (!isSearchSuggestionsEnabled) return

    if (isFirstRender.current){
      isFirstRender.current = false
     return
    }

    if (debouncedQuery.length <= 1) {
      setSuggestions([])
      return
    }

    fetch(`/lookups/search_suggestions?q=${debouncedQuery}&pagesize=5`)
      .then(response => response.json())
      .then(data => {
        setSuggestions(data.data)
      })
      .catch(error => { })
  }, [debouncedQuery])

  const verticalType = useMemo(() => {
    return selectedMenuItemType != null && selectedMenuItem != 'all' && selectedMenuItemType == 'vertical_type' ? selectedMenuItem : null
  }, [selectedMenuItemType, selectedMenuItem])

  const category = useMemo(() => {
    return selectedMenuItemType != null && selectedMenuItem != 'all' && selectedMenuItemType == 'category' ? selectedMenuItem : null
  }, [selectedMenuItemType, selectedMenuItem])

  function resetAllInputs() {
    setQuery('')
    setSuggestions([])
  }

  function search(event) {
    event.preventDefault();


    const redirectUrl = new URL(event.target.action)
    redirectUrl.searchParams.delete('filter[q]');
    redirectUrl.searchParams.append('filter[q]', query)

    redirectToUrl({
      redirectUrl: redirectUrl.href,
      searchTerm: query,
      verticalType,
      category
    })
  }

  const handleSelectSearch = (key, value) => {
    if (['listing_type_id', 'city_id'].includes(key)) {
      if (!value) return
      const url = window.location.href
      const new_url = url.replaceAll(getValueFromUrl(key), value)
      window.location.href = new_url
      return

    }
    const url = new URL(window.location.href);
    url.searchParams.delete(`filter[${key}][]`);
    url.searchParams.set(`filter[${key}][]`, value);
    window.history.replaceState(null, null, url)
    window.location.reload()
  }

  const getValueFromUrl = (key) => {
    if (['listing_type_id', 'city_id'].includes(key)) {
      const url = window.location.href
      const listing_types_table = selectSearchItem.find(({ table }) => table.key == key)
      const { data } = listing_types_table.table
      const listing_type = data.find((listing_type) => url.indexOf(listing_type?.slug) > 0)
      return listing_type?.slug

    }
    const url = new URL(window.location.href);
    const value = url.searchParams.get(`filter[${key}][]`)
    return value ? value : undefined
  }

  return (
    <Suspense fallback={Loader()}>
      {/* Full search box, displayed on md screens and larger */}
      {!isMobile && (
        <div className={`w-full hidden md:block`}>
          {/* Menu Items */}
          <div className="rounded-lg relative" style={withBackground && backgroundUrl ? {
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${backgroundUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          } : {}}
          >
            <div className={`relative flex flex-col space-y-2 lg:space-y-3 xl:space-y-6 items-center justify-start rounded-lg w-full ${backgroundUrl ? 'bg-white bg-opacity-30' : ''} ${withBackground && backgroundUrl ? 'p-8 px-24' : ''}`}>
              {withBackground ?
                <p className="text-center text-xl lg:text-2xl xl:text-4xl lg:leading-6 xl:leading-8 lg:py-3 xl:py-6 text-white mb-1">
                  <span className="font-semibold">{Translation.t('texts.buy_and_sell')}&nbsp;</span>{Translation.t('texts.whatever_you_need')}
                </p>
                : null
              }
              <div className={`flex flex-col items-start justify-start w-full`}>
                { withCard && (
                  <div className="flex flex-col items-start justify-start px-4 pt-2 pb-2 lg:pb-4 rounded-lg max-w-full">
                    {menuItems && menuItems.length > 0 &&
                        <div className="flex space-s-4 items-start justify-start max-w-full overflow-x-auto hide-scroll">
                            <div
                                onClick={() => setSelectedMenuItem('all')}
                                className={`flex items-start justify-start px-1 sm:px-4 py-2 border-2 rounded-full cursor-pointer whitespace-nowrap ${selectedMenuItem == 'all' ? (dark ? 'border-white bg-white bg-opacity-10' : 'border-emerald-600 bg-emerald-50') : 'border-transparent'}`}>
                                <p className={`text-sm leading-none text-center ${selectedMenuItem == 'all' ? (dark ? 'text-white' : 'text-emerald-600') : (dark ? 'text-white' : 'text-coolGray-600')}`}>
                                  {Translation.t('texts.all_verticals')}
                                </p>
                            </div>
                          {menuItems.map((menuItem: MenuItem) => {
                            return (
                              <div
                                key={menuItem.slug}
                                onClick={() => { handleSubmitUrl(menuItem) }}
                                className={`flex items-start justify-start px-1 sm:px-4 py-2 border-2 rounded-full cursor-pointer whitespace-nowrap ${selectedMenuItem == menuItem.slug ? (dark ? 'border-white bg-white bg-opacity-10' : 'border-emerald-600 bg-emerald-50') : 'border-transparent'}`}>
                                <p className={`text-sm leading-none text-center ${selectedMenuItem == menuItem.slug ? (dark ? 'text-white' : 'text-emerald-600') : (dark ? 'text-white' : 'text-coolGray-600')}`}>
                                  {menuItem.name}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                    }
                  </div>
                )}
                {/* Search Field  */}
                <form onSubmit={search} action={submitUrl} method="GET" className="w-full">
                  <div className="flex space-s-4 w-full justify-between">
                    <div className="w-full flex-1">
                      <div className="relative rounded-md shadow-sm h-12">
                        <div className="absolute inset-y-0 start-0 ps-3 flex items-center pointer-events-none h-12">
                          <svg className="h-5 w-5 text-coolGray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                        </div>
                        <input
                          data-search-input-field="true"
                          autoComplete="off"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          onFocus={() => setInputIsFocused(true)}
                          type="text"
                          name="filter[q]"
                          id="search"
                          className="h-12 focus:ring-emerald-500 focus:border-emerald-500 block w-full ps-10 sm:text-sm border-gray-300 rounded-md"
                          placeholder={Translation.t('texts.search_for_anything')} />

                        {isSearchSuggestionsEnabled && (recentSearches.length || popularSearches.length) ? (<ul id="searches-list" style={{ zIndex: 99999 }} className={`absolute bg-white rounded-lg w-full mt-1 ${showRecentOrPopularSearch ? 'border border-coolGray-200' : ''}`}>
                          {showRecentOrPopularSearch ? <RecentSearchList
                            recentSearches={recentSearches}
                            submitUrl={baseSubmitUrl}
                            verticalType={verticalType}
                            category={category}
                            blurInputHandler={() => setInputIsFocused(false)}
                            setRecentSearches={(result) => setRecentSearches(result)}
                          /> : null}

                          {showRecentOrPopularSearch ? <PopularSearchList
                            popularSearches={popularSearches}
                            submitUrl={baseSubmitUrl}
                            verticalType={verticalType}
                            category={category}
                            blurInputHandler={() => setInputIsFocused(false)}
                          /> : null}
                        </ul>) : null}

                        {/* Clear button */}
                        {query != '' ?
                          <div onClick={resetAllInputs} className="absolute inset-y-0 end-0 pe-3 flex items-center h-12">
                            <span className="text-coolGray-400 text-sm cursor-pointer">{Translation.t('buttons.clear')}</span>
                          </div>
                          : null}

                        {/* Suggestions */}
                        <SearchSuggestionsList suggestions={suggestions} submitUrl={baseSubmitUrl} verticalType={verticalType} category={category} />
                      </div>
                    </div>

                    {/* additional custom filters */}
                    {selectSearchItem.length == 1 && (
                      <div className={`flex gap-2`}>
                        {selectSearchItem && selectSearchItem.map(({ table }) => {
                          return (
                            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-full`} name={table.key} key={table.key}
                              value={getValueFromUrl(table.key)}
                              onChange={(e) => { handleSelectSearch(table.key, e.target.value) }} id="">
                              <option value=''>{table.title}</option>
                              {table.data.map((value) => {
                                return (<option key={value.id} value={['city_id', 'listing_type_id'].includes(table.key) ? value.slug : value.id}>{value.name}</option>)
                              })}
                            </select>)
                        })}
                      </div>
                    )}

                    { withSearchButton && (
                      <button type="submit" className="h-12 px-8 py-3 shadow-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        <span className="text-base leading-normal text-center text-white">{Translation.t('texts.search')}</span>
                      </button>
                    )}
                  </div>

                  {/* additional custom filters */}
                  {selectSearchItem.length > 1 && (
                    <div className={`flex mt-2 w-full gap-2`}>
                      {selectSearchItem && selectSearchItem.map(({ table }) => {
                        return (
                          <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-full`} name={table.key} key={table.key}
                            value={getValueFromUrl(table.key)}
                            onChange={(e) => { handleSelectSearch(table.key, e.target.value) }} id="">
                            <option value=''>{table.title}</option>
                            {table.data.map((value) => {
                              return (<option key={value.id} value={['city_id', 'listing_type_id'].includes(table.key) ? value.slug : value.id}>{value.name}</option>)
                            })}
                          </select>)
                      })}
                    </div>
                  )}
                </form>
              </div>
            </div >
          </div >
        </div>
      )}

        <input type="text" hidden  name="filter[q]" value={query}/>

      {/* Simple input field for xs screens and mobile */}
      <div className={`${isMobile ? '' : 'md:hidden my-4'}`}>
        < div className={`${widthFull ? 'w-full' : 'mx-2 md:my-6'}`} >
          <div className="mt-1 relative rounded-md shadow-sm">
            <form onSubmit={search} action={submitUrl} method="GET" className="w-full">
              <input
                data-search-input-field="true"
                autoComplete="off"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onFocus={() => setInputIsFocused(true)}
                type="text"
                name="filter[q]"
                id="search"
                className="focus:ring-emerald-500 focus:border-emerald-500 block w-full pe-10 sm:text-sm border-gray-300 rounded-md"
                placeholder={Translation.t('texts.search_for_anything')} />
              <div className="absolute inset-y-0 end-0 pe-3 flex items-center">
                <button className={`${suggestions.length ? 'ps-2' : ''}`} type="submit">
                  <svg className="h-5 w-5 text-coolGray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>

                {/* Clear button */}
                {suggestions.length ? <button onClick={(e) => {
                  e.preventDefault();
                  resetAllInputs()
                }}>
                  <svg className="h-5 w-5 ms-2 fill-current text-coolGray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z" />
                  </svg>
                </button> : null}
              </div>

              {recentSearches.length || popularSearches.length ? (<ul id="searches-list" style={{ zIndex: 99999 }} className={`absolute bg-white rounded-lg w-full mt-1 ${showRecentOrPopularSearch ? 'border border-coolGray-200' : ''}`}>
                {showRecentOrPopularSearch ? <RecentSearchList
                  recentSearches={recentSearches}
                  submitUrl={baseSubmitUrl}
                  verticalType={verticalType}
                  category={category}
                  blurInputHandler={() => setInputIsFocused(false)}
                  setRecentSearches={(result) => setRecentSearches(result)}
                /> : null}

                {showRecentOrPopularSearch ? <PopularSearchList
                  popularSearches={popularSearches}
                  submitUrl={baseSubmitUrl}
                  verticalType={verticalType}
                  category={category}
                  blurInputHandler={() => setInputIsFocused(false)}
                /> : null}
              </ul>) : null}

              {/* Suggestions */}
              <SearchSuggestionsList suggestions={suggestions} submitUrl={baseSubmitUrl} verticalType={verticalType} category={category} />
            </form>
          </div>

          {selectSearchItem.length >= 1 && (
            <div className={`grid grid-cols-3 mt-2 w-full gap-2`}>
              {selectSearchItem && selectSearchItem.map(({ table }) => {
                return (
                  <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-full`} name={table.key} key={table.key}
                    value={getValueFromUrl(table.key)}
                    onChange={(e) => { handleSelectSearch(table.key, e.target.value) }} id="">
                    <option value=''>{table.title}</option>
                    {table.data.map((value) => {
                      return (<option key={value.id} value={['city_id', 'listing_type_id'].includes(table.key) ? value.slug : value.id}>{value.name}</option>)
                    })}
                  </select>)
              })}
            </div>
          )}
        </div >
      </div>
    </Suspense>
  )
}

export default SearchBox
