window.dhmsCountDown = function () {
  return {
    startDate: undefined,
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		clock: undefined,

		pad: function (number, len = 2) {
			return number.toString().padStart(len, '0')
		},

		timer: function () {
			// Get today's date and time
			var now = new Date().getTime();
			// Find the distance between now and the count down date
			var distance = this.startDate.getTime() - now;
			// If the count down is over, clear the interval and stop
			if (distance < 0) {
				clearInterval(this.clock);
        return
			}
			// Time calculations for days, hours, minutes and seconds
			this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
			this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
		},

		init: function (startDate) {
			this.startDate = startDate
			this.clock = setInterval(this.timer.bind(this), 1000);
		},
  };
};
