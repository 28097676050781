import React, {useEffect, useState, useRef} from "react";
import Translation from '../../../helper/Translation'
import {UserEmailType} from "../UserEmail";
import {constraints} from '../../../helper/constraints'
import isEmpty from 'lodash/isEmpty'
import ModalComponent from "../../Modal";


interface MobilePhoneModalType {
    onClose: () => {};
    onSubmit: () => {},
    selectedUserEmail: UserEmailType
}


const CreateUserEmailModal = ({selectedUserEmail, onClose, onSubmit}) => {

    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)


    useEffect(() => {
        setTimeout(() => {
            validate()
        }, 0)

    }, [selectedUserEmail, email])

    useEffect(() => {
        if (!isEmpty(selectedUserEmail)) {
            setEmail(selectedUserEmail.email)
        }
    }, [selectedUserEmail])

    function validate() {
        if (!constraints.test(email)) {
            setError(Translation.t('create_new_ad.invalid_email_address'))
        } else {
            setError(null)
        }
    }

    function submit() {
        validate()
        if (error === null) {
            onSubmit({email})
        }


    }

    return (
        <ModalComponent
          visible={true}
          onClose={onClose}
          body={(
            <div className="md:p-6 px-2 py-4">
                <div className="flex items-start">
                    <div
                        className="me-4 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-50">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M16.4993 0.5H1.49935C1.03911 0.5 0.666016 0.873096 0.666016 1.33333V14.6667C0.666016 15.1269 1.03911 15.5 1.49935 15.5H16.4993C16.9596 15.5 17.3327 15.1269 17.3327 14.6667V1.33333C17.3327 0.873096 16.9596 0.5 16.4993 0.5ZM3.70601 3.19837L9.04934 7.73587H9.05018L14.2877 3.20337L15.3777 4.4642L9.06018 9.93087L2.62684 4.46837L3.70601 3.19837Z"
                                  fill="#10B981"/>
                        </svg>

                    </div>
                    <div className="text-start flex-1 flex-col">
                        <p className="text-base leading-6 font-semibold text-coolGray-900">{isEmpty(selectedUserEmail)
                            ? Translation.t('create_new_ad.add_email_address') : Translation.t('create_new_ad.send_verification_link')
                        }</p>
                        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('create_new_ad.add_email_paragraph')}</p>
                    </div>
                    <div className="self-start cursor-pointer" onClick={onClose}>
                        <svg className="h-6 w-6 text-coolGray-400" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 24 24" stroke="none">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"/>
                        </svg>
                    </div>
                </div>

                <div className="flex flex-col mt-3">
                    <label htmlFor=""
                           className="text-sm leading-5 font-medium inline-flex flex-end  text-gray-700">{Translation.t('create_new_ad.email_address')}</label>

                    <input required={true}
                           value={email}
                           autoComplete="email"
                           onChange={(e) => {
                               setEmail(e.target.value)
                           }}
                           className="mt-1 form-input border border-coolGray-300 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                           type="email" id="user_email"/>

                </div>

                {error &&
                (<span className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{error} </span>)}

                <input disabled={error ? true : false} type="submit" name="commit"
                       value={Translation.t('create_new_ad.send_verification_link')}
                       onClick={submit}
                       className={`mt-4 ${error ? 'cursor-not-allowed' : 'cursor-pointer'} inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm`}
                       data-disable-with="Login"/>

            </div>
          )}
        />
    )
}


export default CreateUserEmailModal;