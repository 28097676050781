import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import { Services } from '@shobbak/react-services';
const { Helpers: { imageUrl } } = Services;
import TimerContainer from './TimerContainer';
import DateTime from '../../helper/DateTime';

type Props = {
  item: PostingAuction;
  onPress?: () => void;
  containerStyle?: string;
};

const MyAuctionItem = ({item, containerStyle, onPress}: Props) => {
	const {
    currentBidAmount,
    expiresAt,
    statusInfo,
    statusTitle,
    statusColor,
    status,
  } = item;
  const {title, processedAssetUrls, createdAt} = item.posting || {};
  const image = processedAssetUrls?.[0]?.thumbnail;

	return (
		<button
			disabled={!onPress}
			onClick={onPress}
			className={`flex space-s-2 p-2 border border-coolGray-200 rounded-lg w-full ${containerStyle ?? ''}`}
		>
			<div>
				<img className="w-8 h-8 rounded-full p-0.5 border border-coolGray-100" src={imageUrl(image, 25, 25)} />
			</div>

			<div className="flex-1">
        <div className="pb-2 flex justify-between border-b border-coolGray-100">
          <div>
            <p className="font-bold text-coolGray-700">{title}</p>
          </div>
          <div>
            <p className="mb-0.5 font-semibold text-emerald-500 text-end">{currentBidAmount?.formatted}</p>
            <p className="text-xs ms-1 text-coolGray-400 text-end">
              {new DateTime(createdAt).MMddyyyy()}
            </p>
          </div>
        </div>
        <div className="pt-1 flex justify-between">
					<div className="flex justify-between">
						<div className="flex items-center">
							<span className="flex me-1 items-center justify-center w-5 h-5 rounded-full" style={{ backgroundColor: statusInfo.backgroundColor }}>
								<i className={`${statusInfo.icon}`} style={{ color: statusInfo.foregroundColor }}></i>
							</span>
							<p className="text-xs font-medium leading-4" style={{ color: statusInfo.foregroundColor }}>{statusInfo.name}</p>
						</div>
					</div>
					<TimerContainer
						auction={item}
					/>
        </div>
			</div>
		</button>
	);
}

export default MyAuctionItem;