import React, {useEffect, useRef, useState} from "react"
import AdLocation from "../src/Components/Posting/Steps/AdLocationStep";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import map from 'lodash/map'
import BackendCall from "../src/Components/BackendCall";
import {toast} from "react-toastify";
import Translation from "../src/helper/Translation";
import isEmpty from 'lodash/isEmpty'
import EventDispatcher from "../src/helper/EventDispatcher";
import DefaultButton, {ButtonElement} from "../src/Components/EditProfile/DefaultButton";
import { showError } from "../src/helper/errors";


const EditPostingDescriptionModal = ({postingId, description, title, locale, token}) => {

    const [state, setState] = useState({description: description, title: title})
    const [showDescriptionModal, setCanShowDescriptionModal] = useState<boolean>(false)


    const updatePosting = () => {
        BackendCall.i({token, locale})
            .postingUpdate(postingId, {description: state.description, title: state.title, submit_for_review: true})
            .then(res => {
                toast.success(Translation.t('texts.ads_updated'));
                setTimeout(() => window.location.reload(), 100)
            }).catch((error)=> {
              showError(error)
            })
    }

    useEffect(() => {
        EventDispatcher.on('edit-description', () => {
            setCanShowDescriptionModal(true)
        })
    }, [])

    const footer = () => {
        return (
            <div className="bg-coolGray-100">
                <div className="flex w-full px-4 md:px-6 md:py-4 py-4  gap-4 justify-end">
                    <ButtonElement label={Translation.t('buttons.cancel')}
                                   className={`text-coolGray-500 border-coolGray-300`}
                                   onButtonClick={() => setCanShowDescriptionModal(false)}/>
                    <DefaultButton disabled={false} onButtonClick={updatePosting}
                                   label={Translation.t('buttons.save')}/>
                </div>
            </div>
        );
    }

    const header = () => {
        return (
            <div className={`bg-coolGray-100`}>
                <div className={`flex items-center justify-between  p-4`}>
                    <span
                        className={`text-base leading-6 font-semibold text-coolGray-900`}>{Translation.t('texts.edit')}</span>
                    <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`}
                       onClick={() => setCanShowDescriptionModal(false)}/>
                </div>
            </div>
        );
    }


    if (!showDescriptionModal) return (<></>);
    return (
        <BaseModal onClose={() => setCanShowDescriptionModal(false)}>
            <>
                {header()}
                <div className="flex flex-col gap-2 overflow-y-auto slider-scroll-style md:p-6 p-4">
                    <div className="flex flex-col gap-1">
                        <label className="block text-gray-700 text-start text-sm leading-5 font-normal"
                               htmlFor="title">{Translation.t('create_new_ad.ad_title')}</label>
                        <input
                            type="text"
                            name="title"
                            value={state?.title}
                            onChange={(e) => {
                                setState({description: state.description, title: e.target.value})
                            }}
                            className="mt-1 form-input border border-coolGray-300 block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                    </div>

                    <div className="flex flex-col gap-1">
                        <label className="block text-gray-700 text-start text-sm leading-5 font-normal"
                               htmlFor="description">{Translation.t('create_new_ad.ad_description')}</label>
                        <textarea
                            rows={3}
                            name="description"
                            value={state?.description}
                            onChange={(e) => {
                                setState({description: e.target.value, title: state.title})
                            }}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                    </div>

                </div>
                {footer()}
            </>
        </BaseModal>
    );

}


export default EditPostingDescriptionModal
