import React from "react";
import Button from "../Button";
import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';
import 'remixicon/fonts/remixicon.css'
import Accoridon from "../Accordion";
import Translation from "../../helper/Translation";
import BackendCall from "../BackendCall";
import ModalComponent from "../Modal";

export type Props = {
  visible: boolean;
  amount: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ConfirmBid = ({visible, amount, onClose, onConfirm}: Props) => {
  const [FAQs, setFAQs] = React.useState<any>();

  React.useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      const remoteFaqs = await BackendCall.i().getFaqs({
        section: 'create_auction',
      });
      setFAQs(
        remoteFaqs.map((remoteFaq) => {
          return {
            title: remoteFaq.question,
            content: remoteFaq.answer,
          };
        }),
      );
    } catch (error) {}
  };

  return (
    <ModalComponent
			visible={visible}
      onClose={onClose}
      body={
        <div>
          <div className="p-4 pt-0 bg-white">
            <div className="flex items-center justify-center mb-2">
              <AuctionRulesSvg />
            </div>
            <p className="font-bold text-2xl text-emerald-500 text-center mb-1">{Translation.t('auctions.modals.send_bid.title')}</p>
            <p className="text-center text-sm text-coolGray-500 px-4 mb-6">
              {Translation.t('auctions.modals.send_bid.description', {bid: amount})}
            </p>
          </div>
        </div>
      }
      footer={
        <div className="flex items-center">
          <Button
            onPress={onClose}
            type="outline"
            color="coolGray-400"
            label={Translation.t('buttons.no')}
            containerStyle="flex-1 me-4"
          />
          <Button
            label={Translation.t('buttons.yes_bid')}
            containerStyle="flex-1"
            onPress={onConfirm}
          />
        </div>
      }
    />
  );
}

export default ConfirmBid;
