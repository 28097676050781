import codes from 'country-calling-code';

export  default class CountryCode {
  
  private countryCode 

  constructor(countryCode){
    this.countryCode = countryCode
  }

  static available(){
    let enabled_countries = window.env?.enabled_countries ||  ['SAU', 'BHR']
   return  codes.filter(item => enabled_countries.includes(item.isoCode3))
  }

  get firstCountryCode(){
    return this.countryCode.countryCodes[0]
  }
  static getCountryIdFromMobileCountryCode(countryCode , countries){
   let code =  codes.find((code)=> code.countryCodes.includes(countryCode)  )
    if(!code)return
   return  countries.find(country=> country.code3 == code.isoCode3).id

  }

  static getDisplayCodeByCode3(countryCode3: string):string {
   let countryCodeItem = codes.find((code)=> code.isoCode3  == countryCode3 )
    if(!countryCodeItem)return ;
    return countryCodeItem.countryCodes[0]
  }
}
  

