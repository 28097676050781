import React ,{useState} from 'react'

import PromotionAdSvg from "../src/Assets/svg/PromotionPosting/PromotionAdSvg";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Translation from "../src/helper/Translation";


const PromotionPostingSuccessModal = ({posting ,redirect= true}) => {

  const [showSuccessModal , setShowSuccessModal] = useState(true)
  const viewPosting= ()=> {
    if(!redirect){
      setShowSuccessModal(false)
      return
    }
    window.location.href = `/p/${posting.id}`
  }

  if(!showSuccessModal)return(<></>)
  return (
    <BaseModal maxWidth={`max-w-md`} onClose={viewPosting}>
      <div className={`flex flex-col md:p-6 p-4 `}>
        <div className="flex flex-col gap-1 items-center">
          <PromotionAdSvg />
          <p className="text-2xl leading-8 font-bold text-coolGray-700 text-center">{Translation.t('promotion_posting.success_modal.title')}</p>
          <p className="text-base leading-6 font-normal text-coolGray-500 text-center">{Translation.t('promotion_posting.success_modal.description')}</p>
        </div>
        <div className="flex gap-2 pt-4">
          <button onClick={viewPosting} className={`w-full bg-emerald-500 text-white text-base leading-6 font-normal rounded py-2.5`}>{Translation.t('promotion_posting.success_modal.view_posting_button')}</button>
        </div>
      </div>
    </BaseModal>
  )
}


export default PromotionPostingSuccessModal