import React from "react";
import 'remixicon/fonts/remixicon.css';
import RadioButton from "./RadioButton";

export type ExtraOption = {
  id: string;
  description: string;
  selected: boolean;
};

type PaymentMethodCardProps = {
  action?: any;
  onDelete?: () => void;
  last4?: string;
  title?: string;
  name?: string;
  containerStyle?: any;
  iconName: string;
  selected?: boolean;
  onSelect?: () => void;
  options?: ExtraOption[];
  onOptionChange?: (id: string) => void;
};

const getPaymentMethodIcon = (
  brand: string,
  withAction: boolean,
  selected: boolean
) => {
  switch (brand.toLowerCase()) {
    case "visa":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center ${
            withAction ? "bg-white" : "bg-coolGray-100"
          } `}
        >
          <i className="ri-visa-line ri-lg text-emerald-500" />
        </div>
      );
    case "mada":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center ${
            withAction ? "bg-white" : "bg-coolGray-100"
          } `}
        >
          <i className="ri-visa-line ri-lg text-emerald-500" />
        </div>
      );
    case "apple_pay":
      return (
        <div className={`w-9 h-9 rounded-xl flex justify-center items-center ${withAction ? 'bg-white' : 'bg-coolGray-100'} `}>
       		<i className="ri-apple-fill ri-lg text-emerald-500" />
      	</div>
      );
    case "mastercard":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center ${
            withAction ? "bg-white" : "bg-coolGray-100"
          } `}
        >
          <i className="ri-mastercard-line ri-lg text-emerald-500" />
        </div>
      );
    case "wallet":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center ${
            withAction ? "bg-white" : "bg-coolGray-100"
          } `}
        >
          <i className="ri-wallet-2-fill ri-lg text-emerald-500" />
        </div>
      );
  }
  return <i className="ri-bank-card-line" />;
};

export default function PaymentMethodCard({
  action,
  onDelete,
  last4,
  title,
  name,
  containerStyle = {},
  iconName,
  selected = false,
  onSelect,
  options = [],
  onOptionChange = () => null,
}: PaymentMethodCardProps) {
  const [showDeleteIcon, setShowDeleteIcon] = React.useState(false);
  return (
    <div
      className={`${showDeleteIcon ? "bg-gray-200" : ""} 
				${action && !selected ? "bg-coolGray-50 border-coolGray-50" : ""} 
				${action ? "mb-2" : ""} 
				${containerStyle ?? ''} 
				flex flex-col items-center justify-center px-2 py-3 border border-coolGray-200 rounded-lg flex-1`}
    >
      <button
        className={`w-full flex flex-1 items-center justify-center space-s-4 ${
          showDeleteIcon ? "bg-gray-200" : ""
        }`}
        onClick={() => {
          if (showDeleteIcon) {
            setShowDeleteIcon(false);
          } else {
            onSelect && onSelect();
          }
        }}
        onDoubleClick={() => {
          if (onDelete) {
            setShowDeleteIcon(true);
          }
        }}
      >
        <div className="flex items-center justify-center">
          {showDeleteIcon ? (
            <i
              className="ri-delete-bin-line text-red-500 cursor-pointer "
              onClick={onDelete}
            />
          ) : (
            getPaymentMethodIcon(iconName || "", !!action, selected)
          )}
        </div>

        <div className="flex flex-col flex-1 items-start pe-4">
          {last4 ? (
            <div className="flex justify-start items-center">
              {Array(3)
                .fill(undefined)
                .map((i, k) => (
                  <div key={k} className="flex items-center me-2">
                    {Array(4)
                      .fill(undefined)
                      .map((i, key) => (
                        // @ts-ignore
                        <i
                          className="ri-checkbox-blank-circle-fill ri-xxs me-px"
                          key={key}
                        />
                      ))}
                  </div>
                ))}
              <p className="font-medium text-coolGray-700 text-sm">{last4}</p>
            </div>
          ) : (
            <div className="flex items-center justify-start">
              <p className="text-coolGray-700 font-medium text-sm">{title}</p>
            </div>
          )}
          {name ? <p className="text-coolGray-400 text-xs">{name}</p> : null}
        </div>

        {!action && (
          <i className="ri-checkbox-circle-fill text-emerald-500 ri-1x" />
        )}
        {action}
      </button>
      {selected && (
        <div className="flex flex-col w-full space-between ml-24">
          {options.map((option) => (
            <button
              className="w-full"
              onClick={() => {
                onOptionChange(option.id);
              }}
              key={option.id}
            >
              <RadioButton
                size="small"
                value={option.selected}
                containerStyle='my-0'
                inActiveColor='emerald-500'
                onChange={() => {
                  onOptionChange(option.id);
                }}
              />
              <p className="ps-1">{option.description}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
