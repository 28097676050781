import Translation from "../../src/helper/Translation";
import MobileValidation from '../../src/helper/MobileValidation'
import CountryCode from  '../../src/helper/countryCode'


window.mobileNumberInput = function mobileNumberInput() {
   
    const countryCodes = CountryCode.available()
    return {
        country_code: (new CountryCode(countryCodes[0])).firstCountryCode,
        country_codes: countryCodes ,
        mobile_number: '',
        error_message: Translation.t('errors.messages.invalid_mobile_number'),
        errors: [],
        iso3: '',
        validateMobileNumber(number, country_code, $dispatch) {

            const mobile =  new MobileValidation(`+${country_code}${number}`);
            if (!mobile.isValid()) {
                $dispatch('mobile-component', {payload: 'invalid'});
                return this.errors = this.error_message
            }
            this.errors = ''
            this.iso3 = mobile.countryIso3
            $dispatch('mobile-component', {payload: 'valid'});

        },
        get full_mobile() {
            return `+${this.country_code}${this.mobile_number}`
        }
    }

}