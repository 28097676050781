import React, { useRef, useState } from "react";
import Translation from "../../helper/Translation";

const ChangeUserProfile = ({profileUrl ,onProfileChange }) => {
  const [previewFile , setPreviewFile] = useState<string>(profileUrl)
  const fileUploadRef = useRef(null)
  const openFileUploader = ()=> {
    fileUploadRef?.current?.click()
  }
  const uploadFile = (event)=> {
    const file = event.target.files[0]
    onProfileChange(file)
    setPreviewFile(URL.createObjectURL(file))
  }

  return (
    <div className={`flex flex-col`}>
      <h2 className={`text-sm leading-5 font-medium text-coolGray-700`}>{Translation.t('texts.cover_photo')}</h2>
      <div className={`flex md:gap-6 gap-4 pt-2 items-center`}>
         <span className="inline-block rounded-full overflow-hidden bg-gray-100">
           <img src={previewFile} className={`object-cover h-16 w-16`} alt="profile image"/>
         </span>
        <input type="file" className={`hidden`}  accept="image/*" ref={fileUploadRef} onChange={uploadFile} />
        <button className={`rounded-md border border-coolGray-200 text-sm leading-4 h-8 font-medium text-gray-700 px-3 shadow-sm`} onClick={openFileUploader}>{Translation.t('texts.change')}</button>
      </div>
    </div>

  );
}



export default ChangeUserProfile