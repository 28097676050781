import React from 'react'

export default function ShobbakAgreementIcon() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92 48.227v-21.08a6.813 6.813 0 0 0-5.214-6.667l-34.56-8.32a6.812 6.812 0 0 0-8.413 6.667v66.507A6.815 6.815 0 0 0 52.226 92l34.573-8.44a6.813 6.813 0 0 0 5.214-6.666v-2.667" stroke="#FFD836" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M92 55.614a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.746a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm0 4.76a1.334 1.334 0 0 0 0-2.56 1.333 1.333 0 0 0 0 2.56Zm-64.627-55.6 2.854-9.333.413-1.333c.306-.974-1.227-1.334-1.52-.427l-2.854 9.333-.413 1.334c-.306.973 1.213 1.333 1.52.426ZM3.054 15.733 12 19.573l1.253.533a.813.813 0 0 0 1.08-.28.787.787 0 0 0-.28-1.08l-8.947-3.84-1.253-.546a.84.84 0 0 0-1.08.28.813.813 0 0 0 .28 1.093Z" fill="#10B981"/>
      <path d="m18.427 13.84-2.974-9.253-.426-1.333c-.36-.96-1.827-.587-1.52.426l2.973 9.254.413 1.333c.32.96 1.84.547 1.534-.413v-.014Z" fill="#FFD836"/>
      <path d="m73.2 60.2.253-5.64a.653.653 0 0 1 1.08-.507l4.28 3.373a.746.746 0 0 1 0 1.24l-4.534 2.294a.747.747 0 0 1-1.08-.76Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M75.999 56.733s6.667 7.12 2.093 13.64c-4.173 5.88-10.426 3.614-11.6 3.12a.24.24 0 0 1-.133-.293l.707-3.013a.24.24 0 0 1 .293-.187c1.867.373 14.053 2.427 8.573-11.293L76 56.733Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="m73.2 60.2.253-5.64a.653.653 0 0 1 1.08-.507l4.28 3.373a.746.746 0 0 1 0 1.24l-4.534 2.294a.747.747 0 0 1-1.08-.76Z" fill="#C2E9DB" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M20.133 37.334 18.8 42.84a.666.666 0 0 1-1.16.294l-3.587-4.107a.747.747 0 0 1 .293-1.213l4.867-1.334a.733.733 0 0 1 .92.854Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M18.08 40.667s-5.334-8.253.466-13.8c5.2-5 10.92-1.613 12-.907a.24.24 0 0 1 .094.307l-1.307 2.827a.241.241 0 0 1-.32.146c-1.76-.733-13.333-5-10.52 9.494l-.413 1.933Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M20.132 37.333 18.8 42.84a.653.653 0 0 1-1.16.28l-3.587-4.094a.759.759 0 0 1 .294-1.226l4.866-1.334a.746.746 0 0 1 .92.867Z" fill="#C2E9DB" stroke="#10B981" strokeWidth=".75" strokeMiterlimit="10"/>
      <path d="M43.24 77.56s-.267-.16-.293-.533c-.027-.374.173-.734.293-.747l3.733-.547-2.546-1.92c-3.52-2.666-7.787-7.333-9.213-12.506-2.667-9.467-2.52-20.467-2.347-24.747a2.841 2.841 0 0 1 1.253-2.373l10.547-5.84-.2-1.52 4.706-.947h.454c.252-.001.5.063.72.187l14.853 8.12a2.826 2.826 0 0 1 1.334 2.373c.173 4.253.266 15.173-2.32 24.627C62.12 68.88 53.906 75.453 50.306 76.32h-.294l-.226.187-6.547 1.053Z" fill="#fff"/>
      <path d="M49.694 27.134h.08l14.787 8.2a1.467 1.467 0 0 1 .573 1.253c.16 4.2.267 14.96-2.266 24.226C60.96 67.773 53.334 74.147 49.92 75l-.56.133-.24.2h-.36l-3.533-2.666c-3.334-2.52-7.387-6.947-8.734-11.8-2.56-9.334-2.453-20.107-2.293-24.334a1.467 1.467 0 0 1 .573-1.253l9.747-5.413 1.6-.88-.16-1.187 3.373-.6h.32l.04-.066Zm-.04-2.6h-.413a2.13 2.13 0 0 0-.427.08l-5.88 1.173.254 1.853-9.747 5.413a4 4 0 0 0-1.947 3.48c-.173 4.36-.266 15.52 2.4 25.147 1.467 5.333 5.774 10.267 9.694 13.227l-.587.08a1.933 1.933 0 0 0-1.427 2.2 1.787 1.787 0 0 0 1.627 1.733h.2l6.427-.947c.252-.039.488-.15.68-.32 4.186-1.013 12.72-8.106 14.893-16.066 2.667-9.627 2.533-20.72 2.36-25.04a4 4 0 0 0-1.947-3.48l-14.746-8.24a2.853 2.853 0 0 0-1.334-.36l-.08.067Z" fill="#10B981"/>
      <path d="m27.16 35.653 15.333-8.413a1.56 1.56 0 0 1 1.493 0l15.333 8.413a2.16 2.16 0 0 1 1.027 1.827c.147 3.8.32 15.253-2.373 24.96-2.267 8.24-11.827 15.16-14.667 15.227-2.84.066-12.427-6.92-14.667-15.107-2.666-9.747-2.546-21.333-2.4-25.08a2.16 2.16 0 0 1 .92-1.827Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M39.534 47.52s-1.107-8 3.466-7.934c5.08 0 3.64 9.134 3.64 9.134l-7.106-1.2Z" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="m36.427 46.946 13.187 2.28a1.546 1.546 0 0 1 1.333 1.694V60a1.547 1.547 0 0 1-1.333 1.693l-13.187-2.28a1.52 1.52 0 0 1-1.333-1.68V48.64a1.547 1.547 0 0 1 1.333-1.694Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="m43.387 51.28-.28 6.014m2.879-2.921-5.48-.16m4.774-1.986-4.067 4.133m3.881.12L41.4 52.106" stroke="#fff" strokeWidth=".89" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="m65.333 34.666-5.986 1.014L44 27.24l4.907-1.547a2.88 2.88 0 0 1 2.36.267l14.066 8.36a.173.173 0 1 1 0 .346Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M64.64 51.227a.307.307 0 1 1-.294.293.293.293 0 0 1 .293-.293Zm0 1.507a.306.306 0 0 1 .23.523.307.307 0 0 1-.524-.23.293.293 0 0 1 .293-.293Zm-.747-2.267a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613ZM63.894 52a.293.293 0 0 1 .293.306.293.293 0 0 1-.293.294.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002Zm0 1.48a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm1.506-6.013a.293.293 0 0 1 .293.293.306.306 0 0 1-.523.23.306.306 0 0 1 .23-.523Zm-.76.747a.308.308 0 0 1 .37.3.306.306 0 0 1-.37.3.293.293 0 0 1-.294-.294.294.294 0 0 1 .293-.306Zm0 1.506a.306.306 0 1 1 0 .6.293.293 0 0 1-.294-.294.294.294 0 0 1 .293-.306Zm-.746-2.253a.294.294 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm0 1.506a.293.293 0 0 1 .293.293.308.308 0 0 1-.523.23.307.307 0 0 1 .23-.523Z" fill="#10B981"/>
      <path d="M63.893 50.467a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613Zm1.507-6.014a.293.293 0 0 1 .293.294.306.306 0 0 1-.523.23.306.306 0 0 1 .23-.524Zm0 1.507a.293.293 0 0 1 .293.294.306.306 0 1 1-.293-.294Zm-.76-.76a.307.307 0 0 1 .306.307.306.306 0 0 1-.538.257.308.308 0 0 1-.061-.257.294.294 0 0 1 .293-.307Zm0 1.467a.308.308 0 0 1 .37.3.306.306 0 0 1-.37.3.293.293 0 0 1-.294-.294.294.294 0 0 1 .293-.306Zm-.746-2.214a.294.294 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm0 1.507a.293.293 0 0 1 .293.294.308.308 0 0 1-.523.23.307.307 0 0 1 .23-.524Zm0 1.507a.294.294 0 0 1 .293.293.307.307 0 1 1-.293-.293ZM65.4 41.44a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm0 1.507a.295.295 0 0 1 .274.19c.014.038.02.077.019.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm-.76-.747a.306.306 0 0 1 .23.524.307.307 0 0 1-.524-.23.293.293 0 0 1 .293-.294Zm-.001 1.494a.308.308 0 0 1 .235.518.306.306 0 1 1-.235-.518Zm-.745-2.254a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm0 1.507a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Z" fill="#10B981"/>
      <path d="M65.4 44.453a.293.293 0 0 1 .293.294.306.306 0 0 1-.523.23.306.306 0 0 1 .23-.524Zm-1.506 0a.294.294 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm-1.507 6.014a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613Zm1.506 0a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613ZM62.386 52a.293.293 0 0 1 .293.306.293.293 0 0 1-.293.294.306.306 0 0 1-.135-.598.307.307 0 0 1 .135-.002Zm1.508 0a.293.293 0 0 1 .293.306.293.293 0 0 1-.293.294.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002Zm-.761-.773a.308.308 0 0 1 .23.523.307.307 0 0 1-.523-.23.293.293 0 0 1 .293-.293Zm-1.505 0a.307.307 0 1 1-.294.293.293.293 0 0 1 .294-.293Zm1.505 1.507a.306.306 0 1 1-.293.293.293.293 0 0 1 .293-.293Zm-1.505 0a.306.306 0 0 1 .23.523.307.307 0 0 1-.524-.23.293.293 0 0 1 .294-.293Zm.758.746a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm1.508 0a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm-1.508-6.013a.293.293 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm1.508 0a.294.294 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm-1.508 1.507a.294.294 0 0 1 .28.36.307.307 0 1 1-.293-.294l.013-.066Zm1.508-.001a.293.293 0 0 1 .293.293.308.308 0 0 1-.523.23.307.307 0 0 1 .23-.523Zm-.761-.759a.307.307 0 1 1 0 .6.293.293 0 0 1-.293-.294.294.294 0 0 1 .293-.306Zm-1.505 0a.307.307 0 1 1 0 .6.293.293 0 0 1-.294-.294.292.292 0 0 1 .294-.306Zm1.505 1.506a.306.306 0 0 1 .136.598.307.307 0 0 1-.136.002.293.293 0 0 1-.293-.294.294.294 0 0 1 .293-.306Zm-1.505 0a.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002.293.293 0 0 1-.294-.294.292.292 0 0 1 .294-.306Z" fill="#10B981"/>
      <path d="M62.387 50.467a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613Zm1.506 0a.307.307 0 1 1 0 .613.307.307 0 0 1 0-.613Zm-1.507-6.014a.293.293 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm1.508 0a.294.294 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm-1.508 1.507a.293.293 0 0 1 .293.294.308.308 0 0 1-.523.23.306.306 0 0 1 .23-.524Zm1.508 0a.293.293 0 0 1 .293.294.308.308 0 0 1-.523.23.307.307 0 0 1 .23-.524Zm-.76-.76a.307.307 0 0 1 .307.307.307.307 0 1 1-.6 0 .292.292 0 0 1 .293-.307Zm-1.508 0a.307.307 0 0 1 .307.307.306.306 0 0 1-.598.135.307.307 0 0 1-.002-.135.294.294 0 0 1 .293-.307Zm1.507 1.467a.307.307 0 1 1 0 .6.293.293 0 0 1-.293-.294.294.294 0 0 1 .293-.306Zm-1.505 0a.307.307 0 1 1 0 .6.293.293 0 0 1-.294-.294.293.293 0 0 1 .294-.306Zm.758.8a.293.293 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm1.508 0a.294.294 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm-1.508-6.027a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm1.508 0a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm-1.508 1.507a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm1.508 0a.295.295 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm-.761-.747a.306.306 0 1 1-.293.294.293.293 0 0 1 .293-.294Zm-1.505 0a.306.306 0 0 1 .23.524.307.307 0 0 1-.524-.23.293.293 0 0 1 .294-.294Zm1.505 1.494a.307.307 0 1 1 .026.614.307.307 0 0 1-.026-.614Zm-1.506 0a.308.308 0 0 1 .235.518.306.306 0 1 1-.235-.518Z" fill="#10B981"/>
      <path d="M62.386 44.453a.293.293 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm1.508 0a.294.294 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm.666-6.787a.307.307 0 1 1-.294.294.293.293 0 0 1 .293-.294Zm0 1.507a.307.307 0 1 1-.294.294.294.294 0 0 1 .293-.294Zm.773-.76a.294.294 0 0 1 .293.307.293.293 0 0 1-.293.293.306.306 0 1 1 0-.6Zm-1.519 0a.293.293 0 0 1 .293.307.293.293 0 0 1-.293.293.306.306 0 0 1-.136-.598.306.306 0 0 1 .136-.002Zm1.519 1.507a.292.292 0 0 1 .293.306.293.293 0 0 1-.293.294.308.308 0 0 1-.34-.432.306.306 0 0 1 .34-.168Zm-1.519 0a.291.291 0 0 1 .275.19c.014.037.02.077.018.116a.293.293 0 0 1-.293.294.307.307 0 1 1 0-.6Zm-.761-2.254a.307.307 0 1 1-.293.294.293.293 0 0 1 .293-.294Zm0 1.507a.307.307 0 1 1-.293.294.294.294 0 0 1 .293-.294Zm1.507 1.507a.306.306 0 0 1 .23.523.306.306 0 0 1-.524-.23.293.293 0 0 1 .293-.293Zm-1.507 0a.306.306 0 0 1 .23.523.306.306 0 0 1-.523-.23.293.293 0 0 1 .293-.293Zm2.28-3.773a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.308.308 0 0 1-.34-.432.306.306 0 0 1 .34-.168Zm-1.519 0a.291.291 0 0 1 .275.19c.014.038.02.077.018.117a.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Z" fill="#10B981"/>
      <path d="M64.56 37.666a.307.307 0 1 1-.294.294.293.293 0 0 1 .293-.294Zm-1.507 0a.307.307 0 1 1-.293.294.293.293 0 0 1 .293-.294Zm-1.493 0a.293.293 0 0 1 .293.294.307.307 0 1 1-.293-.294Z" fill="#10B981"/>
      <path d="M63.053 37.666a.307.307 0 1 1-.293.294.293.293 0 0 1 .293-.294Zm-1.493 1.507a.293.293 0 0 1 .293.294.307.307 0 1 1-.293-.294Zm1.493 0a.307.307 0 1 1-.293.294.294.294 0 0 1 .293-.294Zm-.747-.76a.306.306 0 0 1 .257.538.308.308 0 0 1-.257.062.306.306 0 0 1-.136-.598.306.306 0 0 1 .136-.002Zm0 1.507a.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002.307.307 0 1 1 0-.6Zm-.746.76a.293.293 0 0 1 .293.293.307.307 0 1 1-.293-.293Zm1.493 0a.306.306 0 0 1 .23.523.306.306 0 0 1-.523-.23.293.293 0 0 1 .293-.293Zm-.747-3.773a.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002.307.307 0 1 1 0-.6Zm-.746.759a.293.293 0 0 1 .293.294.307.307 0 1 1-.293-.294Z" fill="#10B981"/>
      <path d="M63.053 37.666a.307.307 0 1 1-.293.294.293.293 0 0 1 .293-.294ZM61.56 57.813a.307.307 0 1 1 .293-.293.293.293 0 0 1-.293.293Zm0-1.493a.307.307 0 0 1-.307-.306.306.306 0 0 1 .598-.136c.01.045.011.091.002.136a.307.307 0 0 1-.294.306Zm.746.747a.306.306 0 0 1-.136-.599.307.307 0 0 1 .136-.001.307.307 0 1 1 0 .6Zm0-1.507a.307.307 0 1 1 0-.6.306.306 0 0 1 .257.538.308.308 0 0 1-.257.062Zm.747 2.253a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm.001-1.493a.307.307 0 0 1-.293-.306.306.306 0 0 1 .598-.136c.01.045.011.091.002.136a.307.307 0 0 1-.307.306Zm-1.494-1.506a.307.307 0 0 1-.307-.307.306.306 0 0 1 .598-.135c.01.044.011.09.002.135a.294.294 0 0 1-.294.307Zm1.494 0a.293.293 0 0 1-.293-.307.306.306 0 0 1 .598-.135c.01.044.011.09.002.135a.307.307 0 0 1-.307.307Zm-1.494 6.013a.306.306 0 0 1-.315-.363.307.307 0 0 1 .608.07.293.293 0 0 1-.293.293Zm0-1.507a.307.307 0 1 1 .293-.293.293.293 0 0 1-.293.293Zm.746.76a.307.307 0 1 1 0-.6.307.307 0 1 1 0 .6Zm0-1.506a.307.307 0 1 1 0-.6.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002Zm.747 2.253a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm0-1.507a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Z" fill="#10B981"/>
      <path d="M61.56 57.813a.307.307 0 1 1 .293-.293.293.293 0 0 1-.293.293Zm1.493 0a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293ZM61.56 63.84a.307.307 0 1 1 0-.6.293.293 0 0 1 .293.293.294.294 0 0 1-.293.307Zm0-1.506a.307.307 0 1 1 .293-.294.293.293 0 0 1-.293.294Zm.747.76a.307.307 0 1 1 .306-.307.32.32 0 0 1-.306.307Zm0-1.507a.306.306 0 0 1-.307-.253.307.307 0 0 1 .307-.307.32.32 0 0 1 .36.307.308.308 0 0 1-.36.253Zm.746.747a.293.293 0 0 1-.293-.294.307.307 0 1 1 .293.294Zm-1.493-1.507a.306.306 0 0 1-.315-.363.307.307 0 0 1 .608.07.293.293 0 0 1-.293.293Zm1.493 0a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Z" fill="#10B981"/>
      <path d="M61.56 63.84a.307.307 0 1 1 0-.6.293.293 0 0 1 .293.293.294.294 0 0 1-.293.307Zm1.493-6.027a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm.001-1.493a.307.307 0 0 1-.293-.306.306.306 0 0 1 .598-.136c.01.045.011.091.002.136a.307.307 0 0 1-.307.306Zm.76.747a.306.306 0 0 1-.136-.599.307.307 0 0 1 .136-.001.293.293 0 0 1 .293.293.294.294 0 0 1-.293.307Zm0-1.507a.307.307 0 1 1 .293-.293.293.293 0 0 1-.293.293Zm.746-.746a.294.294 0 0 1-.294-.307.307.307 0 1 1 .6 0 .306.306 0 0 1-.306.307Zm-1.506 0a.293.293 0 0 1-.293-.307.306.306 0 0 1 .598-.135c.01.044.011.09.002.135a.307.307 0 0 1-.307.307Zm-.001 6.013a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm0-1.507a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm.761-.746a.307.307 0 1 1 0-.6.293.293 0 0 1 .293.293.294.294 0 0 1-.293.306Z" fill="#10B981"/>
      <path d="M63.053 57.813a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm0 3.014a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293ZM61.547 36.76a.295.295 0 0 1-.274-.19.294.294 0 0 1-.019-.117.293.293 0 0 1 .294-.293.306.306 0 1 1 0 .6Zm1.506 0a.295.295 0 0 1-.274-.19.293.293 0 0 1-.019-.117.293.293 0 0 1 .293-.293.306.306 0 0 1 .257.538.308.308 0 0 1-.257.062Zm1.507 0a.296.296 0 0 1-.276-.19.294.294 0 0 1-.018-.117.293.293 0 0 1 .293-.293.306.306 0 0 1 .257.538.308.308 0 0 1-.257.062ZM59.893 63.2a.306.306 0 0 1 .23.524.307.307 0 0 1-.523-.23.293.293 0 0 1 .293-.294Zm0 1.506A.307.307 0 1 1 59.6 65a.293.293 0 0 1 .293-.294Zm.761-.706a.294.294 0 0 1 .293.306.293.293 0 0 1-.294.294.306.306 0 0 1-.135-.598.307.307 0 0 1 .136-.002Zm-1.506 0a.293.293 0 0 1 .293.306.293.293 0 0 1-.293.294.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002Zm1.506 1.453a.292.292 0 0 1 .293.307.293.293 0 0 1-.294.293.307.307 0 1 1 0-.6Zm-1.506 0a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Zm-.761-.747a.308.308 0 0 1 .23.524.307.307 0 0 1-.523-.23.293.293 0 0 1 .293-.294Zm1.506 1.494a.306.306 0 1 1 .027.612.306.306 0 0 1-.027-.611Zm-1.506 0a.306.306 0 0 1 .235.519.307.307 0 1 1-.235-.518Zm1.506-6.013a.307.307 0 1 1-.293.293.293.293 0 0 1 .293-.293Zm0 1.507a.307.307 0 1 1-.293.293.293.293 0 0 1 .293-.293Zm.761-.761a.294.294 0 0 1 .293.307.293.293 0 0 1-.294.293.306.306 0 0 1-.135-.598.307.307 0 0 1 .136-.002Zm0 1.507a.292.292 0 0 1 .293.307.293.293 0 0 1-.294.293.307.307 0 1 1 0-.6Zm-1.506 0a.292.292 0 0 1 .293.307.293.293 0 0 1-.293.293.307.307 0 1 1 0-.6Z" fill="#10B981"/>
      <path d="M59.893 63.2a.306.306 0 0 1 .23.524.307.307 0 0 1-.523-.23.293.293 0 0 1 .293-.294Zm0-6.026a.306.306 0 1 1 0 .6.293.293 0 0 1-.293-.294.294.294 0 0 1 .293-.306Zm0 1.493a.308.308 0 0 1 .37.3.306.306 0 0 1-.37.3.293.293 0 0 1-.293-.294.294.294 0 0 1 .293-.306Zm.761-.734a.293.293 0 0 1 .293.293.306.306 0 0 1-.523.23.306.306 0 0 1 .23-.523Zm-.002 1.494a.306.306 0 1 1 0 .613.306.306 0 0 1 0-.613Zm-.759.76a.307.307 0 1 1-.293.293.293.293 0 0 1 .293-.293Zm.761-5.267a.293.293 0 0 1 .293.294.306.306 0 0 1-.523.23.306.306 0 0 1 .23-.524Zm0 1.507a.293.293 0 0 1 .293.293.306.306 0 1 1-.293-.293Zm-2.267 8.279a.308.308 0 0 1 .23.524.307.307 0 0 1-.523-.23.293.293 0 0 1 .293-.294Zm-.747.747a.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002.307.307 0 1 1 0-.6Zm.747.747a.306.306 0 0 1 .235.519.307.307 0 1 1-.235-.518ZM56 70.547a.306.306 0 1 1 0-.613.306.306 0 0 1 0 .613Zm0-2.107a.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002m.814.747a.307.307 0 1 1 .293-.294.294.294 0 0 1-.293.294Zm0-1.507a.306.306 0 1 1 .293-.293.293.293 0 0 1-.293.293Zm.746.76a.307.307 0 1 1 0-.6.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002Zm0-1.507a.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002.307.307 0 1 1 0 .6Z" fill="#10B981"/>
      <path d="M56 70.547a.306.306 0 1 1 0-.613.306.306 0 0 1 0 .613Zm1.56-1.507a.307.307 0 1 1 0-.6.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002Zm.747-.76a.293.293 0 0 1-.293-.293.307.307 0 1 1 .293.293Zm.761-.747a.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002.307.307 0 1 1 0 .6Zm-1.508 0a.306.306 0 0 1-.136-.598.307.307 0 0 1 .136-.002.307.307 0 1 1 0 .6Zm-5.28 7.213a.292.292 0 0 1-.293-.306.294.294 0 0 1 .293-.294.306.306 0 0 1 .257.539.308.308 0 0 1-.257.061Z" fill="#10B981"/>
      <path d="M52.28 74.746a.292.292 0 0 1-.293-.306.294.294 0 0 1 .293-.294.306.306 0 0 1 .257.539.308.308 0 0 1-.257.061Zm3.014-3.012a.306.306 0 0 1-.135-.599.306.306 0 0 1 .135-.001.306.306 0 0 1 .257.538.307.307 0 0 1-.257.062Zm0-1.507a.307.307 0 1 1 0-.6.306.306 0 0 1 .135.598.307.307 0 0 1-.135.002Z" fill="#10B981"/>
      <path d="M52.28 74.746a.292.292 0 0 1-.293-.306.294.294 0 0 1 .293-.294.306.306 0 0 1 .257.539.308.308 0 0 1-.257.061Zm1.506-1.506a.294.294 0 0 1-.294-.306.293.293 0 0 1 .294-.293.306.306 0 0 1 .135.598.307.307 0 0 1-.135.001Zm-.746.747a.307.307 0 1 1 .294-.293.293.293 0 0 1-.294.293Zm1.507-1.494a.307.307 0 1 1-.027-.613.307.307 0 0 1 .027.613Zm.747-.759a.306.306 0 0 1-.135-.599.306.306 0 0 1 .135-.001.306.306 0 0 1 .257.538.307.307 0 0 1-.257.062Z" fill="#10B981"/>
      <path d="M52.28 74.746a.292.292 0 0 1-.293-.306.294.294 0 0 1 .293-.294.306.306 0 0 1 .257.539.308.308 0 0 1-.257.061Zm3.014-3.012a.306.306 0 0 1-.135-.599.306.306 0 0 1 .135-.001.306.306 0 0 1 .257.538.307.307 0 0 1-.257.062Z" fill="#10B981"/>
      <path d="M55.294 71.734a.306.306 0 0 1-.135-.599.306.306 0 0 1 .135-.001.306.306 0 0 1 .257.538.307.307 0 0 1-.257.062Z" fill="#10B981"/>
    </svg>

  )
}
