import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from '../src/Components/BackendCall';
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import PaymentMethodsList from '../src/Components/PaymentMethodsList';
import BankCardIcon from "../src/Components/icon/BankCardIcon";
import { CreditCard } from '@shobbak/react-services/dist/Entities';
import { toast } from "react-toastify";
import AddPaymentMethodForm from "../src/Components/AddPaymentMethodForm";
import SeparatedModal from "../src/Components/SeparatedModal";
import { Env } from "../src/Services/AppContext";
import Divider from "../src/Components/Divider";

interface Props {
	token: string;
	locale: string;
	env: Env;
  }

function MyPaymentMethods({locale, token, env}: Props) {
  BackendCall.i({ token, locale })
	const [isNewCardModalVisible, setIsNewCardModalVisible] = React.useState<boolean>(false);
	const [cards, setCards] = React.useState<CreditCard[]>([]);
  	const [isFetchingCards, setIsFetchingCards] = React.useState<boolean>(false);

	React.useEffect(() => {
    async function fetchCards() {
      setIsFetchingCards(true);
      try {
        const cards = await BackendCall.i().getCreditCards();
        setIsFetchingCards(false);
        setCards(cards);
      } catch (error) {
        setIsFetchingCards(false);
      }
    }
    fetchCards();
  }, []);

	return (
    	<Suspense fallback={Loader()}>
			<ProfileLayout>
				<>
					{!cards && (
						<ProfileCard>
							<div className="bg-white px-20">
								<div className="flex items-center justify-center mb-2">
									<div className="w-14 h-14 bg-emerald-50 rounded-2xl flex items-center justify-center">
										<BankCardIcon className="fill-current w-9 h-9 text-emerald-500" />
									</div>
								</div>
								<p className="font-semibold text-2xl text-coolGray-700 text-center mb-1">{Translation.t('texts.you_dont_have_any_saved_cards_title')}</p>
								<p className="text-center text-coolGray-500 px-4 mb-6">
									{Translation.t('texts.you_dont_have_any_saved_cards_description')}
								</p>
								<div className="grid grid-cols-3 mb-6">
									<div className="flex flex-col items-center space-y-2">
										<div className="w-9 h-9 bg-emerald-50 rounded-xl flex items-center justify-center">
											<i className="ri-money-dollar-circle-fill ri-lg text-emerald-500" />
										</div>
										<p className="text-center font-semibold text-coolGray-700">
											{Translation.t('texts.add_card')}
										</p>
									</div>
									<div className="flex flex-col items-center space-y-2">
										<div className="w-9 h-9 bg-emerald-50 rounded-xl flex items-center justify-center">
											<i className="ri-thumb-up-fill ri-lg text-emerald-500" />
										</div>
										<p className="text-center font-semibold text-coolGray-700">
											{Translation.t('texts.sell_and_buy')}
										</p>
									</div>
									<div className="flex flex-col items-center space-y-2">
										<div className="w-9 h-9 bg-emerald-50 rounded-xl flex items-center justify-center">
											<i className="ri-shield-check-fill ri-lg text-emerald-500" />
										</div>
										<p className="text-center font-semibold text-coolGray-700">
											{Translation.t('texts.be_safe')}
										</p>
									</div>
								</div>
								<div className="flex items-center justify-center">
									<button
										onClick={() => setIsNewCardModalVisible(true)}
										className="text-white bg-emerald-500 px-8 py-4 rounded-md"
									>
										{Translation.t("texts.add_credit_card")}
									</button>
								</div>
							</div>
						</ProfileCard>
					)}

					<ProfileCard title={cards ? Translation.t('texts.payment_method') : ''}>
						{cards && (
							<>
								<div className="border border-dashed border-emerald-500 rounded-lg p-4 flex items-center gap-4 w-full">
									<div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
										<BankCardIcon className="fill-current w-5 h-5 text-emerald-500" />
									</div>
									<div className="flex flex-col gap-1">
										<p
											className="font-semibold text-emerald-500 cursor-pointer"
											onClick={() => {
												setIsNewCardModalVisible(true)
											}}>
											{Translation.t("texts.add_new_payment_method_title")}
										</p>
										<p className="text-xs leading-none text-coolGray-500">{Translation.t("texts.add_new_payment_method_description")}</p>
									</div>
								</div>

								<Divider label={Translation.t("texts.or_choose_from_list")} darker containerStyle="my-6" />
							</>
						)}

						<p className="font-semibold text-coolGray-700 mb-2">
							{Translation.t('texts.payment_method')}
						</p>

						{isFetchingCards ? (
							<Loader />
						) : (
							<PaymentMethodsList
								cards={cards}
								isFetchingCards={isFetchingCards}
								onDelete={async (selectedCardId) => {
									try {
										setIsFetchingCards(true);
										await BackendCall.i().deleteCreditCard(selectedCardId);
										const cards = await BackendCall.i().getCreditCards();
										setCards(cards);
										toast.success(Translation.t('texts.card_delete_success'));
										setIsFetchingCards(false);
									} catch (error) {
										toast.error(error.message);
										setIsFetchingCards(false);
									}
								}}
								showWebPay={false}
								hideRadio
							/>
						)}
					</ProfileCard>


					{isNewCardModalVisible && (
						<SeparatedModal visible={isNewCardModalVisible}
							onClose={()=> setIsNewCardModalVisible(false)}
							headerLabel={"Add New Card"}
							closeBtn
							body={<AddPaymentMethodForm
								onCancel={() => setIsNewCardModalVisible(false)}
								onSuccess={async (card) => {
									setIsNewCardModalVisible(false);
									try {
										setIsFetchingCards(true);
										await BackendCall.i().tokenizeCreditCard({
											token: card.token,
											name: card.name,
										});
										const cards = await BackendCall.i().getCreditCards();
										setCards(cards);
										toast.success(Translation.t('texts.card_add_success'));
										setIsFetchingCards(false);
									} catch (error) {
										toast.error(error.message);
										setIsFetchingCards(false);
									}
								}}
								publicKey={env?.CHECKOUT_PUBLIC_KEY}
								customRender={(renderForm, renderButton) => {
									return (
										<SeparatedModal
											visible={isNewCardModalVisible}
											body={renderForm()}
											footer={renderButton()}
											closeBtn
											onClose={() => setIsNewCardModalVisible(false)}
										/>
									);
								}}
							/>}
						/>
					)}
				</>
      </ProfileLayout>
    </Suspense>
	);
}

export default MyPaymentMethods;