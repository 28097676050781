import React from 'react'
import { GroupedTransaction, WalletTransaction } from "@shobbak/react-services/dist/Entities";
import Translation from '../../src/helper/Translation';
import DateTime from '../../src/helper/DateTime';

interface TransactionsProps {
  transactionGroups: GroupedTransaction[];
}

export default function Transactions({ transactionGroups }: TransactionsProps) {
  if (transactionGroups.length == 0) {
    return (
      <div className="p-2 flex flex-col items-center justify-center w-full">
        <h3 className="text-lg leading-8 text-emerald-500">{Translation.t('wallet.messages.no_transactions_title')}</h3>
        <h4 className="text-sm leading-8 text-coolGray-500">{Translation.t('wallet.messages.no_transactions_description')}</h4>
      </div>
    )
  }

  return (
    <ul>
      {transactionGroups.map((group: GroupedTransaction) => {
        return (
          <li key={group.id} className="p-2 border border-gray-200 rounded my-2">
            <ul>
              {group.transactions.map((transaction: WalletTransaction, index) => {
                const positiveAmount = transaction.amountDetail.value > 0
                const lastIndex = group.transactions.length - 1 == index
                return (
                  <li key={transaction.id} className={`text-sm ${lastIndex ? '' : 'border-b-2 border-coolGray-100'} ${group.transactions.length > 1 ? 'py-2' : ''}`}>
                    <a href={group.transactionFor?.webUrl} className="flex justify-between items-center">
                      <span className="flex flex-col w-1/3">
                        <span className="text-coolGray-700 font-medium">{group.transactionFor?.title}</span>
                        <span className="text-coolGray-500">{new DateTime(transaction.createdAt).short()}</span>
                      </span>
                      <span className="w-1/3 flex justify-center">
                        <span className="text-coolGray-500">{transaction.transactionTypeName}</span>
                      </span>
                      <span className="w-1/3 flex justify-end">
                        <span className={`font-medium ${positiveAmount ? 'text-emerald-500' : 'text-red-500'}`}>
                          {`${positiveAmount ? '+' : ''}${transaction.amountDetail.formatted}`}
                        </span>
                      </span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )
}
