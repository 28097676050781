import React from 'react'

export default function NotificationIcon({ count = 0 }) {
  return (
    <div className="w-10 h-10 rounded-full flex items-center justify-center bg-emerald-50">
      <i className="ri-notification-3-fill ri-xl text-emerald-500"></i>
      {count > 0 && (
        <span className="absolute -top-0.5 -right-0.5 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white p-1" style={{ fontSize: count > 99 ? 8 : 12 }}>
          {count > 99 ? '99+' : count}
        </span>
      )}
    </div>
  )
}
