// Recursively convert an object fields from snake case to camelCase
// https://stackoverflow.com/a/59771233

import transform from 'lodash/transform'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import snakeCase from 'lodash/snakeCase'
import camelCase from 'lodash/camelCase'

export function transformKeysToSnakeCase(object) {
  const convertToSnake = obj => transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key);
    acc[camelKey] = isObject(value) ? convertToSnake(value) : value;
  });

  return convertToSnake(object);
}

export function transformKeysToCamelCase(object) {
  const convertToCamel = obj => transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? convertToCamel(value) : value;
  });

  return convertToCamel(object);
}