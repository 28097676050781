import React from 'react';
import DateTime from '../../helper/DateTime';

type CompProps = {
  bidder: any;
  index: number;
};

export default function BidderRaw({bidder, index}: CompProps) {
  const [bidTime, setBidTime] = React.useState('');
  
  React.useEffect(() => {
    const stopWatch = setInterval(() => {
      let humanizedTime = (new DateTime(bidder.createdAt)).fromNow();
      setBidTime(humanizedTime);
    }, 1000);
    return () => clearInterval(stopWatch);
  }, [bidder.createdAt]);

  return (
    <div className="flex items-center space-s-2">
      <span>
        {index + 1}
      </span>
      <span
        className={`${index === 0 ? 'text-emerald-500' : 'text-coolGray-700'}`}
      >
        {bidder.biddingAmount.humanized}
      </span>
      <span className="flex-1">
        {bidder.maskedName}
      </span>
      <span>
        {bidTime}
      </span>
    </div>
  );
}