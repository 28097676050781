import React from 'react';

import Message from './Message';

export const renderOfferMessages = (
  messages,
  place:
    | 'under_title'
    | 'above_delivery'
    | 'under_delivery'
    | 'above_payment_breakdown'
    | 'above_payment'
    | 'under_payment',
) => {
  if ((messages && messages.length === 0) || messages === undefined) return;

  return messages
    .filter((entry) => entry.place === place)
    .map((message, index) => (
      <div key={index} className="px-4 mt-2">
        <Message message={message} />
      </div>
    ));
};
