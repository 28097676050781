import React from 'react';
import 'remixicon/fonts/remixicon.css'

export type ButtonType = 'solid' | 'outline' | 'clear';
export type ButtonSize = 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall';

type ButtonProps = {
  type: ButtonType;
  size: ButtonSize;
  iconName?: string;
  disabled?: boolean;
  circle?: boolean;
  raised?: boolean;
  badge?: boolean;
  color?: string;
  iconColor?: string;
  onPress?: () => void;
  containerStyle?: string;
};

const sizesStyles = {
  xLarge: 'p-2',
  large: 'p-3',
  medium: 'p-2',
  small: 'p-3',
  xSmall: 'p-1',
};

export default function IconButton(props: ButtonProps) {
  const color = props.color || 'emerald-500';

  const typesStyles = () =>
    ({
      solid: `bg-${color}`,
      outline: `bg-transparent border border-${color}`,
      clear: 'bg-transparent',
      disabled: props.type === 'clear' ? 'bg-transparent' : 'bg-coolGray-300',
    });

  let type;
  const buttonSize: ButtonSize = props.size || 'medium';
  switch (props.type) {
    case 'solid':
      type = 'solid';
      break;
    case 'outline':
      type = 'outline';
      break;
    case 'clear':
      type = 'clear';
      break;

    default:
      type = 'solid';
      break;
  }

  const getContentColor = () => {
    if (type === 'solid') {
      return 'text-white'
    } else {
      return `text-${color}`
    }
  };
  return (
    <div>
      <button
        onClick={() => !props.disabled && props.onPress?.()}
        className={`flex items-center justify-center overflow-hidden self-baseline rounded-md ${sizesStyles[buttonSize]} ${props.containerStyle} ${props.circle ? 'rounded-full' : ''} ${props.disabled ? typesStyles().disabled : ''} ${typesStyles()[type]}`}
      >
        <div>
          <i className={`flex-1 ${['small', 'xSmall'].includes(buttonSize) ? '' : buttonSize === 'xLarge' ? 'ri-3x' : 'ri-2x'} ${props.disabled ? 'text-coolGray-400' : props.iconColor ? props.iconColor : getContentColor()} ${props.iconName}`}  />
          {props.badge && (
            <div
              className="absolute w-2 h-2 rounded-full bg-red-500 right-0"
            >
            </div>
          )}
        </div>
      </button>
    </div>
  );
}
