// app/javascript/channels/chat_room_channel.js
import consumer from './consumer'

const UserChannel = consumer.subscriptions.create({ channel: 'UserChannel', id: window.userId }, {
  connected () {
    // Called when the subscription is ready for use on the server
  },

  disconnected () {
    // Called when the subscription has been terminated by the server
  },

  received (data) {
    let { event_name, payload } = data
    let event = new CustomEvent(event_name, {
      detail: payload,
    })
    window.dispatchEvent(event)
  },

  speak (message) {
  }
})

export default UserChannel