import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from "../../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../../src/Components/profile/ProfileCard"))
const Tabs = lazy(() => import("../../src/Components/profile/Tabs"))
import Transactions from "./Transactions";
import WithdrawRequests from "./WithdrawRequests";
import Loader from "../../src/Components/icon/Loader";

import { TabItem } from "../../src/Components/profile/Tabs";
import Translation from '../../src/helper/Translation';
import "remixicon/fonts/remixicon.css";
import { GroupedTransaction, Wallet, WithdrawRequest } from "@shobbak/react-services/dist/Entities";

type WalletActionType = 'wallet_transaction' | 'withdraw_request';

export default ({ locale, token, isVerified, shouldVerifyWithAbsher }) => {
  BackendCall.i({ token, locale })

  const filters = [
    { id: '1', name: Translation.t('wallet.types.transactions'), key: 'wallet_transaction' as WalletActionType, foregroundColor: '#0369A1', backgroundColor: '#F0F9FF' },
    { id: '2', name: Translation.t('wallet.types.withdraw_requests'), key: 'withdraw_request' as WalletActionType, foregroundColor: '#0369A1', backgroundColor: '#F0F9FF' },
  ]
  const [wallet, setWallet] = useState<Wallet>()
  const [transactionGroups, setTransactionsGroups] = useState<GroupedTransaction[]>([])
  const [withdrawRequests, setWithdrawRequests] = useState<WithdrawRequest[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [activeTap, setActiveTap] = useState<WalletActionType>(filters[0].key)

  useEffect(() => {
    getWallet()
  }, [])

  useEffect(() => {
    if (activeTap == 'wallet_transaction') {
      getTransactions({ page: currentPage })
    } else {
      getWithdrawRequests({ page: currentPage })
    }
  }, [currentPage, activeTap])

  function getWallet() {
    BackendCall.i()
      .getWallet()
      .then(response => setWallet(response))
      .catch(err => { })
  }

  function getTransactions({ page }) {
    setIsLoading(true)
    BackendCall.i()
      .getWalletGroupedTransactions({ page: { number: page, size: 15 } })
      .then(response => {
        setTransactionsGroups(response)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  function getWithdrawRequests({ page }) {
    setIsLoading(true)
    BackendCall.i()
      .getWalletWithdrawRequests({ page: { number: page, size: 15 } })
      .then(response => {
        setWithdrawRequests(response)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  function renderCurrentTab() {
    if (isLoading == true) return
    if (activeTap == 'wallet_transaction') {
      return (
        <div className="mt-3">
          <Transactions transactionGroups={transactionGroups} />
        </div>
      )
    } else {
      return (
        <div className="mt-3">
          <WithdrawRequests requests={withdrawRequests} token={token} locale={locale} isVerified={isVerified} shouldVerifyWithAbsher={shouldVerifyWithAbsher} />
        </div>
      )
    }
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('breadcrumb.my_wallet')} subtitle={wallet?.id ? `${Translation.t('wallet.labels.balance')}: ${wallet?.balanceDetail?.humanized}` : ''}>
          <div className="flex flex-col">
            <Tabs
              items={filters}
              keyBy="key"
              titleBy="name"
              onChange={(item: TabItem) => {
                if (item.key != activeTap) {
                  setCurrentPage(1)
                  setActiveTap(item.key)
                }
              }}
            />
            <hr />
            {isLoading && <Loader />}
            {renderCurrentTab()}
          </div>
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  );
}
