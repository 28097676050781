import React, { useEffect, useRef, useState } from "react"
import AdLocation from "../src/Components/Posting/Steps/AdLocationStep";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import map from 'lodash/map'
import BackendCall from "../src/Components/BackendCall";
import { toast } from "react-toastify";
import Translation from "../src/helper/Translation";
import isEmpty from 'lodash/isEmpty'
import EventDispatcher from "../src/helper/EventDispatcher";
import DefaultButton, { ButtonElement } from "../src/Components/EditProfile/DefaultButton";
import BasicInfoStep from "../src/Components/Posting/Steps/BasicInfoStep";
import { Posting } from "@shobbak/react-services/dist/Entities";
import Loader from "../src/Components/icon/Loader";
import { object } from "prop-types";




const EditPostingSpecificationModal = ({postingId ,verticalType ,categoryId ,listingTypeId ,locale,token})=> {

  const [state , setState] = useState({
    vertical_type: verticalType,
    category_id: categoryId,
    listing_type_id: listingTypeId
  })
  const [showDescriptionModal , setCanShowDescriptionModal] = useState<boolean>(false)
  const [isLoading , setIsLoading] = useState<boolean>(false)


  const updatePosting = ()=> {
   BackendCall.i({token , locale})
    .postingUpdate(postingId , {  ...state })
    .then(res=> {
      toast.success(Translation.t('texts.ads_updated'));
      setTimeout(()=> window.location.reload() , 100)
    })
  }

  useEffect(()=> {
    EventDispatcher.on('edit-specifications', ()=> {
      setCanShowDescriptionModal(true)
      setIsLoading(true)
      BackendCall
       .i({locale , token})
      .getPosting(postingId)
        .then(res=> {
          let newState = state
          Object.keys(res.dataFieldValues).map((key)=> {
            newState[key] = res.dataFieldValues[key].id ? res.dataFieldValues[key].id : res.dataFieldValues[key].value
          })
          setState(newState)
          setIsLoading(false)

        })

    })
  } , [])

  const footer = ()=> {
    return (
      <div className="bg-coolGray-100">
        <div className="flex w-full px-4 md:px-6 md:py-4 py-4  gap-4 justify-end">
          <ButtonElement label={Translation.t('buttons.cancel')}  className={`text-coolGray-500 border-coolGray-300`} onButtonClick={()=> setCanShowDescriptionModal(false)} />
          <DefaultButton disabled={false} onButtonClick={updatePosting} label={Translation.t('buttons.save')} />
        </div>
      </div>
    );
  }

  const header = ()=> {
    return (
      <div className={`bg-coolGray-100`}>
        <div className={`flex items-center justify-between  p-4`}>
          <span className={`text-base leading-6 font-semibold text-coolGray-900`}>{Translation.t('screens.edit_post.title')}</span>
          <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`}  onClick={()=> setCanShowDescriptionModal(false) }/>
        </div>
      </div>
    );
  }


   if (!showDescriptionModal) return (<></>);
   return (
     <BaseModal onClose={ ()=> setCanShowDescriptionModal(false) }>
       {isLoading ? <Loader /> : (
         <>
           {header()}
           <div className={`max-h-120 px-4 overflow-y-auto slider-scroll-style`}>
             <BasicInfoStep moveToNextStep={()=> {}}
                            setCustomFieldsCount={(count) => {}} state={state}
                            dispatch={({payload})=> {
                              setState((prev)=> {
                                return {...prev , [payload['key']]: payload['value']}
                              })
                            }} />
           </div>
           {footer()}
         </>
       )}
     </BaseModal>
   );

}



export default EditPostingSpecificationModal