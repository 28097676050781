import { GiftCard } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import 'remixicon/fonts/remixicon.css';

type OfferGiftCardProps = {
  gift: GiftCard;
  onPress: () => void;
  selected?: boolean;
};

export default function OfferGiftCard({
  gift,
  onPress,
  selected,
}: OfferGiftCardProps) {
  return (
    <div className="flex space-x-4 bg-coolGray-50 p-4 border border-dashed rounded-lg items-center">
      <div>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.49996 0.5H14V3.83333H0.666626V1.33333C0.666626 0.873096 1.03972 0.5 1.49996 0.5ZM0.666626 5.5H16.5C16.9602 5.5 17.3333 5.8731 17.3333 6.33333V14.6667C17.3333 15.1269 16.9602 15.5 16.5 15.5H1.49996C1.03972 15.5 0.666626 15.1269 0.666626 14.6667V5.5ZM11.5 11.3333V9.66667H14V11.3333H11.5Z" fill="#374151" />
        </svg>
      </div>
      <div className="flex-1 flex-col">
        <p className="font-normal text-emerald-500">{gift.amountDetail.humanized}</p>
        <p className="font-normal text-coolGray-700 text-sm">{gift.shobbakCard.name}</p>
      </div>
      <div>
        <input
          type="checkbox"
          checked={selected}
          onChange={onPress}
          className={`rounded-sm focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300 cursor-pointer`} />
      </div>
    </div>
  );
}
