import React, { FC, useContext, useEffect, useState } from "react";
import Translation from '../../helper/Translation'
import last from 'lodash/last'
import map from 'lodash/map'

const StepsProgress = ({ steps,onClickToActiveStep = (step)=> {}, currentStep, isLastStepComplete, title = '', theme = 'emerald-500' }) => {
	const [activeOrCurrentSteps, setActiveOrCurrentSteps] = useState([])
	const [incomingSteps, setIncomingSteps] = useState([])

	useEffect(() => {
		setActiveOrCurrentSteps(() => steps.filter((item) => (item.id == currentStep.id || item.id < currentStep.id)))
		setIncomingSteps(() => steps.filter((item) => item.id > currentStep.id))
	}, [steps, currentStep])
	return (
		<div className="border border-gray-100 md:border-gray-300 md:w-1/4 w-full px-4 py-2  md:p-6  md:rounded-md md:bg-white flex-row">
			<h2 className="text-lg leading-6 text-coolGray-700 text-normal mb-3 hidden md:block">
				{title == '' ? Translation.t('create_new_ad.title') : title}
			</h2>
			<nav aria-label="Progress">
				<ol role="list" className="overflow-hidden flex justify-between md:flex-col  space-s-1 md:space-s-0">
					{
						<div className="flex md:flex-col space-s-2 md:space-s-0">
							{activeOrCurrentSteps.map((item, index) => {
								if (item.id < currentStep.id || isLastStepComplete) {
									return <ActiveStep onClickToActiveStep={()=> onClickToActiveStep(item)} theme={theme} key={item.id} id={item.id} title={item.title} isLast={item.id == last(map(steps, 'id')) && isLastStepComplete} />
								} else if (item.id == currentStep.id) {
									return <CurrentStatus theme={theme} key={item.id} id={item.id} title={item.title} isLast={item.id == last(map(steps, 'id'))} />
								}
							})}
						</div>
					}

					{
						<div className="flex md:flex-col md:flex-none space-s-2 md:space-s-0">
							{incomingSteps.map((item, index) => {
								return <IncomingStep key={item.id} id={item.id} title={item.title} isLast={item.id == last(map(steps, 'id'))} />
							})}
						</div>
					}

				</ol>
			</nav>
		</div>
	);
}


const IncomingStep = ({ id, title, isLast }) => {
	return (<div className="flex md:block space-s-1 md:space-s-0" key={id}>
		<li className={`relative ${isLast ? '' : 'md:pb-6'}`}>
			<div
				className={`-ms-px absolute mt-0.5 top-3 start-3 w-0.5 h-full bg-gray-200 ${isLast ? 'hidden' : ''} md:block hidden`} />
			<span className="flex items-center space-s-4" aria-hidden="true">
				<span
					className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-gray-200  rounded-full">
					<span className="h-2.5 w-2.5 bg-white rounded-full" />
				</span>
				<span className="text-base leading-6 text-normal  text-coolGray-500  hidden md:block truncate">{title}</span>
			</span>
		</li>
	</div>)
}

const ActiveStep = ({ id, title, isLast, onClickToActiveStep,theme = 'emerald-500' }) => {
	return (
		<li className={`relative cursor-pointer ${isLast ? '' : 'md:pb-6'}`} onClick={onClickToActiveStep}>
			<div
				className={`-ms-px absolute mt-0.5 top-3 start-3 w-0.5 h-full bg-${theme} ${isLast ? 'hidden' : 'md:block'}  hidden`}
			/>
			<span className="inline-flex items-center md:space-s-4 space-s-2">
				<span className="flex items-center">
					<span
						className={`relative z-10 w-6 h-6 flex items-center justify-center bg-${theme} rounded-full group-hover:bg-${theme}`}>
						<svg className="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
							fill="currentColor" aria-hidden="true">
							<path fillRule="evenodd"
								d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
								clipRule="evenodd" />
						</svg>
					</span>
				</span>
				<span className="text-base leading-6 text-normal  text-coolGray-500 hidden md:block truncate">{title}</span>
			</span>
		</li>
	);

}

const CurrentStatus = ({ id, title, isLast, theme = 'emerald-500' }) => {
	return (
		<li className={`relative ${isLast ? '' : 'md:pb-6'}`}>
			<div
				className={`-ms-px absolute mt-0.5 top-3 start-3 w-0.5 h-full bg-gray-200 ${isLast ? 'hidden' : 'md:block'}  hidden`}
				aria-hidden="true" />
			<span className="flex items-center md:space-s-4 space-s-2" aria-hidden="true">
				<span
					className={`relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-${theme} rounded-full`}>
					<span className={`h-2.5 w-2.5 bg-${theme} rounded-full`} />
				</span>
				<span className="text-base leading-6 text-normal  text-coolGray-500 truncate">{title}</span>
			</span>
		</li>)

}

export default StepsProgress;


