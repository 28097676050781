import React, { useEffect, useRef, useState } from "react"
import AdLocation from "../src/Components/Posting/Steps/AdLocationStep";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import map from 'lodash/map'
import BackendCall, { HttpUnprocessableEntity } from "../src/Components/BackendCall";
import { toast } from "react-toastify";
import Translation from "../src/helper/Translation";
import isEmpty from 'lodash/isEmpty'
import DefaultButton, { ButtonElement } from "../src/Components/EditProfile/DefaultButton";
import { PostingDeliveryOption } from "@shobbak/react-services/dist/Entities";




const EditDeliveryOptionsModal = ({posting ,authUser ,locale,token,  onClose, googleMapsApiKey})=> {
  const canSubmitRequest = useRef<boolean>(true)
  const [state , setState] = useState({
    latlon: { lat: posting.lat , lon: posting.lon  } ,
    vertical_type: posting.verticalType ,
    category_id: posting.category.id , category: posting.category  ,
    delivery_options: map(posting.deliveryOptions ,'id'),
    auth_user: authUser,
    delivery_options_data: posting.deliveryOptionsData
  })

  useEffect(()=> {
    getDeliveryOptions()
  } , [])


  function getDeliveryOptions() {
      BackendCall.i().getDeliveryOptions({
        lat: state.latlon.lat,
        lon: state.latlon.lon,
        vertical_type: state.vertical_type,
        category_id: state.category_id,
        posting_id: posting.id
      })
      .then((postingDeliveryOption: PostingDeliveryOption[]) => {
        let selected = []
        postingDeliveryOption.map((item) =>  {
          if(item.selected){
            selected.push(item.id)
          }
          item?.providers?.map((item) => {
            if(item.selected){
              selected.push(item.id)
            }
          })
        })
        updateState({key: 'delivery_options',value: selected})
      })

  }

  const updateState = (payload) => {
    let  newState = { ...state }
    if (payload instanceof Array) {
      payload.forEach((item) => {
        newState[item.key] = item.value
      })
    }else {
      return setState((prevState)=> {
        return {...prevState , [payload['key']]: payload['value'] }
      })
    }
    setState(prevState => {
      return {...newState}
    })
  }

  const updatePosting = ()=> {
    if (!canSubmitRequest.current || (state.category.postingLocationRequired && isEmpty(state.latlon))) {
      return ;
    }

    BackendCall.i({token , locale})
    .postingUpdate(posting?.id , {  ...state, lat: state.latlon.lat , lon: state.latlon.lon  })
    .then(res=> {
      toast.success(Translation.t('texts.ads_updated'));
      setTimeout(()=> window.location.reload() , 100)
    }).catch(error => {
      let errorMessage = Translation.t('texts.operation_failed');
      if (error instanceof HttpUnprocessableEntity) {
        errorMessage = error.messageStr();
      }
      toast.error(errorMessage)
    })
  }


  const footer = ()=> {
    return (
      <div className="bg-coolGray-100">
        <div className="flex w-full px-4 md:px-6 md:py-4 py-4  gap-4 justify-end">
          <ButtonElement label={Translation.t('buttons.cancel')}  className={`text-coolGray-500 border-coolGray-300`} onButtonClick={onClose} />
          <DefaultButton disabled={!canSubmitRequest.current} onButtonClick={updatePosting} label={Translation.t('buttons.save')} />
        </div>
      </div>
    );
  }


   return (
     <BaseModal onClose={ onClose } maxWidth={'max-w-2xl'}>
       <>
         <div className={`max-h-120 overflow-y-auto slider-scroll-style md:p-6 p-4`}>
            <AdLocation containerStyle={'p-4'} state={state} dispatch={({payload})=>updateState(payload)  } googleMapsApiKey={googleMapsApiKey}  moveToNextStep={()=> {}} mode='edit' />
         </div>
         {footer()}
       </>
     </BaseModal>
   );

}



export default EditDeliveryOptionsModal