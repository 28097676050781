import React, { lazy, Suspense, useEffect, useState } from 'react'
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Loader from "../src/Components/icon/Loader";
import Pagination from '../src/Components/Pagination'
import isEmpty from 'lodash/isEmpty'

import Translation from '../src/helper/Translation';
import { GiftCard } from '@shobbak/react-services/dist/Entities';
import GiftCardItem from '../src/Components/GiftCards/GiftCardItem';
import IconButton from '../src/Components/IconButton';
import ModalComponent from '../src/Components/Modal';
import NoGiftsSvg from '../src/Assets/svg/NoGiftsSvg';

const NoGiftsView = () => {
  return (
    <div className="flex flex-col items-center">
      <NoGiftsSvg />
      <p className="text-2xl mt-4 mb-2 text-coolGray-700">{Translation.t('texts.no_gifts')}</p>
      <p className="text-coolGray-400">{Translation.t('texts.gift_coming_soon')}</p>
    </div>
  );
};

export default ({ token, locale }) => {
  BackendCall.i({ token, locale })

  const [isLoading, setIsLoading] = useState(false)
  const [giftCards, setGiftCards] = useState<GiftCard[]>([])
  const [meta, setMeta] = useState({})
  const [selectedCard, setSelectedCard] = useState<GiftCard>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    getGiftCards({ page: 1 })
  }, [])

  const getGiftCards = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getUserGifts({
      page: { number: page, size: 15 },
    }).then(({ records, meta }) => {
      setGiftCards(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  const renderTerms = (terms) => {
    if (!terms) return

    if (typeof terms === 'string') {
      terms = JSON.parse(terms)
    }

    return terms.map((term, index) => (
      <p className="text-sm" key={index}>{'\u2022'} {term}</p>
    ))
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('texts.shobbak_gifts')}>
          <div className="flex flex-col">

            <hr />

            {isLoading && <Loader />}


            {!isLoading && (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 my-4">
                  {giftCards?.map((giftCard) => {
                    return (
                      <GiftCardItem
                        key={giftCard.id}
                        gift={giftCard}
                        onPress={() => {
                          setSelectedCard(giftCard);
                          setModalVisible(true);
                        }}
                      />
                    )
                  })}
                </div>

                {giftCards?.length == 0 && <NoGiftsView />}

                {!isEmpty(meta) && giftCards?.length != 0 && (
                  <Pagination {...meta} onPaginationChange={({ page }) => {
                    getGiftCards({ page })
                  }} />
                )}
              </>
            )}
          </div>




          <ModalComponent
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            body={(
              <div>
                <div className="flex justify-between items-center">
                  <p>
                    {Translation.t('texts.gift_details')}
                  </p>
                  <IconButton
                    iconName="ri-close-fill"
                    color="gray-500"
                    type="clear"
                    size="small"
                    onPress={() => {
                      setModalVisible(false);
                    }}
                  />
                </div>
                {selectedCard && (
                  <div className="flex flex-col gap-4">
                    <GiftCardItem gift={selectedCard} showReadMore={false} />
                    <div className="flex flex-col gap-2">
                      <p>{Translation.t('texts.gift_description')}</p>
                      <p className="text-sm">{selectedCard?.shobbakCard?.description}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p>{Translation.t('texts.gift_terms')}</p>
                      {renderTerms(selectedCard?.shobbakCard?.terms)}
                    </div>
                  </div>
                )}
              </div>
            )}
          />
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  )
}
