import camelCase from 'camelcase';
import decamelize from 'decamelize';
import {
  AuctionBidding,
  Hub,
  HubOrder,
  PostingAuction,
  PostingOffer,
  PostingOfferAction,
  PostingReportType,
  PublishedPosting,
  StatusGroups,
  User,
  UserAddress,
  UserMobile
} from '@shobbak/react-services/dist/Entities'
import has from 'lodash/has'
import get from 'lodash/get'
import VerticalType from "@shobbak/react-services/dist/Entities/VerticalType"
import { CreditCard } from '@shobbak/react-services/dist/Entities';
import { HubPartner, HubType } from "@shobbak/react-services/dist/Entities/Hubs";

var qs = require('qs');

const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export interface FaqType {
  question: string;
  answer: string;
  id: string
}

export class HttpUnauthorized extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, HttpUnauthorized.prototype);
  }
}

export class HttpUnprocessableEntity extends Error {
  constructor(m: string, errors: object[] = [], response: Response) {
    super(m);
    //@ts-ignore
    this.errors = errors;
    //@ts-ignore
    this.response = response;
    Object.setPrototypeOf(this, HttpUnprocessableEntity.prototype);
  }
  //@ts-ignore

  errorMessages(): ErrorMessage {
    //@ts-ignore
    const contentTypeHeader = this.response.headers.get('Content-Type');
    const contentType = contentTypeHeader.split(';')[0];
    switch (contentType) {
      case 'application/json':
        return this.jsonErrorMessages();
      case 'application/vnd.api+json':
        return this.jsonAPIErrorMessages();
      default:
        return {};
    }
  }
  //@ts-ignore

  jsonErrorMessages(): ErrorMessage {
    //@ts-ignore
    const errors = this.errors && this.errors['errors'];
    if (!errors) {
      return {};
    }
    //@ts-ignore

    const errorMessages: ErrorMessage = Object.keys(errors).reduce(
      //@ts-ignore
      (s: ErrorMessage, key: string) => {
        s[camelCase(key)] = errors[key].join('\n');
        return s;
      },
      {},
      //@ts-ignore
    ) as ErrorMessage;
    return errorMessages;
  }
  //@ts-ignore

  jsonAPIErrorMessages(): ErrorMessage {
    //@ts-ignore
    if (!this.errors) {
      return {};
    }
    //@ts-ignore
    const errorMessages: ErrorMessage = this.errors.reduce(
      //@ts-ignore
      (s: ErrorMessage, element) => {
        if (
          element['source'] &&
          element['source']['pointer'] &&
          element['source']['pointer'].indexOf('/data/attributes/') === 0
        ) {
          const pointer = element['source']['pointer'].substr(
            '/data/attributes/'.length,
          );
          s[camelCase(pointer)] = element['detail'];
        }
        return s;
      },
      {},
      //@ts-ignore
    ) as ErrorMessage;
    return errorMessages;
  }

  messageStr() {
    let message = '';
    const errorMessages = this.errorMessages();
    Object.keys(errorMessages).forEach((key) => {
      message += errorMessages[key] + '\n';
    });
    return message;
  }
}

export default class BackendCall {
  constructor(token?: string, locale?: string, pathPrefix?: string) {
    this.token = token;
    this.locale = locale;
    this.pathPrefix = pathPrefix;
  }

  private static instance: BackendCall;
  private token = '';
  private locale = 'en';
  private baseUrl = '';
  private pathPrefix = '';

  static i({ reset = false, token = '', locale = 'en', pathPrefix = null } = {}): BackendCall {
    if (pathPrefix == null) {
      pathPrefix = '/api/v1/'
    }
    if (this.instance && reset === false) {
      return this.instance;
    }
    this.instance = new BackendCall(token, locale, pathPrefix);
    return this.instance;
  }

  async get(path: string, params = {}): Promise<any> {
    return this.executeHttp('GET', path, params, null, {});
  }

  private async getWithMeta(path: string, params = {}): Promise<any> {
    return this.executeHttp('GET', path, params, null);
  }

  private async postWithMeta(path: string, params: any): Promise<any> {
    return this.executeHttp('POST', path, null, params);
  }

  private async patchWithMeta(path: string, params: any): Promise<any> {
    return this.executeHttp('PATCH', path, null, params);
  }

  async post(path: string, params: any = {}, fileParams: any = {}): Promise<any> {
    return this.executeHttp("POST", path, null, params, fileParams);
  }

  async patch(path: string, params: any, fileParams = {}): Promise<any> {
    return this.executeHttp('PATCH', path, null, params, fileParams);
  }

  async put(path: string, params: any, fileParams = {}): Promise<any> {
    return this.executeHttp('PUT', path, null, params, fileParams);
  }

  private async delete(path: string, params: any): Promise<any> {
    return this.executeHttp('DELETE', path, null, params, {});
  }

  promisfy(cb) {
    return new Promise((resolve, reject) => {
      return cb()
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  getMyAccount() {
    return this.get('users/me', { locale: this.locale });
  }

  submitInvestigation(params) {
    return this.post("/investigations", params)
  }

  getDeliveryOptionCustomFields(id) {
    return this.get(`delivery_options/${id}`)
  }

  getMyAccountMeta() {
    return this.get('users/me/meta');
  }

  checkBlackListNumber(params): Promise<any> {
    return this.get("/lookups/check_blacklisted_numbers", params)
  }

  userResume(id: string, params = {}) {
    return this.get(`/user_resumes/${id}`, params);
  }


  async userResumeList(params = {}): Promise<any> {
    return this.get(`/user_resumes`, params);
  }

  getVerificationMethods(filter: any = {}): Promise<any> {
    return this.get(`/verification_methods`, filter);
  }

  userResumeMeta(filter = {}) {
    return this.get(`user_resumes/meta_data`, { filter });
  }

  markUserResumePrimary(id: number) {
    return this.post(`user_resumes/${id}/mark_primary`, {});
  }

  currentUser() {
    return this.get('users/me');
  }

  acceptShobbakAgreement() {
    return this.post('users/accept_shobbak_agreement');
  }

  deleteUserResume(id: number) {
    return this.delete(`user_resumes/${id}`, {});
  }

  userCreateResume(params) {
    const resume = params.resume
    delete params['resume']
    return this.post(`user_resumes`, params, { file: resume, name: 'resume' });
  }

  applyToJob(params) {
    return this.post(`posting_applications`, params);
  }

  getMyDevice() {
    return this.get('devices/me', { locale: this.locale });
  }

  getVerticalTypes() {
    return this.promisfy(() => this.get('vertical_types', { locale: this.locale }))
  }

  getCountries(params = {}) {
    return this.get(`lookups/countries`, { locale: this.locale, ...params });
  }

  lookupsLocation(lat, lon) {
    return this.get(`/lookups/by_location`, { lat, lon });
  }

  getCarModelsForMake(id) {
    return this.get(`/lookups/get_car_models_for_make_field`, { id });
  }

  getCategories({ vertical_type, parent_id }) {
    return this.promisfy(() => this.get('categories', {
      filter: { vertical_type, parent_id },
      locale: this.locale
    }))
  }

  getUserAddresses(params = {}): Promise<UserAddress[]> {
    return this.get('/user_addresses', params);
  }

  createUserAddress(params: any) {
    return this.promisfy(() => this.post(`/user_addresses`, params));
  }
  updateUserAddress(id: string, params: any) {
    return this.put(`/user_addresses/${id}`, params);
  }

  getWallet(params = {}) {
    return this.get('wallets/me', params);
  }

  getWalletTransactions(params = {}) {
    return this.get('wallets/me/transactions', params);
  }

  getWalletGroupedTransactions(params = {}) {
    return this.get('wallets/me/grouped_transactions', params);
  }

  getWalletWithdrawRequests(params = {}) {
    return this.get('withdraw_requests', params);
  }

  createWalletWithdrawRequest(params = {}) {
    return this.post('withdraw_requests', params);
  }

  getWithdrawRequestMeta(params = {}) {
    return this.get('withdraw_requests/meta', params);
  }

  withdrawRequestAction(id, action) {
    return this.post(`withdraw_requests/${id}/${action}`);
  }

  getBanks(params = {}) {
    return this.get('/lookups/banks', params);
  }

  getWithdrawMethods(params = {}) {
    return this.get('/withdraw_methods', params);
  }

  createWithdrawMethod(params = {}) {
    return this.post('withdraw_methods', params);
  }

  deleteWithdrawMethod(id) {
    return this.delete(`withdraw_methods/${id}`, {});
  }

  getPostingIndex(params) {
    return this.get(`/postings`, { ...params, locale: this.locale });
  }


  postingUpdate(postingId: string, params: any, fileParams = {}) {
    return this.put(`/postings/${postingId}`, params, fileParams);
  }

  getPosting(id) {
    return this.get(`/postings/${id}`);
  }

  getPromotionPackages() {
    return this.get(`/promotion_packages`);
  }

  promotePosting(postingId, packageId) {
    return this.post(`/postings/${postingId}/promote`, { promotion_package_id: packageId })
  }

  cancelPromotePosting(postingId) {
    return this.post(`/postings/${postingId}/cancel_promote`)
  }

  async postingOffers(
    filter?: Record<string, unknown>,
    pageNumber: number = 1,
    perPage: number = 20,
    sort?: Record<string, unknown>,
  ): Promise<PostingOffer[]> {
    const params = this.pageParams(pageNumber, perPage, { filter, sort });
    const result = await this.getWithMeta(`/posting_offers`, params);
    return result['records'];
  }

  getPostingOffersIndex(params) {
    return this.get(`/posting_offers`, { ...params, locale: this.locale });
  }

  async getPostingOffers(
    offerId: string | number,
    params?: any,
  ): Promise<PostingOffer> {
    return await this.getWithMeta(
      `/posting_offers/${offerId}`,
      params,
    );
  }

  getInvoicesIndex(
    filters?: Record<string, unknown>,
    pageNumber: number = 1,
    perPage: number = 20,
    sort?: Record<string, unknown>
  ) {
    const params = this.pageParams(pageNumber, perPage, { filters, sort });
    return this.get(`/invoices`, params);
  }

  async exploreShow(id: string): Promise<PublishedPosting> {
    return this.get(`/explores/${id}`);
  }

  async offerStartChat(offerId: string): Promise<any> {
    return this.get(`/posting_offers/${offerId}/start_chat`);
  }

  async updatePostingOffers(
    offerId: string,
    params: {
      amount: number;
    },
  ): Promise<PostingOffer> {
    return await this.patchWithMeta(
      `/posting_offers/${offerId}`,
      params,
    );
  }

  async getOfferDeliveryOptions(offer_id: string): Promise<any[]> {
    return this.get(`/posting_offers/${offer_id}/delivery_options`);
  }

  async setOfferDeliveryOption(
    offer_id: string,
    delivery_option: string,
  ): Promise<any[]> {
    return this.post(`/posting_offers/${offer_id}/set_delivery_option`, {
      delivery_option,
    });
  }

  async setOfferAddress(
    offer_id: string,
    address_id: string,
  ): Promise<PostingOffer> {
    return await this.postWithMeta(
      `/posting_offers/${offer_id}/set_address`,
      { address_id },
    );
  }

  async getHubItem(id: string): Promise<Hub> {
    return this.get(`/hubs/${id}`);
  }

  async createPostingOffers(params: {
    postingId: string;
    amount?: number;
  }): Promise<PostingOffer> {
    return await this.postWithMeta(`/posting_offers`, params);
  }

  async getFaqs(params?: any): Promise<FaqType[]> {
    return this.get(`/faqs`, params);
  }

  async actionPostingOffers(
    offerId: string,
    action: PostingOfferAction,
    params: any = {},
  ): Promise<PostingOffer> {
    return await this.postWithMeta(
      `/posting_offers/${offerId}/${action}`,
      params,
    );
  }

  async returnProductActions(
    id: string | number,
    action: string,
    params: any = {},
  ): Promise<any> {
    return await this.postWithMeta(
      `/return_product/${id}/${action}`,
      params,
    );
  }

  async getRejectReasonList(): Promise<any> {
    return this.get(`/posting_offers/reject_reasons`);
  }

  async sendBusinessPhoneVerifyCode(id: string): Promise<any> {
    return this.post(`/business_profile_mobiles/${id}/resend`, {});
  }

  async sendPhoneVerifyCode(id: string, params = {}): Promise<any> {
    return this.post(`/user_mobiles/${id}/resend`, params);
  }

  async checkBusinessPhoneVerifyCode(id: string, code: string): Promise<any> {
    return this.post(`/business_profile_mobiles/${id}/verify`, { code });
  }

  async checkPhoneVerifyCode(id: string, code: string): Promise<any> {
    return this.post(`/user_mobiles/${id}/verify`, { code });
  }

  async getDisputeTypes(offerId: string): Promise<any> {
    return this.get(`/posting_offers/${offerId}/dispute_types`);
  }

  async createNewUserMobile(params): Promise<UserMobile> {
    return this.post(`/user_mobiles`, params);
  }

  getPriceTypes() {
    return this.promisfy(() => this.get('postings/price_types', { locale: this.locale }))

  }

  async hubOrderAction(hub_id, action, params = {}): Promise<any> {
    return this.post(`/hubs/${hub_id}/action/${action}`, params);
  }

  async getHubOrder(offer_id): Promise<HubOrder> {
    return this.get(`/posting_offers/${offer_id}/hub_order`);
  }

  requestProfileVerifyCode(key: string, params: any): Promise<any> {
    return this.post(
      `verification_methods/${key}/request_verification`,
      params,
    );
  }

  resendVerificationForDefaultMail(params = {}): Promise<User> {
    return this.post(`/users/resend_email_confirmation`, params);
  }

  usersUpdate(fields: any, file = {}): Promise<User> {
    return this.patch(`users/me`, fields, file);
  }

  confirmPassword(payload = {}) {
    return this.post(`users/confirm_password`, payload);
  }

  deleteUser(payload = {}) {
    return this.delete(`users/request_delete`, payload);
  }

  pay(payload = {}) {
    return this.post("/pay", payload)
  }

  validateApplePayMerchant(payload = {}) {
    return this.post("apple_pay/validate_merchant", payload)
  }

  verifyProfileCodeResend(
    key: string,
    id: string,
    params?: any,
  ): Promise<any> {
    return this.post(`verification_methods/${key}/resend`, params);
  }

  verifyProfileCodeCheck(
    key: string,
    id: string,
    params: any,
  ): Promise<any> {
    return this.post(
      `verification_methods/${key}/verify_otp?verification_id=${id}`,
      params,
    );
  }

  async getLogisticProviderApi(params: any): Promise<PublishedPosting[]> {
    return this.get(`/lookups/logistic_providers`, params);
  }

  getListingTypes({ category_id, vertical_type_id }) {
    return this.promisfy(() => this.get('listing_types', {
      filter: { category_id },
      vertical_type_id,
      locale: this.locale
    }))
  }

  getCustomFields({ category_id, listing_type_id, vertical_type }) {
    return this.promisfy(() => this.get(`/vertical_types/${vertical_type}/custom_fields`, {
      filter: {
        category_id,
        listing_type_id
      }, locale: this.locale
    }))
  }

  async getHubsList(params = {}): Promise<Hub[]> {
    return this.get('/hubs', params);
  }

  async buyerSelectHub(offer_id, params): Promise<any> {
    return this.post(`/posting_offers/${offer_id}/hub_order`, params);
  }
  //@ts-ignore
  async getOfferSteps(params): Promise<VerticalType[]> {
    return this.get(`/posting_offers/steps`, params);
  }

  getCustomFieldValues({ category_id, listing_type_id, vertical_type, custom_field_id, parent_id = null, q = '' }) {
    return this.promisfy(() => this.get(`vertical_types/${vertical_type}/custom_fields/${custom_field_id}/values`, {
      filter: {
        category_id,
        listing_type_id,
        parent_id,
        q
      }, locale: this.locale
    }))
  }

  getDeliveryOptions({ lat, lon, vertical_type, category_id, posting_id = null }) {
    return this.promisfy(() => this.get('/postings/delivery_options', {
      lat,
      lon,
      vertical_type,
      category_id,
      posting_id,
      locale: this.locale
    }))
  }

  verifyUserMobile({ mobile_id, code }) {
    return this.promisfy(() => this.post(`user_mobiles/${mobile_id}/verify`, { code }))
  }

  resendMobileCode({ mobile_id, payload }) {
    return this.promisfy(() => this.post(`user_mobiles/${mobile_id}/resend`, payload))
  }

  resendEmailCode({ email_id }) {
    return this.promisfy(() => this.post(`user_emails/${email_id}/resend`))
  }

  getUserMobiles() {
    return this.promisfy(() => this.get('user_mobiles'))
  }

  getUserEmails() {
    return this.promisfy(() => this.get('user_emails'))
  }

  createUserMobile({ number, country_id }) {
    return this.promisfy(() => this.post('user_mobiles', { number, country_id }))
  }

  createUserEmail({ email }) {
    return this.promisfy(() => this.post('user_emails', { email }))
  }

  makeMobilePrimary({ mobile_id }) {
    return this.promisfy(() => this.post(`user_mobiles/${mobile_id}/primary`))
  }

  makeEmailPrimary({ email_id }) {
    return this.promisfy(() => this.post(`user_emails/${email_id}/primary`))
  }

  createPosting({ data }) {
    return this.promisfy(() => this.post('/postings', data))
  }


  updatePostingMedia(id, { data }) {
    return this.promisfy(() => this.put(`/postings/${id}`, data))
  }

  lookupLocation({ lat, lon }) {
    return this.promisfy(() => this.get('/lookups/by_location', { lat, lon, locale: this.locale }))
  }

  updateDeviceLocation({ lat, lon }) {
    return this.promisfy(() => this.post('devices/location', { lat, lon }))
  }

  lookupAddressCategories() {
    return this.promisfy(() => this.get('/lookups/address_categories', { locale: this.locale }))
  }

  postingClose(id) {
    return this.post(`postings/${id}/close`, {});
  }

  postingVoid(id) {
    return this.post(`postings/${id}/void`, {});
  }

  postingPublish(id) {
    return this.post(`postings/${id}/publish`, {});
  }

  postingBump(id) {
    return this.post(`postings/${id}/bump`, {});
  }

  postingAction(id, action) {
    return this.post(`postings/${id}/${action}`, {});
  }

  async getUserGifts(filter?: any): Promise<any> {
    return this.get(`user_shobbak_cards`, filter);
  }

  async getCreditCards(): Promise<CreditCard[]> {
    return this.get(`credit_cards`);
  }

  async tokenizeCreditCard(params: {
    token: string;
    name: string;
  }): Promise<any> {
    return this.post(`credit_cards/checkout/tokenize`, params);
  }

  async deleteCreditCard(cardId: string): Promise<any> {
    return this.delete(`credit_cards/${cardId}`, {});
  }

  getMyBusinessProfiles(params = {}) {
    return this.promisfy(() => this.get('business_profiles', { locale: this.locale, ...params }));
  }

  getBusinessProfile(id) {
    return this.promisfy(() => this.get(`business_profiles/${id}`, { locale: this.locale, }));
  }

  attachBusinessProfileToDevice(profileId) {
    return this.promisfy(() => this.post(`devices/business_profiles/${profileId}`));
  }

  detachBusinessProfileFromDevice(profileId) {
    return this.promisfy(() => this.delete(`devices/business_profiles/${profileId}`, {}));
  }

  myBusinessProfileIsReady(profileId) {
    return this.promisfy(() => this.post(`/business_profiles/${profileId}/ready`, {}));
  }

  getBusinessMetaData() {
    return this.get('business_profiles/meta_data', { locale: this.locale });
  }

  async getPostingOffersGroups(): Promise<StatusGroups[]> {
    return this.get(`posting_offers/status_groups`, { locale: this.locale });
  }

  async getPostingStatusGroups(): Promise<StatusGroups[]> {
    return this.get(`postings/status_groups`, { locale: this.locale });
  }

  async getInvoicesStatusGroups(): Promise<StatusGroups[]> {
    return this.get(`invoices/status_groups`, { locale: this.locale });
  }

  getCurrentBusinessProfileMeta() {
    return this.get('business_profiles/me/meta', { locale: this.locale });
  }

  async auctionActions(
    id: string,
    action: string,
    params: any,
  ): Promise<PostingAuction> {
    return this.post(`posting_auctions/${id}/${action}`, params);
  }

  async auctionBidActions(
    id: string | number,
    action: string,
    params: any,
  ): Promise<AuctionBidding> {
    return this.post(`posting_auctions/${id}/bid/${action}`, params);
  }

  async createPostingAuction(params: any): Promise<PostingAuction> {
    return this.postWithMeta(`posting_auctions`, params);
  }

  async getPostingAuctionsGroups(params: object = {}): Promise<StatusGroups[]> {
    return this.get(`posting_auctions/status_groups`, params);
  }

  async getMyAuctions(
    filter?: Record<string, unknown>,
    pageNumber: number = 1,
    perPage: number = 20,
    sort?: Record<string, unknown>,
  ) {
    const params = this.pageParams(pageNumber, perPage, { filter, sort });
    return this.getWithMeta(`posting_auctions`, params);
  }

  async rechargeWalletDraft(params: any): Promise<any> {
    return this.postWithMeta(
      `wallet_recharges/draft`,
      params,
    );
  }

  async rechargeWalletCreate(params: any): Promise<any> {
    return this.post(`wallet_recharges`, params);
  }

  async rechargeWalletPay(id: string, params: any): Promise<any> {
    return this.post(`wallet_recharges/${id}/pay`, params);
  }

  async getAuctions(type = '', params = {}): Promise<PostingAuction[]> {
    return this.get(`posting_auctions/${type}`, params);
  }

  async getAuctionDetails(auction_id: string | number): Promise<PostingAuction> {
    return this.get(`posting_auctions/${auction_id}`);
  }

  async getBannerForm(id: number) {
    return this.get(`banner_forms/${id}`);
  }

  async replyToBannerForm(id: number, params = {}) {
    return this.post(`banner_forms/${id}/reply`, params);
  }

  sellerHandoversBuyer(offer_id: string, code: string) {
    return this.post(`posting_offers/${offer_id}/seller_handovers_buyer`, {
      code
    })
  }

  async getInvestigationsTypes(entity: string): Promise<PostingReportType[]> {
    if (entity === 'posting') {
      return this.get(`posting_report_types`);
    }

    return this.get(`investigations/report_types`);
  }

  async createInvestigation(formData, entity): Promise<any> {
    if (entity === 'posting') {
      return this.post(`explores/${formData.get('posting_id')}/report`, formData)
    }

    return this.post(`investigations`, formData);
  }

  async usersResetPassword(fields: object) {
    return this.post(`/users/reset_password`, fields);
  }

  async createUserMobileV2(params: any): Promise<UserMobile> {
    return this.post(`mobiles`, params);
  }

  async verifyMobileV2(params): Promise<UserMobile> {
    return this.post(`mobiles/verify`, params);
  }

  async usersRequestResetPasswordV2(params) {
    return this.post(`users/forget_password`, params);
  }
  async verifyResetPasswordCodeV2(params) {
    return this.post(`users/verify_forget_password`, params);
  }

  async rate(params) {
    return this.post(`rates`, params);
  }

  async getReviews({ relatedToType, relatedToId }: { relatedToType: 'User' | 'BusinessProfile', relatedToId: number | string }, params) {
    if (relatedToType == 'BusinessProfile') {
      return this.get(`business_profiles/${relatedToId}/reviews`, params)
    }
    return this.get(`users/${relatedToId}/reviews`, params)
  }

  async getNotifications(params: any = {}): Promise<any> {
    return this.get('notifications', params);
  }

  async getNotificationTypes(params: any = {}): Promise<any> {
    return this.get('notification_types', params);
  }

  async markNotificationAsRead(id): Promise<any> {
    return this.post(`notifications/${id}/mark_as_read`);
  }

  async markAllNotificationAsRead(params: any = {}): Promise<any> {
    return this.post('notifications/mark_all_readed', params);
  }

  private async executeHttp(
    method: string,
    path: string,
    urlParams: any,
    bodyParams: any,
    fileParams: any = {}
  ) {
    const { url, requestOptions } = await this.buildRequest(
      method,
      path,
      urlParams,
      bodyParams,
      fileParams
    );
    const response = await fetch(url, requestOptions);
    return this.processResponse(response);
  }

  async buildRequest(
    method: string,
    path: string,
    urlParams: any,
    bodyParams: any,
    fileParams: any[]
  ) {
    const headers = new Headers();
    if (this.token && this.token !== '') {
      headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (this.locale && (this.locale == "ar" || this.locale == "en")) {
      headers.set('Accept-Language', this.locale);
    }

    const requestOptions: RequestInit = {
      method,
      headers,
    };
    var uri = `${this.baseUrl}${this.pathPrefix}${path}`;
    uri = uri.replace(/([^:]\/)\/+/g, "$1") // remove extran douple slashes
    let queryString = "";
    if (urlParams) {
      queryString = qs.stringify(urlParams, { arrayFormat: "brackets" });
    }

    if (bodyParams instanceof FormData) {
      bodyParams.append('locale', this.locale)
      requestOptions.body = bodyParams;
    } else if (bodyParams || !isEmpty(fileParams)) {
      if (!isEmpty(fileParams)) {
        const formData = new FormData();
        //@ts-ignore
        formData.append(fileParams.name, fileParams.file);
        const params = this.toUnderscore(bodyParams);
        if (!isEmpty(params)) {
          Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
          });
        }
        requestOptions.body = formData;
      } else {
        headers.append("Content-Type", "application/json");
        requestOptions.body = JSON.stringify(this.toUnderscore(bodyParams));
      }
    }

    const url = `${uri.toString()}${queryString ? `?${queryString}` : ``}`;

    return { url, requestOptions };
  }

  private async processResponse(response: Response): Promise<any> {
    if (response.status === 401) {
      return Promise.reject(new HttpUnauthorized('Unauthorized'));
    }
    const contentTypeHeader = response.headers.get('Content-Type');
    if (!contentTypeHeader) {
      return {};
    }
    let data, result;

    const contentType = contentTypeHeader.split(';')[0];
    switch (contentType) {
      case 'application/json':
        data = await response.json();
        result = this.toCamelCase(data);
        break;
      case 'application/vnd.api+json':
        data = await response.json();
        if (has(data, 'meta')) {
          result = { records: this.jsonAPIToObject(data), meta: get(data, 'meta') }
        } else {
          result = this.jsonAPIToObject(data);

        }
        break;
      default:
        data = await response.text();
        result = data;
    }
    if (response.status === 422) {
      return Promise.reject(
        new HttpUnprocessableEntity('422', result, response),
      );
    }
    if (!response.ok) {
      return Promise.reject(new Error(`Http Call Failed ${response.status}`));
    }
    return result;
  }

  private toCamelCase(obj: any): any {
    return Object.keys(obj).reduce((ccObj, field) => {
      let value: any;
      if (Array.isArray(obj[field])) {
        value = obj[field].map((val) => {
          if (typeof val === 'object') {
            return this.toCamelCase(val);
          } else {
            return val;
          }
        });
      } else if (obj[field] && typeof obj[field] === 'object') {
        value = this.toCamelCase(obj[field]);
      } else {
        value = obj[field];
      }
      return {
        ...ccObj,
        [camelCase(field)]: value,
      };
    }, {});
  }

  private toUnderscore(obj: any): any {
    if (!obj) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map((record) => {
        if (typeof record === 'object') {
          return this.toUnderscore(record);
        } else {
          return record;
        }
      });
    } else {
      return Object.keys(obj).reduce(
        (ccObj, field) => ({
          ...ccObj,
          [decamelize(field)]:
            typeof obj[field] === 'object'
              ? this.toUnderscore(obj[field])
              : obj[field],
        }),
        {},
      );
    }
  }

  pageParams(page: number, perPage: number, params: object = {}) {
    params['page'] = { number: 1, size: 10 };
    if (page) {
      params['page']['number'] = page;
    }
    if (perPage) {
      params['page']['size'] = perPage;
    }
    return params;
  }

  private jsonAPIToObject(json: any): any {
    if (Array.isArray(json['errors'])) {
      return json['errors'];
    } else if (Array.isArray(json['data'])) {
      const res = json['data'].map((record) => {
        const compiledObj = {
          id: record['id'],
          ...this.toCamelCase(record['attributes']),
        };
        this.addRelationship(record, json, compiledObj);
        return compiledObj;
      });
      return res;
    } else {
      const res = {
        id: json['data']['id'],
        ...this.toCamelCase(json['data']['attributes']),
      };
      this.addRelationship(json['data'], json, res);
      return res;
    }
  }

  private addRelationship(recordData, json, record) {
    if (recordData['relationships'] && json['included']) {
      for (const name in recordData['relationships']) {
        const relationship = recordData['relationships'][name];
        if (Array.isArray(relationship['data'])) {
          record[camelCase(name)] = relationship['data'].map((oneRelation) =>
            this.extractOneRelationship(json, oneRelation),
          );
        } else {
          record[camelCase(name)] = this.extractOneRelationship(
            json,
            relationship['data'],
          );
        }
      }
    }
    return record;
  }

  private extractOneRelationship(json, oneRelationship) {
    const element = json['included'].find(
      (item) =>
        oneRelationship &&
        item['type'] === oneRelationship['type'] &&
        item['id'] === oneRelationship['id'],
    );
    if (element) {
      const relationshipRecord = {
        id: element['id'],
        ...this.toCamelCase(element['attributes']),
      };
      this.addRelationship(element, json, relationshipRecord);
      return relationshipRecord;
    }
    return oneRelationship;
  }
}
