import { Theme } from '@shobbak/react-services';
const { Colors } = Theme;
import React from 'react';

const FilterButton = ({
  foregroundColor,
  backgroundColor,
  name,
  onPress,
  isSelected,
}) => {
  return (
    <button
      className="border border-orange-100 rounded-3xl whitespace-nowrap"
      style={{
          borderColor: backgroundColor,
          backgroundColor: isSelected ? backgroundColor : Colors.white,
        }}
      onClick={onPress}>
      <span className="py-1 px-2 text-sm" style={{color: foregroundColor}}>{name}</span>
    </button>
  );
};

export default FilterButton;

