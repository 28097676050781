import { PaymentOption } from '@shobbak/react-services/dist/Entities';
import { Hooks } from '@shobbak/react-services';
const { useDebounce } = Hooks
import React, { useEffect } from 'react';
import 'remixicon/fonts/remixicon.css'

import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';
import PaymentAction from './PaymentAction';
import BackendCall from '../BackendCall';
import { toast } from 'react-toastify';
import SeparatedModal from '../SeparatedModal';
import Translation from '../../helper/Translation';
import IconButton from '../IconButton';
import Input from '../Input';

type RechargeWalletDraft = {
  amount: {
    formatted: string;
    humanized: string;
    value: string;
  };
  applePayAllowed: boolean;
  cardPayAllowed: boolean;
  currency: string;
  description: string;
  id: string;
  options: {formatted: string; humanized: string; value: string}[];
  payment3ds: {
    shouldRedirect: boolean;
  };
  paymentMethod: string;
  paymentOptions: PaymentOption[];
  status: string;
  type: string;
  walletId: number;
  webPayAllowed: boolean;
  rawAttributes: any;
};

export type Props = {
  amount: number|string;
  auctionId: number|string;
  redirectUrl?: string;
  visible: boolean;
  onClose?: () => void;
};

export default function WalletRecharge({amount, auctionId, redirectUrl, visible, onClose}: Props) {
  const [editing, setEditing] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<string>(
    String(amount),
  );
  const delayedAmount = useDebounce<any>(selectedValue, 1000);
  const [rechargeDraft, setRechargeDraft] =
    React.useState<RechargeWalletDraft>();

  const fetchDraft = () => {
    if (!selectedValue) {
      return null;
    }
    BackendCall.i()
      .rechargeWalletDraft({
        amount: selectedValue,
        base_amount: amount,
        posting_auction_id: auctionId,
      })
      .then((res) => {
        setRechargeDraft(res as any);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  React.useEffect(() => {
    fetchDraft();
  }, [delayedAmount]);

  return (
    <SeparatedModal
      visible={visible}
      closeBtn
      headerLabel="Wallet Recharge"
      onClose={onClose}
      body={
        <div>
          <div className="p-4 pt-0 bg-white">
            <div className="flex items-center justify-center mb-2">
              <AuctionRulesSvg />
            </div>
            <p className="font-bold text-2xl text-emerald-500 text-center mb-1">{Translation.t('auctions.labels.auction_rules')}</p>
            <p className="text-center text-sm text-coolGray-500 px-4 mb-6">
              {rechargeDraft?.description}
            </p>
            <div className="flex items-center justify-center">
              <button
                className="mb-6"
              >
                <div className="p-2 rounded bg-coolGray-50 flex items-center justify-center">
                  <p className="font-medium text-xs">
                    See How Auction Work
                  </p>
                  <i className={`${Translation.locale() == 'ar' ? 'ri-arrow-left-s-line' : 'ri-arrow-right-s-line'}`} />
                </div>
              </button>
            </div>
          </div>
    
          <div className="p-4 -mx-4 bg-coolGray-50">
            <div className="p-4 rounded-2xl bg-coolGray-50">
              <div className="bg-white rounded border border-coolGray-300 border-dashed p-2 flex flex-col items-center mb-4">
                {!editing && rechargeDraft && (
                  <div className="flex">
                    <p className="font-bold text-3xl text-emerald-500 leading-9 mb-0.5" >
                      {selectedValue === rechargeDraft?.amount.value
                        ? rechargeDraft?.amount.humanized
                        : rechargeDraft?.options.find(
                            (option) => option.value === selectedValue,
                          )
                        ? rechargeDraft?.options.find(
                            (option) => option.value === selectedValue,
                          )?.humanized
                        : `${selectedValue} ${rechargeDraft?.currency}`}
                    </p>
                    <IconButton
                      color='coolGray-300'
                      size="small"
                      type="clear"
                      iconName="ri-edit-fill"
                      onPress={() => {
                        setEditing(true);
                      }}
                    />
                  </div>
                )}
                {editing && (
                  <div className="flex-1 w-1/2">
                    <Input
                      value={selectedValue}
                      autoFocus
                      onChange={(e) => setSelectedValue(e.target.value)}
                    />
                  </div>
                )}
                <p className="text-xs text-coolGray-400" >
                  Enter an amount or select from chooses
                </p>
              </div>
              <div className="flex items-center justify-center">
                {rechargeDraft?.options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedValue(option.value)}
                    className="me-2"
                  >
                    <div className={`py-2 px-1 rounded ${selectedValue === option.value ? 'bg-emerald-500' : 'bg-white'}`}>
                      <p className={`text-xs ${selectedValue === option.value ? 'text-white' : 'text-coolGray-500'}`}>
                        {option.humanized}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {rechargeDraft && !!selectedValue && (
            <PaymentAction
              onError={() => {}}
              onSuccess={() => {}}
              onUpdate={() => {}}
              amountValue={selectedValue}
              draft={rechargeDraft}
              redirectUrl={redirectUrl}
            />
          )}
        </div>
      }
    />
  );
}
