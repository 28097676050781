import React, { useEffect } from 'react'

const OfferIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.6667L5.63 0.913369C5.73955 0.840277 5.8683 0.80127 6 0.80127C6.1317 0.80127 6.26045 0.840277 6.37 0.913369L12 4.6667V14C12 14.1768 11.9298 14.3464 11.8047 14.4714C11.6797 14.5965 11.5101 14.6667 11.3333 14.6667H0.666667C0.489856 14.6667 0.320286 14.5965 0.195262 14.4714C0.0702379 14.3464 0 14.1768 0 14V4.6667ZM6 7.33337C6.35362 7.33337 6.69276 7.19289 6.94281 6.94284C7.19286 6.6928 7.33333 6.35366 7.33333 6.00004C7.33333 5.64641 7.19286 5.30727 6.94281 5.05723C6.69276 4.80718 6.35362 4.6667 6 4.6667C5.64638 4.6667 5.30724 4.80718 5.05719 5.05723C4.80714 5.30727 4.66667 5.64641 4.66667 6.00004C4.66667 6.35366 4.80714 6.6928 5.05719 6.94284C5.30724 7.19289 5.64638 7.33337 6 7.33337ZM3.33333 10.6667V12H8.66667V10.6667H3.33333ZM3.33333 8.6667V10H8.66667V8.6667H3.33333Z"/>
    </svg>

  );
}


export default OfferIcon