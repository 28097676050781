import React from 'react';

export type RadioButtonProps = {
  value?: boolean;
  mix?: boolean;
  onValueChange?: (e) => void;
  containerStyle?: string;
  disabled?: boolean;
  inActiveColor?: string;
  size?: 'small' | 'normal';
  onChange?: () => void;
  activeIcon?: string;
  inActiveIcon?: string;
};

export default function RadioButton({
  value,
  onValueChange,
  containerStyle,
  disabled,
  onChange,
  inActiveColor,
  mix,
  size = 'normal',
  activeIcon = 'ri-radio-button-line',
  inActiveIcon = 'ri-checkbox-blank-circle-line',
}: RadioButtonProps) {
  const [currentValue, setCurrentValue] = React.useState<boolean>(
    value || false,
  );
  React.useEffect(() => {
    !disabled && onValueChange && onValueChange(false);
    value !== null && value !== undefined && setCurrentValue(value);
  }, [value]);
  const getIconName = () => {
    if (disabled) {
      return 'ri-checkbox-blank-circle-line';
    } else if (currentValue === true) {
      return activeIcon;
    } else {
      return inActiveIcon;
    }
  };
  return (
    <div
      onClick={() => {
        if (!disabled) {
          if (onChange) {
            onChange();
          } else {
            value === null ||
              (value === undefined && setCurrentValue(!currentValue));
          }
        }
      }}
      className={`${size === 'small' ? 'w-4' : 'w-6'} ${containerStyle ? containerStyle : ''}`}
    >
      <i
        className={`${getIconName()} ${size === 'small' ? 'ri-1x' : 'ri-lg'} ${
          disabled 
          ? 'text-coolGray-200' 
          : value 
          ? 'text-emerald-500 active:text-emerald-700' 
          : inActiveColor
          ? `text-${inActiveColor}`
          : 'text-emerald-500 active:text-emerald-700'}`}
        />
    </div>
  );
}
