import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

const create_post_events = [
  'post_creation_started',            // ✅
  'post_creation_step_completed',     // ✅
  'post_creation_completed',          // ✅
  'first_post_creation_completed',    // ✅
];

const view_post_events = [
  'post_clicked',                     // ✅
  'post_detail_view',                 // ✅
  'post_contact_clicked',             // ❌
  'post_advertiser_view',             // ✅
  'post_contacted',                   // ✅
  'post_description_viewed',          // ✅
  'post_specification_viewed',        // ✅
  'post_specs_expanded',              // ✅
  'post_specs_minimized',             // ❌
  'post_add_to_fav',                  // ✅
  'post_add_to_wishlist',             // ❌
  'post_click_to_share',              // ❌
];

const offer_events = [
  'post_offer_submitted',             // ✅
  'post_offer_updated',               // ❌
  'post_offer_accepted',              // ✅
  'post_offer_rejected',              // ✅
  'purchase',                         // ✅
];

const job_events = [
  'job_apply_clicked',                // ✅
  'job_apply_abandoned',              // ✅
  'job_apply_submitted',              // ✅
];

const resume_events = [
  'resume_creation_started',          // ✅
  'resume_creation_completed',        // ✅
];

const auth_events = [
  'login',                            // ✅
  'sign_up',                          // ✅
  'sign_out',                         // ✅
];

const category_events = [
  'post_category_view',               // ✅
];

const search_events = [
  'search',                           // ✅
  'filter',                           // ✅
];

const auction_events = [
  'auction_bid',                      // ✅
  'auction_bid_clicked',              // ✅
  'auction_bid_completed',            // ✅
  'auction_creation',                 // ✅
];

const super_ads_events = [
  'home_super_click',                 // ✅
  'home_super_opened_my_posts',       // ✅
  'home_super_view_post',             // ✅
  'posting_make_super_click',         // ✅
  'super_package_selected',           // ✅
  'super_package_purchased',           // ✅
];

const banner_events = [
  'banner_impression',
  'banner_click',
  'banner_form_submitted_successfully',
  'banner_form_closed_without_submission'
]

const allowed_events = [
  ...create_post_events,
  ...view_post_events,
  ...offer_events,
  ...job_events,
  ...resume_events,
  ...auth_events,
  ...category_events,
  ...search_events,
  ...auction_events,
  ...super_ads_events,
  ...banner_events
];

export function trackViaDataLayer({ event = '', rawPayload = {}, redirectUrl = '', shouldRedirect = true, processPayload = true, callback = null } = {}) {
  if (!allowed_events.includes(event)) return;

  const payload = preparePayload(event, rawPayload, processPayload)

  // https://www.simoahava.com/gtm-tips/use-eventtimeout-eventcallback/
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...payload,
    'eventCallback': function () {
      redirectTo({ redirectUrl, shouldRedirect })
    },
    'eventTimeout': 2000
  })

  setTimeout(() => {
    // callbacks can be like external redirects, so we can execute it immediately
    executeCallback({ callback })
  }, 0);

  setTimeout(() => {
    // redirecting to the intended url anyway after 2 seconds (if the eventCallback isn't triggered before that)
    redirectTo({ redirectUrl, shouldRedirect })
  }, 2000)
}

function redirectTo({ redirectUrl, shouldRedirect }) {
  if (shouldRedirect) {
    window.location.href = redirectUrl
  }
}

function executeCallback({ callback }) {
  if (callback && isFunction(callback)) {
    callback()
  }
}

function preparePayload(event, rawPayload, processPayload) {
  let payload = { ...get(rawPayload, 'tracking_meta_data') }

  if (processPayload == false) {
    return { ...payload, ...omit(rawPayload, ['tracking_meta_data']) }
  } else if ([...create_post_events, ...view_post_events, ...job_events, ...super_ads_events].includes(event)) {
    return { ...payload, ...preparePayloadForPosting(rawPayload) }
  } else if ([...offer_events].includes(event)) {
    return { ...payload, ...preparePayloadForPosting(rawPayload), ...preparePayloadForOffer(rawPayload) }
  } else if ([...auction_events].includes(event)) {
    return { ...payload, ...preparePayloadForPosting(rawPayload), ...preparePayloadForAuction(rawPayload) }
  }

  return payload
}

function preparePayloadForPosting(posting) {
  let payload = {}
  let brand = null
  let brandField = posting.custom_fields?.find(cf => cf.is_brand) || {}

  if (!isEmpty(brandField) && brandField.key && posting.data_field_values && posting.data_field_values[brandField.key]) {
    brand = posting.data_field_values[brandField.key].slug ? posting.data_field_values[brandField.key].slug : posting.data_field_values[brandField.key].value
  }

  if (posting.communication_method) {
    payload = { ...payload, communication_method: posting.communication_method }
  }

  payload = {
    ...payload,
    id: posting.id,
    ad_type: posting.ad_type || get(posting, 'listing_type.slug') || get(posting, 'listing_type.name'),
    vertical_type: posting.vertical_type,
    category: get(posting, 'category.slug'),
    post_name: posting.title,
    post_brand: brand,
    created_at: posting.created_at ? posting.created_at : (new Date()).toISOString(),
    post_city: get(posting, 'city.slug'),
    total_price: get(posting, 'total_price_detail.value'),
    currency: posting.currency,
    is_negotiable: posting.negotiable_price,
  }

  return payload
}

function preparePayloadForOffer(offer) {
  return {
    offer_amount: get(offer, 'offered_amount_detail.value'),
    offer_currency: offer.currency,
    offer_delivery_fee: get(offer, 'delivery_fee_detail.value'),
    offer_vat: get(offer, 'vat_amount_detail.value'),
    offer_total: get(offer, 'total_amount_payable_detail.value'),
    date_time: offer.offer_created_at ? offer.offer_created_at : (new Date()).toISOString()
  }
}

function preparePayloadForAuction(rawPayload) {
  let payload = {}

  if (rawPayload.bid_amount) {
    payload = { ...payload, bid_amount: rawPayload.bid_amount }
  }

  if (rawPayload.bid_created_at) {
    payload = { ...payload, bid_created_at: rawPayload.bid_created_at }
  }

  return payload
}
