import React, { FC, useEffect, useState } from "react";
import ProfileSidebar from "./ProfileSidebar";
import { ChevronLeftIcon, ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import SuccessAlert from "../../src/Components/Alerts/SuccessAlert";
import WarningAlert from "../../src/Components/Alerts/WarningAlert";
import Translation from '../../src/helper/Translation'
import { sidebar } from '../helper/Sidebar'
import Breadcrumb from "../../src/Components/Posting/Breadcrumb"
import first from 'lodash/first'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBag from "../../components/ErrorBag";
import ConfirmPasswordAlert from "./Alerts/ConfirmPasswordAlert";

interface BaseModalType {
  children: JSX.Element,
  errorBag?: Array<{ id: number, title: string }>
  withOutTitleInErrorBag?: boolean
}

const ProfileLayout: FC<BaseModalType> = ({ errorBag = [],withOutTitleInErrorBag= false, children }) => {
  return (
    <>
      <div className="md:block hidden">
        <Breadcrumb steps={{ current: sidebar.filter((item) => item.active) }}
          currentStep={first(sidebar.filter((item) => item.active))} />
      </div>
      <div className="md:py-8 md:px-2">
        <SuccessAlert />
        <WarningAlert />
        <ConfirmPasswordAlert />
        {errorBag.length > 0 && (<ErrorBag errorBag={errorBag} withOutTitleInErrorBag={withOutTitleInErrorBag} />)}
        <div className="flex flex-col items-start md:flex-row w-full md:space-s-4 pb-6">
          <ProfileSidebar />
          <div className="md:w-3/4 w-full flex flex-col gap-4">
            <HeaderBreadcrumb />
            {children}
          </div>
        </div>
      </div>
      <ToastContainer rtl />
    </>
  )
}

const HeaderBreadcrumb = () => {

  const [active, setActive] = useState({})
  useEffect(() => {
    setActive(sidebar.find((item) => item.active))
  }, [])

  return (
    <div className={`md:border md:border-gray-200  md:hidden bg-white rounded-md px-4 py-2 flex flex-col gap-2`}>
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-s-4">
          <li>
            <a href="/">
              <span className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </span>
            </a>
          </li>

          <li>
            <div className="flex items-center">
              {Translation.locale() == 'en' ? (
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />) : (
                <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />)}

              <span className="ms-4 text-sm font-medium text-coolGray-400 hover:text-gray-700"
                aria-current="page">{active.title}</span>
            </div>
          </li>
        </ol>
      </nav>


    </div>
  );
}


export default ProfileLayout;
