import {io as SocketIoClient} from 'socket.io-client';

import {
  HEARTBEAT_COMMAND,
  HEART_BEAT_SEC_INTERVAL,
  SIGN_IN_COMMAND,
  JOIN_CHANNEL_COMMAND,
  LEAVE_CHANNEL_COMMAND,
  USER_STATUS_EVENT,
} from './events';
class SocketClient {
  private static httpEndpoint: string;
  private static socketEndpoint: string;

  private static token: string = '';
  private static bearerToken: string = '';
  private static socket: ReturnType<typeof SocketIoClient>;
  private static interval: any = null;

  static makeBearerToken = (token: string) => {
    return `Bearer ${token}`;
  };

  static connection = (url = window.env?.SOHBBAK_SOCKET_URL  || 'http://localhost:5009' ) => {
    this.httpEndpoint = this.getHttpEndpoint(url);
    this.socketEndpoint = this.getSocketEndpoint(url);
    this.socket = SocketIoClient(this.socketEndpoint, {
      forceNew: true,
      transports: ['websocket'],
    });
    this.subscribeEvents();
  };

  static getHttpEndpoint = (url: string): string => {
    if(!url.includes("://")) {
      url = `https://${url}`;
    }

    if (!url.startsWith('http')) {
      url = url.replace(/.*:\/\//, 'https://')
    }

    if(!url.endsWith('client')) {
      url = `${url}/client`
    }

    return url;
  }

  static getSocketEndpoint = (url: string): string => {
    if(!url.includes("://")) {
      url = `wss://${url}`;
    }

    if(url.startsWith('http://')) {
      url = url.replace('http://', 'ws://')
    }

    if(url.startsWith('https://')) {
      url = url.replace('https://', 'wss://')
    }

    return url;
  }

  static signin = (token?: string) => {
    if (token) {
      this.token = token;
      this.bearerToken = this.makeBearerToken(token);
    }
    if (this.socket) {
      this.socket.emit(
        SIGN_IN_COMMAND,
        {
          args: {
            token: this.token,
          },
        },
        (response) => {
        },
      );
    }
  };

  //for user status
  static subscribeEvents = () => {
    if (this.socket) {
      this.socket.on('connect', (payload) => {
        this.signin();
        this.sendHeartBeat();
      });

      this.socket.on('disconnect', () => {
        this.clearHeartBeat();
      });
    }
  };

  static clearHeartBeat = () => {
    this.interval && clearInterval(this.interval);
  };

  static sendHeartBeat = () => {
    try {
      if (this.socket) {
        this.interval = setInterval(() => {
          this.socket.emit(HEARTBEAT_COMMAND);
        }, HEART_BEAT_SEC_INTERVAL * 1000);
      }
    } catch (error) {
    }
  };

  static setIsShowOnlineStatusToOtherUsers = async (
    show_last_seen: boolean ,
  ) => {
    try {
      const options = {
        method: 'POST',
        headers: {authorization: this.bearerToken},
        data: {show_last_seen}
      };
      const result = await (await fetch(`${this.httpEndpoint}/preference`, options)).json();
      return result.data.show_last_seen;
    } catch (error) {
      return false;
    }
  };

  static getIsShowOnlineStatus = async (token) => {
    try {
      const result = await (await fetch(
        `${this.httpEndpoint}/preference`,
        {headers: {authorization:  this.makeBearerToken(token) }},
      )).json();
      return result.data.user.show_last_seen;
    } catch (error) {
      return false;
    }
  };

  // for auction

  static getScreenBody = (screen_name: string, id?: string|number) => {
    return {
      args: {
        channel: this.getScreenEventName(screen_name, id),
      },
    };
  };

  static getScreenEventName = (screen_name: string, id?: string|number) => {
    return `${screen_name}${id ? '/' + id : ''}`;
  };

  static subscribeEvent = async (
    event_type: string,
    callback: (data: any) => void
  ) => {
    try {
      if (this.socket) {
        this.socket.on(event_type, callback);
      }
    } catch (error) {
    }
  };

  static userStatusEvent = async (callback) => {
    try {
      if (this.socket) {
        this.socket.on(USER_STATUS_EVENT, callback);
      }
    } catch (error) {
    }
  };

  static on = ()=> {

  }
  static joinChannel = async (screen_name: string, id?: string|number) => {
    try {
      if (this.socket) {
        this.socket.emit(
          JOIN_CHANNEL_COMMAND,
          this.getScreenBody(screen_name, id),
        );
      }
    } catch (error) {
    }
  };

  static leaveChannel = async (screen_name: string, id?: string|number) => {
    try {
      if (this.socket) {
        this.socket.emit(
          LEAVE_CHANNEL_COMMAND,
          this.getScreenBody(screen_name, id),
        );
      }
    } catch (error) {
    }
  };
}

export default SocketClient;
