import React, { FC, useContext, useEffect, useState } from "react";
import Translation from '../../helper/Translation'
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const Breadcrumb = ({ steps, currentStep, theme = '' }) => {
  const [activeOrCurrentSteps, setActiveOrCurrentSteps] = useState([])
  const [stepsGreaterThanThree, stepStepsGreaterThanThree] = useState(false)

  useEffect(() => {
    setActiveOrCurrentSteps(() => {
      const x = steps.current.filter((item) => (item.id == currentStep.id || item.id < currentStep.id));
      if (x.length > 3) {
        stepStepsGreaterThanThree(true)
      }
      return x.filter((item) => (item.id == currentStep.id || item.id < currentStep.id)).reverse().splice(0, 3).reverse()
    })
  }, [steps, currentStep])
  return (
    <div className={`overflow-container bg-white ${theme == 'coolGray' ? 'bg-coolGray-700  md:bg-white' : ''}`}>
      <header className="container md:max-w-7xl mx-auto  px-4 sm:px-6  md:py-4 py-2  w-auto ">
        <nav className="flex "
          aria-label="Breadcrumb">
          <ol role="list" className="flex items-center justify-center md:space-s-4 space-s-1">
            <li className={`overflow-hidden ${!stepsGreaterThanThree ? '' : 'md:block hidden'}`}>
              <a href="/">
                <div className="me-4">
                  <span className="text-gray-400 hover:text-gray-500">
                    <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                  </span>

                </div>
              </a>
            </li>

            {stepsGreaterThanThree && (
              <li className="overflow-hidden">
                <div className="flex items-center truncate w-full">
                  <svg className="flex-shrink-0 md:h-5 md:w-5 h-3 w-3  text-gray-400 md:block hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    {Translation.locale() == 'en' ? (
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />) : (
                      <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />)}
                  </svg>

                  <span className="text-gray-400 hover:text-gray-500 md:ms-4 ">
                    <svg width="18" height="4" viewBox="0 0 18 4" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM7 2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2C11 3.1 10.1 4 9 4C7.9 4 7 3.1 7 2Z"
                        fill="#374151" />
                    </svg>
                  </span>

                </div>
              </li>
            )}
            {activeOrCurrentSteps.map(step => {

              return (
                <li key={step.id} className="overflow-hidden">
                  <a href={step.route ? `/${step.route}` : ''}>
                    <div className="flex items-center  truncate w-full">
                      {Translation.locale() == 'en' ? (
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />) : (
                        <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />)}
                      <span
                        className={`md:ms-4 ms-1 text-xs md:leading-4 leading-0 font-normal text-coolGray-400 truncate ${step.id == currentStep.id ?
                          'text-coolGray-400' : 'text-coolGray-700'} `}>{step.title}</span>
                    </div>
                  </a>
                </li>
              )

            })}
          </ol>
        </nav>
      </header>
    </div>
  );
}


export default Breadcrumb;


