import React, { FC, useState, forwardRef, useImperativeHandle, Dispatch } from 'react'
import { BusinessProfileState } from '../../../../components/CreateBusinessProfile'
import AddCircleFill from '../../icon/AddCircleFill'
import SingleImageUploader from '../../SingleImageUploader'
import Translation from '../../../helper/Translation'
import CloseCircle from '../../icon/CloseCircle'
import { Country } from '@shobbak/react-services/dist/Entities'
import TwitterFill from '../../icon/TwitterFill'
import SnapchatFill from '../../icon/SnapchatFill'
import FacebookFill from '../../icon/FacebookFill'
interface MediaInformationProps {
  state: BusinessProfileState;
  dispatch: Dispatch<any>;
  countries: Country[];
  mode: 'create' | 'update';
}

const MediaInformation: FC<MediaInformationProps> = forwardRef(({ state, dispatch, countries, mode = 'create' }, ref) => {
  const [errorBag, setErrorBag] = useState({});

  useImperativeHandle(ref, () => ({
    validateStep() {
      return new Promise((resolve, reject) => {
        if (mode == 'update' || (state.logo != undefined && state.cover_image != undefined)) {
          return resolve(true)
        }

        let errors = {}

        if (state.logo == undefined) {
          errors.logo = Translation.t('errors.messages.required_field', { field: Translation.t('texts.business.profile_image') })
        }

        if (state.cover_image == undefined) {
          errors.cover_image = Translation.t('errors.messages.required_field', { field: Translation.t('texts.cover_photo') })
        }

        setErrorBag(errors)
        return reject(errors)
      })
    }
  }))

  return (
    <div>
      {/* Profile Photo */}
      <div>
        <div className="mb-4">
          <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.profile_image')}</h3>
          <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.profile_image_detail')}</h4>
        </div>

        <div className="mb-4 flex md:space-s-4 flex-col md:flex-row">
          <div className="w-full md:w-1/2 mb-2 flex flex-col">
            <h4 className="text-base leading-7 font-semibold text-coolGray-700 mb-2">{Translation.t('texts.business.profile_image')}</h4>
            <SingleImageUploader
              keyName="logo"
              uploadedFile={state.logo}
              onUpload={file => dispatch({ type: 'key_was_updated', payload: { key: 'logo', value: file } })}
              onDeleted={() => dispatch({ type: 'key_was_updated', payload: { key: 'logo', value: null } })} />
            <span className="text-red-500 text-sm">
              {errorBag.logo}
            </span>
          </div>
          <div className="w-full md:w-1/2 flex flex-col">
            <h4 className="text-base leading-7 font-semibold text-coolGray-700 mb-2">{Translation.t('texts.cover_photo')}</h4>
            <SingleImageUploader
              keyName="cover_image"
              uploadedFile={state.cover_image}
              onUpload={file => dispatch({ type: 'key_was_updated', payload: { key: 'cover_image', value: file } })}
              onDeleted={() => dispatch({ type: 'key_was_updated', payload: { key: 'cover_image', value: null } })}
            />
            <span className="text-red-500 text-sm">
              {errorBag.cover_image}
            </span>
          </div>
        </div>
      </div>

      {/* Business Contacts */}
      <div>
        <div className="mb-4">
          <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.business_contacts')}</h3>
          <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.business_contacts_detail')}</h4>
        </div>

        <div className="mb-4">
          <label htmlFor="company-website" className="block text-sm font-medium text-coolGray-700 leading-5">
            {Translation.t('texts.business.website.label')}
          </label>
          <div className="mt-1 flex rounded-md border border-coolGray-300 focus-within:border-coolGray-400">
            <span className="inline-flex items-center px-3 rounded-s-md border-r border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              www.
            </span>
            <input
              type="text"
              name="company-website"
              id="company-website"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-e-md border-0 focus:outline-none focus:ring-0 sm:text-sm"
              value={state.website ? state.website : ''}
              onChange={e => dispatch({ type: 'key_was_updated', payload: { key: 'website', value: e.target.value } })}
            />
          </div>
        </div>

        <div>
          <div className="mb-4">
            <button className="w-full" onClick={() => {
              const mobiles = state.mobiles
              dispatch({ type: 'key_was_updated', payload: { key: 'mobiles', value: [...mobiles, { country_id: '194', number: '966' }] } })
            }}>
              <div className="flex items-center px-8 py-4 mt-2 rounded-sg border-2 border-dashed border-coolGray-300">
                <div className="w-10">
                  <AddCircleFill className="fill-current text-coolGray-700" />
                </div>
                <div className="flex-1 flex flex-col px-2 text-start">
                  <p className="text-coolGray-700 text-sm leading-5 font-semibold">{Translation.t('texts.business.add_business_number')}</p>
                  <p className="text-coolGray-500 text-sm leading-0 font-normal">{Translation.t('texts.business.add_business_number_detail')}</p>
                </div>
              </div>
            </button>
          </div>

          {state.mobiles.map((mobile, index) => {
            return (
              <div className="mb-4" key={index}>
                <div className="flex flex-col">
                  <label className="text-sm leading-5 font-medium inline-flex flex-end  text-gray-700">{Translation.t('create_new_ad.ad_phone_number')}</label>
                  <div className="flex rounded-md overflow-hidden border border-coolGray-300 focus-within:border-coolGray-400">
                    <select
                      value={mobile.country_id}
                      onChange={event => {
                        let mobiles = state.mobiles.map((m, i) => {
                          if (i != index) return m
                          m = { country_id: event.target.value, number: countries.find(c => c.id == event.target.value).countryCode }
                          return m
                        })
                        dispatch({ type: 'key_was_updated', payload: { key: 'mobiles', value: mobiles } })
                        setErrorBag({})
                      }}
                      className="sm:text-sm text-base leading-6 text-coolGray-500 text-normal bg-coolGray-50 rounded-s-md border-0 border-r border-coolGray-300 focus-within:border-coolGray-400 focus:outline-none">
                      {countries?.map((country: Country) => {
                        return (<option key={country.id} value={country.id}>{country.name}</option>)
                      })}
                    </select>
                    <input
                      type="text"
                      value={mobile.number}
                      onChange={event => {
                        let mobiles = state.mobiles.map((m, i) => {
                          if (i != index) return m
                          m = { country_id: m.country_id, number: event.target.value }
                          return m
                        })
                        dispatch({ type: 'key_was_updated', payload: { key: 'mobiles', value: mobiles } })
                        setErrorBag({})
                      }}
                      className="block ps-3 pe-6 w-full sm:text-sm border-0 focus:outline-none focus:ring-0" />
                    <div className="inset-y-0 end-0 pe-3 flex items-center cursor-pointer rounded-md">
                      <button onClick={() => {
                        let mobiles = state.mobiles.filter((m, i) => i != index)
                        dispatch({ type: 'key_was_updated', payload: { key: 'mobiles', value: mobiles } })
                        setErrorBag({})
                      }}>
                        <CloseCircle className="fill-current text-coolGray-700" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div>
          <div className="mb-4">
            <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.social_accounts')} <span className="text-coolGray-400">{Translation.t('texts.business.optional')}</span></h3>
            <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.social_accounts_detail')}</h4>
          </div>

          <div className="flex flex-col md:flex-row md:space-s-4">
            <div className="w-full md:w-1/3">
              <label htmlFor="twitter-account" className="block text-sm font-medium text-gray-700">
                {Translation.t('create_new_ad.twitter')}
              </label>
              <div className="mt-1 flex rounded-md shadow-sm border border-coolGray-300 focus-within:border-coolGray-400">
                <span className="inline-flex items-center px-3 rounded-s-md border-0 border-e border-coolGray-300 bg-gray-50 sm:text-sm">
                  <TwitterFill className="fill-current text-gray-900" />
                </span>
                <input
                  type="text"
                  name="twitter-account"
                  id="twitter-account"
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-e-md border-0 focus:outline-none focus:ring-0 sm:text-sm border-gray-300"
                  placeholder="@username"
                  value={state.social_accounts.twitter}
                  onChange={e => {
                    let social_accounts = { ...state.social_accounts, twitter: e.target.value }
                    dispatch({ type: 'key_was_updated', payload: { key: 'social_accounts', value: social_accounts } })
                  }}
                />
              </div>
            </div>

            <div className="w-full md:w-1/3">
              <label htmlFor="snapchat-account" className="block text-sm font-medium text-gray-700">
                {Translation.t('create_new_ad.snapchat')}
              </label>
              <div className="mt-1 flex rounded-md shadow-sm border border-coolGray-300 focus-within:border-coolGray-400">
                <span className="inline-flex items-center px-3 rounded-s-md border-0 border-e border-coolGray-300 bg-gray-50 sm:text-sm">
                  <SnapchatFill className="fill-current text-gray-900" />
                </span>
                <input
                  type="text"
                  name="snapchat-account"
                  id="snapchat-account"
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-e-md border-0 focus:outline-none focus:ring-0 sm:text-sm border-gray-300"
                  placeholder="@username"
                  value={state.social_accounts.snapchat}
                  onChange={e => {
                    let social_accounts = { ...state.social_accounts, snapchat: e.target.value }
                    dispatch({ type: 'key_was_updated', payload: { key: 'social_accounts', value: social_accounts } })
                  }}
                />
              </div>
            </div>

            <div className="w-full md:w-1/3">
              <label htmlFor="facebook-account" className="block text-sm font-medium text-gray-700">
                {Translation.t('create_new_ad.facebook')}
              </label>
              <div className="mt-1 flex rounded-md shadow-sm border border-coolGray-300 focus-within:border-coolGray-400">
                <span className="inline-flex items-center px-3 rounded-s-md border-0 border-e border-coolGray-300 bg-gray-50 sm:text-sm">
                  <FacebookFill className="fill-current text-gray-900" />
                </span>
                <input
                  type="text"
                  name="facebook-account"
                  id="facebook-account"
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-e-md border-0 focus:outline-none focus:ring-0 sm:text-sm border-gray-300"
                  placeholder="@username"
                  value={state.social_accounts.facebook}
                  onChange={e => {
                    let social_accounts = { ...state.social_accounts, facebook: e.target.value }
                    dispatch({ type: 'key_was_updated', payload: { key: 'social_accounts', value: social_accounts } })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default MediaInformation
