import React, { useEffect } from 'react'

const BankCardIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.6663 3.33333H0.333008V0.666667C0.333008 0.298477 0.631485 0 0.999674 0H12.9997C13.3679 0 13.6663 0.298477 13.6663 0.666667V3.33333ZM13.6663 4.66667V11.3333C13.6663 11.7015 13.3679 12 12.9997 12H0.999674C0.631485 12 0.333008 11.7015 0.333008 11.3333V4.66667H13.6663ZM8.99967 10V8.66667H11.6663V10H8.99967Z"/>
    </svg>

  );
}


export default BankCardIcon