window.desktop_slider = (size = []) => {
  return {
    size: size - 1 ,
    activeAsset: 0,
    move (direction = 'next') {
      let currentIndex = this.activeAsset
      if (direction == 'next') {
        this.activeAsset = currentIndex + 1  > this.size ? 0 : currentIndex + 1

      }
      else{
        this.activeAsset = currentIndex  ==  0  ? this.size : currentIndex - 1
      }
    }
  }
}