//channels
export const USER_STATUS_CHANNEL = 'UserStatus';

//commands
export const SIGN_IN_COMMAND = 'sign_in';
export const JOIN_CHANNEL_COMMAND = 'join_channel';
export const LEAVE_CHANNEL_COMMAND = 'leave_channel';
export const HEARTBEAT_COMMAND = 'heartbeat';

// events
export const SET_USER = 'set-user';
export const TRACK_SCREEN = 'track-screen';
export const DISCONNECT = 'disconnect';
export const CONNECTION = 'connection';
export const USER_STATUS_EVENT = 'user_status_update';

//keys
export const USER_STATUS = 'user-status';

export const HEART_BEAT_SEC_INTERVAL = 5;
