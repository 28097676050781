import React from 'react'


const LoginSvgIcon  = ()=> {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69 36.17V20.36a5.11 5.11 0 0 0-3.91-5L39.17 9.12a5.11 5.11 0 0 0-6.31 5V64a5.11 5.11 0 0 0 6.31 5l25.93-6.33a5.11 5.11 0 0 0 3.91-5v-2" stroke="#FFD836"/>
      <path d="M69 41.71a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.56a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm-48.47-41.7 2.14-7 .31-1c.23-.73-.92-1-1.14-.32l-2.14 7-.31 1c-.23.73.91 1 1.14.32ZM2.29 11.8 9 14.68l.94.4a.61.61 0 0 0 .81-.21.589.589 0 0 0-.21-.81l-6.71-2.88-.94-.41a.63.63 0 0 0-.81.21.61.61 0 0 0 .21.82Z" fill="#10B981"/>
      <path d="m13.82 10.38-2.23-6.94-.32-1C11 1.72 9.9 2 10.13 2.76l2.23 6.94.31 1c.24.72 1.38.41 1.15-.31v-.01Z" fill="#FFD836"/>
      <path d="M38.34 37.06a11.72 11.72 0 0 0-5.05-3.14c2.4-1.34 4.08-4.37 4.08-7.9a8.72 8.72 0 0 0-5.3-8.4 4.15 4.15 0 0 0-2.13-.4l-6.23 1.36s1.55.55 1.68 1.1c.044.155.122.299.23.42a9.88 9.88 0 0 0-1.87 5.9 9.18 9.18 0 0 0 3.48 7.51 12.66 12.66 0 0 0-7.92 3.9c-5 5.34-4.38 13.93-4.35 14.3a1 1 0 0 0 .77.91l23.63 5.9h-.16a1.008 1.008 0 0 0 .24 2h.24l4.18-1c.108-.03.21-.08.3-.15a.81.81 0 0 0 .25-.3.505.505 0 0 0 0-.13c.033-.087.057-.178.07-.27v-.06c.17-.61.52-14.68-6.14-21.55Z" fill="#10B981"/>
      <path d="M16.59 52.11s-1.28-17.26 13.55-17.26S44.23 59 44.23 59l-27.64-6.89Z" fill="#fff" stroke="#10B981"/>
      <path d="M33.29 36.1a.25.25 0 1 1 0 .499.25.25 0 0 1 0-.5Zm0 1.25a.251.251 0 0 1 .3.245.25.25 0 0 1-.3.245.25.25 0 1 1 0-.49Zm.62-.63a.25.25 0 1 1 .02.5.25.25 0 0 1-.02-.5Zm-1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5ZM33.91 38a.25.25 0 0 1 .25.25.253.253 0 0 1-.052.208.25.25 0 0 1-.387 0 .25.25 0 0 1-.052-.208.25.25 0 0 1 .24-.25Zm-1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-1.9a.25.25 0 0 1 .05.495.25.25 0 1 1-.05-.495Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M32.05 36.1a.25.25 0 0 1 .05.495.25.25 0 1 1-.05-.495Zm5.61 6.9a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.23a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm.62-.63a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1-.25.25.26.26 0 0 1 .25-.25Zm1.28 1.25a.25.25 0 0 1 .048.495.25.25 0 1 1-.049-.495ZM36.41 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25 2.47a.25.25 0 1 1 0 .499.25.25 0 0 1 0-.5Zm0-4.98a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 0 1 .24.25.25.25 0 1 1-.49 0 .25.25 0 0 1 .25-.25Zm.62-.62a.25.25 0 0 1 .172.43.25.25 0 1 1-.172-.43Zm-1.28 0a.253.253 0 0 1 .209.052.25.25 0 0 1-.208.438.25.25 0 0 1-.276-.352.25.25 0 0 1 .276-.138Zm1.28 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.59-1.87a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M37.66 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25-3.75a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.66-.62a.25.25 0 1 1 0 .49.25.25 0 0 1-.275-.352.25.25 0 0 1 .276-.138Zm1.28 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.28 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5ZM36.41 38a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.25 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M36.41 38a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 5a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-2.51a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 0 1 .042.497.25.25 0 0 1-.216-.427.25.25 0 0 1 .173-.07Zm0 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Z" fill="#10B981"/>
      <path d="M36.41 43a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-5a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24 1.25a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 .1.49.25.25 0 0 1-.1-.49Zm1.25 1.24a.25.25 0 1 1-.001.5.25.25 0 0 1 0-.5Zm-.62.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.24-3.73a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.63-.62a.25.25 0 0 1 .25.25.25.25 0 1 1-.49 0 .25.25 0 0 1 .24-.25Zm1.25 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-1.25 0a.25.25 0 1 1 .021.499.25.25 0 0 1-.02-.499Z" fill="#10B981"/>
      <path d="M35.17 38a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm1.24 0a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm1.8 11.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Zm0-1.25a.25.25 0 0 1-.25-.25.26.26 0 0 1 .25-.25.25.25 0 1 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M38.21 49.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Z" fill="#10B981"/>
      <path d="M38.21 49.06a.26.26 0 0 1-.25-.25.25.25 0 1 1 .25.25Zm0-1.25a.25.25 0 0 1-.25-.25.26.26 0 0 1 .25-.25.25.25 0 1 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm1.32-3a.25.25 0 1 1-.001.501.25.25 0 0 1 0-.5Zm0 1.24a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.62-.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.24a.26.26 0 0 1 .25.25.25.25 0 1 1-.25-.25Zm.62.63a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0-4.98a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.24a.25.25 0 0 1 .048.495.25.25 0 1 1-.049-.495Zm-.62-.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm0 1.3a.25.25 0 1 1 0 .49.25.25 0 0 1-.276-.352.25.25 0 0 1 .275-.138Z" fill="#10B981"/>
      <path d="M39.53 43.57a.25.25 0 1 1-.001.501.25.25 0 0 1 0-.5Zm-.62-3.11a.25.25 0 1 1 0 .49.25.25 0 0 1-.276-.352.25.25 0 0 1 .275-.138Zm.62.62a.25.25 0 1 1 0 .5.25.25 0 0 1 0-.5Zm-.69 8.56a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm.62.6a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm0-1.22a.25.25 0 0 1-.24-.25.251.251 0 0 1 .245-.3.25.25 0 0 1 .245.3.25.25 0 0 1-.25.25Zm-.62-.63a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm.62 4.36a.25.25 0 1 1 .001-.5.25.25 0 0 1 0 .5Zm0-1.25a.25.25 0 1 1 .1-.49.25.25 0 0 1-.1.49Zm.63 1.87a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Z" fill="#10B981"/>
      <path d="M38.84 49.64a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm.62 3.11a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm.63 1.87a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm0-1.25a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0 4.98a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.25a.25.25 0 1 1 0-.49.252.252 0 0 1 .276.138.25.25 0 0 1-.275.352Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-2.49a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm1.24 3.73a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm0-1.24a.25.25 0 1 1 .25-.25.26.26 0 0 1-.25.25Zm.62.62a.25.25 0 0 1-.244-.299.25.25 0 1 1 .245.299Zm0-1.25a.25.25 0 1 1 .08-.494.25.25 0 0 1-.08.494Zm-.62-.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.24 4.98a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm-1.24 0a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.24-1.25a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm-1.24 0a.25.25 0 1 1 0-.49.252.252 0 0 1 .276.138.25.25 0 0 1-.275.352Zm.62.63a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.29 0a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm-1.29-1.25a.25.25 0 1 1 0-.499.25.25 0 0 1 0 .5Zm1.29 0a.25.25 0 0 1-.3-.24.26.26 0 0 1 .25-.25.25.25 0 0 1 .25.25.25.25 0 0 1-.2.24Zm.58 1.87a.251.251 0 0 1-.256-.292.25.25 0 0 1 .453-.098.25.25 0 0 1-.197.39Zm0-1.25a.25.25 0 1 1-.019-.5.25.25 0 0 1 .02.5Zm-1.25-1.24a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M40.09 54.62a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm2.49 2.49a.251.251 0 0 1-.256-.292.25.25 0 0 1 .453-.098.25.25 0 0 1-.197.39Zm0-1.25a.25.25 0 1 1-.019-.5.25.25 0 0 1 .02.5Zm-1.81 1.88a.25.25 0 1 1 0-.49.25.25 0 0 1 .276.352.25.25 0 0 1-.276.138Zm1.23 0a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Zm1.2-1.22a.25.25 0 1 1-.001-.5.25.25 0 0 1 0 .5Zm.07 1.26a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z" fill="#10B981"/>
      <path d="M10.26 52.58s-1.34-18 14.12-18c15.46 0 14.69 25.19 14.69 25.19l-28.81-7.19Zm14.5-19.05c2.95 0 5.34-3.139 5.34-7.01 0-3.872-2.39-7.01-5.34-7.01-2.95 0-5.34 3.138-5.34 7.01 0 3.871 2.39 7.01 5.34 7.01Z" fill="#fff" stroke="#10B981"/>
      <path d="M42.6 37.54A11.617 11.617 0 0 0 39 38c-4.9 1.55-8.72 5.3-8.72 11.8 0 6.5 3.87 11.8 8.72 11.8.33 0 2.72.45 3.65.45 5.07 0 9.2-5.5 9.2-12.26s-4.18-12.25-9.25-12.25Z" fill="#10B981"/>
      <path d="M50 49.75c0 5.8-3.19 10.5-7.38 10.5-4.19 0-7.81-4.7-7.81-10.5s3.4-10.5 7.6-10.5c4.2 0 7.59 4.75 7.59 10.5Z" fill="#C2E9DB"/>
      <path d="m46.58 47.89-3 .38-.2-3.5a1.001 1.001 0 1 0-2 .12l.21 3.64-3.24.47a1.002 1.002 0 0 0 .13 2h.13l3.08-.4.22 3.85a1 1 0 0 0 1 1H43a.998.998 0 0 0 1-1.08l-.23-4 3.12-.41a1.009 1.009 0 0 0-.26-2l-.05-.07Z" fill="#fff"/>
      <path d="M37.974 54.722a2.63 2.63 0 0 1 .035-3.557l.969-.969-.573-.573-.24-.24a2.65 2.65 0 0 1-.12-3.458 2.561 2.561 0 0 1 3.655-.134l.856.827 1.018-1.018a2.411 2.411 0 0 1 1.818-.7 2.71 2.71 0 0 1 1.852.834l.106.106a2.64 2.64 0 0 1 .007 3.6l-.763.763.495.495.247.248a2.66 2.66 0 0 1 .12 3.457 2.57 2.57 0 0 1-3.663.128l-.813-.729-1.23 1.23a2.392 2.392 0 0 1-1.81.637 2.72 2.72 0 0 1-1.86-.827l-.106-.12Z" fill="#10B981"/>
      <path d="m46.402 52.206-2.235-2.008 2.277-2.277a1 1 0 0 0-.05-1.364 1 1 0 0 0-1.35-.064l-2.348 2.348-2.418-2.178a1 1 0 0 0-1.372.042 1 1 0 0 0 .092 1.322l.092.092 2.312 2.072-2.475 2.475a1 1 0 0 0 .043 1.372l.042.042a1 1 0 0 0 1.358.057l2.567-2.567 2.333 2.093a1 1 0 0 0 1.379-.035.998.998 0 0 0-.247-1.422Z" fill="#fff"/>
    </svg>

  );


}


export default LoginSvgIcon