import React, { FC, useContext, useEffect, useState, useRef } from "react";
import { PostingFormState } from "./CreatePostingForm";
import BackendCall from "../BackendCall";
import Translation from '../../helper/Translation';
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import CreateUserEmailModal from "./Modal/CreateUserEmailModal";
import SelectEmailOptionsModal from "./Modal/SelectEmailOptionsModal";
import Chevron from "../icon/Chevron";
import AddCircleFill from "../icon/AddCircleFill";


interface AdInfoProps {
	state: PostingFormState;
	dispatch: React.Dispatch<any>;
	withOutDisplayIconName?: boolean

}

export type UserEmailType = {
	id: string;
	email: string;
	verifiedAt?: number;
	verified: boolean;
	isPrimary?: boolean;
};


const UserEmail: FC<AdInfoProps> = ({ state,withOutDisplayIconName, dispatch }) => {
	const [userEmails, setUserEmails] = useState<UserEmailType[]>([])
	const [selectUserEmail, setSelectUserEmail] = useState<UserEmailType>(null)
	const [isSelectOptionModelOpened, setIsSelectOptionModelOpened] = useState(false)
	const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

	const getUserEmails = () => {
		BackendCall.i()
			.getUserEmails()
			.then((res: UserEmailType[]) => setUserEmails(res))
	}

	const createUserEmail = ({ email }) => {
		return BackendCall.i()
			.createUserEmail({ email })
			.then((res: UserEmailType) => {
				setUserEmails([...userEmails, res])
				setIsCreateModalOpen(false)

			})
	}

	const makeEmailPrimary = (emailId) =>
		BackendCall.i()
			.makeEmailPrimary({ email_id: emailId })
			.then(res => { })


	const resendVerificationCode = (userEmail: UserEmailType) => {
		BackendCall.i().resendEmailCode({ email_id: userEmail.id })
	}

	function newEmailVerified(event) {
		if (event && event.detail && event.detail.id) {
			getUserEmails();

		}
	}


	useEffect(() => {
		getUserEmails();
	}, []);

	useEffect(() => {

		window.addEventListener("newEmailVerified", newEmailVerified);
		return () => {
			window.removeEventListener("newEmailVerified", newEmailVerified);
		};
	}, []);


	function handleOnClick(userEmail: UserEmailType) {
		if (userEmail.verified) {
			setIsSelectOptionModelOpened(true)
		} else {
			setIsCreateModalOpen(true)
		}
	}

	function shouldDisplayCommunicationIcons(item) {
		return item.isPrimary || map(state.email_communications, 'id').includes(item.id.toString())
	}

	function shouldDisplayEmailIcon(item) {
		return state.email_communications.filter((communication) => (communication.communication_type == 'email'  || communication.communicationType == 'email' )&& communication.id == item.id).length > 0
	}


	const displayUserEmail = (email) => {
		return (
			<div
				className="md:w-auto w-full border border-gray-200 px-2 py-1 md:px-4 md:py-2 flex flex-col md:flex-row items-center justify-between rounded-md"
				key={email.id}>


				<div className="flex items-center justify-between md:w-auto w-full">
					<div className="flex items-center space-s-2 md:space-s-4">
						<div className="p-4 bg-coolGray-50 rounded-md cursor-pointer" onClick={() => {
							setSelectUserEmail(email)
							handleOnClick(email)
						}}>
							<svg width="20" height="19" viewBox="0 0 20 19" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd"
									d="M19 0.536133H1C0.447715 0.536133 0 0.983848 0 1.53613V17.5361C0 18.0884 0.447715 18.5361 1 18.5361H19C19.5523 18.5361 20 18.0884 20 17.5361V1.53613C20 0.983848 19.5523 0.536133 19 0.536133ZM3.648 3.77413L10.06 9.21913H10.061L16.346 3.78013L17.654 5.29313L10.073 11.8531L2.353 5.29813L3.648 3.77413Z"
									fill="#111827" />
							</svg>

						</div>
						<div className="flex flex-col gap-2 cursor-pointer" onClick={() => {
							setSelectUserEmail(email)
							handleOnClick(email)
						}}>
							<div className="text-base leading-6 font-semibold text-coolGray-900">{email.email}</div>
							{email.verified
								? (<div
									className="text-sm leading-3 font-normal text-coolGray-400">{Translation.t('create_new_ad.choose_how_to_create_via_email')}</div>)
								: (<div
									className="text-sm leading-3 font-normal text-orange-500">{Translation.t('create_new_ad.email_not_verified')}</div>)
							}

						</div>
					</div>
					<div className="cursor-pointer md:hidden block" onClick={() => {
						setSelectUserEmail(email)
						handleOnClick(email)
					}}>
						<Chevron locale={state.locale} />
					</div>
				</div>

				{shouldDisplayCommunicationIcons(email) && (

					<div className="mt-2 md:mt-0">
						<div className="flex items-center gap-2">
							{email.isPrimary && (
								<div className="flex items-center gap-2 bg-coolGray-50 rounded-xl py-1 px-2">
									<svg width="10" height="13" viewBox="0 0 10 13" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M4.99922 0.668945L9.38162 1.64281C9.62535 1.69721 9.79922 1.91321 9.79922 2.16335V7.48974C9.79922 8.55961 9.26428 9.55908 8.37415 10.1521L4.99922 12.4023L1.62429 10.1521C0.733619 9.55854 0.199219 8.55961 0.199219 7.49028V2.16335C0.199219 1.91321 0.373085 1.69721 0.616819 1.64281L4.99922 0.668945ZM7.37362 4.52068L4.73362 7.16014L3.22535 5.65188L2.47122 6.40601L4.73415 8.66894L8.12828 5.27481L7.37362 4.52068Z"
											fill="#10B981" />
									</svg>

									{!withOutDisplayIconName && (
									<span
										className="text-sm leading-5 font-normal text-coolGray-700">{Translation.t('create_new_ad.is_primary')}</span>
									)}
								</div>
							)}

							{shouldDisplayEmailIcon(email) && (
								<div className="flex items-center gap-2 bg-coolGray-50 rounded-xl py-1 px-2">
									<svg width="12" height="11" viewBox="0 0 12 11" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd"
											d="M10.7993 0.735352H1.19935C0.904797 0.735352 0.666016 0.974133 0.666016 1.26868V9.80202C0.666016 10.0966 0.904797 10.3354 1.19935 10.3354H10.7993C11.0939 10.3354 11.3327 10.0966 11.3327 9.80202V1.26868C11.3327 0.974133 11.0939 0.735352 10.7993 0.735352ZM2.6116 2.46238L6.03134 5.36638H6.03187L9.38387 2.46558L10.0815 3.27251L6.03827 6.77118L1.92094 3.27518L2.6116 2.46238Z"
											fill="#10B981" />
									</svg>


									<span
										className="text-sm leading-5 font-normal text-coolGray-700">{Translation.t('create_new_ad.email_icon')}</span>
								</div>)}
						</div>
					</div>

				)}


				<div className="cursor-pointer md:block hidden" onClick={() => {
					setSelectUserEmail(email)
					if (!email.verified) {
						return setIsVerifyModalOpen(true)
					}
					setIsSelectOptionModelOpened(true)
				}}>
					<Chevron locale={state.locale} />
				</div>
			</div>
		);
	}
	return (
		<>

			{isCreateModalOpen && <CreateUserEmailModal
				selectedUserEmail={selectUserEmail}
				onClose={() => {
					setIsCreateModalOpen(false)
					setSelectUserEmail(null)
				}}
				onSubmit={(email) => {
					if (isEmpty(selectUserEmail)) {
						createUserEmail(email)
					} else {
						resendVerificationCode(selectUserEmail)
						setIsCreateModalOpen(false)
						setSelectUserEmail(null)
					}
				}} />}


			{isSelectOptionModelOpened && (
				<SelectEmailOptionsModal selectedUserEmail={selectUserEmail}
					emailCommunications={state.email_communications}
					onClose={() => {
						setIsSelectOptionModelOpened(false)
						setSelectUserEmail(null)
					}}

					onSubmit={({ options, isPrimary, emailId }) => {
						if (!selectUserEmail.isPrimary && isPrimary) {
							makeEmailPrimary(emailId)

							const newUserEmails = userEmails.map((userEmail) => {
								userEmail.isPrimary = false;
								if (emailId == userEmail.id) {
									userEmail.isPrimary = true
								}
								return userEmail;
							})

							setUserEmails(newUserEmails)
						}
						dispatch({
							type: 'key_was_updated',
							payload: { key: 'email_communications', value: options }
						})

						setSelectUserEmail(null)
						setIsSelectOptionModelOpened(false)


					}}
					onChangeEmail={() => {
						setSelectUserEmail(null)
						setIsSelectOptionModelOpened(false)
					}}

				/>
			)}
			<div>
				<div className="flex justify-between space-s-2 items-center md:py-2 py-1.5">
					<div className="flex items-center md:space-s-4">
						<span
							className="text-base leading-7 font-semibold text-coolGray-900 truncate">{Translation.t('create_new_ad.add_email_address')}</span>
					</div>
					<div
						className="border-2 border-dashed border-gray-200 flex items-center justify-center p-3 space-s-2 cursor-pointer truncate"
						onClick={() => {
							setIsCreateModalOpen(true)
						}}>
						<AddCircleFill className="fill-current text-emerald-500" />
						<span
							className="text-sm leading-5 font-semibold text-emerald-500 truncate">{Translation.t('create_new_ad.email_address')}</span>
					</div>
				</div>

				<ul className="flex flex-col gap-2">
					{userEmails.map(email =>
						displayUserEmail(email)
					)}

				</ul>
			</div>

		</>
	)
}


export default UserEmail

