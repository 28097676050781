import { Business, DeviceMeResponse, User } from "@shobbak/react-services/dist/Entities";
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import BackendCall from "../Components/BackendCall";

export default ({ token, locale }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | undefined>()
  const [device, setDevice] = useState<DeviceMeResponse | undefined>()
  const [businessProfile, setBusinessProfile] = useState<Business | undefined>()

  useEffect(() => {
    getMyDevice()
  }, [])

  function getMyDevice() {
    BackendCall.i({ token, locale })
      .getMyDevice()
      .then(response => {
        setDevice(response)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isEmpty(device)) return

    setUser(device.user)
    setBusinessProfile(device.businessProfile)
  }, [device])

  return { isLoading, user, device, businessProfile }
}