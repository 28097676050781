import React from "react";

interface BaseLayoutProps {
  children: JSX.Element
}
function BaseLayout ({children}: BaseLayoutProps){
  return (
    <div className="flex flex-col justify-center items-center md:p-10 md:gap-6 gap-4 p-2 w-full">
      {children}
    </div>
  )
}

export default BaseLayout