export default class EventDispatcher {
  static dispatch(eventName: string, payload = {}) {
    const customEvent = new CustomEvent(eventName, { detail: payload instanceof Object ? { ...payload } : payload });
    window.document.dispatchEvent(customEvent)
  }

  static on(eventType, listener) {
    window.document.addEventListener(eventType, listener);
  }

  static remove(eventType, listener) {
    window.document.removeEventListener(eventType, listener);
  }

  static openLoginModal(payload = {}) {
    window.document.dispatchEvent(new CustomEvent('open-auth-modal', { detail: { ...payload } }))
  }
}
