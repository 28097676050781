export default class StringHelper {
  private words = '';

  constructor(words: string) {
    this.words = words
  }

  take(number: number) {
   return this.words?.split(/\s+/).slice(0, number).join(" ");
  }

}
