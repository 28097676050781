window.initResetPasswordForm = function () {
    return {
        password: '',
        password_confirmation: '',
        input_type: 'password',
        password_type: 'password',
        password_confirmation_type: 'password',
        changeCurrentType(input) {
            this[input] = this[input] == 'password' ? 'text' : 'password'
        },
        rules: {
            repeated_char_valid: '',
            eight_char_with_number: '',
            password_confirmation_rule: '',
            upper_case_or_lowercase: '',
        },
        disable_password_confirmation: false,
        validatePasswordConfirmation(value) {
            if (this.disable_password_confirmation) return;
            if (value == this.password && this.password) {
                this.rules.password_confirmation_rule = 'valid'
            } else {
                this.rules.password_confirmation_rule = 'not_valid'

            }
        },
        validatePassword(value, $dispatch) {
            if (/\s/.test(value)) {
                this.rules.repeated_char_valid = 'not_valid';
            } else {
                this.rules.repeated_char_valid = 'valid';
            }
            //upper case or lower case
            if (/[a-z]/.test(value) || /[A-Z]/.test(value)) {
                this.rules.upper_case_or_lowercase = 'valid';
            } else {
                this.rules.upper_case_or_lowercase = 'not_valid';
            }
            //length and and has number
            if (value.length >= 8 && /^(?=.*\d).+$/.test(value)) {
                this.rules.eight_char_with_number = 'valid'
            } else {
                this.rules.eight_char_with_number = 'not_valid'
            }
            //password confirmation

            if (!this.disable_password_confirmation) {
                if (value == this.password_confirmation && this.password_confirmation) {
                    this.rules.password_confirmation_rule = 'valid'
                } else {
                    this.rules.password_confirmation_rule = 'not_valid'
                }
            }
            $dispatch('password-component', {no_valid: this.buttonDisabled})
            $dispatch('password-rules-changed', {rules: this.rules})


        },
        get buttonDisabled() {
            $length = Object.keys(this.rules).map(item => this.rules[item]).filter(item => item == 'valid').length
            return this.disable_password_confirmation ? $length != 3 : $length != 4;
        },
        get passwordStrength() {
            let colors = ['bg-gray-200', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200'];
            countOfVaild = Object.keys(this.rules).map(item => this.rules[item]).filter(item => item == 'valid').length

            if (countOfVaild == 1) {
                colors = ['bg-yellow-400', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200'];
            }
            if (countOfVaild == 2) {
                colors = ['bg-yellow-400', 'bg-yellow-400', 'bg-yellow-400', 'bg-yellow-400', 'bg-gray-200', 'bg-gray-200'];
            }
            if (countOfVaild == 3) {
                colors = ['bg-emerald-500', 'bg-emerald-500', 'bg-emerald-500', 'bg-gray-200', 'bg-gray-200', 'bg-gray-200'];

            }
            if (countOfVaild == 4) {
                colors = ['bg-emerald-500', 'bg-emerald-500', 'bg-emerald-500', 'bg-emerald-500', 'bg-emerald-500', 'bg-emerald-500'];

            }
            return colors
        }


    }
}


