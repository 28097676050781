import {OfferMessage} from '@shobbak/react-services/dist/Entities';
import React, {FC} from 'react';

interface MessageProps {
  message: OfferMessage;
}

const Message: FC<MessageProps> = ({message}) => {
  return (
    <div
      className="px-4 py-2 mb-4 bg-coolGray-50 flex space-s-2 rounded-lg"
      style={{backgroundColor: message.backgroundColor}}
    >
      {message.icon && (
        <i className={`text-coolGray-700 ${message.icon}`} style={{color: message.iconColor}} />
      )}
      <div className="flex-1">
        {message.title && (
          <p
            className="mb-1 text-coolGray-600"
            style={{color: message.titleColor}}
          >
            {message.title}
          </p>
        )}
        {message.message && (
          <p
            className="text-coolGray-600 flex-1"
            style={{color: message.foregroundColor}}
          >
            {message.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Message;
