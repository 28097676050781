import React, { useEffect, useState } from 'react'
import BackendCall from "../src/Components/BackendCall";
import { Posting } from "@shobbak/react-services/dist/Entities";
import { Services } from '@shobbak/react-services';
import Translation from "../src/helper/Translation";
import DateTime from "../src/helper/DateTime";
import Loader from "../src/Components/icon/Loader";
import PromotionAdSvg from "../src/Assets/svg/PromotionPosting/PromotionAdSvg";
import PaymentButtons from "../src/Components/PaymentButtons";
import AppContext, { Env } from "../src/Services/AppContext";
import { Provider } from "react-redux";
import { store } from '../src/redux/store'
import { toast } from "react-toastify";
import PromotionPostingSuccessModal from "./PromotionPostingSuccessModal";
import { ApplePay } from '../src/helper/ApplePay';
import { HandlePaymentParams } from '../src/Components/Offer/Actions/PaymentAction';
const { Helpers: { imageUrlA } } = Services;


const PromotionPosting = ({authUser, locale, token , env , postingId}) => {
  const[posting , setPosting] =useState <Posting | any >({})
  const [promotionPosting , setPromotionPosting] = useState<any>({})
  const [isLoading , setIsLoading] = useState<boolean>(true)
  const [isProcessingPayment ,setIsProcessingPayment] = useState<boolean>(false)
  const [paymentMethod , setPaymentMethod] = useState<'wallet' | 'card_pay' | null>(null)
  const [showSuccessModal , setShowSuccessModal] = useState<boolean>(false)


  useEffect(()=> {
    BackendCall.i({token , locale})
    .getPosting(postingId)
    .then(postingResponse =>  {
      setPosting(postingResponse)
      setPromotionPosting(postingResponse.promotionPosting)
      setIsLoading(false)
    } )
  } , [])


  const postingInfo = ()=> {
    return (
      <div className="flex space-s-6 overflow-hidden">
        <div>
          <img
            width="96"
            height="96"
            src={imageUrlA(posting.processedAssetUrls[0]?.url, 96)}
            className="rounded-lg w-24 h-24"
          />
        </div>
        <div className="overflow-hidden">
          <p className="text-coolGray-900 text-2xl leading-8 truncate" dir="auto">{posting.title}</p>
          <p className="flex space-s-4">
            <span className="text-coolGray-400 text-lg">{Translation.t('texts.posted_in')}</span>
            <span className="text-coolGray-600 text-sm flex items-center space-s-1">
            <i className="ri-calendar-line ri-lg"></i>
            <span>{new DateTime(posting.createdAt).short()}</span>
          </span>
          </p>
        </div>
      </div>
    )
  }

  const handlePayment = (data: HandlePaymentParams)=>{
    setIsProcessingPayment(true)
    BackendCall.i({token , locale})
    .pay({...data , payment_for_id: promotionPosting.id , payment_for_type: 'promotion_postings'})
    .then((posting)=>{
      if(posting?.promotionPosting?.payment3Ds.url && data.payment_handler == 'card_pay'){
         return window.location.href = posting?.promotionPosting?.payment3Ds.url
      }
      if(posting?.promotionPosting?.status == 'paid'){
        if (data.payment_handler === 'apple_pay') {
          ApplePay.complete(ApplePay.SUCCESS);
        }
        setPosting(posting)
        setShowSuccessModal(true)
      }
    }).catch(error => {
      if (data.payment_handler === 'apple_pay') {
        ApplePay.complete(ApplePay.FAILURE);
      }
    })
  }

  const handleError = (message)=> {
    setIsProcessingPayment(false);
    toast.error(message);
  }

  const onPaymentMethodUpdate = (payment)=> {
    setPaymentMethod(payment)
  }

  const viewPosting= ()=> {
    window.location.href = posting.publicSiteUrl
  }


  const promotionDetail = ()=> {
    return (<div className="bg-white">
        <div className="flex flex-col items-center justify-center md:p-6 p-3">
          <PromotionAdSvg />
          <p className="text-2xl leading-8 font-bold text-emerald-500 text-center pt-4 pb-1">{Translation.t('promotion_posting.pay.package',{package: promotionPosting.promotionPackageName})}</p>
          <p className="text-base leading-6 font-normal text-center text-coolGray-500">{Translation.t('promotion_posting.pay.fee')}</p>
        </div>

      <PaymentButtons
        isDisabled={isProcessingPayment}
        amount={promotionPosting?.totalAmountPayable}
        offerAmount={0}
        deliveryFee={0}
        currency={promotionPosting?.currency}
        paymentOptions={promotionPosting ? promotionPosting.paymentOptions : []}
        onSuccess={handlePayment}
        onFailure={handleError}
        isProcessingPayment={isProcessingPayment}
        onStartPayment={() => setIsProcessingPayment(true)}
        onEndPayment={() => setIsProcessingPayment(false)}
        readyToPay={true}
        walletPayAllowed={promotionPosting.walletPayAllowed}
        cardPayAllowed={promotionPosting.cardPayAllowed}
        canPayByWallet={promotionPosting.canPayByWallet}
        applePayAllowed={promotionPosting.applePayAllowed}
        webPayAllowed={!promotionPosting.cardPayAllowed && !(ApplePay.isAvailable() && promotionPosting.applePayAllowed) && promotionPosting.webPayAllowed}
        lineItems={promotionPosting?.lineItems}
        withWalletLineItems={promotionPosting?.withWalletLineItems}
        messages={promotionPosting?.messages}
        coveredByGiftCard={promotionPosting?.coveredByGift}
        offer={promotionPosting}
        selectedGiftCard={null}
        onPaymentMethodUpdate={onPaymentMethodUpdate}
      />


    </div>)
  }


  const successModal = () => {
    return (
      <PromotionPostingSuccessModal posting={posting} redirect={true} />
    )
  }

  return (
      <Provider store={store}>
        <AppContext.Provider value={{ user: authUser.user, country: authUser.country, env }}>
          <div className="container md:py-8 md:max-w-7xl mx-auto md:px-4 w-auto">
          {showSuccessModal && successModal()}
        <div className="flex space-s-6 items-start">
          {/* offer */}
          <div className="w-full rounded-lg md:w-2/3">
            {isLoading ? <Loader /> : promotionDetail()}
          </div>

          {/* post */}
          <div className="hidden md:block md:w-1/3">
              <div className="bg-white rounded-lg p-6">
                {isLoading ? <Loader /> : postingInfo()}
              </div>
          </div>
        </div>
      </div >
        </AppContext.Provider>
      </Provider>
  )
}


export default PromotionPosting
