import React, { useEffect, useRef, useState } from "react";
import BackendCall from "../src/Components/BackendCall";
import isEmpty from 'lodash/isEmpty'
import Pagination from '../src/Components/Pagination'
import Loader from "../src/Components/icon/Loader";

import Translation from '../src/helper/Translation'
import 'remixicon/fonts/remixicon.css'
import { Review } from "../entities";
import DateTime from "../src/helper/DateTime";
import RateStars from "./RateStars";

const ReviewsList = ({ locale, token, relatedToType, relatedToId }: {locale: string, token: string, relatedToType: 'User'|'BusinessProfile', relatedToId: number|string}) => {
  BackendCall.i({ token, locale })

  const [reviews, setReviews] = useState<Review[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const reviewRefs = useRef(reviews)

  const setReviewsWithRefs = (reviews) => {
    setReviews(reviews)
    reviewRefs.current = reviews
  }
  const [currentPage, setCurrentPage] = useState(1)

  const getReviews = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getReviews({relatedToType, relatedToId}, {
      page: { number: page, size: 15 },
    }).then(({ records, meta }) => {
      setReviewsWithRefs(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getReviews({ page: 1 })
  }, [currentPage])

  return (
    <div className="flex flex-col divide-y">

      {isLoading && <Loader />}

      {!isLoading && reviews && (
        <>
          {reviews.map((review) => {
            return (<ReviewShow review={review} key={review.id} />)
          })}

          {reviews.length == 0 && (
            <div
              className="p-2 flex items-center justify-center w-full">{Translation.t('rating.no_rating')}</div>
          )}

          {!isEmpty(meta) && reviews.length != 0 && (
            <Pagination {...meta} onPaginationChange={({ page }) => {
              getReviews({ page })
            }} />
          )}
        </>
      )}

    </div>
  )
}


const ReviewShow = ({ review }: {review: Review}) => {
  return (
    <div className="flex items-start justify-between py-4" key={review.id}>
      <div className="flex items-start gap-4 w-full">
        <div className="w-9 h-9 rounded-full overflow-hidden">
          <img src={review.user.avatarUrl} alt={review.user.fullName}
            className="object-cover rounded-s-lg h-full w-full" />
        </div>
        <div className="flex-1 flex flex-col gap-2 w-full">
          <div className="flex">
            <span
              className="md:text-xl md:leading-7 md:font-medium  text-base leading-6 font-semibold text-coolGray-700 flex-1">{review.user.fullName}</span>
            {review.raterRoleLabel && (
              <span className="w-16 p-1 rounded text-center text-sm font-medium" style={{backgroundColor: review.raterRoleLabel.backgroundColor, color: review.raterRoleLabel.color}}>
                {review.raterRoleLabel.title}
              </span>
            )}
          </div>
          
          <RateStars score={review.score} />

          <p className="text-sm">{review.comment}</p>

          <p className="text-xs text-coolGray-300">{new DateTime(review.createdAt).short()}</p>
        </div>

      </div>
    </div>
  );
}

export default ReviewsList
