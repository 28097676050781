export default class BlurHash {

  constructor() {
   this.init()

  }

  init = ()=> {
    const images = document.querySelectorAll('[id^="blur-hash-image-"]');
    images.forEach((image)=> {

      const id =  image.getAttribute('id').replace('blur-hash-image-','');
      const src = image.getAttribute('blurhash-src')
      let url = new URL(src);
      let default_url = new URL(env.DEFAULT_IMAGES_URL)
      let search_params = url.searchParams;
      search_params.set('dpr', parseInt(window.devicePixelRatio.toFixed(0)));
      url.search = search_params.toString();
      image.src = url.toString() ;
      image.removeAttribute('blurhash-src')
      const blurHashCanvas = document.getElementById(`blur-hash-canvas-${id}`)

      if (this.isCached(image)) {
        return  this.handleBlurHashImag(image , blurHashCanvas)
      }
      image.onload = ()=> {
        this.handleBlurHashImag(image , blurHashCanvas)
      }

    })
  }

  isCached = (image)=> {
    return image.complete;
  }

  handleBlurHashImag = (image , blurHashImage)=> {
    setTimeout(()=> {
      if (blurHashImage){
        blurHashImage.style.display = 'none'
      }
      image.classList.remove('hidden')
    } , 500)
  }




}
