import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Pagination from '../src/Components/Pagination'
import Loader from "../src/Components/icon/Loader";
import Translation from '../src/helper/Translation'
import StatusGroups from "../src/Components/StatusGroups";
import 'remixicon/fonts/remixicon.css'
import moment from 'moment';

import 'remixicon/fonts/remixicon.css'
import isEmpty from 'lodash/isEmpty'

export default ({ locale, token }) => {
  BackendCall.i({ token, locale })

  const [statusGroup, setStatusGroup] = useState<string[]>([]);
  const [isAscSorting, setIsAscSorting] = useState(false);
  const [invoices, setInvoices] = useState<[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const invoicesRef = useRef(invoices)

  const setInvoicesWithRefs = (invoices) => {
    setInvoices(invoices)
    invoicesRef.current = invoices
  }

  const getInvoices = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getInvoicesIndex(
      {
        status_group: statusGroup,
      },
      page,
      15,
      { created_at: isAscSorting ? 'asc' : 'desc' },
    ).then(({ records, meta }) => {
      setInvoicesWithRefs(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getInvoices({ page: 1 })
  }, [currentPage, statusGroup, isAscSorting])

  const onSelectStatusGroup = (data) => {
    setStatusGroup(Object.keys(data));
  };

  function renderInvoice(invoice) {
    return (
      <div className="flex border border-coolGray-200 p-4 mb-2 rounded items-center">
        <div className="flex-1">
          <div className="flex space-x-1">
            <p className="">{`${Translation.t('texts.invoice_id')}:`} </p>
            <p className="font-thin text-coolGray-700">{invoice.id}</p>
          </div>

          <div className="flex space-x-1">
            <p className="">{`${Translation.t('texts.status')}:`} </p>
            <p className={`${invoice.status.toLowerCase() == "completed" ? 'text-emerald-500' : 'text-yellow-500'} font-thin`}>{invoice.statusLocalized}</p>
          </div>

          <div className="flex space-x-1">
            <p className="">{`${Translation.t('texts.date')}:`} </p>
            <p className="font-thin text-coolGray-700">{moment(invoice.createdAt).format('DD MMM YYYY - hh:mm A')}</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <a href={invoice.showUrl} target="_blank">
            <i className="ri-eye-line ri-lg" />
          </a>
          <a href={invoice.downloadUrl} target="_blank">
            <i className="ri-arrow-down-line ri-lg" />
          </a>
        </div>
      </div>
    )
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('breadcrumb.my_invoices')}>
          <div className="flex flex-col">
            <div className="flex items-center justify-between space-s-4">
              <StatusGroups
                onSelect={onSelectStatusGroup}
                type="invoices"
              />
              <button
                onClick={() => setIsAscSorting(!isAscSorting)}
                className="flex items-center justify-center"
              >
                <i className={`${isAscSorting ? 'ri-sort-asc' : 'ri-sort-desc'} ri-lg`} />
              </button>
            </div>
            <hr />

            {isLoading && <Loader />}

            {!isLoading && (
              <>
                <div className="flex flex-col space-y-2 my-4">
                  {invoices.map((invoice) => {
                    return (
                      <div key={invoice.id}>
                        {renderInvoice(invoice)}
                      </div>
                    )
                  })}

                </div>

                {invoices.length == 0 && (
                  <div
                    className="p-2 flex items-center justify-center w-full">{Translation.t('posting_offer.tabs.not_results')}
                  </div>
                )}

                {!isEmpty(meta) && invoices.length != 0 && (
                  <Pagination {...meta} onPaginationChange={({ page }) => {
                    getInvoices({ page })
                  }} />
                )}
              </>
            )}
          </div>
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  );
}
