import React, {useState} from 'react'
import {Bank, WithdrawRequest, WithdrawRequestMeta} from "@shobbak/react-services/dist/Entities";
import Translation from '../../src/helper/Translation';
import NewWithdrawRequest from './NewWithdrawRequest';
import DateTime from '../../src/helper/DateTime';
import BaseModal from "../../src/Components/Posting/Modal/BaseModal";
import Header from "../WithdrawRequest/Header";
import ReviewStep from "../WithdrawRequest/ReviewStep";
import useAbsher from '../../src/Hooks/useAbsher';
import RequestProfileVerifyModal from '../../src/Components/EditProfile/RequestProfileVerifyModal';
import OtpModal from '../../src/Components/EditProfile/OtpModal';

interface WithdrawRequestsProps {
    requests: WithdrawRequest[];
    token: string;
    locale: string;
    isVerified: boolean;
    shouldVerifyWithAbsher: boolean;
}

interface WithdrawRequestProp {
    id: bigint;
    lineItems: any[]
    allowedActions: any[]

}

export default function WithdrawRequests({
                                             requests,
                                             token,
                                             locale,
                                             isVerified,
                                             shouldVerifyWithAbsher
                                         }: WithdrawRequestsProps) {
    const [isFormVisible, setFormVisibility] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [currentWithdrawRequest, setCurrentWithdrawRequest] = useState<WithdrawRequestProp | null>(null)
    const {
        isVerificationModalVisible,
        isOtpModalVisible,
        absherVerificationMethod,
        updateVerificationMethods,
        afterVerificationMethodUpdated,
        resendVerificationCode
    } = useAbsher({token, locale, isVerified, shouldVerifyWithAbsher});

    const renderHeader = (request) => {
        const statusColors = {
            created: {color: "bg-yellow-100", text: "text-yellow-800"},
            initiated: {color: "bg-yellow-100", text: "text-yellow-800"},
            processing: {color: "bg-yellow-100", text: "text-yellow-800"},
            approved: {color: "bg-yellow-100", text: "text-yellow-800"},
            senttobank: {color: "bg-yellow-100", text: "text-yellow-800"},
            done: {color: "bg-emerald-50", text: "text-emerald-500"},
            rejected: {color: "bg-red-50", text: "text-red-500"},
            held: {color: "bg-yellow-100", text: "text-yellow-800"},
            cancelled: {color: "bg-red-50", text: "text-red-500"}
        }
        let currentBgColor = statusColors[request.status].color
        let currentTextColor = statusColors[request.status].text
        return (

            <div className="flex flex-col w-1/3 text-coolGray-500">
                <span
                    className={`${currentTextColor} ${currentBgColor} w-16 p-1 rounded flex items-center justify-center`}>{request.status}</span>
                <span>{new DateTime(request.createdAt).short()}</span>
            </div>
        )
    }
    const reviewStepModal = () => {
        if (!showModal) return (<></>)
        return (
            <BaseModal onClose={() => setShowModal(false)} maxWidth="max-w-lg">
                <div className="bg-white p-4 sm:p-6">
                    <Header onClose={() => setShowModal(false)}/>
                    <ReviewStep lineItems={currentWithdrawRequest.lineItems}
                                allowedActions={currentWithdrawRequest.allowedActions}
                                withdrawRequestId={currentWithdrawRequest.id} onCancel={() => setShowModal(false)}
                                showToastyAlert={true}/>
                </div>
            </BaseModal>

        )
    }

    const setShowWithdrawRequest = (withdrawRequest) => {
        setCurrentWithdrawRequest(withdrawRequest)
        setShowModal(true)
    }

    return (
        <div>
            {isFormVisible && isVerificationModalVisible &&
                <RequestProfileVerifyModal
                    onSuccess={(method) => afterVerificationMethodUpdated(method)}
                    updateMethods={(method) => {
                        updateVerificationMethods(method)
                    }}
                    locale={locale}
                    token={token}
                    methodPayload={absherVerificationMethod}
                    onClose={() => {
                        setFormVisibility(false)
                    }}/>

            }

            {isFormVisible && isOtpModalVisible &&
                <OtpModal
                    locale={locale}
                    token={token}
                    methodPayload={absherVerificationMethod}
                    resendVerificationCode={(methodPayloadData) => resendVerificationCode(methodPayloadData)}
                    title={absherVerificationMethod.pageTitle}
                    onClose={() => {
                        setFormVisibility(false)
                    }}
                    onSuccess={(methodPayload) => {
                        window.location.reload()
                    }}/>
            }

      {reviewStepModal()}
      {(isFormVisible && !isVerificationModalVisible && !isOtpModalVisible) ? <NewWithdrawRequest onClose={() => setFormVisibility(false)} token={token} locale={locale} /> : null}
      <div className="flex justify-end my-4">
        <button
          onClick={() => setFormVisibility(true)}
          className={`whitespace-nowrap bg-emerald-600 border border-transparent rounded-md py-1 px-2 inline-flex items-center justify-center text-base font-normal cursor-pointer leading-6 text-white hover:bg-emerald-700 active:bg-emerald-600 disabled:opacity-50`}>
          {Translation.t('wallet.actions.new_withdraw_request')}
        </button>
      </div>
      {requests.length ?
        <ul>
          {requests.map((request: WithdrawRequest) => {
            return (
              <li
                key={request.id}
                onClick={() => {
                  if (!isVerified && shouldVerifyWithAbsher && request.status == "initiated") {
                    setFormVisibility(true)
                  } else {
                    setShowWithdrawRequest(request)
                  }
                }}
                className="flex cursor-pointer items-center  text-sm p-2 border border-gray-200 rounded my-2">
                {renderHeader(request)}
                <span className="w-1/3 flex flex-col justify-center">
                  <span className="text-coolGray-500">{request.destination}</span>
                  <span className="text-coolGray-500">{request.iban}</span>
                </span>
                                <span className="w-1/3 flex justify-end">
                  <span className="font-medium text-coolGray-700">
                    {request.amountDetail.formatted}
                  </span>
                </span>
                            </li>
                        )
                    })}
                </ul> : null
            }

            {requests.length == 0 ?
                <div className="p-2 flex flex-col items-center justify-center w-full">
                    <h3 className="text-lg leading-8 text-emerald-500">{Translation.t('wallet.messages.no_withdraws_title')}</h3>
                    <h4 className="text-sm leading-8 text-coolGray-500">{Translation.t('wallet.messages.no_withdraws_description')}</h4>
                </div> : null
            }

        </div>
    )
}
