import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Loader from "../src/Components/icon/Loader";
import Translation from '../src/helper/Translation'
import 'remixicon/fonts/remixicon.css'
import { UserAddress } from "@shobbak/react-services/dist/Entities";
import CreateUserAddressModal from "../src/Components/CreateUserAddressModal";
import UserAddresses from "../src/Components/UserAddress";
import Divider from "../src/Components/Divider";
import Switch from "../src/Components/Switch";
import { toast } from "react-toastify";

export default ({ locale, token, currentCountry, env }) => {
  BackendCall.i({ token, locale })

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [creationMode, setCreationMode] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<UserAddress[]>([])
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | object>({})

  useEffect(() => {
    userAddresses()
  }, [])


  const userAddresses = () => {
    BackendCall.i({ token, locale })
      .getUserAddresses()
      .then(res => {
        setIsLoading(false)
        setAddresses(res)
      })
      .catch((res) => {
        setIsLoading(false)
      })
  }



  const displayAddresses = () => {
    return (
      <div>
        <div className="border border-dashed border-emerald-500 rounded-lg p-4 flex items-center gap-4 w-full">
          <div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
            <i className={`ri-pin-distance-fill text-emerald-500 ri-xl`} />
          </div>
          <div className="flex flex-col gap-1 cursor-pointer" onClick={() => setCreationMode(true)}>
            <p className="font-semibold text-emerald-500 cursor-pointer">{Translation.t('buttons.add_new_address')}</p>
            <p className="text-xs leading-none text-coolGray-500">{Translation.t('texts.no_address_message')}</p>
          </div>
        </div>
        <Divider label={Translation.t("texts.or_choose_from_list")} darker containerStyle="my-6" />
        <div className={`flex flex-col items-start justify-start gap-1`}>
          {addresses.map((address) => <Address key={address.id} address={address} />)}
        </div>

      </div>
    )
  }

  const Address = ({ address }) => {
    return (
      <div className={`w-full flex flex-col gap-2 border border-coolGray-200 rounded-md p-2`}>
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <div className={`flex flex-row items-start justify-start gap-2`}>
              <div className={`w-8 h-8 rounded-md flex items-center  justify-center bg-coolGray-50`}>
                <img src={address.categoryDetail.svgPath} alt="category icon" />
              </div>
              <div className={`flex flex-col w-full divide-y divide-coolGray-200 gap-2`}>
                {/*address summary*/}
                <div className={`flex flex-col`}>
                  <p className={`text-sm leading-5 font-semibold text-coolGray-700`}>{address.category}</p>
                  <p className={`text-sm leading-0 font-normal text-coolGray-500`}>{address.address}</p>
                </div>
                {/*{address info  }*/}
                <div className={`flex flex-col items-start  pt-1`}>
                  <div className={`flex items-start gap-1`}>
                    <div className={`flex gap-2 items-center justify-center rounded-md w-6 h-6 bg-coolGray-50`}>
                      <i className={`ri-article-fill ri-sm text-coolGray-700`} />
                    </div>
                    <div className={`flex flex-col gap-2`}>
                      <p className={`text-sm leading-5 font-semibold text-coolGray-700`}>Address Info</p>
                      <ul className="list-disc list-inside bg-stripes-white text-light-blue-600  rounded-md">
                        {address.phoneNumber && (<li className={`text-sm leading-5 font-normal text-coolGray-500`}>{address.phoneNumber}</li>)}
                        <li className={`text-sm leading-5 font-normal text-coolGray-500`}>{address.category}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* actions  */}
                <div className={`flex gap-2 px-4 cursor-pointer`} onClick={() => {
                  setSelectedAddress(address)
                  setCreationMode(true)
                }}>
                  <i className={`ri-edit-fill ri-sm text-coolGray-700`} />
                  <p className={`text-sm leading-0 font-normal text-coolGray-700`}>{Translation.t('texts.edit')}</p>
                </div>

              </div>
            </div>
          </div>
          <div>
            <Switch
              value={address.isPrimary}
              locale={locale}
              skipInitialRenderHook={true}
              onValueChange={() => {
                BackendCall.i({ token, locale }).updateUserAddress(address.id, { is_primary: !address.isPrimary })
                  .then(() => {
                    toast.success(Translation.t('texts.user_address_updated'))
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000);
                  })
                  .catch(({ errors }) => toast.error(errors[0]?.detail))
              }}
            />
          </div>
        </div>
      </div>
    )
  }


  const renderCreationOrEdit = () => {
    return (<CreateUserAddressModal mode={Object.keys(selectedAddress).length > 0 ? 'edit' : 'create'} address={selectedAddress} currentCountry={currentCountry} googleMapsApiKey={env.FRONTEND_GOOGLE_MAP_KEY} onClose={() => { setCreationMode(false) }} afterSubmit={() => {
      setIsLoading(true)
      userAddresses()
      setCreationMode(false)
    }} />)
  }

  const displayEmptyState = () => {
    return (
      <div className={`min-h-72 flex flex-col items-center justify-center gap-2`}>
        <div className={`w-12 h-12 rounded-md bg-emerald-50 flex items-center justify-center`}>
          <i className={`ri-pin-distance-fill text-emerald-500 ri-xl`} />
        </div>

        <div className={`flex flex-col gap-1 justify-center items-center mt-4`}>
          <h3 className={`md:text-2xl md:leading-8  text-lg leading-6 font-semibold text-coolGray-700`}>{Translation.t('texts.no_address')}</h3>
          <p className={`text-base leading-0 font-normal text-coolGray-500 text-center`}>{Translation.t('texts.no_address_message')}</p>
        </div>

        <button onClick={() => setCreationMode(true)} className={`whitespace-nowrap mt-2 bg-emerald-600 border border-transparent rounded-md py-2 px-4 items-center justify-center text-base font-normal cursor-pointer leading-6 text-white hover:bg-emerald-700 active:bg-emerald-600`}>{Translation.t('buttons.add_new_address')}</button>

      </div>
    )
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('profile_sidebar.my_addresses')}>
          <div className="flex flex-col">
            {isLoading ? <Loader /> : (
              creationMode ? renderCreationOrEdit() :
                (addresses.length > 0 ? displayAddresses() : displayEmptyState())
            )}
          </div>
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  );
}
