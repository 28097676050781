import React, { FC, useEffect, useRef } from "react";
import DefaultButton, { ButtonElement } from "../../EditProfile/DefaultButton";
import Translation from "../../../helper/Translation";


interface BaseModalType {
    onClose: () => void;
    children: JSX.Element;
    maxWidth?: string;
    overflowHidden?: boolean;
}

const BaseModal: FC<BaseModalType> = ({ children, maxWidth = 'max-w-md', overflowHidden = true, onClose }) => {

    const ref = useRef();


    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                // @ts-ignore
                if (!ref.current || ref?.current?.contains(event.target)) {
                    return;
                }

                onClose();
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        }, [ref]
    );
    return (
        <div className="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
            aria-modal="true">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-44 text-center sm:block sm:p-0">

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>

                <div
                    ref={ref}
                    className={`inline-block max-h-screen bg-white rounded-lg text-start ${overflowHidden ? 'overflow-hidden' : 'overflow-y-auto'} shadow-xl transform transition-all my-8 align-middle w-full ${maxWidth} `}>

                    {children}

                </div>
            </div>
        </div>
    )
}


export default BaseModal



