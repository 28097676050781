import {phone} from 'phone';

export default class MobileValidation {

  private mobile 

  constructor(mobile){
    this.mobile = phone(mobile) 
  }
  
  isValid() {
  return  this.mobile.isValid
  }
  values(){
    this.mobile 
  }

  get countryIso3(){
    return this.mobile.countryIso3
  }


}