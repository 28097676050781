import { trackViaDataLayer } from './dataLayer'
import { transformKeysToSnakeCase } from '../helper/Object'
import isFunction from 'lodash/isFunction'

window.trackActivity = function ({ event = '', rawPayload = {}, redirectUrl = '', shouldRedirect = true, processPayload = true, callback = null } = {}) {
  try {
    // This way we can add many implementations other than DataLayer (if needed)
    // We can also enable/disable this feature by checking a .env flag or something
    if (window.isDataLayerTrackingEnabled) {
      rawPayload = { ...rawPayload, tracking_meta_data: window.trackingMetaData }
      rawPayload = transformKeysToSnakeCase(rawPayload)
      trackViaDataLayer({ event, rawPayload, redirectUrl, shouldRedirect, processPayload, callback })
    } else {
      continueAnyWay({ shouldRedirect, redirectUrl, callback })
    }
  } catch (error) {
    continueAnyWay({ shouldRedirect, redirectUrl, callback })
  }
}

function continueAnyWay({ shouldRedirect = true, redirectUrl = '', callback = null }) {
  if (callback && isFunction(callback)) {
    callback()
  }

  if (shouldRedirect && redirectUrl != '') {
    window.location.href = redirectUrl
  }
}
