import produce from 'immer';

import {UploadActionTypes} from './upload.types';
const INITIAL_STATE = {
  uploads: {},
};

const uploadReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UploadActionTypes.ADD_UPLOAD:
        draft['uploads'][action.data.uploadKey] = {
          progress: 0,
          status: 'preparing',
          uploading: false,
          ...action.data,
        };
        break;
      case UploadActionTypes.REMOVE_UPLOAD:
        draft['uploads'][action.data.uploadKey] = undefined;
        break;
      case UploadActionTypes.UPDATE_UPLOAD_PROGRESS:
        draft['uploads'][action.data.uploadKey] = {
          ...draft['uploads'][action.data.uploadKey],
          ...action.data,
        };
        break;
      case UploadActionTypes.UPDATE_UPLOAD_METADATA:
        draft['uploads'][action.data.uploadKey] = {
          ...draft['uploads'][action.data.uploadKey],
          ...action.data,
        };
        break;
      case UploadActionTypes.UPLOAD_FAILED:
        draft['uploads'][action.data.uploadKey]['status'] = 'failed';
        draft['uploads'][action.data.uploadKey]['progress'] = 0;
        draft['uploads'][action.data.uploadKey]['uploading'] = false;
        draft['uploads'][action.data.uploadKey]['error'] = action.data.error;
        break;
      case UploadActionTypes.UPLOAD_COMPLETED:
        draft['uploads'][action.data.uploadKey]['status'] = 'completed';
        draft['uploads'][action.data.uploadKey]['progress'] = 0;
        draft['uploads'][action.data.uploadKey]['uploading'] = false;
        draft['uploads'][action.data.uploadKey]['result'] = action.data.result;
        break;
      default:
        break;
    }
  });

export default uploadReducer;
