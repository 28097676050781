import React, { FC } from 'react'
import { socialItemProps } from "../../../components/AuthenticationContainer";

interface AuthSocialProps {
  socialLinks: socialItemProps[]
}


const AuthSocial: FC<AuthSocialProps> = ({socialLinks,...props})=> {

  return (
    <div className="flex my-2 space-s-2">
      {socialLinks.map(socialLink=> {
        return (
          <form key={socialLink.key} action={socialLink.route} method="POST" className="w-full cursor-pointer">
            <input type="hidden" name="authenticity_token" value={document.querySelector("meta[name='csrf-token']").getAttribute("content")} autoComplete="off"/>
            <button
              className="flex flex-col  items-center justify-items-center p-2 w-full  inline-flex  border border-coolGray-200 rounded-md bg-white text-base leading-6 font-normal text-coolGray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
              <div className="mb-2.5">
                <img src={socialLink.icon} alt={socialLink.title} className='w-5 h-5' />
              </div>
              <div className="text-sm leading-0 text-normal text-coolGray-600">
                <span className="block">{socialLink.title}</span>
              </div>

            </button>
          </form>
        )
      })}
    </div>
  )
}

export default AuthSocial
