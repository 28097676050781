import React from 'react'

const ImageIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.666667 0H11.3333C11.7015 0 12 0.298477 12 0.666667V11.3333C12 11.7015 11.7015 12 11.3333 12H0.666667C0.298477 12 0 11.7015 0 11.3333V0.666667C0 0.298477 0.298477 0 0.666667 0ZM1.33333 5.4L2.66667 4.06667L6.33333 7.73333L8.66667 5.4L10.6667 7.4V1.33333H1.33333V5.4ZM7.33333 3.66667C7.33333 4.21895 7.78105 4.66667 8.33333 4.66667C8.88562 4.66667 9.33333 4.21895 9.33333 3.66667C9.33333 3.11438 8.88562 2.66667 8.33333 2.66667C7.78105 2.66667 7.33333 3.11438 7.33333 3.66667Z"/>

    </svg>




);
}


export default ImageIcon