import React, { useEffect, lazy, useRef, useState, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
import Translation from '../src/helper/Translation'
import EventDispatcher from "../src/helper/EventDispatcher"
import Loader from "../src/Components/icon/Loader";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Button from "../src/Components/Button";
import Input from "../src/Components/Input"
import 'remixicon/fonts/remixicon.css'
import { Posting } from "@shobbak/react-services/dist/Entities";
import { Community } from "../entities";
import CommunityBadge from './CommunityBadge'


const SendOffer = ({ locale, token, postingImage, altImage, userCanSendOfferAgain, offerId, community }: {community?: Community}) => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const steps = ['offerStepsModal', 'OfferFormBeforeSubmit', 'SuccessMessage', 'FailedStatus']
  const [currentStep, setCurrentStep] = useState('');
  const [offer, setOffer] = useState({})
  const [postingId, setPostingId] = useState('')
  const [posting, setPosting] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    EventDispatcher.on('open-send-offer-modal', async ({ detail }) => {
      setPostingId(detail.postingId)
      BackendCall.i({ token, locale }).exploreShow(detail.postingId).then((response) => {
        if (response?.offerMeta.canSendOffer || userCanSendOfferAgain) {
          setCurrentStep(steps[0])
        } else {
          setCurrentStep(steps[3])  // 3 is FailedStatus
        }
        setPosting(response)
        setIsModalOpen(true)
      })
    })


    EventDispatcher.on('buy-now-action', ({ detail }) => {
      BackendCall
        .i({ token, locale })
        .createPostingOffers({
          postingId: detail.postingId,
        }).then(response => {
          window.location.href = `/my_offers/${response.id}`
        })
    })
  }, [])

  const createPostingOffer = (amount) => {
    setIsLoading(true)
    // if (offerId) {
    //   BackendCall.i({ token, locale })
    //     .updatePostingOffers(offerId, { amount })
    //     .then((offer) => {
    //       setOffer(offer)
    //       window.trackActivity({ event: 'post_offer_submitted', rawPayload: { ...offer, ...posting, offer_created_at: offer.createdAt }, shouldRedirect: false })
    //       navigateTonNext()
    //       setIsLoading(false)
    //     }).catch(error => {
    //       setError(error?.errors[0]?.detail)
    //       setCurrentStep(steps[3]) // 3 is FailedStatus
    //       setIsLoading(false)
    //     })
    // } else {
      BackendCall.i({ token, locale })
        .createPostingOffers({
          postingId: posting.id,
          amount: amount
        })
        .then((offer) => {
          setOffer(offer)
          window.trackActivity({ event: 'post_offer_submitted', rawPayload: { ...offer, ...posting, offer_created_at: offer.createdAt }, shouldRedirect: false })
          navigateTonNext()
          setIsLoading(false)
        }).catch(error => {
          setError(error?.errors[0]?.detail)
          setCurrentStep(steps[3]) // 3 is FailedStatus
          setIsLoading(false)
        })
    // }

  }

  const reload = () => {
    window.location.reload()
  }

  const navigateTonNext = () => {
    if (!currentStep) {
      window.location.reload()
    }
    let currentIndex = steps.indexOf(currentStep)

    if (currentIndex == steps.length) {
      window.location.reload()
    }

    setCurrentStep(steps[currentIndex + 1])
  }

  if (!isModalOpen) return (<></>)
  return (
    <Suspense fallback={Loader()}>
      <BaseModal onClose={reload}>
        <div>
          {currentStep == 'offerStepsModal' && (
            <OfferStepModal locale={locale} onClose={reload} onSubmit={navigateTonNext} token={token} />
          )}

          {currentStep == 'SuccessMessage' && (
            <SuccessMessage onClose={reload} offer={offer} />
          )}

          {currentStep == 'FailedStatus' && (
            <FailedStatus posting={posting} errorMessage={error} postingImage={postingImage} altImage={altImage} community={community} />
          )}
          {currentStep == 'OfferFormBeforeSubmit' && (
            <OfferForm
              posting={posting}
              postingImage={postingImage}
              altImage={altImage}
              locale={locale}
              token={token}
              deliveryOptions={posting.deliveryOptions}
              onSubmit={(value) => {
                createPostingOffer(value)
              }}
              isLoading={isLoading}
              offerMeta={posting.offerMeta}
              postingId={postingId}
              onClose={reload}
              community={community} />
          )}
        </div>

      </BaseModal>
    </Suspense>
  )
}

const OfferForm = ({ posting, postingId, offerMeta, isLoading, onClose, onSubmit, deliveryOptions, token, locale, postingImage, altImage, community }: { posting: Posting, community?: Community }) => {
  const [offerAmount, setOfferAmount] = useState(0)
  const [steps, setSteps] = useState([])
  useEffect(() => {
    BackendCall.i({ token, locale }).getOfferSteps().then((stepsRes) => {
      setSteps(stepsRes)
    })
  }, [])

  return (
    <div>
      <div className={`flex justify-between p-6 pb-8`}>
        <h2
          className={`text-2xl leading-8 font-medium text-coolGray-900`}>{Translation.t('buttons.make_offer_button')}</h2>
        <i className={`ri-close-line text-coolGray-400 cursor-pointer`} onClick={onClose} />
      </div>

      <div className={`overflow-y-auto pb-10`} style={{ height: '80vh' }}>
        <div className="px-4 pb-4">
          <div className="flex items-center space-x-4 p-2 mb-2 rounded-lg bg-emerald-50">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.621633 6.7631C0.186634 6.6181 0.182467 6.38394 0.629967 6.23477L16.5358 0.933103C16.9766 0.786437 17.2291 1.0331 17.1058 1.46477L12.5608 17.3698C12.4358 17.8106 12.1816 17.8256 11.995 17.4073L8.99997 10.6673L14 4.0006L7.3333 9.0006L0.621633 6.7631Z" fill="#10B981" />
            </svg>
            <div>
              <p className="font-bold text-emerald-500 text-start">{Translation.t('texts.send_new_offer_tries_count_title')}</p>
              <p className="text-sm text-emerald-500 text-start">{Translation.t('texts.send_new_offer_tries_count_description', { count: 5 })}</p>
            </div>
          </div>
          {/* post info */}
          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="me-2">
                <img className="h-20 w-20 rounded-lg" src={postingImage ? postingImage : altImage} />
              </div>
              <div className="flex flex-col justify-between items-start">
                <p className="text-coolGray-900 text-start">{posting?.title}</p>
                <div className="flex space-x-4 items-end">
                  <p className="text-coolGray-400 text-start">{Translation.t('texts.posted_in')}</p>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.50065H13.1666V0.833984H11.5V2.50065H6.49996V0.833984H4.83329V2.50065H1.49996C1.03972 2.50065 0.666626 2.87375 0.666626 3.33398V7.50065H17.3333V3.33398C17.3333 2.87375 16.9602 2.50065 16.5 2.50065ZM17.3333 9.16732H0.666626V16.6673C0.666626 17.1276 1.03972 17.5007 1.49996 17.5007H16.5C16.9602 17.5007 17.3333 17.1276 17.3333 16.6673V9.16732Z" fill="#9CA3AF" />
                  </svg>
                  <p className="text-coolGray-600 text-start">{(new Date(posting.createdAt)).toLocaleString()}</p>
                </div>
                {community && <CommunityBadge community={community} />}
              </div>
            </div>
          </div>
          <div className="p-4 rounded border border-dashed">
            <div className={`w-full`}>
              <Input
                prefix={offerMeta.totalAmount.currencyIso}
                placeholder={Translation.t('inputs.price.label')}
                containerStyle="mb-4 w-full"
                value={offerAmount}
                onChange={(event) => {
                  setOfferAmount(event.target.value);
                }}
              />
            </div>
            <div>
              <Button containerStyle={`w-full ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`} onPress={() => {
                onSubmit(offerAmount)
              }} label={Translation.t('buttons.make_offer_button')} />
            </div>
          </div>
          {/* <div>
            <h2 className={`text-lg leading-6 font-semibold text-coolGray-900`}>{Translation.t('steps.delivery')}</h2>
            <div className="flex flex-col space-y-2 mt-4">
              {deliveryOptions.map((option) => {
                return (
                  <div key={option.id}
                    className="md:p-4 p-2 border shadow-sm rounded-lg flex justify-between items-center gap-2 border-md">
                    <div className=" flex justify-start items-center gap-2">
                      <div
                        className="p-2 bg-coolGray-50 flex justify-center items-center flex-shrink-0 w-12 h-12 rounded-full">
                        <img src={option.icon} alt={option.name} />
                      </div>
                      <div className="flex flex-col -mt-0.5 space-y-1">
                        <span className="text-sm text-coolGray-900 leading-0 font-medium">{option.name}</span>
                        <p className="text-sm leading-5 font-normal text-coolGray-500">{option.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}
        </div>
        <div className={"flex flex-col gap-6 px-6 pb-4"}>
          {steps.map((step) => {
            return (
              <div className={`flex items-start gap-3`} key={step.id}>
                <div className={`rounded-md flex items-center flex-shrink-0 justify-center w-12 h-12`}>
                  <i className={`ri-${step.iconName} ri-2x text-gray-500`} />
                </div>
                <div className={'flex flex-col gap-1'}>
                  <h2 className={`text-base leading-6 font-semibold text-coolGray-800 text-start`}>{step.name}</h2>
                  <p className={`text-sm leading-5 font-normal text-coolGray-600 text-start`}>{step.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

const SuccessMessage = ({ offer, onClose }) => {
  return (
    <div className={`p-6`}>
      <div className={`flex justify-between pb-10`}>
        <h2
          className={`text-2xl leading-8 font-medium text-coolGray-900`}>{Translation.t('buttons.make_offer_button')}</h2>
        <i className={`ri-close-line text-coolGray-400 cursor-pointer`} onClick={onClose} />
      </div>

      <div className={`flex flex-col gap-4 pb-10 justify-center items-center`}>
        <div className={`w-24 h-24 bg-emerald-50 flex justify-center items-center rounded-2xl`}>
          <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
              d="M1.99992 0H31.9999C32.9204 0 33.6666 0.746192 33.6666 1.66667V28.3333C33.6666 29.2538 32.9204 30 31.9999 30H1.99992C1.07944 30 0.333252 29.2538 0.333252 28.3333V1.66667C0.333252 0.746192 1.07944 0 1.99992 0ZM30.3333 7.06321L17.1199 18.8965L3.66659 7.02655V26.6665H30.3333V7.06321ZM17.1016 14.4367L4.51824 3.33333H29.5032L17.1016 14.4367Z"
              fill="#10B981" />
          </svg>
        </div>

        <div className={`flex flex-col items-center justify-center`}>
          <h2
            className={`text-center  text-lg leading-7 font-semibold text-coolGray-800`}>{Translation.t('texts.make_special_offer_to_approved_by_seller')}</h2>
          <p
            className={`text-center  text-base leading-6 font-normal text-coolGray-600`}>{Translation.t('texts.wait_for_seller_response_and_browser_more_ads')}</p>
        </div>
      </div>

      <div className={`pt-8 flex flex-col justify-center items-center gap-2`}>
        <span
          className={`text-lg leading-7 font-semibold text-coolGray-800 text-center`}>{Translation.t('texts.your_offer_price')}</span>
        <span className={`text-3xl leading-9 font-bold text-emerald-500`}>{offer.offeredAmountDetail.formatted}</span>
      </div>
    </div>
  )
}

const FailedStatus = ({ posting, onClose, token, locale, postingImage, altImage, errorMessage, community }: { posting: Posting, community?: Community }) => {
  const [steps, setSteps] = useState([])
  useEffect(() => {
    BackendCall.i({ token, locale }).getOfferSteps().then((stepsRes) => {
      setSteps(stepsRes)
    })
  }, [])

  return (
    <div>
      <div className={`flex justify-between p-6 pb-8`}>
        <h2
          className={`text-2xl leading-8 font-medium text-coolGray-900`}>{Translation.t('buttons.make_offer_button')}</h2>
        <i className={`ri-close-line text-coolGray-400 cursor-pointer`} onClick={onClose} />
      </div>

      <div className={`overflow-y-auto pb-10`} style={{ height: '80vh' }}>
        <div className="px-4 pb-4">
          {/* post info */}
          <div className="mb-4">
            <div className="flex space-x-4">
              <div>
                <img className="h-20 w-20 rounded-lg" src={postingImage ? postingImage : altImage} />
              </div>
              <div className="flex flex-col justify-between items-start">
                <p className="text-coolGray-900">{posting?.title}</p>
                <div className="flex space-x-4 items-end">
                  <p className="text-coolGray-400">{Translation.t('texts.posted_in')}</p>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.50065H13.1666V0.833984H11.5V2.50065H6.49996V0.833984H4.83329V2.50065H1.49996C1.03972 2.50065 0.666626 2.87375 0.666626 3.33398V7.50065H17.3333V3.33398C17.3333 2.87375 16.9602 2.50065 16.5 2.50065ZM17.3333 9.16732H0.666626V16.6673C0.666626 17.1276 1.03972 17.5007 1.49996 17.5007H16.5C16.9602 17.5007 17.3333 17.1276 17.3333 16.6673V9.16732Z" fill="#9CA3AF" />
                  </svg>
                  <p className="text-coolGray-600">{(new Date(posting.createdAt)).toLocaleString()}</p>
                </div>
                {community && <CommunityBadge community={community} />}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-4 border border-dashed">
            <p className="leading-6 text-4xl font-bold text-red-500 mb-1">{Translation.t('create_new_ad.error')}</p>
            <p className="leading-5 font-normal text-coolGray-500">{errorMessage ? errorMessage : Translation.t('texts.error')}</p>
          </div>
        </div>
        <div className={"flex flex-col gap-6 px-6 pb-4"}>
          {steps.map((step) => {
            return (
              <div className={`flex items-start gap-3`} key={step.id}>
                <div className={`rounded-md flex items-center flex-shrink-0 justify-center w-12 h-12`}>
                  <i className={`ri-${step.iconName} ri-2x text-gray-500`} />
                </div>
                <div className={'flex flex-col gap-1'}>
                  <h2 className={`text-base leading-6 font-semibold text-coolGray-800 text-start`}>{step.name}</h2>
                  <p className={`text-sm leading-5 font-normal text-coolGray-600 text-start`}>{step.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

const OfferStepModal = ({ locale, token, onClose, onSubmit }) => {
  const [steps, setSteps] = useState([])
  useEffect(() => {
    BackendCall.i({ token, locale }).getOfferSteps().then((stepsRes) => {
      setSteps(stepsRes)
    })
  }, [])

  return (
    <div className={`w-full flex flex-col items-center`}>
      <div className={`p-6`}>
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="42" height="42" rx="21" fill="#10B981" />
          <path
            d="M24.7985 14.7563C24.6495 14.7563 24.5395 14.8237 24.4808 14.8706C24.4068 14.9302 24.2827 15.0631 24.2827 15.287V26.7132C24.2827 26.9356 24.4056 27.0685 24.4789 27.1279C24.5523 27.1875 24.7082 27.2793 24.9197 27.2293L30.6287 25.8824C30.8659 25.8266 31.0313 25.6144 31.0313 25.3664V16.6876C31.0313 16.442 30.8676 16.23 30.6331 16.1725L24.9241 14.772C24.8796 14.761 24.8376 14.7563 24.7985 14.7563ZM24.7977 29.25C24.2421 29.25 23.7001 29.0599 23.2539 28.6982C22.6567 28.2144 22.3141 27.4907 22.3141 26.7132V15.287C22.3141 14.5049 22.6596 13.7788 23.2624 13.2948C23.8656 12.811 24.6392 12.6387 25.385 12.8214L31.0937 14.2221C32.2162 14.4974 33 15.5113 33 16.6876V25.3664C33 26.5532 32.2076 27.5694 31.0728 27.8369L25.3635 29.1838C25.1756 29.2281 24.986 29.25 24.7977 29.25Z"
            fill="white" />
          <path
            d="M17.2013 14.7563C17.1622 14.7563 17.1204 14.761 17.0759 14.772L11.3667 16.1725C11.1326 16.23 10.9687 16.442 10.9687 16.6876V25.3664C10.9687 25.6144 11.1341 25.8266 11.3711 25.8824L17.0801 27.2293C17.2928 27.2795 17.4479 27.1873 17.5211 27.1277C17.5944 27.0683 17.7174 26.9356 17.7174 26.7132V15.287C17.7174 15.0633 17.5932 14.9302 17.5192 14.8708C17.4607 14.8237 17.3507 14.7563 17.2013 14.7563ZM17.2021 29.25C17.014 29.25 16.8246 29.2281 16.6363 29.1838L10.9272 27.8369C9.79241 27.5693 9 26.5532 9 25.3664V16.6876C9 15.5113 9.78378 14.4974 10.906 14.2221L16.6152 12.8214C17.3603 12.6385 18.1346 12.8108 18.7374 13.295C19.3402 13.7788 19.6859 14.5049 19.6859 15.287V26.7132C19.6859 27.4907 19.3433 28.2142 18.7463 28.6982C18.2999 29.0599 17.7577 29.25 17.2021 29.25Z"
            fill="white" />
        </svg>
      </div>
      <div className={`flex flex-col items-center gap-4`}>
        <h2
          className={`text-xl leading-7 font-bold text-emerald-500 text-center`}>{Translation.t('texts.your_money_safe_with_shobbak')}</h2>
        <p
          className={`text-base leading-5 font-normal text-coolGray-500 text-center`}>{Translation.t('texts.how_shobbak_offering_system_work_steps')}</p>
      </div>

      <div className={'overflow-y-auto max-h-80'}>
        <div>
          <div className={"flex flex-col gap-6 px-6 py-8"}>
            {steps.map((step) => {
              return (

                <div className={`flex items-start gap-3`} key={step.id}>
                  <div className={`rounded-md flex items-center flex-shrink-0 justify-center w-12 h-12`}>
                    <i className={`ri-${step.iconName} ri-2x text-emerald-500`} />
                  </div>
                  <div className={'flex flex-col gap-1'}>
                    <h2 className={`text-base leading-6 font-semibold text-coolGray-800 text-start`}>{step.name}</h2>
                    <p className={`text-sm leading-5 font-normal text-coolGray-600 text-start`}>{step.description}</p>
                  </div>
                </div>

              )
            })}
          </div>
        </div>
      </div>

      <div className={`p-6 w-full`}>
        <Button containerStyle="w-full" onPress={onSubmit} label={Translation.t('buttons.got_it')} />
      </div>
    </div>
  );
}


export default SendOffer
