import React from 'react';
import Loader from './icon/Loader'
import 'remixicon/fonts/remixicon.css'

export type ButtonType = 'solid' | 'outline' | 'clear';
export type ButtonSize = 'xLarge' | 'large' | 'medium' | 'small';
export type ContentAlignment = 'left' | 'center' | 'right';

export type ButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  label: string;
  iconName?: string;
  disabled?: boolean;
  contentAlignment?: ContentAlignment;
  color?: string | false;
  labelColor?: string;
  onPress?: () => void;
  containerStyle?: string;
  innerContainerStyle?: string;
  loading?: boolean;
};

const sizesStyles = {
  xLarge: 'py-5',
  large: 'py-4',
  medium: 'py-3',
  small: 'py-2',
};

const labelColor = {
  solid: 'text-white',
  outline: 'text-green-500',
  clear:'text-green-500',
  disabled: 'text-coolGray-400'
};

export default function Button(props: ButtonProps) {
  const color = props.color || 'emerald-500';

  const typesStyles = {
    solid: `bg-${color}`,
    outline: `bg-transparent border border-${color}`,
    clear: 'bg-transparent',
    disabled: 'bg-coolGray-300 border border-coolGray-300 cursor-not-allowed'
  };

  let type;
  let size;
  let alignment;
  switch (props.type) {
    case 'solid':
      type = 'solid';
      break;
    case 'outline':
      type = 'outline';
      break;
    case 'clear':
      type = 'clear';
      break;

    default:
      type = 'solid';
      break;
  }

  switch (props.size) {
    case 'xLarge':
      size = 'xLarge';
      break;
    case 'large':
      size = 'large';
      break;
    case 'medium':
      size = 'medium';
      break;
    case 'small':
      size = 'small';
      break;

    default:
      size = 'medium';
      break;
  }

  switch (props.contentAlignment) {
    case 'left':
      alignment = 'content-start';
      break;

    case 'center':
      alignment = 'content-center';
      break;

    case 'right':
      alignment = 'content-start';
      break;

    default:
      alignment = 'content-center';
  }
  const getContentColor = () => {
    if (type === 'solid') {
      return props.labelColor ?? 'text-white';
    } else {
      return props.labelColor ?? `text-${color}`;
    }
  };

  return (
    <button
      disabled={props.disabled}
      className={`${props.containerStyle ? props.containerStyle : ''}`}
      onClick={() => props.disabled || (props.onPress && props.onPress())}
    >
      <div
        className={`rounded-md flex items-center justify-center px-4 ${props.innerContainerStyle} ${props.disabled ? typesStyles.disabled : typesStyles[type]} ${sizesStyles[size]} ${alignment} ${props.contentAlignment === 'right' ? 'flex-row-reverse' : 'flex-row'}`}
      >
        {props.loading ? (
          <svg className="animate-spin  h-5 w-5 text-emerald-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
              <path className="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
        ) : (
          <>
            {props.iconName && (
              <i
                className={`${props.iconName} ${props.disabled? 'text-coolGray-400': getContentColor()} ${size === 'small' ? 'ri-xl' : 'ri-2xl'}`}
              />
            )}
            {props.iconName && <div className="w-2"></div>}
            <div
              className="overflow-hidden w-full items-center justify-center"
            >
              <p
                className={`overflow-hidden ${props.contentAlignment || 'text-center'} ${props.disabled ? labelColor['disabled'] : getContentColor()}`}
              >
                {props.label}
              </p>
            </div>
          </>
        )}
      </div>
    </button>
  );
}
