import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import AuctionTitleWithDescription from '../AuctionTitleWithDescription';
import Button from '../../Button'
import Translation from '../../../helper/Translation'
import { toast } from 'react-toastify'
import TimerContainer from '../TimerContainer';

type Props = {
  auction: PostingAuction;
};

function AwardedToMeScenario({auction}: Props) {
	return (
    <>
      {/* start timer container */}
      <div className="flex items-center">
        <p className="text-lg font-bold text-coolGray-700 flex-1">{Translation.t("auctions.labels.live_auction_fire")}</p>
        <TimerContainer
          auction={auction}
          containerStyle="px-1.5 py-2"
        />
      </div>
      {/* end timer container  */}


      <hr className="mt-4" />


      <AuctionTitleWithDescription 
        title={auction?.statusTitle} 
        description={auction?.statusDescription} 
      />

      <Button
        label={Translation.t('buttons.continue')}
        containerStyle="w-full"
        onPress={() => {
          try{      
            if(!auction?.postingOfferId) throw { message: Translation.t('texts.offer_not_found') }; 

            window.location.href = `/my_offers/${auction?.postingOfferId}`
          }catch(e: any){
            toast.error(e.message);
          }
        }}
      />
    </>
  );
}

export default AwardedToMeScenario;