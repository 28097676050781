import React from 'react'

export default function ShobbakLockIcon() {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69 36.17V20.36a5.11 5.11 0 0 0-3.91-5L39.17 9.12a5.11 5.11 0 0 0-6.31 5V64a5.11 5.11 0 0 0 6.31 5l25.93-6.33a5.11 5.11 0 0 0 3.91-5v-2" stroke="#FFD836" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M69 41.71a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.56a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm0 3.57a1 1 0 0 0 0-1.92 1 1 0 0 0 0 1.92Zm-48.47-41.7 2.14-7 .31-1c.23-.73-.92-1-1.14-.32l-2.14 7-.31 1c-.23.73.91 1 1.14.32ZM2.29 11.8 9 14.68l.94.4a.61.61 0 0 0 .81-.21.589.589 0 0 0-.21-.81l-6.71-2.88-.94-.41a.63.63 0 0 0-.81.21.61.61 0 0 0 .21.82Z" fill="#10B981"/>
      <path d="m13.82 10.38-2.23-6.94-.32-1C11 1.72 9.9 2 10.13 2.76l2.23 6.94.31 1c.24.72 1.38.41 1.15-.31v-.01Z" fill="#FFD836"/>
      <path d="m54.9 45.15.19-4.23a.49.49 0 0 1 .81-.38l3.21 2.53a.56.56 0 0 1 0 .93l-3.4 1.72a.56.56 0 0 1-.81-.57Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M57 42.55s5 5.34 1.57 10.23c-3.13 4.41-7.82 2.71-8.7 2.34a.18.18 0 0 1-.1-.22l.53-2.26a.18.18 0 0 1 .22-.14c1.4.28 10.54 1.82 6.43-8.47l.05-1.48Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="m54.9 45.15.19-4.23a.49.49 0 0 1 .81-.38l3.21 2.53a.56.56 0 0 1 0 .93l-3.4 1.72a.56.56 0 0 1-.81-.57Z" fill="#C2E9DB" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="m15.1 28-1 4.13a.5.5 0 0 1-.87.22l-2.69-3.08a.558.558 0 0 1 .22-.91l3.65-1a.552.552 0 0 1 .69.64Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M13.56 30.5s-4-6.19.35-10.35c3.9-3.75 8.19-1.21 9-.68a.18.18 0 0 1 .07.23L22 21.82a.18.18 0 0 1-.24.11c-1.32-.55-10-3.75-7.89 7.12l-.31 1.45Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="m15.1 28-1 4.13a.49.49 0 0 1-.87.21l-2.69-3.07a.57.57 0 0 1 .22-.92l3.65-1a.56.56 0 0 1 .69.65Z" fill="#C2E9DB" stroke="#10B981" strokeWidth=".75" strokeMiterlimit="10"/>
      <path d="M32.43 58.17s-.2-.12-.22-.4c-.02-.28.13-.55.22-.56l2.8-.41-1.91-1.44c-2.64-2-5.84-5.5-6.91-9.38-2-7.1-1.89-15.35-1.76-18.56a2.13 2.13 0 0 1 .94-1.78l7.91-4.38-.15-1.14 3.53-.71h.34c.188 0 .374.047.54.14l11.14 6.09a2.119 2.119 0 0 1 1 1.78c.13 3.19.2 11.38-1.74 18.47-1.57 5.77-7.73 10.7-10.43 11.35h-.22l-.17.14-4.91.79Z" fill="#fff"/>
      <path d="M37.27 20.35h.06l11.09 6.15a1.1 1.1 0 0 1 .43.94c.12 3.15.2 11.22-1.7 18.17-1.43 5.22-7.15 10-9.71 10.64l-.42.1-.18.15h-.27l-2.65-2c-2.5-1.89-5.54-5.21-6.55-8.85-1.92-7-1.84-15.08-1.72-18.25a1.1 1.1 0 0 1 .43-.94l7.31-4.06 1.2-.66-.12-.89L37 20.4h.24l.03-.05Zm-.03-1.95h-.31a1.65 1.65 0 0 0-.32.06l-4.41.88.19 1.39-7.31 4.06a3 3 0 0 0-1.46 2.61c-.13 3.27-.2 11.64 1.8 18.86 1.1 4 4.33 7.7 7.27 9.92l-.44.06a1.45 1.45 0 0 0-1.07 1.65 1.34 1.34 0 0 0 1.22 1.3h.15l4.82-.71a1 1 0 0 0 .51-.24c3.14-.76 9.54-6.08 11.17-12.05 2-7.22 1.9-15.54 1.77-18.78a3 3 0 0 0-1.46-2.61L38.3 18.62a2.141 2.141 0 0 0-1-.27l-.06.05Z" fill="#10B981"/>
      <path d="m20.37 26.74 11.5-6.31a1.17 1.17 0 0 1 1.12 0l11.5 6.31a1.62 1.62 0 0 1 .77 1.37c.11 2.85.24 11.44-1.78 18.72-1.7 6.18-8.87 11.37-11 11.42-2.13.05-9.32-5.19-11-11.33-2-7.31-1.91-16-1.8-18.81a1.62 1.62 0 0 1 .69-1.37Z" fill="#fff" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M29.65 35.64s-.83-6 2.6-5.95c3.81 0 2.73 6.85 2.73 6.85l-5.33-.9Z" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="m27.32 35.21 9.89 1.71a1.16 1.16 0 0 1 1 1.27V45a1.16 1.16 0 0 1-1 1.27l-9.89-1.71a1.14 1.14 0 0 1-1-1.26v-6.82a1.16 1.16 0 0 1 1-1.27Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="m32.54 38.46-.21 4.51m2.16-2.19-4.11-.12m3.58-1.49-3.05 3.1m2.91.09-2.77-3.28" stroke="#fff" strokeWidth=".89" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="m49 26-4.49.76L33 20.43l3.68-1.16a2.16 2.16 0 0 1 1.77.2L49 25.74a.13.13 0 1 1 0 .26Z" fill="#15B880" stroke="#10B981" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M48.48 38.42a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.129a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-.56-1.699a.23.23 0 1 1 0 .459.23.23 0 0 1 0-.46Zm0 1.15a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm0 1.11a.218.218 0 0 1 .206.143c.01.028.016.058.014.087a.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm1.13-4.51a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-.57.56a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.219.219 0 0 1 .133-.213.222.222 0 0 1 .087-.018Zm0 1.13a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.218.218 0 0 1 .133-.212.218.218 0 0 1 .087-.018Zm-.56-1.69a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Z" fill="#10B981"/>
      <path d="M47.92 37.85a.23.23 0 1 1 0 .459.23.23 0 0 1 0-.46Zm1.13-4.51a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-.57-.57a.23.23 0 0 1 .23.23.23.23 0 1 1-.45 0 .22.22 0 0 1 .22-.23Zm0 1.1a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.218.218 0 0 1 .133-.213.22.22 0 0 1 .087-.018Zm-.56-1.66a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.13-4.52a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.229.229 0 0 1-.256-.127.229.229 0 0 1 .154-.322.23.23 0 0 1 .101-.002Zm0 1.13a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-.57-.56a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.12a.23.23 0 0 1 .176.388.23.23 0 1 1-.176-.388Zm-.56-1.69a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.229.229 0 0 1-.255-.127.229.229 0 0 1 .154-.322.23.23 0 0 1 .101-.002Zm0 1.13a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Z" fill="#10B981"/>
      <path d="M49.05 33.34a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.13 4.51a.23.23 0 1 1 0 .46.23.23 0 0 1 0-.46Zm1.13 0a.23.23 0 1 1 0 .459.23.23 0 0 1 0-.46ZM46.79 39a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-.57-.58a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.13 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm1.13 1.129a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.13 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm.57.561a.218.218 0 0 1 .206.143c.01.028.015.058.013.087a.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm1.13 0a.218.218 0 0 1 .206.143c.01.028.016.058.014.087a.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.13-4.51a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.13 1.13A.22.22 0 0 1 47 37a.23.23 0 1 1-.22-.22l.01-.05Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-.57-.57a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.222.222 0 0 1 .133-.213.221.221 0 0 1 .086-.018Zm-1.13 0a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.222.222 0 0 1 .134-.213.221.221 0 0 1 .086-.018Zm1.13 1.13a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.222.222 0 0 1 .133-.212.218.218 0 0 1 .086-.018Zm-1.13 0a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.222.222 0 0 1 .134-.212.218.218 0 0 1 .086-.018Z" fill="#10B981"/>
      <path d="M46.79 37.85a.23.23 0 1 1 0 .46.23.23 0 0 1 0-.46Zm1.13 0a.23.23 0 1 1 0 .459.23.23 0 0 1 0-.46Zm-1.13-4.51a.22.22 0 0 1 .22.22.23.23 0 0 1-.272.236.231.231 0 0 1-.168-.319.23.23 0 0 1 .22-.137Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.13 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-.57-.57a.23.23 0 0 1 .23.23.23.23 0 1 1-.45 0 .219.219 0 0 1 .133-.212.222.222 0 0 1 .087-.018Zm-1.13 0a.23.23 0 0 1 .23.23.23.23 0 1 1-.45 0 .222.222 0 0 1 .133-.212.221.221 0 0 1 .086-.018Zm1.13 1.1a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.221.221 0 0 1 .22-.23Zm-1.13 0a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.221.221 0 0 1 .22-.23Zm.57.6a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.13-4.52a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.229.229 0 0 1-.256-.127.23.23 0 0 1 .255-.324Zm1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.229.229 0 0 1-.255-.127.229.229 0 0 1 .154-.322.23.23 0 0 1 .101-.002Zm-1.13 1.13a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-.57-.56a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.13 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm1.13 1.12a.23.23 0 1 1 .02.46.23.23 0 0 1-.02-.46Zm-1.13 0a.23.23 0 1 1 .02.46.23.23 0 0 1-.02-.46Z" fill="#10B981"/>
      <path d="M46.79 33.34a.22.22 0 0 1 .22.22.23.23 0 0 1-.272.236.231.231 0 0 1-.168-.319.23.23 0 0 1 .22-.137Zm1.13 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm.5-5.09a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.13a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm.58-.57a.221.221 0 0 1 .207.142.22.22 0 0 1 .013.088.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.14 0a.222.222 0 0 1 .206.142c.01.028.015.058.014.088a.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45ZM49 29.94a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.14 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-.57-1.69a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.13a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm1.13 1.13a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.13 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22ZM49 27.68a.221.221 0 0 1 .207.143.22.22 0 0 1 .013.088.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.14 0a.222.222 0 0 1 .206.143c.01.028.015.058.014.088a.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Z" fill="#10B981"/>
      <path d="M48.42 28.25a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.13 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.12 0a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Z" fill="#10B981"/>
      <path d="M47.29 28.25a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.12 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.12 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-.56-.57a.23.23 0 1 1 0 .45.23.23 0 1 1 0-.45Zm0 1.13a.23.23 0 1 1 0 .45.23.23 0 1 1 0-.45Zm-.56.57a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm1.12 0a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-.56-2.83a.229.229 0 0 1 .256.127.229.229 0 0 1-.154.322.23.23 0 0 1-.101.002.23.23 0 1 1 0-.45Zm-.56.57a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Z" fill="#10B981"/>
      <path d="M47.29 28.25a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-1.12 15.11a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm0-1.12a.23.23 0 0 1-.23-.23.23.23 0 1 1 .45 0 .23.23 0 0 1-.22.23Zm.56.56a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm0-1.13a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm.56 1.69a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm0-1.12a.23.23 0 0 1-.22-.23.229.229 0 0 1 .224-.278.23.23 0 0 1 .226.278.23.23 0 0 1-.23.23Zm-1.12-1.13a.23.23 0 0 1-.23-.23.23.23 0 1 1 .45 0 .22.22 0 0 1-.22.23Zm1.12 0a.22.22 0 0 1-.22-.23.229.229 0 0 1 .224-.278.229.229 0 0 1 .224.176.23.23 0 0 1 .002.101.23.23 0 0 1-.23.23Zm-1.12 4.51a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm0-1.13a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm.56.57a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm0-1.13a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm.56 1.69a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm0-1.13a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Z" fill="#10B981"/>
      <path d="M46.17 43.36a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm1.12 0a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm-1.12 4.52a.23.23 0 1 1 0-.45.22.22 0 0 1 .22.22.221.221 0 0 1-.22.23Zm0-1.13a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm.56.57a.23.23 0 1 1 .23-.23.24.24 0 0 1-.23.23Zm0-1.13a.23.23 0 0 1-.23-.19.23.23 0 0 1 .23-.23.239.239 0 0 1 .27.23.23.23 0 0 1-.27.19Zm.56.56a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm-1.12-1.13a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm1.12 0a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Z" fill="#10B981"/>
      <path d="M46.17 47.88a.23.23 0 1 1 0-.45.22.22 0 0 1 .22.22.221.221 0 0 1-.22.23Zm1.12-4.52a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm0-1.12a.23.23 0 0 1-.22-.23.229.229 0 0 1 .224-.278.23.23 0 0 1 .226.278.23.23 0 0 1-.23.23Zm.57.56a.23.23 0 1 1 0-.45.22.22 0 0 1 .22.22.219.219 0 0 1-.134.213.222.222 0 0 1-.086.018Zm0-1.13a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm.56-.56a.22.22 0 0 1-.22-.23.23.23 0 1 1 .45 0 .23.23 0 0 1-.23.23Zm-1.13 0a.22.22 0 0 1-.22-.23.229.229 0 0 1 .224-.278.229.229 0 0 1 .224.176.23.23 0 0 1 .002.101.23.23 0 0 1-.23.23Zm0 4.51a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm0-1.13a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm.57-.56a.23.23 0 1 1 0-.45.22.22 0 0 1 .22.22.219.219 0 0 1-.134.212.222.222 0 0 1-.086.018Z" fill="#10B981"/>
      <path d="M47.29 43.36a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm0 2.26a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm-1.13-18.05a.218.218 0 0 1-.206-.143.218.218 0 0 1-.014-.087.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm1.13 0a.218.218 0 0 1-.206-.143.222.222 0 0 1-.014-.087.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm1.13 0a.218.218 0 0 1-.207-.143.222.222 0 0 1-.014-.087.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm-3.5 19.83a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.13a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm.57-.53a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm1.13 1.09a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-.57-.56a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm1.13 1.12a.23.23 0 1 1 .02.46.23.23 0 0 1-.02-.46Zm-1.13 0a.23.23 0 1 1 .02.46.23.23 0 0 1-.02-.46Zm1.13-4.51a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0 1.13a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm.57-.57a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm0 1.13a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Zm-1.13 0a.22.22 0 0 1 .22.23.22.22 0 0 1-.22.22.23.23 0 1 1 0-.45Z" fill="#10B981"/>
      <path d="M44.92 47.4a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm0-4.52a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.222.222 0 0 1 .133-.212.221.221 0 0 1 .086-.018Zm0 1.12a.23.23 0 1 1 0 .45.22.22 0 0 1-.22-.22.221.221 0 0 1 .22-.23Zm.57-.55a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.12a.23.23 0 1 1 0 .46.23.23 0 0 1 0-.46Zm-.57.57a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm.57-3.95a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm0 1.13a.22.22 0 0 1 .22.22.23.23 0 1 1-.22-.22Zm-1.7 6.21a.23.23 0 1 1-.22.22.22.22 0 0 1 .22-.22Zm-.56.56a.23.23 0 1 1 0 .45.23.23 0 1 1 0-.45Zm.56.56a.23.23 0 1 1 .02.46.23.23 0 0 1-.02-.46ZM42 52.91a.23.23 0 1 1 0-.461.23.23 0 0 1 0 .46Zm0-1.58a.23.23 0 1 1 0 .45m.61.56a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm0-1.13a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm.56.57a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm0-1.13a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Z" fill="#10B981"/>
      <path d="M42 52.91a.23.23 0 1 1 0-.461.23.23 0 0 1 0 .46Zm1.17-1.13a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm.56-.57a.22.22 0 0 1-.22-.22.23.23 0 1 1 .22.22Zm.57-.56a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm-1.13 0a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm-3.96 5.41a.22.22 0 0 1-.22-.23.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Z" fill="#10B981"/>
      <path d="M39.21 56.06a.22.22 0 0 1-.22-.23.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm2.26-2.26a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Zm0-1.13a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Z" fill="#10B981"/>
      <path d="M39.21 56.06a.22.22 0 0 1-.22-.23.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm1.13-1.13a.222.222 0 0 1-.207-.143.219.219 0 0 1-.014-.087.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm-.56.56a.23.23 0 1 1 .22-.22.22.22 0 0 1-.22.22Zm1.13-1.12a.23.23 0 1 1-.02-.459.23.23 0 0 1 .02.459Zm.56-.57a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Z" fill="#10B981"/>
      <path d="M39.21 56.06a.22.22 0 0 1-.22-.23.22.22 0 0 1 .22-.22.23.23 0 1 1 0 .45Zm2.26-2.26a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Z" fill="#10B981"/>
      <path d="M41.47 53.8a.23.23 0 1 1 0-.45.23.23 0 1 1 0 .45Z" fill="#10B981"/>
    </svg>
  )
}
