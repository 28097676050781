import React, { lazy, Suspense } from "react";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
import Loader from "../src/Components/icon/Loader";
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))

import Translation from '../src/helper/Translation'
import 'remixicon/fonts/remixicon.css'
import ReviewsList from "./ReviewsList";

const ReviewIndex = ({ locale, token, relatedToType, relatedToId }) => {
  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('rating.reviews')}>
          <ReviewsList
            locale={locale} 
            token={token}
            relatedToType={relatedToType}
            relatedToId={relatedToId} />
        </ProfileCard>

      </ProfileLayout>
    </Suspense>
  )
}

export default ReviewIndex
