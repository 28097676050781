import React, { Suspense, useEffect, useState } from 'react'
import Translation from '../../src/helper/Translation'
import Loader from '../../src/Components/icon/Loader'
import ProfileLayout from '../../src/Components/ProfileLayout'
import ProfileCard from '../../src/Components/profile/ProfileCard'
import BackendCall from '../../src/Components/BackendCall'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import Pagination from '../../src/Components/Pagination'
import NotificationsList from './NotificationsList'
import { emitSomeNotificationsWereMarkedAsReadEvent } from './events'

export default function MyNotifications({ token, locale }) {
  const [types, setTypes] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [notifications, setNotifications] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    BackendCall.i({ token, locale })
      .getNotificationTypes()
      .then(res => {
        setTypes(res)
      })
      .catch(err => console.log({ err }))

  }, [])

  useEffect(() => {
    getNotifications({ page: 1, types: selectedTypes })
  }, [selectedTypes])

  function getNotifications({ types = [], page = 1 }) {
    BackendCall.i({ token, locale })
      .getNotifications({ filter: { notification_type_id: types }, page: { number: page } })
      .then(res => {
        setNotifications(res?.records)
        setMeta(res?.meta)
      })
      .catch(err => console.log({ err }))
  }

  function toggleType(id) {
    setSelectedTypes(prevSelectedTypes =>
      prevSelectedTypes.includes(id)
        ? prevSelectedTypes.filter(typeId => typeId !== id)
        : [...prevSelectedTypes, id]
    )
  }

  function markAllAsRead() {
    setNotifications(prevNotifications => prevNotifications.map(n => ({ ...n, readedAt: true })))
    BackendCall.i({ token, locale })
      .markAllNotificationAsRead({ filter: { notification_type_id: selectedTypes } })
      .then(() => {
        // TODO show toast
        emitSomeNotificationsWereMarkedAsReadEvent()
      })
      .catch(err => console.log({ err }))
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard>
          <div className="flex items-center justify-between mb-6">
            <h2 className={`text-2xl leading-8 font-semibold text-coolGray-700`}>{Translation.t('breadcrumb.notifications')}</h2>
            <button disabled={notifications.length == 0} onClick={() => markAllAsRead()} type="button" className={`h-8 w-8 flex items-center justify-center bg-gray-100 rounded-lg text-coolGray-500 hover:text-coolGray-700 ${notifications.length == 0 ? 'hover:cursor-not-allowed' : ''}`}>
              <i className="ri-chat-check-line ri-xl"></i>
            </button>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-2 border-b pb-4 overflow-x-auto">
              {types.map(t => {
                let typeIsSelected = selectedTypes.includes(t.id)
                return <button key={t.id} onClick={() => toggleType(t.id)} type="button" className={`${typeIsSelected ? 'text-white border-emerald-500 bg-emerald-500' : 'text-coolGray-700 border-gray-300 hover:bg-coolGray-50'} leading-6 font-normal lg:text-base text-sm border rounded-full px-2 whitespace-nowrap`}>{t.title}</button>
              })}
            </div>

            <div className="py-1 flex flex-col space-y-3" role="none">
              <NotificationsList notifications={notifications} token={token} locale={locale} />
            </div>

            {!isEmpty(meta) && notifications.length != 0 && (
              <Pagination key={meta.total_pages} {...meta} onPaginationChange={({ page }) => {
                getNotifications({ types: selectedTypes, page })
              }} />
            )}
          </div>
        </ProfileCard>
      </ProfileLayout>
    </Suspense>
  )
}
