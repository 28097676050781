import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import  DefaultButton  from "./DefaultButton";
import DefaultInput from "./DefaultInput";
import Translation from "../../helper/Translation";
import BackendCall from "../BackendCall";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";


type Props = {
  email: string;
  locale?: string;
  token?: string;
  emailConfirmation: boolean;
  onEmailChange: (data: any) => void;
  saveFirst?: boolean;
  recaptchaEnabled?: boolean;
  onVerificationSent?: () => void
}

const EditEmail = ({email ,locale,token,  emailConfirmation , onEmailChange, saveFirst = false, recaptchaEnabled = true, onVerificationSent}: Props)=> {
  const [isVerified , setIsVerified] = useState(emailConfirmation)
  const [lastSavedEmail , setLastSavedEmail] = useState(email)
  const recaptchaRef = useRef(null)

  const verify = async ()=> {
    if(isVerified) return ;
    let params = {}
    if (recaptchaEnabled) {
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    if (saveFirst && email !== lastSavedEmail) {
      BackendCall
      .i({token , locale}).usersUpdate({...params, email}).then(res=>  {
        setIsVerified(true)
        setLastSavedEmail(email)
        onVerificationSent ? onVerificationSent() : toast.success(Translation.t('texts.verification_mail_sent'))
        backOff() 
      })
    } else {
      BackendCall
      .i({token , locale}).resendVerificationForDefaultMail(params).then(res=>  {
        setIsVerified(true)
        onVerificationSent ? onVerificationSent() : toast.success(Translation.t('texts.verification_mail_sent'))
        backOff() 
      })
    }

  }

  const backOff = () => {
      setTimeout(()=> {
        if (!emailConfirmation){
          setIsVerified(false)
        }
      }, 10000)
  }

  return (
    <div className={`flex items-end gap-2 w-full`} >
      <DefaultInput containerClass={`w-full`} type={'email'} name={`email`} label={Translation.t('create_new_ad.email_address')} value={email} onValueChange={(value)=>{
        onEmailChange({ email: value })
        setIsVerified(!saveFirst)
      }}/>
      {recaptchaEnabled && <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      }
      <DefaultButton label={Translation.t('buttons.verify')} disabled={isVerified || !email} onButtonClick={()=>{ verify() }} />

    </div>
  );
}

export default EditEmail
