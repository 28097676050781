import React, { useEffect, useState } from 'react'
import Loader from "../src/Components/icon/Loader";
import { PostingOffer } from "@shobbak/react-services/dist/Entities";
import BackendCall from "../src/Components/BackendCall";
import OfferSummaryCard from "../src/Components/profile/OfferSummaryCard";
import Translation from "../src/helper/Translation";


const RecentOffers = ({postingId , token , locale})=> {
  const [isLoading , setIsLoading] = useState<Boolean>(true)
  const [offers , setOffers] = useState<PostingOffer[]>([])


  useEffect(()=> {
    BackendCall.i({token , locale})
    .getPostingOffersIndex({  filter: {posting_id: postingId} , perPage: 2 })
      .then((res)=> {
        setOffers(res.records)
        setIsLoading(false)
      })
  } , [])


  const emptyState = ()=> {
    return (
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="w-10 h-10 bg-emerald-50 flex items-center justify-center flex-shrink-0 rounded-lg">
          <i className="ri-price-tag-2-fill text-xl text-emerald-500"/>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-base leading-6 font-semibold text-coolGray-700 text-center">{Translation.t('texts.no_offers_received')}</p>
          <p className="text-sm leading-0 font-normal text-coolGray-500">{Translation.t('texts.no_offer_description')}</p>
        </div>
      </div>
    );
  }

  const displayOffers = ()=> {
    return (<div className={`flex flex-col`}>
      {offers.map((offer)=> {
        return <OfferSummaryCard key={offer.id} offer={offer} />
      })}
    </div>)
  }
  return (
    <div className="flex flex-col gap-4 md:border md:rounded-md bg-white p-4">
      <div className="flex justify-between items-center w-full">
        <h3 className="text-lg leading-6 font-semibold text-coolGray-700">{Translation.t('texts.latest_offers')}</h3>
        <div className="flex items-center justify-center gap-2 text-emerald-500">
          <span className="text-base leading-6 font-normal">{Translation.t('buttons.view_all')}</span>
          <i className="ri-arrow-right-s-line text-xl" />
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        offers.length == 0 ? emptyState() : displayOffers()
      )}



    </div>

)


}

export default RecentOffers

