import React, { FC, useEffect } from 'react'

interface LocationProviderProps {
  ip: string;
}

type trackingStatus = 'accepted' | 'rejected' | null

const LocationProvider: FC<LocationProviderProps> = ({ ip }) => {

  function locationSuccessHandler(position: GeolocationPosition) {
    const { coords: { latitude, longitude } } = position;
    fetch('/devices/location', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ lat: latitude, lon: longitude }),
    })
      .then(response => {
        if (response.status == 200) {
          window.localStorage.setItem('latestStoredIp', JSON.stringify(ip))
          window.localStorage.setItem('locationTrackingStatus', JSON.stringify('accepted'))
        }
      })
      .catch(error => {
        //
      });
  }

  function locationErrorHandler(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        window.localStorage.setItem('locationTrackingStatus', JSON.stringify('rejected'))
        break;
      case error.POSITION_UNAVAILABLE:
        //
        break;
      case error.TIMEOUT:
        //
        break;
      case error.UNKNOWN_ERROR:
      default:
        //
        break;
    }
  }

  useEffect(() => {
    const locationTrackingStatus: trackingStatus = JSON.parse(window.localStorage.getItem('locationTrackingStatus'))
    const latestStoredIp = JSON.parse(window.localStorage.getItem('latestStoredIp'))

    if (locationTrackingStatus == 'rejected') return

    if (latestStoredIp == ip) return

    if (latestStoredIp != ip && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationSuccessHandler, locationErrorHandler)
    }
  }, [])

  return null
}

export default ({ ip, baseUrl }) => {
  return <LocationProvider ip={ip} />
};
