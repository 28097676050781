export const defaultImageObject = (processedAssetUrls: any[]) => {
  if (processedAssetUrls.length > 0) {
    const image = processedAssetUrls[0];
    return {
      url:
        image.type === 'video'
          ? image.thumbnails?.gif || image.thumbnail
          : image.thumbnail,
      blurhash: image?.blurhash,
    };
  }

  return {
    url: 'https://shobbak-public.imgix.net/static/avatar.png',
  };
};
