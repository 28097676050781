import React, { useEffect, useState, useRef } from "react";
import Translation from '../../../helper/Translation'
import BaseModal from "./BaseModal";
import { UserPhoneType } from "../UserMobile";
import phone from "phone";
import { UserEmailType } from "../UserEmail";
import ModalComponent from "../../Modal";


interface SelectEmailOptionsModalType {
    onClose: () => {};
    onSubmit: () => {},
    onChangeMobile: () => {},
    selectedUserEmail: UserEmailType
    emailCommunications: any[]
}


interface optionsType {
    isPrimary: boolean;
    emailEnable: boolean;
}

const SelectEmailOptionsModal = ({
    selectedUserEmail,
    emailCommunications,
    onChangeEmail,
    onClose,
    onSubmit
}) => {

    const [emailCommunicationsOptions, setEmailCommunicationsOptions] = useState<optionsType>({
        isPrimary: false,
        emailEnable: false,

    })


    function updateState(key, value) {
        setEmailCommunicationsOptions(() => {
            return { ...emailCommunicationsOptions, [key]: value }
        })
    }

    function saveChanges() {

        const options = [];

        if (emailCommunicationsOptions.emailEnable) {
            options.push(
                {
                    id: selectedUserEmail.id,
                    communication_type: 'email'
                }
            )
        }

        onSubmit({ options, isPrimary: emailCommunicationsOptions.isPrimary, emailId: selectedUserEmail.id })
    }

    useEffect(() => {
        const initObject = {};
        initObject.isPrimary = selectedUserEmail.isPrimary;

        emailCommunications.map(email => {
            if (email.communication_type == 'email' && email.id == selectedUserEmail.id) {
                initObject.emailEnable = true
            }
            return email;
        })

        setEmailCommunicationsOptions(() => ({ ...initObject }))
    }, [selectedUserEmail, emailCommunications])

    return (
        <ModalComponent
          visible={true}
          onClose={onClose}
          body={(
            <div className="md:py-6 md:px-4  py-4 px-2">
                <div className="flex items-start">
                    <div
                        className="me-4 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-50">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M16.4993 0.5H1.49935C1.03911 0.5 0.666016 0.873096 0.666016 1.33333V14.6667C0.666016 15.1269 1.03911 15.5 1.49935 15.5H16.4993C16.9596 15.5 17.3327 15.1269 17.3327 14.6667V1.33333C17.3327 0.873096 16.9596 0.5 16.4993 0.5ZM3.70601 3.19837L9.04934 7.73587H9.05018L14.2877 3.20337L15.3777 4.4642L9.06018 9.93087L2.62684 4.46837L3.70601 3.19837Z"
                                fill="#10B981" />
                        </svg>

                    </div>
                    <div className="text-start flex-1 flex-col">
                        <p className="text-base leading-6 font-semibold text-coolGray-900">{Translation.t('create_new_ad.ad_email_availability')}</p>
                        <p className="text-sm leading-5 font-normal text-coolGray-500">{Translation.t('create_new_ad.add_email_paragraph')} </p>

                        <p className="text-sm leading-5 font-normal text-coolGray-500">{selectedUserEmail.email}
                            <span className="text-emerald-500 ms-2 cursor-pointer"
                                onClick={onChangeEmail}>{Translation.t('create_new_ad.change')}</span></p>
                    </div>
                    <div className="self-start cursor-pointer" onClick={onClose}>
                        <svg className="h-6 w-6 text-coolGray-400" xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 24 24" stroke="none">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z" />
                        </svg>
                    </div>
                </div>


                <div className="mt-4 flex flex-col items-start w-full">
                    <h2 className="text-base leading-7 font-normal text-coolGray-700 mb-2">{Translation.t('create_new_ad.options')}</h2>

                    <ul className="flex flex-col divide-y divide-coolGray-100 w-full">

                        {/* primary mobile */}
                        {!selectedUserEmail.isPrimary && (
                            <li className="py-2 flex justify-between items-center">
                                <div className="flex gap-4 items-center">
                                    <span className="p-3 bg-coolGray-100 rounded-xl">
                                        <svg width="12" height="12" viewBox="0 0 12 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6 0.666992L11.478 1.88433C11.626 1.91723 11.7584 1.99964 11.8533 2.11793C11.9482 2.23622 12 2.38334 12 2.53499V9.19299C12 9.85148 11.8373 10.4998 11.5266 11.0803C11.2158 11.6609 10.7666 12.1557 10.2187 12.521L6 15.3337L1.78133 12.521C1.23352 12.1558 0.784307 11.6611 0.473565 11.0806C0.162824 10.5002 0.000157571 9.85204 0 9.19366V2.53499C2.60252e-05 2.38334 0.0517586 2.23622 0.14666 2.11793C0.241561 1.99964 0.373958 1.91723 0.522 1.88433L6 0.666992ZM6 4.66699C5.7065 4.66701 5.4212 4.76386 5.18836 4.94254C4.95551 5.12121 4.78812 5.37173 4.71216 5.65523C4.63619 5.93873 4.65589 6.23937 4.76821 6.51054C4.88052 6.7817 5.07916 7.00823 5.33333 7.15499V10.0003H6.66667L6.66733 7.15499C6.92156 7.0082 7.12023 6.78161 7.23252 6.51038C7.34482 6.23915 7.36447 5.93844 7.28842 5.65491C7.21237 5.37137 7.04487 5.12086 6.81192 4.94223C6.57896 4.76361 6.29356 4.66686 6 4.66699Z"
                                                fill="#374151" />
                                        </svg>
                                    </span>

                                    <div className="flex flex-col items-start">
                                        <span
                                            className="text-base text-coolGray-700 leading-6 font-normal">{Translation.t('create_new_ad.make_as_a_primary')}</span>
                                        <span
                                            className="text-sm leading-5 font-normal text-coolGray-400">{Translation.t('create_new_ad.mark_email_as_primary')}</span>
                                    </div>

                                </div>


                                <div>
                                    <input id="" checked={emailCommunicationsOptions.isPrimary} onChange={(e) => {
                                        updateState('isPrimary', e.target.checked)
                                    }} name="push-notifications"
                                        type="checkbox"
                                        className="focus:ring-emerald-500 rounded-sm h-4 w-4 text-emerald-600 border-gray-300" />
                                </div>
                            </li>
                        )}
                        {/* enable mobile calling  */}
                        <li className="py-2  flex justify-between items-center">
                            <div className="flex gap-4 items-center">
                                <span className="p-3 bg-coolGray-100 rounded-xl">
                                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M13.0007 0H1.00065C0.632461 0 0.333984 0.298477 0.333984 0.666667V11.3333C0.333984 11.7015 0.632461 12 1.00065 12H13.0007C13.3688 12 13.6673 11.7015 13.6673 11.3333V0.666667C13.6673 0.298477 13.3688 0 13.0007 0ZM2.76598 2.15874L7.04064 5.78874H7.04131L11.2313 2.16274L12.1033 3.1714L7.04931 7.54474L1.90264 3.17474L2.76598 2.15874Z"
                                            fill="#111827" />
                                    </svg>


                                </span>

                                <div className="flex flex-col items-start">
                                    <span
                                        className="text-base text-coolGray-700 leading-6 font-normal">{Translation.t('create_new_ad.open_for_emails')}</span>
                                    <span
                                        className="text-sm leading-5 font-normal text-coolGray-400">{Translation.t('create_new_ad.mark_email_as_primary')}</span>
                                </div>

                            </div>


                            <div>
                                <input id="" checked={emailCommunicationsOptions.emailEnable} onChange={(e) => {
                                    updateState('emailEnable', e.target.checked)
                                }} name="push-notifications"
                                    type="checkbox"
                                    className="focus:ring-emerald-500 rounded-sm h-4 w-4 text-emerald-600 border-gray-300" />
                            </div>
                        </li>


                    </ul>
                </div>


                <input type="submit" name="commit"
                    onClick={saveChanges}
                    value={Translation.t('create_new_ad.save_changes')}
                    className={`mt-4 ${false ? 'cursor-not-allowed' : 'cursor-pointer'} inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm`}
                    data-disable-with="Login" />

            </div>
          )}
        />
    )
}


export default SelectEmailOptionsModal;