import { BusinessTypes } from '@shobbak/react-services/dist/Entities';
import React, { FC, forwardRef, useImperativeHandle, useState, useEffect, Dispatch } from 'react'
import isEmpty from 'lodash/isEmpty'
import Input from '../../Input'
import MapWithDraggableMarker from '../../Maps/MapWithDraggableMarker'
import { BusinessProfileState } from '../../../../components/CreateBusinessProfile'
import Translation from "../../../helper/Translation"

interface AboutBusinessProps {
  state: BusinessProfileState
  dispatch: Dispatch<any>;
  googleMapsApiKey: string;
  businessTypes: BusinessTypes;
}

const AboutBusiness: FC<AboutBusinessProps> = forwardRef(({ state, dispatch, googleMapsApiKey, businessTypes }, ref) => {
  const [errorBag, setErrorBag] = useState({})

  useImperativeHandle(ref, () => ({
    validateStep() {
      return new Promise((resolve, reject) => {
        if (!isEmpty(state.name) && !isEmpty(state.business_type)) {
          return resolve(true)
        }

        let errors = {}

        if (isEmpty(state.name)) {
          errors.name = Translation.t('errors.messages.required_field', { field: Translation.t('texts.business.business_name.label') })
        }

        if (isEmpty(state.business_type)) {
          errors.business_type = Translation.t('errors.messages.required_field', { field: Translation.t('texts.business.business_type') })
        }

        setErrorBag(errors)
        return reject(errors)
      })
    }
  }))

  return (
    <div>
      {/* About Business Section */}
      <div className="mb-4">
        <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.about_business.label')}</h3>
        <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.about_your_business.label')}</h4>
        <div className="my-4">
          <div className="flex flex-col mb-4">
            <Input
              label={Translation.t('texts.business.business_name.label')}
              labelStyle="text-sm leading-5 font-normal"
              value={state.name}
              onChange={e => dispatch({ type: 'key_was_updated', payload: { key: 'name', value: e.target.value } })} />

            <span className="text-red-500 text-sm">
              {errorBag.name}
            </span>
          </div>

          <div>
            <Input
              label={Translation.t('texts.business.about_your_business.label')}
              labelStyle="text-sm leading-5 font-normal"
              multiline="true"
              rows="5"
              containerStyle="mb-4"
              value={state.about ? state.about : ''}
              onChange={e => dispatch({ type: 'key_was_updated', payload: { key: 'about', value: e.target.value } })} />
          </div>
        </div>
      </div>

      {/* Business Type Section */}
      <div className="mb-4">
        <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.business_type')}</h3>
        <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.business.business_type_detail')}</h4>
        <div className="my-4">
          <div className="flex flex-wrap gap-4 mt-2">
            {businessTypes.map(t => {
              let checked = state.business_type != null && state.business_type == t.key
              return (
                <div key={t.key} className={`${checked ? 'bg-coolGray-700' : 'bg-coolGray-100'} rounded-full py-2 px-4 flex justify-center items-center truncate`}>
                  <label htmlFor={`${t.key}-${t.name}`} className={`text-base leading-6 text-normal  ${checked ? 'text-white' : 'text-coolGray-700'}  me-2 truncate`}>{t.name}</label>
                  <input
                    id={`${t.key}-${t.name}`}
                    checked={checked}
                    onChange={e => dispatch({ type: 'key_was_updated', payload: { key: 'business_type', value: t.key } })}
                    name='business_type'
                    type="radio"
                    className="focus:ring-gray-500 h-4 w-4 text-orange-200 border-gray-300" />
                </div>
              )
            })}
          </div>

          <span className="text-red-500 text-sm">
            {errorBag.business_type}
          </span>
        </div>
      </div>

      {/* Choose Your Address (Optional) */}
      <div className="mb-4">
        <h3 className="text-xl leading-7 font-semibold text-coolGray-700">{Translation.t('texts.business.choose_your_location')} <span className="text-coolGray-400">{Translation.t('texts.business.optional')}</span></h3>
        <h4 className="text-base leading-5 text-normal text-coolGray-500">{Translation.t('texts.location_will_be_hidden')}</h4>
        <div className="h-52 mt-2">
          <MapWithDraggableMarker
            lat={state.latlon_lat}
            lon={state.latlon_lon}
            googleMapsApiKey={googleMapsApiKey}
            onDragEnd={({ lat, lon, address_line }) => {
              dispatch({ type: 'key_was_updated', payload: { key: 'latlon_lat', value: lat } })
              dispatch({ type: 'key_was_updated', payload: { key: 'latlon_lon', value: lon } })
              dispatch({ type: 'key_was_updated', payload: { key: 'location', value: address_line } })
            }}
          />
        </div>
        <div>
          <Input
            label={Translation.t('texts.address')}
            labelStyle="text-sm leading-5 font-normal"
            containerStyle="mb-4"
            value={state.location ? state.location : ''}
            onChange={e => dispatch({ type: 'key_was_updated', payload: { key: 'location', value: e.target.value } })} />
        </div>
      </div>
    </div>
  )
})

export default AboutBusiness
