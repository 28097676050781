import React, { useEffect, useRef } from "react";
import Translation from "../src/helper/Translation";
import startCase from "lodash/startCase"

const ErrorBag = ({ errorBag = [] , withOutTitleInErrorBag= false, showMoreDetails = false})=> {

  const topContainer = useRef(undefined);

  // when error bag is filled, scroll to top
  // we are using it inside a hook, to allow using `setErrorBag` in multiple places and handle the result once
  useEffect(() => {
    if (errorBag.length == 0) return

    window.scrollTo(0, 0);

  }, [errorBag])

  return (
    <div className="flex flex-col gap-2 mb-4 bg-red-100 border-s-4 border-red-500 text-red-700 p-4"
         role="alert" ref={topContainer}>
      <div className="flex items-center justify-start gap-2">
        <i className={`ri-information-line ri-xl`} />
        <p className="font-bold">{Translation.t('texts.error')}</p>
      </div>
      {errorBag.map((error, index) => {
        return (
          <p key={index} className={`${showMoreDetails ? 'line-clamp-2' : 'truncate'}`}>
            { !withOutTitleInErrorBag && (
              error?.source?.pointer === '/data/attributes/base' ? '' : startCase(error.title.replace('_',' '))
            )}
                &nbsp;
            {error.detail}</p>
        )
      })}
    </div>
  )

}


export default ErrorBag
