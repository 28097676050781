import { locales } from "@shobbak/react-services"
import I18n from "i18n-js"

I18n.translations.en = locales.en
I18n.translations.ar = locales.ar

I18n.fallbacks = true;
I18n.defaultLocale = I18n.defaultLocale;
I18n.locale = window.locale || I18n.defaultLocale;
export default class Translation {
  static t(key, data = {}) {
    I18n.translations.en = { ...I18n.translations.en, ...locales.en }
    I18n.translations.ar = { ...I18n.translations.ar, ...locales.ar }
    return I18n.t(key, data)
  }

  static locale() {
    return I18n.locale;
  }

  static isRtl() {
    return I18n.locale == 'ar';
  }
}
