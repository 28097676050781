
import React from "react"
import BackendCall from "../../src/Components/BackendCall";
import { toast } from "react-toastify";
import Translation from "../../src/helper/Translation";


interface lineItem {
  amount: string ,
  light: boolean ,
  title: string ,
  isTotal: boolean
}

interface ReviewStepProps {
  lineItems: lineItem[];
  allowedActions: string[];
  withdrawRequestId: bigint;
  onCancel: ()=> void;
  showToastyAlert: boolean
}

export default function ReviewStep ({lineItems , allowedActions,withdrawRequestId,showToastyAlert , onCancel}: ReviewStepProps) {

  const performRequest = (action)=> {
    BackendCall.i()
    .withdrawRequestAction(withdrawRequestId , action)
    .then(response => {
      if(!showToastyAlert && action == 'cancel'){
        return onCancel()
      }
      toast.success(Translation.t("withdraw_request.updated"))
      setTimeout(()=> window.location.reload() , 1000)
    })
    .catch(err => { })
  }


  const renderBreakDown = ()=> {
   return(
     <div>
       {lineItems.map((item, index) => (
         <div
           className="flex items-center mb-1"
           key={index}>
           <p
             className={`flex-1 ${item.isTotal ? 'font-bold' : ''} ${item.light ? 'text-coolGray-500' : 'text-coolGray-700'}`}>
             {item.title}
           </p>

           <p
             className={`${item.light ? 'text-coolGray-500' : 'text-coolGray-700'} ${item.isTotal ? 'font-bold' : 'font-normal'}`}
           >
             {item.amount}
           </p>
         </div>
       ))}
     </div>
   )
  }

  const renderAllowedActions = ()=> {
    return(
      <div className="flex justify-between items-between gap-4">
        {allowedActions.map((action)=> {
          return(
            <button key={action} onClick={()=> performRequest(action)} type="button" className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 truncate">
              {Translation.t(`withdraw_request.modal.actions.${action}`)}
            </button>
          )

        })}
      </div>
    )
  }

  return(
    <div className="flex flex-col gap-2 mt-6">
      {renderBreakDown()}
      {renderAllowedActions()}
  </div>
)
}



