import { BusinessMetaData, BusinessTypes, Country } from '@shobbak/react-services/dist/Entities';
import React, { Suspense, useMemo, useState, useEffect, useRef, useReducer } from 'react'
import { serialize } from 'object-to-formdata';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import BackendCall from '../src/Components/BackendCall';
import Loader from "../src/Components/icon/Loader";
import Breadcrumb from "../src/Components/Posting/Breadcrumb"
import StepsProgress from "../src/Components/Posting/StepsProgress"
import AboutBusiness from "../src/Components/BusinessProfile/Steps/AboutBusiness"
import MediaInformation from "../src/Components/BusinessProfile/Steps/MediaInformation"
import BusinessControl from "../src/Components/BusinessProfile/Steps/BusinessControl"
import DocumentedFiles from "../src/Components/BusinessProfile/Steps/DocumentedFiles"
import Translation from "../src/helper/Translation"
import ReCAPTCHA from "react-google-recaptcha";

interface Step {
  id: number;
  title: string;
}

export interface ValidationError {
  title: string;
  detail: string;
}

export type BusinessProfileState = {
  name: string;
  about: string;
  business_type: string;
  latlon_lon: number;
  latlon_lat: number;
  location: string;
  emails: Array<{ email: string; }>;
  mobiles: Array<{ country_id: string; number: string; country_code?: string }>;
  logo: {};
  cover_image: {};
  website: string;
  social_accounts: {
    facebook: string;
    twitter: string;
    instagram: string;
    snapchat: string;
  }
  documents: Array<{ id: number; documents: Array<any> }>;
}

export const initialState: BusinessProfileState = {
  name: '',
  about: '',
  business_type: null,
  latlon_lon: null,
  latlon_lat: null,
  location: '',
  emails: [],
  mobiles: [],
  logo: null,
  cover_image: null,
  website: '',
  social_accounts: {
    facebook: '',
    twitter: '',
    instagram: '',
    snapchat: '',
  },
  documents: []
}

export const reducer = (state: BusinessProfileState, action) => {
  switch (action.type) {
    case 'key_was_updated':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'hydrate_state':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function initiateSteps() {
  return [
    { id: 1, title: Translation.t('texts.business.about_business.label') },
    { id: 2, title: Translation.t('texts.business.media_information') },
    { id: 3, title: Translation.t('texts.business.business_control') },
    { id: 4, title: Translation.t('texts.business.documented_files') },
  ]
}

export default function CreateBusinessProfile({ locale, token, googleMapsApiKey }) {
  const steps = useRef([]);
  const aboutBusinessRef = useRef<HTMLDivElement | null>(null);
  const mediaInformationRef = useRef<HTMLDivElement | null>(null);
  const businessControlRef = useRef<HTMLDivElement | null>(null);
  const documentedFilesRef = useRef<HTMLDivElement | null>(null);
  const recaptchaRef = useRef(null)
  const [currentStep, setCurrentStep] = useState<Step>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [countries, setCountries] = useState<Country[]>([]);
  const [businessMeta, setBusinessMeta] = useState<BusinessMetaData>();
  const [businessTypes, setBusinessTypes] = useState<BusinessTypes>([]);
  const [documentsCanBeUploaded, setDocumentsCanBeUploaded] = useState<boolean>(false);
  const topContainer = useRef<HTMLDivElement>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestHasBeenSubmitted, setRequestHasBeenSubmitted] = useState<boolean>(false);
  const hasReachedFinalStep = useMemo<boolean>(() => {
    return currentStep != undefined && (currentStep.id == 4 || (currentStep.id == 3 && !documentsCanBeUploaded))
  }, [currentStep])
  const [profileId, setProfileId] = useState(null)

  useEffect(() => {
    getCountries()
    getBusinessMetaData()
    steps.current = initiateSteps()
    setCurrentStep(steps.current[0])
  }, [])

  useEffect(() => {
    if (businessMeta == null) return

    setBusinessTypes(businessMeta.businessTypes)
    setDocumentsCanBeUploaded(businessMeta.documents.length > 0)
  }, [businessMeta])

  useEffect(() => {
    if (documentsCanBeUploaded == false) return

    let documents = []
    businessMeta.documents.forEach(d => documents.push({ id: d.id, documents: [] }))

    dispatch({ type: 'key_was_updated', payload: { key: 'documents', value: documents } })
  }, [documentsCanBeUploaded])
  async function forwardValidation() {
    let stepIsValid = false;

    if (currentStep.id == 1) {
      // @ts-ignore
      stepIsValid = await aboutBusinessRef?.current?.validateStep()
    } else if (currentStep.id == 2) {
      // @ts-ignore
      stepIsValid = await mediaInformationRef?.current?.validateStep()
    } else if (currentStep.id == 3) {
      // @ts-ignore
      stepIsValid = await businessControlRef?.current?.validateStep()
    } else if (currentStep.id == 4) {
      // @ts-ignore
      stepIsValid = await documentedFilesRef?.current?.validateStep()
    }

    return stepIsValid;
  }

  async function moveToNextStep() {
    let stepIsValid = false;
    try { stepIsValid = await forwardValidation() } catch (error) { }
    if (stepIsValid == false) return

    if (hasReachedFinalStep) {
      await submit()
      return
    }

    setCurrentStep(steps.current.find(s => s.id == currentStep.id + 1))
    topContainer.current?.scrollIntoView()
  }

  async function moveToPreviousStep() {
    if (currentStep.id == 1) return

    setCurrentStep(steps.current.find(s => s.id == currentStep.id - 1))
    topContainer.current?.scrollIntoView()
  }

  function getCountries() {
    BackendCall.i({ locale, token })
      .getCountries({ enabled: true })
      .then(response => setCountries(response))
      .catch(err => { })
  }

  function getBusinessMetaData() {
    BackendCall.i({ locale, token })
      .getBusinessMetaData()
      .then(response => setBusinessMeta(response))
      .catch(error => { })
  }

  const submit = async() => {
    setIsLoading(true)
    await recaptchaRef.current.reset()
    let recaptcha_token = await recaptchaRef.current.executeAsync()

    let data = { ...state }
    data = { ...omitBy(data, isNil) }
    data = {...data , recaptcha_token: recaptcha_token}
    const options = { indices: false, allowEmptyArrays: false };
    const formData = serialize({ ...data }, options);

    BackendCall.i({ locale, token })
      .post('business_profiles', formData)
      .then(res => {
        setProfileId(res.id)
        setRequestHasBeenSubmitted(true)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        if (isEmpty(error.errors)) {
          setValidationErrors([{ title: 'Error', detail: 'Please try again later' }])
        } else {
          setValidationErrors(error.errors)
        }
      })
  }

  function myBusinessProfileIsReady() {
    if (profileId == null) return

    setIsLoading(true)
    BackendCall.i()
      .myBusinessProfileIsReady(profileId)
      .then(() => {
        window.location.href = '/dashboard'
      })
      .catch(() => setIsLoading(false))
  }

  function renderValidationErrors() {
    if (validationErrors.length == 0) return

    return (
      <div className="col-span-6 text-red-300 text-sm p-4 mb-4 rounded bg-red-50">
        {validationErrors.map((error: ValidationError, index) => {
          return (
            <p key={`error-${index}`}><span className="text-red-500">{error.title} ({error.detail})</span></p>
          )
        })}
      </div>
    )
  }

  function renderSuccessMessage() {
    return (
      <Suspense fallback={Loader()}>
        <div ref={topContainer}>
          <Breadcrumb theme="coolGray" steps={steps} currentStep={currentStep} />
          <div className="md:py-8 md:px-2 ">
            <div className="flex md:flex-row flex-col items-start  w-full md:space-s-4">
              <StepsProgress title={Translation.t('buttons.create_business_account')} theme="coolGray-700" steps={steps.current} currentStep={currentStep} isLastStepComplete={requestHasBeenSubmitted} />
              <div className="md:w-3/4 md:p-6 p-4 md:border md:rounded-md bg-white border-gray-300 h-full flex items-center justify-center flex-col w-full border-box">
                <span>{Translation.t('texts.alert_business_profile_status', { status: Translation.t('texts.business.publish.review_publish') })}</span>
                {
                  profileId != null ?
                    <button disabled={isLoading} onClick={myBusinessProfileIsReady} className="mt-2 whitespace-nowrap bg-coolGray-700 border border-transparent rounded-md py-3 px-16 inline-flex items-center justify-center text-base font-normal leading-6 text-orange-200 hover:bg-coolGray-900 active:bg-coolGray-700">
                      <span className="text-base leading-6 text-center font-semibold">{Translation.t('buttons.submit_business_profile_for_review')}</span>
                    </button> : null
                }
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    )
  }

  if (requestHasBeenSubmitted) {
    return renderSuccessMessage()
  }

  return (
    <Suspense fallback={Loader()}>
      <div ref={topContainer}>
        <Breadcrumb theme="coolGray" steps={steps} currentStep={currentStep} />
        <div className="md:py-8 md:px-2 ">
          <div className="flex md:flex-row flex-col items-start  w-full md:space-s-4">
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={window?.env.RECAPTCHA_KEY}
            />

            <StepsProgress title={Translation.t('buttons.create_business_account')} theme="coolGray-700" steps={steps.current} currentStep={currentStep} isLastStepComplete={requestHasBeenSubmitted} />
            <div className="md:w-3/4 md:p-6 p-4  md:border md:rounded-md md:bg-white border-gray-300 h-full flex-row w-full border-box">
              {renderValidationErrors()}
              {currentStep != null && currentStep.id == 1 ? <AboutBusiness ref={aboutBusinessRef} businessTypes={businessTypes} googleMapsApiKey={googleMapsApiKey} state={state} dispatch={dispatch} /> : null}
              {currentStep != null && currentStep.id == 2 ? <MediaInformation ref={mediaInformationRef} countries={countries} state={state} dispatch={dispatch} /> : null}
              {currentStep != null && currentStep.id == 3 ? <BusinessControl ref={businessControlRef} state={state} dispatch={dispatch} /> : null}
              {currentStep != null && currentStep.id == 4 ? <DocumentedFiles ref={documentedFilesRef} businessMeta={businessMeta} state={state} dispatch={dispatch} /> : null}
              <div className="flex space-s-4 md:justify-between mt-10 py-3 ">
                <button
                  onClick={moveToPreviousStep}
                  disabled={isLoading}
                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} w-1/4 md:w-auto whitespace-nowrap bg-white border border-coolGray-700 rounded-md py-3 px-10 inline-flex items-center justify-center text-base font-normal leading-6 text-coolGray-700`}>
                  {Translation.t('buttons.back')}
                </button>

                <button
                  onClick={moveToNextStep}
                  disabled={isLoading}
                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} w-3/4 md:w-auto whitespace-nowrap bg-coolGray-700 border border-transparent rounded-md py-3 px-16 inline-flex items-center justify-center text-base font-normal leading-6 text-orange-200 hover:bg-coolGray-900 active:bg-coolGray-700`}>
                  {Translation.t('buttons.continue')}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
