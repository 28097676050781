import React, { ReactNode, useEffect, useState } from 'react';
import AccountInfo from './AccountInfo';

interface Props {
  token: string;
  locale: string;
  isVerified: boolean;
  children?: ReactNode;
}

export default function ProfileCardWrapper({ children, token, locale, isVerified = false }: Props) {
  return (
    <div className="flex flex-col mx-4 lg:mx-0 lg:flex-row bg-white border border-gray-200 rounded-md">
      <div className="order-last lg:order-first w-full lg:w-2/3 mb-2 lg:mb-0">{children}</div>
      <div className="w-full p-4 lg:w-1/3 mb-2 lg:mb-0">
        <AccountInfo token={token} locale={locale} isVerified={isVerified} />
      </div>
    </div>
  )
}
