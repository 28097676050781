import { Posting } from '@shobbak/react-services/dist/Entities';
import React from 'react'
import { Services } from '@shobbak/react-services';
import DateTime from '../../helper/DateTime';
import CalenderFill from '../icon/CalenderFill';
import MapPinFill from '../icon/MapPinFill';
import PriceTagTextFill from '../icon/PriceTagTextFill';
import Translation from '../../helper/Translation';
import EyeFill from '../icon/EyeFill';

const { Helpers: { imageUrl } } = Services;

interface Props {
  posting: Posting
}

function PostingSummaryCard({ posting }: Props) {
  const { processedAssetUrls } = posting || {};
  const image = processedAssetUrls?.[0]?.thumbnail;
  return (
    <a key={posting.id} href={posting.publicSiteUrl}>
      <div className="flex space-s-2 p-4 border border-coolGray-200 rounded-md mb-4">
        <div>
          <img className="w-8 h-8 rounded-full" src={imageUrl(image, 25, 25)} />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex flex-col pb-2 mb-2 border-b border-coolGray-100">
            <div className="flex justify-between">
              <p className="text-coolGray-400 text-xs font-normal leading-0">{`${posting.verticalTypeDetail?.name} - ${posting.category?.name}`}</p>
              <div className="flex items-center">
                <span className="flex me-1 items-center justify-center w-5 h-5 rounded-full" style={{ backgroundColor: posting.statusDetail.backgroundColor }}>
                  <i className={`${posting.statusDetail.icon}`} style={{ color: posting.statusDetail.foregroundColor }}></i>
                </span>
                <p className="text-xs font-medium leading-4" style={{ color: posting.statusDetail.foregroundColor }}>{posting.statusDetail.name}</p>
              </div>
            </div>
            <h4 className="text-coolGray-700 text-base font-semibold leading-6">{posting.title}</h4>
            <p className="text-emerald-500 text-sm font-semibold leading-5">{posting.totalPriceDetail?.humanized}</p>
            <div className="flex space-s-2">
              <div className="flex items-end space-s-1">
                <CalenderFill className="fill-current text-coolGray-300" width="16" height="16" />
                <p className="text-coolGray-400 text-xs font-medium leading-4">{new DateTime(posting.createdAt).MMddyyyy()}</p>
              </div>
              {
                posting.displayLocation ?
                  <div className="flex items-end space-s-1">
                    <MapPinFill className="fill-current text-coolGray-300" width="16" height="16" />
                    <p className="text-coolGray-400 text-xs font-medium leading-4">{posting.displayLocation}</p>
                  </div> : null
              }
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex items-end space-s-1">
              <EyeFill className="fill-current text-blue-600" width="16" height="16" />
              <p className="text-blue-600 text-xs leading-4 font-normal">
                {Translation.t('texts.number_of_viewed', { count: posting.postingMeta?.views ? posting.postingMeta?.views : '0' })}
              </p>
            </div>
            <div className="flex items-end space-s-1">
              <PriceTagTextFill className="fill-current text-coolGray-600" width="16" height="16" />
              <p className="text-coolGray-600 text-xs leading-4 font-normal">
                {Translation.t('texts.number_of_offer_received', { count: posting.postingMeta?.offerReceived ? posting.postingMeta?.offerReceived : '0' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default PostingSummaryCard;