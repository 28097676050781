import React, {Reducer} from 'react';

import {
  RemoteItemLoaderAction,
  RemoteItemLoaderInitialState,
  RemoteItemLoaderReducer,
  RemoteItemLoaderState,
} from '../Stores/RemoteItemLoaderStore'

type UseRemoteItemOptions = {
  dataLoader: (params?: any) => Promise<any>;
};

type UseRemoteItemReturn<Item> = [
  RemoteItemLoaderState<Item>,
  (
    data?: any,
    callback?: (data?) => void,
    errorCallback?: (data?) => void,
  ) => Promise<void>,
  (params?: any) => void,
];

export const useRemoteItem = <Item = any>({
  dataLoader,
}: UseRemoteItemOptions): UseRemoteItemReturn<Item> => {
  const [remoteItem, remoteItemDispatch] = React.useReducer<
    Reducer<RemoteItemLoaderState<Item>, RemoteItemLoaderAction<Item>>
  >(RemoteItemLoaderReducer, RemoteItemLoaderInitialState);

  const loadItem = async (params, callback, errorCallback) => {
    try {
      remoteItemDispatch({ type: "startLoading" });
      const newItem = await dataLoader(params);
      callback && callback(newItem);
      remoteItemDispatch({ type: "loaded", item: newItem });
    } catch (error) {
      errorCallback && errorCallback(error);
      remoteItemDispatch({ type: "loadingFailed", error });
    }
  };

  const updateItem = async (data) => {
    try {
      remoteItemDispatch({ type: "startLoading" });
      remoteItemDispatch({ type: "loaded", item: data });
    } catch (error) {
      remoteItemDispatch({ type: "loadingFailed", error });
    }
  };

  return [remoteItem, loadItem, updateItem];
};
