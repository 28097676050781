import React, { useState } from "react";
import BackendCall from "../BackendCall";
import BaseModal from "../Posting/Modal/BaseModal";
import ReactInputVerificationCode from "react-input-verification-code";
import Translation from "../../helper/Translation";
import DefaultButton from "./DefaultButton";
import Timer from "../Timer";

const OtpModal = ({ token, locale, onSuccess, methodPayload, resendVerificationCode, title, onClose, canClose = true }) => {
  const [number, setNumber] = useState('')
  const [errors, setErrors] = useState({})

  const validateAndSubmit = () => {

    BackendCall.i({ token, locale })
      .verifyProfileCodeCheck(
        methodPayload.key,
        String(methodPayload.metadata.id),
        {
          code: number,
        },
      )
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        if (error.errors) {
          setErrors({ number: error.errors[0].detail })
        }
      });
  }

  return (
    <BaseModal onClose={onClose} overflowHidden={false}>
      <div className="flex flex-col gap-4">
        <div className={`flex w-full items-between justify-between gap-2 bg-coolGray-100 p-4`}>
          <p className="text-base leading-6 font-semibold text-coolGray-900">{title}</p>
          {canClose && (
            <div className="self-start cursor-pointer" onClick={onClose}>
              <i className="ri-close-circle-fill text-coolGray-400" />
            </div>
          )}
        </div>
        <div className={`flex flex-col gap-2 md:px-6 px-4 pb-4`}>
          <div className="mt-6" dir="ltr">
            <div className="grid gap-2 grid-cols-4 px-4 justify-center">
              <ReactInputVerificationCode length={4} value={number} onChange={(e) => {
                if (/^\d+$/.test(e)) {
                  setErrors({})
                  return setNumber(e)
                }
                // setErrors({ number: 'invalid number' })

              }} />
            </div>
          </div>
          <div className="mt-6 flex flex-col gap-2 items-center">

            <div className="flex items-center">
              {Object.keys(errors).map(k => {
                return (
                  <span key={k} className="text-sm text-red-500">{errors[k]}</span>
                )
              })}
            </div>

            <span className="text-base leading-7 font-normal text-coolGray-900">{Translation.t('create_new_ad.code_will_send_in_second')}</span>
            {/* <span className="text-sm leading-6 font-normal text-coolGray-500 cursor-pointer" onClick={() => { resendVerificationCode(methodPayload) }}>{Translation.t('buttons.resend_verification_code')}</span> */}
            <div className="flex items-center justify-center">
              <Timer
                expiry={methodPayload?.metadata?.nextRequestAt}
                onFinish={() => { }}
                disabled={false}
                onResend={() => { resendVerificationCode(methodPayload) }}
              />
            </div>
          </div>
          <DefaultButton label={Translation.t('buttons.submit')} disabled={number.length != 4} onButtonClick={validateAndSubmit} />
        </div>

      </div>
    </BaseModal>
  );
}


export default OtpModal
