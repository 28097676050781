import React, { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty'
import AbsherIcon from '../icon/AbsherIcon';

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  withBoarder?: boolean;
  isVerified?: boolean;
}

function ProfileCard({ title, children, subtitle = '', withBoarder = true, isVerified = false }: Props) {
  return (
    <div className={`${withBoarder ? 'border border-gray-200' : ''}	md:m-0 m-2 p-4 bg-white rounded-md`}>
      {(!isEmpty(title) || !isEmpty(subtitle)) && (
        <div className="mb-4">
          {!isEmpty(title) && (
            <div className="flex space-x-2 items-center">
              <div className="flex items-center gap-2">
                <h2 className={`text-2xl leading-8 font-semibold text-coolGray-700`}>{title}</h2>
                {isVerified && ( <AbsherIcon />
                )}
              </div>
            </div>
          )}
          {!isEmpty(subtitle) && <h3 className={`text-lg leading-8 text-emerald-500`}>{subtitle}</h3>}
        </div>
      )}
      {children}
    </div>
  );
}

export default ProfileCard;
