import React, { FC, useState, useEffect, useMemo, lazy, Suspense } from "react"
import Translation from "../../src/helper/Translation"
import SearchSuggestion from "../../src/Types/SearchSuggestion"
import RecentSearch from "../../src/Types/RecentSearch"
import PopularSearch from "../../src/Types/PopularSearch"
import useDebouncedValue from "../../src/Hooks/useDebouncedValue"
import Loader from "../../src/Components/icon/Loader"
const SearchSuggestionsList = lazy(() => import("./SearchSuggestionsList"))
const RecentSearchList = lazy(() => import("./RecentSearchList"))
const PopularSearchList = lazy(() => import("./PopularSearchList"))
import redirectToUrl from './redirectToUrl'
import { City, CustomFieldValue } from "@shobbak/react-services/dist/Entities"
import BackendCall from "../../src/Components/BackendCall"

interface MenuItem {
  id: string;
  icon: string;
  name: string;
  key: string;
  slug: string;
  search_url: string
}

interface CarsSearchBoxProps {
  selectedMake?: CustomFieldValue;
  selectedModel?: CustomFieldValue;
  selectedYear?: CustomFieldValue;
  selectedCity?: City;
  years?: CustomFieldValue[];
  cities?: City[];
  isMobile: boolean;
  baseSubmitUrl: string;
  withBackground: boolean;
  menuItems?: MenuItem[];
  backgroundUrl: string;
  isSearchSuggestionsEnabled: boolean;
  isRecentAndPopularSearchSuggestionsEnabled: boolean;
  selectSearchItem: any[],
  navigateToPageDirectly: boolean;
  locale: string;
  token: string;
}

type SearchBoxMode = "select_make_model_year_city" | "select_model_year_city" | "select_year_city" | "select_city" | "select_nothing"

const CarsSearchBox: FC<CarsSearchBoxProps> = ({ selectedMake, selectedModel, selectedYear, selectedCity, years = [], cities = [], baseSubmitUrl, menuItems, selectSearchItem = [], backgroundUrl = null, withBackground = true, isSearchSuggestionsEnabled = false, isRecentAndPopularSearchSuggestionsEnabled = false, isMobile = false, navigateToPageDirectly = false, locale, token }) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, 500);
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const [popularSearches, setPopularSearches] = useState<PopularSearch[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('all');
  const [selectedMenuItemType, setSelectedMenuItemType] = useState(null);
  const [submitUrl, setSubmitUrl] = useState(baseSubmitUrl);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [models, setModels] = useState<CustomFieldValue[]>([]);
  const [makeId, setMakeId] = useState();
  const [modelId, setModelId] = useState();
  const [year, setYear] = useState();
  const [cityId, setCityId] = useState();
  const searchBoxMode: SearchBoxMode = useMemo(() => {
    if (!selectedMake) return "select_make_model_year_city"
    if (selectedMake && !selectedModel) return "select_model_year_city"
    if (selectedMake && selectedModel && !selectedYear) return "select_year_city"
    if (selectedMake && selectedModel && selectedYear && !selectedCity) return "select_city"
    if (selectedMake && selectedModel && selectedYear && !selectedCity) return "select_city"
    return "select_nothing"
  }, [selectedMake, selectedModel]);
  const showRecentOrPopularSearch = useMemo<boolean>(() => {
    return isRecentAndPopularSearchSuggestionsEnabled && inputIsFocused && (query == null || query == '')
  }, [inputIsFocused, query]);

  useEffect(() => {
    if (!makeId) return

    BackendCall.i({ locale, token })
      .getCarModelsForMake(makeId)
      .then(res => {
        setModels(res || [])
      })
      .catch(err => {
        // 
      })
  }, [makeId])


  useEffect(() => {
    if (!isSearchSuggestionsEnabled) return

    if (debouncedQuery.length <= 1) {
      setSuggestions([])
      return
    }

    fetch(`/lookups/search_suggestions?q=${debouncedQuery}&pagesize=5`)
      .then(response => response.json())
      .then(data => {
        setSuggestions(data.data)
      })
      .catch(error => { })
  }, [debouncedQuery])

  const verticalType = useMemo(() => {
    return selectedMenuItemType != null && selectedMenuItem != 'all' && selectedMenuItemType == 'vertical_type' ? selectedMenuItem : null
  }, [selectedMenuItemType, selectedMenuItem])

  const category = useMemo(() => {
    return selectedMenuItemType != null && selectedMenuItem != 'all' && selectedMenuItemType == 'category' ? selectedMenuItem : null
  }, [selectedMenuItemType, selectedMenuItem])

  function resetAllInputs() {
    setQuery('')
    setSuggestions([])
  }

  function search(event) {
    event.preventDefault();
    let newSubmitUrl = baseSubmitUrl
    let newFilters = ""

    if (searchBoxMode == "select_make_model_year_city") {
      newFilters = encodeURI(`&filter[q]=${query}&filter[make]=${makeId ?? ''}&filter[model]=${modelId ?? ''}&filter[year][from]=${year ?? ''}&filter[city_id]=${cityId ?? ''}`)
    } else if (searchBoxMode == "select_model_year_city") {
      newFilters = encodeURI(`&filter[q]=${query}&filter[make]=${selectedMake.id}&filter[model]=${modelId ?? ''}&filter[year][from]=${year ?? ''}&filter[city_id]=${cityId ?? ''}`)
    } else if (searchBoxMode == "select_year_city") {
      newFilters = encodeURI(`&filter[q]=${query}&filter[make]=${selectedMake.id}&filter[model]=${selectedModel.id}&filter[year][from]=${year ?? ''}&filter[city_id]=${cityId ?? ''}`)
    } else if (searchBoxMode == "select_city") {
      newFilters = encodeURI(`&filter[q]=${query}&filter[make]=${selectedMake.id}&filter[model]=${selectedModel.id}&filter[year][from]=${selectedYear.slug}&filter[city_id]=${cityId ?? ''}`)
    } else if (searchBoxMode == "select_nothing") {
      newFilters = encodeURI(`&filter[q]=${query}&filter[make]=${selectedMake.id}&filter[model]=${selectedModel.id}&filter[year][from]=${selectedYear.slug}&filter[city_id]=${selectedCity.id}`)
    }

    newSubmitUrl = `${newSubmitUrl}${newFilters}`

    redirectToUrl({
      redirectUrl: newSubmitUrl,
      searchTerm: query,
      verticalType: 'vehicles',
      category: 'cars'
    })
  }

  function renderForm() {
    switch (searchBoxMode) {
      case "select_make_model_year_city":
      default:
        return renderSelectMakeModelYearCityInputs()
      case "select_model_year_city":
        return renderSelectModelYearCityInputs()
      case "select_year_city":
        return renderSelectYearCityInputs()
      case "select_city":
        return renderSelectCityInputs()
      case "select_nothing":
        return renderSelectNothing()
    }
  }

  function renderSelectMakeModelYearCityInputs() {
    return (
      <>
        <div className="flex-1 me-2">
          <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
            onChange={(e) => { setMakeId(e.target.value) }} id="">
            <option value={null}>{Translation.t('inputs.drop_down.select_make')}</option>
            {menuItems.map((item) => {
              return (
                <option key={item.id} value={item.id}>{item.value}</option>
              )
            })}
          </select>
        </div>

        {models.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setModelId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_model')}</option>
              {models.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {years.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setYear(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_year')}</option>
              {years.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {cities.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setCityId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_city')}</option>
              {cities.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
        )}
      </>
    )
  }

  function renderSelectModelYearCityInputs() {
    return (
      <>
        <div className="flex-1 me-2">
          <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200  block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`} id="">
            {[selectedMake].map((item) => {
              return (
                <option key={item.id} value={item.id}>{item.value}</option>
              )
            })}
          </select>
        </div>

        {menuItems.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setModelId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_model')}</option>
              {menuItems.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {years.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setYear(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_year')}</option>
              {years.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {cities.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setCityId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_city')}</option>
              {cities.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
        )}
      </>
    )
  }

  function renderSelectYearCityInputs() {
    return (
      <>
        <div className="flex-1 me-2">
          <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200  block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`} id="">
            {[selectedMake].map((item) => {
              return (
                <option key={item.id} value={item.id}>{item.value}</option>
              )
            })}
          </select>
        </div>

        {[selectedModel].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedModel].map((item) => {
                return (
                  <option key={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {years.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setYear(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_year')}</option>
              {years.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {cities.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setCityId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_city')}</option>
              {cities.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
        )}
      </>
    )
  }

  function renderSelectCityInputs() {
    return (
      <>
        <div className="flex-1 me-2">
          <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200  block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`} id="">
            {[selectedMake].map((item) => {
              return (
                <option key={item.id} value={item.id}>{item.value}</option>
              )
            })}
          </select>
        </div>

        {[selectedModel].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedModel].map((item) => {
                return (
                  <option key={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {[selectedYear].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedYear].map((item) => {
                return (
                  <option key={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {cities.length > 0 && (
          <div className="flex-1 me-2">
            <select className={`w-full h-12 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { setCityId(e.target.value) }} id="">
              <option value={null}>{Translation.t('inputs.drop_down.select_city')}</option>
              {cities.map((item) => {
                return (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
        )}
      </>
    )
  }

  function renderSelectNothing() {
    return (
      <>
        <div className="flex-1 me-2">
          <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200  block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`} id="">
            {[selectedMake].map((item) => {
              return (
                <option key={item.id} value={item.id}>{item.value}</option>
              )
            })}
          </select>
        </div>

        {[selectedModel].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedModel].map((item) => {
                return (
                  <option key={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {[selectedYear].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedYear].map((item) => {
                return (
                  <option key={item.id}>{item.value}</option>
                )
              })}
            </select>
          </div>
        )}

        {[selectedCity].length > 0 && (
          <div className="flex-1 me-2">
            <select disabled className={`w-full h-12 form-input border border-coolGray-200 bg-coolGray-200 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              onChange={(e) => { }} id="">
              {[selectedCity].map((item) => {
                return (
                  <option key={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>
        )}
      </>
    )
  }

  return (
    <Suspense fallback={Loader()}>
      <div className={`w-full`}>
        {/* Menu Items */}
        <div className="rounded-lg relative" style={withBackground ? {
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${backgroundUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        } : {}}
        >
          <div className={`relative flex flex-col space-y-6 items-center justify-start bg-white bg-opacity-30 rounded-lg w-full ${withBackground ? 'md:p-8 md:px-24 p-4 px-6' : ''}`}>
            {withBackground ?
              <p className="text-center text-2xl leading-8 text-white font-medium">
                <span className="font-bold">{Translation.t('texts.buy_and_sell')}&nbsp;</span> <br />{Translation.t('texts.whatever_you_need')}
              </p>
              : null
            }
            <div className={`flex flex-col space-y-2 items-start justify-start p-6 bg-white rounded-lg w-full ${withBackground ? 'bg-opacity-80' : 'bg-coolGray-50 border border-coolGray-200'}`}>
              {/* Search Field  */}
              <form onSubmit={search} action={submitUrl} method="GET" className="w-full">
                <div className="flex flex-col md:flex-row md:space-x-4 justify-between space-x-2">
                  <div className="w-full mb-2 md:w-1/3 md:mb-0">
                    <div className="relative rounded-md shadow-sm h-12">
                      <div className="absolute inset-y-0 start-0 ps-3 flex items-center pointer-events-none h-12">
                        <svg className="h-5 w-5 text-coolGray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                      </div>
                      <input
                        data-search-input-field="true"
                        autoComplete="off"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={() => setInputIsFocused(true)}
                        type="text"
                        name="filter[q]"
                        id="search"
                        className="h-12 focus:ring-emerald-500 focus:border-emerald-500 block w-full ps-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={Translation.t('texts.search_for_anything')} />

                      {isSearchSuggestionsEnabled && (recentSearches.length || popularSearches.length) ? (<ul id="searches-list" className={`absolute bg-white rounded-lg w-full mt-1 ${showRecentOrPopularSearch ? 'border border-coolGray-200' : ''}`}>
                        {showRecentOrPopularSearch ? <RecentSearchList
                          recentSearches={recentSearches}
                          submitUrl={baseSubmitUrl}
                          verticalType={verticalType}
                          category={category}
                          blurInputHandler={() => setInputIsFocused(false)}
                          setRecentSearches={(result) => setRecentSearches(result)}
                        /> : null}

                        {showRecentOrPopularSearch ? <PopularSearchList
                          popularSearches={popularSearches}
                          submitUrl={baseSubmitUrl}
                          verticalType={verticalType}
                          category={category}
                          blurInputHandler={() => setInputIsFocused(false)}
                        /> : null}
                      </ul>) : null}

                      {/* Clear button */}
                      {query != '' ?
                        <div onClick={resetAllInputs} className="absolute inset-y-0 end-0 pe-3 flex items-center h-12">
                          <span className="text-coolGray-400 text-sm cursor-pointer">{Translation.t('buttons.clear')}</span>
                        </div>
                        : null}

                      {/* Suggestions */}
                      <SearchSuggestionsList suggestions={suggestions} submitUrl={baseSubmitUrl} verticalType={verticalType} category={category} />
                    </div>
                  </div>

                  <div className="w-full md:flex-1 flex justify-between">
                    {renderForm()}

                    <button type="submit" className="h-12 px-8 py-3 shadow-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      <span className="text-base leading-normal text-center text-white">{Translation.t('texts.search')}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div >
        </div >
      </div>
    </Suspense>
  )
}

export default CarsSearchBox
