import React, { CSSProperties } from 'react';

export type SwitchProps = {
  value?: boolean;
  onValueChange?: (e) => void;
  containerStyle?: CSSProperties;
  disabled?: boolean;
  onChange?: () => void;
  locale?: string;
  skipInitialRenderHook?: boolean;
};

export default function Switch({
  value,
  onValueChange,
  containerStyle,
  disabled,
  onChange,
  locale = 'en',
  skipInitialRenderHook = false
}: SwitchProps) {
  const [currentValue, setCurrentValue] = React.useState<boolean>(
    value || false,
  );

  // This hook will be called at the initial render because value will be updated from null to something else
  // and that side effect may not be wanted (may be it will trigger API call) 
  React.useEffect(() => {
    if (skipInitialRenderHook) return;
    if (disabled) {
      handleAnimation(false);
    } else {
      handleAnimation(value);
    }
  }, [value]);

  const handleAnimation = (value) => {
    setCurrentValue(value);
    onValueChange && onValueChange(value);
  };

  return (
    <button
      onClick={() => {
        if (!disabled) {
          if (onChange) {
            onChange();
          } else {
            handleAnimation(!currentValue);
          }
        }
      }}
      type="button"
      className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 ${currentValue ? 'bg-emerald-500' : 'bg-coolGray-200'} ${containerStyle ? containerStyle : ''}`}
      role="switch"
      aria-checked="false"
    >
      <span className="sr-only">Use setting</span>
      {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
      <span aria-hidden="true" className={`${currentValue ? `${locale == 'ar' ? '-translate-x-5' : 'translate-x-5'}` : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
    </button>
  );
}
