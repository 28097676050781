import Translation from "./Translation";

export default class DateTime {
  private date
  constructor(date) {
    this.date = new Date(date)
  }

  utc() {
    return new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(), this.date.getUTCDate(), this.date.getUTCHours(), this.date.getUTCMinutes(), this.date.getUTCSeconds());
  }

  short() {
    const locale = Translation.locale() == 'en' ? 'en-US' : 'ar-eg'
    return this.date.toLocaleString(locale, {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric'
    })
  }

  MMddyyyy() {
    const locale = Translation.locale() == 'en' ? 'en-US' : 'ar-eg'
    return this.date.toLocaleString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  fromNow() {
    var seconds = Math.round((new Date() - this.date) / 1000);
    var years = Math.round(seconds / 31536000);
    var months = Math.round(seconds / 2592000);
    var days = Math.round(seconds / 86400);

    if (days > 548) {
      return years + ' years ago';
    }
    if (days >= 320 && days <= 547) {
      return 'a year ago';
    }
    if (days >= 45 && days <= 319) {
      return months + ' months ago';
    }
    if (days >= 26 && days <= 45) {
      return 'a month ago';
    }

    var hours = Math.floor(seconds / 3600);


    if (hours >= 36 && days <= 25) {
      return days + ' days ago';
    }
    if (hours >= 22 && hours <= 35) {
      return 'a day ago';
    }

    var minutes = Math.floor(seconds / 60);

    if (minutes >= 90 && hours <= 21) {
      return hours + ' hours ago';
    }
    if (minutes >= 45 && minutes <= 89) {
      return 'an hour ago';
    }
    if (seconds >= 90 && minutes <= 44) {
      return minutes + ' minutes ago';
    }
    if (seconds >= 45 && seconds <= 89) {
      return 'a minute ago';
    }
    if (seconds >= 0 && seconds <= 45) {
      return 'a few seconds ago';
    }
  }
}