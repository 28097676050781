import React from 'react';

import AlertModal from './AlertModal';

type ConfirmationModalProps = {
  isVisible: boolean;
  title?: string;
  confirmText?: any;
  cancelText?: any;
  onConfirm: (x: any) => void;
  onCancel: (x: any) => void;
};

export default function ConfirmationModal({
  isVisible,
  title,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) {
  return (
    <AlertModal
      headline={title || 'Confirm Action'}
      body=""
      visible={isVisible}
      rowButtons={[
        {label: cancelText || 'Cancel', onPress: onCancel, type: 'outline'},
        {label: confirmText || 'Confirm', onPress: onConfirm, type: 'solid'},
      ]}
      containerStyle="h-52"
    />
  );
}
