import React from "react"

function NoGiftsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={83}
      height={82}
      fill="none"
    >
      <style>
        {
          ".B{stroke-width:2}.C{fill:#10b981}.D{stroke:#10b981}.E{stroke-linejoin:round}.F{stroke-miterlimit:10}"
        }
      </style>
      <path
        stroke="#ffd836"
        strokeLinecap="round"
        d="M79.083 41.194V23.188a5.82 5.82 0 0 0-4.453-5.694l-29.52-7.107a5.82 5.82 0 0 0-4.981 1.096 5.82 5.82 0 0 0-2.205 4.599V72.89a5.82 5.82 0 0 0 2.205 4.599 5.82 5.82 0 0 0 4.981 1.096l29.531-7.209a5.82 5.82 0 0 0 4.453-5.695v-2.278"
        className="B F"
      />
      <g className="C">
        <use xlinkHref="#B" />
        <use xlinkHref="#B" y={4.055} />
        <use xlinkHref="#B" y={8.12} />
        <use xlinkHref="#B" y={12.186} />
        <path d="m23.882 12.198 2.437-7.972.353-1.139c.262-.831-1.048-1.139-1.298-.364l-2.437 7.972-.353 1.139c-.262.831 1.036 1.139 1.298.364zM3.108 13.439l7.642 3.28 1.071.456a.7.7 0 0 0 .509.064.694.694 0 0 0 .414-.303.67.67 0 0 0-.043-.748.66.66 0 0 0-.197-.175l-7.642-3.28-1.071-.467a.72.72 0 0 0-.923.239.696.696 0 0 0 .239.934z" />
      </g>
      <path
        fill="#ffd836"
        d="M16.239 11.822 13.7 3.918l-.364-1.139c-.308-.82-1.56-.501-1.298.364l2.54 7.904.353 1.139c.273.82 1.572.467 1.31-.353v-.011z"
      />
      <g fill="#fff" className="B D F">
        <path d="M37.901 28.125v38.21a.86.86 0 0 0 .331.682.873.873 0 0 0 .352.166.87.87 0 0 0 .389-.005l22.049-5.148a.866.866 0 0 0 .672-.843l.125-27.14a.865.865 0 0 0-.637-.843l-22.186-5.911a.87.87 0 0 0-.752.146.865.865 0 0 0-.342.686z" />
        <path d="M38.05 28.125v38.21a.86.86 0 0 1-.331.682.866.866 0 0 1-.352.166.87.87 0 0 1-.389-.005L14.964 62.03a.866.866 0 0 1-.672-.843l-.125-27.14a.865.865 0 0 1 .638-.843l22.151-5.911a.87.87 0 0 1 .752.146.876.876 0 0 1 .342.686z" />
      </g>
      <g className="C">
        <use xlinkHref="#C" />
        <use xlinkHref="#D" />
        <path d="M42.275 29.059a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.53.53 0 0 1 .085.302.525.525 0 0 1-.524.524z" />
        <use xlinkHref="#E" />
        <use xlinkHref="#F" />
        <use xlinkHref="#G" />
        <use xlinkHref="#D" y={5.273} />
        <path d="M42.275 34.332a.54.54 0 0 1-.302-.085.53.53 0 0 1-.202-.24.54.54 0 0 1 .114-.589.53.53 0 0 1 .277-.147.54.54 0 0 1 .312.033.53.53 0 0 1 .325.504.525.525 0 0 1-.154.37.53.53 0 0 1-.17.114.52.52 0 0 1-.2.04zm-2.631-2.642a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.54.54 0 0 1-.117-.171.505.505 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.52.52 0 0 1-.154.37.525.525 0 0 1-.37.153zm2.631 0a.523.523 0 0 1-.523-.523.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157.525.525 0 0 1 .37.154.53.53 0 0 1 .114.17c.027.064.04.132.04.2a.54.54 0 0 1-.037.202.51.51 0 0 1-.285.285.51.51 0 0 1-.202.037z" />
        <use xlinkHref="#H" />
        <use xlinkHref="#I" />
        <use xlinkHref="#C" />
        <use xlinkHref="#E" />
        <use xlinkHref="#J" />
        <use xlinkHref="#G" y={5.273} />
        <use xlinkHref="#D" y={10.546} />
        <path d="M42.275 39.605a.54.54 0 0 1-.302-.085.53.53 0 0 1-.202-.24.54.54 0 0 1 .114-.589.53.53 0 0 1 .277-.147.54.54 0 0 1 .312.033.53.53 0 0 1 .325.504.525.525 0 0 1-.154.37.52.52 0 0 1-.17.114.53.53 0 0 1-.2.04zm-2.631-2.642a.526.526 0 0 1-.204-.036.51.51 0 0 1-.174-.113.54.54 0 0 1-.117-.171.505.505 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.52.52 0 0 1-.154.37.525.525 0 0 1-.37.153zm2.631 0a.523.523 0 0 1-.523-.523.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157c.069 0 .137.014.2.04a.52.52 0 0 1 .17.114.53.53 0 0 1 .114.17c.027.064.04.132.04.2a.54.54 0 0 1-.037.202.51.51 0 0 1-.285.285.51.51 0 0 1-.202.037z" />
        <use xlinkHref="#E" y={10.546} />
        <use xlinkHref="#K" />
        <use xlinkHref="#F" />
        <use xlinkHref="#H" />
        <path d="M40.954 46.188a.523.523 0 0 1-.524-.523.53.53 0 0 1 .086-.302.537.537 0 0 1 .24-.202.54.54 0 0 1 .589.114.53.53 0 0 1 .147.277.538.538 0 0 1-.235.552.53.53 0 0 1-.302.085zm0-2.631a.536.536 0 0 1-.523-.644.54.54 0 0 1 .427-.418.53.53 0 0 1 .31.035c.097.042.18.113.238.202a.53.53 0 0 1 .085.3.536.536 0 0 1-.536.524zm-1.31 1.321a.53.53 0 0 1-.3-.085.54.54 0 0 1-.237-.548.53.53 0 0 1 .144-.277.54.54 0 0 1 .274-.15.534.534 0 0 1 .311.028.53.53 0 0 1 .242.197.537.537 0 0 1 .091.299.54.54 0 0 1-.153.375.53.53 0 0 1-.371.161z" />
        <use xlinkHref="#L" />
        <use xlinkHref="#M" />
        <path d="M42.275 42.236a.525.525 0 0 1 0-1.047.523.523 0 1 1 0 1.047z" />
        <use xlinkHref="#N" />
        <use xlinkHref="#O" />
        <use xlinkHref="#J" />
        <use xlinkHref="#E" y={10.546} />
        <use xlinkHref="#P" />
        <use xlinkHref="#E" />
        <use xlinkHref="#E" x={5.272} />
        <use xlinkHref="#Q" />
        <use xlinkHref="#H" />
        <use xlinkHref="#R" />
        <use xlinkHref="#I" />
        <use xlinkHref="#S" />
        <use xlinkHref="#T" />
        <path d="M44.917 31.69a.51.51 0 0 1-.204-.037.52.52 0 0 1-.174-.113.524.524 0 0 1-.116-.171.52.52 0 0 1-.041-.203.54.54 0 0 1 .18-.327.534.534 0 0 1 .7 0 .54.54 0 0 1 .18.327.523.523 0 0 1-.523.523zm2.631 0a.523.523 0 0 1-.524-.523.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157.534.534 0 0 1 .371.154.52.52 0 0 1 .114.17c.026.064.04.132.04.2a.54.54 0 0 1-.037.202.51.51 0 0 1-.487.322z" />
        <use xlinkHref="#H" x={5.272} />
        <use xlinkHref="#U" />
        <use xlinkHref="#P" />
        <use xlinkHref="#E" />
        <use xlinkHref="#E" x={5.272} />
        <use xlinkHref="#V" />
        <use xlinkHref="#E" y={10.546} />
        <use xlinkHref="#R" y={5.273} />
        <use xlinkHref="#K" />
        <use xlinkHref="#S" y={5.273} />
        <use xlinkHref="#T" y={5.273} />
        <path d="M44.917 36.963a.526.526 0 0 1-.204-.036.51.51 0 0 1-.174-.113.524.524 0 0 1-.116-.171.52.52 0 0 1-.041-.203.54.54 0 0 1 .18-.327.534.534 0 0 1 .7 0 .54.54 0 0 1 .18.327.523.523 0 0 1-.523.523zm2.631 0a.523.523 0 0 1-.524-.523.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157.526.526 0 0 1 .524.523.5.5 0 0 1-.037.202.51.51 0 0 1-.487.322z" />
        <use xlinkHref="#E" x={5.272} y={10.546} />
        <use xlinkHref="#K" x={5.273} />
        <use xlinkHref="#Q" />
        <use xlinkHref="#H" />
        <use xlinkHref="#H" x={5.272} />
        <path d="M46.215 46.188a.523.523 0 0 1-.524-.523.539.539 0 0 1 .638-.537.539.539 0 0 1 .424.424.538.538 0 0 1-.537.637z" />
        <use xlinkHref="#N" />
        <path d="M46.216 43.557a.542.542 0 0 1-.496-.333.53.53 0 0 1 .398-.729.532.532 0 0 1 .548.237.531.531 0 0 1-.076.671.534.534 0 0 1-.374.153z" />
        <use xlinkHref="#O" />
        <use xlinkHref="#W" />
        <use xlinkHref="#L" x={5.273} />
        <use xlinkHref="#X" />
        <path d="M47.548 42.236a.526.526 0 0 1-.37-.894.525.525 0 0 1 .894.37.527.527 0 0 1-.524.524z" />
        <use xlinkHref="#Y" />
        <use xlinkHref="#O" x={5.274} />
        <use xlinkHref="#V" />
        <use xlinkHref="#E" y={10.546} />
        <use xlinkHref="#E" x={5.272} y={10.546} />
        <use xlinkHref="#Z" />
        <use xlinkHref="#H" x={5.272} />
        <use xlinkHref="#a" />
        <use xlinkHref="#U" />
        <use xlinkHref="#S" x={5.272} />
        <use xlinkHref="#b" />
        <path d="M50.191 31.69a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.526.526 0 0 1-.117-.171.52.52 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.523.523 0 0 1-.523.523zm2.63 0a.51.51 0 0 1-.524-.524.523.523 0 0 1 .523-.524.52.52 0 0 1 .37.154.527.527 0 0 1 .154.37.5.5 0 0 1-.037.202.51.51 0 0 1-.487.322z" />
        <use xlinkHref="#H" x={10.59} />
        <use xlinkHref="#c" />
        <use xlinkHref="#d" />
        <use xlinkHref="#E" x={5.272} y={10.546} />
        <use xlinkHref="#a" y={5.273} />
        <use xlinkHref="#K" x={5.273} />
        <use xlinkHref="#S" x={5.272} y={5.273} />
        <use xlinkHref="#b" y={5.273} />
        <path d="M50.191 36.963a.526.526 0 0 1-.204-.036.51.51 0 0 1-.174-.113.526.526 0 0 1-.117-.171.52.52 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.523.523 0 0 1-.523.523zm2.63 0a.51.51 0 0 1-.524-.524.523.523 0 1 1 1.047 0 .5.5 0 0 1-.037.202.51.51 0 0 1-.487.322z" />
        <use xlinkHref="#E" x={10.59} y={10.546} />
        <use xlinkHref="#e" />
        <use xlinkHref="#Z" />
        <use xlinkHref="#H" x={5.272} />
        <use xlinkHref="#H" x={10.59} />
        <path d="M51.5 46.188a.523.523 0 0 1-.523-.523.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.537.637z" />
        <use xlinkHref="#Y" />
        <path d="M51.5 43.557a.536.536 0 1 1 .001-.001z" />
        <use xlinkHref="#O" x={5.274} />
        <use xlinkHref="#W" x={5.273} />
        <path d="M52.821 44.878a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587.538.538 0 0 1 .827.075.537.537 0 0 1 .091.299.528.528 0 0 1-.524.536z" />
        <use xlinkHref="#X" x={5.273} />
        <path d="M52.821 42.236a.527.527 0 0 1-.484-.323.524.524 0 0 1 .381-.714.52.52 0 0 1 .303.03.522.522 0 0 1 .323.484.5.5 0 0 1-.037.202.51.51 0 0 1-.113.172.51.51 0 0 1-.374.15z" />
        <use xlinkHref="#f" />
        <use xlinkHref="#g" />
        <use xlinkHref="#d" />
        <use xlinkHref="#E" x={5.272} y={10.546} />
        <use xlinkHref="#E" x={10.59} y={10.546} />
        <use xlinkHref="#L" x={14.498} y={-9.225} />
        <use xlinkHref="#H" x={10.59} />
        <path d="M56.773 33.011a.533.533 0 0 1-.484-.725.514.514 0 0 1 .284-.284.514.514 0 0 1 .2-.04.51.51 0 0 1 .374.15.51.51 0 0 1 .15.374.523.523 0 0 1-.523.524z" />
        <use xlinkHref="#c" />
        <path d="M55.452 34.332a.542.542 0 0 1-.496-.333.535.535 0 1 1 .495.332z" />
        <use xlinkHref="#h" />
        <use xlinkHref="#i" />
        <use xlinkHref="#j" />
        <use xlinkHref="#E" x={10.59} y={10.546} />
        <path d="M56.773 38.284a.533.533 0 0 1-.484-.725.514.514 0 0 1 .284-.284.514.514 0 0 1 .2-.04.51.51 0 0 1 .202.037.52.52 0 0 1 .285.285.51.51 0 0 1 .037.202.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#e" />
        <path d="M55.452 39.605a.542.542 0 0 1-.496-.333.53.53 0 0 1 .398-.729.532.532 0 0 1 .548.237.531.531 0 0 1-.076.671.536.536 0 0 1-.375.153z" />
        <use xlinkHref="#O" x={14.498} y={-3.952} />
        <use xlinkHref="#k" />
        <path d="M58.094 36.963a.51.51 0 0 1-.202-.037.52.52 0 0 1-.285-.285.52.52 0 0 1-.037-.202.525.525 0 0 1 .524-.523.54.54 0 0 1 .524.524.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#l" />
        <path d="M59.415 38.284a.536.536 0 0 1-.213-1.026.54.54 0 0 1 .587.109c.076.075.128.17.15.274s.012.212-.029.311a.538.538 0 0 1-.495.333z" />
        <use xlinkHref="#L" x={14.498} y={-9.225} />
        <use xlinkHref="#H" x={10.59} />
        <use xlinkHref="#i" />
        <path d="M56.773 46.188a.525.525 0 0 1-.524-.524.533.533 0 0 1 .524-.524.523.523 0 0 1 .523.523.51.51 0 0 1-.15.374.51.51 0 0 1-.374.15z" />
        <use xlinkHref="#f" />
        <path d="M56.773 43.557a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.53.53 0 0 1 .085.302.523.523 0 0 1-.523.524z" />
        <use xlinkHref="#g" />
        <path d="M55.451 44.878a.532.532 0 0 1-.494-.33.53.53 0 0 1 .39-.729.531.531 0 0 1 .639.524.537.537 0 0 1-.535.535z" />
        <use xlinkHref="#W" x={13.178} />
        <path d="M55.452 42.236a.523.523 0 0 1-.476-.331.52.52 0 0 1-.025-.302.524.524 0 0 1 1.035.11.52.52 0 0 1-.041.203.516.516 0 0 1-.291.284.527.527 0 0 1-.204.037zm2.642 0a.52.52 0 0 1-.37-.154.525.525 0 0 1 .37-.893.525.525 0 0 1 .524.523.52.52 0 0 1-.154.37.526.526 0 0 1-.37.154zm2.767-7.904a.536.536 0 0 1-.495-.333.54.54 0 0 1-.029-.311.535.535 0 0 1 .427-.418.53.53 0 0 1 .31.035.54.54 0 0 1 .238.202.53.53 0 0 1 .085.3.536.536 0 0 1-.536.524z" />
        <use xlinkHref="#m" />
        <use xlinkHref="#k" x={5.41} />
        <path d="M60.861 44.878a.532.532 0 0 1-.524-.639c.021-.104.072-.199.146-.274s.17-.126.274-.146.212-.01.309.03a.536.536 0 0 1 .173.872.537.537 0 0 1-.378.157z" />
        <use xlinkHref="#n" />
        <path d="M59.415 46.188a.526.526 0 0 1-.536-.523.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.526.526 0 0 1-.524.523z" />
        <use xlinkHref="#S" x={14.498} y={9.225} />
        <use xlinkHref="#j" />
        <use xlinkHref="#E" x={10.59} y={10.546} />
        <use xlinkHref="#l" />
        <use xlinkHref="#o" />
        <use xlinkHref="#p" />
        <use xlinkHref="#q" />
        <use xlinkHref="#L" x={-2.494} y={5.296} />
        <path d="M41.102 51.495a.543.543 0 0 1-.291-.101.53.53 0 0 1-.187-.245.537.537 0 0 1-.021-.307.53.53 0 0 1 .152-.268.54.54 0 0 1 .274-.14.534.534 0 0 1 .306.034.54.54 0 0 1 .237.197c.058.087.089.19.089.295a.54.54 0 0 1-.165.387.53.53 0 0 1-.181.114.546.546 0 0 1-.212.034zm2.631 0a.53.53 0 0 1-.3-.085.54.54 0 0 1-.202-.238.533.533 0 0 1-.035-.31.54.54 0 0 1 .418-.427.538.538 0 0 1 .644.524.51.51 0 0 1-.037.204.52.52 0 0 1-.113.174.526.526 0 0 1-.171.117.52.52 0 0 1-.203.041zm-2.631-2.642a.534.534 0 0 1-.535-.524.53.53 0 0 1 .085-.3.54.54 0 0 1 .548-.237.54.54 0 0 1 .427.418.538.538 0 0 1-.525.644zm2.631 0a.523.523 0 1 1 .524-.523.52.52 0 0 1-.154.37.525.525 0 0 1-.37.153z" />
        <use xlinkHref="#r" />
        <use xlinkHref="#s" />
        <path d="M42.412 47.532a.523.523 0 0 1-.524-.523.54.54 0 0 1 .086-.302.53.53 0 0 1 .24-.202.54.54 0 0 1 .589.114.53.53 0 0 1 .147.277.54.54 0 0 1-.033.312.53.53 0 0 1-.504.325zm-2.631 0a.51.51 0 0 1-.202-.037.52.52 0 0 1-.172-.113.51.51 0 0 1-.15-.374.523.523 0 0 1 .523-.523.52.52 0 0 1 .367.157.54.54 0 0 1 .157.367.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#t" />
        <use xlinkHref="#R" x={-3.803} y={25.067} />
        <use xlinkHref="#u" />
        <path d="M42.411 55.447a.532.532 0 0 1-.524-.639.541.541 0 0 1 .421-.42.536.536 0 1 1 .103 1.06z" />
        <use xlinkHref="#v" />
        <path d="M41.102 56.757a.51.51 0 0 1-.204-.037.52.52 0 0 1-.174-.113.526.526 0 0 1-.117-.171.52.52 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.52.52 0 0 1-.154.37.526.526 0 0 1-.37.154zm2.631 0a.51.51 0 0 1-.487-.322.52.52 0 0 1-.037-.202.52.52 0 0 1 .154-.37.525.525 0 0 1 .894.37.5.5 0 0 1-.037.202.52.52 0 0 1-.113.172.51.51 0 0 1-.374.15z" />
        <use xlinkHref="#w" />
        <path d="M43.733 54.126c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235c.058.09.088.195.085.301a.525.525 0 0 1-.524.523z" />
        <use xlinkHref="#x" />
        <use xlinkHref="#y" />
        <use xlinkHref="#o" />
        <use xlinkHref="#p" />
        <use xlinkHref="#r" />
        <use xlinkHref="#z" />
        <use xlinkHref="#AA" />
        <path d="M42.411 60.72a.532.532 0 0 1-.524-.639.537.537 0 0 1 .97-.194.532.532 0 0 1-.067.675.54.54 0 0 1-.379.157z" />
        <use xlinkHref="#v" y={5.273} />
        <path d="M41.102 62.03a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.526.526 0 0 1-.117-.171.52.52 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.52.52 0 0 1-.154.37.525.525 0 0 1-.37.153zm2.631 0a.5.5 0 0 1-.202-.037.51.51 0 0 1-.285-.285.517.517 0 0 1-.037-.202.52.52 0 0 1 .154-.37.526.526 0 0 1 .37-.154.52.52 0 0 1 .37.154.526.526 0 0 1 .154.37.5.5 0 0 1-.037.202.51.51 0 0 1-.285.285.517.517 0 0 1-.202.037z" />
        <use xlinkHref="#AB" />
        <path d="M43.733 59.388a.539.539 0 0 1-.537-.638.539.539 0 0 1 .424-.424.538.538 0 0 1 .552.235.53.53 0 0 1 .085.302.526.526 0 0 1-.524.524z" />
        <use xlinkHref="#AC" />
        <use xlinkHref="#y" y={5.273} />
        <path d="M42.412 58.078a.523.523 0 1 1 .377-.149.52.52 0 0 1-.174.113.527.527 0 0 1-.204.037z" />
        <use xlinkHref="#AD" />
        <use xlinkHref="#M" x={5.433} y={15.842} />
        <path d="M42.411 65.993a.53.53 0 0 1-.494-.33.536.536 0 0 1-.03-.309.54.54 0 0 1 .147-.274.537.537 0 0 1 .583-.116.543.543 0 0 1 .24.197.53.53 0 0 1 .09.297.535.535 0 0 1-.536.536zm-2.63 0a.53.53 0 0 1-.3-.085.54.54 0 0 1-.202-.238.533.533 0 0 1-.035-.31.54.54 0 0 1 .418-.427.538.538 0 0 1 .491.9.536.536 0 0 1-.371.161z" />
        <use xlinkHref="#S" x={-3.816} y={30.34} />
        <path d="M43.733 64.672a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.53.53 0 0 1 .085.302.525.525 0 0 1-.524.523z" />
        <use xlinkHref="#AE" />
        <use xlinkHref="#z" />
        <use xlinkHref="#AA" />
        <use xlinkHref="#AC" />
        <use xlinkHref="#AF" />
        <use xlinkHref="#r" />
        <path d="M47.684 50.174a.54.54 0 0 1-.503-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.53.53 0 0 1 .085.302.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#s" />
        <path d="M46.363 51.495a.53.53 0 0 1-.494-.33.536.536 0 0 1 .117-.583.53.53 0 0 1 .274-.146.544.544 0 0 1 .309.03.53.53 0 0 1 .24.197c.059.088.09.192.09.297a.53.53 0 0 1-.157.378.538.538 0 0 1-.379.157zm2.643 0a.53.53 0 0 1-.3-.085.54.54 0 0 1-.202-.238.533.533 0 0 1-.035-.31.54.54 0 0 1 .418-.427.538.538 0 0 1 .644.524.536.536 0 0 1-.524.536z" />
        <use xlinkHref="#a" x={-5.136} y={15.842} />
        <path d="M49.006 48.853a.523.523 0 0 1-.523-.523.51.51 0 0 1 .037-.202.52.52 0 0 1 .285-.285.52.52 0 0 1 .202-.037.523.523 0 0 1 .524.523.54.54 0 0 1-.524.524z" />
        <use xlinkHref="#r" x={5.25} />
        <use xlinkHref="#s" x={5.25} />
        <path d="M47.685 47.532a.523.523 0 0 1-.524-.523.54.54 0 0 1 .524-.524.523.523 0 0 1 .523.523.51.51 0 0 1-.037.202.52.52 0 0 1-.113.172.51.51 0 0 1-.374.15z" />
        <use xlinkHref="#t" />
        <use xlinkHref="#t" x={5.25} />
        <path d="M47.685 58.078a.533.533 0 0 1-.524-.524.527.527 0 0 1 .726-.487.51.51 0 0 1 .285.285.51.51 0 0 1 .037.202.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#x" />
        <path d="M47.685 55.447a.533.533 0 0 1-.457-.53.536.536 0 0 1 .457-.53.533.533 0 0 1 .457.53.534.534 0 0 1-.457.53z" />
        <use xlinkHref="#y" />
        <use xlinkHref="#AG" />
        <path d="M49.006 56.757a.51.51 0 0 1-.374-.15.51.51 0 0 1-.113-.172.51.51 0 0 1-.037-.202.523.523 0 0 1 .523-.523.54.54 0 0 1 .524.524.526.526 0 0 1-.524.523z" />
        <use xlinkHref="#q" x={3.951} y={3.952} />
        <use xlinkHref="#O" x={5.409} y={10.569} />
        <use xlinkHref="#x" x={5.25} />
        <use xlinkHref="#y" x={5.25} />
        <use xlinkHref="#AF" />
        <use xlinkHref="#r" />
        <use xlinkHref="#r" x={5.25} />
        <use xlinkHref="#AH" />
        <use xlinkHref="#AC" />
        <use xlinkHref="#O" x={4.089} y={17.163} />
        <use xlinkHref="#y" y={5.273} />
        <use xlinkHref="#AG" y={5.273} />
        <path d="M49.006 62.03a.5.5 0 0 1-.202-.037.51.51 0 0 1-.285-.285.51.51 0 0 1-.037-.202.523.523 0 0 1 .523-.524.54.54 0 0 1 .524.524.527.527 0 0 1-.524.524z" />
        <use xlinkHref="#q" x={3.951} y={9.214} />
        <use xlinkHref="#O" x={5.409} y={15.83} />
        <use xlinkHref="#AC" x={5.25} />
        <use xlinkHref="#y" x={5.25} y={5.273} />
        <use xlinkHref="#AD" x={7.904} />
        <use xlinkHref="#M" x={5.433} y={15.842} />
        <use xlinkHref="#M" x={10.683} y={15.842} />
        <use xlinkHref="#AE" />
        <use xlinkHref="#q" x={3.951} y={14.498} />
        <use xlinkHref="#O" x={5.409} y={21.115} />
        <use xlinkHref="#AH" />
        <use xlinkHref="#AC" />
        <use xlinkHref="#AC" x={5.25} />
        <use xlinkHref="#AI" />
        <use xlinkHref="#r" x={5.25} />
        <path d="M52.958 50.174c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.538.538 0 0 1 .391-.736.538.538 0 0 1 .484.907.525.525 0 0 1-.37.153z" />
        <use xlinkHref="#s" x={5.25} />
        <path d="M51.637 51.495a.532.532 0 0 1-.524-.639c.021-.104.072-.199.146-.274s.17-.126.274-.146.211-.01.309.03.181.109.24.197a.534.534 0 0 1-.446.832z" />
        <use xlinkHref="#AJ" />
        <use xlinkHref="#G" x={10.682} y={15.842} />
        <path d="M54.279 48.853a.538.538 0 0 1-.536-.524.531.531 0 0 1 .323-.502.54.54 0 0 1 .587.109.538.538 0 0 1 .121.585.538.538 0 0 1-.496.333z" />
        <use xlinkHref="#AK" />
        <use xlinkHref="#AL" />
        <path d="M52.958 47.532a.51.51 0 0 1-.374-.15.51.51 0 0 1-.15-.374.525.525 0 0 1 1.047 0 .51.51 0 0 1-.037.202.52.52 0 0 1-.113.172.51.51 0 0 1-.374.15z" />
        <use xlinkHref="#t" x={5.25} />
        <use xlinkHref="#AM" />
        <path d="M52.958 58.078a.525.525 0 0 1 0-1.048.527.527 0 0 1 .524.524.526.526 0 0 1-.524.524z" />
        <use xlinkHref="#x" x={5.25} />
        <path d="M52.958 55.447a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .012.99.505.505 0 0 1-.203.041z" />
        <use xlinkHref="#y" x={5.25} />
        <use xlinkHref="#AN" />
        <path d="M54.279 56.757a.528.528 0 0 1-.536-.524.533.533 0 0 1 .53-.457.534.534 0 0 1 .53.457.523.523 0 0 1-.523.524z" />
        <use xlinkHref="#AO" />
        <path d="M54.279 54.126c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.538.538 0 0 1 .391-.736.538.538 0 0 1 .638.536.533.533 0 0 1-.524.524z" />
        <use xlinkHref="#AP" />
        <use xlinkHref="#AQ" />
        <use xlinkHref="#AI" />
        <use xlinkHref="#r" x={5.25} />
        <use xlinkHref="#AK" />
        <use xlinkHref="#AC" x={5.25} />
        <use xlinkHref="#y" x={5.25} y={5.273} />
        <use xlinkHref="#AO" y={5.262} />
        <path d="M54.279 59.388a.539.539 0 0 1-.537-.638.539.539 0 0 1 .424-.424.538.538 0 0 1 .638.537.533.533 0 0 1-.524.524z" />
        <use xlinkHref="#AP" y={5.273} />
        <use xlinkHref="#AR" />
        <path d="M52.958 58.078a.527.527 0 0 1-.484-.323.524.524 0 1 1 1.007-.2.5.5 0 0 1-.037.202.51.51 0 0 1-.285.285.517.517 0 0 1-.202.037z" />
        <use xlinkHref="#M" x={10.683} y={15.842} />
        <use xlinkHref="#AS" />
        <use xlinkHref="#AC" x={5.25} />
        <use xlinkHref="#AN" y={5.273} />
        <path d="M52.958 63.351a.534.534 0 0 1-.371-.154.53.53 0 0 1-.114-.17.52.52 0 0 1-.04-.2.523.523 0 1 1 1.007.2.52.52 0 0 1-.114.17.53.53 0 0 1-.17.114.514.514 0 0 1-.2.04zm1.321-1.321a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.526.526 0 0 1-.117-.171.52.52 0 0 1-.041-.203.533.533 0 0 1 .53-.457c.129 0 .253.046.35.13a.54.54 0 0 1 .18.327.523.523 0 0 1-.523.523zm-1.321-1.31a.53.53 0 0 1-.3-.085.54.54 0 0 1-.237-.548.53.53 0 0 1 .144-.277.54.54 0 0 1 .274-.15.538.538 0 0 1 .644.524.51.51 0 0 1-.037.204.52.52 0 0 1-.487.331z" />
        <use xlinkHref="#AP" y={5.273} />
        <use xlinkHref="#AT" />
        <use xlinkHref="#AK" />
        <use xlinkHref="#L" x={15.956} y={5.296} />
        <use xlinkHref="#AL" />
        <use xlinkHref="#h" x={-1.184} y={17.163} />
        <use xlinkHref="#AJ" x={5.273} />
        <path d="M56.91 48.853a.533.533 0 0 1-.367-.157.54.54 0 0 1-.157-.367.51.51 0 0 1 .154-.37.525.525 0 0 1 .37-.153.5.5 0 0 1 .202.037.52.52 0 0 1 .172.113.495.495 0 0 1 .113.172.51.51 0 0 1 .037.202.523.523 0 0 1-.523.523zm2.642 0a.534.534 0 0 1-.535-.524.53.53 0 0 1 .085-.3.54.54 0 0 1 .548-.237.54.54 0 0 1 .427.418.538.538 0 0 1-.524.644z" />
        <use xlinkHref="#AU" />
        <use xlinkHref="#m" y={10.569} />
        <path d="M58.231 47.532a.51.51 0 0 1-.202-.037.52.52 0 0 1-.285-.285.51.51 0 0 1-.037-.202.523.523 0 0 1 .523-.523.54.54 0 0 1 .524.524.525.525 0 0 1-.523.523z" />
        <use xlinkHref="#AM" />
        <path d="M60.862 47.532a.523.523 0 0 1-.523-.523.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.53.53 0 0 1-.302.085z" />
        <use xlinkHref="#u" x={18.45} />
        <use xlinkHref="#AP" />
        <use xlinkHref="#v" x={18.45} />
        <use xlinkHref="#AQ" />
        <path d="M56.91 56.757a.523.523 0 0 1-.524-.523.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157.525.525 0 0 1 .523.523.51.51 0 0 1-.037.202.52.52 0 0 1-.113.172.51.51 0 0 1-.374.15zm2.642 0a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.54.54 0 0 1-.117-.171.505.505 0 0 1-.041-.203.54.54 0 0 1 .18-.327.534.534 0 0 1 .7 0 .54.54 0 0 1 .18.327.525.525 0 0 1-.154.37.53.53 0 0 1-.17.114.514.514 0 0 1-.2.04zm-2.642-2.631c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.54.54 0 0 1 .114-.589.53.53 0 0 1 .277-.147.54.54 0 0 1 .312.033.53.53 0 0 1 .325.503.523.523 0 0 1-.523.523z" />
        <use xlinkHref="#w" x={18.449} />
        <use xlinkHref="#G" x={19.908} y={25.067} />
        <path d="M60.861 55.447a.532.532 0 0 1-.524-.639c.021-.103.072-.199.146-.274s.17-.126.274-.146.212-.01.309.03a.536.536 0 0 1-.205 1.03z" />
        <use xlinkHref="#AT" />
        <use xlinkHref="#AK" />
        <use xlinkHref="#AU" />
        <use xlinkHref="#AP" y={5.273} />
        <path d="M58.231 60.72a.53.53 0 0 1-.51-.314.536.536 0 1 1 .509.315z" />
        <use xlinkHref="#AR" />
        <path d="M56.91 62.03a.52.52 0 0 1-.37-.154.527.527 0 0 1-.154-.37.52.52 0 0 1 .157-.367.54.54 0 0 1 .367-.157.51.51 0 0 1 .37.154.525.525 0 0 1 .153.37.51.51 0 0 1-.322.487.517.517 0 0 1-.202.037zm2.642 0a.51.51 0 0 1-.204-.037.53.53 0 0 1-.174-.113.54.54 0 0 1-.117-.171.505.505 0 0 1-.041-.203.54.54 0 0 1 .18-.327.534.534 0 0 1 .7 0 .54.54 0 0 1 .18.327.534.534 0 0 1-.154.371.53.53 0 0 1-.17.114.514.514 0 0 1-.2.04zm-2.642-2.642a.54.54 0 0 1-.302-.086.53.53 0 0 1-.202-.24.54.54 0 0 1 .114-.589.53.53 0 0 1 .277-.147.54.54 0 0 1 .312.033.53.53 0 0 1 .325.504.523.523 0 0 1-.523.524z" />
        <use xlinkHref="#AB" x={18.449} />
        <path d="M60.861 60.72a.53.53 0 0 1-.494-.33.536.536 0 0 1 .939-.503.53.53 0 0 1-.067.675.537.537 0 0 1-.378.157zm-2.63-2.642a.52.52 0 0 1-.37-.154.526.526 0 0 1-.154-.37.52.52 0 0 1 .154-.37.526.526 0 0 1 .37-.154.52.52 0 0 1 .37.154.526.526 0 0 1 0 .74.524.524 0 0 1-.37.154z" />
        <use xlinkHref="#AS" />
        <use xlinkHref="#n" y={15.842} />
        <use xlinkHref="#AP" y={5.273} />
        <g className="B D E">
          <path d="m22.698 17.545-.16.148h-.068l-3.326 3.064a3.77 3.77 0 0 0-1.264 2.973c.137 2.096 1.834 5.057 9.988 5.888l10.056-2.722s-11.002-12.289-15.227-9.35z" />
          <path d="M28.654 29.321s-.547 11.844-11.104 14.521l5.888 3.963s11.275-5.489 9.999-19.782l-4.783 1.298z" />
        </g>
      </g>
      <g fill="#fff" className="B D E">
        <path d="M37.901 26.941S24.963 12.42 21.774 18.627s16.127 8.314 16.127 8.314zM56.83 20.802l-3.326-3.064h-.068l-.159-.148c-4.214-2.938-15.204 9.384-15.204 9.384l10.056 2.722c8.154-.82 9.863-3.781 9.988-5.877a3.803 3.803 0 0 0-.3-1.655 3.79 3.79 0 0 0-.987-1.363z" />
      </g>
      <path
        fill="#c2e9db"
        d="M46.773 29.423s.581 11.856 11.093 14.521l-5.854 3.952s-11.275-5.478-9.999-19.783l4.761 1.31zm-8.723-2.482s12.938-14.521 16.138-8.314-16.138 8.314-16.138 8.314z"
        className="B D E"
      />
      <g className="C">
        <path d="M49.791 53.784a2.55 2.55 0 0 0 .967-2.504 2.55 2.55 0 0 0-1.809-1.983l-.569-3.052 12.038-2.278.569 3.052a2.54 2.54 0 0 0-.985 2.503 2.54 2.54 0 0 0 1.816 1.984l2.403 12.892-12.038 2.278-2.392-12.892z" />
        <path d="m59.507 45.334.239 1.321a3.69 3.69 0 0 0-.845 3.107 3.69 3.69 0 0 0 1.916 2.588l2.073 11.127-9.84 1.845-2.073-11.138a3.69 3.69 0 0 0 .85-3.104 3.69 3.69 0 0 0-1.909-2.591l-.251-1.321 9.795-1.822.045-.011zm1.777-2.642-14.225 2.642.934 5.034c.087-.011.175-.011.262 0a1.409 1.409 0 0 1 1.389 1.139 1.426 1.426 0 0 1-1.139 1.651l2.756 14.806 14.316-2.654-2.767-14.806h-.25a1.41 1.41 0 0 1-1.416-1.281 1.41 1.41 0 0 1 1.154-1.521l-.934-5.022-.08.011z" />
      </g>
      <path
        fill="#fff"
        d="M51.796 55.356a2.575 2.575 0 0 0 .968-2.506 2.54 2.54 0 0 0-1.811-1.982l-.569-3.052 12.027-2.278.569 3.052a2.55 2.55 0 0 0-.967 2.504 2.55 2.55 0 0 0 1.81 1.983l2.392 12.858-12.027 2.278-2.392-12.858z"
      />
      <path
        d="m61.499 46.86.25 1.333a3.69 3.69 0 0 0-.845 3.107 3.69 3.69 0 0 0 1.916 2.588l2.073 11.195-9.795 1.822-2.05-11.15a3.69 3.69 0 0 0 .845-3.107 3.69 3.69 0 0 0-1.916-2.588l-.239-1.321 9.76-1.879zm1.799-2.619L49.063 46.86l.934 5.023h.262a1.41 1.41 0 0 1 1.405 1.286 1.41 1.41 0 0 1-1.154 1.515l2.768 14.806 14.27-2.654-2.767-14.806a.958.958 0 0 1-.262 0 1.41 1.41 0 0 1-1.405-1.286 1.41 1.41 0 0 1 1.154-1.515l-.968-4.988z"
        className="C"
      />
      <g fill="#ffd836">
        <use xlinkHref="#AV" />
        <use xlinkHref="#AV" x={-1.88} y={0.353} />
        <use xlinkHref="#AV" x={-3.77} y={0.695} />
      </g>
      <path
        d="m61.371 56.742.315-.059a1.32 1.32 0 0 1 .645.032 1.16 1.16 0 0 1 .524.338c.146.162.242.368.289.619a1.23 1.23 0 0 1-.046.686 1.16 1.16 0 0 1-.367.504 1.32 1.32 0 0 1-.59.262l-.315.059c-.221.041-.433.03-.637-.034a1.13 1.13 0 0 1-.523-.334 1.231 1.231 0 0 1-.289-.619 1.28 1.28 0 0 1 .045-.69 1.16 1.16 0 0 1 .367-.504 1.3 1.3 0 0 1 .582-.261zm.421.51-.315.059a.935.935 0 0 0-.343.136.67.67 0 0 0-.233.272.643.643 0 0 0-.034.387.62.62 0 0 0 .17.345.65.65 0 0 0 .314.162c.12.026.243.027.369.003l.315-.059a.9.9 0 0 0 .346-.141.61.61 0 0 0 .234-.268.602.602 0 0 0 .038-.384.61.61 0 0 0-.174-.344.647.647 0 0 0-.314-.166.9.9 0 0 0-.373-.002zm-3.234 3.015.319-.059c.224-.042.437-.031.641.033a1.16 1.16 0 0 1 .524.338c.146.162.242.368.289.619.048.256.032.485-.046.686a1.16 1.16 0 0 1-.367.504 1.31 1.31 0 0 1-.586.262l-.319.059a1.304 1.304 0 0 1-.641-.033 1.13 1.13 0 0 1-.524-.334 1.227 1.227 0 0 1-.289-.619 1.25 1.25 0 0 1 .046-.686 1.19 1.19 0 0 1 .366-.508 1.31 1.31 0 0 1 .586-.262zm.425.509-.319.059a.96.96 0 0 0-.347.137.67.67 0 0 0-.233.272.64.64 0 0 0-.034.387.62.62 0 0 0 .17.345.64.64 0 0 0 .314.166c.12.026.245.026.373.002l.319-.059a.9.9 0 0 0 .346-.141.614.614 0 0 0 .268-.655.605.605 0 0 0-.174-.344.65.65 0 0 0-.31-.166.936.936 0 0 0-.373-.002zm3.804.158-5.196-2.04.191-.476 5.196 2.04-.191.476zM61.329 42.68l1.993 1.56-2.893.638-.66-1.321 1.56-.877zM51.273 67.975l2.004 1.56 1.025-1.56-.421-1.982-1.64-.535-.968 2.517z"
        className="C"
      />
      <defs>
        <path
          id="B"
          d="M79.084 47.503a1.14 1.14 0 0 0 .82-1.093 1.14 1.14 0 0 0-.82-1.093 1.14 1.14 0 0 0-.82 1.093 1.14 1.14 0 0 0 .82 1.093z"
        />
        <path
          id="C"
          d="M40.953 30.38a.532.532 0 0 1-.524-.639.541.541 0 0 1 .421-.42.536.536 0 0 1 .482.902.537.537 0 0 1-.378.157z"
        />
        <path
          id="D"
          d="M39.644 29.059a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .638.537.533.533 0 0 1-.524.524z"
        />
        <path
          id="E"
          d="M43.597 30.38a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .18.871.531.531 0 0 1-.371.161z"
        />
        <path
          id="F"
          d="M40.953 35.653a.532.532 0 0 1-.524-.639.537.537 0 0 1 .97-.193.532.532 0 0 1-.067.676.533.533 0 0 1-.378.157z"
        />
        <path
          id="G"
          d="M40.954 33.011a.533.533 0 0 1-.524-.524.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.536.536 0 0 1-.535.524z"
        />
        <path
          id="H"
          d="M43.597 35.653a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587.542.542 0 0 1 .585-.122.531.531 0 0 1 .333.496.534.534 0 0 1-.524.535z"
        />
        <path
          id="I"
          d="M43.596 33.011a.525.525 0 0 1-.523-.524.51.51 0 0 1 .15-.374.51.51 0 0 1 .374-.15.523.523 0 0 1 .523.523.54.54 0 0 1-.524.524z"
        />
        <path
          id="J"
          d="M40.953 40.926a.532.532 0 0 1-.524-.639.537.537 0 0 1 .97-.193.532.532 0 0 1-.067.675.537.537 0 0 1-.378.157z"
        />
        <path
          id="K"
          d="M43.596 38.284a.523.523 0 0 1-.523-.523.51.51 0 0 1 .037-.202.52.52 0 0 1 .285-.285.51.51 0 0 1 .202-.037.523.523 0 0 1 .523.523.54.54 0 0 1-.524.524z"
        />
        <path
          id="L"
          d="M42.275 44.878a.538.538 0 0 1-.457-.53.534.534 0 0 1 .457-.53.533.533 0 0 1 .457.53.534.534 0 0 1-.457.53z"
        />
        <path
          id="M"
          d="M39.644 42.236a.527.527 0 0 1-.484-.323.524.524 0 1 1 .484.324z"
        />
        <path
          id="N"
          d="M43.596 46.188a.52.52 0 0 1-.374-.15.51.51 0 0 1-.15-.374.523.523 0 0 1 .523-.523.54.54 0 0 1 .524.524.526.526 0 0 1-.523.524z"
        />
        <path
          id="O"
          d="M43.596 43.557a.533.533 0 0 1-.457-.53.534.534 0 0 1 .457-.53.533.533 0 0 1 .457.53.534.534 0 0 1-.457.53z"
        />
        <path
          id="P"
          d="M46.216 30.38a.532.532 0 0 1-.494-.33.534.534 0 0 1 .699-.699.536.536 0 0 1 .173.872.538.538 0 0 1-.379.157z"
        />
        <path
          id="Q"
          d="M46.216 35.653a.532.532 0 0 1-.494-.33.53.53 0 0 1 .39-.729.536.536 0 1 1 .103 1.06z"
        />
        <path
          id="R"
          d="M46.215 33.011a.533.533 0 0 1-.524-.524.533.533 0 0 1 .53-.457.534.534 0 0 1 .53.457.536.536 0 0 1-.535.524z"
        />
        <path
          id="S"
          d="M44.918 34.332a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.533.533 0 0 1-.439.826z"
        />
        <path
          id="T"
          d="M47.549 34.332a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .484.907.526.526 0 0 1-.37.153z"
        />
        <path
          id="U"
          d="M48.869 33.011a.525.525 0 0 1-.523-.524.51.51 0 0 1 .15-.374.495.495 0 0 1 .172-.113.51.51 0 0 1 .202-.037.523.523 0 0 1 .523.523.54.54 0 0 1-.524.524z"
        />
        <path
          id="V"
          d="M46.216 40.926a.532.532 0 0 1-.494-.33.53.53 0 0 1 .39-.729.536.536 0 0 1 .482.902.538.538 0 0 1-.379.157z"
        />
        <path
          id="W"
          d="M44.917 44.878a.531.531 0 0 1-.502-.323.533.533 0 0 1 .109-.587.542.542 0 0 1 .585-.122.531.531 0 0 1 .333.496.538.538 0 0 1-.524.536z"
        />
        <path
          id="X"
          d="M44.917 42.236a.527.527 0 0 1-.484-.323.524.524 0 1 1 .484.324z"
        />
        <path
          id="Y"
          d="M48.869 46.188a.52.52 0 0 1-.374-.15.51.51 0 0 1-.15-.374.523.523 0 0 1 .523-.523.54.54 0 0 1 .524.524.524.524 0 0 1-.523.524z"
        />
        <path
          id="Z"
          d="M51.5 35.653a.532.532 0 0 1-.494-.33.53.53 0 0 1 .39-.729.536.536 0 1 1 .104 1.06z"
        />
        <path
          id="a"
          d="M51.5 33.011a.533.533 0 0 1-.524-.524.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.536.536 0 0 1-.536.524z"
        />
        <path
          id="b"
          d="M52.821 34.332a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .484.907.525.525 0 0 1-.37.153z"
        />
        <path id="c" d="M54.188 33.011a.534.534 0 1 1 .001.001z" />
        <path
          id="d"
          d="M51.5 40.926a.532.532 0 0 1-.494-.33.53.53 0 0 1 .39-.729.536.536 0 0 1 .482.902.537.537 0 0 1-.378.157z"
        />
        <path id="e" d="M54.188 38.284a.534.534 0 1 1 .001.001z" />
        <path
          id="f"
          d="M54.188 46.188a.526.526 0 0 1-.536-.523.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.523.523 0 0 1-.523.523z"
        />
        <path
          id="g"
          d="M54.187 43.557a.538.538 0 0 1-.504-.325.538.538 0 0 1 .391-.736.538.538 0 0 1 .638.537.533.533 0 0 1-.524.524z"
        />
        <path
          id="h"
          d="M58.094 34.332a.533.533 0 0 1-.457-.53.534.534 0 0 1 .457-.53.538.538 0 0 1 .457.53.534.534 0 0 1-.457.53z"
        />
        <path
          id="i"
          d="M59.416 35.653a.531.531 0 0 1-.502-.323.533.533 0 0 1 .109-.587.542.542 0 0 1 .585-.122.531.531 0 0 1 .333.496.534.534 0 0 1-.524.535z"
        />
        <path
          id="j"
          d="M56.773 40.926a.538.538 0 0 1-.457-.53.534.534 0 0 1 .457-.53.538.538 0 0 1 .457.53.534.534 0 0 1-.457.53z"
        />
        <path
          id="k"
          d="M55.452 36.963a.525.525 0 0 1-.523-.524.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.537.638z"
        />
        <path
          id="l"
          d="M59.416 40.926a.531.531 0 0 1-.502-.323.533.533 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .18.871.531.531 0 0 1-.371.161z"
        />
        <path
          id="m"
          d="M60.861 39.605a.536.536 0 1 1 .002-1.07.536.536 0 0 1-.002 1.069z"
        />
        <path
          id="n"
          d="M60.861 42.236a.523.523 0 1 1 .203-.036.527.527 0 0 1-.204.037z"
        />
        <path
          id="o"
          d="M42.412 52.805a.523.523 0 0 1-.524-.523.539.539 0 0 1 .638-.537.539.539 0 0 1 .424.424.538.538 0 0 1-.235.552.53.53 0 0 1-.302.085z"
        />
        <path
          id="p"
          d="M39.781 52.805a.51.51 0 0 1-.202-.037.52.52 0 0 1-.172-.113.51.51 0 0 1-.15-.374.523.523 0 0 1 .523-.524.54.54 0 0 1 .524.524.523.523 0 0 1-.523.523z"
        />
        <path
          id="q"
          d="M42.412 50.174a.542.542 0 0 1-.496-.333.53.53 0 0 1 .398-.729.53.53 0 0 1 .548.237.531.531 0 0 1-.076.671.534.534 0 0 1-.374.153z"
        />
        <path
          id="r"
          d="M45.076 52.805a.528.528 0 0 1-.536-.524.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.525.525 0 0 1-.524.523z"
        />
        <path
          id="s"
          d="M45.077 50.174c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.533.533 0 0 1-.439.826z"
        />
        <path
          id="t"
          d="M45.076 47.532a.51.51 0 0 1-.204-.037.52.52 0 0 1-.174-.113.526.526 0 0 1-.158-.374.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.525.525 0 0 1-.524.523z"
        />
        <path
          id="u"
          d="M39.781 58.078a.523.523 0 0 1-.523-.523.51.51 0 0 1 .15-.374c.049-.049.107-.088.172-.113a.54.54 0 0 1 .572.117c.098.099.153.232.153.37s-.059.27-.157.367-.229.154-.367.157z"
        />
        <path
          id="v"
          d="M39.781 55.447a.531.531 0 0 1-.502-.323.533.533 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .333.495.532.532 0 0 1-.524.535z"
        />
        <path
          id="w"
          d="M41.102 54.126c-.107.002-.212-.028-.302-.085s-.16-.141-.202-.24a.538.538 0 0 1 .391-.736.538.538 0 0 1 .552.235.533.533 0 0 1-.439.825z"
        />
        <path id="x" d="M45.076 58.078a.534.534 0 1 1 .001.001z" />
        <path
          id="y"
          d="M45.077 55.447a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .333.495.532.532 0 0 1-.524.535z"
        />
        <path
          id="z"
          d="M42.412 63.351a.524.524 0 0 1-.487-.341.533.533 0 0 1-.036-.206.538.538 0 0 1 .53-.457.534.534 0 0 1 .53.457.528.528 0 0 1-.152.386.528.528 0 0 1-.382.161z"
        />
        <path
          id="AA"
          d="M39.781 63.351a.534.534 0 0 1-.371-.154.53.53 0 0 1-.154-.37.51.51 0 0 1 .15-.374.495.495 0 0 1 .172-.113.51.51 0 0 1 .202-.037.523.523 0 0 1 .523.523.54.54 0 0 1-.524.524z"
        />
        <path
          id="AB"
          d="M41.102 59.388a.539.539 0 0 1-.537-.638.539.539 0 0 1 .424-.424.538.538 0 0 1 .552.235.533.533 0 0 1-.439.826z"
        />
        <path id="AC" d="M45.076 63.351a.534.534 0 1 1 .001.001z" />
        <path
          id="AD"
          d="M39.781 58.078a.526.526 0 0 1-.37-.894.521.521 0 0 1 .893.37.525.525 0 0 1-.523.524z"
        />
        <path
          id="AE"
          d="M45.077 65.993a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .18.871.536.536 0 0 1-.371.161z"
        />
        <path
          id="AF"
          d="M47.685 52.805a.523.523 0 0 1-.524-.523.54.54 0 0 1 .524-.524.526.526 0 0 1 .523.524.51.51 0 0 1-.15.374.51.51 0 0 1-.374.15z"
        />
        <path
          id="AG"
          d="M46.364 56.757a.525.525 0 0 1-.523-.524.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.529.529 0 0 1-.301.085z"
        />
        <path
          id="AH"
          d="M47.685 63.351a.533.533 0 0 1-.524-.524.526.526 0 0 1 .524-.523.51.51 0 0 1 .374.15.51.51 0 0 1 .15.374.525.525 0 0 1-.523.524z"
        />
        <path
          id="AI"
          d="M52.958 52.805a.51.51 0 0 1-.374-.15.51.51 0 0 1-.113-.172.517.517 0 0 1-.037-.202.526.526 0 0 1 .894-.37.526.526 0 0 1 .153.37.51.51 0 0 1-.15.374.51.51 0 0 1-.374.15z"
        />
        <path
          id="AJ"
          d="M54.279 51.495a.531.531 0 0 1-.502-.323.54.54 0 0 1 .109-.587c.075-.076.17-.128.274-.15s.212-.012.311.029a.538.538 0 0 1 .333.495.536.536 0 0 1-.524.536z"
        />
        <path
          id="AK"
          d="M55.589 52.805a.534.534 0 0 1-.371-.154.53.53 0 0 1-.154-.37c-.002-.107.028-.212.085-.302s.141-.16.24-.202a.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.529.529 0 0 1-.301.085z"
        />
        <path
          id="AL"
          d="M55.589 50.174a.542.542 0 0 1-.496-.333.533.533 0 0 1 .122-.585.542.542 0 0 1 .587-.109.538.538 0 0 1 .323.502.536.536 0 0 1-.535.524z"
        />
        <path
          id="AM"
          d="M55.589 47.532a.52.52 0 0 1-.201-.04.514.514 0 0 1-.284-.284.52.52 0 0 1-.04-.2c-.002-.107.028-.212.085-.302s.141-.16.24-.202a.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.529.529 0 0 1-.301.085z"
        />
        <path
          id="AN"
          d="M51.636 56.757a.525.525 0 0 1-.524-.524.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.53.53 0 0 1-.302.085z"
        />
        <path
          id="AO"
          d="M51.637 54.126a.536.536 0 1 1 .002-1.07.536.536 0 0 1-.002 1.069z"
        />
        <path
          id="AP"
          d="M55.588 58.078a.533.533 0 0 1-.524-.524.533.533 0 0 1 .53-.457.534.534 0 0 1 .53.457.536.536 0 0 1-.536.524z"
        />
        <path
          id="AQ"
          d="M55.589 55.447a.532.532 0 0 1-.524-.639.541.541 0 0 1 .421-.42.531.531 0 0 1 .639.524.535.535 0 0 1-.536.536z"
        />
        <path
          id="AR"
          d="M55.589 60.72a.532.532 0 0 1-.524-.639.537.537 0 0 1 .97-.194.532.532 0 0 1-.067.675.538.538 0 0 1-.379.157z"
        />
        <path
          id="AS"
          d="M55.589 58.078a.523.523 0 1 1 .494-.32.52.52 0 0 1-.116.171.528.528 0 0 1-.378.15z"
        />
        <path
          id="AT"
          d="M58.231 52.805a.51.51 0 0 1-.202-.037.52.52 0 0 1-.172-.113.51.51 0 0 1-.15-.374.523.523 0 0 1 .523-.524.54.54 0 0 1 .524.524.525.525 0 0 1-.523.523z"
        />
        <path
          id="AU"
          d="M60.862 52.805a.523.523 0 0 1-.523-.523.538.538 0 0 1 .325-.504.538.538 0 0 1 .736.391.538.538 0 0 1-.235.552.53.53 0 0 1-.302.085z"
        />
        <path
          id="AV"
          d="M59.279 52.167a.49.49 0 0 0 .49-.49.49.49 0 0 0-.49-.49.49.49 0 0 0-.49.49.49.49 0 0 0 .49.49z"
        />
      </defs>
    </svg>
  )
}

export default NoGiftsSvg

