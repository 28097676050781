export default class AuthApi{
  private locale ;
  private country

  constructor(locale , country) {
    this.locale = locale
    this.country = country
  }

  login(params){
    return this.request(`/users/sign_in?country=${this.country}&locale=${this.locale}` ,
      params
      )
  }

  register(params: {}){
    return this.request(`/users?country=${this.country}&locale=${this.locale}` ,
      params
    )
  }

  resetPassword(params){
    return this.request(`/users/password?country=${this.country}&locale=${this.locale}` ,params)
  }

  usersResetPasswordForm(params={}) {
    return this.request(`/users/password/new_password_form`, params, 'GET', {'Accept': 'text/javascript'});
  }


  private request(url ,body, method ='POST', headers={}){
    method = method.toUpperCase() 
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    return fetch(url + (method === 'GET' ? ('?' + ( new URLSearchParams( body ) ).toString()) : ''),
    {
      method: method,
      headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrf,
          ...headers
        },
      ...(method === 'POST' ? { body: JSON.stringify(body) } : {})
    }).then(async(response)=> {
      if(response.ok){
        if (response.headers.get('content-type').includes('application/json')) {
          return response.json();
        } else {
          return response.text();
        }
      }
      return Promise.reject(await response.json());
    })
  }




}