import React, { FC } from 'react'
import Translation from "../../helper/Translation";

interface AuthInputProps {
  error: string;
  type?: string;
  name: string;
  label: string;
  placeholder: string;
}

const AuthInput: FC<AuthInputProps> = ({error,label , placeholder,name,type= 'text',...props})=> {

 return (
     <div>
       <label className="block text-gray-700 text-start text-sm leading-5 font-normal" htmlFor={name}>{label}</label>
       <input className="mt-1 form-input border border-coolGray-300 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              type={type}
              placeholder={placeholder}
              required={true}
              name={name}
              id={name} />
       {error && (<span className="text-red-500 text-xs text-start leading-4 font-normal">{error}</span> )}
     </div>
 )

}

export default AuthInput