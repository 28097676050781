import React, { useEffect, useState } from 'react'
import { ChatService } from "bulbul-chat";
import ChatServiceHelper, { ChatContentUser } from "../src/helper/ChatServiceHelper";
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import moment from "moment"


const RecentChats = ({postingId, chatEndpoint ,chatUserToken })=> {
  const [isLoading , setIsLoading] = useState<Boolean>(true)
  const [channels , setChannels] = useState<ChatContentUser[]>([])

  useEffect(()=> {
    ChatService.getService(chatUserToken , chatEndpoint).getChannels({
      metadata: {relatedToId: postingId},
      perPage: 2
    }).then((channels)=> {
      setChannels((new ChatServiceHelper(channels)).getPreparedCollection())
      setIsLoading(false)
    })
  } , [])


  const emptyState = ()=> {
     return (
       <div className="flex flex-col items-center justify-center gap-3 pt-4">
         <div className="w-10 h-10 bg-emerald-50 flex items-center justify-center flex-shrink-0 rounded-lg">
           <i className="ri-chat-3-fill text-xl text-emerald-500" />
         </div>
         <div className="flex flex-col gap-1">
           <p className="text-base leading-6 font-semibold text-coolGray-700 text-center">{Translation.t('texts.no_chat_received')}</p>
           <p className="text-sm leading-0 font-normal text-coolGray-500">{Translation.t('texts.no_data_message')}</p>
         </div>
       </div>
     )
  }


  const displayChannels = ()=> {
    return (
      <div className={`flex flex-col divide-y divide-coolGray-100`}>
        {channels.map(channel => {
          return (
            <div key={channel.id} className="flex justify-between gap-2 items-center w-full py-4">
            <div className="flex gap-2 flex-1 items-center justify-center">
              <img className="w-10 h-10 rounded-full"
                   src={channel.userImageUrl} />
              <div className="flex flex-col gap-1 w-full">
                <div className="flex justify-between items-center w-full">
                  <p className="text-base leading-6 font-semibold text-coolGray-700">{channel.userName}</p>
                  <p className="text-coolGray-400">{moment(channel.createdAt).fromNow()}</p>

                </div>
                {channel.contentType == 'plaintext' && (<p className="text-sm leading-0 font-normal text-coolGray-400">{channel.content}</p>) }
              </div>
            </div>
            <div className="flex items-center justify-center gap-2 text-coolGray-700">
               <a href={channel.chatUrl}>
                 <i className="ri-arrow-right-s-line text-xl" />
               </a>
            </div>
          </div>
            )
        }
        )}
      </div>
    )
  }

   return (
     <>
       <div className="flex flex-col md:border md:rounded-md bg-white p-4">
         <div className="flex justify-between items-center w-full">
           <h3 className="text-lg leading-6 font-semibold text-coolGray-700">{Translation.t('texts.latest_chat')}</h3>
           <div className="flex items-center justify-center gap-2 text-emerald-500">
             <a href="/chat" className="text-base leading-6 font-normal">{Translation.t('buttons.view_all')}</a>
             <i className="ri-arrow-right-s-line text-xl"/>
           </div>
         </div>

         { isLoading ? <Loader /> : (
           channels.length == 0 ? emptyState() : displayChannels()
         )}




       </div>
     </>
   );
}

export default RecentChats

