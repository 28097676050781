import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { ProfileVerificationMethod } from "../../components/EditProfile";
import BackendCall from "../Components/BackendCall";
import Translation from "../helper/Translation";

export default ({ token, locale, isVerified, shouldVerifyWithAbsher }) => {
  const [absherVerificationMethod, setAbsherVerificationMethod] = useState<ProfileVerificationMethod>();
  const [verificationMethods, setVerificationMethods] = useState<ProfileVerificationMethod[]>();
  const [isVerificationModalVisible, setVerificationModalVisible] = useState(false);
  const [isOtpModalVisible, setOtpModalVisible] = useState(false);

  useEffect(() => {
    if (!shouldVerifyWithAbsher) return
    BackendCall.i({ locale, token })
      .getVerificationMethods()
      .then((methods: ProfileVerificationMethod[]) => {
        setVerificationMethods(methods);
        setAbsherVerificationMethod(methods.find(m => m.key == "moi"));
      })
      .catch((error) => { });
  }, [])

  useEffect(() => {
    if (isVerified || !shouldVerifyWithAbsher || !absherVerificationMethod) return
    setVerificationModalVisible(absherVerificationMethod ? true : false)
  }, [absherVerificationMethod])

  const updateVerificationMethods = (method) => {
    let newVerificationMethods = verificationMethods
    newVerificationMethods = newVerificationMethods.map((item) => {
      return item.key == method.key ? method : item
    })
    setVerificationMethods(newVerificationMethods)
  }

  const afterVerificationMethodUpdated = (method) => {
    setVerificationModalVisible(false)
    if (method.afterInput == 'ask_otp') {
      setOtpModalVisible(true)
      setAbsherVerificationMethod(method) // updating the method with the new object the contains the updated metadata (like exp. time)
    } else {
      toast.success(Translation.t('texts.verification_wait_approve'))
    }
  }

  const resendVerificationCode = (method) => {
    BackendCall.i({ token, locale })
      .verifyProfileCodeResend(method.key, String(method.metadata.id))
      .then((newMethod) => {
        setAbsherVerificationMethod(newMethod) // updating the method with the new object the contains the updated metadata (like exp. time)
      })
  }

  return { isVerificationModalVisible, isOtpModalVisible, absherVerificationMethod, updateVerificationMethods, afterVerificationMethodUpdated, resendVerificationCode }
}
