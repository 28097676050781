import React from "react";
import 'remixicon/fonts/remixicon.css';
import RadioButton from "./RadioButton";

export type ExtraOption = {
  id: string;
  description: string;
  selected: boolean;
};

type PaymentMethodCardProps = {
  action?: any;
  onDelete?: () => void;
  id?: string;
  last4?: string;
  title?: string;
  name?: string;
  expiryDate?: string;
  containerStyle?: any;
  iconName: string;
  selected?: boolean;
  onSelect?: (id: string) => any;
  options?: ExtraOption[];
  onOptionChange?: (id: string) => void;
};

const getPaymentMethodIcon = (brand: string, selected: boolean) => {
  switch (brand.toLowerCase()) {
    case "visa":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center`}
        >
          <i className={`ri-visa-line ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />
        </div>
      );
    case "mada":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center`}
        >
          <i className={`ri-visa-line ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />
        </div>
      );
    case "apple_pay":
      return (
        <div className={`w-9 h-9 rounded-xl flex justify-center items-center `}>
          <i className={`ri-apple-fill ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />
        </div>
      );
    case "mastercard":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center`}
        >
          <i className={`ri-mastercard-line ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />
        </div>
      );
    case "wallet":
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center`}
        >
          <i className={`ri-wallet-2-fill ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />
        </div>
      );
    default:
      return (
        <div
          className={`w-9 h-9 rounded-xl flex justify-center items-center`}
        >
          <i className={`ri-bank-card-line ri-lg ${selected ? 'text-emerald-500' : 'text-coolGray-500'}`} />;
        </div>
      );
  }
};

export default function PaymentMethodSquareCard({
  action,
  onDelete,
  id,
  last4,
  title,
  name,
  expiryDate,
  containerStyle = {},
  iconName,
  selected = false,
  onSelect,
  options = [],
  onOptionChange = () => null,
}: PaymentMethodCardProps) {
  return (
    <div
      className={` 
				${action ? "mb-2" : ""} 
				${containerStyle ?? ''} 
        ${selected ? "border-emerald-200 bg-emerald-50" : "border-coolGray-200 bg-coolGray-50"}
				px-2 py-3 border rounded-lg flex-1`
      }
    >
      <button className="w-full" onClick={() => onSelect(id)}>
        <div className="mb-2">
          {getPaymentMethodIcon(iconName || "", selected)}
        </div>

        <div className="flex flex-col flex-1 items-start pe-4">
          {last4 ? (
            <div className="flex justify-start items-center">
              {Array(3)
                .fill(undefined)
                .map((i, k) => (
                  <div key={k} className="flex items-center me-2">
                    {Array(4)
                      .fill(undefined)
                      .map((i, key) => (
                        // @ts-ignore
                        <i
                          className="ri-checkbox-blank-circle-fill ri-xxs me-px"
                          key={key}
                        />
                      ))}
                  </div>
                ))}
              <p className="font-medium text-coolGray-700 text-sm">{last4}</p>
            </div>
          ) : (
            <div className="flex items-center justify-start">
              <p className="text-coolGray-700 font-medium text-sm">{title}</p>
            </div>
          )}
          {name ? <p className="my-1 text-coolGray-500 text-sm ld-5">{name}</p> : null}
          {expiryDate ? (
            <div className="flex space-x-4 items-center">
              <p className="text-coolGray-400 text-xs">EXP</p>
              <p className="text-coolGray-400 text-sm">{expiryDate}</p>
            </div>
          ) : null}
        </div>

        {action}
      </button>
      {/* {selected && (
        <div className="flex flex-col w-full space-between ml-24">
          {options.map((option) => (
            <button
              className="w-full"
              onClick={() => {
                onOptionChange(option.id);
              }}
              key={option.id}
            >
              <RadioButton
                size="small"
                value={option.selected}
                containerStyle='my-0'
                inActiveColor='emerald-500'
                onChange={() => {
                  onOptionChange(option.id);
                }}
              />
              <p className="ps-1">{option.description}</p>
            </button>
          ))}
        </div>
      )} */}
    </div >
  );
}
