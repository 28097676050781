window.auth_modal = function () {
  return {
      open: false,
      mode: 'login',
      user_signed_in: window.user_signed_in,
      openModal(auth_mode = 'login') {
          if (this.user_signed_in) return;
          this.open = true
          this.mode = auth_mode
      },
      closeModal(auth_mode = 'login') {
          this.open = false
          this.mode = auth_mode
      }
  }
}