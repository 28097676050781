import React, { useEffect, useState } from "react";
import BackendCall from "../../src/Components/BackendCall";
import TelInput from "../TelInput";
import BaseLayout from "./BaseLayout";
import Header from "./Header";
import Faqs from "../Faqs";
import Translation from "../../src/helper/Translation";
import { country } from "../Fraud";

interface FraudProps {
  locale: string;
  token: string;
  dispatch: React.Dispatch<any>;

  countries: country[]
}
function FraudSearch ({ locale, token ,dispatch, countries }: FraudProps) {
  const [number , setNumber] = useState<string>(null)
  const [fullNumber , setFullNumber] = useState<string>(null)
  const [mobileValid,setMobileValid] = useState(false)
  const submitForm = ()=> {
    if(!mobileValid)return
    BackendCall.i({ token: token , locale: locale })
    .checkBlackListNumber({ number: fullNumber })
    .then((response)=> {
      dispatch({  type: 'update_key', payload: [
          { key: 'isBlocked', value:  response.isBlocked},
          {key:'mode' , value: 'display_checking_result'},
          {key:'number' , value: fullNumber},
        ]
      })
    })
  }
  const form = ()=> {
    return (
      <>
        <div className="flex flex-col gap-2 justify-start w-full">
          <label htmlFor="" className="text-coolGray-700 text-sm font-medium">{Translation.t("frauds.labels.fraud_number")}</label>
          <TelInput countries={countries} onChange={(number)=> setNumber(number)}
                    onCountryCodeChange={(countryCode)=> dispatch({
                      type: 'update_key' ,
                      payload: { key:'countryCode' , value:countryCode }
                    })}
                    fullNumber={(full)=>setFullNumber(full) }
                    valid={(valid)=> setMobileValid(valid)}
                    value={number} />
        </div>
        <button className={`text-white text-base leading-6 font-normal ${mobileValid ? 'bg-emerald-500': 'bg-coolGray-400 cursor-not-allowed'} w-full flex items-center justify-center rounded-md py-2`} onClick={submitForm}>{Translation.t('buttons.search')}</button>
      </>
    )
  }
  return (
    <BaseLayout>
      <>
        <Header title={Translation.t("frauds.labels.search_for_frauds")} description={Translation.t("frauds.messages.search_fraud")} />
        {form() }
        <Faqs section="fraud_report" token={token} locale={locale}/>
      </>
    </BaseLayout>
  )
}

export default FraudSearch