import React from 'react';
import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';

type Props = {
  title: string;
  description: string;
};

const AuctionTitleWithDescription = ({title, description}: Props) => {
	return (
		<div className="bg-white rounded-2xl px-2 pt-6 pb-1">
			<div className="flex items-center justify-center mb-2">
				<AuctionRulesSvg />
			</div>
			<p className="font-bold text-2xl text-coolGray-700 text-center mb-1">
				{title}
			</p>
			<p className="text-center text-sm text-coolGray-400 px-4 mb-1">
				{description}
			</p>
		</div>
	);
}

export default AuctionTitleWithDescription;