import isEqual from 'lodash/isEqual';
import React, {Reducer} from 'react';

import {
  RemoteItemsLoaderAction,
  RemoteItemsLoaderInitialState,
  RemoteItemsLoaderReducer,
  RemoteItemsLoaderState,
} from '../Stores/RemoteItemsLoaderStore';

type DataLoaderType = (
  params: any,
) => Promise<any>;

type UseRemoteDataOptions = {
  dataLoader: DataLoaderType;
  params?: any;
  loadPerPage?: 1 | 2 | 20 | 50 | 100;
  requestCounter?: number;
};

type LoadingType = {
  paginationLoading: boolean;
  filterLoading: boolean;
  pullToRefreshLoading: boolean;
  noActionLoading: boolean;
};

type LoadDataType = 'pagination' | 'pull_refresh' | 'update_filter' | 'full';

type UseRemoteDataReturn<Item> = [
  RemoteItemsLoaderState<Item> & LoadingType,
  (params: any, loadType?: LoadDataType) => void,
];

/**
 * @typedef {Object} RemoteData
 * @property {boolean} reset - Has the data reset
 * @property {boolean} isLoading- Is data loading
 * @property {boolean} paginationLoading - Is pagination loading
 * @property {boolean} filterLoading - Is filter loading
 * @property {boolean} pullToRefreshLoading - Is pull to refresh loading
 * @property {boolean} noActionLoading - Is no action (full) loading
 * @property {function} bottomLoaderTimer - Delay to prevent screen lagging due to multiple fast scroll/data loading
 * @property {number} lastLoadedpage - Number of the last loaded page
 * @property {object[]} items - Array of items
 * @property {string} error - The error message
 * @property {boolean} isLastPage - Is the current page the last page
 * @property {number} counter - Current counter number
 * @property {object} requestData - The data (params) the have been requested
 * @property {object} cancellationSource - Request cancel object (token)
 */

/**
 * Hook to wrap `RemoteItemsLoaderStore` to ease the implementation.
 * @param {Object} config - The configration params.
 * @param {Function} config.dataLoader - Async function that return the data list.
 * @param {number} config.loadPerPage - The number of item per page.
 * @param {number} config.requestCounter - The initial count of the current request.
 * @returns {RemoteData}
 */
const useRemoteData = <Item = any>({
  dataLoader,
  loadPerPage = 20,
  requestCounter = 0,
}: UseRemoteDataOptions): UseRemoteDataReturn<Item> => {
  const [remoteData, remoteDataDispatch] = React.useReducer<
    Reducer<RemoteItemsLoaderState<Item>, RemoteItemsLoaderAction<Item>>
  >(RemoteItemsLoaderReducer, RemoteItemsLoaderInitialState);

  const [loading, setLoading] = React.useState<LoadingType>({
    paginationLoading: false,
    filterLoading: false,
    pullToRefreshLoading: false,
    noActionLoading: false,
  });

  const loadData = async (
    params: any = {},
    loadType: LoadDataType = 'full',
  ) => {
    if (loadType === 'pagination' && remoteData.bottomLoaderTimer) {
      return null;
    }

    const page = loadType !== 'pagination' ? 0 : remoteData.lastLoadedpage;
    const loadedPage =
      loadType === 'pagination' || page === 0 ? page + 1 : page;

    const requestData = {
      page: loadPerPage ? {number: loadedPage, size: loadPerPage} : undefined,
      ...params,
    };

    if (remoteData.isLoading && isEqual(requestData, remoteData.requestData)) {
      return null;
    }

    const myRequestCounter = ++requestCounter;

    remoteDataDispatch({
      type: 'startLoading',
      reset: loadType === 'full',
      requestData,
      requestCounter: myRequestCounter,
    });

    setLoading({
      paginationLoading: loadType === 'pagination',
      filterLoading: loadType === 'update_filter',
      pullToRefreshLoading: loadType === 'pull_refresh',
      noActionLoading: loadType === 'full',
    });

    try {
      const remoteRecords = await dataLoader(requestData);

      // Add a 1 second delay to prevent screen lagging due to multiple fast scroll/data loading
      const bottomLoaderTimer = setTimeout(() => {
        remoteDataDispatch({type: 'clearTimeout'});
      }, 1000);

      remoteDataDispatch({
        type: 'loaded',
        reset: loadType !== 'pagination',
        items: remoteRecords,
        loadPerPage,
        bottomLoaderTimer,
        requestCounter: myRequestCounter,
      });
    } catch (error) {
      remoteDataDispatch({
        type: 'loadingFailed',
        error,
        requestCounter: myRequestCounter,
      });
    } finally {
      setLoading({
        paginationLoading: false,
        filterLoading: false,
        pullToRefreshLoading: false,
        noActionLoading: false,
      });
    }
  };

  return [{...loading, ...remoteData}, loadData];
};

export default useRemoteData;
