import React, { useState } from 'react';
import 'remixicon/fonts/remixicon.css'
import useForkRef from '../Hooks/useForkRef';

export type status = 'normal' | 'error' | 'warning' | 'success';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  containerStyle?: string;
  errorMessage?: any;
  disabled?: boolean;
  startIcon?: string;
  startIconOnPress?: () => void;
  endIcon?: string;
  endIconOnPress?: () => void;
  onPressPrefix?: () => void;
  message?: string;
  suffix?: string;
  status?: status;
  prefix?: string;
  label?: string;
  labelStyle?: string;
  multiline?: boolean;
  lettersCount?: number;
};
const endIconColor = {
  normal: 'text-coolGray-600',
  success: 'text-emerald-500',
  error: 'text-red-500',
  warning: 'text-orange-800',
  disabled: 'text-coolGray-400',
};
const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      status = 'normal',
      prefix,
      suffix,
      label,
      labelStyle,
      onFocus,
      onBlur,
      startIcon,
      startIconOnPress,
      onPressPrefix,
      endIcon,
      endIconOnPress,
      containerStyle,
      disabled = false,
      lettersCount,
      ...props
    }: InputProps,
    forwardedRef,
  ) => {
    const borderColor = {
      normal: 'border-coolGray-300 focus-within:border-coolGray-400',
      success: 'border-emerald-500 focus-within:border-emerald-600',
      error: 'border-red-500 focus-within:border-red-600',
      warning: 'border-orange-800 focus-within:border-orange-900',
      disabled: 'border-coolGray-400',
    };
    const handleInputRefWarning = React.useCallback((instance) => {
      if (process.env.NODE_ENV !== 'production') {
        if (instance && instance.nodeName !== 'INPUT' && !instance.focus) {
          console.error(
            [
              'You have provided a `inputComponent` to the input component',
              'that does not correctly handle the `ref` prop.',
              'Make sure the `ref` prop is called with a HTMLInputElement.',
            ].join('\n'),
          );
        }
      }
    }, []);
    const handleInputRef = useForkRef(forwardedRef, handleInputRefWarning);

    return (
      <div className={`${containerStyle}`}>
        <div className="flex items-center justify-between">
          {label && (
            <p
              className={`text-coolGray-700 text-start font-medium mb-2 ${labelStyle}`}
            >
              {label} {props.required && <span className="text-red-500">*</span>}
            </p>
          )}
          {lettersCount && (
            <p className="text-coolGray-300">
              {props.value?.length}/{lettersCount}
            </p>
          )}
        </div>
        <div
          className={`overflow-hidden border rounded-md flex ${disabled ? 'border-coolGray-300 bg-coolGray-300' : (borderColor[status] + ' bg-white')} ${prefix ? 'pe-4' : 'px-0'}`}
        >
          {prefix && (
            <button onClick={onPressPrefix}>
              <div
                className={`w-16 flex justify-center items-center h-full items-center border-r rounded-l-md ${disabled ? 'border-coolGray-300 bg-coolGray-300' : (borderColor[status] + 'bg-coolGray-100')}`}
              >
                <p className="text-coolGray-400">
                  {prefix}
                </p>
              </div>
            </button>
          )}
          <div
            className={`flex w-full items-center ${prefix ? 'ps-2' : ''}`}
          >
            {startIcon && (
              <button onClick={startIconOnPress}>
                <i className={`me-2 ri-2x ${startIcon} ${disabled ? 'text-coolGray-400' : 'text-coolGray-600'}`} />
              </button>
            )}

            {props.multiline ? (
              <textarea
                disabled={disabled}
                {...props}
                className={`resize-none flex-1 border-0 focus:outline-none focus:ring-0 font-medium text-coolGray-600 ${disabled ? 'placeholder-coolGray-400' : 'placeholder-coolGray-300'}`}
              ></textarea>
            ) : (
              <input
                disabled={disabled}
                {...props}
                ref={handleInputRef}
                type="text"
                className={`font-medium flex-1 border-0 focus:outline-none focus:ring-0 text-coolGray-600 ${disabled ? 'placeholder-coolGray-400' : 'placeholder-coolGray-300'}`}
              />
            )}

            {suffix && (
              <p
                className={`ml-2 font-medium ${disabled ? 'text-coolGray-400' : 'text-coolGray-600'}`}
              >
                {suffix}
              </p>
            )}
            {endIcon && (
              <button onClick={endIconOnPress}>
                <i className={`ml-2 ri-2x ${endIcon} ${disabled ? 'text-coolGray-400' : endIconColor[status]}`} />
              </button>
            )}
          </div>
        </div>
        {!!props.message && (
          <p
            className={`mt-2 ${disabled ? 'text-coolGray-400' : status === 'normal' ? 'text-coolGray-400' : endIconColor[status]}`}
          >
            {props.message}
          </p>
        )}
        {!!props.errorMessage && (
          <p
            className="mt-2 text-red-500"
          >
            {props.errorMessage}
          </p>
        )}
      </div>
    );
  },
);

export default Input;
