import { HttpUnprocessableEntity } from "../Components/BackendCall";

export type RemoteItemLoaderState<Item> = {
  isLoading: boolean;
  item?: Item;
  error?: string;
};

export const RemoteItemLoaderInitialState = {
  isLoading: false,
  item: undefined,
  error: undefined,
};

export type RemoteItemLoaderAction<Item> =
  | {
      type: 'startLoading';
    }
  | {
      type: 'loaded';
      item: Item;
    }
  | {
      type: 'loadingFailed';
      error: Error;
    };

export function RemoteItemLoaderReducer<Item>(
  state: RemoteItemLoaderState<Item>,
  action: RemoteItemLoaderAction<Item>,
) {
  if (action.type === 'startLoading') {
    return {...state, isLoading: true, error: undefined};
  } else if (action.type === 'loaded') {
    return {...state, item: action.item, isLoading: false, error: undefined};
  } else if (action.type === 'loadingFailed') {
    let message = action.error.message;
    if (action.error instanceof HttpUnprocessableEntity) {
      message = action.error.messageStr();
    }
    return {...state, isLoading: false, error: message};
  }
  return state;
}
