import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))
import Pagination from '../src/Components/Pagination'
import Loader from "../src/Components/icon/Loader";
import Translation from '../src/helper/Translation'
import 'remixicon/fonts/remixicon.css'

import 'remixicon/fonts/remixicon.css'
import isEmpty from 'lodash/isEmpty'
import { PostingAuction } from "@shobbak/react-services/dist/Entities";
import StatusGroups from "../src/Components/Auction/StatusGroups";
import MyAuctionItem from "../src/Components/Auction/MyAuctionItem";
import EmptyState from "../src/Components/Auction/EmptyState";

export default ({ locale, token }) => {
  BackendCall.i({ token, locale })

  const [statusGroup, setStatusGroup] = useState<string[]>([]);
  const [isAscSorting, setIsAscSorting] = useState(false);
  const [auctions, setAuctions] = useState<PostingAuction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const auctionRefs = useRef(auctions)

  const setAuctionsWithRefs = (auctions) => {
    setAuctions(auctions)
    auctionRefs.current = auctions
  }

  const getAuctions = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getMyAuctions(
      { 
        status_group: statusGroup,
        role: 'bidder'
      },
      page,
      15,
      { created_at: isAscSorting ? 'asc' : 'desc' },
    ).then(({records, meta}) => {
      setAuctionsWithRefs(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getAuctions({ page: 1 })
  }, [currentPage, statusGroup, isAscSorting])

  const onSelectStatusGroup = (data) => {
    setStatusGroup(Object.keys(data));
  };

  const onPressItem = (item) => {
    if (item.status === 'initiated') {
      window.location.href = `/postings/${item.posting.id}/start_auction`
    } else {
      window.location.href = `/postings/${item.posting.id}`
    }
  };

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
      {statusGroup.length == 0 && auctions.length == 0 && !isLoading ? (
          <ProfileCard>
            <EmptyState
              title={Translation.t('texts.auctions.empty_bids.title')}
              subtitle={Translation.t('texts.auctions.empty_bids.description')}
            />
          </ProfileCard>
        ) : (
          <ProfileCard title={Translation.t('breadcrumb.my_bids')}>
            <div className="flex flex-col">

              <div className="flex items-center justify-between space-s-4">
                <StatusGroups
                  onSelect={onSelectStatusGroup}
                  role="bidder"
                />
                <button
                  onClick={() => setIsAscSorting(!isAscSorting)}
                  className="flex items-center justify-center"
                >
                  <i className={`${isAscSorting ? 'ri-sort-asc' : 'ri-sort-desc'} ri-lg`} />
                </button>
              </div>
              <hr />


              {isLoading && <Loader />}


              {!isLoading && (
                <>
                  <div className="flex flex-col space-y-2 my-4">
                    {auctions.map((auction) => {
                      return (
                        <div key={auction.id}>
                          <MyAuctionItem
                              key={auction.id}
                              item={auction}
                              onPress={() => {
                                onPressItem(auction);
                              }}
                            />
                        </div>
                      )
                    })}

                  </div>

                  {auctions.length == 0 && (
                    <div
                      className="p-2 flex items-center justify-center w-full">{Translation.t('posting_offer.tabs.not_results')}</div>
                  )}

                  {!isEmpty(meta) && auctions.length != 0 && (
                    <Pagination {...meta} onPaginationChange={({ page }) => {
                      getAuctions({ page })
                    }} />
                  )}
                </>
              )}
            </div>
          </ProfileCard>
        )}
      </ProfileLayout>
    </Suspense>
  );
}
