import React, { useState } from 'react';
import 'remixicon/fonts/remixicon.css'
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import Loader from "./icon/Loader";
import camelCase from 'lodash/camelCase'
import { toast } from "react-toastify";
import { Theme } from '@shobbak/react-services';
import Translation from '../helper/Translation';
const { Colors } = Theme;


type ValidationType = {
  isEmpty: boolean;
  isValid: boolean;
  errorMessage?: string;
}|null;

type AddPaymentMethodFormProps = {
  publicKey?: string;
  onCancel: () => void;
  onSuccess: (cardEvent: FrameCardTokenizedEvent&{name: string}) => void;
  customRender?: (renderForm: () => any, renderButton: () => any) => any;
};

export default function AddPaymentMethodForm({
                                               publicKey,
                                               onCancel,
                                               onSuccess,
                                               customRender,
                                             }: AddPaymentMethodFormProps) {
  const [cardholder, setCardholder] = React.useState({
    name: '',
  });
  const errorBagRef = React.useRef({})
  const [errorBag, setErrorBag] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFramesLoading, setIsFramesLoading] = React.useState(true)


  const validateCard = (errors) => {
    const prevErrorBag = errorBagRef.current
    if (errors.element && !errors.isValid) {
      errorBagRef.current = { ...prevErrorBag, [camelCase(errors.element)]: "invalid " + camelCase(errors.element) }
    }
    if (errors.isValid) {
      delete prevErrorBag[camelCase(errors.element)]
      errorBagRef.current = { ...prevErrorBag }
    }

    setErrorBag(errorBagRef.current)

  }
  if (!publicKey) {
    return <Loader/>;
  }
  return (
    // @ts-ignore
    <Frames
      cardTokenized={(e) => {
        onSuccess(e)
      }}
      ready={() => {setIsFramesLoading(false)}}
      frameValidationChanged={(errors) => {
        validateCard(errors)
      }}
      config={{
        debug: true,
        publicKey: publicKey,
        cardholder: {
          name: cardholder.name,
        },
        localization: {
          cardNumberPlaceholder: "3344 2315 6674 1275",
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: 'CVV',
        },
        style: {
          base: {
            color: Colors['coolGray']['600']
          },
          placeholder: {
            base: {
              color: Colors['coolGray']['300']
            }
          }
        }
      }}
    >
      {isFramesLoading && <Loader /> }

      <div className={`flex-col gap-2 ${isFramesLoading ? 'hidden' : 'flex'}`}>
        <div
          className="flex items-center justify-center gap-1 mb-2 "
        >
          <i className="ri-shield-check-fill text-emerald-500"/>

          <p
            className="text-black"
          >
            Your payment info is stored securely
          </p>
        </div>
        
        <div
          className="flex items-center justify-center mb-2 gap-2"
        >
          <MadaIcon />
          <MastercardIcon />
          <VisaIcon />
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <label>{Translation.t('inputs.card_number.label')}</label>  
            <CardNumber
              className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'}/>
            {errorBag?.cardNumber && ( <span className={`text-red-500 -mt-2 text-sm`}>{errorBag?.cardNumber}</span> )}
          </div>

          <div>
            <label>{Translation.t('inputs.card_name.label')}</label>  
            <input
              className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-coolGray-600 placeholder-coolGray-300 font-serif'}
              placeholder="Mahmoud Ahmed"
              onChange={(e) => {
                setCardholder({
                  name: e.target.value,
                });
              }}
            />
          </div>

          <div className={'flex gap-4'}>
            <div className={'flex flex-col'}>
              <label>{Translation.t('inputs.card_expiry_date.label')}</label>  
              <ExpiryDate
                className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'}/>
              {errorBag?.expiryDate && ( <span className={`text-red-500 text-sm`}>{errorBag?.expiryDate}</span> )}
            </div>
            <div className={'flex flex-col'}>
              <label>{Translation.t('inputs.card_cvv.label')}</label>  
              <Cvv
                className={'w-full h-10 mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'}/>
              {errorBag?.cvv && ( <span className={`text-red-500 text-sm`}>{errorBag?.cvv}</span> )}
            </div>
          </div>
        </div>


        <button
          className={`${isLoading ? 'cursor-not-pointer' : 'cursor-pointer'} mt-12 whitespace-nowrap bg-emerald-600 border border-transparent rounded-md py-2 px-4 items-center justify-center text-base font-normal  leading-6 text-white hover:bg-emerald-700 active:bg-emerald-600 md:inline-flex`}
          onClick={() => {
            setIsLoading(true)
            Frames
            .submitCard()
            .then(() => {
              setIsLoading(true)
            })
            .catch(error => {
              toast.error(error)
              setIsLoading(false)
            })
          }}
        >
          <div className={'flex gap-2 items-center justify-center'}>
            {isLoading && (
              <svg className="animate-spin -ms-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={4}/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
              </svg>
            )}
            <span>Save</span>
          </div>
        </button>
      </div>


    </Frames>
  );
}

const MadaIcon = () => {
  return (
    <svg width="55" height="19" viewBox="0 0 55 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9852 11.2734H0V18.9279H22.9852V11.2734Z" fill="#84B740"/>
      <path d="M22.9852 0.830078H0V8.49127H22.9852V0.830078Z" fill="#259BD6"/>
      <path d="M45.9705 17.3734L45.8681 17.394C45.5132 17.4622 45.3835 17.4894 45.1242 17.4894C44.5235 17.4894 43.8138 17.1825 43.8138 15.7362C43.8138 14.9925 43.9366 14.0033 45.0558 14.0033H45.0627C45.2537 14.017 45.4722 14.0375 45.8816 14.1603L45.9704 14.1875L45.9705 17.3734ZM46.1548 10.1625L45.9705 10.1966V12.8709L45.8068 12.8231L45.7589 12.8094C45.5747 12.7549 45.1516 12.6321 44.7421 12.6321C42.5036 12.6321 42.0327 14.3241 42.0327 15.743C42.0327 17.6873 43.1247 18.8061 45.0288 18.8061C45.834 18.8061 46.4278 18.7243 47.0284 18.5264C47.5812 18.3491 47.7791 18.0966 47.7791 17.5576V9.88281C47.2467 9.97829 46.6939 10.0738 46.1548 10.1625Z" fill="#27292D"/>
      <path d="M52.6245 17.4229L52.529 17.4502L52.1878 17.5389C51.8671 17.6207 51.5804 17.6685 51.362 17.6685C50.8365 17.6685 50.5225 17.4092 50.5225 16.9659C50.5225 16.6793 50.6522 16.1949 51.5121 16.1949H52.6244V17.4229H52.6245ZM51.8397 12.5996C51.1504 12.5996 50.4406 12.7224 49.5603 12.9953L48.9869 13.1658L49.1781 14.462L49.7378 14.2778C50.3247 14.0867 51.0549 13.9639 51.6009 13.9639C51.8467 13.9639 52.5974 13.9639 52.5974 14.7758V15.1306H51.5532C49.6491 15.1306 48.7687 15.7377 48.7687 17.0407C48.7687 18.1527 49.5809 18.8213 50.9457 18.8213C51.3688 18.8213 51.9558 18.7394 52.4608 18.6165L52.4881 18.6097L52.5154 18.6165L52.686 18.6438C53.2183 18.7393 53.771 18.8349 54.3102 18.9371V14.6801C54.3102 13.3022 53.4776 12.5996 51.8397 12.5996Z" fill="#27292D"/>
      <path d="M39.3642 17.4229L39.2687 17.4502L38.9275 17.5389C38.6068 17.6207 38.3269 17.6685 38.1017 17.6685C37.5762 17.6685 37.2622 17.4092 37.2622 16.9659C37.2622 16.6793 37.3919 16.1949 38.245 16.1949H39.3574V17.4229H39.3642ZM38.5862 12.5996C37.8901 12.5996 37.1872 12.7224 36.3069 12.9953L35.7335 13.1658L35.9246 14.462L36.4842 14.2778C37.0712 14.0867 37.8014 13.9639 38.3474 13.9639C38.5931 13.9639 39.3438 13.9639 39.3438 14.7758V15.1306H38.2997C36.3956 15.1306 35.5085 15.7377 35.5085 17.0407C35.5085 18.1527 36.3206 18.8213 37.6923 18.8213C38.1154 18.8213 38.7024 18.7394 39.2074 18.6165L39.2347 18.6097L39.262 18.6165L39.4257 18.6438C39.9648 18.7393 40.5108 18.8349 41.0499 18.944V14.687C41.0568 13.2955 40.2242 12.5996 38.5862 12.5996Z" fill="#27292D"/>
      <path d="M32.178 12.6104C31.3113 12.6104 30.5948 12.897 30.3286 13.0197L30.2604 13.0539L30.199 13.0061C29.8304 12.74 29.2913 12.6035 28.5406 12.6035C27.8786 12.6035 27.2576 12.699 26.5819 12.8968C26.0018 13.0741 25.7766 13.3539 25.7766 13.8793V18.7435H27.592V14.2476L27.6807 14.2204C28.0492 14.0976 28.2677 14.0771 28.4792 14.0771C29.0047 14.0771 29.2709 14.3568 29.2709 14.9026V18.7502H31.0589V14.8275C31.0589 14.5955 31.0111 14.4591 31.0044 14.4318L30.9429 14.3158L31.0657 14.2613C31.3387 14.1385 31.639 14.0771 31.9528 14.0771C32.3145 14.0771 32.7445 14.2204 32.7445 14.9026V18.7502H34.5257V14.7253C34.5257 13.2994 33.7613 12.6104 32.178 12.6104Z" fill="#27292D"/>
      <path d="M51.2868 5.82379C51.0207 5.82379 50.577 5.79651 50.229 5.72831L50.1266 5.70779V3.08124C50.1266 2.86288 50.0857 2.72648 50.0788 2.70596L50.0242 2.59685L50.1403 2.54905C50.1676 2.53541 50.1949 2.52853 50.229 2.51489L50.2496 2.50125C50.2905 2.48761 50.3314 2.47397 50.3724 2.46033C50.3929 2.45345 50.4065 2.44669 50.4202 2.44669C50.8229 2.33757 51.1914 2.35121 51.3552 2.33757H51.362C52.4744 2.33757 52.6041 3.32675 52.6041 4.07042C52.5972 5.51684 51.8805 5.82379 51.2868 5.82379ZM51.28 0.830078C51.2664 0.830078 51.2459 0.830078 51.2322 0.830078C50.1881 0.830078 49.1166 1.11663 48.7344 1.67598C48.5296 1.9489 48.4137 2.29001 48.4068 2.69245V5.40084C48.4068 5.63284 48.359 5.72143 48.3522 5.74195L48.2908 5.85795H44.9945V3.97506H44.9876C44.9467 1.98982 43.7729 0.898277 42.0736 0.898277H41.8757H40.4152C40.347 1.38267 40.2924 1.72378 40.2242 2.20818H41.8757C42.7424 2.20818 43.1997 2.94496 43.1997 4.07742V5.97395L43.0837 5.91251C43.0631 5.90563 42.9199 5.85795 42.6946 5.85795H39.8419C39.7873 6.21947 39.7191 6.69022 39.644 7.16097H48.4136C48.7139 7.09953 49.0619 7.04497 49.3622 6.99729C49.8058 7.21565 50.6315 7.33153 51.1979 7.33153C53.102 7.33153 54.3373 6.05579 54.3373 4.09106C54.3306 2.14674 53.1363 0.870997 51.28 0.830078Z" fill="#27292D"/>
      <path d="M35.9043 7.96029H35.9861C37.8902 7.96029 38.7774 7.33262 38.7774 5.78409C38.7774 4.67203 37.9652 3.7852 36.6004 3.7852H34.8465C34.321 3.7852 34.007 3.48501 34.007 2.98022C34.007 2.6391 34.1367 2.21615 34.9966 2.21615H38.832C38.9138 1.71812 38.9548 1.40428 39.0299 0.90625H35.0443C33.188 0.90625 32.253 1.68396 32.253 2.98022C32.253 4.26271 33.0652 4.9313 34.43 4.9313H36.1839C36.7094 4.9313 37.0234 5.3475 37.0234 5.78409C37.0234 6.07064 36.8937 6.66415 36.0406 6.66415H35.7472L30.1305 6.65051H29.1068C28.24 6.65051 27.6327 6.15936 27.6327 5.02002V4.23543C27.6327 3.04841 28.1036 2.31163 29.1068 2.31163H30.7719C30.847 1.80684 30.8947 1.48612 30.9631 1.00861H28.8883H28.6904C26.9911 1.00861 25.8173 2.14795 25.7764 4.13307V4.20815V5.02002C25.8173 7.00527 26.9911 7.95353 28.6904 7.95353H28.8883H30.3488L33.3925 7.96041H35.2079L35.9043 7.96029Z" fill="#27292D"/>
    </svg>

  );
}

const MastercardIcon = () => {
  return (
    <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4883 4.37695H12.0543V18.1883H20.4883V4.37695Z" fill="#FF5F00"/>
      <path d="M13.0166 11.2825C13.0166 8.56553 14.2619 6.01836 16.3562 4.37685C12.5637 1.37685 7.01658 2.0561 4.01658 5.84855C1.01658 9.641 1.69582 15.1882 5.48827 18.1882C8.65809 20.6787 13.1298 20.6787 16.3562 18.1882C14.2619 16.4901 13.0166 13.9429 13.0166 11.2825Z" fill="#EB001B"/>
      <path d="M29.6581 16.7166V16.4336H29.7713V16.377H29.4883V16.4336H29.6015L29.6581 16.7166ZM30.2241 16.7166V16.377H30.1109L29.9977 16.6034L29.8845 16.377H29.8279V16.7166H29.8845V16.4336L29.9977 16.66H30.0543L30.1675 16.4336L30.2241 16.7166Z" fill="#F79E1B"/>
      <path d="M30.5071 11.2825C30.5071 16.1504 26.6015 20.0561 21.7336 20.0561C19.7524 20.0561 17.8279 19.3769 16.2996 18.1882C20.092 15.1882 20.7713 9.69761 17.7713 5.84855C17.3185 5.28251 16.8656 4.82968 16.2996 4.37685C20.092 1.37685 25.6392 2.0561 28.6392 5.84855C29.8845 7.37685 30.5071 9.30138 30.5071 11.2825Z" fill="#F79E1B"/>
    </svg>

  );
}

const VisaIcon = () => {
  return (
    <svg width="50" height="17" viewBox="0 0 50 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5482_263159)">
        <path d="M21.596 16.5845H17.629L20.1103 1.24219H24.0771L21.596 16.5845Z" fill="#00579F"/>
        <path d="M35.9769 1.61607C35.1944 1.30564 33.9533 0.962891 32.4188 0.962891C28.5012 0.962891 25.7424 3.05195 25.7255 6.03867C25.693 8.24226 27.7007 9.4662 29.2023 10.2009C30.7371 10.9517 31.2588 11.4418 31.2588 12.111C31.2432 13.1389 30.0186 13.6126 28.8765 13.6126C27.2926 13.6126 26.444 13.3684 25.1543 12.7965L24.632 12.5514L24.0768 15.9954C25.0073 16.4193 26.7215 16.7955 28.5012 16.812C32.6637 16.812 35.3736 14.7552 35.4057 11.5724C35.4215 9.82587 34.3614 8.48762 32.0758 7.39406C30.6883 6.69207 29.8386 6.21872 29.8386 5.50046C29.8548 4.84749 30.5573 4.17869 32.1235 4.17869C33.4132 4.14594 34.3607 4.45593 35.0786 4.76614L35.4374 4.92906L35.9769 1.61607V1.61607Z" fill="#00579F"/>
        <path d="M41.2494 11.1493C41.5761 10.2679 42.8332 6.85661 42.8332 6.85661C42.8167 6.88936 43.1593 5.95894 43.3552 5.38776L43.6324 6.70975C43.6324 6.70975 44.3836 10.3822 44.5468 11.1493C43.9268 11.1493 42.033 11.1493 41.2494 11.1493ZM46.1462 1.24219H43.0777C42.1314 1.24219 41.4125 1.51943 41.0043 2.51515L35.1118 16.5843H39.2742C39.2742 16.5843 39.9595 14.6907 40.1068 14.2828C40.5635 14.2828 44.6127 14.2828 45.2002 14.2828C45.3141 14.8215 45.6735 16.5843 45.6735 16.5843H49.3466L46.1462 1.24219V1.24219Z" fill="#00579F"/>
        <path d="M14.3155 1.24219L10.4305 11.7042L10.006 9.58236C9.2877 7.13406 7.03508 4.47404 4.52127 3.15141L8.07982 16.5682H12.2749L18.5103 1.24219H14.3155V1.24219Z" fill="#00579F"/>
        <path d="M6.82293 1.24219H0.440357L0.375061 1.55218C5.35387 2.82536 8.65124 5.8943 10.006 9.58301L8.61848 2.53185C8.39005 1.55197 7.68806 1.27451 6.82293 1.24219Z" fill="#FAA61A"/>
      </g>
      <defs>
        <clipPath id="clip0_5482_263159">
          <rect width="48.9715" height="15.8491" fill="white" transform="translate(0.375061 0.962891)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
