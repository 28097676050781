import React, { useState, useEffect, useRef } from 'react';
import BackendCall from '../../src/Components/BackendCall';
import moment from 'moment';
import Translation from '../../src/helper/Translation';
import NotificationsList from './NotificationsList';
import NotificationIcon from './NotificationIcon';

export default ({ token, locale }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const dropdownRootRef = useRef(null);  // Ref for the dropdown container

  // Toggle the dropdown open/close state
  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  // Handler to close dropdown when clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRootRef.current && !dropdownRootRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Add event listener to detect clicks outside the dropdown when it's open
  useEffect(() => {
    // mousedown will close the dropdown if the event doesn't contain the root element of the dropdown
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    fetchLatestNotifications()

    const someNotificationsWasMarkedAsReadEventHandler = (event) => {
      fetchLatestNotifications()
    };

    const decrementUnreadNotificationsCountEventHandler = (event) => {
      setCount(prevCount => prevCount - 1)
    };

    // Listen for the 'decrement' events on window, because other components can clear notifications
    window.addEventListener('someNotificationsWasMarkedAsRead', someNotificationsWasMarkedAsReadEventHandler);
    window.addEventListener('decrementUnreadNotificationsCount', decrementUnreadNotificationsCountEventHandler);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('someNotificationsWasMarkedAsRead', someNotificationsWasMarkedAsReadEventHandler);
      window.removeEventListener('decrementUnreadNotificationsCount', decrementUnreadNotificationsCountEventHandler);
    };
  }, [])

  function fetchLatestNotifications() {
    BackendCall.i({ token, locale })
      .getNotifications({ page: { size: 5 }, with_stats: true })
      .then(res => {
        setCount(res?.meta?.notification_count)
        setNotifications(res?.records)
      })
      .catch(err => console.log({ err }))
  }

  function markAllAsRead() {
    setNotifications(prevNotifications => prevNotifications.map(n => ({ ...n, readedAt: true })))
    setCount(0)
    BackendCall.i({ token, locale })
      .markAllNotificationAsRead()
      .then(() => {
        // TODO show toast
      })
      .catch(err => console.log({ err }))
  }

  return (
    <div className="relative inline-block text-start" ref={dropdownRootRef}>
      <button
        onClick={toggleDropdown}
        className="relative inline-block"
      >
        <NotificationIcon count={count} />
      </button>

      {/* Dropdown menu */}
      <div
        className={`absolute flex flex-col gap-1 ${locale == 'ar' ? 'start-0' : 'end-0'} mt-2 p-2 w-96 origin-top-end rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50
          transition ease-in-out duration-300 transform ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div className="flex justify-between items-center">
          <p className="text-coolGray-700 text-sm leading-5">{Translation.t('texts.notifications')}</p>
          <button disabled={notifications.length == 0} onClick={() => markAllAsRead()} type="button" className={`text-coolGray-500 hover:text-coolGray-700 ${notifications.length == 0 ? 'hover:cursor-not-allowed' : ''}`}>
            <i className="ri-chat-check-line ri-lg"></i>
          </button>
        </div>

        <div className="py-1 flex flex-col space-y-1" role="none">
          <NotificationsList notifications={notifications} token={token} locale={locale} size='small' />
        </div>

        <a href="/notifications" className="w-full flex items-center justify-center gap-2 rounded-md bg-white px-2.5 py-1.5 font-normal text-emerald-500 ring-1 ring-inset ring-emerald-500 hover:bg-emerald-50 text-base leading-6">
          <i className="ri-notification-3-line ri-lg"></i>
          <span>{Translation.t('buttons.view_all_notifications')}</span>
        </a>

      </div>
    </div>
  );
};
