import React from 'react';

const PromotionAdSvg = ()=> {
 return (
   <svg width="81" height="75" viewBox="0 0 81 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M79.06 34.042v-18.92a6.115 6.115 0 0 0-4.68-5.984L43.362 1.671a6.115 6.115 0 0 0-7.55 5.983v59.692a6.115 6.115 0 0 0 7.55 5.983l31.03-7.575a6.115 6.115 0 0 0 4.68-5.984v-2.393" stroke="#FFD836"/>
    <path d="M79.06 40.671a1.197 1.197 0 0 0 0-2.297 1.197 1.197 0 0 0 0 2.297Zm0 4.26a1.196 1.196 0 0 0 0-2.297 1.197 1.197 0 0 0 0 2.297Zm0 4.273a1.197 1.197 0 0 0 0-2.298 1.197 1.197 0 0 0 0 2.298Zm0 4.272a1.197 1.197 0 0 0 0-2.298 1.197 1.197 0 0 0 0 2.298ZM22.253 13.147l2.56-8.376.372-1.197c.275-.874-1.101-1.197-1.365-.383l-2.56 8.377-.372 1.196c-.275.874 1.09 1.197 1.365.383ZM.425 14.452l8.03 3.446 1.125.479a.73.73 0 0 0 .969-.251.708.708 0 0 0-.251-.97l-8.03-3.446-1.125-.49a.754.754 0 0 0-.97.25.73.73 0 0 0 .252.982Z" fill="#10B981"/>
    <path d="m14.223 12.752-2.669-8.305-.383-1.196c-.323-.862-1.64-.527-1.364.383l2.669 8.305.37 1.196c.288.862 1.652.491 1.377-.37v-.013Z" fill="#FFD836"/>
    <path d="M21.027 25.267v28.846c0 .956.689 1.798 1.607 2.028l35.924 7.957c1.3.268 2.525-.688 2.525-1.99l.23-44.914c0-1.836-1.722-3.213-3.52-2.754l-35.197 8.799c-.919.268-1.569 1.071-1.569 2.028Z" fill="#fff"/>
    <path d="M59.018 64.596c-.191 0-.382-.039-.535-.077l-35.924-7.958a2.499 2.499 0 0 1-1.952-2.448V25.267a2.46 2.46 0 0 1 1.875-2.41l35.197-8.8c.995-.23 1.99-.038 2.793.612a3.226 3.226 0 0 1 1.262 2.563l-.19 44.915c0 .765-.345 1.454-.957 1.951a2.83 2.83 0 0 1-1.569.498ZM21.45 25.227s0 .038 0 0v28.885c0 .765.536 1.415 1.263 1.607l35.924 7.957a1.614 1.614 0 0 0 1.99-1.569l.229-44.914c0-.727-.345-1.415-.919-1.874a2.344 2.344 0 0 0-2.066-.421l-35.158 8.799c-.727.153-1.225.803-1.263 1.53Z" fill="#10B981"/>
    <path d="M67.739 18.457V62.11l-8.264 2.066.23-49.544c0-.88 8.034 3.826 8.034 3.826Z" fill="#fff"/>
    <path d="M59.476 64.596a.708.708 0 0 1-.268-.077c-.115-.076-.153-.19-.153-.344l.23-49.544a.53.53 0 0 1 .268-.459c.267-.153.612-.344 4.667 1.798 1.76.957 3.481 1.951 3.711 2.104a.42.42 0 0 1 .23.383V62.11a.448.448 0 0 1-.345.421l-8.264 2.066h-.076Zm.65-49.582-.23 48.626 7.423-1.837V18.725c-2.908-1.722-6.16-3.443-7.193-3.711Z" fill="#10B981"/>
    <path d="M66.828 32.766c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0 1.874c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm.92-.918c.497 0 .497.727 0 .766-.498-.039-.498-.766 0-.766Zm-1.878 0c.498 0 .498.727 0 .766-.497-.039-.497-.766 0-.766Zm1.878 1.837c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm-1.878 0c.498 0 .498.727 0 .765-.497 0-.497-.727 0-.765Zm-.92-2.793c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0 1.874c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 1.876c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.878-7.499c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm0 1.875c.497 0 .497.726 0 .765-.498 0-.498-.727 0-.765Zm.919-.918c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.877 1.874c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-.92-2.831c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm0 1.874c.496 0 .496.727 0 .766-.498 0-.498-.727 0-.766Z" fill="#10B981"/>
    <path d="M66.823 32.766c.497 0 .497.727 0 .766-.497-.039-.497-.766 0-.766Zm-1.873 0c.497 0 .497.727 0 .766-.498-.039-.498-.766 0-.766Zm1.878-7.461c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0 1.875c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm.919-.957c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.877 1.875c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-.92-2.793c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0 1.875c.496 0 .496.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 1.837c.497 0 .497.727 0 .765-.497 0-.497-.727 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm1.878-7.46c.497 0 .497.727 0 .766-.498-.039-.498-.766 0-.766Zm0 1.874c.497 0 .497.727 0 .766-.498-.039-.498-.765 0-.765Zm.919-.955c.497 0 .497.727 0 .765-.497 0-.497-.727 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497 0-.497-.727 0-.765Zm1.877 1.874c.497 0 .497.727 0 .765-.497 0-.497-.726 0-.765Zm-1.877 0c.498 0 .498.727 0 .765-.497 0-.497-.726 0-.765Zm-.92-2.792c.497 0 .497.726 0 .765-.498-.039-.498-.765 0-.765Zm0 1.874c.496 0 .496.727 0 .765-.498-.038-.498-.765 0-.765Z" fill="#10B981"/>
    <path d="M66.823 25.306c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.873 7.46c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.875 1.874c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-.959-.918c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 1.837c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm-.918-2.793c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm0 1.874c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.874 1.875c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-3.747 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.875-7.498c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm1.876 0c.498 0 .498.727 0 .765-.497 0-.497-.727 0-.765Zm-1.876 1.875c.497 0 .497.726 0 .765-.498 0-.498-.727 0-.765Zm1.876 0c.498 0 .498.726 0 .765-.497 0-.497-.727 0-.765Zm-.959-.918c.498 0 .498.726 0 .765-.497-.039-.497-.765 0-.765Zm-1.873 0c.497 0 .497.726 0 .765-.497-.039-.497-.765 0-.765Zm1.873 1.874c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-.919-2.831c.498 0 .498.727 0 .765-.497 0-.497-.726 0-.765Zm0 1.875c.498 0 .498.727 0 .765-.497 0-.497-.727 0-.765Z" fill="#10B981"/>
    <path d="M63.076 32.766c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-3.747 0c.497 0 .497.727 0 .766-.498-.039-.498-.766 0-.766Zm1.875-7.461c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.875 1.875c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-.959-.956c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 1.874c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-.918-2.793c.498 0 .498.727 0 .766-.497-.039-.497-.766 0-.766Zm0 1.875c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.874 1.837c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm1.873 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm-3.747 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm1.875-7.46c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm1.875 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.875 1.874c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm1.875 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-.959-.956c.497 0 .497.727 0 .765-.497 0-.497-.727 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm1.873 1.875c.497 0 .497.727 0 .765-.497 0-.497-.727 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498 0-.498-.727 0-.765Zm-.917-2.794c.497 0 .497.727 0 .766-.498-.039-.498-.765 0-.765Zm0 1.875c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Z" fill="#10B981"/>
    <path d="M63.076 25.306c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-3.747 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm0 7.46c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm0 1.874c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm0 1.875c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0-7.498c.498 0 .498.727 0 .765-.497 0-.497-.727 0-.765Zm0 1.874c.498 0 .498.727 0 .765-.497 0-.497-.726 0-.765Z" fill="#10B981"/>
    <path d="M61.202 32.766c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0-7.461c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm0 1.875c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm0 1.836c.497 0 .497.727 0 .766-.498 0-.498-.727 0-.766Zm.001-7.46c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm0 1.874c.497 0 .497.728 0 .766-.498-.038-.498-.765 0-.765Z" fill="#10B981"/>
    <path d="M61.202 25.306c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm6.427-6.58c.497 0 .497.727 0 .765-.46-.038-.46-.765 0-.765Zm0 1.874c.497 0 .497.727 0 .766-.46-.039-.46-.765 0-.765Zm-1.836-1.874c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Z" fill="#10B981"/>
    <path d="M67.629 18.726c.497 0 .497.727 0 .765-.46-.038-.46-.765 0-.765Zm-.919-.918c.498 0 .498.727 0 .765-.496-.038-.496-.765 0-.765Zm-1.874 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.874 1.875c.498 0 .498.727 0 .765-.496-.038-.496-.765 0-.765Zm-1.874 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-.916-2.831c.497 0 .497.726 0 .765-.498 0-.498-.727 0-.765Zm0 1.874c.496 0 .496.727 0 .765-.498-.038-.498-.765 0-.765Zm1.873 1.874c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm1.837 0c.497 0 .497.727 0 .765-.46-.038-.46-.765 0-.765Zm-3.711 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Z" fill="#10B981"/>
    <path d="M63.917 16.851c.498 0 .498.727 0 .765-.497 0-.497-.726 0-.765Zm-1.871.001c.498 0 .498.726 0 .765-.497 0-.497-.727 0-.765Z" fill="#10B981"/>
    <path d="M63.92 16.852c.498 0 .498.726 0 .765-.497 0-.497-.727 0-.765Zm-1.874 1.874c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.874 0c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm-.956-.918c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.875 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm1.875 1.875c.497 0 .497.726 0 .765-.497-.039-.497-.765 0-.765Zm-1.875 0c.497 0 .497.726 0 .765-.497-.039-.497-.765 0-.765Zm.954.917c.498 0 .498.727 0 .765-.497-.038-.497-.765 0-.765Zm1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-.954-4.666c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-1.875 0c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm.954.917c.498 0 .498.727 0 .766-.497 0-.497-.727 0-.766Z" fill="#10B981"/>
    <path d="M63.918 16.852c.497 0 .497.726 0 .765-.498 0-.498-.727 0-.765Zm-1.872 25.057c-.497 0-.497-.727 0-.766.498 0 .498.727 0 .766Zm0-1.875c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-.957.918c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm-1.875-1.874c-.497 0-.497-.727 0-.766.497.039.497.766 0 .766Zm1.875 0c-.497 0-.497-.727 0-.766.497.039.497.766 0 .766Zm.956 2.832c-.498 0-.498-.728 0-.766.496 0 .496.727 0 .765Zm0-1.876c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm-1.877-1.874c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm1.876 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm-1.873 7.46c-.497 0-.497-.726 0-.764.498.038.498.765 0 .765Zm0-1.836c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm-.957.918c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm-1.875-1.874c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm.956 2.792c-.498 0-.498-.726 0-.765.496.039.496.766 0 .766Zm0-1.836c-.498 0-.498-.727 0-.765.496 0 .496.727 0 .765Z" fill="#10B981"/>
    <path d="M62.043 41.91c-.497 0-.497-.728 0-.766.498 0 .498.727 0 .765Zm1.876 0c-.497 0-.497-.728 0-.766.497 0 .497.727 0 .765Zm-1.873 7.46c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm0-1.875c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-.957.956c-.497 0-.497-.727 0-.765.497 0 .497.727 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.497 0 .497.727 0 .765Zm-1.875-1.874c-.497 0-.497-.727 0-.766.497.039.497.766 0 .766Zm1.875 0c-.497 0-.497-.727 0-.766.497.039.497.766 0 .766Zm.956 2.793c-.498 0-.498-.727 0-.766.496.038.496.765 0 .765Zm0-1.875c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm-1.877-1.875c-.497 0-.497-.726 0-.765.498.039.498.766 0 .766Zm1.876 0c-.497 0-.497-.726 0-.765.497.039.497.766 0 .766Zm-1.873 7.499c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm0-1.875c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-.957.918c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm-1.875-1.836c-.497 0-.497-.727 0-.766.497 0 .497.727 0 .766Zm1.875 0c-.497 0-.497-.727 0-.766.497 0 .497.727 0 .766Zm.956 2.793c-.498 0-.498-.727 0-.766.496.039.496.766 0 .766Zm0-1.875c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Z" fill="#10B981"/>
    <path d="M62.043 49.37c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm1.876 0c-.497 0-.497-.727 0-.765.497.038.497.765 0 .765Zm1.875-7.46c-.497 0-.497-.727 0-.765.459 0 .459.727 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496 0 .496.727 0 .765Zm1.874-1.875c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm.919.918c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498.038.498.765 0 .765Zm-1.871-1.874c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498.038.498.765 0 .765Zm.919 2.831c-.498 0-.498-.727 0-.766.497 0 .497.727 0 .765Zm.001-1.875c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm-1.836-1.875c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm3.707 0c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-1.833 7.46c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm1.874-1.836c-.497 0-.497-.727 0-.766.459 0 .459.727 0 .766Zm-1.874 0c-.498 0-.498-.727 0-.766.496 0 .496.727 0 .766Zm.919.918c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498.038.498.765 0 .765Zm-1.871-1.875c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498.038.498.765 0 .765Zm.919 2.793c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm.001-1.836c-.498 0-.498-.727 0-.766.496 0 .496.727 0 .766Z" fill="#10B981"/>
    <path d="M65.794 41.91c-.497 0-.497-.727 0-.766.459 0 .459.727 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.766.496 0 .496.727 0 .765Zm3.707 0c-.497 0-.497-.728 0-.766.498 0 .498.727 0 .765Zm-1.833 7.46c-.497 0-.497-.727 0-.766.459.039.459.766 0 .766Zm-1.874 0c-.498 0-.498-.727 0-.766.496.039.496.766 0 .766Zm1.874-1.875c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm.919.956c-.498 0-.498-.727 0-.765.497 0 .497.727 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498 0 .498.727 0 .765Zm-1.871-1.874c-.498 0-.498-.727 0-.766.497.039.497.766 0 .766Zm1.871 0c-.496 0-.496-.727 0-.766.498.039.498.766 0 .766Zm.919 2.793c-.498 0-.498-.727 0-.766.497.038.497.765 0 .765Zm.001-1.875c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm-1.836-1.875c-.497 0-.497-.726 0-.765.459.039.459.765 0 .765Zm-1.874 0c-.498 0-.498-.726 0-.765.496.039.496.765 0 .765Zm3.707 0c-.497 0-.497-.726 0-.765.498.039.498.766 0 .766Zm-1.833 7.499c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm1.874-1.874c-.497 0-.497-.727 0-.766.459.039.459.766 0 .766Zm-1.874 0c-.498 0-.498-.727 0-.766.496.039.496.766 0 .766Zm.919.917c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.871 0c-.496 0-.496-.727 0-.765.498.038.498.765 0 .765Zm-1.871-1.836c-.498 0-.498-.727 0-.766.497 0 .497.727 0 .766Zm1.871 0c-.496 0-.496-.727 0-.766.498 0 .498.727 0 .766Zm.919 2.793c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm.001-1.874c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Z" fill="#10B981"/>
    <path d="M65.794 49.37c-.497 0-.497-.727 0-.765.459.038.459.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.496.038.496.765 0 .765Zm3.707 0c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm.001-7.46c-.497 0-.497-.728 0-.766.498 0 .498.727 0 .765Zm0-1.875c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm0-1.875c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm0 7.46c-.497 0-.497-.726 0-.765.498.039.498.765 0 .765Zm0-1.836c-.498 0-.498-.727 0-.765.497 0 .497.727 0 .765Z" fill="#10B981"/>
    <path d="M67.628 41.91c-.498 0-.498-.727 0-.766.497 0 .497.727 0 .766Zm0 7.46c-.497 0-.497-.727 0-.766.498.039.498.766 0 .766Zm0-1.875c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm0-1.875c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm0 7.499c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm0-1.874c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Z" fill="#10B981"/>
    <path d="M67.628 49.37c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm-6.425 8.455c-.498 0-.498-.727 0-.766.497 0 .497.727 0 .766Zm-.001-1.875c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm0-1.875c-.497 0-.497-.727 0-.765a.383.383 0 0 1 0 .765Zm0 5.585c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Z" fill="#10B981"/>
    <path d="M61.202 57.824c-.497 0-.497-.727 0-.765.497 0 .497.727 0 .765Zm1.875 0c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Z" fill="#10B981"/>
    <path d="M61.203 57.824c-.498 0-.498-.727 0-.765.497 0 .497.727 0 .765Zm1.874-1.874c-.497 0-.497-.727 0-.766.498 0 .498.727 0 .766Zm-1.874 0c-.498 0-.498-.727 0-.766.497 0 .497.727 0 .766Zm.92.917c-.498 0-.498-.726 0-.765.497.039.497.766 0 .766Zm1.873 0c-.498 0-.498-.726 0-.765.497.039.497.766 0 .766Zm-1.873-1.874c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.873 0c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm.956 2.831c-.498 0-.498-.727 0-.765.497 0 .497.726 0 .765Zm0-1.874c-.498 0-.498-.728 0-.766.497 0 .497.727 0 .765Zm-1.875-1.875c-.497 0-.497-.727 0-.765a.383.383 0 0 1 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765a.383.383 0 0 1 0 .765Zm3.747 0c-.497 0-.497-.727 0-.765a.383.383 0 0 1 0 .765Zm-1.873 5.585c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-1.874 0c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm.92.956c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.873 0c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm-1.873-1.874c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm1.873 0c-.498 0-.498-.727 0-.765.497.038.497.765 0 .765Zm.954.918c-.497 0-.497-.726 0-.764.498.038.498.765 0 .765Zm-1.873-1.836c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Z" fill="#10B981"/>
    <path d="M61.202 57.824c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm3.748 0c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm1.874.001c-.497 0-.497-.727 0-.765.46 0 .46.727 0 .765Z" fill="#10B981"/>
    <path d="M64.95 57.825c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm1.874-1.875c-.497 0-.497-.727 0-.765.46 0 .46.727 0 .765Zm-1.874 0c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm.92.918c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm1.874 0c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-1.874-1.874c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm1.874 0c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-.92-.919c-.497 0-.497-.727 0-.765.46.038.46.727 0 .765Zm-1.874 0c-.497 0-.497-.727 0-.765a.383.383 0 0 1 0 .765Zm1.874 5.585c-.497 0-.497-.726 0-.764.46.038.46.765 0 .765Zm-1.874 0c-.497 0-.497-.726 0-.764.498.038.498.765 0 .765Zm.92.957c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm1.874 0c-.497 0-.497-.727 0-.765.498.038.498.765 0 .765Zm-1.874-1.874c-.497 0-.497-.727 0-.766.498.039.498.766 0 .766Zm1.874 0c-.497 0-.497-.727 0-.766.498.039.498.766 0 .766Zm-.92-.919c-.497 0-.497-.727 0-.765.46 0 .46.727 0 .765Z" fill="#10B981"/>
    <path d="M64.95 57.824c-.497 0-.497-.727 0-.765.498 0 .498.727 0 .765Zm.882 3.941c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm.953-.956c.498 0 .498.726 0 .765-.497-.039-.497-.765 0-.765Zm-1.873 0c.497 0 .497.726 0 .765-.498-.039-.498-.765 0-.765Zm-.957.956c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.873 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Z" fill="#10B981"/>
    <path d="M63.955 61.765c.497 0 .497.727 0 .765-.497-.038-.497-.765 0-.765Zm-.918-.956c.497 0 .497.726 0 .765-.498-.039-.498-.765 0-.765Zm-1.875 0c.497 0 .497.726 0 .765-.498-.039-.498-.765 0-.765Zm1.875 1.874c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-1.875 0c.497 0 .497.727 0 .765-.498-.038-.498-.765 0-.765Zm-13.085-8.608 4.4.688.153-26.168-4.324.536-.23 24.944Zm-6.504-1.071 3.75.612.19-19.359-3.71.268-.23 18.479Zm-5.586-.918 3.214.535.191-14.5-3.214.077-.19 13.888Zm-4.899-.766 2.831.42.154-10.329-2.793-.038-.192 9.947Zm-4.282-.727 2.487.421.115-7.077-2.449-.115-.153 6.771Z" fill="#10B981"/>
    <path d="m23.742 42.254 3.061.956 6.657-8.417 3.099.498 5.7-6.81 4.591.306 5.854-5.93-1.378-1.377-4.973 5.203-4.591-.192-5.662 6.925-3.023-.46-6.618 8.532-2.525-.727-.192 1.493Z" fill="#F7931E"/>
    <path d="m49.108 21.518 5.127-2.257-1.301 5.547-3.826-3.29Z" fill="#F7931E"/>
    <path d="M30.631 50.478a.082.082 0 0 0-.076.076V52.2c.076 1.147 5.777 1.147 5.853 0v-1.646a.082.082 0 0 0-.076-.076h-5.7Z" fill="#fff"/>
    <path d="M33.465 53.233c-1.378 0-3.023-.268-3.1-.995v-1.645c0-.153.116-.23.23-.23h5.662c.154 0 .23.115.23.23v1.645c0 .727-1.607.995-3.022.995ZM30.71 50.63v1.57c.038.344 1.071.688 2.755.688 1.683 0 2.754-.382 2.754-.727v-1.568H30.71v.038Z" fill="#10B981"/>
    <path d="M33.463 49.637c1.607 0 2.907.383 2.907.88-.076 1.148-5.776 1.148-5.853 0 .038-.497 1.34-.88 2.946-.88Z" fill="#fff"/>
    <path d="M33.465 51.511c-1.378 0-3.023-.267-3.1-.994 0-.689 1.57-1.033 3.1-1.033s3.098.344 3.098 1.033c-.076.727-1.683.994-3.098.994Zm0-1.721c-1.684 0-2.755.42-2.755.727.038.344 1.071.688 2.755.688 1.683 0 2.754-.344 2.754-.727.038-.267-1.071-.688-2.754-.688Z" fill="#10B981"/>
    <path d="M30.631 48.757a.082.082 0 0 0-.076.076v1.645c.076 1.148 5.777 1.148 5.853 0v-1.645a.082.082 0 0 0-.076-.076h-5.7Z" fill="#fff"/>
    <path d="M33.465 51.511c-1.378 0-3.023-.268-3.1-.994V48.87c0-.153.116-.229.23-.229h5.662c.154 0 .23.115.23.23v1.645c0 .726-1.607.956-3.022.994ZM30.71 48.91v1.568c.038.345 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.569H30.71v.039Z" fill="#10B981"/>
    <path d="M33.463 47.878c1.607 0 2.907.382 2.907.88-.076 1.148-5.776 1.148-5.853 0 .038-.498 1.34-.88 2.946-.88Z" fill="#fff"/>
    <path d="M33.465 49.752c-1.378 0-3.023-.268-3.1-.995 0-.688 1.57-1.033 3.1-1.033s3.098.345 3.098 1.033c-.076.727-1.683.995-3.098.995Zm0-1.722c-1.684 0-2.755.421-2.755.727.038.345 1.071.689 2.755.689 1.683 0 2.754-.344 2.754-.727.038-.268-1.071-.689-2.754-.689Z" fill="#10B981"/>
    <path d="M30.631 46.997a.082.082 0 0 0-.076.076v1.645c.076 1.148 5.777 1.148 5.853 0v-1.645a.082.082 0 0 0-.076-.076h-5.7Z" fill="#fff"/>
    <path d="M33.465 49.751c-1.378 0-3.023-.268-3.1-.994V47.11c0-.153.116-.23.23-.23h5.662c.154 0 .23.116.23.23v1.646c0 .726-1.607.994-3.022.994Zm-2.755-2.563v1.569c.038.344 1.071.688 2.755.688 1.683 0 2.754-.383 2.754-.727V47.15H30.71v.038Z" fill="#10B981"/>
    <path d="M33.463 46.154c1.607 0 2.907.383 2.907.88-.076 1.148-5.776 1.148-5.853 0 .038-.497 1.34-.88 2.946-.88Z" fill="#fff"/>
    <path d="M33.465 48.029c-1.378 0-3.023-.268-3.1-.995 0-.688 1.57-1.033 3.1-1.033s3.098.345 3.098 1.033c-.076.727-1.683.995-3.098.995Zm0-1.722c-1.684 0-2.755.421-2.755.727.038.345 1.071.689 2.755.689 1.683 0 2.754-.344 2.754-.727.038-.268-1.071-.689-2.754-.689Zm-.775 1.915c.153 0 .153.23 0 .23-.19 0-.19-.23 0-.23Z" fill="#10B981"/>
    <path d="M32.998 47.916c.153 0 .153.23 0 .23-.191.038-.191-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.613.613c.153 0 .153.23 0 .23-.191 0-.191-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.303-.307c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm.613.612c.153 0 .153.23 0 .23-.192 0-.192-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm-.616-.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M32.078 48.222c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.305-.306c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.613 0c.154 0 .154.23 0 .23-.153.038-.153-.23 0-.23Zm.613.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.613 0c.154 0 .154.23 0 .23-.153 0-.153-.23 0-.23Zm.308.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.611 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.956 1.721c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.305.307c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.61-.574c-.153 0-.153-.23 0-.23.19-.038.19.23 0 .23Zm.612 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.613 2.411c-.153 0-.153-.23 0-.23.191-.037.191.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.305.306c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.918c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M31.124 50.556c-.153 0-.153-.23 0-.23.19 0 .19.23 0 .23Zm.612 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 1.797c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.305.307c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.308.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.573c-.153 0-.153-.23 0-.23.19-.039.19.23 0 .23Zm.612 0c-.153 0-.153-.23 0-.23.153-.039.153.23 0 .23Zm.617-1.225c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M31.741 50.555c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.305.305c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.303.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.609-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.222 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.61 2.411c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.305.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.613-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.303.918c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.609-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M31.741 50.556c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.222 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.61 1.797c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.305.307c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.304.307c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.61-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.612 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.222 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M32.96 50.555c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0 2.411c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M32.96 50.555c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0 1.798c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.836-3.826c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm0 .612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.309-.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.615 0c.154 0 .154.23 0 .23-.153 0-.153-.23 0-.23Zm.615.574c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.615 0c.154 0 .154.23 0 .23-.153.038-.153-.23 0-.23Zm-.306-.88c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm0 .612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613.574c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M34.798 47.954c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.577 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M34.181 47.954c.154 0 .154.23 0 .23-.153 0-.153-.23 0-.23Zm-.573.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.573 0c.154 0 .154.23 0 .23-.153 0-.153-.23 0-.23Zm-.267-.306c.153 0 .153.23 0 .23-.191 0-.191-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613.574c.153 0 .153.23 0 .23-.191.038-.191-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.309.306c.152 0 .152.23 0 .23-.154.038-.154-.23 0-.23Zm.574 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.574-1.186c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M34.185 47.954c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.922 2.946c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.307c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.306.919c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm-.615 2.411c-.154 0-.154-.23 0-.23.153 0 .153.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.153 0 .153.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.918c-.154 0-.154-.23 0-.23.153 0 .153.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.153 0 .153.23 0 .23Z" fill="#10B981"/>
    <path d="M33.264 50.9c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.615 1.798c-.154 0-.154-.23 0-.23.153-.038.153.23 0 .23Zm.307-.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.308.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.572-1.186c-.153 0-.153-.23 0-.23.192-.038.192.23 0 .23Z" fill="#10B981"/>
    <path d="M33.877 50.9c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.573-.613c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.307c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.575 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.575-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.575 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.919c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.615-.612c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 2.41c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.573-.612c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.575 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.575-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.575 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.918c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.615-.574c-.153 0-.153-.23 0-.23.192-.038.192.23 0 .23Z" fill="#10B981"/>
    <path d="M33.877 50.9c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 1.797c-.153 0-.153-.23 0-.23.192-.037.192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.306-.305c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.575 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.305c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.614-.612c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M35.064 50.9c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.001-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.001 2.41c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M35.065 50.9c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0 1.797c-.153 0-.153-.229 0-.229.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.653-3.863c.154 0 .154.23 0 .23-.153 0-.153-.23 0-.23Zm-.266-.306c.153 0 .153.23 0 .23-.192.038-.192-.23 0-.23Zm-.001.612c.153 0 .153.23 0 .23-.191 0-.191-.23 0-.23Zm.267.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.305 1.722c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.307c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.575c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.307-.305c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0 2.409c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M35.413 50.556c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0 1.797c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.307-.305c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M46.963 52.851a.082.082 0 0 0-.077.077v1.645c.077 1.147 5.777 1.147 5.854 0v-1.645a.082.082 0 0 0-.077-.077h-5.7Z" fill="#fff"/>
    <path d="M49.795 55.605c-1.377 0-3.022-.267-3.099-.994v-1.645c0-.153.115-.23.23-.23h5.662c.153 0 .23.115.23.23v1.645c0 .727-1.646.994-3.023.994Zm-2.793-2.601v1.569c.038.344 1.071.688 2.755.688 1.683 0 2.754-.383 2.754-.727v-1.568h-5.509v.038Z" fill="#10B981"/>
    <path d="M49.795 51.971c1.607 0 2.907.383 2.907.88-.076 1.148-5.777 1.148-5.853 0 0-.497 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 53.846c-1.377 0-3.022-.268-3.099-.995 0-.688 1.569-1.033 3.099-1.033 1.53 0 3.099.345 3.099 1.033-.077.727-1.722.995-3.1.995Zm0-1.722c-1.683 0-2.755.421-2.755.727.039.345 1.072.689 2.755.689 1.721 0 2.755-.344 2.793-.727-.038-.268-1.11-.689-2.793-.689Z" fill="#10B981"/>
    <path d="M46.963 51.09a.082.082 0 0 0-.077.077v1.645c.077 1.148 5.777 1.148 5.854 0v-1.645a.082.082 0 0 0-.077-.076h-5.7Z" fill="#fff"/>
    <path d="M49.795 53.845c-1.377 0-3.022-.267-3.099-.994v-1.645c0-.154.115-.23.23-.23h5.662c.153 0 .23.115.23.23v1.645c0 .727-1.646.994-3.023.994Zm-2.793-2.601v1.568c.038.345 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.568h-5.509v.038Z" fill="#10B981"/>
    <path d="M49.795 50.25c1.607 0 2.907.382 2.907.88-.076 1.147-5.777 1.147-5.853 0 0-.498 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 52.124c-1.377 0-3.022-.268-3.099-.995 0-.689 1.569-1.033 3.099-1.033 1.53 0 3.099.344 3.099 1.033-.077.727-1.722.995-3.1.995Zm0-1.722c-1.683 0-2.755.42-2.755.727.039.344 1.072.689 2.755.689 1.683 0 2.755-.345 2.755-.727 0-.268-1.072-.689-2.755-.689Z" fill="#10B981"/>
    <path d="M46.963 49.37a.082.082 0 0 0-.077.077v1.645c.077 1.148 5.777 1.148 5.854 0v-1.645a.082.082 0 0 0-.077-.077h-5.7Z" fill="#fff"/>
    <path d="M49.795 52.125c-1.377 0-3.022-.268-3.099-.995v-1.645c0-.153.115-.23.23-.23h5.662c.153 0 .23.115.23.23v1.645c0 .727-1.646.995-3.023.995Zm-2.793-2.602v1.569c.038.344 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.569h-5.509v.038Z" fill="#10B981"/>
    <path d="M49.795 48.49c1.607 0 2.907.383 2.907.88-.076 1.148-5.777 1.148-5.853 0 0-.497 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 50.403c-1.377 0-3.022-.268-3.099-.995 0-.688 1.569-1.033 3.099-1.033 1.53 0 3.099.345 3.099 1.033-.077.727-1.722.995-3.1.995Zm0-1.76c-1.683 0-2.755.42-2.755.727.039.344 1.072.689 2.755.689 1.683 0 2.755-.345 2.755-.727 0-.268-1.072-.689-2.755-.689Zm-.809 1.949c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M49.294 50.286c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.614.001c.152 0 .152.23 0 .23-.154 0-.154-.23 0-.23Zm.614.611c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.614 0c.152 0 .152.23 0 .23-.154 0-.154-.23 0-.23Zm-.266-.305c.153 0 .153.23 0 .23-.191 0-.191-.23 0-.23Zm.573.573c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.573 0c.153 0 .153.23 0 .23-.191.038-.191-.23 0-.23Zm-.61-.573c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M48.415 50.593c.154 0 .154.23 0 .23-.19 0-.19-.23 0-.23Zm-.303-.306c.153 0 .153.23 0 .23-.192 0-.192-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm.613.612c.153 0 .153.23 0 .23-.192 0-.192-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm.304.268c.154 0 .154.23 0 .23-.152.038-.152-.23 0-.23Zm.614 0c.153 0 .153.23 0 .23-.191.038-.191-.23 0-.23Zm-.963 1.759c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm.306.919c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 2.411c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.267c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.573c-.154 0-.154-.23 0-.23.152-.038.152.23 0 .23Zm.306.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M47.454 52.927c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.613 1.797c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.306.306c-.154 0-.154-.229 0-.229.152 0 .152.23 0 .23Zm0-.611c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613-1.187c-.153 0-.153-.229 0-.229.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M48.067 52.926c-.153 0-.153-.229 0-.229.153-.038.153.23 0 .23Zm.613-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm.303.919c-.152 0-.152-.23 0-.23.154-.038.154.23 0 .23Zm0-.612c-.152 0-.152-.23 0-.23.154 0 .154.23 0 .23Zm-.57-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.574 2.411c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.267c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.574-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192-.038.192.23 0 .23Zm.303.88c-.152 0-.152-.23 0-.23.154 0 .154.23 0 .23Zm0-.574c-.152 0-.152-.23 0-.23.154-.038.154.23 0 .23Zm-.57-.612c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M48.067 52.927c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.574 1.797c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm-.613 0c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.306.306c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.574 0c-.153 0-.153-.229 0-.229.192 0 .192.23 0 .23Zm-.574-.611c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M49.254 52.927c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.001-.613c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm.001 2.411c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.001-.573c-.153 0-.153-.23 0-.23.154-.038.154.23 0 .23Z" fill="#10B981"/>
    <path d="M49.253 52.927c-.153 0-.153-.23 0-.23.154-.038.154.23 0 .23Zm.001 1.798c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm.001-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.88-3.827c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm0 .612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.305-.306c.154 0 .154.23 0 .23-.19.038-.19-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.612.613c.154 0 .154.23 0 .23-.19 0-.19-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.308-.919c.153 0 .153.23 0 .23-.154.038-.154-.23 0-.23Zm0 .613c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm.615.611c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.615 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M51.135 50.287c.153 0 .153.23 0 .23-.153.037-.153-.23 0-.23Zm-.615 0c.153 0 .153.23 0 .23-.153.037-.153-.23 0-.23Zm-.61.001c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M50.523 50.288c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.613.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.306-.306c.153 0 .153.23 0 .23-.153.037-.153-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23-.153.037-.153-.23 0-.23Zm.612.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.306.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.617-1.225c.153 0 .153.23 0 .23-.153.037-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M50.52 50.287c.152 0 .152.23 0 .23-.154.037-.154-.23 0-.23Zm-.915 2.946c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.265.307c-.152 0-.152-.23 0-.23.192 0 .192.23 0 .23Zm0-.574c-.152 0-.152-.23 0-.23.192-.038.192.23 0 .23Zm.308.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm-.573-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.573 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.265.306c-.152 0-.152-.23 0-.23.192-.037.192.23 0 .23Zm0-.611c-.152 0-.152-.23 0-.23.192 0 .192.23 0 .23Zm.308.917c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M49.605 53.233c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.573 1.797c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.265-.267c-.152 0-.152-.23 0-.23.192-.038.192.23 0 .23Zm.308.267c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm-.573-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.613-1.224c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M50.177 53.233c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614-.574c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.306.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm-.612-.573c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.609 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm-.614-.611c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.917c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M50.177 53.233c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.609 1.798c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306-.268c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.303.267c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm-.609-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M51.403 53.233c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M51.403 53.233c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0 1.797c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.649-3.864c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.307-.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm0 .613c.153 0 .153.229 0 .229s-.153-.23 0-.23Zm.307.267c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.346 1.759c-.153 0-.153-.229 0-.229.191-.038.191.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.307-.306c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0 2.411c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0-.575c-.153 0-.153-.23 0-.23.191-.037.191.23 0 .23Zm.307.268c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M51.706 52.927c-.153 0-.153-.23 0-.23.191-.038.191.23 0 .23Zm0 1.797c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.307-.306c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Z" fill="#10B981"/>
    <path d="M46.963 47.648a.082.082 0 0 0-.077.076v1.645c.077 1.148 5.777 1.148 5.854 0v-1.645a.082.082 0 0 0-.077-.076h-5.7Z" fill="#fff"/>
    <path d="M49.795 50.402c-1.377 0-3.022-.267-3.099-.994v-1.645c0-.154.115-.23.23-.23h5.662c.153 0 .23.115.23.23v1.645c0 .727-1.646.994-3.023.994Zm-2.793-2.601v1.568c.038.345 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.568h-5.509v.038Z" fill="#10B981"/>
    <path d="M49.795 46.769c1.607 0 2.907.382 2.907.88-.076 1.147-5.777 1.147-5.853 0 0-.498 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 48.643c-1.377 0-3.022-.268-3.099-.995 0-.688 1.569-1.033 3.099-1.033 1.53 0 3.099.345 3.099 1.033-.077.765-1.722.995-3.1.995Zm0-1.722c-1.683 0-2.755.421-2.755.727.039.345 1.072.689 2.755.689 1.721 0 2.755-.344 2.793-.727-.038-.268-1.11-.689-2.793-.689Z" fill="#10B981"/>
    <path d="M46.963 45.927a.082.082 0 0 0-.077.077v1.645c.077 1.148 5.777 1.148 5.854 0v-1.645a.082.082 0 0 0-.077-.077h-5.7Z" fill="#fff"/>
    <path d="M49.795 48.643c-1.377 0-3.022-.268-3.099-.995v-1.645c0-.153.115-.23.23-.23h5.662c.153 0 .23.115.23.23v1.645c0 .727-1.646.995-3.023.995Zm-2.793-2.563v1.568c.038.345 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.569h-5.509v.039Z" fill="#10B981"/>
    <path d="M49.795 45.047c1.607 0 2.907.383 2.907.88-.076 1.148-5.777 1.148-5.853 0 0-.498 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 46.921c-1.377 0-3.022-.267-3.099-.994 0-.689 1.569-1.033 3.099-1.033 1.53 0 3.099.344 3.099 1.033-.077.727-1.722.994-3.1.994Zm0-1.721c-1.683 0-2.755.42-2.755.727.039.344 1.072.688 2.755.688 1.683 0 2.755-.344 2.755-.727 0-.267-1.072-.688-2.755-.688Z" fill="#10B981"/>
    <path d="M46.963 44.167a.082.082 0 0 0-.077.076v1.645c.077 1.148 5.777 1.148 5.854 0v-1.645a.082.082 0 0 0-.077-.076h-5.7Z" fill="#fff"/>
    <path d="M49.795 46.921c-1.377 0-3.022-.268-3.099-.995v-1.645c0-.153.115-.23.23-.23h5.662c.153 0 .23.116.23.23v1.645c0 .727-1.646.995-3.023.995Zm-2.793-2.601v1.568c.038.345 1.071.689 2.755.689 1.683 0 2.754-.383 2.754-.727v-1.569h-5.509v.039Z" fill="#10B981"/>
    <path d="M49.795 43.287c1.607 0 2.907.383 2.907.88-.076 1.148-5.777 1.148-5.853 0 0-.46 1.339-.88 2.946-.88Z" fill="#fff"/>
    <path d="M49.795 45.2c-1.378 0-3.023-.268-3.1-.995 0-.688 1.57-1.033 3.1-1.033s3.099.345 3.099 1.033c-.077.727-1.722.995-3.1.995Zm0-1.722c-1.684 0-2.755.421-2.755.727.038.345 1.071.689 2.755.689 1.683 0 2.754-.344 2.754-.727 0-.268-1.071-.689-2.754-.689Zm-.809 1.912c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M49.293 45.084c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.614 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.614.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.614 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.266-.306c.154 0 .154.23 0 .23-.19 0-.19-.23 0-.23Zm.573.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.573 0c.154 0 .154.23 0 .23-.19 0-.19-.23 0-.23Zm-.609-.612c.153 0 .153.229 0 .229s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M48.415 45.39c.153 0 .153.229 0 .229-.191 0-.191-.23 0-.23Zm-.304-.306c.154 0 .154.229 0 .229-.19 0-.19-.23 0-.23Zm-.613-.001c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613.613c.154 0 .154.23 0 .23-.19 0-.19-.23 0-.23Zm-.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.305.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.614 0c.153 0 .153.23 0 .23-.192 0-.192-.23 0-.23Zm-.963 1.721c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.613c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm.306.307c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm.306.92c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 2.41c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.306c-.154 0-.154-.23 0-.23.152-.038.152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152-.038.152.23 0 .23Zm.306.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M47.454 47.724c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 1.798c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613-1.186c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M48.067 47.724c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm.303.919c-.152 0-.152-.23 0-.23.154 0 .154.23 0 .23Zm0-.612c-.152 0-.152-.23 0-.23.154 0 .154.23 0 .23Zm-.57-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.574 2.41c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.613-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192-.038.192.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192-.038.192.23 0 .23Zm.303.88c-.152 0-.152-.23 0-.23.154 0 .154.23 0 .23Zm0-.574c-.152 0-.152-.23 0-.23.154-.038.154.23 0 .23Zm-.57-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M48.067 47.724c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.574 1.798c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.574 0c-.153 0-.153-.23 0-.23.192 0 .192.23 0 .23Zm.307.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.574-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.613 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.187 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M49.254 47.725c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.001-.613c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm.001 2.41c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.001-.573c-.153 0-.153-.23 0-.23.154-.038.154.23 0 .23Z" fill="#10B981"/>
    <path d="M49.253 47.725c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm.001 1.797c-.153 0-.153-.23 0-.23.154 0 .154.23 0 .23Zm.001-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.88-3.788c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm0 .574c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.305-.306c.154 0 .154.23 0 .23-.19.038-.19-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.612.612c.154 0 .154.229 0 .229-.19 0-.19-.23 0-.23Zm-.612 0c.153 0 .153.229 0 .229s-.153-.23 0-.23Zm-.308-.88c.153 0 .153.23 0 .23-.154 0-.154-.23 0-.23Zm0 .574c.153 0 .153.23 0 .23-.154.038-.154-.23 0-.23Zm.615.612c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.615 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M51.135 45.122c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.615 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.61.001c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M50.523 45.123c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.613.574c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm.613 0c.153 0 .153.23 0 .23-.153.038-.153-.23 0-.23Zm-.306-.307c.153 0 .153.23 0 .23-.153.039-.153-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23-.153.039-.153-.23 0-.23Zm.612.613c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.612 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.306.305c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.613 0c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.617-1.186c.153 0 .153.23 0 .23s-.153-.23 0-.23Z" fill="#10B981"/>
    <path d="M50.52 45.122c.152 0 .152.23 0 .23-.154 0-.154-.23 0-.23Zm-.915 2.908c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm.265.268c-.152 0-.152-.23 0-.23.192 0 .192.23 0 .23Zm0-.574c-.152 0-.152-.23 0-.23.192-.038.192.23 0 .23Zm.308.88c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm0-.573c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.573-.613c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.573 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.265.306c-.152 0-.152-.23 0-.23.192 0 .192.23 0 .23Zm0-.612c-.152 0-.152-.23 0-.23.192 0 .192.23 0 .23Zm.308.918c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M49.605 48.03c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.573 1.837c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.265-.307c-.152 0-.152-.23 0-.23.192-.038.192.23 0 .23Zm.308.307c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.573-.613c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.573 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.613-1.224c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M50.177 48.03c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.268c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306.88c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.609 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.306c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.614-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm.306.918c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.612-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M50.177 48.03c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm-.609 1.836c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.306-.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.303.306c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.609-.612c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm-.614 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm1.223 0c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Z" fill="#10B981"/>
    <path d="M51.403 48.03c-.153 0-.153-.229 0-.229s.153.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.153-.037.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0 2.41c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23s.153.23 0 .23Z" fill="#10B981"/>
    <path d="M51.403 48.03c-.153 0-.153-.23 0-.23s.153.23 0 .23Zm0 1.836c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm0-.612c-.153 0-.153-.23 0-.23.153-.038.153.23 0 .23Zm.649-3.864c.153 0 .153.229 0 .229s-.153-.23 0-.23Zm-.307-.307c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm0 .613c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm.307.306c.153 0 .153.23 0 .23s-.153-.23 0-.23Zm-.346 1.721c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0-.613c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.308.307c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm-.308-.305c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0 2.41c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0-.574c-.153 0-.153-.23 0-.23.191-.038.191.23 0 .23Zm.308.306c-.154 0-.154-.23 0-.23.152-.038.152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152-.038.152.23 0 .23Z" fill="#10B981"/>
    <path d="M51.706 47.724c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm0 1.797c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23Zm.308.306c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm0-.612c-.154 0-.154-.23 0-.23.152 0 .152.23 0 .23Zm-.308-.305c-.153 0-.153-.23 0-.23.191 0 .191.23 0 .23ZM20.875 25.84l38.717-9.334.076-1.11c.039-.65-.574-1.186-1.224-.994l-35.848 8.875s-1.798-.23-1.721 2.564Z" fill="#10B981"/>
  </svg>
 )
}


export default PromotionAdSvg