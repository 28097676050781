import React from 'react';

function AuctionRules() {
	return (
		<svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M69.5 36.17V20.36C69.5074 19.2078 69.1252 18.0869 68.4154 17.1793C67.7056 16.2717 66.71 15.6305 65.59 15.36L39.67 9.11997C38.9157 8.93775 38.1299 8.92967 37.3721 9.09635C36.6143 9.26304 35.9044 9.6001 35.2963 10.082C34.6881 10.5639 34.1977 11.1779 33.8622 11.8776C33.5267 12.5772 33.355 13.3441 33.36 14.12V64C33.355 64.7759 33.5267 65.5427 33.8622 66.2424C34.1977 66.942 34.6881 67.5561 35.2963 68.038C35.9044 68.5198 36.6143 68.8569 37.3721 69.0236C38.1299 69.1903 38.9157 69.1822 39.67 69L65.6 62.67C66.72 62.3994 67.7156 61.7583 68.4254 60.8507C69.1352 59.943 69.5174 58.8222 69.51 57.67V55.67" stroke="#FFD836" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
			<path d="M69.5 41.71C69.7077 41.6495 69.8902 41.5231 70.02 41.35C70.1498 41.1769 70.22 40.9664 70.22 40.75C70.22 40.5337 70.1498 40.3231 70.02 40.15C69.8902 39.9769 69.7077 39.8506 69.5 39.79C69.2923 39.8506 69.1099 39.9769 68.98 40.15C68.8502 40.3231 68.78 40.5337 68.78 40.75C68.78 40.9664 68.8502 41.1769 68.98 41.35C69.1099 41.5231 69.2923 41.6495 69.5 41.71Z" fill="#10B981"/>
			<path d="M69.5 45.2701C69.7077 45.2095 69.8902 45.0832 70.02 44.9101C70.1498 44.737 70.22 44.5265 70.22 44.3101C70.22 44.0937 70.1498 43.8832 70.02 43.7101C69.8902 43.537 69.7077 43.4107 69.5 43.3501C69.2923 43.4107 69.1099 43.537 68.98 43.7101C68.8502 43.8832 68.78 44.0937 68.78 44.3101C68.78 44.5265 68.8502 44.737 68.98 44.9101C69.1099 45.0832 69.2923 45.2095 69.5 45.2701Z" fill="#10B981"/>
			<path d="M69.5 48.8399C69.7077 48.7793 69.8902 48.653 70.02 48.4799C70.1498 48.3068 70.22 48.0963 70.22 47.8799C70.22 47.6636 70.1498 47.453 70.02 47.2799C69.8902 47.1068 69.7077 46.9805 69.5 46.9199C69.2923 46.9805 69.1099 47.1068 68.98 47.2799C68.8502 47.453 68.78 47.6636 68.78 47.8799C68.78 48.0963 68.8502 48.3068 68.98 48.4799C69.1099 48.653 69.2923 48.7793 69.5 48.8399Z" fill="#10B981"/>
			<path d="M69.5 52.4102C69.7077 52.3496 69.8902 52.2233 70.02 52.0502C70.1498 51.8771 70.22 51.6666 70.22 51.4502C70.22 51.2339 70.1498 51.0233 70.02 50.8502C69.8902 50.6771 69.7077 50.5508 69.5 50.4902C69.2923 50.5508 69.1099 50.6771 68.98 50.8502C68.8502 51.0233 68.78 51.2339 68.78 51.4502C68.78 51.6666 68.8502 51.8771 68.98 52.0502C69.1099 52.2233 69.2923 52.3496 69.5 52.4102Z" fill="#10B981"/>
			<path d="M21.0301 10.7099L23.1701 3.7099L23.4801 2.7099C23.7101 1.9799 22.5601 1.7099 22.3401 2.3899L20.2001 9.3899L19.8901 10.3899C19.6601 11.1199 20.8001 11.3899 21.0301 10.7099Z" fill="#10B981"/>
			<path d="M2.79018 11.8002L9.50018 14.6802L10.4402 15.0802C10.5765 15.1551 10.7363 15.1751 10.8868 15.136C11.0374 15.097 11.1674 15.0019 11.2502 14.8702C11.2898 14.8033 11.3158 14.7292 11.3267 14.6522C11.3376 14.5752 11.3331 14.4968 11.3136 14.4216C11.2941 14.3463 11.2599 14.2756 11.2129 14.2136C11.166 14.1516 11.1073 14.0995 11.0402 14.0602L4.33018 11.1802L3.39018 10.7702C3.25286 10.7 3.09456 10.6826 2.94528 10.7213C2.79599 10.76 2.66608 10.8522 2.58018 10.9802C2.50228 11.1175 2.48064 11.2797 2.5198 11.4326C2.55896 11.5855 2.65588 11.7173 2.79018 11.8002Z" fill="#10B981"/>
			<path d="M14.32 10.3802L12.09 3.44024L11.77 2.44024C11.5 1.72024 10.4001 2.00024 10.63 2.76024L12.86 9.70024L13.17 10.7002C13.41 11.4202 14.55 11.1102 14.32 10.3902V10.3802Z" fill="#FFD836"/>
			<path d="M14.3 60C14.3 58.3431 15.6432 57 17.3 57H45.5001C47.157 57 48.5002 58.3431 48.5002 60V62H14.3V60Z" fill="#10B981" stroke="#10B981" strokeWidth="2" strokeLinejoin="round"/>
			<path d="M20.5999 55C20.5999 53.3431 21.943 52 23.5999 52H39.1999C40.8567 52 42.1999 53.3431 42.1999 55V57H20.5999V55Z" fill="#10B981" stroke="#10B981" strokeWidth="2" strokeLinejoin="round"/>
			<path d="M10.5 60C10.5 58.3431 11.8431 57 13.5 57H41.7C43.3569 57 44.7 58.3431 44.7 60V62H10.5V60Z" fill="white" stroke="#10B981" strokeWidth="2" strokeLinejoin="round"/>
			<path d="M16.8 55C16.8 53.3431 18.1432 52 19.8 52H35.4001C37.057 52 38.4001 53.3431 38.4001 55V57H16.8V55Z" fill="white" stroke="#10B981" strokeWidth="2" strokeLinejoin="round"/>
			<path d="M46.084 33.8487L34.8811 29.5483C33.8499 29.1524 32.693 29.6675 32.2972 30.6987C31.9013 31.7299 32.4164 32.8868 33.4476 33.2826L44.6506 37.583C45.6818 37.9789 46.8386 37.4638 47.2345 36.4326C47.6303 35.4014 47.1153 34.2445 46.084 33.8487Z" fill="white" stroke="#10B981" strokeWidth="2"/>
			<path d="M58.0035 36.2823L46.8005 31.9819C45.7693 31.586 44.6125 32.1011 44.2166 33.1323L42.7831 36.8666C42.3873 37.8978 42.9024 39.0547 43.9336 39.4505L55.1365 43.7509C56.1677 44.1468 57.3246 43.6317 57.7204 42.6005L59.1539 38.8662C59.5497 37.835 59.0347 36.6781 58.0035 36.2823Z" fill="#10B981"/>
			<path d="M40.0487 27.2477L30.7129 23.6641L26.4125 34.867L35.7483 38.4507L40.0487 27.2477Z" fill="#10B981"/>
			<path d="M26.4125 34.8673C26.8084 33.8361 27.9652 33.321 28.9964 33.7168L34.5979 35.867C35.6291 36.2629 36.1442 37.4197 35.7483 38.4509L34.3149 42.1853C33.919 43.2165 32.7622 43.7315 31.731 43.3357L26.1295 41.1855C25.0983 40.7896 24.5832 39.6328 24.9791 38.6016L26.4125 34.8673Z" fill="white" stroke="#10B981" strokeWidth="2"/>
			<path d="M32.1464 19.9298C32.5423 18.8986 33.6991 18.3835 34.7303 18.7793L40.3318 20.9295C41.363 21.3254 41.8781 22.4822 41.4822 23.5134L40.0488 27.2478C39.6529 28.279 38.4961 28.794 37.4649 28.3982L31.8634 26.248C30.8322 25.8521 30.3171 24.6953 30.713 23.6641L32.1464 19.9298Z" fill="white" stroke="#10B981" strokeWidth="2"/>
		</svg>
	);
}

export default AuctionRules;
