import { HttpUnprocessableEntity } from "../Components/BackendCall";

export type RemoteItemsLoaderState<Item> = {
  isLoading: boolean;
  reset: boolean;
  bottomLoaderTimer?: ReturnType<typeof setTimeout>;
  lastLoadedpage: number;
  items: Item[];
  error?: string;
  isLastPage: boolean;
  counter?: number;
  requestData?: any;
};
export const RemoteItemsLoaderInitialState = {
  isLoading: false,
  reset: false,
  bottomLoaderTimer: undefined,
  lastLoadedpage: 0,
  items: [],
  loadingErrorMessage: undefined,
  isLastPage: false,
  counter: 0,
};

export type RemoteItemsLoaderAction<Item> =
  | {
      type: 'startLoading';
      reset: boolean;
      requestData?: any;
      requestCounter?: number;
    }
  | {
      type: 'loaded';
      reset?: boolean;
      items: Item[];
      loadPerPage: number;
      bottomLoaderTimer?: ReturnType<typeof setTimeout>;
      requestCounter?: number;
    }
  | {
      type: 'unshift';
      items: Item[];
      bottomLoaderTimer?: ReturnType<typeof setTimeout>;
      requestCounter?: number;
    }
  | {
      type: 'loadingFailed';
      error: Error;
      requestCounter?: number;
    }
  | {
      type: 'clear';
    }
  | {
      type: 'clearTimeout';
      error?: Error;
    };

export function RemoteItemsLoaderReducer<Item>(
  state: RemoteItemsLoaderState<Item>,
  action: RemoteItemsLoaderAction<Item>,
): RemoteItemsLoaderState<Item> {
  if (action.type === 'startLoading') {
    return {
      ...state,
      items: action.reset ? [] : state.items,
      isLoading: true,
      reset: action.reset,
      requestData: action.requestData,
      counter: action.requestCounter,
    };
  } else if (action.type === 'loaded') {
    if (
      action.requestCounter &&
      state.counter &&
      action.requestCounter < state.counter
    ) {
      return state;
    }
    const items = action.reset ? [] : state.items;
    const areThereMore = action.items.length > 0;
    const page = action.reset ? 1 : state.lastLoadedpage + 1;
    const newItems = items.concat(action.items);
    return {
      ...state,
      isLoading: false,
      error: undefined,
      items: newItems,
      lastLoadedpage: page,
      reset: false,
      isLastPage: !areThereMore,
      bottomLoaderTimer: action.bottomLoaderTimer,
    };
  } else if (action.type === 'unshift') {
    const newItems: any = [action.items].concat(state.items);
    return {
      ...state,
      items: newItems,
    };
  } else if (action.type === 'loadingFailed') {
    if (
      action.requestCounter &&
      state.counter &&
      action.requestCounter < state.counter
    ) {
      return state;
    }
    let message = action.error.message;
    if (action.error instanceof HttpUnprocessableEntity) {
      message = action.error.messageStr();
    }
    return {
      ...state,
      isLoading: false,
      reset: false,
      error: message,
    };
  } else if (action.type === 'clearTimeout') {
    let message = action.error?.message;
    if (action.error instanceof HttpUnprocessableEntity) {
      message = action.error.messageStr();
    }
    return {
      ...state,
      bottomLoaderTimer: undefined,
      error: message,
    };
  }
  return state;
}
