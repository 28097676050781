import React from "react";
import Button from "../Button";
import SeparatedModal from "../SeparatedModal";
import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';
import 'remixicon/fonts/remixicon.css'
import Accoridon from "../Accordion";
import Translation from "../../helper/Translation";
import BackendCall from "../BackendCall";

export type Props = {
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

const AuctionRules = ({visible, onClose, onConfirm}: Props) => {
  const [FAQs, setFAQs] = React.useState<any>();

  React.useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      const remoteFaqs = await BackendCall.i().getFaqs({
        section: 'create_auction',
      });
      setFAQs(
        remoteFaqs.map((remoteFaq) => {
          return {
            title: remoteFaq.question,
            content: remoteFaq.answer,
          };
        }),
      );
    } catch (error) {}
  };

  return (
    <SeparatedModal
      visible={visible}
      closeBtn
      headerLabel={Translation.t('auctions.labels.auction_rules')}
      onClose={onClose}
      body={
        <div>
          <div className="p-4 pt-0 bg-white">
            <div className="flex items-center justify-center mb-2">
              <AuctionRulesSvg />
            </div>
            <p className="font-bold text-2xl text-emerald-500 text-center mb-1">{Translation.t('auctions.labels.auction_rules')}</p>
            <p className="text-center text-sm text-coolGray-500 px-4 mb-6">
              {Translation.t('auctions.messages.auction_rules')}
            </p>
            <div className="grid grid-cols-3">
              <div className="flex flex-col items-center space-y-2">
                <div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
                  <i className="ri-money-dollar-circle-fill ri-lg text-emerald-500" />
                </div>
                <p className="text-center text-sm text-coolGray-700">
                  {Translation.t('auctions.labels.auction_fees')}
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2">
                <div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
                  <i className="ri-shield-check-fill ri-lg text-emerald-500" />
                </div>
                <p className="text-center text-sm text-coolGray-700">
                  {Translation.t('auctions.labels.account_verified')}
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2">
                <div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
                  <i className="ri-calendar-todo-fill ri-lg text-emerald-500" />
                </div>
                <p className="text-center text-sm text-coolGray-700">
                  {Translation.t('auctions.labels.timing_availability')}
                </p>
              </div>
            </div>
          </div>
    
          <div className="p-4 -mx-4 bg-coolGray-50">
            <Accoridon
              title={Translation.t('auctions.labels.auctions_faqs')}
              items={FAQs}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex items-center">
          <Button
            type="outline"
            color="coolGray-400"
            label={Translation.t('auctions.actions.not_interested')}
            containerStyle="flex-1 me-4"
            onPress={onClose}
          />
          <Button
            label={Translation.t('auctions.actions.start_auction')}
            containerStyle="flex-1"
            onPress={() => {
              if(onConfirm) {
                onConfirm()
              } else {
                window.location.href = window.location.href + '/start_auction'
              }
            }}
          />
        </div>
      }
    />
  );
}

export default AuctionRules;
