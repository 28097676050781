import React from 'react'

const ContactPhoneIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66667 0.333252V13.6666H0V0.333252H2.66667ZM4 0.333252H10.67C11.4047 0.333252 12 0.931919 12 1.65992V12.3399C12 13.0726 11.4047 13.6666 10.67 13.6666H4V0.333252ZM12.6667 2.99992H14V5.66658H12.6667V2.99992ZM12.6667 6.99992H14V9.66658H12.6667V6.99992ZM8 6.99992C8.35362 6.99992 8.69276 6.85944 8.94281 6.60939C9.19286 6.35935 9.33333 6.02021 9.33333 5.66658C9.33333 5.31296 9.19286 4.97382 8.94281 4.72378C8.69276 4.47373 8.35362 4.33325 8 4.33325C7.64638 4.33325 7.30724 4.47373 7.05719 4.72378C6.80714 4.97382 6.66667 5.31296 6.66667 5.66658C6.66667 6.02021 6.80714 6.35935 7.05719 6.60939C7.30724 6.85944 7.64638 6.99992 8 6.99992ZM6 9.66658H10C10 9.13615 9.78929 8.62744 9.41421 8.25237C9.03914 7.8773 8.53043 7.66658 8 7.66658C7.46957 7.66658 6.96086 7.8773 6.58579 8.25237C6.21071 8.62744 6 9.13615 6 9.66658Z"/>
    </svg>




);
}


export default ContactPhoneIcon