import React, { useRef, useState } from 'react'
import AuthModalHeader from "./AuthModalHeader";
import Translation from "../../helper/Translation";
import AuthEmailInput from "./AuthEmailInput";
import AuthButton from "./AuthButton";
import EventDispatcher from "../../helper/EventDispatcher";
import AuthApi from "./AuthApi";
import get from "lodash/get"
import last from "lodash/last"
import ReCAPTCHA from "react-google-recaptcha";


interface ErrorBagType {
  email: string;
}

const ForgetPassword = ({locale ,country, recaptchaEnabled = true,...props}) => {

  const [errorBag , setErrorBag] = useState<ErrorBagType>({ email: '' })
  const [mode , setMode] = useState<string>('reset')
  const [formDisabled , setFormDisabled] = useState<boolean>(false)
  const recaptchaRef = useRef(null)


  const onClose = ()=> {
    EventDispatcher.dispatch('close-auth-modal')
  }

  const forgetPassword = async(email)=> {
    let params = {user: {  email }}
    if (recaptchaEnabled) {
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    const api = new AuthApi(locale , country)
    api
    .resetPassword(params)
      .then(res=>  {
        setMode('afterReset')
      } )
      .catch(error=> setErrorBag({ email: last(get(error,'email'))}))
  }
  return (
   <>
     <div className={`md:p-6 p-4`}>
       <div className={`flex flex-col gap-3`}>
         <AuthModalHeader onClose={onClose} title={Translation.t('texts.reset_password')} subTitle={ Translation.t('texts.foreget_password_sub_title') } headerIconName={`ri-lock-2-line`}  />
       </div>

       {recaptchaEnabled && <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
        }

       {mode == 'reset' && (
       <form onSubmit={(e)=> {
         e.preventDefault()
         if (formDisabled)return ;
         // @ts-ignore
         forgetPassword(e.target.email.value)
       }}>
         <div className={`mt-4 flex flex-col gap-2`}>
           <AuthEmailInput error={errorBag.email}  />
           <div className="w-full text-center text-sm leading-5 text-coolGray-500 text-normal">
             {Translation.t('texts.forget_password_paragraph')}
           </div>
         </div>
         <div className={`mt-4`}>
           <AuthButton label={Translation.t('buttons.reset')} disabled={formDisabled} />
         </div>
       </form>
       )}

       {mode == 'afterReset' && (
       <div className="flex flex-col gap-4 pt-12">
         <div className={`flex justify-center items-center`}>
           <div className={`w-32 h-32 bg-emerald-50 flex items-center justify-center rounded-lg`}>
             <i className={`ri-mail-send-line text-4xl text-emerald-500`} />
           </div>
         </div>

         <div className="flex flex-col justify-center items-center text-base leading-6 font-normal text-coolGray-800 text-center pb-8 pt-2">
           {Translation.t('texts.forget_password_paragraph')}
         </div>

         <AuthButton label={Translation.t('buttons.got_it')} disabled={false} onSubmit={onClose} />

       </div>
       )}
     </div>

     {mode == 'reset' && (
     <div className="flex gap-1 justify-center items-center py-4 bg-coolGray-200  text-center">
       <p className="text-coolGray-700 text-sm leading-5 font-normal">
         {Translation.t('texts.do_not_have_account')}
       </p>
       <p className="text-emerald-500 cursor-pointer" onClick={()=> EventDispatcher.dispatch('open-auth-modal' , {mode: 'register'})}>
         {Translation.t('texts.create_new_account')}
       </p>
     </div>
     )}
   </>
  )

}

export default ForgetPassword