import { PostingOffer } from "@shobbak/react-services/dist/Entities";
import React, { useState } from "react";
import Translation from "../helper/Translation";
import BackendCall from "./BackendCall";
import Button from "./Button";
import SeparatedModal from "./SeparatedModal";
import Input from "./Input";
import { Rating } from "react-simple-star-rating";

export default function ({ offer, visible, onClose }: { offer: PostingOffer, visible: boolean, onClose: (refresh?: boolean) => any }) {
  const [rating, setRating] = useState(0)
  const [feedBack, setFeedBack] = useState("");
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState("");

  function confirmDelivery() {
    BackendCall.i().rate({
      rate_type: "entity",
      related_to_type: "PostingOffer",
      related_to_id: offer.id,
      score: rating,
      comment: feedBack
    })
      .then(response => {
        setSuccess(true)
      })
      .catch(error => {
        setError(error?.errors[0]?.detail ?? error?.errors?.errors[0]?.detail ?? "An error occurred while rating")
      })
  }

  return (
    <SeparatedModal
      closeBtn
      onClose={onClose}
      visible={visible}
      footer={
        !success && (<Button
          containerStyle="w-full"
          type="solid"
          size="medium"
          label={Translation.t('buttons.submit')}
          onPress={confirmDelivery}
        />)
      }
      body={
        !success ? (
          <div>
            <div className="flex flex-col gap-1 items-center">
              <img className="inline-block h-12 w-12 rounded-full" src={offer.whoWillBeRated?.avatarUrl} alt=""/>
              <p className="text-md font-medium">{ offer.whoWillBeRated?.name }</p>
            </div>
            <p className="mt-2 text-md text-coolGray-500 text-center">{ Translation.t('rating.rate_experience') }</p>
            <div className="flex items-center justify-center p-4">
              <Rating
                rtl={Translation.isRtl()} 
                size={32} 
                initialValue={rating} 
                onClick={(rating) => setRating(rating)}
                SVGclassName="inline-block"
              />
            </div>
            <Input
              label={Translation.t('rating.add_feedback')}
              lettersCount={150}
              value={feedBack}
              onChange={ e => setFeedBack(e.target.value)}
              multiline
              rows="3"
              placeholder={Translation.t('rating.write_your_comment')}
            />
            {error && (<p className="text-red-500 text-sm mt-2">{error}</p>)}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2">
            <i className="ri-star-smile-line text-emerald-500 ri-4x"></i>
            <p className="text-lg font-medium text-center">{Translation.t('texts.thank_you')}</p>
            <p className="text-md text-coolGray-500 text-center">{Translation.t('texts.thank_you_for_rating_experience_with', {name: offer.whoWillBeRated?.name})}</p>
          </div>
        )
      }
    />
  )
}
