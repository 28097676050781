import { Posting, PostingOffer } from "@shobbak/react-services/dist/Entities";
import React, { useEffect, useState, lazy, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"));
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"));
import Loader from "../src/Components/icon/Loader";
import DashboardStats from "../src/Components/profile/DashboardStats";
import ProfileCardWrapper from "../src/Components/profile/ProfileCardWrapper";
import Translation from '../src/helper/Translation';
import useAuth from "../src/Hooks/useAuth";
import AppContext from "../src/Services/AppContext";
import StatusGroups from "../src/Components/StatusGroups";
import 'remixicon/fonts/remixicon.css'
import OfferSummaryCard from "../src/Components/profile/OfferSummaryCard";
import PostingSummaryCard from "../src/Components/profile/PostingSummaryCard";

export default function ProfileDashboard({ token, locale, isVerified }) {
  const { isLoading, user, device, businessProfile } = useAuth({ token, locale })
  const [postingsIsLoading, setPostingsLoading] = useState(false)
  const [offersIsLoading, setOffersLoading] = useState(false)
  const [postings, setPostings] = useState<Posting[]>([])
  const [offers, setOffers] = useState<PostingOffer[]>([])
  const [statusGroup, setStatusGroup] = useState<string[]>([])

  useEffect(() => {
    getOffers()
  }, [])

  useEffect(() => {
    // will be called on mounted too
    getPostings()
  }, [statusGroup])

  function getPostings() {
    setPostingsLoading(true)
    BackendCall.i({ locale, token })
      .getPostingIndex({ page: { size: 3 }, filter: { group: statusGroup } })
      .then(response => {
        setPostings(response.records)
        setPostingsLoading(false)
      })
      .catch(() => setPostingsLoading(false))
  }

  function getOffers() {
    setOffersLoading(true)
    BackendCall.i({ locale, token })
      .getPostingOffersIndex({ page: { size: 3 } })
      .then(response => {
        setOffers(response.records)
        setOffersLoading(false)
      })
      .catch(() => setOffersLoading(false))
  }

  function onSelectStatusGroup(data) {
    setStatusGroup(Object.keys(data));
  };

  function renderRecentPostings() {
    if (postingsIsLoading) return <>{Loader()}</>

    if (postings.length == 0) return <div className="mt-2 w-full text-center text-coolGray-400 text-base font-normal leading-6">{Translation.t('texts.no_posts')}</div>

    return (
      <>
        {postings.map(posting => {
          return (
            <div key={posting.id}>
              <PostingSummaryCard posting={posting}></PostingSummaryCard>
            </div>
          )
        })}
        <a href="/my_postings">
          <button type="button" className="mt-4 w-full items-center px-6 py-3 border border-emerald-500 text-base leading-6 font-normal rounded-md text-emerald-500 bg-white hover:bg-emerald-50 hover:text-emerald-700 hover:border-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
            {Translation.t('buttons.view_all')}
          </button>
        </a>
      </>
    )
  }

  function renderRecentOffers() {
    if (offersIsLoading) return <>{Loader()}</>

    if (offers.length == 0) return <div className="mt-2 w-full text-center text-coolGray-400 text-base font-normal leading-6">{Translation.t('texts.no_offers')}</div>

    return (
      <>
        {offers.map(offer => {
          return (
            <div key={offer.id}>
              <OfferSummaryCard offer={offer}></OfferSummaryCard>
            </div>
          )
        })}
        <a href="/my_offers">
          <button type="button" className="mt-4 w-full items-center px-6 py-3 border border-emerald-500 text-base leading-6 font-normal rounded-md text-emerald-500 bg-white hover:bg-emerald-50 hover:text-emerald-700 hover:border-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
            {Translation.t('buttons.view_all')}
          </button>
        </a>
      </>
    )
  }

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <AppContext.Provider value={{ user: user, device: device, businessProfile: businessProfile }}>
          {
            isLoading ?
              Loader() :
              <ProfileCardWrapper token={token} locale={locale} isVerified={isVerified}>
                <ProfileCard withBoarder={false} title={Translation.t('profile_sidebar.dashboard')}>
                  <DashboardStats user={user} businessProfile={businessProfile} token={token} locale={locale}></DashboardStats>
                  <div className="flex flex-col mt-4">
                    <div className="mb-4">
                      <h3 className="text-lg leading-6 font-semibold text-coolGray-700 mb-4">{Translation.t('buttons.my_posts')}</h3>
                      <div className="mb-4">
                        <StatusGroups type="posting" onSelect={onSelectStatusGroup} />
                      </div>
                      {renderRecentPostings()}
                    </div>
                    <div>
                      <h3 className="text-lg leading-6 font-semibold text-coolGray-700 mb-4">{Translation.t('texts.orders')}</h3>
                      {renderRecentOffers()}
                    </div>
                  </div>
                </ProfileCard>
              </ProfileCardWrapper>
          }
        </AppContext.Provider>
      </ProfileLayout>
    </Suspense>
  )
}
