import React, { Suspense, useEffect, useState } from "react";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import ProfileCard from "../src/Components/profile/ProfileCard";
import ProfileLayout from "../src/Components/ProfileLayout";
import Translation from "../src/helper/Translation";
import 'remixicon/fonts/remixicon.css'
import Divider from '../src/Components/Divider'
import { UserResume } from "@shobbak/react-services/dist/Entities";
import SeparatedModal from "../src/Components/SeparatedModal";
import isEmpty from 'lodash/isEmpty'
import { ToggleButton } from "../src/Components/Posting/Steps/AdLocationStep";
import { toast } from "react-toastify";
import EventDispatcher from "../src/helper/EventDispatcher";


function MyResumes({ locale, token }) {

	const [userResumes , setUserResumes] = useState<UserResume[]>([]);
	const [isLoading , setIsLoading] = useState(false)
	const [selectUserResume , setSelectUserResume] = useState<UserResume | any>({})
	const [render , setRerender] = useState(false)

	useEffect(()=>{
		setIsLoading(true)
		BackendCall.i({ token, locale })
		.userResumeList()
			.then(response=>{
				setUserResumes(response)
				setIsLoading(false)
			}).catch(error => {
				setIsLoading(false)
		})
	} , [])


	useEffect(()=> {
		EventDispatcher.on('confirmed', ({detail})=> {
			setIsLoading(true)
			BackendCall.i({ token, locale })
			.deleteUserResume(detail?.id)
			.then(response=>{
				const resumes = detail.collection.filter((item)=>   response.id != item.id   )
				setSelectUserResume({})
				setUserResumes(resumes)
				setIsLoading(false)
				EventDispatcher.dispatch('close-warning-modal')
			}).catch(error => {
				setIsLoading(false)
			})

		})

		EventDispatcher.on('discard' , ()=> {
			setIsLoading(false)
		})

	} , [])

	const markUserResumePrimary = (userResume) => {
		if (userResume.primary) return ;
		setIsLoading(true)
		BackendCall.i({ token, locale })
		.markUserResumePrimary(userResume?.id)
		.then(response=>{
			const userResumesClone = userResumes.map((item)=>  {  return { ...item , primary: (response.id == item.id ? true : false) }    })
			setUserResumes(userResumesClone)
			setSelectUserResume(response)
			setIsLoading(false)
			toast.success(Translation.t('texts.updated'))
		})

	}


  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard
          title={Translation.t("breadcrumb.my_resume")}
        >

          <ResumeHeader />
					<Divider label={Translation.t("texts.or_choose_from_list")} darker containerStyle="my-6" />

					{isLoading && (<Loader />)}
					{!isLoading  && userResumes  && (
					<div className={`flex flex-col gap-2`}>
						{/*is_primary ? bg-white : bg-coolGray-50 */}
						{userResumes.map((userResume: UserResume)=> { return ( <div className={`${userResume.primary ? 'bg-coolGray-50' : 'bg-white'} flex p-2 border border-coolGray-200 gap-2 rounded-md`}>
								<div className={`${userResume.primary ? 'bg-coolGray-50' : 'bg-white'} rounded-xl w-8 h-8 flex flex-col items-center justify-center bg-white `}>
									<i className="ri-article-fill ri-lg text-emerald-500" />
								</div>
								<div className={`flex flex-col w-full gap-2 flex-1`}>
									<div className={`flex flex-1 justify-between`}>
										<div className={`flex flex-col gap-2`}>
											<p className={`text-sm leading-5 font-semibold text-coolGray-700`}>{userResume.currentPosition}</p>
											<p className={`text-sm leading-3 font-normal text-coolGray-500`}>{userResume.primary
												? Translation.t('texts.resume_will_be_apply_to_next_job')
												: Translation.t('texts.make_resume_default_to_apply_next_job')
											}</p>
										</div>
										<div className={`flex`}>
											<input id="is_primary" name="is_primary" checked={userResume.primary} type="radio" className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
										</div>
									</div>
									<div className={`flex flex-col gap-2`}>
										<Divider height={1} containerStyle={'py-1'}/>
										<div className={`flex flex-row gap-2 items-center`}>
											<div className={`h-6 h-6 flex items-center rounded-full justify-center bg-coolGray-50`}>
												<i className="ri-file-pdf-fill ri-lg text-black" />
											</div>
											<p className={`text-sm leading-5 font-semibold text-coolGray-700 cursor-pointer`} onClick={()=> {
												window.open(userResume.resumeUrl)
											}}>{userResume?.resumeFileName}</p>
										</div>
										<Divider height={1} containerStyle={'py-1'}/>
										<div className={`flex gap-2`}>
											 <div className={`flex gap-1.5 cursor-pointer`} onClick={()=> {  setSelectUserResume(userResume) }}>
												 <i className="ri-edit-fill ri-lg text-black" /> <span className={`text-sm leading-0 font-normal`}>{Translation.t('texts.edit_cv')}</span>
											 </div>
										</div>
									</div>
								</div>

							</div>
						)})}
					</div>
					)}


					{!isLoading && userResumes.length == 0 && (
						<span className={`flex justify-center items-center`}>{Translation.t('posting.tabs.not_results')}</span>
					)}


         <SeparatedModal visible={!isEmpty(selectUserResume)}
												 onClose={()=> { setSelectUserResume({}) ; setRerender(! render) }}
												 headerLabel={Translation.t("texts.create_resume_description")}
												 body={
														 <div className={`flex flex-col gap-2`}>
                               <div className={`flex items-center gap-4 p-2 bg-coolGray-50`}>
                                  <div className={`h-8 w-8 bg-white flex items-center justify-center`}>
																		<i className="ri-article-fill ri-lg text-emerald-500" />
																	</div>
																 <p className={`text-sm leading-5 font-semibold text-coolGray-700`}>{selectUserResume?.currentPosition}</p>
															 </div>
															 <h2 className={`text-base leading-7 font-semibold text-coolGray-700`}>{Translation.t('buttons.settings')}</h2>
															 {/*delete*/}
															 <div  onClick={()=> {
																 EventDispatcher.dispatch('open-warning-modal' ,
																 {
																	 title: Translation.t('texts.delete'),
																	 display_action: Translation.t('texts.delete'),
																	 paragraph: Translation.t('texts.are_you_sure' ,{action: Translation.t('texts.delete')}),
																	 action: Translation.t('texts.delete'),
																	 id: selectUserResume?.id,
																	 collection : userResumes
																 })
																 setSelectUserResume({})
															 }} className={`flex items-center justify-between gap-4 cursor-pointer`}>
																 <div className={`flex items-center gap-4`}>
																	 <div className={`w-8 h-8 flex items-center justify-center bg-coolGray-100 rounded-lg`}>
																		 <i className="ri-delete-bin-fill ri-x text-coolGray-700" />
																	 </div>
																	 <span className={`text-base leading-6 font-normal text-coolGray-700`}>{Translation.t('texts.delete')}</span>
																 </div>
																 <i className={`ri-arrow-right-s-line ri-xl text-coolGray-700`} />
															 </div>


															 <Divider height={1}  containerStyle="my-2" />

															 <h2 className={`text-base leading-7 font-semibold text-coolGray-700`}>{Translation.t('texts.controls')}</h2>

															 {/*mark as primary*/}
															 <div  onClick={()=> {}} className={`flex items-center justify-between gap-4 cursor-pointer`}>
																 <div className={`flex items-center gap-4`}>
																	 <div className={`w-8 h-8 flex items-center justify-center bg-coolGray-100 rounded-lg`}>
																		 <i className="ri-shield-keyhole-fill ri-x text-coolGray-700" />
																	 </div>
																	 <span className={`text-base leading-6 font-normal text-coolGray-700`}>{Translation.t('create_new_ad.make_as_a_primary')}</span>
																 </div>

																 <ToggleButton  value={selectUserResume?.primary} onToggle={()=> {
																	 markUserResumePrimary(selectUserResume)
																 }} />
															 </div>

														 </div>
												 }
												 closeBtn />



				</ProfileCard>
      </ProfileLayout>
    </Suspense>
  );
}

export const ResumeHeader = ({ }) => {
	return (
		<div className="border border-dashed border-emerald-500 rounded-lg p-2 flex items-center gap-4 w-full">
			<div className="w-10 h-10 bg-emerald-50 rounded-xl flex items-center justify-center">
				<i className="ri-folder-add-line ri-lg text-emerald-500" />
			</div>
			<div className="flex flex-col gap-1">
				<p
					className="font-semibold text-emerald-500 cursor-pointer"
					onClick={() => {
						window.trackActivity({ event: 'resume_creation_started', processPayload: false, rawPayload: {}, redirectUrl: '/user_resumes/new' })
					}}>
					{Translation.t("texts.create_resume")}
				</p>
				<p className="text-xs leading-none text-coolGray-500">{Translation.t("texts.create_resume_description")}</p>
			</div>
		</div>
	);
}

export default MyResumes;
