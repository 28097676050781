import { PostingOffer } from "@shobbak/react-services/dist/Entities";
import React, { useState } from "react";
import Translation from "../helper/Translation";
import BackendCall from "./BackendCall";
import LockIcon from "./icon/LockIcon";
import Modal from './Modal';

export default function ({ offer, isVisible, onClose }: { offer?: PostingOffer, isVisible: boolean, onClose: (refresh?: boolean) => any }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  function confirmDelivery() {
    BackendCall.i().sellerHandoversBuyer(offer.id, code)
      .then(response => {
        onClose(true)
      })
      .catch(error => {
        setError(error?.errors[0]?.detail ?? "An error occurred while confirming the code")
      })
  }

  return (
    <Modal
      visible={isVisible}
      onClose={() => {
        setError("")
        onClose()
      }}
      containerStyle="w-full"
      body={
        <div className="w-full flex space-x-2 mb-4">
          <div className="flex items-center justify-center bg-emerald-100 p-2 rounded-full h-10 w-10">
            <LockIcon />
          </div>
          <div>
            <p className="mb-2 text-lg leading-6 font-medium">Delivery Code</p>
            <p className="mb-4 text-sm leading-5 font-normal">This code will be shared with you from the buyer side to confirm the purchase</p>
            <div className="flex flex-col mb-2">
              <label htmlFor="code" className="block text-sm leading-5 font-normal text-coolGray-700">Code</label>
              <input value={code} onChange={(e) => setCode(e.target.value)} required={true} type="text" name="code" id="code" className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            {error && (
              <p className="mb-4 text-sm leading-5 font-normal text-red-400">{error}</p>
            )}
          </div>
        </div>
      }
      footer={
        <div className="w-full pt-2 flex flex-row-reverse">
          <div className="me-2">
            <button onClick={confirmDelivery} className="px-4 py-1 rounded-lg bg-emerald-500 text-white">{Translation.t('buttons.confirm')}</button>
          </div>
          <div className="me-2">
            <button
              onClick={() => {
                setError("")
                onClose()
              }}
              className="px-4 py-1 rounded-lg bg-coolGray-50 text-coolGray-400 border border-coolGray-200">
              {Translation.t('buttons.cancel')}
            </button>
          </div>
        </div>
      }
    />
  )
}
