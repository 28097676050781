import React, { useRef } from 'react'
import Translation from '../helper/Translation'
import DeleteBinEmpty from './icon/DeleteBinEmpty';

export default function DocumentsUploader({ keyName, onUpload, documents = [], disabled = false, limit = 1, onDeleted, accept = '' }) {
  const fileInput = useRef(null);

  return (
    <div className="flex flex-col md:flex-row w-full md:space-s-4">
      <div
        className={`flex ${documents.length ? 'w-full md:w-1/2' : 'w-full'} justify-center mb-2 md:mb-0 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md`}>
        <div className="space-y-1 text-center">
          <svg className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} mx-auto h-12 w-12 text-gray-400`} stroke="currentColor" fill="none"
            onClick={() => fileInput.current.click()}
            viewBox="0 0 48 48" aria-hidden="true">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label htmlFor={`file-upload-${keyName}`} className={`relative ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} bg-white rounded-md font-medium text-emerald-600 hover:text-emerald-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-emerald-500`}>
              <span className="text-emerald-600">{Translation.t('texts.upload_file')}</span>
              <input
                accept={accept}
                id={`file-upload-${keyName}`}
                ref={fileInput}
                name={`file-upload-${keyName}`}
                type="file"
                className="sr-only"
                disabled={disabled}
                multiple={limit > 1}
                onChange={e => {
                  if (e.target.files.length > limit) return
                  onUpload(e.target.files)
                }}
              />
            </label>
            <p className="pl-1">{Translation.t('texts.drop_upload_image')}</p>
          </div>
          <p className="text-xs text-gray-500"> {accept != '' ? accept : Translation.t('texts.file_types')} </p>
        </div>
      </div>
      <div className={`flex flex-col ${documents.length ? 'w-full md:w-1/2' : ''}`}>
        {documents.map((d, i) => {
          return (
            <div key={i} className="flex justify-between w-full py-2 px-3 mb-2 border border-gray-300 border-dashed rounded-md">
              <p>{d.name}</p>
              <button
                className="bg-gray-200 p-2 rounded hover:bg-gray-300"
                onClick={() => {
                  onDeleted(i)
                  fileInput.current.value = ""  // resetting the input to allow uploading the same file again after deleting it
                }}>
                <DeleteBinEmpty className="fill-current text-coolGray-900" width="12" height="12" />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
