import React, {Fragment, Suspense} from "react";
import { lazily } from 'react-lazily'
const { Message, DateSeparator } = lazily(() => import('@shobbak/bulbul-chat-web'))
import {ChatMessage as BulbulChatMessage} from 'bulbul-chat';
import Loader from "../src/Components/icon/Loader";

interface ChatMessage extends BulbulChatMessage {
  __sender__?: {
    externalId: string;
    name: string;
    photoUrl: string
  }
}

export default ({postingOwnerId, messages}) => {
  return (
    <Suspense fallback={Loader()}>
      {messages.map((message: ChatMessage, index) => {
        return (
          <Fragment key={message.id}>
            <DateSeparator message={message} previousMessage={messages[index-1]} />
            <Message
              message={message}
              sender={{name: message?.__sender__?.name, image: message?.__sender__?.photoUrl}}
              isMe={message?.__sender__?.externalId === postingOwnerId}
              useFullName
            />
          </Fragment>
        );
      })}
    </Suspense>
  )
}
