import React, { useEffect, useState } from 'react'
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import DefaultButton from "../src/Components/EditProfile/DefaultButton";
import { toast } from "react-toastify";
import PostingCommunication from "../src/Components/Posting/PostingCommunication";
import { Category, Country } from "@shobbak/react-services/dist/Entities";
import ModalComponent from "../src/Components/Modal";


export type PostingCommunicationType = {
  category?: Category | any;
  communications: any[];
  mobile_communications: any[];
  email_communications: any[];
  auth_user?: { user: {}, country: Country };

}


const EditPostingCommunication = ({ token , authUser ,locale , postingId, recaptchaEnabled=true  })=> {
  const[isEditable , setIsEditable] = useState(false)
  const [isLoading , setIsLoading] = useState(false)
  const [posting , setPosting] = useState<PostingCommunicationType>( { category: {}  ,auth_user: authUser, communications: []  , mobile_communications: [] , email_communications: []})
  const [formSubmitted , setFormSubmitted] = useState(false)




  useEffect(()=> {
    EventDispatcher.on('edit-contact-detail' , (event)=> {
      setIsEditable(true)
      setIsLoading(true)
      BackendCall.i({token , locale})
      .getPosting(postingId)
        .then(({communications , category})=>  {
          const email_communications = communications.filter((item)=> item.communicationType == 'email')
          const mobile_communications = communications.filter((item)=>  !['email','chat'].includes(item.communicationType))
          const chat  =  communications.filter((item)=> item.communicationType == 'chat')
          setPosting({ category: category , email_communications: email_communications ,auth_user: authUser , mobile_communications: mobile_communications , communications:chat})
          setIsLoading(false)

        })
    })

    return ()=> {  }
  } , [])



  const updatePosting = ()=> {


    const new_communications =  [...posting.email_communications, ...posting.mobile_communications, ...posting.communications]

    BackendCall.i({token , locale})
    .postingUpdate(postingId , {  communications: new_communications  })
    .then(res=> {
      toast.success(Translation.t('texts.ads_updated'));
      setTimeout(()=> window.location.reload() , 100)
    })
  }


  const header = ()=> {
    return (
      <div className={``}>
         <div className={`flex items-center justify-between  p-4`}>
           <span className={`text-base leading-6 font-semibold text-coolGray-900`}>{Translation.t('screens.edit_post.title')}</span>
           <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`}  onClick={()=> setIsEditable(false) }/>
         </div>
      </div>
    );
  }

  const footer = ()=> {
    return (
      <div className={``}>
        <div className={`flex w-full p-4`}>
          <DefaultButton label={Translation.t('buttons.save')} containerClass={`w-full`} disabled={formSubmitted}  onButtonClick={updatePosting} />
        </div>
      </div>
    );
  }

  if (!isEditable) return <></>

  return (
      <ModalComponent
        visible={true}
        onClose={()=>{ setIsEditable(false) }}
        body={isLoading ? <Loader /> :  (
          <div className={`flex flex-col`}>
            {header()}
            <div className={`px-4 pb-4 max-h-96 md:max-h-120 overflow-y-auto slider-scroll-style`}>
              <PostingCommunication recaptchaEnabled={recaptchaEnabled} withOutDisplayIconName={true} state={posting} dispatch={({payload})=> {
                setPosting((prev)=> {
                  return {...prev , [payload['key']]: payload['value']}
                })
              }} />
            </div>
            {footer()}
          </div>
        )  }
      />
  )
}

export default EditPostingCommunication