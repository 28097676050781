import Translation from './Translation'

const isActive = (route) => {
  return window.location.pathname.split('/').includes(route) || window.location.pathname == route;
}

export const sidebar = [
  {
    id: 1,
    title: Translation.t('profile_sidebar.dashboard'),
    route: '/dashboard',
    group: 'dashboard',
    icon: 'bar-icon',
    active: isActive('dashboard')
  },
  {
    id: 2,
    title: Translation.t('buttons.my_stores'),
    route: '/my_stores',
    group: Translation.t('texts.shobbak_business'),
    icon: 'my-stores',
    active: isActive('my_stores')
  },
  {
    id: 3,
    title: Translation.t('buttons.all_stores'),
    route: '',
    group: Translation.t('texts.shobbak_business'),
    icon: 'all-stores',
    active: false
  },
  {
    id: 4,
    title: Translation.t('profile_sidebar.my_ads'),
    route: '/my_postings',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_postings'),
    icon: 'image-icon'
  },
  {
    id: 5,
    title: Translation.t('profile_sidebar.my_offers'),
    route: '/my_offers',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_offers'),
    icon: 'offer-icon',
  },
  {
    id: 6,
    title: Translation.t('profile_sidebar.my_auctions'),
    route: '/my_auctions',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_auctions'),
    icon: 'ri-auction-fill',
  },
  {
    id: 7,
    title: Translation.t('profile_sidebar.my_bids'),
    route: '/my_bids',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_bids'),
    icon: 'ri-auction-fill',
  },
  {
    id: 8,
    title: Translation.t('profile_sidebar.bookmarks'),
    route: '',
    group: Translation.t('profile_sidebar.profile'),
    active: false,
    icon: 'bookmark-icon'
  },
  {
    id: 9,
    title: Translation.t('texts.frauds'),
    route: '/frauds',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('frauds'),
    icon: 'ri-spy-fill'
  },
  {
    id: 10,
    title: Translation.t('profile_sidebar.my_resume'),
    route: '/user_resumes',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('user_resumes'),
    icon: 'ri-file-list-fill'
  },
  {
    id: 11,
    title: Translation.t('profile_sidebar.my_contacts'),
    route: '',
    group: Translation.t('profile_sidebar.profile'),
    active: false,
    icon: 'contact-phone-icon'
  },
  {
    id: 12,
    title: Translation.t('profile_sidebar.my_addresses'),
    route: '/my_addresses',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_addresses'),
    icon: 'pin-distance-icon'
  },
  {
    id: 13,
    title: Translation.t('profile_sidebar.my_wallet'),
    route: '/my_wallet',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_wallet'),
    icon: 'wallet-icon'
  },
  {
    id: 14,
    title: Translation.t('texts.shobbak_gifts'),
    route: '/my_shobbak_cards',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_shobbak_cards'),
    icon: 'ri-gift-fill'
  },
  {
    id: 15,
    title: Translation.t('profile_sidebar.my_invoices'),
    route: '/my_invoices',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('my_invoices'),
    icon: 'bill-icon'
  },
  {
    id: 16,
    title: Translation.t('profile_sidebar.edit_profile'),
    route: '/users/edit',
    group: Translation.t('profile_sidebar.settings'),
    active: isActive('/users/edit'),
    icon: 'edit-icon'
  },
  {
    id: 17,
    title: Translation.t('profile_sidebar.payment_methods'),
    route: '/my_payment',
    group: Translation.t('profile_sidebar.settings'),
    active: isActive('my_payment'),
    icon: 'bank-card-icon'
  },
  {
    id: 18,
    title: Translation.t('profile_sidebar.notifications'),
    route: '/notifications',
    group: Translation.t('profile_sidebar.profile'),
    active: isActive('notifications'),
    icon: 'notification-icon'
  },
  {
    id: 19,
    title: Translation.t('buttons.sign_out'),
    route: '/users/sign_out',
    method: 'DELETE',
    group: Translation.t('profile_sidebar.settings'),
    active: false,
    icon: 'ri-logout-box-line h-auto'
  }
]

