import { ChatChannel } from "bulbul-chat/dist/src/ChatClient";


export type ChatContentUser = {
  id: string;
  userName: string;
  userImageUrl: string;
  content: string;
  contentType: string;
  createdAt: Date | string;
  unreadMessageCount: number ;
  chatUrl: string;
};

export default class ChatServiceHelper {
  private channels: ChatChannel[] =  []

  constructor(channels: ChatChannel[]) {
    this.channels = channels
  }

  getPreparedCollection(): ChatContentUser[]{
   return  this.channels.map(channel=> {
      const user = channel.channelMemberships.find(item => item.externalRole == 'buyer')?.user
      return   {
        id: channel.id ,
        userName: user.name ,
        unreadMessageCount: channel.unreadMessageCount ,
        userImageUrl: user.photoUrl ,
        content: channel.lastMessage.content ,
        contentType: channel.lastMessage.messageType ,
        createdAt: new Date(channel.lastMessage.createdAt),
        chatUrl: `/chat/${channel.externalId}`
      }
    }).slice(0,2)
  }






}