import React, { Fragment, useEffect } from 'react';
import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';
import BackendCall from '../BackendCall';
import AuctionsSlider from './AuctionsSlider';

interface Props {
	title: string;
	subtitle: string;
}

const EmptyState = ({title, subtitle}: Props) => {
	const [auctionsCategories, setAuctionsCategories] = React.useState([])

	useEffect(() => {
    BackendCall.i().getAuctions('grouped')
			.then((res) => {
				setAuctionsCategories(res)
			})
	}, [])
  
  return (
		<div className="p-4">
			<div className="mb-4">
				<div className="flex items-center justify-center mb-2">
					<AuctionRulesSvg />
				</div>
				<p className="font-bold text-2xl text-emerald-500 text-center mb-1">
					{title}
				</p>
				<p className="text-center text-sm text-coolGray-500 px-4 mb-6">
					{subtitle}
				</p>
			</div>
			
			<div className="flex flex-col space-y-5">
				{auctionsCategories.map(category => (
					<Fragment key={category.id}>
						<AuctionsSlider category={category.category as any}/>
					</Fragment>
				))}
			</div>
		</div>
  );
};

export default EmptyState;