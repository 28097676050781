import { PostingAuction } from '@shobbak/react-services/dist/Entities';
import React from 'react';
import { toast } from 'react-toastify';
import AuctionRulesSvg from '../../Assets/svg/Auctions/AuctionRules';
import Translation from '../../helper/Translation';
import BackendCall from '../BackendCall';
import Button from '../Button';
import SeparatedModal from '../SeparatedModal';
import 'remixicon/fonts/remixicon.css'
import ModalComponent from '../Modal';
import TimerContainer from './TimerContainer';
import Bids from './Bids';

export type Props = {
  visible: boolean;
  onClose?: () => void;
  auction: PostingAuction;
	onAction?: () => void;
};

type Action =
	'accept' |
	'cancel'

export default function SellerAuctionDetails({visible, onClose, auction, onAction}: Props) {
  const [confirmAcceptVisible, setConfirmAcceptVisible] =
    React.useState<boolean>(false);
  const [confirmCancelVisible, setConfirmCancelVisible] =
    React.useState<boolean>(false);

  const executeAction = (action: Action) => {
    BackendCall.i()
      .auctionActions(String(auction?.id), action, {})
      .then((res) => {
				if(onAction) {
					onAction()
				}
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
		<>
			<SeparatedModal
				visible={visible}
				closeBtn
				headerLabel={auction?.statusTitle}
				onClose={onClose}
				body={
					<div className="p-4 bg-white">
						<div className="flex items-center justify-center mb-2">
							<AuctionRulesSvg />
						</div>
						<p className="font-semibold text-lg text-center mb-1">{auction?.statusTitle}</p>
						<p className="text-sm leading-5 text-center text-coolGray-500 mb-2">{auction?.statusDescription}</p>
						<TimerContainer
						  auction={auction}
						  containerStyle="mb-6"
						/>
						<div className="p-2 border border-dashed border-coolGray-300 rounded mb-6">
							<p className="font-bold text-emerald-500 text-center mb-0.5 text-3xl">
								{auction?.currentBidAmount.humanized}
							</p>
							<p className="text-coolGray-400 text-center text-xs">
								{Translation.t('auctions.labels.highest_bid')}
							</p>
						</div>

						<Bids auction={auction} />

						{auction?.postingAuctionBids.length === 0 && (
							<div className="p-2 rounded-lg bg-orange-50 flex">
								<i className="ri-auction-fill text-orange-700" />
								<p className="ms-2 text-orange-700 text-start text-xs">
									{Translation.t('auctions.messages.no_bids')}
								</p>
							</div>
						)}
					</div>
				}
				footer={
					<div className="flex items-center space-s-4">
						{auction?.allowedActions.includes('cancel') && (
							<Button
								containerStyle="flex-1"
								label={Translation.t('auctions.actions.end_auction')}
								type="outline"
								color="coolGray-400"
								onPress={() => {
									setConfirmCancelVisible(true);
								}}
							/>
						)}

						{auction?.allowedActions.includes('accept') && (
							<Button
								label={Translation.t('auctions.actions.accept_bid_amount', {
									bid: auction?.currentBidAmount.humanized,
								})}
								containerStyle="flex-1"
								onPress={() => {
									setConfirmAcceptVisible(true);
								}}
							/>
						)}
					</div>
				}
			/>

			{/* accept modal */}
			<ModalComponent
				visible={confirmAcceptVisible}
				body={
					<div className="p-4 bg-white">
						<div className="flex items-center justify-center mb-2">
							<AuctionRulesSvg />
						</div>
						<p className="font-bold text-center mb-1 text-emerald-500 text-lg">
							{Translation.t('auctions.labels.sure_accept')}
						</p>
						<p className="text-center text-coolGray-500 mb-2 text-xs">
							{Translation.t('auctions.messages.accept_bid_message')}
						</p>

						<div className="p-2 border border-dashed border-coolGray-300 rounded mb-6">
							<p className="font-bold text-emerald-500 text-center mb-0.5 text-3xl">
								{auction?.currentBidAmount.humanized}
							</p>
							<p className="text-coolGray-400 text-center text-xs">
								{Translation.t('auctions.labels.highest_bid')}
							</p>
						</div>
						
					</div>
				}
				footer= {
					<div className="flex items-center">
						<Button
							onPress={() => {
								setConfirmAcceptVisible(false);
							}}
							type="outline"
							color="coolGray-400"
							label={Translation.t('auctions.actions.not_now')}
							containerStyle="flex-1 me-4"
						/>
						<Button
							label={Translation.t('auctions.actions.accept_bid')}
							containerStyle="flex-1"
							onPress={() => {
								setConfirmAcceptVisible(false);
								executeAction('accept');
							}}
						/>
					</div>
				}
			/>

			{/* cancel modal */}
			<ModalComponent
				visible={confirmCancelVisible}
				body={
					<div className="p-4 bg-white">
						<div className="flex items-center justify-center mb-2">
							<AuctionRulesSvg />
						</div>
						<p className="font-bold text-center mb-1 text-red-500 text-lg">
							{Translation.t('auctions.labels.sure_end')}
						</p>
						<p className="text-center text-coolGray-500 mb-2 text-xs">
							{Translation.t('auctions.messages.end_auction_message')}
						</p>
					</div>
				}
				footer= {
					<div className="flex items-center">
						<Button
							onPress={() => {
								setConfirmCancelVisible(false);
							}}
							type="outline"
							color="coolGray-400"
							label={Translation.t('auctions.actions.keep_live')}
							containerStyle="flex-1 me-4"
						/>
						<Button
							label={Translation.t('auctions.actions.end_auction')}
							containerStyle="flex-1"
							color="red-500"
							onPress={() => {
								setConfirmCancelVisible(false);
								executeAction('cancel');
							}}
						/>
					</div>
				}
			/>   
		</>
  );
}
