import React, { useEffect, useState } from "react";
import BackendCall from "./BackendCall";
import Translation from '../helper/Translation'
import BaseModal from '../Components/Posting/Modal/BaseModal'
import { MapIcon } from "@heroicons/react/solid";
import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import useDebouncedValue from "../Hooks/useDebouncedValue";
import MapWithDraggableMarker from "./Maps/MapWithDraggableMarker";
import { LatLng } from "../Types/LatLng";
import CountryCode from "../helper/countryCode";
import first from 'lodash/first'
import { AddressCategory } from "@shobbak/react-services/dist/Entities";
import { toast } from "react-toastify";
import ErrorBag from "../../components/ErrorBag";

export default function CreateUserAddressModal({ mode='create' ,address = {},googleMapsApiKey, currentCountry,afterSubmit, onClose }) {
  const [location, setLocation] = useState<Location>();
  const [userCoords, setUserCoords] = useState<LatLng>();
  const debouncedUserCoords = useDebouncedValue(userCoords?.latitude, 1000);
  const [addressCategories, setAddressCategories] = useState<AddressCategory[]>([])
  const [locationType, setLocationType] = React.useState<string>();
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [currentMobilePrefix, setCurrentMobilePrefix] = useState(first(CountryCode.available().find(item => item.isoCode3 == currentCountry.code3).countryCodes))
  const [errorBag, setErrorBag] = useState([])

  useEffect(()=> {
    if(isEditMode()){
      setPhoneNumber(address?.phoneNumber)
      setLocation(address?.address)
      setLocationType(address?.category)
    }
  } , [])


  const isEditMode = ()=> {
    return mode == 'edit' && Object.keys(address).length > 0
  }

  const onSubmit = async () => {

    const params = {
      phone_number: phoneNumber,
      lat: userCoords?.latitude,
      lon: userCoords?.longitude,
      address: location,
      city_id: location?.city_id || '204',
      category: locationType,
    };

    let caller = null
    if (isEditMode()){
      caller = BackendCall.i().updateUserAddress(address?.id,params)
    }else{
      caller = BackendCall.i().createUserAddress(params)
    }

    caller.then(res=>  {
      afterSubmit(res)
      toast.success(isEditMode() ? Translation.t('texts.user_address_updated') : Translation.t('texts.user_address_created'))
    })
    .catch(({errors})=> setErrorBag(errors))
  }

  useEffect(() => {
    BackendCall.i().lookupAddressCategories().then((res) => {
      setAddressCategories(res)
    })
  }, [])

  useEffect(() => {
    ( async () => {
      if (userCoords) {
        BackendCall.i().lookupsLocation(
          userCoords.latitude,
          userCoords.longitude,
        ).then((res) => {})

      }
    } )();
  }, [debouncedUserCoords]);

  return (
    <BaseModal onClose={onClose} overflowHidden={false}>
      <div className={`p-6 flex flex-col gap-4`}>

        <div className={`flex flex-row justify-between items-start`}>
          <div className={`flex flex-row gap-4`}>
            <div className={`flex items-center justify-center w-8 h-8 bg-emerald-50 rounded-md`}>
              <i className={`ri-pin-distance-fill text-emerald-500 ri-xl`} />
            </div>
            <div className={`flex flex-col gap-1`}>
              <h2
                className={`text-base leading-6 font-semibold text-coolGray-900`}>{isEditMode() ?  Translation.t('buttons.update_address') : Translation.t('texts.user_address.new')}</h2>
              {isEditMode() ? (<span></span>)
                : (<p
                className={`text-sm leading-5 font-normal text-coolGray-500`}>{Translation.t('texts.user_address.create_new_address')}</p>)}
            </div>
          </div>
          <div>
            <GrFormClose className={`w-5 h-5 cursor-pointer`} onClick={onClose}/>
          </div>
        </div>
        {errorBag.length >0 && (<ErrorBag errorBag={errorBag} withOutTitleInErrorBag={true} />) }
        <div className={`h-52`}>
          <MapWithDraggableMarker googleMapsApiKey={googleMapsApiKey}
                                  lat={ isEditMode() ? address?.lat :  userCoords?.latitude}
                                  lon={isEditMode() ? address?.lon : userCoords?.longitude}
                                  onDragEnd={({ lat, lon, address_line }) => {
            setUserCoords({ latitude: lat, longitude: lon })
            setLocation(address_line)

          }}/>
        </div>


        {location && ( <div className="mt-4">
          <label htmlFor="address"
                 className="mb-2 text-base leading-6 font-semibold text-coolGray-700">{Translation.t('create_new_ad.address')}</label>
          <div className="px-3 py-2 border rounded-md border-gray-200">
        <span className="text-sm leading-0 font-normal text-coolGray-500">
      {location}
        </span>
          </div>
        </div> )}


        <div className="">
          <h2
            className="text-base leading-6 font-semibold text-coolGray-700">{Translation.t('create_new_ad.select_address_type')}</h2>
          <div className="flex flex-wrap gap-3 mt-2">
            {addressCategories.map((v: AddressCategory) => {
              return <div
                onClick={
                  () => {
                    setLocationType(v.key)
                  }
                }
                className={`flex flex-wrap gap-2 rounded-xl md:py-1 md:px-2 px-1  items-center justify-center cursor-pointer ${locationType == v.key ? 'bg-emerald-500' : 'bg-coolGray-50'}`}
                key={v.id}>
                <img src={v.svgPath} className="md:w-6 md:h-6 w-4 h-4 text-white" alt=""/>
                <span
                  className={`text-xs leading-5 font-medium ${locationType == v.key ? 'text-white' : 'text-coolGray-700'}`}>{v.name}</span>
              </div>
            })}
          </div>

        </div>

        <div className="flex flex-col">
          <label className="text-sm leading-5 font-medium inline-flex flex-end  text-gray-700">{Translation.t('create_new_ad.ad_phone_number')}</label>
          <div
            className="flex mt-2 border rounded-md overflow-hidden focus-within:ring-2 focus-within:ring-emerald-500  shadow-sm focus:outline-none">

            <span className="sm:text-sm text-center p-2 border-e border-t-0 bg-coolGray-50 text-base leading-6 text-coolGray-500 text-normal border-b-0 border-s-0 focus:ring-0
                   border-white focus:outline-none focus:border-emerald-500">
              {currentMobilePrefix}
            </span>

            <input type="text" id="search"
                   onChange={(e) => {
                     setPhoneNumber(e.target.value)
                   }}
                   value={phoneNumber}
                   className="block px-3 w-full sm:text-sm border-0 border-white  focus:outline-none focus:ring-0 focus:border-white"/>
          </div>
        </div>

        <div>
          <input onClick={onSubmit} type="submit" name="commit"
                 className="mt-4 cursor-pointer inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm"
                 value={ isEditMode() ?  Translation.t('buttons.update_address') : Translation.t('buttons.add_new_address')  }/>
        </div>


      </div>
    </BaseModal>
  )

}