import React from 'react';

export default function LiveAuctionTimer({expiresAt, closed = false}) {

  return (
		<div className="flex items-center space-s-1" x-data="dhmsCountDown()" x-init={`init(new Date('${closed ? new Date : expiresAt}'))`}>
			<span className={`inline-flex w-6 h-6 items-center justify-center text-md bg-coolGray-50 rounded ${closed ? 'text-red-500' : 'text-coolGray-700'}`} x-text={"pad(days)"}></span> 
			<span className="text-sm ">:</span>
			<span className={`inline-flex w-6 h-6 items-center justify-center text-md bg-coolGray-50 rounded ${closed ? 'text-red-500' : 'text-coolGray-700'}`} x-text="pad(hours)"></span> 
			<span className="text-sm ">:</span>
			<span className={`inline-flex w-6 h-6 items-center justify-center text-md bg-coolGray-50 rounded ${closed ? 'text-red-500' : 'text-coolGray-700'}`} x-text="pad(minutes)"></span> 
			<span className="text-sm ">:</span>
			<span className={`inline-flex w-6 h-6 items-center justify-center text-md bg-coolGray-50 rounded ${closed ? 'text-red-500' : 'text-coolGray-700'}`} x-text="pad(seconds)"></span> 
		</div>
  );
}
