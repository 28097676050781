import React from "react";

interface WarningProps {
  title: string;
  description: string;
  color?: 'red' | 'yellow'
}
function Warning({ title, description, color = 'red' }: WarningProps) {
  const COLORS = {
    red: { backgroundColor: 'bg-red-100', textColor: 'text-red-600' },
    yellow: { backgroundColor: 'bg-orange-50', textColor: 'text-orange-400' }
  }

  return (
    <div className={`flex flex-col gap-1.5 ${COLORS[color]['backgroundColor']} rounded-lg p-3  ${COLORS[color]['textColor']} w-full`}>
      <div className="flex gap-1 items-center justify-start">
        <i className="ri-alert-fill ri-lg"> </i>
        <p className="font-semibold text-normal">{title}</p>
      </div>
      <p className="font-normal text-xs">{description}</p>
    </div>
  )
}

export default Warning