import React from 'react'
import { Rating } from 'react-simple-star-rating'
import Translation from '../src/helper/Translation'

const RateStars = ({score}) => {

  return (
    <span className="flex gap-1.5 items-center leading-none">
      <Rating 
        readonly
        allowFraction
        rtl={Translation.isRtl()} 
        size={18} 
        initialValue={score} 
        SVGclassName="inline-block"
      />
      <span className="text-sm text-coolGray-400">({score})</span>
    </span>
  )
}

export default RateStars
