import {Buffer} from 'buffer';

import BackendCall from '../../../Components/BackendCall';
var md5 = require('md5');

async function directUpload(upload, onProgress) {
  const [file] = upload.file
  let srcPath = URL.createObjectURL(file)
  
  const md5Version = md5(decodeURI(srcPath));
  const size = file.size;
  const checksum = Buffer.from(md5Version!, 'hex').toString('base64');

  onProgress({status: 'uploading', progress: 0, uploading: true});

  let fileName = file.name;
  if (!fileName) {
    fileName = srcPath.split('/').pop();
  }

  const params = {
    blob: {
      filename: fileName,
      content_type: file.type,
      byte_size: size,
      service_name: upload.serviceName,
      checksum,
    },
  };

  const {url, requestOptions} = await BackendCall.i().buildRequest(
    'post',
    '/direct_uploads',
    null,
    params,
    {}
  );
  

  const directUploadRes = await fetch(url, requestOptions);
  const contentTypeHeader = directUploadRes.headers.get('content-type');
  const responseContentType = contentTypeHeader?.split(';')[0];

  if (
    directUploadRes.status !== 200 ||
    responseContentType !== 'application/json'
  ) {
    return Promise.reject(new Error(`Failed upload file`));
  }

  const directUploadResJson = directUploadRes.json();

  return directUploadResJson;
}

export default directUpload;
