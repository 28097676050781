import React from "react";
import Modal from "react-modal";
import 'remixicon/fonts/remixicon.css'

export type ModalComponentProps = {
  visible: boolean;
  headerLabel?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  hapticFeedback?: boolean;
  closeBtn?: boolean;
  backBtn?: boolean;
  leftBtn?: React.ReactNode;
  onClose?: () => void;
};

export default function SeparatedModal({
  visible,
  headerLabel,
  body = null,
  footer = null,
  hapticFeedback = false,
  closeBtn,
  backBtn,
  leftBtn,
  onClose,
}: ModalComponentProps) {
  return (
    <Modal
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      className="w-11/12 md:w-1/2 lg:w-1/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 focus-within:outline-none"
      overlayClassName="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      isOpen={visible}
      ariaHideApp={false}
    >
      <>
        <div className="p-4 bg-coolGray-50 flex items-center justify-between rounded-t-2xl">
          {backBtn ? (
            <button
              onClick={onClose}
              className="text-coolGray-400"
            >
              <i className="ri-arrow-left-line ri-lg"></i>
            </button>
          ) : leftBtn ? (
            leftBtn
          ) : (
            <div></div>
          )}
          <p className="text-center flex-1 text-coolGray-700 font-bold">{headerLabel}</p>
          {closeBtn && (
            <button
              onClick={onClose}
              className="text-coolGray-400"
            >
              <i className="ri-close-circle-fill ri-lg"></i>
            </button>
          )}
        </div>
        <div />
        <div
          className={`bg-white overflow-hidden flex-grow-0 ${!footer ? 'rounded-b-2xl' : ''}`}
        >
          <div className="bg-white p-4 overflow-y-auto" style={{maxHeight: 'calc(100vh - 100px)'}}>{body}</div>
        </div>
        {footer && <div className="p-4 mb-8 rounded-b-2xl bg-white">{footer}</div>}
      </>
    </Modal>
  );
}