import React, { FC, useEffect, useState, useMemo, useRef } from "react";
import Translation from '../../../helper/Translation';
import BackendCall from "../../BackendCall";
import MapWithDraggableMarker from "../../Maps/MapWithDraggableMarker";
import { PostingFormState } from "../CreatePostingForm";
import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import intersection from 'lodash/intersection'
import includes from 'lodash/includes'
import remove from 'lodash/remove'
import map from 'lodash/remove'
import {
  AddressCategory,
  CustomField, CustomFieldValue, DeliveryOptionDetail,
  LocationLookup,
  PostingDeliveryOption
} from "@shobbak/react-services/dist/Entities";
import { containerCSS } from "react-select/dist/declarations/src/components/containers";
import EventDispatcher from "../../../helper/EventDispatcher";
import camelCase from 'lodash/camelCase'


interface AdLocationProps {
  state: PostingFormState | any;
  dispatch: React.Dispatch<any>;
  moveToNextStep: () => void;
  googleMapsApiKey: string;
  containerStyle?: string;
  mode?: string;
}

const AdLocation: FC<AdLocationProps> = ({ containerStyle= 'mb-6',state, dispatch, moveToNextStep, googleMapsApiKey,mode= null  }) => {
  const [errorBag, setErrorBag] = useState({})
  const [deliveryOptions, setDeliveryOptions] = useState<PostingDeliveryOption[]>([])
  const [addressCategories, setAddressCategories] = useState<AddressCategory[]>([])
  const [deliveryOption, setDeliveryOption] = useState<PostingDeliveryOption|null>(null)


  useEffect(()=> {
    if(mode != 'edit') return
    if(deliveryOptions.length == 0) return

    let selected = []
    deliveryOptions.map((item) =>  {
      selected.push(item.id)
      item?.providers?.map((item) => {
        selected.push(item.id)
      })
    })

    dispatch({
      type: 'key_was_updated',
      payload: { key: 'delivery_options', value: intersection(selected ,state.delivery_options ) }
    })


  } , [deliveryOptions])

  useEffect(()=> {
    if(mode == null )return
    if(state.delivery_options_data == null ) return
    let selected = Object.keys(state.delivery_options_data)
    if(selected?.length == 0) return
    getDeliveryOptionCustomFields(Object.keys(state.delivery_options_data)[0])
  } , [mode])
  function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      BackendCall.i().lookupLocation({ lat: state.latlon?.lat, lon: state.latlon?.lon })
      .then((response) => {
        resolve(response)
      })
      .catch(e => {
      })
    })
  }
  function getDeliveryOptions() {
    return new Promise((resolve, reject) => {
      BackendCall.i().getDeliveryOptions({
        lat: state.latlon.lat,
        lon: state.latlon.lon,
        vertical_type: state.vertical_type,
        category_id: state.category_id
      })
      .then((response: PostingDeliveryOption[]) => {
        setDeliveryOptions(response)
        resolve(response)
      })
    })
  }

  function getDeliveryOptionCustomFields(id){
    BackendCall.i().getDeliveryOptionCustomFields(id).then((response)=>{
      dispatch({
        type: 'key_was_updated',
        payload: { key: 'deliveryOptionRef', value: response }
      })
    })
  }
  function emptyDeliveryOptions() {
    return(
      <span className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">
        {Translation.t('errors.messages.delivery_options_not_available')}
      </span>
    )
  }

  function addressInfo(){
    return (
      <>
        {has(errorBag, 'latlon') && (<span className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{get(errorBag, 'latlon')}</span>)}
        {state.address_line && (
          <div className="mt-4">
            <label htmlFor="address" className="mb-2 text-base leading-6 font-semibold text-coolGray-700">{Translation.t('create_new_ad.address')}</label>
            <div className="px-3 py-2 border rounded-md border-gray-200">
                   <span className="text-sm leading-0 font-normal text-coolGray-500">
                      {state.address_line}
                   </span>
            </div>
          </div>
        )}
        <div className="mt-4">
          <h2 className="text-base leading-6 font-semibold text-coolGray-700">{Translation.t('create_new_ad.select_address_type')}</h2>
          <div className="flex flex-wrap gap-3 mt-2">
            {addressCategories.map((v: AddressCategory) => {
              return <div
                onClick={
                  () => {
                    dispatch({
                      type: 'key_was_updated',
                      payload: { key: 'address_category', value: v.key }
                    })
                  }
                }
                className={`flex flex-wrap gap-2 rounded-xl md:py-1 md:px-2 px-1  items-center justify-center cursor-pointer ${state.address_category == v.key ? 'bg-emerald-500' : 'bg-coolGray-50'}`}
                key={v.id}>
                <img src={v.svgPath} className="md:w-6 md:h-6 w-4 h-4 text-white" alt=""/>
                <span
                  className={`text-xs leading-5 font-medium ${state.address_category == v.key ? 'text-white' : 'text-coolGray-700'}`}>{v.name}</span>
              </div>
            })}
          </div>

        </div>
      </>
    )
  }

  function renderInputs(customField: CustomField , deliveryOption: DeliveryOptionDetail) {
    switch (customField.inputType) {
      case 'string':
        return renderString(customField ,deliveryOption);
        break;
      case 'list':
        return renderList(customField,deliveryOption);
        break;
      case 'number':
        return renderNumber(customField,deliveryOption);
        break;
      case 'text':
        return renderText(customField,deliveryOption);
        break;
      case 'switch':
        return renderBoolean(customField,deliveryOption);
        break;
    }
  }

  function renderString(customField: CustomField ,deliveryOption: DeliveryOptionDetail) {
    return (<>
        <input
          type="text"
          value={get(state,`delivery_options_data.${deliveryOption.id}.${customField.key}`)}
          name={camelCase(customField.key)}
          onChange={(e) =>handleCustomFieldChanged(customField , deliveryOption ,e.target.checked)}
          className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
      </>
    )
  }

  function handleCustomFieldChanged(customField , deliveryOption ,value){
    let deliveryCustomFieldValues = get(state,`delivery_options_data.${deliveryOption.id}`) || {}
    let NewDeliveryOptionsData = {...deliveryCustomFieldValues,[customField.key]: value}
    let deliveryOptionData = { ...state?.delivery_options_data , [deliveryOption.id]: NewDeliveryOptionsData}
    dispatch({
      type: 'key_was_updated',
      payload: { key: 'delivery_options_data', value: deliveryOptionData }
    })
  }

  function renderBoolean(customField: CustomField ,deliveryOption: DeliveryOptionDetail) {
    return (
      <>
        <div className="w-full flex justify-between justify-center items-center">
          <div className="flex gap-1">
            {customField.placeholder && (<div dangerouslySetInnerHTML={{ __html: customField?.placeholder }} />)}
            {customField.isRequired && (<p className="text-red-600">*</p>)}
          </div>
          <input
            name={camelCase(customField.key)}
            checked={!!get(state,`delivery_options_data.${deliveryOption.id}.${customField.key}`)}
            type="checkbox"
            onChange={(e) => handleCustomFieldChanged(customField , deliveryOption ,e.target.checked)}
            className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
        </div>

      </>
    )
  }

  function renderList(customField: CustomField ,deliveryOption: DeliveryOptionDetail) {
    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="flex gap-1 w-full">
          {customField.name && (<div dangerouslySetInnerHTML={{ __html: customField?.name }} />)}
          {customField.isRequired && (<p className="text-red-600">*</p>)}
        </div>
        <div className="flex flex-wrap gap-4 mt-2">
          {sortBy(customField.customFieldValues, 'weight').reverse()?.map((value: CustomFieldValue) => {
            return (
              <div key={value.id}
                   className="bg-coolGray-50 rounded-full py-2 px-4 flex justify-center items-center truncate">
                <input
                  id={`${customField.key}-${value.id}`}
                  checked={get(state,`delivery_options_data.${deliveryOption.id}.${customField.key}`) == value.slug}
                  onChange={(e) =>handleCustomFieldChanged(customField , deliveryOption ,value.slug)}
                  name={camelCase(customField.key)}
                  type="radio"
                  className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
                <label htmlFor={`${customField.key}-${value.id}`}
                       className="text-base leading-6 text-normal text-coolGray-700 ms-2 truncate">{value.displayValue}</label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }


  function renderNumber(customField: CustomField ,deliveryOption: DeliveryOptionDetail) {
    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="flex gap-1 w-full">
          {customField.name && (<div dangerouslySetInnerHTML={{ __html: customField?.name }} />)}
          {customField.isRequired && (<p className="text-red-600">*</p>)}
        </div>

        <input
          type="number"
          value={get(state,`delivery_options_data.${deliveryOption.id}.${customField.key}`)}
           name={camelCase(customField.key)}
          onChange={(e) => handleCustomFieldChanged(customField , deliveryOption ,e.target.value)}
          className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
      </div>
    )
  }

  function renderText(customField: CustomField ,deliveryOption: DeliveryOptionDetail) {
    return (
      <>
				<textarea
          rows={3}
          value={get(state,`delivery_options_data.${deliveryOption.id}.${customField.key}`)}
          name={camelCase(customField.key)}
          onChange={(e) =>handleCustomFieldChanged(customField , deliveryOption ,e.target.checked)}
          className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
      </>
    )
  }

  function showCustomFields(option){
   return  option?.hasCustomFields && state?.deliveryOptionRef &&state?.deliveryOptionRef.id == option.id && state?.delivery_options.includes(option.id)
  }

  function renderCustomFields() {
    return (<div className={`border-t py-4`}>
      {sortBy(state?.deliveryOptionRef?.customFields, 'weight').reverse()?.map((customField) => {
        return (<div className="mb-6 flex flex-col gap-2" key={customField.id}>
          <div className="flex">
            {renderInputs(customField , state?.deliveryOptionRef)}
          </div>
        </div>)
      }) }
    </div>)
  }

  function renderDeliveryOption(option: PostingDeliveryOption) {
    return(
      <div className={'flex flex-col gap-2 md:p-4 p-2 border shadow-sm rounded-lg border-md w-full'}  key={option.id}>
        <div className="flex justify-between items-center gap-2">
          <div className=" flex justify-start items-center gap-2">
            <div className="p-2 bg-coolGray-50 flex justify-center items-center rounded">
              <img src={option.icon} alt={option.name} className="w-4 h-4"/>
            </div>
            <div className="flex flex-col -mt-0.5 space-y-1">
              <span className="text-sm text-coolGray-900 leading-0 font-medium">{option.name}</span>
              <p className="text-sm leading-5 font-normal text-coolGray-500">{option.description}</p>
            </div>
          </div>
          <ToggleButton value={includes(state.delivery_options, option.id)} onToggle={() => {
            const providers = option?.providers || []
            let ids = state.delivery_options;
            //remove ids
            if (includes(state.delivery_options, option.id)) {
              let provider_ids=  providers.map(item => item.id)
              remove(ids, n => n === option.id || provider_ids.includes(n) );
            } else {
              ids = [...ids, option.id]
              if (option?.hasCustomFields){
                getDeliveryOptionCustomFields(option.id)
              }
            }
            dispatch({
              type: 'key_was_updated',
              payload: { key: 'delivery_options', value: ids }
            })

          }}/>
        </div>
        {option?.providers && state?.deliveryOptionRef &&state?.deliveryOptionRef.id == option.id && state?.delivery_options.includes(option.id) && displayProviders(option?.providers)}
        { showCustomFields(option) && renderCustomFields()}
      </div>
    )
  }

  function displayDeliveryOptions(){
    return ( <div className="mt-4">
      <h2 className="text-base leading-7 text-semibold text-cooGray-800">{Translation.t('create_new_ad.delivery_option_available_to_buyer')}</h2>
      <p className="text-sm leading-0 font-normal text-coolGray-700 my-2">{Translation.t('create_new_ad.available_options')}</p>

      <div className="flex flex-col space-y-2">
        {deliveryOptions.length == 0 && emptyDeliveryOptions()}
        {deliveryOptions.map((option: PostingDeliveryOption) => renderDeliveryOption(option))}
      </div>
    </div>)
  }

  function displayProviders(providers) {
    return(
      <div className={'flex flex-col gap-2 md:p-4 p-2 divide-y w-full border-t w-full'}>
        <div className="flex w-full gap-2">
          {providers.map(option => {
            return (
             <div key={option.id} className={"flex justify-between items-center gap-2 w-full"}>
               <div className=" flex justify-start items-center gap-2" >
                 <div className="p-2 bg-coolGray-50 flex justify-center items-center rounded-full">
                   <img src={option.image} alt={option.name} className="w-6 rounded-full h-6"/>
                 </div>
                 <div className="flex flex-col -mt-0.5 space-y-1">
                   <span className="text-sm text-coolGray-900 leading-0 font-medium">{option.name}</span>
                   <p className="text-sm leading-5 font-normal text-coolGray-500">{option.description}</p>
                 </div>
               </div>
               <ToggleButton value={includes(state.delivery_options, option.id)} onToggle={() => {
                 let ids = state.delivery_options;
                 //remove ids
                 if (includes(state.delivery_options, option.id)) {
                   remove(ids, n => n === option.id);
                 } else {
                   ids = [...ids, option.id]
                 }
                 dispatch({
                   type: 'key_was_updated',
                   payload: { key: 'delivery_options', value: ids }
                 })

               }}/>
             </div>
            )
          })}
        </div>
      </div>

    )
  }

  useEffect(() => {
    BackendCall.i().lookupAddressCategories().then((res) => {
      setAddressCategories(res)
    })
  }, [])

  useEffect(() => {
    function handleValidation() {
      setErrorBag([])
      let bag = {}

      if (state.category.postingLocationRequired && isEmpty(state.latlon)) {
        bag['location'] = Translation.t('errors.messages.required_field', { field: 'location' })
      }

      if (Object.keys(bag).length == 0) {
        moveToNextStep()
        return () => {
          window.removeEventListener("validateCurrentStep", handleValidation)
        }
      }

      setErrorBag(() => bag)
    }

    window.addEventListener('validateCurrentStep', handleValidation)

    return () => {
      window.removeEventListener("validateCurrentStep", handleValidation)
    }
  }, [state])

  useEffect(() => {
    if (state.latlon == null) return
    setErrorBag((bag) => ( { ...bag, latlon: null } ))
    setDeliveryOptions([])
    getCurrentLocation()
    .then((location: LocationLookup) => {
      if (location?.data?.country?.id == null || state?.auth_user?.country?.id != location?.data?.country?.id) {
        setErrorBag((bag) => ( {
          ...bag,
          latlon: Translation.t('texts.select_valid_location', { countryName: state?.auth_user?.country.name })
        } ))
        EventDispatcher.dispatch('location-not-valid' , { valid: false })
      } else {
        EventDispatcher.dispatch('location-not-valid' , { valid: true })
        getDeliveryOptions()
      }
    })
  }, [state.latlon])

  return (
    <div className={containerStyle}>
      <h2
        className="md:text-2xl text-lg  md:leading-8 leading-6  md:text-coolGray-800 text-coolGray-900 text-semibold mb-4">{Translation.t('create_new_ad.location_and_delivery_method')}</h2>
      <div className="mt-4">
        <div>
          <label className="text-base leading-6 text-semibold text-coolGray-900"
                 htmlFor="title">{Translation.t('create_new_ad.location')}</label>
        </div>
        <div className="h-52">
          <MapWithDraggableMarker googleMapsApiKey={googleMapsApiKey} lat={state.latlon?.lat}
                                  lon={state.latlon?.lon} onDragEnd={({ lat, lon, address_line }) => {
            dispatch({
              type: 'key_was_updated',
              payload: [{ key: 'latlon', value: { lat, lon } }, { key: 'address_line', value: address_line }]
            })
          }}/>

        </div>
      </div>
      <div className="flex items-center space-s-2 bg-blue-50 w-full px-3 py-2 mt-1">
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 0.666992L11.478 1.88433C11.7827 1.95233 12 2.22233 12 2.53499V9.19299C12 10.5303 11.3313 11.7797 10.2187 12.521L6 15.3337L1.78133 12.521C0.668 11.779 0 10.5303 0 9.19366V2.53499C0 2.22233 0.217333 1.95233 0.522 1.88433L6 0.666992ZM8.968 5.48166L5.668 8.78099L3.78267 6.89566L2.84 7.83833L5.66867 10.667L9.91133 6.42432L8.968 5.48166Z"
            fill="#009EFF"/>
        </svg>
        <span>{Translation.t('create_new_ad.location_will_not_be_shared')}</span>
      </div>
      {!isEmpty(state.latlon) && (
        <>
          {addressInfo()}
          {displayDeliveryOptions()}
        </>
      )}


    </div>

  )
}


export const ToggleButton = ({ onToggle, value }) => {
  return ( <button type="button"
                   onClick={onToggle}
                   className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                   role="switch" aria-checked="false">
    <span className="sr-only">Use setting</span>
    <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md"/>
    <span aria-hidden="true"
          className={`${value ? 'bg-emerald-500' : 'bg-gray-200'} pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}/>
    <span aria-hidden="true"
          className={`${value ? 'translate-x-5' : 'translate-x-0'} pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}/>
  </button> );
}

export default AdLocation
