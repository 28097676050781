import React, { FC, useEffect } from "react"
import RecentSearch from "../../src/Types/RecentSearch"
import get from "lodash/get"
import 'remixicon/fonts/remixicon.css'
import redirectToUrl from './redirectToUrl'
import qs from "qs";

interface RecentSearchListProps {
  recentSearches: RecentSearch[];
  submitUrl: string;
  blurInputHandler: () => void;
  setRecentSearches: (result: RecentSearch[]) => void;
  verticalType?: string;
  category?: string;
  trackingMetaData?: {};
}

const RecentSearchList: FC<RecentSearchListProps> = ({ recentSearches, submitUrl, blurInputHandler, setRecentSearches, verticalType = null, category = null, trackingMetaData = {} }) => {
  useEffect(() => {
    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, [])

  function clickHandler(e) {
    if (document.getElementById('searches-list').contains(e.target) || get(e, 'target.dataset.searchInputField')) {
      // Clicked inside the component, don't blur the input field
    } else {
      blurInputHandler()
    }
  }

  function navigateToRecentSearch(recentSearch: RecentSearch) {
    let url = `${submitUrl}?`
    url += qs.stringify({filter: recentSearch.filters}, {encode: false})

    redirectToUrl({
      redirectUrl: encodeURI(url),
      searchTerm: recentSearch.search_term,
      verticalType,
      category,
      trackingMetaData
    })
  }

  function deleteRecentSearchItem(index) {
    fetch(`/recent_search/${index}/delete`, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        setRecentSearches(data.data)
      })
      .catch(error => { })
  }

  return recentSearches.map((recentSearch, index) => {
    return (
      <li
        key={recentSearch.search_term}
        className="px-3 py-1 rounded-lg relative cursor-pointer hover:bg-coolGray-50 hover:text-gray-900 text-coolGray-700">
        <div className="flex justify-between">
          <span className="flex flex-1" onClick={() => navigateToRecentSearch(recentSearch)}>
            <i className="ri-history-line pe-2" />
            <span>
              {recentSearch.search_term}
            </span>
          </span>
          <i className="ri-close-fill ps-3" onClick={() => deleteRecentSearchItem(index)}></i>
        </div>
      </li>
    )
  }
  )
}

export default RecentSearchList
