import React from 'react';


const AddCircleFill = (props) => {
	return (
		<svg className={props.className} width="20" height="21" viewBox="0 0 20 21" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd"
				d="M0 10.5361C0 16.0591 4.477 20.5361 10 20.5361C15.523 20.5361 20 16.0591 20 10.5361C20 5.01313 15.523 0.536133 10 0.536133C4.477 0.536133 0 5.01313 0 10.5361ZM5 9.53613H9V5.53613H11V9.53613H15V11.5361H11V15.5361H9V11.5361H5V9.53613Z"
			/>
		</svg>
	);
}


export default AddCircleFill;