import React, { useEffect } from 'react'

const WalletIcon = (props) => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={props.width ? props.width : 24} height={props.height ? props.height : 24}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M22 8h-9a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h9v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4zm-7 3h3v2h-3v-2z" />
    </svg>
  );
}


export default WalletIcon
