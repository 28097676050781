import React, { useState ,useRef} from "react";
import BaseLayout from "./BaseLayout";
import Header from "./Header";
import { initialStateProps } from "../Fraud";
import Warning from "../../src/Components/Warning";
import Translation from "../../src/helper/Translation";

interface DisplaySearchResultProps {
  state: initialStateProps;
  dispatch: React.Dispatch<any>;
  locale: string
}
function DisplaySearchResult ({ locale,state ,dispatch }: DisplaySearchResultProps) {
  return (
    <BaseLayout>
      <>
        <Header title={`+${state.number}`} description={Translation.t("frauds.messages.search_fraud")} />
          <Warning color={state.isBlocked ? 'red' :'yellow'} title={state.isBlocked ? Translation.t("texts.frauds"): Translation.t("frauds.labels.attention") } description={state.isBlocked? Translation.t("frauds.messages.number_is_fraud") : Translation.t("frauds.messages.attention") } />
        <Instruction />
        <Warning title={Translation.t("frauds.labels.warning")} description={ Translation.t("frauds.messages.warning")} />
        { !state.isBlocked && <Action dispatch={dispatch}/>}
      </>
    </BaseLayout>
  )
}

const Instruction = ()=> {
  const [isOpen , setIsOpen] = useState(true)
  return (
    <div className="rounded-lg bg-coolGray-50 px-6 py-4 flex flex-col gap-4 w-full cursor-pointer">
      <div className="flex justify-between cursor-pointer" onClick={()=> setIsOpen(prevState => !prevState)}>
        <p className="text-coolGray-700 text-sm font-semibold">{Translation.t('frauds.labels.to_be_safe')}</p>
        <i className={`${isOpen ? 'ri-arrow-drop-up-line' : 'ri-arrow-drop-down-line'} text-coolGray-700 ri-1x`}></i>
      </div>
      { isOpen && (
        <div className="grid grid-cols-1 divide-y divide-coolGray-100 gap-4">
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center h-12 w-12 bg-emerald-50 rounded-full">
              <i className={`ri-money-dollar-circle-fill text-emerald-500 ri-2x`}></i>
            </div>
            <div className="flex flex-col gap-1 text-coolGray-500">
              <p className="font-medium text-sm">{Translation.t('frauds.labels.sending_money')}</p>
              <p className="font-normal text-xs">{Translation.t('frauds.messages.sending_money')}</p>
            </div>
          </div>
          <div className="flex items-center gap-2 pt-1">
            <div className="flex items-center justify-center h-12 w-12 bg-emerald-50 rounded-full">
              <i className={`ri-chat-3-fill text-emerald-500 ri-2x`}></i>
            </div>
            <div className="flex flex-col gap-1 text-coolGray-500">
              <p className="font-medium text-sm">{Translation.t('frauds.labels.chat_and_contacts')}</p>
              <p className="font-normal text-xs">{Translation.t('frauds.messages.chat_and_contacts')}</p>
            </div>
          </div>
          <div className="flex items-center gap-2 pt-1">
            <div className="flex items-center justify-center h-12 w-12 bg-emerald-50 rounded-full">
              <i className={`ri-link-unlink-m text-emerald-500 ri-2x`}></i>
            </div>
            <div className="flex flex-col gap-1 text-coolGray-500">
              <p className="font-medium text-sm">{Translation.t('frauds.labels.outside_links')}</p>
              <p className="font-normal text-xs">{Translation.t('frauds.messages.outside_links')}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Action = ({dispatch})=> {
  const [instructionChecked , setInstructionChecked] = useState(false)
  const checkboxRef = useRef()

  const handleRedirect = (key: string , skipChecking: boolean)=> {
    if(!instructionChecked && ! skipChecking){
      // @ts-ignore
      return checkboxRef?.current?.focus()
    }

    dispatch( {  type: 'update_key', payload: { key: 'mode', value:  key}})
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex items-center gap-2">
        <input id="comments" name="comments"  ref={checkboxRef} onClick={(e)=> {
          // @ts-ignore
          setInstructionChecked(e?.target?.checked)
        } } type="checkbox" className="h-4 w-4 rounded border-gray-300 text-coolGray-500 focus:ring-coolGray-500" />
        <label htmlFor="comments"className="text-black font-normal text-sm">{Translation.t('frauds.actions.read_instructions')}</label>
      </div>
      <div className="flex md:flex-row flex-col gap-4 w-full">
        <button className="text-white text-base leading-6 font-normal bg-white text-red-600 border border-red-100 w-full flex items-center justify-center rounded-md py-2" onClick={()=> handleRedirect('search' , true)}>{Translation.t("buttons.cancel")}</button>
        <button className="text-white text-base leading-6 font-normal bg-emerald-500 w-full flex items-center justify-center rounded-md py-2" onClick={()=> handleRedirect('report' , false)}>{Translation.t('frauds.actions.report_this_number')}</button>
      </div>
    </div>
  )
}

export default DisplaySearchResult