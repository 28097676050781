import React, { useEffect, useState } from "react";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import Translation from '../helper/Translation'
import last from 'lodash/last'

interface PaginatorProps {
  current_page: bigint;
  total: bigint;
  total_pages: bigint;
  perPage: bigint;
  from: any;
  to: any;
  next: any;
  previous: any;
  onPaginationChange: (params: {}) => void;
}


const Pagination: React.FC<PaginatorProps> =
  ({
     current_page,
     total,
     total_pages,
     perPage,
     from,
     to,
     next,
     previous,
     onPaginationChange,
   }) => {


    const [beforeLinks, setBeforeLinks] = useState([]);
    const [afterLinks, setAfterLinks] = useState([]);
    const [shouldDisplayDotAfterFirst, setShouldDisplayDotAfterFirst] =
      useState(false);
    const [shouldDisplayDotbeforeLast, setShouldDisplayDotBeforeLast] =
      useState(false);

    useEffect(() => {
      const takeFromEachSide = 2;

      let before = [current_page, ...Array(current_page).keys()]
      .filter((item) => ![0, 1, current_page].includes(item))
      .reverse()
      .slice(0, takeFromEachSide + 1);

      before = before.sort((a, b) => {
        return a - b;
      });

      setBeforeLinks(before);

      setShouldDisplayDotAfterFirst(before[0] - 1 >= 2);

      let after = [...Array(current_page + takeFromEachSide + 1).keys()].filter(
        (item) => {
          return item >= current_page && item < total_pages && item != 1
        }
      );


      setAfterLinks(after);

      setShouldDisplayDotBeforeLast(total_pages - last(after) > 2);
    }, [current_page]);
    const updatePagination = (params) => {
      onPaginationChange(params);
    };

    const listItem = (number) => {
      return <span
        key={number}
        onClick={() => {
          if (current_page != number) {
            onPaginationChange({ page: number })
          }
        }
        }
        className={`${current_page == number ? 'cursor-not-allowed border-emerald-300 border-t-2 text-emerald-500' : 'cursor-pointer text-gray-700'} border-transparent  hover:text-emerald-700 hover:border-emerald-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium`}>
        {number}
     </span>


    }
    return (
      <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
        <div className="-mt-px w-0 flex-1 flex">
          <span
            onClick={() => {
              if (previous) {
                onPaginationChange({ page: previous })
              }
            }}
            className={`${previous ? 'cursor-pointer' : 'cursor-not-allowed'} border-t-2 border-transparent  pt-4 pr-1 inline-flex gap-3 items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
          >
             {Translation.locale() == 'ar' ? (
                 <ArrowNarrowRightIcon className=" h-5 w-5  text-gray-400" aria-hidden="true"/> )
               : (
                 <ArrowNarrowLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/> )
             }

            <span> {Translation.t('pagination.previous')}</span>
          </span>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {listItem(1)}

          {shouldDisplayDotAfterFirst && (
            <span
              className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">...</span>
          )}

          {beforeLinks && beforeLinks.map((item) => {
            return (
              listItem(item)
            );
          })}

          {afterLinks && afterLinks.map((item) => {
            return (
              listItem(item)
            );
          })}


          {shouldDisplayDotbeforeLast && (
            <span
              className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">...</span>
          )}


          {total_pages > 1 && (
            listItem(total_pages)
          )}

        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <span
            onClick={() => {
              if (next) {
                onPaginationChange({ page: next })
              }
            }}

            className={` ${next ? 'cursor-pointer' : 'cursor-not-allowed'}  border-t-2 border-transparent pt-4 pl-1 inline-flex items-center gap-3 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300`}
          >
            <span>{Translation.t('pagination.next')}</span>
            {Translation.locale() == 'ar' ?
              ( <ArrowNarrowLeftIcon className="h-5 w-5  text-gray-400" aria-hidden="true"/>
              )
              : (
                <ArrowNarrowRightIcon className="h-5 w-5  text-gray-400" aria-hidden="true"/>
              )}


          </span>
        </div>
      </nav>
    )

  }


export default Pagination;