export default function ({ redirectUrl, searchTerm = '', verticalType = null, category = null }) {
  if (searchTerm != '') {
    let rawPayload: {} = { search_term: searchTerm }

    if (verticalType != null) {
      rawPayload = { ...rawPayload, vertical_type: verticalType }
    } else if (category != null) {
      rawPayload = { ...rawPayload, category }
    }

    window.trackActivity({ event: 'search', rawPayload, shouldRedirect: true, redirectUrl, processPayload: false })
  } else {
    window.location.href = redirectUrl
  }
}