import React, { Suspense, useEffect, useState, useReducer, useRef } from 'react'
import { Business, BusinessMetaData, BusinessTypes, Country } from '@shobbak/react-services/dist/Entities'
import BackendCall from '../src/Components/BackendCall'
import Loader from '../src/Components/icon/Loader'
import { initialState, reducer, ValidationError } from './CreateBusinessProfile'
import AboutBusiness from '../src/Components/BusinessProfile/Steps/AboutBusiness'
import MediaInformation from '../src/Components/BusinessProfile/Steps/MediaInformation'
import BusinessControl from '../src/Components/BusinessProfile/Steps/BusinessControl'
import Translation from '../src/helper/Translation'
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { serialize } from 'object-to-formdata';
import DocumentedFiles from '../src/Components/BusinessProfile/Steps/DocumentedFiles'

export default function EditBusinessProfile({ token, locale, businessProfileId, googleMapsApiKey }) {
  const topContainer = useRef<HTMLDivElement>(undefined);
  const aboutBusinessRef = useRef<HTMLDivElement | null>(null);
  const mediaInformationRef = useRef<HTMLDivElement | null>(null);
  const businessControlRef = useRef<HTMLDivElement | null>(null);
  const documentedFilesRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [documentsCanBeUploaded, setDocumentsCanBeUploaded] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [businessProfile, setBusinessProfile] = useState<Business>()
  const [countries, setCountries] = useState<Country[]>([]);
  const [businessMeta, setBusinessMeta] = useState<BusinessMetaData>();
  const [businessTypes, setBusinessTypes] = useState<BusinessTypes>([]);

  useEffect(() => {
  }, [])

  useEffect(() => {
    getBusinessProfile()
    getCountries()
    getBusinessMetaData()
  }, [])

  useEffect(() => {
    if (businessMeta == null) return

    setBusinessTypes(businessMeta.businessTypes)
    setDocumentsCanBeUploaded(businessMeta.documents.length > 0)
  }, [businessMeta])

  useEffect(() => {
    if (businessProfile == null) return

    hydrateStateWithBusinessProfile()
  }, [businessProfile])


  useEffect(() => {
    if (documentsCanBeUploaded == false) return

    let documents = []
    businessMeta.documents.forEach(d => documents.push({ id: d.id, documents: [] }))

    dispatch({ type: 'key_was_updated', payload: { key: 'documents', value: documents } })
  }, [documentsCanBeUploaded])

  function getBusinessProfile() {
    BackendCall.i({ token, locale })
      .getBusinessProfile(businessProfileId)
      .then(response => {
        setBusinessProfile(response as Business)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  function getCountries() {
    BackendCall.i({ locale, token })
      .getCountries({ enabled: true })
      .then(response => setCountries(response))
      .catch(err => { })
  }

  function getBusinessMetaData() {
    BackendCall.i({ locale, token })
      .getBusinessMetaData()
      .then(response => setBusinessMeta(response))
      .catch(error => { })
  }

  function hydrateStateWithBusinessProfile() {
    const { name, about, businessType, latlonLon, latlonLat, location, website, businessProfileMobiles, businessProfileEmails, businessProfileSocialAccounts } = businessProfile;

    let newState = {
      name,
      about,
      business_type: businessType,
      latlon_lon: latlonLon,
      latlon_lat: latlonLat,
      location,
      website,
      mobiles: businessProfileMobiles.map(item => ({ country_id: item.countryId, number: item.number })),
      emails: businessProfileEmails.map(item => ({ email: item.email })),
      social_accounts: {}
    }

    let social_accounts = {}
    businessProfileSocialAccounts.forEach(account => {
      social_accounts[account.accountType] = account.accountUrl
    })

    newState = { ...newState, social_accounts }

    dispatch({
      type: 'hydrate_state',
      payload: newState
    })
  }

  function cancel() {
    window.location.href = '/my_stores'
  }

  async function update() {
    let aboutBusinessIsValid, mediaInformationIsValid, businessControlIsValid = false;
    try {
      aboutBusinessIsValid = await aboutBusinessRef?.current?.validateStep()
      mediaInformationIsValid = await mediaInformationRef?.current?.validateStep()
      businessControlIsValid = await businessControlRef?.current?.validateStep()

      if (aboutBusinessIsValid && mediaInformationIsValid && businessControlIsValid) {
        return submit()
      }
      topContainer.current?.scrollIntoView()
    } catch (error) {
      topContainer.current?.scrollIntoView()
    }
  }

  function submit() {
    setIsLoading(true)

    let data = { ...state }
    data = { ...omitBy(data, isNil) }
    const options = { indices: false, allowEmptyArrays: false };
    const formData = serialize({ ...data }, options);

    BackendCall.i({ locale, token })
      .patch(`business_profiles/${businessProfileId}`, formData)
      .then(res => {
        window.location.href = '/my_stores'
      })
      .catch(error => {
        setIsLoading(false)
        if (isEmpty(error.errors) || !Array.isArray(error.errors)) {
          setValidationErrors([{ title: 'Error', detail: 'Please try again later' }])
        } else {
          setValidationErrors(error.errors)
        }
        topContainer.current?.scrollIntoView()
      })
  }

  function renderValidationErrors() {
    if (validationErrors.length == 0) return

    return (
      <div className="col-span-6 text-red-300 text-sm p-4 mb-4 rounded bg-red-50">
        {validationErrors.map((error: ValidationError, index) => {
          return (
            <p key={`error-${index}`}><span className="text-red-500">{error.title} ({error.detail})</span></p>
          )
        })}
      </div>
    )
  }

  return (
    <Suspense fallback={Loader()}>
      {isLoading && <Loader />}
      {
        isLoading == false ?
          <div className="pt-16 py-8" ref={topContainer}>
            {renderValidationErrors()}
            <div className="bg-white p-8 rounded-md border border-coolGray-200">
              <div className="mb-4 pb-4 border-dashed border-b-2 border-coolGray-200">
                <AboutBusiness ref={aboutBusinessRef} businessTypes={businessTypes} googleMapsApiKey={googleMapsApiKey} state={state} dispatch={dispatch} />
              </div>
              <div className="mb-4 pb-8 border-dashed border-b-2 border-coolGray-200">
                <MediaInformation ref={mediaInformationRef} mode="update" countries={countries} state={state} dispatch={dispatch} />
              </div>
              <div className={`${businessProfile.canChangeDocuments ? 'mb-4 pb-4 border-dashed border-b-2 border-coolGray-200' : ''}`}>
                <BusinessControl ref={businessControlRef} state={state} dispatch={dispatch} />
              </div>
              {
                businessProfile.canChangeDocuments ?
                  <div className="mb-4 pb-4 border-dashed border-b-2 border-coolGray-200">
                    <DocumentedFiles ref={documentedFilesRef} mode="update" businessMeta={businessMeta} state={state} dispatch={dispatch} />
                  </div> : null
              }

              <div className="flex space-s-4 justify-end mt-4 py-3 ">
                <button
                  onClick={cancel}
                  disabled={isLoading}
                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} w-1/4 md:w-auto whitespace-nowrap bg-white border border-coolGray-700 rounded-md py-3 px-10 inline-flex items-center justify-center text-base font-normal leading-6 text-coolGray-700`}>
                  {Translation.t('buttons.cancel')}
                </button>

                <button
                  onClick={update}
                  disabled={isLoading}
                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} w-3/4 md:w-auto whitespace-nowrap bg-coolGray-700 border border-transparent rounded-md py-3 px-16 inline-flex items-center justify-center text-base font-normal leading-6 text-orange-200 hover:bg-coolGray-900 active:bg-coolGray-700`}>
                  {Translation.t('buttons.submit')}
                </button>
              </div>

            </div>
          </div>
          : null
      }
    </Suspense>
  )
}
