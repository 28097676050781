import React from 'react';

type Props = {
    locale?: string;
}

const Chevron = ({locale, ...props}: Props) => {
    if (locale == 'ar') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  stroke="currentColor"
                 className="w-5 h-5 self-center text-emerald-500">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
        );
    }
    return (
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 className="w-5 h-5 self-center text-emerald-500">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
              </svg>
         </span>
    );
}


export default Chevron;