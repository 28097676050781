import React, { useRef, useState } from 'react'
import AuthModalHeader from "./AuthModalHeader";
import Translation from "../../helper/Translation";
import AuthMobileInput from "./AuthMobileInput";
import AuthButton from "./AuthButton";
import EventDispatcher from "../../helper/EventDispatcher";
import AuthApi from "./AuthApi";
import get from "lodash/get"
import last from "lodash/last"
import ReCAPTCHA from "react-google-recaptcha";
import { UserMobile } from '@shobbak/react-services/dist/Entities'
import OtpForm from "./OtpForm";
import BackendCall, { HttpUnprocessableEntity } from "../BackendCall";
import { toast } from "react-toastify";

interface ForgetPasswordWithMobileProps {
  locale: string ;
  token: string ;
  country: string;
  recaptchaEnabled: boolean;
  enabledCountries: Country[];
  defaultCountry: Country
}

interface ErrorBagType {
  number: string;
}

const ForgetPasswordWithMobile: FC<ForgetPasswordWithMobileProps> = ({defaultCountry,enabledCountries, locale ,country, token, recaptchaEnabled = true,...props}) => {

  const [errorBag , setErrorBag] = useState<ErrorBagType>({ number: '' })
  const [isMobileValid,setIsMobileValid] = useState<boolean>(false)
  const [mobileData,setMobileData] = useState<UserMobile>(undefined)
  const [formDisabled , setFormDisabled] = useState<boolean>(false)
  const recaptchaRef = useRef(null)


  const onClose = ()=> {
    EventDispatcher.dispatch('close-auth-modal')
  }

  const requestResetPassword = async(mobile_number, country_id)=> {
    let params = {country_id: country_id, number: mobile_number}
    if (recaptchaEnabled) {
      await recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      // @ts-ignore
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    BackendCall.i({ token, locale, pathPrefix: '/api/v2/' })
    .usersRequestResetPasswordV2(params)
      .then(res=>  {
        setMobileData(res)
      } )
      .catch(error=> {
        const errors = error?.jsonAPIErrorMessages()
        if(errors) {
          setErrorBag(errors)
        }
      })
      .finally(() => {
        setFormDisabled(false)
      })
  }

  const renderMobileInputForm = () => {
    return <form onSubmit={(e)=> {
       e.preventDefault()
       if (formDisabled || !isMobileValid)return ;
       // @ts-ignore
       requestResetPassword(e.target.mobile_number.value, e.target.country_id.value)
      }}>
       <div className={`mt-4 flex flex-col gap-2`}>
         <AuthMobileInput isValid={setIsMobileValid}
                        countryCodes={enabledCountries}
                        defaultSelectedCountry={defaultCountry}
                        error={errorBag.number}
                        onChange={() => setErrorBag({number: ''})} />
       </div>
       <div className={`mt-4`}>
         <AuthButton label={Translation.t('buttons.confirm')} disabled={(formDisabled ||!isMobileValid)} />
       </div>
    </form>
  }

  const renderNewPasswordForm = () => {
    const api = new AuthApi(locale , country)
    api.usersResetPasswordForm({reset_password_token: mobileData.resetToken})
      .then(eval)
      .catch(error => {
        let message = Translation.t('texts.error');
        if (error instanceof HttpUnprocessableEntity) {
          message = error.messageStr();
        }
        toast.error(message)
      })
    return <div className={`mt-4`} id="reset-password-form"></div>
  }

  const renderOtpForm = () => {
    return <OtpForm
      api="verifyResetPasswordCodeV2"
      locale={locale}
      token={token}
      mobileData={mobileData}
      resendVerificationCode={() => requestResetPassword(mobileData.number, mobileData.countryId)}
      onSuccess={(payload) => {
        setMobileData(payload)
    }} />
  }

  return (
   <>
     <div className={`md:p-6 p-4`}>
      <div className={`flex flex-col gap-3`}>
        <AuthModalHeader onClose={onClose} title={Translation.t('texts.reset_password')} subTitle={ Translation.t('texts.foreget_password_sub_title') } headerIconName={`ri-lock-2-line`}  />
      </div>

      {recaptchaEnabled && (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      )}

       {!!mobileData ? (mobileData.resetToken ? renderNewPasswordForm() : renderOtpForm()) : renderMobileInputForm()}
    

     </div>

     
     {!mobileData && <div className="flex gap-1 justify-center items-center py-4 bg-coolGray-200  text-center">
       <p className="text-coolGray-700 text-sm leading-5 font-normal">
         {Translation.t('texts.do_not_have_account')}
       </p>
       <p className="text-emerald-500 cursor-pointer" onClick={()=> EventDispatcher.dispatch('open-auth-modal' , {mode: 'register'})}>
         {Translation.t('texts.create_new_account')}
       </p>
     </div>
    }
   </>
  )

}

export default ForgetPasswordWithMobile