// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'

var Rails = require('@rails/ujs')
Rails.start()
require("channels")
require("@ryangjchandler/alpine-clipboard")
require("alpine-magic-helpers")
require("alpinejs")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "../src/alpinejs"
import 'remixicon/fonts/remixicon.css'

import Swiper from 'swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination, Autoplay]);
window.Swiper = Swiper

import "../src/Tracking"
import "./lazyload"
import 'blurhash-img';
import BlurHash from './blurhash'


global.Rails = window.Rails = Rails;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    new BlurHash
    lazyload()
  }, 100)
})

import {updateQueryString} from "../src/helper";
window.updateQueryString = updateQueryString
