import React, {FC, useEffect, useState} from "react";
import cammelCase from "camelcase";
import Translation from '../../helper/Translation'
import {PostingFormState} from "./CreatePostingForm";
import BackendCall from "../BackendCall";


interface PricingProps {
    state: PostingFormState | any;
    errorBag: {};
    dispatch: React.Dispatch<any>;
}

const Pricing: FC<PricingProps> = ({state, dispatch, errorBag}) => {

    const [priceTypes, setPriceTypes] = useState([]);
    const [displayInputType, setDisplayInputType] = useState('');
    useEffect(() => {
       BackendCall.i().getPriceTypes().then((res) => {
            setPriceTypes(res)
        })
    }, [])

    const displayInput = () => {

        return (
            <div>
                <div className="flex rounded-md shadow-sm">
        		<span
                    className="inline-flex items-center px-2 rounded-s-md border border-e-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        			{state.auth_user.country.currency}
        		</span>
                    <input
                        type="number"
                        min="0"
                        value={state.total_price}
                        onChange={(e) => dispatch({
                            type: 'key_was_updated',
                            payload: {key: 'total_price', value: `${e.target.value}`.replace(/^0+/, '')}
                        })}
                        className="focus:ring-emerald-500 focus:border-emerald-500 flex-1 block w-full rounded-none rounded-e-md sm:text-sm border-gray-300"
                        placeholder="10.0"/>
                </div>
                <div>
                    {errorBag.total_price && <span
                        className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{errorBag.total_price}</span>}
                </div>
            </div>
        );

    }

    return (<div className="flex flex-col gap-2 mt-4">
            <div>
                <h1 className="text-2xl leading-8 font-semibold text-coolGray-700">{Translation.t('create_new_ad.how_would_your_like_to_receive_offer')}</h1>
                <p className="text-sm leading-0 font-normal text-coolGray-500 mt-1">{Translation.t('create_new_ad.select_the_price_type_suits_your_items')}</p>
            </div>


            {priceTypes && priceTypes.map((priceType) => {
                return (
                    <div key={priceType.id} className="p-2 border border-gary-100 rounded-md flex flex-col gap-2">
                        <div className="flex md:items-center items-stretch justify-between">
                            <div className="flex items-start gap-2 ">
                                <div className="p-2 rounded-full  bg-coolGray-50 flex items-center">
                                    <img src={priceType.icon} alt={priceType.name} className="w-4 h-4"/>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <h1 className="text-base text-coolGray-700 leading-6 font-semibold">{priceType.name}</h1>
                                    <p className="text-sm md:leading-3 leading-5  font-normal text-coolGray-500">{priceType.description}</p>
                                </div>
                            </div>
                            <input type="checkbox"
                                   checked={priceType.key == state.price_type}
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           dispatch({
                                               type: 'key_was_updated',
                                               payload: {key: 'price_type', value: priceType.key}
                                           })
                                       } else {
                                           dispatch({
                                               type: 'key_was_updated',
                                               payload: {key: 'price_type', value: ''}
                                           })
                                       }
                                   }}
                                   className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300 rounded-full"/>
                        </div>
                        {priceType.needAmount && priceType.key == state.price_type && displayInput()}

                    </div>
                )
            })}


        </div>
    )

}

export default Pricing;