import React, { useEffect, useState } from "react";
import BackendCall from "../BackendCall";
import Translation from "../../helper/Translation";
import BaseModal from "../Posting/Modal/BaseModal";
import DefaultInput from "./DefaultInput";
import DefaultButton from "./DefaultButton";
import isEmpty from "lodash/isEmpty";


const RequestProfileVerifyModal = ({ methodPayload, onSuccess, updateMethods, token, locale, onClose, canClose = true }) => {
  const [form, setForm] = useState({})
  const [error, setError] = useState({})
  const [showTime, setShowTimer] = useState<boolean>(false)
  const [countDown, setCountDown] = useState<number>(0)
  const setFormMethod = (obj = {}) => {
    let newForm = form
    newForm = { ...newForm, ...obj }
    setForm(newForm)
  }

  const timeCountDownInSec = () => {
    if (methodPayload.metadata.canRequest && methodPayload.metadata.hasOwnProperty('canSendAgain') && !methodPayload.metadata.canSendAgain) {
      onSuccess(methodPayload)
    } else {
      setShowTimer(false)
    }
  }

  useEffect(() => {
    timeCountDownInSec()
  }, [])

  useEffect(() => {
    let defaultFormValue = {}
    methodPayload?.fields.map((field) => {
      defaultFormValue[field.key] = ''
    })
    setForm(defaultFormValue)
  }, [])

  const submitRequest = (key, values) => {
    BackendCall.i({ token, locale })
      .requestProfileVerifyCode(key, values)
      .then((res) => {
        updateMethods(res);
        onSuccess(res)
      })
      .catch((error) => {
      });
  };

  const validateAndSubmit = () => {
    let error = {}
    methodPayload?.fields.map((field) => {
      let formKeyValue = form[field.key] ? form[field.key] : ''
      Object.keys(field.validations).forEach((validation) => {
        let valid = true
        if (validation == 'regex') {
          let expression = eval(`/^${field.validations[validation]}/`)
          valid = expression.test(formKeyValue)
        }

        if (!form[field.key]) {
          error[field.key] = Translation.t('errors.messages.required_field', { field: field.name })
        } else if (!valid) {
          error[field.key] = Translation.t('errors.messages.invalid_field', { field: field.name })
        }
      })
    })

    if (isEmpty(error)) {
      setError({})
      submitRequest(methodPayload.key, form)

    } else {
      setError(error)
    }
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return setShowTimer(false);
    }

    return (
      <div className="timer flex flex-col items-center justify-center">
        <div className="text-xs	 leading-2  text-emerald-500">{Translation.t("buttons.resend_verification_code")}</div>
        <div className="text-sm leading-2  text-emerald-500">{remainingTime}</div>
        <div className="text-sm leading-2  text-emerald-500">seconds</div>
      </div>
    );
  };
  return (
    <BaseModal onClose={onClose} >
      <div className="md:p-6 px-2 py-4 flex flex-col gap-4">
        <div className="flex items-start">
          <div className="me-4 flex items-center justify-center h-12 w-12 rounded-full">
            <i className="ri-file-text-fill text-emerald-500" />
          </div>
          <div>
            <div className="text-start flex-1 flex-col mb-2">
              <p className="text-base leading-6 font-semibold text-coolGray-900">{methodPayload.heading}</p>
              <p className="text-sm leading-5 font-normal text-coolGray-500">{methodPayload.description}</p>
            </div>
            <div className="text-start flex-1 flex-col">
              <p className="text-base leading-6 font-semibold text-coolGray-900">{methodPayload.whyTitle}</p>
              <p className="text-sm leading-5 font-normal text-coolGray-500">{methodPayload.whyDescription}</p>
            </div>
          </div>
          {canClose && (
            <div className="self-start cursor-pointer" onClick={onClose}>
              <i className="ri-close-circle-fill text-coolGray-400" />
            </div>
          )}
        </div>

        {!showTime && (
          <>
            <div className={`flex flex-col gap-2`}>
              {methodPayload?.fields.map((field) => {
                if (['number', 'string'].includes(field.type)) {
                  return (<div key={field.key}>
                    <DefaultInput containerClass="text-start" inputClasses="h-10" type="string" name={field.key} label={field.name} key={field.key} placeholder={field.placeholder} value={form[field.key]} onValueChange={(value) => setFormMethod({ [field.key]: value })} />
                    {error[field.key] && (
                      <div className="w-full flex">
                        <span className={`text-red-500 text-sm mt-1 text-start`}>{error[field.key]}</span>
                      </div>
                    )}
                  </div>)
                }
              })}
            </div>

            <div className={`flex flex-col gap-2`}>
              <DefaultButton label={Translation.t('buttons.submit')} disabled={false} onButtonClick={validateAndSubmit} />
            </div>
          </>
        )}

      </div>
    </BaseModal>
  );
}

export default RequestProfileVerifyModal
