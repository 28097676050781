import { AuctionBidding } from '@shobbak/react-services/dist/Entities';
import React from 'react';

type Props = {
  bidDraft?: AuctionBidding;
  stepIndex: number;
};

const Message = ({bidDraft, stepIndex}: Props) => {

  const getMessageObject = () => {
    if(bidDraft?.requiresLogin) {
      return bidDraft?.loginInfo;
    } else if(bidDraft?.steps[stepIndex].needRecharge){ 
      return bidDraft?.notEnoughBalance;
    } else {
      return bidDraft?.enoughBalance;
    }
  }

  const getMessageContainerAdditionalStyle = () => {
    return {
      ...{}, // add other styles here
      backgroundColor: getMessageObject()?.backgroundColor ?? ''
    };
  };

  const getMessageTitleAdditionalStyle = () => {
    return {
      ...{}, // add other styles here
      color: getMessageObject()?.foregroundColor ?? ''
    };
  };

  const getTitle = (): string => {
    return getMessageObject()?.title ?? '';
  };

  const getSubTitle = (): string => {
    return getMessageObject()?.description ?? '';
  };

  return (
		<div className="mt-4 text-center text-xs">
      <div 
        className="p-1 rounded-md" 
        style={getMessageContainerAdditionalStyle()}
      >
        { !!getTitle() && 
          <p
            className="font-medium"
            style={getMessageTitleAdditionalStyle()}
          >
            {getTitle()}
          </p>
        }
        <p className="text-coolGray-700">
          {getSubTitle()}
        </p>
      </div>
    </div>
  );
};

export default Message;
