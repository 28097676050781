import React, { useEffect, useState } from 'react'
import EventDispatcher from "../src/helper/EventDispatcher";
import BaseModal from "../src/Components/Posting/Modal/BaseModal";
import Pricing from "../src/Components/Posting/Pricing";
import BackendCall from "../src/Components/BackendCall";
import Loader from "../src/Components/icon/Loader";
import Translation from "../src/helper/Translation";
import DefaultButton from "../src/Components/EditProfile/DefaultButton";
import { toast } from "react-toastify";
import { showError } from "../src/helper/errors";


interface PostingInterface  {
  total_price: String ,
  price_type: String,
  auth_user: any
}

const EditPostingPrice = ({ token , locale , postingId  })=> {
  const[isEditPriceEnable , setEditPriceEnable] = useState(false)
  const [isLoading , setIsLoading] = useState(false)
  const [posting , setPosting] = useState<PostingInterface>({ total_price: '' , price_type:'' , auth_user:{} })
  const [formSubmitted , setFormSubmitted] = useState(false)


  const updatePosting = ()=> {
    setFormSubmitted(true)
     BackendCall.i({token , locale})
     .postingUpdate(postingId , {  price_type: posting?.price_type , total_price: posting?.total_price  })
       .then(res=> {
         toast.success(Translation.t('texts.ads_updated'));
         setTimeout(()=> window.location.reload() , 1000)
       }).catch((error)=> {
       setFormSubmitted(false)
       showError(error)
     })
  }


  useEffect(()=> {
    EventDispatcher.on('show-edit-price' , (event)=> {
      setEditPriceEnable(true)
      setIsLoading(true)
      BackendCall.i({token , locale})
      .getPosting(postingId)
        .then(({ totalPriceDetail , priceType , currency })=>  {
          setPosting({ price_type: priceType.key , auth_user: { country: { currency }} , total_price : totalPriceDetail.value   })
          setIsLoading(false)

        })
    })
  } , [])


  const header = ()=> {
    return (
      <div className={`bg-coolGray-100`}>
         <div className={`flex items-center justify-between  p-4`}>
           <span className={`text-base leading-6 font-semibold text-coolGray-900`}>{Translation.t('screens.edit_price.title')}</span>
           <i className={`ri-close-line text-xl text-coolGray-400 cursor-pointer`}  onClick={()=> setEditPriceEnable(false) }/>
         </div>
      </div>
    );
  }

  const footer = ()=> {
    return (
      <div className={`bg-coolGray-100`}>
        <div className={`flex w-full p-4`}>
          <DefaultButton label={Translation.t('buttons.save')} containerClass={`w-full`} disabled={formSubmitted}  onButtonClick={()=> { updatePosting() }} />
        </div>
      </div>
    );
  }

  if (!isEditPriceEnable) return <></>

  return (
    <BaseModal onClose={()=>{ setEditPriceEnable(false) }}>
      {isLoading ? <Loader /> :  (
         <div className={`flex flex-col`}>
            {header()}
            <div className={`px-4 pb-4`}>
              <Pricing state={posting} dispatch={({payload})=> {
                setPosting((prev)=> {
                  return {...prev , [payload['key']]: payload['value']}
                })
              }} errorBag={ {} } />
            </div>
           {footer()}
         </div>
      )  }

    </BaseModal>
  )
}

export default EditPostingPrice