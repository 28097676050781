import BackendCall from "../Components/BackendCall"
import EventDispatcher from "./EventDispatcher"

export class ApplePay {
  static SUCCESS = 0
  static FAILURE = 1

  static isAvailable() {
    try {
      return window.ApplePaySession && ApplePaySession.canMakePayments()
    } catch (error) {
        return false
    }
  }

  static requestPayment(request, callback) {
    request = {
      ...request,
      merchantCapabilities: [
        "supports3DS"
      ],
      total: request.paymentSummaryItems[0]
    }

    const applePaySession = new ApplePaySession(6, request)

    applePaySession.begin()

    applePaySession.onvalidatemerchant = async event => {
      const merchantSession = await BackendCall.i().validateApplePayMerchant({url: event.validationURL});
      applePaySession.completeMerchantValidation(merchantSession);
    };

    applePaySession.onpaymentauthorized = event => {
      EventDispatcher.on('apple-pay-complete-payment', ({ detail : { type } }) => {
        if (type === ApplePay.SUCCESS) {
          return applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)
        }

        applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
      })

      const applePaymentToken = event.payment.token
      callback(applePaymentToken)
    };
  }

  static complete(type) {
    EventDispatcher.dispatch('apple-pay-complete-payment', { type })
  }
}