import React from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function Toast(props) {
  return <ToastContainer rtl/>;
}

export default Toast;
