import React, { FC, useEffect, useState } from "react";
import BackendCall from '../../BackendCall'
import Translation from "../../../helper/Translation";
import { PostingFormState } from "../CreatePostingForm";
import camelCase from 'camelcase';
import Loader from "../../icon/Loader";
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import { CustomField, CustomFieldGroup, CustomFieldValue } from "@shobbak/react-services/dist/Entities";

interface BasicInfoStepProps {
	state: PostingFormState | any;
	dispatch: React.Dispatch<any>;
	moveToNextStep: () => void;
	setCustomFieldsCount: (number) => void;
}

const BasicInfoStep: FC<BasicInfoStepProps> = ({
	moveToNextStep,
	state,
	dispatch,
	setCustomFieldsCount
}) => {
	const [isLoading, setIsLoading] = useState(true)
	const [customFieldGroups, setCustomFieldGroups] = useState<CustomFieldGroup[]>([])
	const [customFields, setCustomFields] = useState<CustomField[]>([])
	const [requiredFields, setRequiredFields] = useState<string[]>([])
	const [errorBag, setErrorBag] = useState<{}>({})

	useEffect(() => {
		BackendCall.i().getCustomFields({
			vertical_type: state.vertical_type,
			category_id: state.category_id,
			listing_type_id: state.listing_type_id
		})
			.then((result: CustomField[]) => {
				setCustomFieldsCount(result.length)
				if (result.length == 0) {
					moveToNextStep()
					return () => {
						//
					}
				}

				result = result.filter(f => f.customFieldType != 'select' || f.formInputLocation != 'list')
				setCustomFields(result)

				Promise.all(
					result.map(async (f: CustomField) => {

						if (f.customFieldType == 'select' || f.inputType == 'list') {
							let newObj = { ...f, values: [] }

							const res = await BackendCall.i().getCustomFieldValues({
								custom_field_id: f.id,
								vertical_type: state.vertical_type,
								category_id: state.category_id,
								listing_type_id: state.listing_type_id
							})

							newObj = { ...newObj, values: res as CustomFieldValue[] }
							return Promise.resolve(newObj)
						} else {
							return Promise.resolve(f)
						}
					})
				)
					.then(fieldsWithValues => {
						let groups = groupBy(fieldsWithValues, (f: CustomField) => f.customFieldGroup?.id || -1)
						let finalResult = []
						Object.keys(groups).forEach(groupId => {
							let types = map(groups[groupId], 'customFieldType')
							finalResult.push({
								id: groupId,
								title: groups[groupId][0].customFieldGroup?.title,
								values: groups[groupId],
								allAreBooleans: uniq(types).length == 1 && types[0] == 'boolean'
							})
						})
						setCustomFieldGroups(finalResult)
						setTimeout(() => {
							setIsLoading(false)
						}, 0);
					})
			})
	}, [])

	useEffect(() => {
		if (customFields.length == 0) return

		setRequiredFields(() => customFields.filter(f => f.isRequired).map(f => f.key))
	}, [customFields])

	useEffect(() => {
		function handleValidation() {
			setErrorBag([])
			let bag = {};

			requiredFields.forEach(key => {
				let value = state[key];
				if (value === "" || value === null || value === undefined) {
					bag[key] = Translation.t('errors.messages.required_field', { field: key })
				}
			})

			if (Object.keys(bag).length == 0) {
				moveToNextStep()
				return () => {
					window.removeEventListener("validateCurrentStep", handleValidation)
				}
			}

			setErrorBag(() => bag)
		}

		window.addEventListener('validateCurrentStep', handleValidation)

		return () => {
			window.removeEventListener("validateCurrentStep", handleValidation)
		}
	}, [state, requiredFields])

	function displayErrorMessage(customFieldKey: string) {
		if (errorBag[customFieldKey] == null) return (<></>);
		return (
			<span
				className="text-red-500 text-xs flex flex-start leading-4 font-normal mt-1">{errorBag[customFieldKey]}</span>
		)
	}

	function renderInputs(customField: CustomField) {
		switch (customField.inputType) {
			case 'string':
				return renderString(customField);
				break;
			case 'list':
				return renderList(customField);
				break;
			case 'number':
				return renderNumber(customField);
				break;
			case 'text':
				return renderText(customField);
				break;
			case 'switch':
				return renderBoolean(customField);
				break;
		}
	}

	function renderString(customField: CustomField) {
		return (<>
			<input
				type="text"
				value={state[customField.key]}
				name={camelCase(customField.key)}
				onChange={(e) => dispatch({
					type: 'key_was_updated',
					payload: { key: customField.key, value: e.target.value }
				})}
				className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
			{displayErrorMessage(camelCase(customField.key))}
		</>
		)
	}

	function renderBoolean(customField: CustomField) {
		return (
			<>
				<div className="bg-coolGray-50 rounded-full  py-2 flex justify-center items-center truncate">
					<input
						name={camelCase(customField.key)}
						checked={!!state[customField.key]}
						type="checkbox"
						onChange={(e) => dispatch({
							type: 'key_was_updated',
							payload: { key: customField.key, value: e.target.checked }
						})}
						className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
					<label htmlFor=""
						className="text-base leading-6 text-normal text-coolGray-700 ms-2 truncate">{customField.name}</label>
				</div>
				{displayErrorMessage(camelCase(customField.key))}
			</>
		)
	}

	function renderBooleanGroup(fields: []) {
		return (
			<div className="flex flex-wrap mt-2 gap-4">
				{fields.map((f: CustomField) => {
					return (
						<div key={f.id}
							className="bg-coolGray-50 rounded-full flex-shrink  py-2 px-4 flex justify-center items-center truncate">
							<input
								id={`${f.key}-${f.id}`}
								name={camelCase(f.key)}
								checked={!!state[f.key]}
								type="checkbox"
								onChange={(e) => dispatch({
									type: 'key_was_updated',
									payload: { key: f.key, value: e.target.checked }
								})}
								className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
							<label htmlFor={`${f.key}-${f.id}`}
								className="text-base leading-6 text-normal text-coolGray-700 ms-2 truncate">{f.name}</label>
						</div>
					)
				})}
			</div>
		)
	}

	function renderNumber(customField: CustomField) {
		return (
			<>
				<input
					type="number"
					value={state[customField.key]}
					name={camelCase(customField.key)}
					onChange={(e) => dispatch({
						type: 'key_was_updated',
						payload: { key: customField.key, value: e.target.value }
					})}
					className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
				{displayErrorMessage(camelCase(customField.key))}
			</>
		)
	}

	function renderList(customField: CustomField) {
		return (
			<>
				<div className="flex flex-wrap gap-4 mt-2">
					{customField.values?.map((value: CustomFieldValue) => {
						return (
							<div key={value.id}
								className="bg-coolGray-50 rounded-full py-2 px-4 flex justify-center items-center truncate">
								<input
									id={`${customField.key}-${value.id}`}
									checked={!!(state[customField.key] && state[customField.key] == value.id)}
									onChange={(e) => dispatch({
										type: 'key_was_updated',
										payload: { key: customField.key, value: value.id }
									})}
									name={camelCase(customField.key)}
									type="radio"
									className="focus:ring-emerald-500  h-4 w-4 text-emerald-600 border-gray-300" />
								<label htmlFor={`${customField.key}-${value.id}`}
									className="text-base leading-6 text-normal text-coolGray-700 ms-2 truncate">{value.displayValue}</label>
							</div>
						)
					})}
				</div>
				{displayErrorMessage(camelCase(customField.key))}
			</>
		)
	}

	function renderText(customField: CustomField) {
		return (
			<>
				<textarea
					rows={3}
					value={state[customField.key]}
					name={camelCase(customField.key)}
					onChange={(e) => dispatch({
						type: 'key_was_updated',
						payload: { key: customField.key, value: e.target.value }
					})}
					className="w-full mt-2 form-input border border-coolGray-300 block py-2 px-3 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
				{displayErrorMessage(camelCase(customField.key))}
			</>
		)
	}

	if (isLoading) {
		return <Loader />
	}

	return (
		<div>
			{
				customFieldGroups.map((group: CustomFieldGroup) => {
					return (
						<div key={group.id}>
							<h2 className="md:text-2xl text-lg  md:leading-8 leading-6  md:text-coolGray-800 text-coolGray-900 text-semibold mb-4">{group.title}</h2>
							<div className="mb-6" key={group.id}>
								{group.allAreBooleans && renderBooleanGroup(group.values)}
								{!group.allAreBooleans && group.values.map((f: CustomField) => {
									return (
										<div className="mb-6" key={f.id}>
											<label className="text-base leading-7 text-semibold text-coolGray-700"
												htmlFor="user_email">{f.name}</label>
											{renderInputs(f)}
										</div>
									)
								})}
							</div>
						</div>

					)
				})
			}
		</div>
	)
}

export default BasicInfoStep