import React, { useEffect, lazy, useRef, useState, Suspense } from "react";
import BackendCall from "../src/Components/BackendCall";
import { Posting } from '@shobbak/react-services/dist/Entities'
import { Entities } from '@shobbak/react-services'
const { formattedFieldValue, iconFieldValue, placeFields } = Entities;;
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import get from 'lodash/get'
import last from 'lodash/last'
import StringHelper from "../src/helper/String";
import EventDispatcher from "../src/helper/EventDispatcher";
import Pagination from '../src/Components/Pagination'
const ProfileLayout = lazy(() => import("../src/Components/ProfileLayout"))
import Loader from "../src/Components/icon/Loader";
const ProfileCard = lazy(() => import("../src/Components/profile/ProfileCard"))

import Translation from '../src/helper/Translation'
import DateTime from "../src/helper/DateTime";
import StatusGroups from "../src/Components/StatusGroups";
import 'remixicon/fonts/remixicon.css'

const PostingIndex = ({ locale, token, origin = null }) => {
  BackendCall.i({ token, locale })

  const [statusGroup, setStatusGroup] = useState<string[]>([]);
  const [isAscSorting, setIsAscSorting] = useState(false);
  const [posts, setPosts] = useState<Posting[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const postRefs = useRef(posts)

  const setPostsWithRefs = (posts) => {
    setPosts(posts)
    postRefs.current = posts
  }
  const [currentPage, setCurrentPage] = useState(1)


  useEffect(() => {
    EventDispatcher.on('confirmed', async ({ detail }) => {
      const { action, postId } = detail;
      const response = await handleConfirmedAction(action, postId)
      const newPosts = postRefs.current.map((post) => {
        if (post.id == response.id) {
          return response
        }
        return post
      })
      setPostsWithRefs(newPosts)

      EventDispatcher.dispatch('close-warning-modal')
      EventDispatcher.dispatch('show-success-message')
    })
  }, [])

  const handleConfirmedAction = async (action, postId) => {
    let response = {};
    switch (action) {
      case 'void':
        response = await BackendCall.i().postingVoid(postId)
        break;
      case 'close':
        response = await BackendCall.i().postingClose(postId)
        break;
      case 'publish':
        response = await BackendCall.i().postingPublish(postId)
        break;
      default:
      // code block
    }

    return response;
  }


  const getPosts = ({ page }) => {
    setIsLoading(true)
    BackendCall.i().getPostingIndex({
      page: { number: page, size: 15 },
      filter: { status_group: statusGroup },
      sort: { created_at: isAscSorting ? 'asc' : 'desc' },
    }).then(({ records, meta }) => {
      setPostsWithRefs(records)
      setMeta(meta)
      setIsLoading(false)
    }).catch(res => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getPosts({ page: 1 })
  }, [currentPage, statusGroup, isAscSorting])

  const onSelectStatusGroup = (data) => {
    setStatusGroup(Object.keys(data));
  };

  return (
    <Suspense fallback={Loader()}>
      <ProfileLayout>
        <ProfileCard title={Translation.t('breadcrumb.my_ads')}>
          <div className="flex flex-col p-4 rounded-lg bg-emerald-50 mb-2">
            <p className="text-emerald-500">{Translation.t('texts.super_ads_upgrade_hint')}&nbsp;🚀</p>
            <p className="text-emerald-500 text-sm">{Translation.t('texts.super_ads_are_for_published_ads_only')}</p>
          </div>
          <div className="flex flex-col divide-y">

            <div className="flex items-center justify-between space-s-4">
              <StatusGroups
                onSelect={onSelectStatusGroup}
                type="posting"
              />
              <button
                onClick={() => setIsAscSorting(!isAscSorting)}
                className="flex items-center justify-center"
              >
                <i className={`${isAscSorting ? 'ri-sort-asc' : 'ri-sort-desc'} ri-lg`} />
              </button>
            </div>


            {isLoading && <Loader />}

            {!isLoading && (
              <>
                {posts.map((post) => {
                  return (<PostShow post={post} key={post.id} origin={origin} />)
                })}

                {posts.length == 0 && (
                  <div
                    className="p-2 flex items-center justify-center w-full">{Translation.t('posting.tabs.not_results')}</div>
                )}

                {!isEmpty(meta) && posts.length != 0 && (
                  <Pagination {...meta} onPaginationChange={({ page }) => {
                    getPosts({ page })
                  }} />
                )}
              </>
            )}

          </div>
        </ProfileCard>

      </ProfileLayout>
    </Suspense>
  )
}


const PostShow = ({ post, origin = null }) => {
  const [tags, setTags] = useState([])
  useEffect(() => {
    let placeTags = placeFields(post, 'listing-tags')
    let listingTags = []
    if (placeTags.length == 0) return

    placeTags.forEach(field => {
      let value = post.dataFieldValues[field]?.value;

      if (!value) return;

      listingTags.push({
        field,
        value: formattedFieldValue(post, field),
        icon: iconFieldValue(post, field)
      })

    })
    setTags(listingTags)
  }, [])


  const handleSelectedAction = (action, postId) => {
    const displayTranslationAction = {
      title: Translation.t(`posting.allowed_actions.${action}.title`),
      display_action: Translation.t(`posting.allowed_actions.${action}.display_action`),
      paragraph: Translation.t(`posting.allowed_actions.${action}.paragraph`),
      action: action,
      postId: postId
    }
    EventDispatcher.dispatch('open-warning-modal', displayTranslationAction)
  }
  return (
    <div className="flex items-start justify-between py-4" key={post.id}>
      <div className="flex items-start gap-4 w-full cursor-pointer" onClick={() => {
        if (origin == "promotions") {
          window.trackActivity({ event: 'home_super_view_post', rawPayload: { ...post }, redirectUrl: `/p/${post.id}?origin=promotions` })
        } else {
          window.location.href = `/p/${post.id}`
        }
      }}>
        {first(post.assetUrls) && (
          <div className="relative md:w-32 md:h-32 w-24 h-28">
            <img src={get(first(post.assetUrls), 'thumbnail')} alt={post.title}
              className="object-cover rounded-s-lg h-full w-full" />

            {get(post, 'priceType.key') == 'negotiable' && (
              <span
                className="absolute top-2 start-2 px-2 py-1 flex items-center justify-center text-xs leading-4 font-medium text-emerald-600 bg-emerald-100 rounded-xl">Negotiable</span>
            )}
          </div>
        )}
        <div className="flex-1 flex flex-col gap-0.5 w-full">
          <div className="flex flex-col md:flex-row md:gap-2 gap-1 flex-wrap md:items-center items-start">
            <div className={`flex gap-4`}>
              {<Status statusDetail={post.statusDetail} />}
              {post.isPromoted && (
                <div className="bg-blue-50 flex justify-center items-center gap-2 p-4 py-2">
                  <i className="ri-funds-fill ri-lg text-blue-500"> </i>
                  <span className="text-blue-500 font-normal leading-5 text-sm truncate w-full">{Translation.t('promotion_posting.super')}</span>
                </div>
              )}
            </div>
            <div className="flex gap-2">
              <Date date={new DateTime(post.createdAt).short()} />
              <Address address={post.addressLine} />
            </div>
          </div>
          <span
            className="md:text-xl md:leading-7 md:font-medium  text-base leading-6 font-semibold text-coolGray-700">{post.title}</span>
          <span
            className="hidden md:block text-base leading-0 font-normal text-coolGray-500">{(new StringHelper(post.description)).take(6)}</span>
          {get(post, 'priceType.key') != 'best' && (
            <span className="text-sm leading-3 font-normal text-emerald-500">{post.totalPrice}</span>)}
          <div className="flex gap-2  flex-wrap my-2">
            {tags.map(tag => {
              return (
                <span key={tag.field}
                  className="inline-flex items-center rounded-full text-xs px-2.5 py-0.5 leading-4 font-medium bg-white text-coolGray-500 border border-coolGray-500">
                  {tag.value}
                  {tag.icon && (<img src={tag.icon} className="ms-2 w-3 h-3 rounded-xl" />)}
                </span>
              )
            })}
          </div>
        </div>

      </div>
      <div className="">
        <Action allowedActions={post.allowedActions.filter((item) => !['cancel_promote', 'promote'].includes(item))}
          handleSelectedAction={(selectedAction, postId) => handleSelectedAction(selectedAction, post.id)} />
      </div>
    </div>
  );
}

const Action = ({ allowedActions, handleSelectedAction }) => {
  const [displayActions, setDisplayActions] = useState(false);
  const ref = useRef();
  const svgRef = useRef();

  const toggle = () => {
    setDisplayActions(() => {
      return !displayActions
    })
  }


  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref?.current?.contains(event.target) || svgRef.current?.contains(event.target)) {
          return;
        }
        setDisplayActions(false)
      };
      EventDispatcher.on("mousedown", listener);
      EventDispatcher.on("touchstart", listener);
      return () => {
        EventDispatcher.remove("mousedown", listener);
        EventDispatcher.remove("touchstart", listener);
      };
    }, [ref]
  );


  if (allowedActions && allowedActions.length == 0) {
    return <></>
  }

  return (
    <div className="relative inline-block text-start">
      <div onClick={toggle} ref={svgRef}>
        <svg width="4" height="18" className="cursor-pointer" viewBox="0 0 4 18" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
            d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM2 14C0.9 14 0 14.9 0 16C0 17.1 0.9 18 2 18C3.1 18 4 17.1 4 16C4 14.9 3.1 14 2 14ZM0 9C0 7.9 0.9 7 2 7C3.1 7 4 7.9 4 9C4 10.1 3.1 11 2 11C0.9 11 0 10.1 0 9Z"
            fill="#111827" />
        </svg>
      </div>

      {displayActions && (
        <div
          ref={ref}
          className="origin-top-end z-40 absolute w-44	mt-1 end-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
          <div className="flex flex-col items-start" role="none">
            {allowedActions.map((action) => {
              return (
                <span
                  onClick={() => {
                    handleSelectedAction(action)
                    setDisplayActions(false)
                  }}
                  key={action}
                  className="w-full text-sm leading-5 cursor-pointer font-normal text-gray-700 block px-4 py-2 hover:bg-coolGray-200"
                  role="menuitem" id="menu-item-0">{action}</span>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );

}


const Status = ({ statusDetail }) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    setColor(statusDetail.key == 'published' ? 'emerald' : 'yellow');
  }, [statusDetail])
  return (
    <span
      className={`text-xs flex gap-2 py-1.5 px-2 leading-4 font-medium ${color == 'emerald' ? 'bg-emerald-50 text-emerald-500' : 'bg-yellow-100 text-yellow-800'} rounded-md items-center`}>
      <svg width="6" height="6"
        className={`current-color fill-current ${color == 'emerald' ? 'text-emerald-500' : 'text-yellow-400'}`}
        viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" />
      </svg>
      <span>{statusDetail.name}</span>
    </span>
  );
}

const Date = ({ date }) => {
  return (
    <span className={`text-sm leading-0  inline-flex  items-center gap-1 font-normal text-coolGray-500`}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M10.333 2.00002H12.9997C13.3679 2.00002 13.6663 2.2985 13.6663 2.66669V13.3334C13.6663 13.7015 13.3679 14 12.9997 14H0.999674C0.631485 14 0.333008 13.7015 0.333008 13.3334V2.66669C0.333008 2.2985 0.631485 2.00002 0.999674 2.00002H3.66634V0.666687H4.99967V2.00002H8.99967V0.666687H10.333V2.00002ZM8.99967 3.33335H4.99967V4.66669H3.66634V3.33335H1.66634V6.00002H12.333V3.33335H10.333V4.66669H8.99967V3.33335ZM1.66634 7.33335H12.333V12.6667H1.66634V7.33335Z"
          fill="#6B7280" />
      </svg>

      <span className="truncate">{date}</span>
    </span>
  );
}

const Address = ({ address }) => {
  if (!address) {
    return <></>
  }
  return (
    <span className="text-sm leading-0 font-normal text-coolGray-500 inline-flex items-center gap-1">
      <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M6 14.8187L1.75733 10.576C-0.585791 8.2329 -0.585776 4.43392 1.75737 2.09079C4.10051 -0.252349 7.89949 -0.252349 10.2426 2.09079C12.5858 4.43392 12.5858 8.2329 10.2427 10.576L6 14.8187ZM9.3 9.63353C11.1223 7.81103 11.1222 4.85635 9.29978 3.03397C7.47734 1.2116 4.52266 1.2116 2.70022 3.03397C0.877791 4.85635 0.87769 7.81103 2.7 9.63353L6 12.9335L9.3 9.63353ZM4.66667 6.33336C4.66667 7.06974 5.26362 7.66669 6 7.66669C6.73638 7.66669 7.33333 7.06974 7.33333 6.33336C7.33333 5.59698 6.73638 5.00002 6 5.00002C5.26362 5.00002 4.66667 5.59698 4.66667 6.33336Z"
          fill="#6B7280" />
      </svg>
      <span className="truncate">{last(address?.split(','))}</span>
    </span>

  );
}


export default PostingIndex
