import React, { useEffect } from 'react'

const BookmarkIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33366 0.333252H10.667C10.8438 0.333252 11.0134 0.40349 11.1384 0.528514C11.2634 0.653538 11.3337 0.823108 11.3337 0.999919V13.7619C11.3337 13.8215 11.3178 13.8801 11.2876 13.9314C11.2574 13.9828 11.2139 14.0251 11.1618 14.054C11.1096 14.0828 11.0507 14.0972 10.9911 14.0955C10.9315 14.0939 10.8735 14.0763 10.823 14.0446L6.00033 11.0199L1.17766 14.0439C1.12724 14.0756 1.06925 14.0932 1.00973 14.0949C0.950203 14.0965 0.891316 14.0823 0.839189 14.0535C0.787061 14.0247 0.743597 13.9825 0.713314 13.9312C0.683031 13.8799 0.667037 13.8215 0.666992 13.7619V0.999919C0.666992 0.823108 0.73723 0.653538 0.862254 0.528514C0.987279 0.40349 1.15685 0.333252 1.33366 0.333252Z"/>
    </svg>

  );
}


export default BookmarkIcon