import React, {FC, useEffect} from 'react';
import {Collapse} from 'react-collapse';
import 'remixicon/fonts/remixicon.css'

export type AccoridonItem = {
  title: string;
  content: string;
};
interface AccoridonProps {
  title?: string;
  items: AccoridonItem[];
}

const Accoridon: FC<AccoridonProps> = ({title, items}) => {
  const sections = items || [
    {
      title: 'Missing items[0][title] Propery',
      content: 'Missing items[0][content] Propery',
    },
  ];
  const [activeSections, setActiveSections] = React.useState<number[]>([]);

  useEffect(() => {
    setActiveSections([]);
  }, [items]);

  const _renderContent = (section) => {
    return (
      <div className="pt-2">
        <p className="flex text-coolGray-500 text-xs">{section.content}</p>
      </div>
    );
  };

  return (
    <div className="px-6 py-4 bg-white rounded-lg">
      {title && <p className="text-coolGray-700 font-bold text-sm">{title}</p>}

      {sections.map((section: AccoridonItem, index) => {
        return (
          <div
            key={`acc-${index}`}
            className={`pb-2 flex flex-col ${index === 0 && !title ? 'mt-0' : 'mt-4'} ${index === sections.length - 1 ? 'border-b-0' : 'border-b border-coolGray-100'}`}
          >
            <button
              onClick={() => {
                if (activeSections.includes(index)) {
                  setActiveSections([]);
                } else {
                  setActiveSections([index]);
                }
              }}
              className="flex items-center">
              <p className="text-coolGray-700 me-2 flex-1 text-start text-sm font-medium">{section.title}</p>
              <i className={` ${!activeSections.includes(index) ? 'ri-arrow-drop-down-line' : 'ri-arrow-drop-up-line'}`}></i>
            </button>
            <Collapse isOpened={activeSections.includes(index)}>
              {_renderContent(section)}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default Accoridon;
